import { GetFarmerLabelsKeys } from "api/farmer-labels-keys/get";
import { CreateFarmerLabel } from "api/farmer-labels/add";
import { UpdateFarmerLabels } from "api/farmer-labels/update";
import { debounce } from "lodash";
import { IFarmerLabel } from "models/farmer-label";
import { IFarmerLabelKey } from "models/farmer-label-key";
import React from "react";
import { useAppSelector } from "store/hooks";

export interface IAdd {
  onAdded?: () => any;
  variant: "add";
  farmer_id: string;
}

export interface IUpdate {
  onUpdated?: () => any;
  variant: "update";
  key_id: string;
  farmer_id: string;
}

export type Params = IAdd | IUpdate;

export const useLogic = (params: Params) => {
  const [labelsData, setLabelsData] = React.useState<
    Pick<IFarmerLabel, "value" | "id">[]
  >([{ id: "", value: "" }]);

  const [keyOptions, setKeyOptions] = React.useState<IFarmerLabelKey[]>([]);
  const user = useAppSelector((root) => root.appReducer.user);
  const [keySearchTerm, setKeySearchTerm] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingKeys, setLoadingKeys] = React.useState(false);
  const [keyValue, setKeyValue] =
    React.useState<undefined | IFarmerLabelKey>(undefined);
  const handleKeySearchTerm = debounce(
    (value: string) => setKeySearchTerm(value),
    800
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleAdd = () => {
    if (params.variant === "add" && keyValue) {
      setIsSubmitting(true);
      CreateFarmerLabel(
        {
          farmer_id: params.farmer_id,
          label_key_id: keyValue?.id,
          value: labelsData.map((item) => item.value),
        },
        () => {
          setIsSubmitting(false);
          params.onAdded?.();
        }
      );
    }
  };

  const handleUpdate = () => {
    if (params.variant === "update") {
      setIsSubmitting(true);
      UpdateFarmerLabels(
        {
          farmer_id: params.farmer_id,
          label_key_id: params.key_id,
          value: labelsData.map((item) => item.value),
        },
        () => {
          setIsSubmitting(false);
          params.onUpdated?.();
        }
      );
    }
  };

  React.useEffect(() => {
    if (user) {
      setLoadingKeys(true);

      GetFarmerLabelsKeys(
        {
          // TODO
          // provider_id_list: [user?.provider_id],
          page: 0,
          pageSize: 10,
          key: keySearchTerm,
          // key_search: keySearchTerm,
        },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          }
          if (resp) {
            setKeyOptions(resp.items);
            setLoadingKeys(false);
          }
        }
      );
    }
  }, [keySearchTerm]);

  React.useEffect(() => {
    if (params.variant === "update") {
      setLoading(true);
      GetFarmerLabelsKeys(
        {
          page: 0,
          pageSize: 10,
          // id_list: [params.key_id],
          // farmer_id_list: [params.farmer_id]
        },
        (error, resp) => {
          if (error) console.log(error.message);
          if (resp) {
            setLoading(false);
            setKeyValue(resp.items[0]);
            setLabelsData(
              resp.items.find((item) => item.id === params.key_id)!
                .farmerLabelsByLabelKeyId.nodes
            );
          }
        }
      );
    }
  }, [(params as IUpdate).key_id]);

  const handleAddLabelInput = () => {
    setLabelsData([...labelsData, { id: "", value: "" }]);
  };

  const handleKeySelect = (value: IFarmerLabelKey) => setKeyValue(value);

  const handleInsertLabelText = (index: number, value: string) => {
    setLabelsData(
      labelsData.map((item, array_index) =>
        array_index === index ? { ...item, value } : item
      )
    );
  };

  const handleDeleteLabelInput = (index: number) => {
    setLabelsData(
      labelsData.filter((item, array_index) => index !== array_index)
    );
  };

  return {
    labelsData,
    handleAddLabelInput,
    handleDeleteLabelInput,
    keyOptions,
    handleKeySearchTerm,
    loadingKeys,
    handleInsertLabelText,
    keyValue,
    handleKeySelect,
    loading,
    handleUpdate,
    handleAdd,
    isSubmitting,
  };
};
