import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
  },
  rightSideButtonRoot: {
    backgroundColor: "#dbdbdb",
    padding: 16,
    borderRadius: 6,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  TextFieldWrapperInputWithoutRightBorders: {
    "& .MuiInputBase-root": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  textFieldRoot: {},
});
