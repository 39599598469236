import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  textRoot: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
    textAlign: "left",
  },

  iconRoot: {
    marginRight: 8,
  },
  colorSuccess: { color: "#44b16e" },
  colorError: { color: "#ff5252" },
});
