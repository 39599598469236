import MaterialTable from "@material-table/core";
import CustomPagnation from "components/custom-compnents/custom-pagnation/custom-pagnation";
import { Fields, Params, schema } from "./custom-users-table.schema";
import { Box, Grid } from "@mui/material";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import { CustomPagnationProps } from "components/custom-compnents/custom-pagnation/custom-pagnation";
import CustomFiltersContainer, { CustomFiltersContainerProps } from "../custom-filters-container/custom-filters-container";
import CustomUsersTableActionBar, { CustomUsersTableActionBarProps } from "../custom-users-table-action-bar/custom-users-table-action-bar";
import UsersTableForm, { UsersTableFormProps } from "components/forms/users-table-form/users-table-form";

export interface CustomUsersTableProps {
  countLabelProps?: CountLabelProps;
  showFilterArea?: boolean;
  filterContainerProps?: CustomFiltersContainerProps;
  filterFormProps?: UsersTableFormProps;
  pagnationProps?: CustomPagnationProps;
  actionBarProps?: CustomUsersTableActionBarProps;
  data: Fields[];
  isLoading?: boolean;
  customSelection?: Params["customSelection"];
  onOrderChange?: (orderBy: number, orderDirection: "desc" | "asc") => void | undefined;
  onEditButtonClick?: Params["onEditButtonClick"];
  onDeleteButtonClick?: Params["onDeleteButtonClick"];
  isShowRemoveAction?: boolean;
  isShowEditAction?: boolean;
}

const CustomUsersTable: React.FunctionComponent<CustomUsersTableProps> = (props) => {
  const { countLabelProps, showFilterArea, pagnationProps, filterContainerProps, filterFormProps, actionBarProps, data, isLoading, customSelection, onOrderChange, onEditButtonClick, onDeleteButtonClick, isShowEditAction, isShowRemoveAction } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ paddingLeft: 0 }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ paddingLeft: 0 }}>
          <Grid item md={3} xs={12}>
            <CountLabel {...countLabelProps} label="All Users" />
          </Grid>
          <Grid item md={9} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Box width={840} maxWidth="100%">
                <CustomUsersTableActionBar {...actionBarProps} />
              </Box>
            </Box>
          </Grid>
          {showFilterArea && (
            <Grid item xs={12}>
              <CustomFiltersContainer {...filterContainerProps}>
                <UsersTableForm {...filterFormProps} />
              </CustomFiltersContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          {...schema({
            customSelection,
            data,
            columnState: actionBarProps?.columnsState,
            onEditButtonClick,
            onDeleteButtonClick,
            isShowEditAction,
            isShowRemoveAction,
          })}
          isLoading={isLoading}
          onOrderChange={onOrderChange}
        />
      </Grid>
      <Grid item xs={12}>
        {pagnationProps && <CustomPagnation {...pagnationProps} />}
      </Grid>
    </Grid>
  );
};

export default CustomUsersTable;
