import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
interface Params {
  id: string;
}
interface Resp {}

type DeleteSenderType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const DeleteSender: DeleteSenderType = (Params, callback) => {
  AxiosInstance.delete("/senders/" + Params.id)
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { DeleteSender };
