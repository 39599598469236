import { Grid } from "@mui/material";
import CheckBoxItemsMenu, {
  CheckBoxItemsMenuProps,
} from "components/custom-compnents/check-box-items-menu/check-box-items.menu";
import CustomMenuButton from "components/custom-compnents/custom-menu-button/custom-menu-button";
import CustomTextField, {
  CustomTextFieldProps,
} from "components/custom-compnents/custom-text-field/custom-text-field";

import * as React from "react";

export interface CallbacksTableFilterFormProps {
  farmerNameFilterProps?: CustomTextFieldProps;
  farmerLastNameFilterProps?: CustomTextFieldProps;
  farmerPhoneFilterProps?: CustomTextFieldProps;
  noteFilterProps?: CustomTextFieldProps;
  statusMenuFilterProps?: Pick<CheckBoxItemsMenuProps, "Items" | "onItemClick">;
  isShowFarmerNameFilter?: boolean;
  isShowFarmerLastNameFilter?: boolean;
  isShowFarmerPhoneFilter?: boolean;
}

const CallbacksTableFilterForm: React.FunctionComponent<CallbacksTableFilterFormProps> =
  (props) => {
    const {
      noteFilterProps,
      statusMenuFilterProps,
      farmerNameFilterProps,
      farmerLastNameFilterProps,
      farmerPhoneFilterProps,
      isShowFarmerNameFilter,
      isShowFarmerPhoneFilter,
      isShowFarmerLastNameFilter,
    } = props;

    const FieldsData = [
      {
        label: "Farmer Name",
        type: "text",
        props: farmerNameFilterProps,
        hidden: !isShowFarmerNameFilter,
      },
      {
        label: "Farmer Last Name",
        type: "text",
        props: farmerLastNameFilterProps,
        hidden: !isShowFarmerLastNameFilter,
      },
      {
        label: "Farmer Phone",
        type: "text",
        props: farmerPhoneFilterProps,
        hidden: !isShowFarmerPhoneFilter,
      },
      {
        label: "Note",
        type: "text",
        props: noteFilterProps,
      },
      {
        label: "Status",
        type: "check-menu",
        props: statusMenuFilterProps,
        MenuProps: statusMenuFilterProps,
      },
    ];

    const [statusMenuAnchorEl, setstatusMenuAnchorEl] = React.useState();

    const handleStatusMenuOpen = (e: any) =>
      setstatusMenuAnchorEl(e.currentTarget);

    const handleStatusMenuClose = () => setstatusMenuAnchorEl(undefined);

    return (
      <Grid container spacing={2}>
        {FieldsData.filter((item) => !item.hidden).map(
          ({ type, ...item }, index) => (
            <Grid item lg={2} xs={12} key={index}>
              {type === "text" && (
                <CustomTextField
                  placeholder={item.label}
                  fullWidth
                  {...(item.props as CustomTextFieldProps)}
                />
              )}
              {type === "check-menu" && item.MenuProps && (
                <>
                  <CheckBoxItemsMenu
                    {...item.MenuProps}
                    onClose={handleStatusMenuClose}
                    headerLabel="Status"
                    open={Boolean(statusMenuAnchorEl)}
                    anchorEl={statusMenuAnchorEl}
                  />
                  <CustomMenuButton onClick={handleStatusMenuOpen}>
                    {item.label}
                  </CustomMenuButton>
                </>
              )}
            </Grid>
          )
        )}
      </Grid>
    );
  };

export default CallbacksTableFilterForm;
