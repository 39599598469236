import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";
import { generatePaginationJson } from "utils/graphql-pagination-generator";
import { FindAllDto } from "./dto/find-all.dto";
import { filter } from "./graphql/filter";
import { query } from "./graphql/query";
import * as _ from "lodash";
import { IMessageCategory } from "models/message-category";
import snakecaseKeys from "snakecase-keys";

export const findAll = async (findAllDto: FindAllDto) => {
  const cloned_filter = _.cloneDeep(filter);

  cloned_filter.id.in = findAllDto.id_list as any;

  cloned_filter.id.equalTo = findAllDto.id as string;

  cloned_filter.name.includesInsensitive = findAllDto.name as string;

  const variables = { ...generatePaginationJson(findAllDto.page, findAllDto.pageSize), filter: cloned_filter };

  return AxiosInstance.post<{ all_message_categories: { nodes: IMessageCategory[]; total_count: number } }>("/templates/categories", { graphQl: query, variables: cleanDeep(variables) }).then((resp) => ({ ...resp, data: snakecaseKeys(resp.data) }));
};
