import { Typography } from "@mui/material";
import { useStyles } from "./custom-subtitle.style";

interface CustomSubtitleProps {
  label?: string;
}

const CustomSubtitle: React.FunctionComponent<CustomSubtitleProps> = (
  props
) => {
  const { label } = props;
  const classes = useStyles();

  return <Typography className={classes.root}>{label}</Typography>;
};

export default CustomSubtitle;
