import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";


interface Params {
  id: string
}


type Resp = "ok";



type DeleteFarmerType = (
  Params: Params,
  callback: (Error: AxiosError | null, Resp: null | Resp) => any
) => any;


const DeleteFarmersApi: DeleteFarmerType = (Params, callback) => {
  AxiosInstance.delete("/farmers/" + Params.id)
    .then((resp) => {
      callback(null, "ok")
    })
    .catch((err) => callback(err, null));
};

export { DeleteFarmersApi };
