import React from "react";
// import MaterialTable from "material-table";
// import { FarmsTableSchema } from "./schema";
// import { Box, Paper } from "@mui/material";
import CustomFarmsTable from "components/custom-compnents/custom-farms-table/custom-farms-table";
import { useLogic } from "./farms-table.logic";

export interface FarmsTableProps {
  by_farmer_id: string;
  sorting?: boolean;
}

const FarmsTable: React.FC<FarmsTableProps> = ({ by_farmer_id, sorting }) => {
  const { tableProps } = useLogic({ by_farmer_id });
  return (
    // <Box component={Paper} p={2}>
    //   <MaterialTable
    //     {...FarmsTableSchema({ by_farmer_id: by_farmer_id, sorting })}
    //   />
    // </Box>
    <>
      <CustomFarmsTable {...tableProps} />
    </>
  );
};

export default FarmsTable;
