import {
  Autocomplete,
  Box,
  ButtonBase,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import { fetchEwalletUsers } from "api/ewallet-users/fetch-ewallet-users";
import CustomMenu from "components/custom-compnents/custom-menu-component/custom-menu-component";
import { useDebounce } from "hooks/debounce-hook";
import { IEwalletUser } from "models/ewallet-user";
import { FunctionComponent, useEffect, useState } from "react";
import { useStyle } from "../input-with-operator/input-with-operator.style";

export interface IValue {
  value?: IEwalletUser | null;
  operator?: "from_user" | "to_user" | "";
}

export interface InputValue {
  operator?: IValue["operator"];
  value?: IEwalletUser["id"];
}

export interface EwalletUsersInputProps {
  onChange?: (value: IValue) => any;
  value?: InputValue;
  fullWidth?: boolean;
  disabled?: boolean;
  hideOperationInput?: boolean;
  placeholder?: string;
  withOptionDetail?: boolean;
  error?: boolean;
  helperText?: string;
  "data-cy"?: string;
}

const EwalletUsersInput: FunctionComponent<EwalletUsersInputProps> = (
  props
) => {
  const {
    onChange,
    value,
    fullWidth,
    disabled,
    hideOperationInput,
    placeholder,
    withOptionDetail,
    error,
    helperText,
  } = props;

  // this is only for value Output usage
  const [userValue, setUserValue] = useState<IEwalletUser | undefined | null>({
    id: "",
    name: "",
  } as IEwalletUser);

  const [searchTerm, setsearchTerm] = useState("");

  const debouncedValue = useDebounce(searchTerm, 700);

  const [isLoading, setisLoading] = useState(false);

  const optionsList = [
    {
      value: "from_user",
      title: "From",
      "data-cy": "user-filter-input-transactions-table-from-operator",
    },
    {
      value: "to_user",
      title: "To",
      "data-cy": "user-filter-input-transactions-table-to-operator",
    },

    {
      value: "",
      title: "Any",
    },
  ];

  const classes = useStyle();

  const [users, setusers] = useState<IEwalletUser[]>([]);

  useEffect(() => {
    if (!value?.value) {
      setUserValue({ id: "", name: "" } as IEwalletUser);
    }
  }, [value?.value]);

  useEffect(() => {
    setisLoading(true);
    setusers([]);
    fetchEwalletUsers({ page: 0, pageSize: 10, search: debouncedValue })
      .then((resp) => {
        setusers(resp.data.items);
      })
      .finally(() => setisLoading(false));
  }, [debouncedValue]);

  return (
    <Box display="flex" justifyContent="center">
      <Autocomplete
        onChange={(e, v) => {
          setUserValue(v);
          onChange?.({ value: v, operator: value?.operator });
        }}
        disabled={disabled}
        fullWidth={fullWidth}
        value={userValue}
        onInputChange={(e, v) => setsearchTerm(v)}
        getOptionLabel={(item) => item.name}
        renderOption={
          withOptionDetail
            ? (props, option) => (
                <ListItemButton component="li" dense {...props}>
                  <ListItemText primary={option.name} secondary={option.id} />
                  <ListItemSecondaryAction>
                    {option.type}
                  </ListItemSecondaryAction>
                </ListItemButton>
              )
            : undefined
        }
        options={users?.length > 0 ? users : []}
        loading={isLoading}
        loadingText={"Loading ..."}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            InputProps={{
              style: !hideOperationInput
                ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                : {},
              ...params.InputProps,
            }}
            placeholder={placeholder}
            data-cy={props?.["data-cy"]}
          />
        )}
      />
      {!hideOperationInput && (
        <CustomMenu
          disabled={disabled}
          menuData={optionsList.map((item) => ({
            onClick: () => {
              onChange?.({
                value: userValue,
                operator: item.value as IValue["operator"],
              });
            },
            label: item.title,
            "data-cy": item?.["data-cy"],
          }))}
          buttonContainerProps={{
            "data-cy": `${props?.["data-cy"]}-operator-menu`,
          }}
        >
          <Box
            disabled={disabled}
            className={classes.operatorWrapper}
            component={ButtonBase}
          >
            {
              optionsList.find((item) =>
                value?.operator
                  ? item.value === value?.operator
                  : item.value === ""
              )?.title
            }
          </Box>
        </CustomMenu>
      )}
    </Box>
  );
};

export default EwalletUsersInput;

EwalletUsersInput.defaultProps = {
  placeholder: "Search User ...",
};
