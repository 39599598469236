import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";


interface Resp { }
type RestartUssdType = (
  callback: (error: AxiosError | null, resp: null | Resp) => any
) => any;

const RestartSurvey: RestartUssdType = (callback) => {
  AxiosInstance.post("/surveys/restart")
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => callback(error, null));
};

export { RestartSurvey };
