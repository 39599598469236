import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  buttonRoot: {
    justifyContent: "left",
  },
  buttonDownload: {
    justifyContent: "space-between",
  },
});
