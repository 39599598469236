import {
  Box,
  Button,
  Chip,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
import { Farmer } from "models/farmers";
import moment from "moment";
import { IoOpenOutline } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoChevronForwardSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { groupBy, uniqBy } from "lodash";
export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
  selectedFarmersIds?: string[];
  selectedByPhone?: boolean;
}
export interface Fields extends Farmer {
  selected: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (
  params
) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "FirstName",
      field: "first_name",
      hidden: !params.columnState?.find((item) => item.field === "first_name")
        ?.checked,
      render: (data) => <Typography>{data.firstName}</Typography>,
    },
    {
      title: "LastName",
      field: "last_name",
      hidden: !params.columnState?.find((item) => item.field === "last_name")
        ?.checked,
      render: (data) => <Typography>{data.lastName}</Typography>,
    },
    {
      title: "Phone",
      field: "phone",
      hidden: !params.columnState?.find((item) => item.field === "phone")
        ?.checked,
      render: (data) => <Typography>{data.phone}</Typography>,
    },
    {
      title: "Location",
      sorting: false,
      field: "location",
      hidden: !params.columnState?.find((item) => item.field === "location")
        ?.checked,
      render: (rowData) => (
        <Box display="flex" alignItems="center">
          {[
            {
              title: rowData?.location?.region,
            },
            {
              title: rowData?.location?.county,
            },
            { title: rowData?.location?.constituency },
            { title: rowData?.location?.ward },
          ]
            .filter((item) => item.title)
            .map((item, index) => (
              <Box display="flex" alignItems="center">
                <Typography key={index} whiteSpace="nowrap" mx={0.5}>
                  {item.title}
                </Typography>
                {index !== 3 && <IoChevronForwardSharp size={12} />}
              </Box>
            ))}
        </Box>
      ),
    },
    {
      title: "FARMS COUNT",
      field: "farms_count",
      render: (data) => <Typography>{data.farms.length}</Typography>,
      hidden: !params.columnState?.find((item) => item.field === "farms_count")
        ?.checked,
    },
    {
      title: "Labels",
      field: "labels",
      render: (data) =>
        Object.entries(groupBy(data.farmer_labels, (item) => item.key)).map(
          (item) => (
            <Chip
              key={item[0]}
              sx={{ m: 0.5 }}
              label={`${item[0]}: ${item[1]
                .map((item) => item.value)
                .join(", ")}`}
            />
          )
        ),
      hidden: !params.columnState?.find((item) => item.field === "labels")
        ?.checked,
    },
    {
      title: "CROPS",
      hidden: !params.columnState?.find((item) => item.field === "crops")
        ?.checked,
      field: "crops",
      sorting: false,
      render: (data) =>
        data.crops?.map((item, index) => (
          <Box m={0.5} display="inline-block" key={index}>
            <Chip
              variant="outlined"
              size="small"
              label={item.name}
              data-cy="farmers-table-crops-chip"
            />
          </Box>
        )),
    },
    {
      title: "SOURCE",
      hidden: !params.columnState?.find((item) => item.field === "source")
        ?.checked,
      field: "source",
      render: (data) => <Typography>{data.source}</Typography>,
    },
    {
      title: "ACCEPTED TERMS AT",
      hidden: !params.columnState?.find(
        (item) => item.field === "acceptedTermsAt"
      )?.checked,
      field: "acceptedTermsAt",
      render: (data) => (
        <Typography variant="subtitle2">
          {data.acceptedTermsAt
            ? moment(data.acceptedTermsAt).format("D/M/YYYY , HH:mm:SS a")
            : "-"}
        </Typography>
      ),
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: (
        <CustomCheckBox
          onChange={(e, value) =>
            params.customSelection?.onSelectAllChange?.(params.data, value)
          }
          checked={params.data.every((item) => item.selected)}
          indeterminate={
            params.data.some((item) => item.selected) &&
            !params.data.every((item) => item.selected)
          }
        />
      ),
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => (
        <CustomCheckBox
          checked={data.selected}
          onChange={(e, value) =>
            params.customSelection?.onRowSelectChange?.(data, value)
          }
        />
      ),
    });
  }

  return {
    actions: [
      (fields) => ({
        icon: () => (
          <Link to={`/farmers/${fields.id}`}>
            <Button
              variant="text"
              startIcon={<IoOpenOutline />}
              color="success"
              data-cy="farmer-view-details-button"
            >
              View
            </Button>
          </Link>
        ),
        onClick: () => null,
      }),
    ],

    columns: columns.map((item) => ({ ...item, tableData: undefined })),
    data: params.data,
    icons: tableIcons,
    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
