import { AxiosInstance } from "configs/axios";

export interface IResp {
  data: {
    cube: {
      farmers: {
        unique_farmer_count: number;
        gender: "male" | "female" | null;
      };
    }[];
  }
}

export interface IParams {
  region_id_list?: string[];
  county_id_list?: string[];
  constituency_id_list?: string[];
  ward_id_list?: string[];
  provider_id?: string;
}

export const getFarmerGendersKpi = async (Params: IParams) => {
//   const graphQL = `
//   query ($filter:RootWhereInput)  { 
//     cube(where:$filter) {
//       farmers(orderBy: {count: desc } ) {
//         uniqueFarmerCount
//         gender
//       }
//     }
//   }  
// `;

//   const variables = {
//     filter: {
//       regions: { regionId: { in: params.region_id_list } },
//       constituencies: { constituencyId: { in: params.constituency_id_list } },
//       wards: { wardId: { in: params.ward_id_list } },
//       counties: { countyId: { in: params.county_id_list } },
//       providerOptins: { providerId: { equals: params.provider_id } },
//     },
//   };

  return await AxiosInstance.get<IResp>("/farmer-analytics/find-gender-kpi", { params: Params});
};
