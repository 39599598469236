import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useStyles } from "./custom-show-edit-card.styles";

interface CustomShowEditCardProps {
  editable?: boolean;
  ShowNode?: any;
  editComponent?: any;
  label?: string;
}

const CustomShowEditCard: React.FunctionComponent<CustomShowEditCardProps> = (
  props
) => {
  const classes = useStyles();
  const { ShowNode, editComponent, editable, label } = props;
  return (
    <Box component={Paper} px={2} py={1}>
      <Typography
        variant="subtitle1"
        color=" #5d6d6e"
        className={classes.labelRoot}
      >
        {label}
      </Typography>
      {editable ? editComponent : ShowNode}
    </Box>
  );
};

export default CustomShowEditCard;
