// import { Box, Grid, TextField, GridSize, Typography } from "@mui/material";
// import { Formik } from "formik";
import React from "react";
// import SelectMenu, {
//   option,
// } from "components/input-components/select-menu/SelectMenu";

// import * as yup from "yup";
// import { AddAggregationActivity } from "api/aggregation/add-aggregation";
// import { UpdateAggregationActivity } from "api/aggregation/update-aggregation";
// import { Activity } from "models/activity";
// import { GetFarmActivites } from "api/activities/get-farm-activites";
// import { GetCrop } from "api/crops/get-crops";
// import { GetVariety } from "api/variety/get-variety";
// import CustomDialog, {
//   CustomDialogProps,
// } from "components/custom-compnents/custom-dialog/custom-dialog";
import CustomAddUpdateAggregationEventComponent from "components/custom-compnents/custom-add-update-aggregation-event-component/custom-add-update-aggregation-event-component";
import { useLogic } from "./add-update-aggregation-activity.logic";

interface AddUpdateAggregationActivityProps {
  OnCancel: () => any;
  open: boolean;
  farm_id: string;
}

interface AddProps {
  Variant: "Add";
  OnAdded: () => any;
}

interface UpdateProps {
  Variant: "Update";
  OnUpdate: () => any;
  Activity_Id: string;
}
// const SelectCropFirstStatment = "Select Crop First";
// const LoadingVarietyStatment = "Loading ...";
// const NoResultStatment = "No Result";
// const ReadyToSelectStatment = "Variety";

const AddUpdateAggregationActivity: React.FunctionComponent<
  AddUpdateAggregationActivityProps & (AddProps | UpdateProps)
> = (Props) => {
  // const [IsSubmitting, SetIsSubmitting] = React.useState(false);
  // const [AggregationData, SetAggregationData] =
  //   React.useState<Activity | undefined>(undefined);
  // const [CropNewValue, SetCropNewValue] =
  //   React.useState<string | undefined>(undefined); // for Variety update
  // const [VarietyMenuLabel, SetVarietyMenuLabel] = React.useState(
  //   SelectCropFirstStatment
  // );
  // const CustomDialogProps: CustomDialogProps = {
  //   Header:
  //     Props.Variant === "Add"
  //       ? "Add Aggregation Event"
  //       : "Update Aggregation Event",
  //   open: Props.open,
  //   ActionButtonsProps: {
  //     DisableConfirm: IsSubmitting,
  //     ButtonsVariant: "ConfirmCancel",
  //     OnCancel: Props.OnCancel,
  //     OnConfirm: () =>
  //       document
  //         .getElementById("aggregation_add_update_submit_button")
  //         ?.click(),
  //   },
  // };
  // const PaymentMethodsOptions: option[] = [
  //   { title: "Cash", value: "cash" },
  //   { title: "Mpesa", value: "mpesa" },
  // ];
  // const StatusOptions: option[] = [
  //   { title: "Draft", value: "draft" },
  //   { title: "Awaiting Quality Checks", value: "awaiting_quality_checks" },
  //   { title: "Awaiting Payment", value: "awaiting_payment" },
  //   { title: "Done", value: "done" },
  // ];

  const {
    CropOptions,
    formValues,
    handleCropValueChange,
    VarietyOptions,
    isVariteyLoading,
    handleVarietyValueChange,
    handlePaymentMethodChange,
    handleStatusChange,
    handleDayChange,
    handleMonthChange,
    handleQuantityChange,
    handleTotalPriceChange,
    handleYearChange,
    IsSubmitting,
    handleAddEventClick,
    formErrors,
  } = useLogic({
    variant: Props.Variant,
    OnAddedOrUpdate:
      Props.Variant === "Add"
        ? Props.OnAdded
        : Props.Variant === "Update"
        ? Props.OnUpdate
        : () => null,
    activityId: Props.Variant === "Update" ? Props.Activity_Id : undefined,
    farm_id: Props.farm_id,
  });
  // const [CropOptions, SetCropsOptions] = React.useState<option[]>([]);
  // const [VarietyOptions, SetVarietyOptions] = React.useState<option[]>([]);

  // React.useEffect(() => {
  //   GetCrop({}, (error, resp) => {
  //     if (error) {
  //       console.log(error.message);
  //     } else if (resp) {
  //       SetCropsOptions(
  //         resp.items.map((item, index) => ({
  //           value: item.id,
  //           title: item.name,
  //         }))
  //       );
  //     }
  //   });
  // }, []);

  // React.useEffect(() => {
  //   if (CropNewValue) {
  //     SetVarietyMenuLabel(LoadingVarietyStatment);
  //     GetVariety({ crop_id_list: [CropNewValue] }, (error, resp) => {
  //       if (error) {
  //         console.log(error.message);
  //       } else if (resp) {
  //         if (resp.items.length === 0) SetVarietyMenuLabel(NoResultStatment);
  //         if (resp.items.length > 0) SetVarietyMenuLabel(ReadyToSelectStatment);
  //         SetVarietyOptions(
  //           resp.items.map((item) => ({ value: item.id, title: item.name }))
  //         );
  //       }
  //     });
  //   }
  // }, [CropNewValue]);

  // const FieldsArray = [
  //   {
  //     placeholder: "Crop",
  //     name: "crop_id",
  //     type: "menu",
  //     options: CropOptions,
  //     GridProps: { xs: 12 },
  //     OnChange: (crop_id: string) => SetCropNewValue(crop_id),
  //   },
  //   {
  //     placeholder: VarietyMenuLabel,
  //     name: "variety_id",
  //     type: "menu",
  //     options: VarietyOptions,
  //     GridProps: { xs: 12 },
  //     disabled:
  //       VarietyMenuLabel == NoResultStatment ||
  //       VarietyMenuLabel == LoadingVarietyStatment ||
  //       VarietyMenuLabel == SelectCropFirstStatment,
  //   },
  //   {
  //     label: "Quantity (kg)",
  //     name: "aggr_quantity_kg",
  //     placeholder: "Insert Quantity ...",
  //     type: "text",
  //     GridProps: { xs: 12 },
  //   },
  //   {
  //     label: "Year",
  //     name: "year",
  //     placeholder: "Insert Year",
  //     type: "text",
  //     GridProps: { xs: 4 },
  //   },
  //   {
  //     label: "Month",
  //     name: "month",
  //     placeholder: "Insert Month",
  //     type: "text",
  //     GridProps: { xs: 4 },
  //   },
  //   {
  //     label: "Day",
  //     name: "day",
  //     placeholder: "Insert Day",
  //     type: "text",
  //     GridProps: { xs: 4 },
  //   },
  //   {
  //     label: "Total Price (KSh)",
  //     name: "aggr_total_price_ksh",
  //     placeholder: "Insert Price ...",
  //     type: "text",
  //     GridProps: { xs: 12 },
  //   },
  //   {
  //     name: "aggr_payment_method",
  //     placeholder: "Payment Method",
  //     type: "menu",
  //     options: PaymentMethodsOptions,
  //     GridProps: { xs: 12 },
  //   },
  //   {
  //     placeholder: "Status",
  //     name: "aggr_status",
  //     type: "menu",
  //     options: StatusOptions,
  //     GridProps: { xs: 12 },
  //   },
  // ];
  // const ValidationSchema = yup.object().shape({
  //   aggr_total_price_ksh: yup.number().required().label("Price"),
  //   aggr_quantity_kg: yup.number().required().label("Quantity"),
  //   aggr_status: yup
  //     .string()
  //     .required()
  //     .oneOf(["draft", "awaiting_quality_checks", "awaiting_payment", "done"])
  //     .label("Status"),
  //   aggr_payment_method: yup
  //     .string()
  //     .required()
  //     .oneOf(["mpesa", "cash"])
  //     .label("payment"),
  //   day: yup.string().required().min(1).max(2),
  //   month: yup.string().required().min(1).max(2),
  //   year: yup.string().required().min(4).max(4),
  //   crop_id: yup.string().uuid().required().label("crop"),
  // });

  return (
    <CustomAddUpdateAggregationEventComponent
      dialogProps={{ open: Props.open }}
      DayErrorHelper={formErrors.day}
      MonthErrorHelper={formErrors.month}
      YearErrorHelper={formErrors.year}
      cropErrorHelper={formErrors.crop?.value}
      paymentMethodErrorHelper={formErrors.aggr_payment_method?.value}
      statusErrorHelper={formErrors.aggr_status?.value}
      varietyErrorHelper={formErrors.variety?.value}
      quantityErrorHelper={formErrors.aggr_quantity_kg}
      totalPriceErrorHelper={formErrors.aggr_total_price_ksh}
      CropsMenuItems={CropOptions}
      VarietyMenuItems={VarietyOptions}
      isVarietyFieldDisabled={
        isVariteyLoading ||
        !formValues.crop.value ||
        VarietyOptions.length === 0
      }
      onVarietyValueChange={handleVarietyValueChange}
      isVarietyFieldEmpty={VarietyOptions.length === 0}
      isVarietyFieldLoading={isVariteyLoading}
      dayValue={formValues.day}
      monthValue={formValues.month}
      onDayChange={handleDayChange}
      onMonthChange={handleMonthChange}
      onQuantityChange={handleQuantityChange}
      onTotalPriceChange={handleTotalPriceChange}
      onYearChange={handleYearChange}
      yearValue={formValues.year}
      totalPriceValue={formValues.aggr_total_price_ksh}
      selectCropTitleValue={
        Boolean(formValues.crop.label) ? formValues.crop.label : undefined
      }
      quantityValue={formValues.aggr_quantity_kg}
      selectVarietyValue={
        Boolean(formValues.variety.label) ? formValues.variety.label : undefined
      }
      selectPaymentTitleValue={
        Boolean(formValues.aggr_payment_method.label)
          ? formValues.aggr_payment_method.label
          : undefined
      }
      selectStatusTitleValue={
        Boolean(formValues.aggr_status.label)
          ? formValues.aggr_status.label
          : undefined
      }
      onCropValueChange={handleCropValueChange}
      onPaymentValueChange={handlePaymentMethodChange}
      onStatusValueChange={handleStatusChange}
      CancelSuccessButtonsGroupProps={{
        SuccessButtonProps: {
          disabled: IsSubmitting,
          onClick: handleAddEventClick,
        },
        CancelButtonProps: {
          onClick: Props.OnCancel,
        },
      }}
    />
    // <CustomDialog {...CustomDialogProps}>
    //   {Props.Variant === "Update" && !AggregationData ? (
    //     <Typography>Loading ...</Typography>
    //   ) : (
    //     <Box maxWidth={300}>
    //       <Formik
    //         initialValues={{
    //           year: AggregationData?.year,
    //           month: AggregationData?.month,
    //           day: AggregationData?.day,
    //           aggr_quantity_kg: AggregationData?.aggrQuantityKg,
    //           aggr_status: AggregationData?.aggrStatus,
    //           aggr_total_price_ksh: AggregationData?.aggrTotalPriceKsh,
    //           aggr_payment_method: AggregationData?.aggrPaymentMethod,
    //           crop_id: AggregationData?.crop?.id,
    //           variety_id: AggregationData?.variety?.id,
    //         }}
    //         onSubmit={(values, action) => {
    //           SetIsSubmitting(true);
    //           Props.Variant === "Add" &&
    //             AddAggregationActivity(
    //               {
    //                 farm_id: Props.farm_id,
    //                 aggrPaymentMethod: values.aggr_payment_method!,
    //                 aggrQuantityKg: values.aggr_quantity_kg!,
    //                 aggrStatus: values.aggr_status!,
    //                 aggrTotalPriceKsh: values.aggr_total_price_ksh!,
    //                 day: values.day!,
    //                 month: values.month!,
    //                 year: values.year!,
    //                 variety_id: values.variety_id,
    //                 crop_id: values.crop_id!,
    //               },
    //               (error, resp) => {
    //                 if (error) {
    //                   console.log(error);
    //                 } else if (resp) {
    //                   SetIsSubmitting(false);
    //                   Props.OnAdded();
    //                 }
    //               }
    //             );
    //           Props.Variant === "Update" &&
    //             UpdateAggregationActivity(
    //               {
    //                 activity_id: Props.Activity_Id,
    //                 aggrPaymentMethod: values.aggr_payment_method!,
    //                 aggrQuantityKg: values.aggr_quantity_kg!,
    //                 aggrStatus: values.aggr_status!,
    //                 aggrTotalPriceKsh: values.aggr_total_price_ksh!,
    //                 day: values.day!,
    //                 month: values.month!,
    //                 year: values.year!,
    //                 farm_id: Props.farm_id,
    //                 variety_id: values.variety_id,
    //                 crop_id: values.crop_id!,
    //               },
    //               (error, resp) => {
    //                 if (error) {
    //                   console.log(error.message);
    //                 } else if (resp) {
    //                   SetIsSubmitting(false);
    //                   Props.OnUpdate();
    //                 }
    //               }
    //             );
    //         }}
    //         enableReinitialize
    //         validateOnBlur={false}
    //         validateOnChange={false}
    //         validationSchema={ValidationSchema}
    //       >
    //         {(FormikProps) => (
    //           <form onSubmit={FormikProps.handleSubmit}>
    //             <Grid container spacing={2}>
    //               {FieldsArray.map((item, index) => (
    //                 <Grid item key={index} xs={item.GridProps.xs as GridSize}>
    //                   {item.type === "text" ? (
    //                     <TextField
    //                       label={item.label}
    //                       name={item.name}
    //                       placeholder={item.placeholder}
    //                       variant="outlined"
    //                       size="small"
    //                       fullWidth
    //                       autoFocus={index === 0}
    //                       onChange={FormikProps.handleChange}
    //                       value={(FormikProps as any).values[item.name]}
    //                       helperText={(FormikProps as any).errors[item.name]}
    //                       error={Boolean(
    //                         (FormikProps as any).errors[item.name]
    //                       )}
    //                       disabled={item.disabled}
    //                     />
    //                   ) : item.type == "menu" ? (
    //                     <SelectMenu
    //                       helperText={(FormikProps as any).errors[item.name]}
    //                       error={Boolean(
    //                         (FormikProps as any).errors[item.name]
    //                       )}
    //                       value={(FormikProps as any).values[item.name]}
    //                       options={item.options!}
    //                       Placeholder={item.placeholder}
    //                       OnChange={(e) => {
    //                         item.OnChange?.(e.target.value as any);
    //                         if (item.name == "crop_id")
    //                           FormikProps.setFieldValue(
    //                             "variety_id",
    //                             undefined
    //                           );
    //                         FormikProps.setFieldValue(
    //                           item.name,
    //                           e.target.value
    //                         );
    //                       }}
    //                       Searchable={false}
    //                       disabled={item.disabled}
    //                     />
    //                   ) : null}
    //                 </Grid>
    //               ))}
    //             </Grid>
    //             <button
    //               type="submit"
    //               id="aggregation_add_update_submit_button"
    //               style={{ display: "none" }}
    //             />
    //           </form>
    //         )}
    //       </Formik>
    //     </Box>
    //   )}
    // </CustomDialog>
  );
};

export default AddUpdateAggregationActivity;
