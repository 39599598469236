import { AxiosError } from "axios";
import { AxiosInstance } from "../../configs/axios";
import { IUser } from "../../models/user";

interface Params extends Pick<IUser, "id" | "email" | "name" | "menuroles" | "phone" | "password"> {
  provider_id?: string;
}
type UpdateUserType = (Params: Params, callback: (error: null | AxiosError, resp: null | IUser) => any) => any;
const UpdateUser: UpdateUserType = (Params, callback) => {
  AxiosInstance.patch("/users/" + Params.id, { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { UpdateUser };
