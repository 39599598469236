import { Box } from "@mui/material";
import * as React from "react";
import { IUser } from "models/user";
import CertificationTable from "components/data-tables/certification-table/certification-table";

interface CertificationsTabBoxProps {
  farmer_id: string;
  user_role?: IUser["menuroles"];
}

const CertificationsTabBox: React.FunctionComponent<CertificationsTabBoxProps> =
  ({ farmer_id, user_role }) => {
    return (
      <Box>
        <CertificationTable farmer_id={farmer_id} user_role={user_role} />
      </Box>
    );
  };

export default CertificationsTabBox;
