import { GetMessagesStatus } from "api/message/get-messages-status";
import { CustomMessagesStatusTableProps } from "components/custom-compnents/custom-messages-status-table/custom-messages-status-table";
import { Fields } from "components/custom-compnents/custom-messages-status-table/custom-messages-status-table.schema";
import React from "react";

export interface IParams {
  OnStatusClicked?: CustomMessagesStatusTableProps["onStatusClick"];
}
export const useLogic = (params: IParams) => {
  const [data, setData] = React.useState<Fields[]>([]);

  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const [columnsState, setColumnsState] = React.useState([
    { field: "provider", checked: true },
    { field: "queued", checked: true },
    { field: "accepted", checked: true },
    { field: "sent", checked: true },
    { field: "failed", checked: true },
  ]);

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetMessagesStatus(
      {
        page: currentPage,
        pageSize: perPage,
      },
      (error, resp) => {
        if (error) console.log(error.message);
        else if (resp) {
          setIsLoading(false);

          setTotalCount(resp.totalCount);
          setData(
            resp.msgs.map((item: any) => ({
              queued: item.sms_status.queued,
              accepted: item.sms_status.accepted,
              sent: item.sms_status.sent,
              failed: item.sms_status.failed,
              provider: item.provider_name,
              provider_id: item.provider_id,
              selected: false,
            }))
          );
          // resolve({
          //   page: 0,
          //   totalCount: resp.msgs.length,
          //    ) as any,
          // });
        }
      }
    );
  }, [perPage, currentPage, dataUpdateIndex]);

  const tableProps: CustomMessagesStatusTableProps = {
    data,
    countLabelProps: {
      count: totalCount,
    },
    actionBarProps: {
      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
      isShowColumnButton: true,
    },
    isLoading: isLoading,
    onStatusClick: params.OnStatusClicked,
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };

  return { tableProps };
};
