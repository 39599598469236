import { ConvertQueriesToUrl } from "utils/convert-queries-to-url";
import { useNavigate } from "react-router-dom";
import { useQuery } from "hooks/use-query";
import { Paper, useMediaQuery, useTheme } from "@mui/material";
import { BoxProps } from "@mui/system";
import { useAppSelector } from "store/hooks";
import { useDispatch } from "react-redux";
import Data from "configs/routes";
import { useRouteutils } from "hooks/routes-hook";
import ability from "configs/ability";
import { RouteItem } from "classes/route-item";
import { SideMenuListProps } from "components/custom-compnents/side-menu-list/side-menu-list";
import { closeDrawer } from "store/reducers/app/app-slice";

export const useLogic = () => {
  const SideBarProps: BoxProps = {
    minWidth: 300,
    height: "100%",
    maxWidth: 300,
    component: Paper,
    borderRadius: 0,
    position: "fixed",
  };
  const theme = useTheme();

  const isLargeOrLess = useMediaQuery(theme.breakpoints.down("lg"));

  const isDrawerOpen = useAppSelector((root) => root.appReducer.isDrawerOpen);

  const dispatcher = useDispatch();

  const navigate = useNavigate();

  const { IsPartOfPath, RedirectToPath } = useRouteutils();

  const queries = useQuery();

  const querySideMenuItems: string | null = queries.get(
    "SelectedSideMenuItems"
  );

  const SelectedItems = querySideMenuItems?.split(",") || [];

  const handleClose = () => dispatcher(closeDrawer());

  const preparedData: SideMenuListProps["data"] = Data.Lists.filter(
    (item) => !item.hideInMenu && ability().can("see", new RouteItem(item))
  ).map((item, index) => ({
    itemData: {
      isActive: IsPartOfPath([item.path]),
      title: item.title,
      icon: item.icon,
      iconActive: item.iconActive,
      hideArrow: item.isLink,
      isOpenedArrow: Boolean(
        SelectedItems.find((QueryItem) => item.name === QueryItem)
      ),
      href: item.isLink ? item.path : undefined,
      onClick: () => {
        if (!item.isLink) {
          navigate(
            ConvertQueriesToUrl({
              pathname: "",
              queries: [
                {
                  key: "SelectedSideMenuItems",
                  value: SelectedItems?.find((UrlItem) => UrlItem === item.name)
                    ? SelectedItems.filter(
                        (UrlItem) => UrlItem !== item.name
                      ).join(",")
                    : SelectedItems?.concat(item.name).join(",") || item.name,
                },
              ].filter((item) => item.value),
            })
          );
        } else {
          handleClose();
        }
      },
    },
    subMenuData: {
      data:
        item.subList
          ?.filter(
            (subListItem) =>
              !subListItem.hideInMenu &&
              ability().can("see", new RouteItem(subListItem))
          )
          .map((subListItem) => ({
            onClick: () => {
              RedirectToPath([item.path, subListItem.path]);
              handleClose();
            },
            title: subListItem.title,
            isActive: IsPartOfPath([item.path, subListItem.path]),
            href: [item.path, subListItem.path].join("/"),
          })) || [],
    },
    showSubMenu: Boolean(
      SelectedItems.find((QueryItem) => item.name === QueryItem)
    ),
  }));

  return {
    preparedData,
    isDrawerOpen,
    SideBarProps,
    isLargeOrLess,
    handleClose,
  };
};
