import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { ISenderId } from "../../models/sender-id";
interface Params {
  page: number;
  pageSize: number;
  provider_id?: string;
  id?: string;
  sender_type?: 'Africastalking_sender' | 'Infobip_sender';
  status?: boolean;
  at_sender_id?: string;
}
interface Resp {
  allSenders: {
    totalCount: number;
    nodes: ISenderId[];
  };
}

type GetSenderIdsType = (Params: Params, callback: (error: null | AxiosError, Resp: null | Resp) => any) => any;
const GetSenderIds: GetSenderIdsType = (Params, callback) => {

  AxiosInstance.get("/senders", { params: Params })
    .then((resp) => callback(null, {
      allSenders: {
        nodes: resp.data.all_senders.nodes.map((item: any) => ({
          id: item.id,
          senderType: item.sender_type,
          status: item.status,
          atSenderId: item.at_sender_id,
          phoneNumber: item.phone_number,
          providerId: item.provider_id,
          userId: item.userId,
          atAuthKey: item.at_auth_key,
          atUsername: item.at_username,
          baseUrl: item.base_url,
          infobipAuthKey: item.infobip_auth_key,
          is_shared: item.is_shared
        })),
        totalCount: resp.data.all_senders.total_count,
      }
    }))
    .catch((error) => callback(error, null));
};

export { GetSenderIds };
