import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Certificate } from "../../models/certificate";

interface Resp {}
interface Params extends Omit<Certificate, "id"> {}

type CreateCertificateType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const CreateCertificate: CreateCertificateType = (Params, callback) => {
  AxiosInstance.post("/certificates", { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { CreateCertificate };
