import { AddWhatsappMessageTemplate } from "api/whatsapp-templates/add";
import { findAll } from "api/whatsapp-templates/findAll";
import { useFormik } from "formik";
import { languages } from "mocks/whatsapp-template-languages";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { validationSchema } from "./add-update-whatsapp-template.validation";

export interface IAdd {
  variant: "add";
  onAdded?: () => any;
}

export interface IUpdate {
  variant: "update";
  onUpdated?: () => any;
  id: string;
}

export interface IBase {
  open?: boolean;
}

export type IParams = IBase & (IAdd | IUpdate);

export const useLogic = (params: IParams) => {
  const user = useAppSelector((root) => root.appReducer.user);

  const [variableInput, setvariableInput] = useState("");

  const languageOptions = languages;

  const getVariables = (value: string) => (value ? Array.from(value.matchAll(/{{([^{}]+)}}/g), (x) => x[0]) : []);

  const form = useFormik({
    initialValues: { name: "", provider_id: "", access_level: "none", language: "none", category: "MARKETING", body: "" },
    onSubmit: (values, actions) => {
      if (params.variant === "add") {
        actions.setSubmitting(true);
        AddWhatsappMessageTemplate({ ...values, whatsapp_body: values.body, status: "PENDING", template_type: "whatsapp_template", provider_id: values.provider_id || undefined } as any)
          .then((resp) => {
            actions.resetForm();
            actions.setSubmitting(false);
            params.onAdded?.();
          })
          .catch(() => actions.setSubmitting(false))
          .finally(() => actions.setSubmitting(false));
      }
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const removeVariableFromBody = (variable: string) => {
    const regex = new RegExp("{{" + variable + "}}");
    form.setFieldValue("body", form.values["body"].replace(regex, ""));
  };

  const templateBodyVariables = getVariables(form.values["body"]).map((item) => item.replace("{{", "").replace("}}", ""));

  const insertVariableToTemplate = () => {
    const index = (document.getElementById("body") as any)?.selectionEnd || 0;
    const template = form.values["body"];
    form.setFieldValue("body", template.slice(0, index) + "{{" + variableInput + "}}" + template.slice(index));
    setvariableInput("");
  };

  useEffect(() => {
    if (params.variant === "update")
      findAll(
        {
          page: 0,
          pageSize: 10,
          id: params.id || undefined,
          // provider_id: user?.provider_id!,
        },
        (error, resp) => {
          const value = resp?.items[0];

          form.setFieldValue("access_level", value?.access_level);
          form.setFieldValue("name", value?.name);
          form.setFieldValue("language", value?.language);
          form.setFieldValue("category", value?.category);
          form.setFieldValue("body", value?.whatsapp_body);
          form.setFieldValue("provider_id", value?.provider_id);
        }
      );
  }, [params.variant === "update" && params.id]);

  return { languageOptions, form, templateBodyVariables, removeVariableFromBody, variableInput, insertVariableToTemplate, setvariableInput, user };
};
