import { AppBar, Box, Divider, IconButton, Toolbar } from "@mui/material";
import AccountButton from "../../custom-compnents/account-button/account-button";
import BreadcrumbComponent from "./breadcrumb-component/breadcrumb-component";
import { MdMenu } from "react-icons/md";
import { APP_BAR_HEIGHT } from "constants/app-bar";
import useStyles from "./header.style";
import { useLogic } from "./header.logic";
import { IoClose, IoSwapHorizontalSharp } from "react-icons/io5";
import { Scrollbars } from "react-custom-scrollbars";
import EwalletBalance from "components/standalone-components/ewallet-balance/ewallet-balance";

interface HeaderProps {}

const Header: React.FunctionComponent<HeaderProps> = () => {
  const { isLargeOrLessScreen, HandleSettingItemClick, Logout, user, handleOpenDrawer, isMedOrLessScreen, isShowBreadcrumbToolbarForSmallDevices, handleCloseBreadcrumbToolbar, handleOpenBreadcrumbToolbar } = useLogic();

  const classes = useStyles({
    disableHeaderToolbarGutter: isMedOrLessScreen,
  })();

  return (
    <Box height={APP_BAR_HEIGHT}>
      <AppBar
        elevation={1}
        color="inherit"
        style={{
          minWidth: !isLargeOrLessScreen ? "calc(100% - 300px)" : undefined,
          maxWidth: !isLargeOrLessScreen ? "calc(100% - 300px)" : undefined,
        }}
        className={classes.appBarRoot}
      >
        {/* show breadcrumb toolbar on smaller devices */}
        {isMedOrLessScreen && isShowBreadcrumbToolbarForSmallDevices && (
          <Box className={`${classes.mobileBreadcrumbWrapper}`}>
            <Scrollbars>
              <Toolbar className={`${classes.breadcrumbComponentToolbar}`}>
                <BreadcrumbComponent />
                <IconButton onClick={handleCloseBreadcrumbToolbar}>
                  <IoClose />
                </IconButton>
              </Toolbar>
            </Scrollbars>
          </Box>
        )}

        <Toolbar className={classes.toolbarRoot}>
          <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={1}>
              {isLargeOrLessScreen && (
                <IconButton onClick={handleOpenDrawer}>
                  <MdMenu />
                </IconButton>
              )}
              {isMedOrLessScreen && (
                <IconButton onClick={handleOpenBreadcrumbToolbar}>
                  <IoSwapHorizontalSharp />
                </IconButton>
              )}
              {!isMedOrLessScreen && <BreadcrumbComponent />}
            </Box>
            <Box display="flex" alignItems="center">
              {/* {user?.menuroles !== "user" && (
                <Box px={2}>
                  <EwalletBalance hideLabel={isMedOrLessScreen} />
                </Box>
              )} */}

              {user && (
                <Box className={classes.userAreaWrapperRoot}>
                  <Divider orientation="vertical" className={classes.dividerRoot} />
                  <AccountButton name={user.name} OnLogoutItemClick={Logout} OnSettingItemClick={HandleSettingItemClick} />
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
