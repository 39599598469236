import { GetFarmActivites } from "api/activities/get-farm-activites";
import React from "react";
import { Activity } from "models/activity";
import { CustomFarmsActivitesTableProps } from "components/custom-compnents/custom-farms-activites-table/custom-farms-activites-table";
import { Fields } from "components/custom-compnents/custom-farms-activites-table/custom-farms-activities-table.schema";
import { useNavigate } from "react-router-dom";
import { FarmsActivitiesTableProps } from "./farms-activities-table";

interface ILogicParams {
  Farm_Id?: string;
  ActionsToExclude?: FarmsActivitiesTableProps["ActionsToExclude"];
}
export const useLogic = (params: ILogicParams) => {
  const { Farm_Id } = params;

  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [data, setData] = React.useState<Activity[]>([]);
  const [isLoadingData, setIsLoadingData] = React.useState(false);
  const [showFilterArea, setShowFilterArea] = React.useState(false);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);

  const [filterIndex, setFilterIndex] = React.useState<undefined | number>(undefined);
  const [columnsState, setColumnsState] = React.useState([
    { checked: true, field: "activity" },
    { checked: true, field: "date" },
  ]);
  const [OpenAddAggregationModal, SetOpenAddAggregationModal] = React.useState(false);

  const [activityFilterItems, setactivityFilterItems] = React.useState([
    {
      label: "Planting",
      name: "planting",
      checked: true,
    },
    {
      label: "Harvesting",
      name: "harvesting",
      checked: true,
    },
    {
      label: "Fertilizer Application",
      name: "fertilizer_application",
      checked: true,
    },
    {
      label: "Aggregation",
      name: "aggregation",
      checked: true,
    },
  ]);

  const [ActivityToShowDetail, SetActivityToShowDetail] = React.useState<Activity | undefined>(undefined);

  const navigate = useNavigate();

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const preparedActivitiesFilter = activityFilterItems.filter((item) => item.checked).map((item) => item.name);

  React.useEffect(() => {
    setIsLoadingData(true);

    GetFarmActivites(
      {
        page: currentPage,
        pageSize: perPage,
        activity_list: preparedActivitiesFilter,
        with_crop: true,
        with_variety: true,
        with_providers: true,
        farm_id: Farm_Id
      },
      (error, resp) => {
        setIsLoadingData(false);
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setTotalCount(resp.total);
          setData(resp.items);
        }
      }
    );
  }, [Farm_Id, perPage, currentPage, filterIndex, dataUpdateIndex]);

  const handleApplyFilterClick = () => {
    const indexer = typeof filterIndex === "number" ? filterIndex + 1 : 0;
    setFilterIndex(indexer);
    if (preparedActivitiesFilter.length === 0) {
      setShowFilterArea(false);
    }
    setCurrentPage(0);
  };

  const resetFilters = () => {
    setactivityFilterItems(activityFilterItems.map((item) => ({ ...item, checked: false })));
  };

  const handleCancelFilterClick = () => {
    setFilterIndex(undefined);
    resetFilters();
    setShowFilterArea(false);
  };

  const handleAddEventButtonClick = () => {
    SetOpenAddAggregationModal(true);
  };

  const handleActivityFilterChange = (index: number) => {
    const item = activityFilterItems[index];
    const copyArray = activityFilterItems.map((item) => item);
    copyArray[index] = { ...item, checked: !item.checked };

    setactivityFilterItems(copyArray);
  };

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const HandleAdded = () => {
    SetOpenAddAggregationModal(false);
    refreshData();
  };

  const handleNextPageClick = () => {
    if (totalCount >= perPage * (currentPage + 1)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPageButtonClick = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  const HandleCancelAddAggreagationActivity = () => SetOpenAddAggregationModal(false);

  const handleOnChange = (field: string, checked: boolean) => {
    const FilterdArray = columnsState.filter((item) => item.field !== field);
    FilterdArray.push({ field, checked });
    setColumnsState(FilterdArray);
  };

  const handleFilterButtonClick = () => setShowFilterArea(true);

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  const handleViewButtonClick = (data: Fields) => {
    if (data.activity === "aggregation") {
      navigate(`/farms/${data.farmId}/aggregation_activities/${data.id}`);
    } else {
      SetActivityToShowDetail(data);
    }
  };

  const HandleClose = () => {
    SetActivityToShowDetail(undefined);
  };

  const tableProps: CustomFarmsActivitesTableProps = {
    onViewButtonClick: handleViewButtonClick,
    countLabelProps: {
      count: totalCount,
    },
    filterContainerProps: {
      CancelSuccessButtonGroupProps: {
        SuccessButtonProps: {
          onClick: handleApplyFilterClick,
        },
        CancelButtonProps: { onClick: handleCancelFilterClick },
      },
    },
    pagnationProps: {
      from: perPage & totalCount,
      onNextPageClick: handleNextPageClick,
      total: totalCount,
      onRowPerPageChange: handlePerPageChange,
      onPreviousPageClick: handlePreviousPageButtonClick,
      rowsPerPage: perPage,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
    },
    showFilterArea,
    data: preparedData,
    isLoading: isLoadingData,
    filterFormProps: {
      activitiesMenuFilterProps: {
        onItemClick: handleActivityFilterChange,
        Items: activityFilterItems,
      },
    },
    actionBarProps: {
      columnsState: columnsState,
      isShowColumnButton: true,
      isShowCreateButton: !params.ActionsToExclude?.find((item) => item === "add"),
      isShowFilterButton: true,

      filterButtonProps: {
        onClick: handleFilterButtonClick,
      },
      onColumnsStateChange: handleOnChange,
      addButtonProps: {
        onClick: handleAddEventButtonClick,
      },
    },
  };

  return {
    tableProps,
    OpenAddAggregationModal,
    HandleAdded,
    HandleCancelAddAggreagationActivity,
    ActivityToShowDetail,
    HandleClose,
  };
};
