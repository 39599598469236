import { Grid } from "@mui/material";
import CheckBoxItemsMenu, { CheckBoxItemsMenuProps } from "components/custom-compnents/check-box-items-menu/check-box-items.menu";
import CustomBaseInput, { CustomBaseInputProps } from "components/custom-compnents/custom-base-input/custom-base-input";
import CustomMenuButton from "components/custom-compnents/custom-menu-button/custom-menu-button";
import InputWithOperator from "components/input-components/input-with-operator/input-with-operator";
import { InputWithOperatorProps } from "components/input-components/input-with-operator/input-with-operator";

import * as React from "react";

export interface FarmsActivitesTableFilterFormProps {
  activitiesMenuFilterProps?: Pick<CheckBoxItemsMenuProps, "Items" | "onItemClick">;
}

const FarmsActivitesTableFilterForm: React.FunctionComponent<FarmsActivitesTableFilterFormProps> = (props) => {
  const { activitiesMenuFilterProps } = props;

  const [ActivitiesMenuAnchorEl, setActivitesMenuAnchorEl] = React.useState();

  const handleActivitiesMenuOpen = (e: any) => setActivitesMenuAnchorEl(e.currentTarget);

  const handleActivitiesMenuClose = () => setActivitesMenuAnchorEl(undefined);

  const FieldsData = [
    {
      label: "Activity Filter",
      type: "check-menu",
      props: activitiesMenuFilterProps,
      MenuProps: activitiesMenuFilterProps,
    },
  ];

  return (
    <Grid container spacing={2}>
      {FieldsData.map(({ type, ...item }, index) => (
        <Grid item lg={2} xs={12} key={index}>
          {type === "check-menu" && item.MenuProps && (
            <>
              <CheckBoxItemsMenu {...item.MenuProps} onClose={handleActivitiesMenuClose} headerLabel="Activities" open={Boolean(ActivitiesMenuAnchorEl)} anchorEl={ActivitiesMenuAnchorEl} />
              <CustomMenuButton onClick={handleActivitiesMenuOpen}>{item.label}</CustomMenuButton>
            </>
          )}

          {type === "number-operation" && <InputWithOperator placeholder={item.label} fullWidth {...(item.props as InputWithOperatorProps)} />}
          {type === "text" && <CustomBaseInput placeholder={item.label} fullWidth {...(item.props as CustomBaseInputProps)} />}
        </Grid>
      ))}
    </Grid>
  );
};

export default FarmsActivitesTableFilterForm;
