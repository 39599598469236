import { connect } from 'react-redux'

const FarmersAnalyticsCropsChartsState = (
    state = {
        ByType: 'count',
        SelectedCrops: [],
        Data: [
            ['Task', 'Hours per Day'],
            ['Work', 11],
            ['Eat', 2],
            ['Commute', 2],
            ['Watch TV', 2],
            ['Sleep', 7],
        ],
    },
    action
) => {
    return action.type === 'setFarmersAnalyticsCropsChartsState' ? { ...state, ...action.payload } : state
}

const ProvideFarmersAnalyticsCropsChartsStateTo = (Component, ProvideState = false) => {
    const CropsChartsStateToProps = (state) => {
        return ProvideState ? { FarmersAnalyticsCropsChartsState: state.FarmersAnalyticsCropsChartsState } : {}
    }

    const CropsChartsDispatchToProps = (dispatch) => {
        return {
            SetFarmersAnalyticsCropsChartsState: (value) =>
                dispatch({ type: 'setFarmersAnalyticsCropsChartsState', payload: value }),
        }
    }

    return connect(CropsChartsStateToProps, CropsChartsDispatchToProps)(Component)
}

export { ProvideFarmersAnalyticsCropsChartsStateTo }
export default FarmersAnalyticsCropsChartsState
