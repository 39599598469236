import { Box, Grid } from "@mui/material";
import React from "react";
import AgroMeteogram from "components/standalone-components/agro-meteogram/agro-meteogram";
import TempreturePrecipitation14DayForcast from "../../standalone-components/tempreture-precipitation-14-day-forcast/tempreture-precipitation-14-day-forcast";
import TempretureAndPrecipitation from "../../standalone-components/tempreture-and-precipitation-chart/Tempreture-And-Precipitation-And-Solid-moisture-Chart";

export interface WeatherAnalysisScreenProps {
  show?: boolean;
  location: {
    latitude: number | undefined;
    longitude: number | undefined;
  };
}

const WeatherAnalysisScreen: React.SFC<WeatherAnalysisScreenProps> = ({
  show,
  location,
}) => {
  return (
    <Box hidden={!show}>
      <Grid container spacing={5}>
        <Grid md={12} xs={12} item>
          <TempretureAndPrecipitation
            lat={location.latitude as number}
            lng={location.longitude as number}
          />
        </Grid>
        <Grid item xs={12}>
          <TempreturePrecipitation14DayForcast
            lat={location.latitude as number}
            lng={location.longitude as number}
          />
        </Grid>
        <Grid xl={6} xs={12} item>
          <AgroMeteogram location={location} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WeatherAnalysisScreen;
