// import { Box, Paper } from "@mui/material";
// import MaterialTable from "material-table";
import React from "react";
// import { DeleteBroadcastsMessages } from "../../../api/broadcasts-messages/delete-broadcasts-message";
// import AddUpdateBroadcastMessage from "components/standalone-components/add-update-broadcast-message/add-update-broadcast-message";
// import CustomDialog from "components/custom-compnents/custom-dialog/custom-dialog";
import { Actions, Schema } from "./schema";
import CustomBroadcastMessagesTable from "components/custom-compnents/custom-broadcast-messages-table/custom-broadcast-messages-table";
import { useLogic } from "./broadcasts-messages-table.logic";
import AddUpdateBroadcastMessage from "components/standalone-components/add-update-broadcast-message/add-update-broadcast-message";
import RemoveBroadcastMessageComponent from "components/standalone-components/remove-broadcast-message-component/remove-broadcast-message-component";

interface BroadcastsMessagesTableProps {
  broadcast_id?: string;
  ActionsToExclude?: Actions[];
}
const TableRef = React.createRef<any>();

const BroadcastsMessagesTable: React.FunctionComponent<BroadcastsMessagesTableProps> =
  ({ broadcast_id, ActionsToExclude }) => {
    // const [ShowAddMessageDialog, SetShowAddMessageDialog] =
    //   React.useState(false);
    // const HandleCancelAddNewMessage = () => SetShowAddMessageDialog(false);
    // const HandleAddMessageButtonClicked = () => SetShowAddMessageDialog(true);
    // const HandleAddedMessage = () => {
    //   SetShowAddMessageDialog(false);
    //   TableRef?.current?.onQueryChange();
    // };
    // const HandleDeleteButtonClicked = (id: string) => {
    //   SetMesssageInfoToBeDeleted(id);
    //   SetOpenDeleteModal(true);
    // };
    // const [OpenDeleteModal, SetOpenDeleteModal] = React.useState(false);
    // const [IsDeleteSubmitting, SetIsDeleteSubmitting] = React.useState(false);
    // const [MessageInfoToBeDeleted, SetMesssageInfoToBeDeleted] =
    //   React.useState<string | undefined>(undefined);

    // const [MessageToBeUpdated, SetMessageToBeUpdated] =
    //   React.useState<string | undefined>(undefined);
    // const [ShowUpdateMessageDialog, SetShowUpdateMessageDialog] =
    //   React.useState(false);
    // const HandleCancelUpdateMessage = () => {
    //   SetShowUpdateMessageDialog(false);
    //   SetMessageToBeUpdated(undefined);
    // };
    // const HandleUpdatedMessage = () => {
    //   SetShowUpdateMessageDialog(false);
    //   SetMessageToBeUpdated(undefined);
    //   TableRef?.current?.onQueryChange();
    // };
    // const HandleUpdateButtonClicked = (id: string) => {
    //   SetMessageToBeUpdated(id);
    //   SetShowUpdateMessageDialog(true);
    // };
    // const HandleDeleteConfirm = () => {
    //   SetIsDeleteSubmitting(true);
    //   MessageInfoToBeDeleted &&
    //     DeleteBroadcastsMessages(
    //       { id: MessageInfoToBeDeleted },
    //       (error, resp) => {
    //         if (error) {
    //           console.log(error);
    //         } else if (resp) {
    //           SetIsDeleteSubmitting(false);
    //           SetOpenDeleteModal(false);
    //           TableRef?.current?.onQueryChange();
    //         }
    //       }
    //     );
    // };

    const {
      tableProps,
      addMessageDialogProps,
      updateMessageDilaogProps,
      removeMessageDialogProps,
    } = useLogic({ broadcast_id });

    return (
      <>
        <RemoveBroadcastMessageComponent {...removeMessageDialogProps} />
        {broadcast_id && (
          <AddUpdateBroadcastMessage {...updateMessageDilaogProps} />
        )}

        {broadcast_id && (
          <AddUpdateBroadcastMessage {...addMessageDialogProps} />
        )}
        <CustomBroadcastMessagesTable {...tableProps} ActionsToExclude={ActionsToExclude} />
      </>
      // <Box component={Paper} p={2}>
      //   <CustomDialog
      //     open={OpenDeleteModal}
      //     ActionButtonsProps={{
      //       ButtonsVariant: "ConfirmIgnore",
      //       DisableConfirm: IsDeleteSubmitting,
      //       OnIgnore: () => {
      //         SetMesssageInfoToBeDeleted(undefined);
      //         SetOpenDeleteModal(false);
      //       },
      //       OnConfirm: HandleDeleteConfirm,
      //     }}
      //     Header="Delete Broadcast Message"
      //   >
      //     Are You Sure You Want To Remove This Message?
      //   </CustomDialog>
      //   {broadcast_id && (
      //     <AddUpdateBroadcastMessage
      //       Variant="Add"
      //       broadcast_id={broadcast_id as any}
      //       open={ShowAddMessageDialog}
      //       onCancel={HandleCancelAddNewMessage}
      //       onAdded={HandleAddedMessage}
      //     />
      //   )}
      //   {MessageToBeUpdated && (
      //     <AddUpdateBroadcastMessage
      //       Variant="Update"
      //       broadcast_id={broadcast_id as any}
      //       open={ShowUpdateMessageDialog}
      //       onCancel={HandleCancelUpdateMessage}
      //       onUpdated={HandleUpdatedMessage}
      //       message_id={MessageToBeUpdated}
      //     />
      //   )}
      //   <MaterialTable
      //     tableRef={TableRef}
      //     {...Schema({
      //       OnUpdateButtonClicked: HandleUpdateButtonClicked,
      //       OnDeleteButtonClicked: HandleDeleteButtonClicked,
      //       broadcast_id,
      //       AddMessageButtonClicked: HandleAddMessageButtonClicked,
      //       ActionsToExclude: ActionsToExclude,
      //     })}
      //   />
      // </Box>
    );
  };

export default BroadcastsMessagesTable;
