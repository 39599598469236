import MaterialTable from "@material-table/core";
import CustomPagnation from "components/custom-compnents/custom-pagnation/custom-pagnation";
import { Fields, Params, schema } from "./custom-callbacks-table.schema";
import { Box, Grid } from "@mui/material";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import { CustomPagnationProps } from "components/custom-compnents/custom-pagnation/custom-pagnation";
import CustomFiltersContainer, {
  CustomFiltersContainerProps,
} from "../custom-filters-container/custom-filters-container";
import CustomCallbacksTableActionBar, {
  CustomCallbacksTableActionBarProps,
} from "../custom-callbacks-table-action-bar/custom-callbacks-table-action-bar";
import CallbacksTableFilterForm, {
  CallbacksTableFilterFormProps,
} from "components/forms/callbacks-table-filter-form/callbacks-table-filter-form";
// import FarmsActivitesTableFilterForm, {
//   FarmsActivitesTableFilterFormProps,
// } from "components/forms/farms-activites-table-filter-form/farms-activites-table-filter-form";

export interface CustomCallbacksTableProps {
  countLabelProps?: CountLabelProps;
  showFilterArea?: boolean;
  filterContainerProps?: CustomFiltersContainerProps;
  //   filterFormProps?: FarmsActivitesTableFilterFormProps;
  pagnationProps?: CustomPagnationProps;
  actionBarProps?: CustomCallbacksTableActionBarProps;
  filterFormProps?: CallbacksTableFilterFormProps;
  data: Fields[];
  isLoading?: boolean;
  customSelection?: Params["customSelection"];
  onOrderChange?: (
    orderBy: number,
    orderDirection: "desc" | "asc"
  ) => void | undefined;
  onEditButtonClick?: Params["onEditButtonClick"];
}

const CustomCallbacksTable: React.FunctionComponent<CustomCallbacksTableProps> =
  (props) => {
    const {
      countLabelProps,
      showFilterArea,
      pagnationProps,
      filterContainerProps,
      filterFormProps,
      actionBarProps,
      data,
      isLoading,
      customSelection,
      onOrderChange,
      onEditButtonClick,
    } = props;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ paddingLeft: 0 }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingLeft: 0 }}
          >
            <Grid item md={3} xs={12}>
              <CountLabel {...countLabelProps} label="All Callbacks" />
            </Grid>
            <Grid item md={9} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Box width={840} maxWidth="100%">
                  <CustomCallbacksTableActionBar {...actionBarProps} />
                </Box>
              </Box>
            </Grid>
            {showFilterArea && (
              <Grid item xs={12}>
                <CustomFiltersContainer {...filterContainerProps}>
                  <CallbacksTableFilterForm {...filterFormProps} />
                  {/* <FarmsActivitesTableFilterForm {...filterFormProps} /> */}
                </CustomFiltersContainer>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            {...schema({
              customSelection,
              data,
              columnState: actionBarProps?.columnsState,
              onEditButtonClick,
            })}
            isLoading={isLoading}
            onOrderChange={onOrderChange}
          />
        </Grid>
        <Grid item xs={12}>
          {pagnationProps && <CustomPagnation {...pagnationProps} />}
        </Grid>
      </Grid>
    );
  };

export default CustomCallbacksTable;
