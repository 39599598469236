import MaterialTable from "@material-table/core";
import CustomPagnation from "components/custom-compnents/custom-pagnation/custom-pagnation";
import { Fields, Params, schema } from "./custom-farmers-table.schema";
import { Box, Grid } from "@mui/material";
import CustomFarmersTableActionBar, {
  CustomFarmersTableActionBarProps,
} from "components/custom-compnents/custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import { CustomPagnationProps } from "components/custom-compnents/custom-pagnation/custom-pagnation";

import CustomFiltersContainer, {
  CustomFiltersContainerProps,
} from "../custom-filters-container/custom-filters-container";
import FarmersTableFilterForm, {
  FarmersTableFilterFormProps,
} from "components/forms/farmers-table-filter-form/farmers-table-filter-form";
import DynamicForm from "../dynamic-form/dynamic-form";
import { LocationFilterProps } from "components/input-components/location-filter/location-filter";
import { useState } from "react";

export interface CustomFarmersTableProps {
  countLabelProps?: CountLabelProps;
  showFilterArea?: boolean;
  filterContainerProps?: CustomFiltersContainerProps;
  filterFormProps?: FarmersTableFilterFormProps;
  pagnationProps?: CustomPagnationProps;
  actionBarProps?: CustomFarmersTableActionBarProps;
  data: Fields[];
  isLoading?: boolean;
  customSelection?: Params["customSelection"];
  onOrderChange?: (
    orderBy: number,
    orderDirection: "desc" | "asc"
  ) => void | undefined;
}

const CustomFarmersTable: React.FunctionComponent<CustomFarmersTableProps> = (
  props
) => {
  const {
    countLabelProps,
    showFilterArea,
    pagnationProps,
    filterContainerProps,
    filterFormProps,
    actionBarProps,
    data,
    isLoading,
    customSelection,
    onOrderChange,
  } = props;

  const [locationFilterMenuAnchorEl, setlocationFilterMenuAnchorEl] =
    useState<(EventTarget & HTMLDivElement) | undefined>(undefined);

  const LocationFilterProps: Pick<
    LocationFilterProps,
    "menuProps" | "onChange" | "data-cy"
  > = {
    menuProps: {
      open: Boolean(locationFilterMenuAnchorEl),
      onChildrenClick: (e) => setlocationFilterMenuAnchorEl(e.currentTarget),
      anchorEl: locationFilterMenuAnchorEl,
    },
    onChange: (value: any) => {
      filterFormProps?.onLocationFilterChange?.(value);
      setlocationFilterMenuAnchorEl(undefined);
    },
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ paddingLeft: 0 }}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingLeft: 0 }}
        >
          <Grid item md={2} xs={12}>
            <CountLabel {...countLabelProps} />
          </Grid>
          <Grid item md={10} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Box width="100%">
                <CustomFarmersTableActionBar {...actionBarProps} />
              </Box>
            </Box>
          </Grid>
          {showFilterArea && (
            <Grid item xs={12}>
              <CustomFiltersContainer {...filterContainerProps}>
                <Box px={2}>
                  <DynamicForm
                    type="bar"
                    schema={[
                      {
                        inputProps: {
                          type: "text",
                          placeholder: "First Name",
                          value:
                            filterFormProps?.farmerFirstNameFilterProps?.value,
                          onChange:
                            filterFormProps?.farmerFirstNameFilterProps
                              ?.onChange,
                          disabled:
                            filterFormProps?.farmerFirstNameFilterProps
                              ?.disabled,
                          "data-cy":
                            filterFormProps?.farmerFirstNameFilterProps?.[
                              "data-cy"
                            ],
                        },
                      },
                      {
                        inputProps: {
                          type: "text",
                          placeholder: "Last Name",
                          value:
                            filterFormProps?.farmerLastNameFilterProps?.value,
                          onChange:
                            filterFormProps?.farmerLastNameFilterProps
                              ?.onChange,
                          disabled:
                            filterFormProps?.farmerLastNameFilterProps
                              ?.disabled,
                          "data-cy":
                            filterFormProps?.farmerLastNameFilterProps?.[
                              "data-cy"
                            ],
                        },
                      },
                      {
                        inputProps: {
                          type: "chips",
                          autoCompleteProps:
                            filterFormProps?.farmerPhoneFilterProps
                              ?.autoCompleteProps!,
                          onAdd: filterFormProps?.farmerPhoneFilterProps?.onAdd,
                          chipInputProps:
                            filterFormProps?.farmerPhoneFilterProps
                              ?.chipInputProps,
                          RightButtonProps:
                            filterFormProps?.farmerPhoneFilterProps
                              ?.RightButtonProps,
                          textFieldProps: {
                            ...filterFormProps?.farmerPhoneFilterProps
                              ?.textFieldProps,
                            placeholder: "Phone",
                          },
                        },
                      },
                      {
                        inputProps: {
                          type: "location",
                          label: "Location",
                          ...LocationFilterProps,
                        },
                      },
                      {
                        inputProps: {
                          type: "operator-input",
                          ...filterFormProps?.farmsCountFilterProps,
                          placeholder: "Farms Count",
                        },
                      },
                      {
                        inputProps: {
                          type: "chips",
                          textFieldProps: {
                            placeholder: "Crops",
                            ...filterFormProps?.cropFilterProps?.textFieldProps,
                          },
                          autoCompleteProps:
                            filterFormProps?.cropFilterProps
                              ?.autoCompleteProps!,
                          ...filterFormProps?.cropFilterProps,
                        },
                      },
                      {
                        inputProps: {
                          type: "label",
                          value: filterFormProps?.labelsFilterProps?.value!,
                          "data-cy":
                            filterFormProps?.labelsFilterProps?.["data-cy"],
                          onSubmit:
                            filterFormProps?.labelsFilterProps?.onSubmit,
                        },
                      },
                    ]}
                  />
                  {/* <FarmersTableFilterForm {...filterFormProps} /> */}
                </Box>
              </CustomFiltersContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          {...schema({
            customSelection,
            data,
            columnState: actionBarProps?.columnsState,
          })}
          isLoading={isLoading}
          onOrderChange={onOrderChange}
        />
      </Grid>
      <Grid item xs={12}>
        {pagnationProps && <CustomPagnation {...pagnationProps} />}
      </Grid>
    </Grid>
  );
};

export default CustomFarmersTable;
