import * as React from "react";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import uniqBy from "lodash/uniqBy";
import { GetRegions } from "api/location/regions";
import { Region } from "models/region";

export interface RegionInputProps {
  onChange?: (value: Array<Region>) => void;
  options: Array<Region>;
  OnOptionsChange?: (value: Array<Region>) => void;
  value: Array<Region>;
}

const RegionInput: React.SFC<RegionInputProps> = ({
  onChange,
  options,
  OnOptionsChange,
  value,
}) => {
  const [loading, SetLoading] = React.useState(true);

  const uniqOptions: Array<Region> = uniqBy(options, "id");

  React.useEffect(() => HandleInputChange({}), []); // get data for first time

  const HandleInputChange = (e: any) => {
    OnOptionsChange && OnOptionsChange([]);
    SetLoading(true);

    GetRegions(
      {
        page: 0,
        pageSize: 100,
        is_attached_to_farmers: true,
        region_id_list:
          value && value.length ? value.map((v: Region) => v.id) : undefined,
      },
      (error, resp) => {
        SetLoading(false);
        if (error) {
          console.log(error.message);
        } else {
          OnOptionsChange && OnOptionsChange(resp?.allRegions.items!);
        }
      }
    );
  };

  return (
    <Autocomplete
      onChange={(e, value) => onChange && onChange(value)}
      multiple
      value={value}
      loading={loading}
      options={uniqOptions}
      getOptionLabel={(option) => option?.name}
      fullWidth
      style={{ minWidth: 300 }}
      onInputChange={HandleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Region"
          data-cy="farmers-location-filter-region-select"
        />
      )}
      size="small"
    />
  );
};

export default RegionInput;
