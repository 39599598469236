import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { SurveyParticipants } from "models/survey-participants";

type Resp = {
  success: string;
  statusCode: number;
  data: { items: SurveyParticipants[] };
};
interface Params {
  survey_id: string;
  users_id_list: string[];
}
type AddSurveyParticipantsType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: Resp | null) => any
) => any;
const AddSurveyParticipants: AddSurveyParticipantsType = (Params, callback) => {
  AxiosInstance.post(`/surveys/participants/`, Params)
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { AddSurveyParticipants };
