import { GetSurveys } from "api/survey/get-survey";
import { RestartSurvey } from "api/survey/restart-survey";
import { CustomSurveysTableProps } from "components/custom-compnents/custom-surveys-table/custom-surveys-table";
import { CombinedType } from "components/standalone-components/add-update-survey/add-update-survey";
import { RestartSurveyProps } from "components/standalone-components/restart-survey/restart-survey";
import { Survey } from "models/survey";
import React, { useEffect } from "react";
import { useAppSelector } from "store/hooks";

export const useLogic = () => {
  const [isOpenAddSurveyDialog, setIsOpenAddSurveyDialog] = React.useState(false);
  const [isOpenRestartSurveysDialog, setIsOpenRestartSurveysDialog] = React.useState(false);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [data, setData] = React.useState<Survey[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [columnsState, setColumnsState] = React.useState([
    { field: "title", checked: true },
    { field: "jump_code", checked: true },
    { field: "provider", checked: true },
    { field: "start_date", checked: true },
    { field: "expiry_date", checked: true },
    { field: "active", checked: true },
  ]);

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const HandleAdded = () => {
    setIsOpenAddSurveyDialog(false);
    refreshData();
  };
  const HandleCanceledAdd = () => {
    setIsOpenAddSurveyDialog(false);
  };
  const HandleAddClicked = () => setIsOpenAddSurveyDialog(true);

  const HandleRestartConfirm = () => {
    // TODO: restart here
    RestartSurvey((error, resp) => {
      if (error) {
        console.log(error, 'error')
      } else {
        console.log('success')
      }
    })
    setIsOpenRestartSurveysDialog(false);
  };
  const HandleRestartCanceled = () => {
    setIsOpenRestartSurveysDialog(false);
  };
  const HandleRestartClicked = () => setIsOpenRestartSurveysDialog(true);

  useEffect(() => {
    setIsLoading(true);
    GetSurveys({ page: currentPage, pageSize: perPage }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        setData(resp.items);
        setTotalCount(resp.total);
        setIsLoading(false);
      }
    });
  }, [perPage, currentPage, dataUpdateIndex]);

  useEffect(() => {

  }, [isOpenRestartSurveysDialog]);


  const user = useAppSelector((root) => root.appReducer.user);

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  const addSurveyDialogProps: CombinedType = {
    variant: "add",
    open: isOpenAddSurveyDialog,
    onAdded: HandleAdded,
    onCancel: HandleCanceledAdd,
  };

  const restartSurveysDialogProps: RestartSurveyProps = {
    open: isOpenRestartSurveysDialog,
    onAdded: HandleRestartConfirm,
    onCancel: HandleRestartCanceled,
  };


  const tableProps: CustomSurveysTableProps = {
    showViewAction: user?.menuroles !== "user",
    isLoading: isLoading,
    data: preparedData,
    countLabelProps: {
      count: totalCount,
    },
    actionBarProps: {
      addButtonProps: {
        onClick: HandleAddClicked,
      },
      restartButtonProps: {
        onClick: HandleRestartClicked,
      },
      isShowCreateButton: user?.menuroles !== "user",
      isShowRestartButton: user?.menuroles === "admin",
      isShowColumnButton: true,
      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
    },
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };
  return { tableProps, addSurveyDialogProps, restartSurveysDialogProps };
};
