import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
interface Params {
  id: string;
}
interface Resp {}
type DeleteFarmerLabelKeyType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: Resp | null) => any
) => any;
const DeleteFarmerLabelKey: DeleteFarmerLabelKeyType = (Params, callback) => {
  AxiosInstance.delete(`/farmer-label-keys/${Params.id}`)
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => callback(error, null));
};

export { DeleteFarmerLabelKey };
