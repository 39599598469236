import { Box } from "@mui/material";
import useStyles from "./footer.style";

interface FooterProps {
  title?: string;
}

const Footer: React.FunctionComponent<FooterProps> = ({ title }) => {
  const classes = useStyles();
  return <Box className={classes.root}>{title}</Box>;
};

export default Footer;

Footer.defaultProps = {
  title: `© agriBORA ${new Date().getFullYear()}. All rights reserved.`,
};
