import { Grid } from "@mui/material";
import CheckBoxItemsMenu, { CheckBoxItemsMenuProps } from "components/custom-compnents/check-box-items-menu/check-box-items.menu";
import CustomMenuButton from "components/custom-compnents/custom-menu-button/custom-menu-button";
import CustomTextField, { CustomTextFieldProps } from "components/custom-compnents/custom-text-field/custom-text-field";
import InputWithOperator from "components/input-components/input-with-operator/input-with-operator";
import { InputWithOperatorProps } from "components/input-components/input-with-operator/input-with-operator";

import * as React from "react";

export interface MessagesDetailTableFilterFormProps {
  providersMenuFilterProps?: Pick<CheckBoxItemsMenuProps, "Items" | "onItemClick">;
  statusMenuFilterProps?: Pick<CheckBoxItemsMenuProps, "Items" | "onItemClick">;
  archiveMenuFilterProps?: Pick<CheckBoxItemsMenuProps, "Items" | "onItemClick">;
  messageTextFilterProps?: CustomTextFieldProps;
}

const MessagesDetailTableFilterForm: React.FunctionComponent<MessagesDetailTableFilterFormProps> = (props) => {
  const { providersMenuFilterProps, statusMenuFilterProps, messageTextFilterProps, archiveMenuFilterProps } = props;

  const [providersMenuAnchorEl, setprovidersMenuAnchorEl] = React.useState();
  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = React.useState();
  const [archiveMenuAnchorEl, setArchiveMenuAnchorEl] = React.useState();

  const handleProvidersMenuOpen = (e: any) => setprovidersMenuAnchorEl(e.currentTarget);

  const handleStatusMenuOpen = (e: any) => setStatusMenuAnchorEl(e.currentTarget);

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorEl(undefined);
  };

  const handleArchiveMenuClose = () => setArchiveMenuAnchorEl(undefined);

  const handleArchiveMenuOpen = (e: any) => setArchiveMenuAnchorEl(e.currentTarget);

  const handleProvidersMenuClose = () => setprovidersMenuAnchorEl(undefined);

  const FieldsData = [
    {
      label: "Organization",
      type: "check-menu",
      props: providersMenuFilterProps,
      MenuProps: providersMenuFilterProps,
      open: Boolean(providersMenuAnchorEl),
      onClose: handleProvidersMenuClose,
      onOpen: handleProvidersMenuOpen,
      anchorEl: providersMenuAnchorEl,
    },
    {
      label: "Send Status",
      type: "check-menu",
      props: statusMenuFilterProps,
      MenuProps: statusMenuFilterProps,
      open: Boolean(statusMenuAnchorEl),
      onClose: handleStatusMenuClose,
      onOpen: handleStatusMenuOpen,
      anchorEl: statusMenuAnchorEl,
    },
    {
      label: "Archive Status",
      type: "check-menu",
      props: archiveMenuFilterProps,
      MenuProps: archiveMenuFilterProps,
      open: Boolean(archiveMenuAnchorEl),
      onClose: handleArchiveMenuClose,
      onOpen: handleArchiveMenuOpen,
      anchorEl: archiveMenuAnchorEl,
    },
    {
      props: messageTextFilterProps,
      label: "Message Text",
      type: "text",
    },
  ];

  return (
    <Grid container spacing={2}>
      {FieldsData.map(({ type, ...item }, index) => (
        <Grid item lg={2} xs={12} key={index}>
          {type === "check-menu" && item.MenuProps && (
            <>
              <CheckBoxItemsMenu {...item.MenuProps} headerLabel={item.label} onClose={item.onClose} open={item.open} anchorEl={item.anchorEl} />
              <CustomMenuButton onClick={item.onOpen}>{item.label}</CustomMenuButton>
            </>
          )}

          {type === "number-operation" && <InputWithOperator placeholder={item.label} fullWidth {...(item.props as InputWithOperatorProps)} />}
          {type === "text" && <CustomTextField placeholder={item.label} fullWidth {...(item.props as CustomTextFieldProps)} />}
        </Grid>
      ))}
    </Grid>
  );
};

export default MessagesDetailTableFilterForm;
