import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";


export interface BulkFarmer {
  phone: string;
  first_name: string;
  last_name: string;
  ward_id?: string;
  birth_year?: string | number;
  gender?: string;
}
export interface InsertBulkFarmersParams {
  farmers: BulkFarmer[]
}

type AddFarmerType = (
  Params: BulkFarmer,
  callback: (error: AxiosError | null, resp: null | any) => any
) => any;
const AddFarmers: AddFarmerType = (Params, callback) => {
  AxiosInstance.post("/farmers", {
    ...Params
  })
    .then((resp) => callback(null, resp))
    .catch((error) => callback(error, null));
};

export { AddFarmers };
