import { Box, Button, Chip, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
import { IoOpenOutline } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { IFarm } from "models/farm";
import { Link } from "react-router-dom";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
  selectedFarmersIds?: string[];
  selectedByPhone?: boolean;
}

export interface Fields extends IFarm {
  selected: boolean;  
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onViewButtonClick?: (data: Fields) => any;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (params) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "Name",
      field: "name",
      hidden: !params.columnState?.find((item) => item.field === "name")?.checked,
      render: (data) => data.name,
    },
    {
      title: "Current Crop",
      field: "date",
      hidden: !params.columnState?.find((item) => item.field === "current_crop")?.checked,
      render: (data) => (data.LastCropActivity ? data.LastCropActivity?.crop?.name : "-"),
    },
    {
      title: "Status",
      field: "status",
      hidden: !params.columnState?.find((item) => item.field === "status")?.checked,
      render: (data) => (data.ownedByFarmer ? <Chip variant="outlined" label="Owned By Farmer" /> : <Chip variant="outlined" color="error" label="Not Owned By Farmer" />),
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: <CustomCheckBox onChange={(e, value) => params.customSelection?.onSelectAllChange?.(params.data, value)} checked={params.data.every((item) => item.selected)} indeterminate={params.data.some((item) => item.selected) && !params.data.every((item) => item.selected)} />,
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => <CustomCheckBox checked={data.selected} onChange={(e, value) => params.customSelection?.onRowSelectChange?.(data, value)} />,
    });
  }

  return {
    actions: [
      (fields) => ({
        icon: () => (
          <Link to={`/farms/${fields.id}`}>
            <Button variant="text" startIcon={<IoOpenOutline />} onClick={() => params.onViewButtonClick?.(fields)} color="success">
              View
            </Button>
          </Link>
        ),
        onClick: () => null,
      }),
    ],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
