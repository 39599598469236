import {
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useStyles } from "./custom-info-card-with-icon.styles";
import { ReactNode } from "react";

interface CustomInfoCardWithIconProps {
  icon: any;
  actionValue?: string | ReactNode;
  primaryText?: string;
  titleText?: string;
  primaryTextTestId?: string;
}

const CustomInfoCardWithIcon: React.FunctionComponent<
  CustomInfoCardWithIconProps
> = (props) => {
  const { icon: Icon, actionValue, primaryText, titleText } = props;
  const classes = useStyles();

  return (
    <Box component={Paper}>
      <ListItem className={classes.root}>
        <ListItemAvatar className={`${classes.iconWrapper}`}>
          <Icon size={30} className={classes.iconRoot} />
        </ListItemAvatar>
        <Divider orientation="vertical" className={classes.dividerRoot} />
        <ListItemText
          disableTypography
          primary={
            <Box className={`${classes.primaryAreaRoot}`}>
              <Grid container>
                {titleText && (
                  <Grid item xs={12}>
                    <Typography className={`${classes.titleTextRoot}`}>
                      {titleText}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography
                    className={`${classes.primaryTextRoot}`}
                    data-cy={props.primaryTextTestId}
                  >
                    {primaryText}
                  </Typography>
                </Grid>
              </Grid>
              {typeof actionValue !== "string" ? (
                actionValue
              ) : (
                <Typography className={`${classes.actionTextRoot}`}>
                  {actionValue}
                </Typography>
              )}
            </Box>
          }
        />
      </ListItem>
    </Box>
  );
};

export default CustomInfoCardWithIcon;
