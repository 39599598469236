import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Params {
  title: string;
  jump_code: string;
  provider_id: string;
  start_time: string;
  expires_at: string;
  active: boolean;
  id: string;
}
interface Resp {}
type UpdateSurveyType = (Params: Params, callback: (error: AxiosError | null, resp: null | Resp) => any) => any;
const UpdateSurvey: UpdateSurveyType = (Params, callback) => {
  AxiosInstance.patch(`/surveys/${Params.id}`, { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => callback(error, null));
};

export { UpdateSurvey };
