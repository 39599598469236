import { AddFarmers, BulkFarmer } from "api/farmer/add-farmers";
import { useFormik } from "formik";
import React from "react";
import { validation } from "./add-upload-farmer.validation";
import { GetFarmers } from "api/farmer/get-farmer";
import { UpdateFarmerNameAndphoneApi } from "api/farmer/update-farmer";

export interface IAdd {
  OnFarmerAdded?: () => any;
  variant: "add";
}

export interface IUpdate {
  OnFarmerUpdated?: () => any;
  variant: "update";
  id: string;
}

export type IParams = IAdd | IUpdate;

export const useLogic = (props: IParams) => {
  const [IsSubmitting, SetIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (props.variant === "update") {
      GetFarmers({ page: 0, pageSize: 10, Ids: [props.id] }, (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          FormikData.setFieldValue("first_name", resp.Farmers[0].firstName);
          FormikData.setFieldValue("last_name", resp.Farmers[0].lastName);
          FormikData.setFieldValue("phone", resp.Farmers[0].phone);
          FormikData.setFieldValue("gender", resp.Farmers[0].gender);
          FormikData.setFieldValue("birth_year", resp.Farmers[0].birthYear);
        }
      });
    }
  }, [props.variant === "update" && props.id]);

  const FormikData = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validation,
    initialValues: {
      first_name: "",
      last_name: "",
      // name: "",
      phone: "",
      gender: undefined,
      birth_year: undefined,
    },
    onSubmit: (values, actions) => {
      SetIsSubmitting(true);
      if (props.variant === "add") {
        AddFarmers(
          {
            first_name: values.first_name,
            last_name: values.last_name,
            birth_year: values.birth_year ? parseInt(values.birth_year) : undefined,
            phone:
              values.phone.toString().indexOf("+") == 0
                ? values.phone
                : "+" + values.phone,
            gender: values.gender

          },
          (error, resp) => {
            SetIsSubmitting(false);
            if (error) {
              if (error.response?.data?.message) {
                actions.setErrors({
                  phone: error.response?.data.message,
                });
              }
            } else {
              props.OnFarmerAdded?.();
              FormikData.resetForm();
            }
          }
        );
      } else if (props.variant === "update") {
        UpdateFarmerNameAndphoneApi(
          props.id,
          {
            // farmer_name: FormikData.values["name"],
            firstName: FormikData.values["first_name"],
            lastName: FormikData.values["last_name"],
            farmer_phone:
              FormikData.values["phone"]?.[0] === "+"
                ? FormikData.values["phone"]
                : "+" + FormikData.values["phone"],
            // age_group: FormikData?.values["age_group"] as any,
            birthYear: FormikData.values["birth_year"],
            gender: FormikData?.values["gender"] as any,
          },
          (error, resp) => {
            SetIsSubmitting(false);
            if (error) {
              if (error.response?.status === 400) {
                if (error.response?.data?.message?.find((item: string) => item.indexOf('phone') > -1)) {
                  actions.setErrors({
                    phone: error.response?.data.message,
                  });
                }
              }
            }
            else {
              props.OnFarmerUpdated?.();
            }
          }
        );
      }
    },
  });

  const FormData = [
    // { name: "name", placeholder: "Farmer name", label: "Farmer Name" },
    {
      name: "first_name",
      placeholder: "Farmer First Name",
      label: "Farmer First Name",
      "data-cy": "create-update-farmer-first-name-input"
    },
    {
      name: "last_name",
      placeholder: "Farmer Last Name",
      label: "Farmer Last Name",
      "data-cy": "create-update-farmer-last-name-input"
    },
    {
      name: "phone",
      placeholder: "Farmer phone number",
      label: "Farmer Phone",
      "data-cy": "create-update-farmer-phone-number-input"
    },
    {
      name: "gender",
      options: [
        { title: "Male", value: "male", "data-cy": "create-update-farmer-gender-male-option" },
        { title: "Female", value: "female", "data-cy": "create-update-farmer-gender-female-option" },
      ],
      value: undefined,
      type: "select-buttons",
    },
    {
      name: "birth_year",
      label: "Farmer Birth Year",
      placeholder: "Farmer Birth Year",
      "data-cy": "create-update-farmer-birth-year-input"
    },
    // {
    //   name: "age_group",
    //   options: [
    //     { title: "<18", value: "<18" },
    //     { title: "18-35", value: "18-35" },
    //     { title: "36-65", value: "36-65" },
    //     { title: ">65", value: ">65" },
    //   ],
    //   value: undefined,
    //   type: "select-buttons",
    // },
  ];

  return { IsSubmitting, FormikData, FormData };
};
