import { ButtonProps, Container, Grid } from "@mui/material";
import AddRemoveColumnsMenu from "../check-box-items-menu/check-box-items.menu";
import CreateButton from "../create-button/create-button";
import CustomMenuButton from "../custom-menu-button/custom-menu-button";
import FiltersButton from "../filter-button/filter-button";
import * as React from "react";
import CustomSearchTextField from "../custom-search-text-field/custom-search-text-field";
import { CustomTextFieldProps } from "../custom-text-field/custom-text-field";
import ReloadButton from "../reload-button/reload-button";

export interface columnsState {
  field: string;
  checked: boolean;
}
export interface CustomSurveysTableActionBarProps {
  addButtonProps?: ButtonProps;
  restartButtonProps?: ButtonProps;
  importButtonProps?: ButtonProps;
  filterButtonProps?: ButtonProps;
  searchTextFieldProps?: CustomTextFieldProps;
  columnButtonProps?: ButtonProps;
  columnsState?: columnsState[];
  onColumnsStateChange?: (column: string, state: boolean) => any;
  isShowColumnButton?: boolean;
  isShowFilterButton?: boolean;
  isShowImportButton?: boolean;
  isShowCreateButton?: boolean;
  isShowSearchTextField?: boolean;
  isShowRestartButton?: boolean;
}

const CustomSurveysTableActionBar: React.FunctionComponent<
  CustomSurveysTableActionBarProps
> = (props) => {
  const {
    addButtonProps,
    restartButtonProps,
    columnButtonProps,
    filterButtonProps,
    columnsState,
    onColumnsStateChange,
    isShowColumnButton,
    isShowCreateButton,
    isShowRestartButton,
    isShowFilterButton,
    searchTextFieldProps,
    isShowSearchTextField,
  } = props;

  const [columnsMenuAchorEl, setColumnsMenuAchorEl] = React.useState(undefined);
  const handleRemoveColumnsMenuClose = () => setColumnsMenuAchorEl(undefined);
  const handleColumnsButtonClick = (e: any) =>
    setColumnsMenuAchorEl(e.currentTarget);

  const ButtonsData = [
    {
      label: "Search ...",
      Component: CustomSearchTextField,
      props: searchTextFieldProps,
      isShowing: isShowSearchTextField,
    },
    {
      label: "Columns",
      Component: CustomMenuButton,
      props: columnButtonProps,
      isShowing: isShowColumnButton,
    },
    {
      label: "filters",
      Component: FiltersButton,
      props: filterButtonProps,
      isShowing: isShowFilterButton,
    },
    {
      label: "Add Survey",
      Component: CreateButton,
      props: addButtonProps,
      isShowing: isShowCreateButton,
    },
    {
      label: "Restart USSD",
      Component: ReloadButton,
      props: restartButtonProps,
      isShowing: isShowRestartButton,
    },
  ];

  return (
    <>
      <Container disableGutters>
        <AddRemoveColumnsMenu
          Items={
            columnsState?.map((item, index) => ({
              checked: item.checked,
              label: item.field,
            })) || []
          }
          onItemClick={(index) =>
            columnsState &&
            onColumnsStateChange &&
            onColumnsStateChange(
              columnsState[index].field,
              !columnsState[index].checked
            )
          }
          open={Boolean(columnsMenuAchorEl)}
          onClose={handleRemoveColumnsMenuClose}
          anchorEl={columnsMenuAchorEl}
        />
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          {ButtonsData.map(
            (item, index) =>
              item.isShowing && (
                <Grid item lg={3} md={6} xs={12}>
                  <item.Component
                    fullWidth
                    onClick={(e: any) => {
                      if (item.label === "Columns") {
                        handleColumnsButtonClick(e);
                      }
                    }}
                    {...(item.props as any)}
                  >
                    {item.label}
                  </item.Component>
                </Grid>
              )
          )}
        </Grid>
      </Container>
    </>
  );
};

export default CustomSurveysTableActionBar;
