import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import CancelDeleteButtonsGroup from "components/custom-compnents/cancel-delete-buttons-group/cancel-delete-buttons-group";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import { IoWarningSharp } from "react-icons/io5";
import { IParams, useLogic } from "./remove-whatsapp-template-dialog.logic";

export interface RemoveWhatsappTemplateDialogProps extends IParams {
  open?: boolean;
  onCancel?: () => any;
}

const RemoveWhatsappTemplateDialog: React.FunctionComponent<RemoveWhatsappTemplateDialogProps> = (props) => {
  const { open, onCancel, onDeleted, id } = props;
  const { isSubmittingDelete, handleDeleteConfirm } = useLogic({ onDeleted, id });

  return (
    <CustomDialogV2
      open={open ?? false}
      footer={
        <Box display="flex" justifyContent="flex-end">
          <CancelDeleteButtonsGroup
            RootGridProps={{ width: 300 }}
            CancelButtonProps={{
              label: "Cancel",
              onClick: onCancel,
            }}
            DeleteButtonProps={{
              label: "Delete",
              onClick: handleDeleteConfirm,
              disabled: isSubmittingDelete,
            }}
          />
        </Box>
      }
      header={
        <Typography variant="h6" display="inline-flex">
          <IoWarningSharp size={22} style={{ marginRight: 5 }} />
          Delete Whatsapp Template
        </Typography>
      }
    >
      <Typography variant="subtitle1">Are you sure you want to remove this Template?</Typography>
    </CustomDialogV2>
  );
};

export default RemoveWhatsappTemplateDialog;
