import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  FilterText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.64,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#5d6d6e",
    flexShrink: 0,
  },
});
