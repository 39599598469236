import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RegionStateItem {
  region_id: string;
  region: string;
}

export interface ConstituencyStateItem {
  constituency_id: string;
  constituency: string;
  county: string;
  county_id: string;
  region: string;
  region_id: string;
}

export interface CountyStateItem {
  region_id: string;
  county_id: string;
  county: string;
  region: string;
}

export interface WardsStateItem {
  region_id: string;
  county_id: string;
  constituency_id: string;
  region: string;
  county: string;
  constituency: string;
  ward: string;
  ward_id: string;
}
export interface CountiesFarmersCountObject {
  [name: string]: { totalCount: number };
}

export interface WardsFarmersCountObject {
  [name: string]: { totalCount: number };
}

export interface ConstituenciesFarmersCountObject {
  [name: string]: { totalCount: number };
}

// Define a type for the slice state
interface farmerAnalyticsMapState {
  FarmersTotalCount: number;

  LastClickedRegion: RegionStateItem | {};

  LastClickedCounty: CountyStateItem | {};

  LastClickedConstituency: ConstituencyStateItem | {};

  LastClickedWard: WardsStateItem | {};

  SelectedRegions: RegionStateItem[];

  SelectedCounties: CountyStateItem[];

  SelectedConstituencies: ConstituencyStateItem[];

  SelectedWards: WardsStateItem[];

  regionsFarmersCountObject: any;

  CountiesFarmersCountObject: CountiesFarmersCountObject;

  ConstituenciesFarmersCountObject: ConstituenciesFarmersCountObject;

  WardsFarmersCountObject: WardsFarmersCountObject;

  CountiesGeoJson: any;

  ConstituenciesGeoJson: any;

  WardsGeoJson: any;

  RegionsGeoJson: any;
}

const initialState: farmerAnalyticsMapState = {
  FarmersTotalCount: 0,
  //
  LastClickedRegion: {},
  LastClickedCounty: {},
  LastClickedConstituency: {},
  LastClickedWard: {},
  //
  SelectedRegions: [],
  SelectedCounties: [],
  SelectedConstituencies: [],
  SelectedWards: [],
  //
  regionsFarmersCountObject: {},
  CountiesFarmersCountObject: {},
  ConstituenciesFarmersCountObject: {},
  WardsFarmersCountObject: {},
  //
  CountiesGeoJson: undefined,
  ConstituenciesGeoJson: undefined,
  WardsGeoJson: undefined,
  RegionsGeoJson: undefined,
};

export const farmerAnalyticsMapReducer = createSlice({
  name: "farmer-analytics-map",
  initialState,
  reducers: {
    setSelectedRegions: (state, action: PayloadAction<RegionStateItem[]>) => {
      state.SelectedRegions = action.payload;
    },

    setSelectedCounties: (state, action: PayloadAction<CountyStateItem[]>) => {
      state.SelectedCounties = action.payload;
    },

    setSelectedConstituencies: (state, action: PayloadAction<ConstituencyStateItem[]>) => {
      state.SelectedConstituencies = action.payload;
    },

    setSelectedWards: (state, action: PayloadAction<WardsStateItem[]>) => {
      state.SelectedWards = action.payload;
    },

    setLastClickedRegion: (state, action: PayloadAction<RegionStateItem | {}>) => {
      state.LastClickedRegion = action.payload;
    },

    setLastClickedCounty: (state, action: PayloadAction<CountyStateItem | {}>) => {
      state.LastClickedCounty = action.payload;
    },

    setLastClickedConstituency: (state, action: PayloadAction<ConstituencyStateItem | {}>) => {
      state.LastClickedConstituency = action.payload;
    },

    setLastClickedWards: (state, action: PayloadAction<WardsStateItem | {}>) => {
      state.LastClickedWard = action.payload;
    },

    setFarmersTotalCount: (state, action: PayloadAction<number>) => {
      state.FarmersTotalCount = action.payload;
    },

    setRegionsFarmersCountObject: (state, action: PayloadAction<any>) => {
      state.regionsFarmersCountObject = action.payload;
    },

    setCountiesFarmersCountObject: (state, action: PayloadAction<any>) => {
      state.CountiesFarmersCountObject = action.payload;
    },

    setConstituenciesFarmersCountObject: (state, action: PayloadAction<any>) => {
      state.ConstituenciesFarmersCountObject = action.payload;
    },

    setWardsFarmersCountObject: (state, action: PayloadAction<any>) => {
      state.WardsFarmersCountObject = action.payload;
    },

    setRegionsGeoJson: (state, action: PayloadAction<any>) => {
      state.RegionsGeoJson = action.payload;
    },

    setCountiesGeoJson: (state, action: PayloadAction<any>) => {
      state.CountiesGeoJson = action.payload;
    },

    setConstituenciesGeoJson: (state, action: PayloadAction<any>) => {
      state.ConstituenciesGeoJson = action.payload;
    },

    setWardsGeoJson: (state, action: PayloadAction<any>) => {
      state.WardsGeoJson = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setConstituenciesFarmersCountObject,
  setConstituenciesGeoJson,
  setCountiesGeoJson,
  setCountiesFarmersCountObject,
  setFarmersTotalCount,
  setLastClickedConstituency,
  setLastClickedCounty,
  setLastClickedRegion,
  setLastClickedWards,
  setRegionsFarmersCountObject,
  setRegionsGeoJson,
  setSelectedConstituencies,
  setSelectedCounties,
  setSelectedRegions,
  setSelectedWards,
  setWardsFarmersCountObject,
  setWardsGeoJson,
} = farmerAnalyticsMapReducer.actions;

export default farmerAnalyticsMapReducer.reducer;
