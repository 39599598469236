import CustomServicesTable from "components/custom-compnents/custom-services-table/custom-services-table";
import * as React from "react";
import ConfirmChangeModal from "./confirm-change-modal";
import { useLogic } from "./services-table.logic";

export interface ServicesTableProps {}

const tableRef = React.createRef<any>();

const ServicesTable: React.SFC<ServicesTableProps> = () => {
  const { tableProps, confirmChangeDialogProps } = useLogic();
  return (
    <>
      <ConfirmChangeModal {...confirmChangeDialogProps} />
      <CustomServicesTable {...tableProps} />
    </>
  );
};

export default ServicesTable;
