import { GetFarmActivites } from "api/activities/get-farm-activites";
import { DeleteAggregationActivity } from "api/aggregation/delete-aggregation";
import { UpdateAggregationActivity } from "api/aggregation/update-aggregation";
import { GetCrop } from "api/crops/get-crops";
import { GetVariety } from "api/variety/get-variety";
import { CancelDeleteButtonsGroupProps } from "components/custom-compnents/cancel-delete-buttons-group/cancel-delete-buttons-group";
import { CancelSuccessButtonsGroupProps } from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import { DeleteEditButtonGroupProps } from "components/custom-compnents/delete-edit-button-group/delete-edit-button-group";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import * as yup from "yup";

interface IUseLogic {
  activityId?: string;
  farm_id?: string;
}

export const useLogic = ({ activityId, farm_id }: IUseLogic) => {
  const [isInEditMode, setIsInEditMode] = React.useState(false);
  const [isLoadingData, setIsLoadingData] = React.useState(false);
  const [OpenDeleteDialog, SetOpenDeleteDialog] = React.useState(false);
  const [IsSubmittingDelete, SetIsSubmittingDelete] = React.useState(false);
  const navigate = useNavigate();
  const ValidationSchema = yup.object().shape({
    aggr_total_price_ksh: yup.number().required().label("Price"),
    aggr_quantity_kg: yup.number().required().label("Quantity"),
    aggr_status: yup
      .object()
      .shape({
        value: yup
          .string()
          .required()
          .oneOf([
            "draft",
            "awaiting_quality_checks",
            "awaiting_payment",
            "done",
          ]),
      })

      .label("Status"),
    aggr_payment_method: yup
      .object()
      .shape({
        value: yup.string().required().oneOf(["mpesa", "cash"]),
      })

      .label("payment"),
    day: yup.string().required().min(1).max(2),
    month: yup.string().required().min(1).max(2),
    year: yup.string().required().min(4).max(4),
    crop: yup
      .object()
      .shape({
        value: yup.string().uuid().required(),
      })
      .label("crop"),
  });

  const [IsSubmitting, SetIsSubmitting] = React.useState(false);

  const handleDeleteButtonClick = () => SetOpenDeleteDialog(true);

  const HandleConfirmDelete = () => {
    SetIsSubmittingDelete(true);
    farm_id &&
      activityId &&
      DeleteAggregationActivity({ id: activityId }, (error, resp) => {
        if (error) {
          console.log(error.message);
        } else {
          SetIsSubmittingDelete(false);
          SetOpenDeleteDialog(false);
          navigate("/farms/" + farm_id);
        }
      });
  };
  const HandleCancelDelete = () => {
    SetOpenDeleteDialog(false);
  };

  const FormikForm = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidationSchema,
    initialValues: {
      month: "",
      day: "",
      year: "",
      aggr_quantity_kg: "",
      aggr_status: { label: "", value: "" },
      aggr_total_price_ksh: "",
      aggr_payment_method: { label: "", value: "" },
      crop: { label: "", value: "" },
      variety: { label: "", value: "" },
    },
    onSubmit: (values, action) => {
      SetIsSubmitting(true);

      farm_id &&
        UpdateAggregationActivity(
          {
            activity_id: activityId as any,
            aggr_payment_method: values.aggr_payment_method.value as any,
            aggr_quantity_kg: parseInt(values.aggr_quantity_kg!),
            aggr_status: values.aggr_status.value as any,
            aggr_total_price_ksh: parseInt(values.aggr_total_price_ksh!),
            day: parseInt(values.day!),
            month: parseInt(values.month!),
            year: parseInt(values.year!),
            farm_id,
            variety_id: values.variety.value,
            crop_id: values.crop.value,
            aggr_last_editor: ""
          },
          (error, resp) => {
            if (error) {
              console.log(error.message);
            } else if (resp) {
              SetIsSubmitting(false);
              setIsInEditMode(false);
            }
          }
        );
    },
  });

  React.useEffect(() => {
    GetCrop({
      page: 0,
      pageSize: 10,
    }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        SetCropsOptions(
          resp.items.map((item, index) => ({
            value: item.id,
            label: item.name,
          }))
        );
      }
    });
  }, []);

  const [CropOptions, SetCropsOptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  const [VarietyOptions, SetVarietyOptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  const [isVariteyLoading, setIsVariteyLoading] = React.useState(false);

  //update varitey when crop change
  React.useEffect(() => {
    const CropNewValue = FormikForm.values["crop"].value;
    if (FormikForm.values["crop"].value) {
      setIsVariteyLoading(true);
      GetVariety({ page: 0, pageSize: 10, crop_id: CropNewValue }, (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setIsVariteyLoading(false);
          //   if (resp.items.length === 0) SetVarietyMenuLabel(NoResultStatment);
          //   if (resp.items.length > 0) SetVarietyMenuLabel(ReadyToSelectStatment);
          SetVarietyOptions(
            resp.items.map((item) => ({ value: item.id, label: item.name }))
          );
        }
      });
    }
  }, [FormikForm.values["crop"].value]);

  // get aggreagion data & fill it in the form
  React.useEffect(() => {
    setIsLoadingData(true);
    activityId &&
      GetFarmActivites(
        { page: 0, pageSize: 10, id: activityId, with_crop: true, with_variety: true },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            setIsLoadingData(false);
            FormikForm.setValues({
              day: resp.items[0].day.toString(),
              month: resp.items[0].month.toString(),
              year: resp.items[0].year.toString(),
              aggr_payment_method: {
                label: resp.items[0]?.aggrPaymentMethod?.toUpperCase(),
                value: resp.items[0]?.aggrPaymentMethod,
              },
              aggr_quantity_kg: resp.items[0].aggrQuantityKg as any,
              aggr_status: {
                label: resp.items[0]?.aggrStatus?.toUpperCase(),
                value: resp.items[0]?.aggrStatus,
              },
              crop: {
                label: resp.items[0].crop?.name as string,
                value: resp.items[0].crop?.id as string,
              },
              aggr_total_price_ksh: resp.items[0]?.aggrTotalPriceKsh?.toString(),
              variety: {
                label: resp.items[0].variety?.name as string,
                value: resp.items[0].variety?.id as string,
              },
            });
          }
        }
      );
  }, [activityId]);

  const paymentMethodMenuItems = [
    { label: "Cash", value: "cash" },
    { label: "Mpesa ", value: "mpesa" },
  ];
  const statusMenuItems = [
    { label: "Draft", value: "draft" },
    {
      label: "Awaiting Quality Checks",
      value: "awaiting_quality_checks",
    },
    {
      label: "Awaiting Payment",
      value: "awaiting_payment",
    },
    { label: "Done", value: "done" },
  ];

  const cropDetailsData = [
    {
      label: "Crop",
      type: "menu",
      value: FormikForm.values["crop"].label,
      helperText: FormikForm.errors["crop"]?.value,
      error: Boolean(FormikForm.errors["crop"]?.value),
      menuData: CropOptions?.map((item) => ({
        ...item,
        onClick: () => FormikForm.setFieldValue("crop", item),
      })),
    },
    {
      label: "Variety",
      helperText: FormikForm.errors.variety?.value,
      error: Boolean(FormikForm.errors.variety?.value),
      type: "menu",
      disabledLabel: "Select crop first to select variety",
      emptyLabel: "No Result Found",
      isEmpty: VarietyOptions.length === 0,
      loadingLabel: "Loading ...",
      value: FormikForm.values["variety"].label,
      disabled: isVariteyLoading,
      loading: isVariteyLoading,
      menuData: VarietyOptions?.map((item) => ({
        ...item,
        onClick: () => FormikForm.setFieldValue("variety", item),
      })),
    },
    {
      label: "Quantity (Kg)",
      type: "text",
      value: FormikForm.values["aggr_quantity_kg"],
      onChange: (e: any) =>
        FormikForm.setFieldValue("aggr_quantity_kg", e.currentTarget.value),
      helperText: FormikForm.errors["aggr_quantity_kg"],
      error: Boolean(FormikForm.errors["aggr_quantity_kg"]),
    },
  ];

  const EventDetailsData = [
    {
      label: "Year",
      type: "text",
      helperText: FormikForm.errors["year"],
      error: Boolean(FormikForm.errors["year"]),
      gridProps: { xs: 4 },
      value: FormikForm.values["year"],
      onChange: (e: any) =>
        FormikForm.setFieldValue("year", e.currentTarget.value),
    },
    {
      label: "Month",
      type: "text",
      helperText: FormikForm.errors["month"],
      error: Boolean(FormikForm.errors["month"]),
      gridProps: { xs: 4 },
      value: FormikForm.values["month"],
      onChange: (e: any) =>
        FormikForm.setFieldValue("month", e.currentTarget.value),
    },
    {
      label: "Day",
      type: "text",
      helperText: FormikForm.errors["day"],
      error: Boolean(FormikForm.errors["day"]),
      gridProps: { xs: 4 },
      value: FormikForm.values["day"],
      onChange: (e: any) =>
        FormikForm.setFieldValue("day", e.currentTarget.value),
    },
    {
      label: "Total Price (KSh)",
      helperText: FormikForm.errors["aggr_total_price_ksh"],
      error: Boolean(FormikForm.errors["aggr_total_price_ksh"]),
      type: "text",
      gridProps: { xs: 12 },
      value: FormikForm.values["aggr_total_price_ksh"],
      onChange: (e: any) =>
        FormikForm.setFieldValue("aggr_total_price_ksh", e.currentTarget.value),
    },
    {
      label: "payment method",
      helperText: FormikForm.errors["aggr_payment_method"]?.value,
      error: Boolean(FormikForm.errors["aggr_payment_method"]?.value),
      type: "menu",
      gridProps: { xs: 12 },
      menuData: paymentMethodMenuItems?.map((item) => ({
        ...item,
        onClick: () => FormikForm.setFieldValue("aggr_payment_method", item),
      })),
      value: FormikForm.values["aggr_payment_method"].label,
    },
    {
      label: "Status",
      helperText: FormikForm.errors["aggr_status"]?.value,
      error: Boolean(FormikForm.errors["aggr_status"]?.value),
      type: "menu",
      gridProps: { xs: 12 },
      value: FormikForm.values["aggr_status"].label,
      menuData: statusMenuItems?.map((item) => ({
        ...item,
        onClick: () => FormikForm.setFieldValue("aggr_status", item),
      })),
    },
  ];

  const editDeleteButtonGroupProps: DeleteEditButtonGroupProps = {
    RootGridProps: { maxWidth: 300 },
    DeleteButtonProps: {
      label: "Delete",
      onClick: () => handleDeleteButtonClick(),
    },
    EditButtonProps: { label: "Edit", onClick: () => setIsInEditMode(true) },
  };

  const ConfirmCancelButtonGroupProps: CancelSuccessButtonsGroupProps = {
    RootGridProps: { maxWidth: 300 },
    CancelButtonProps: {
      label: "Cancel",
      onClick: () => setIsInEditMode(false),
    },
    SuccessButtonProps: {
      label: "Confirm",
      onClick: () => FormikForm.submitForm(),
      disabled: IsSubmitting,
    },
  };

  const deleteActionGroup: CancelDeleteButtonsGroupProps = {
    CancelButtonProps: {
      label: "Cancel",
      onClick: HandleCancelDelete,
    },
    DeleteButtonProps: {
      label: "Delete Activity",
      onClick: HandleConfirmDelete,
      disabled: IsSubmittingDelete,
    },
    RootGridProps: {
      justifyContent: "flex-end",
    },
  };

  const user = useAppSelector((root) => root.appReducer.user);

  const isUserhasPermissionToUpdateAndDeleteActivity =
    user?.menuroles === "admin" ||
    user?.menuroles === "manager" ||
    user?.menuroles === "org_admin";

  return {
    EventDetailsData,
    cropDetailsData,
    isInEditMode,
    editDeleteButtonGroupProps,
    isLoadingData,
    ConfirmCancelButtonGroupProps,
    OpenDeleteDialog,
    deleteActionGroup,
    isUserhasPermissionToUpdateAndDeleteActivity,
  };
};
