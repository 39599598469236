import { AutocompleteChangeReason } from "@mui/material";
import { findAll } from "api/whatsapp-templates/findAll";
import { MessageType } from "components/pages/send-message-screen/send-message-screen.logic";
import { useDebounce } from "hooks/debounce-hook";
import { IMessageTemplate } from "models/message-template";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";

export type Value = IMessageTemplate | IMessageTemplate[] | null;

export interface MessageTemplateInputProps {
  onChange?: (value: Value, reason: AutocompleteChangeReason) => any;
  placeholder?: string;
  fullwidth?: boolean;
  limitTags?: number;
  error?: boolean;
  helperText?: string;
  value?: Value;
  variant: MessageType;
}

export const useLogic = (params: MessageTemplateInputProps) => {
  const [loading, setloading] = useState(false);

  const user = useAppSelector((root) => root.appReducer.user);

  const [options, setoptions] = useState<IMessageTemplate[]>([]);

  const [searchTerm, setsearchTerm] = useState("");

  const debonucedTerm = useDebounce(searchTerm, 800);

  // get options
  useEffect(() => {
    setloading(true);

    findAll(
      {
        page: 0,
        pageSize: 10,
        // provider_id: user?.provider_id!,
        // created_by: parseInt(user?.id!),
        name: debonucedTerm,
        templateType: params.variant,
      },
      (error, resp) => {
        setloading(false);
        if (error) {
          console.log(error.message);
        } else {
          setoptions(resp?.items!);
        }
      }
    );
  }, [debonucedTerm, params.variant]);

  return { options, setsearchTerm, loading };
};
