import { Button, ButtonProps } from "@mui/material";
import { IoReloadCircle } from "react-icons/io5";

export interface ReloadButtonProps extends ButtonProps { }

const ReloadButton: React.FunctionComponent<ReloadButtonProps> = (props) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<IoReloadCircle />}
      {...props}
    />
  );
};

export default ReloadButton;

ReloadButton.defaultProps = {
  children: "Reload",
};
