import { AxiosError } from "axios";
import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";
interface Params {
  content: string;
  content_type: string;
  send_at?: string;
  sender_id: string;
  provider_id?: string;
  broadcast_message_id: null;
  template_id: null;
  phone_numbers: string[];
}
interface scheduled {
  datePickerData: string;
  // scheduled: 1;
}

interface nonscheduled {
  // scheduled: 0;
}
type AddMessageType = (
  Params: Params & (scheduled | nonscheduled),
  callback: (error: null | AxiosError, Resp: null | any) => any
) => any;
const AddMessage: AddMessageType = (Params, callback) => {
  const body = {
    ...cleanDeep(Params),
    // datePickerCheckbox: Params.scheduled,
    receivers: Params.phone_numbers.join(","),
  };
  AxiosInstance.post("/sms-messages", {
    ...body
  })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { AddMessage };
