import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { IFarmerLabelKey } from "models/farmer-label-key";

interface Params {
  provider_id: string;
  key: string;
}
interface Resp {}
type AddFarmerLabelKeyType = (
  Params: Params,
  callback: (error: AxiosError | null, resp: null | Resp) => any
) => any;
const AddFarmerLabelKey: AddFarmerLabelKeyType = (Params, callback) => {
  AxiosInstance.post("/farmer-label-keys", {
    key: Params.key,
    provider_id: Params.provider_id,
  })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => callback(error, null));
};

export { AddFarmerLabelKey };
