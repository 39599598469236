import { blue, grey, red } from "@mui/material/colors";
import moment from "moment";
import * as React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { GetDataset } from "../../../api/meteoblue/get-dataset";

export interface TempretureChartProps {
  lat: number;
  lng: number;
}

const TempretureChart: React.SFC<TempretureChartProps> = ({ lat, lng }) => {
  const [Era5Meantemperature, setEra5Meantemperature] = React.useState<
    { date: string; meantemperature: number }[]
  >([]);
  const [Era5Maxtemperature, setEra5Maxtemperature] = React.useState<
    { date: string; maxtemperature: number }[]
  >([]);
  const [Era5Mintemperature, setEra5Mintemperature] = React.useState<
    { date: string; mintemperature: number }[]
  >([]);
  const [ICONMeantemperature, setICONMeantemperature] = React.useState<
    { date: string; meantemperature: number }[]
  >([]);
  const [ICONMaxtemperature, setICONMaxtemperature] = React.useState<
    { date: string; maxtemperature: number }[]
  >([]);
  const [ICONMintemperature, setICONMintemperature] = React.useState<
    { date: string; mintemperature: number }[]
  >([]);

  const [GEM15Meantemperature, setGEM15Meantemperature] = React.useState<
    { date: string; meantemperature: number }[]
  >([]);
  const [GEM15Maxtemperature, setGEM15Maxtemperature] = React.useState<
    { date: string; maxtemperature: number }[]
  >([]);
  const [GEM15Mintemperature, setGEM15Mintemperature] = React.useState<
    { date: string; mintemperature: number }[]
  >([]);
  const CurrentDate = new Date(Date.now());
  const DateBeforeThreeYear = new Date(Date.now());
  DateBeforeThreeYear.setFullYear(DateBeforeThreeYear.getFullYear() - 2);
  React.useEffect(() => {
    GetDataset(
      {
        format: "json",
        timeIntervalsAlignment: "none",
        units: {
          energy: "watts",
          length: "metric",
          temperature: "C",
          velocity: "km/h",
        },
        geomtry: { type: "MultiPoint", coordinates: [[lat, lng, 279]] },
        models: [
          {
            domain: "ERA5T",
            gapFillDomain: null,
            timeResolution: "hourly",
            codes: [
              {
                code: 193,
                level: "2 m above gnd",
              },
            ],
            transformations: [
              {
                type: "aggregateMonthly",
                aggregation: "mean",
              },
            ],
          },
          {
            domain: "ERA5T",
            gapFillDomain: null,
            timeResolution: "hourly",
            codes: [
              {
                code: 193,
                level: "2 m above gnd",
              },
            ],
            transformations: [
              {
                type: "aggregateMonthly",
                aggregation: "min",
              },
            ],
          },
          {
            domain: "ERA5T",
            gapFillDomain: null,
            timeResolution: "hourly",
            codes: [
              {
                code: 193,
                level: "2 m above gnd",
              },
            ],
            transformations: [
              {
                type: "aggregateMonthly",
                aggregation: "max",
              },
            ],
          },
          {
            domain: "ICON",
            gapFillDomain: null,
            timeResolution: "hourly",
            codes: [
              {
                code: 193,
                level: "2 m above gnd",
              },
            ],
            transformations: [
              {
                type: "aggregateMonthly",
                aggregation: "mean",
              },
            ],
          },
          {
            domain: "ICON",
            gapFillDomain: null,
            timeResolution: "hourly",
            codes: [
              {
                code: 193,
                level: "2 m above gnd",
              },
            ],
            transformations: [
              {
                type: "aggregateMonthly",
                aggregation: "min",
              },
            ],
          },
          {
            domain: "ICON",
            gapFillDomain: null,
            timeResolution: "hourly",
            codes: [
              {
                code: 193,
                level: "2 m above gnd",
              },
            ],
            transformations: [
              {
                type: "aggregateMonthly",
                aggregation: "max",
              },
            ],
          },
          {
            domain: "GEM15",
            gapFillDomain: null,
            timeResolution: "hourly",
            codes: [
              {
                code: 11,
                level: "2 m above gnd",
              },
            ],
            transformations: [
              {
                type: "aggregateMonthly",
                aggregation: "mean",
              },
            ],
          },
          {
            domain: "GEM15",
            gapFillDomain: null,
            timeResolution: "hourly",
            codes: [
              {
                code: 11,
                level: "2 m above gnd",
              },
            ],
            transformations: [
              {
                type: "aggregateMonthly",
                aggregation: "min",
              },
            ],
          },
          {
            domain: "GEM15",
            gapFillDomain: null,
            timeResolution: "hourly",
            codes: [
              {
                code: 11,
                level: "2 m above gnd",
              },
            ],
            transformations: [
              {
                type: "aggregateMonthly",
                aggregation: "max",
              },
            ],
          },
        ],
        timeIntervals: [{ start: DateBeforeThreeYear, end: CurrentDate }],
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setEra5Meantemperature(
            resp.Data[0].codes[0].dataPerTimeInterval[0].data[0].map(
              (value, index) => ({
                meantemperature: value,
                date: resp.Data[0].timeIntervals[0][index],
              })
            )
          );
          setEra5Mintemperature(
            resp.Data[1].codes[0].dataPerTimeInterval[0].data[0].map(
              (value, index) => ({
                mintemperature: value,
                date: resp.Data[1].timeIntervals[0][index],
              })
            )
          );
          setEra5Maxtemperature(
            resp.Data[2].codes[0].dataPerTimeInterval[0].data[0].map(
              (value, index) => ({
                maxtemperature: value,
                date: resp.Data[2].timeIntervals[0][index],
              })
            )
          );
          //   ----------------
          setICONMeantemperature(
            resp.Data[3].codes[0].dataPerTimeInterval[0].data[0].map(
              (value, index) => ({
                meantemperature: value,
                date: resp.Data[3].timeIntervals[0][index],
              })
            )
          );
          setICONMintemperature(
            resp.Data[4].codes[0].dataPerTimeInterval[0].data[0].map(
              (value, index) => ({
                mintemperature: value,
                date: resp.Data[4].timeIntervals[0][index],
              })
            )
          );
          setICONMaxtemperature(
            resp.Data[5].codes[0].dataPerTimeInterval[0].data[0].map(
              (value, index) => ({
                maxtemperature: value,
                date: resp.Data[5].timeIntervals[0][index],
              })
            )
          );
          //   ----------------
          setGEM15Meantemperature(
            resp.Data[6].codes[0].dataPerTimeInterval[0].data[0].map(
              (value, index) => ({
                meantemperature: value,
                date: resp.Data[0].timeIntervals[0][index],
              })
            )
          );
          setGEM15Mintemperature(
            resp.Data[7].codes[0].dataPerTimeInterval[0].data[0].map(
              (value, index) => ({
                mintemperature: value,
                date: resp.Data[0].timeIntervals[0][index],
              })
            )
          );
          setGEM15Maxtemperature(
            resp.Data[8].codes[0].dataPerTimeInterval[0].data[0].map(
              (value, index) => ({
                maxtemperature: value,
                date: resp.Data[0].timeIntervals[0][index],
              })
            )
          );
        }
      }
    );
  }, []);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={Era5Maxtemperature.map((item) => ({
          Era5maxtemperature: item.maxtemperature?.toFixed(1),
          Era5mintemperature: Era5Mintemperature.find(
            (MinitemToFind) => item.date === MinitemToFind.date
          )?.mintemperature?.toFixed(1),
          Era5meantemperature: Era5Meantemperature.find(
            (MaxItemToFind) => item.date === MaxItemToFind.date
          )?.meantemperature?.toFixed(1),
          name: moment(item.date).format("MMM YYYY"),
          //--------------
          ICONmaxtemperature: ICONMaxtemperature.find(
            (MaxitemToFind) => item.date === MaxitemToFind.date
          )?.maxtemperature?.toFixed(1),
          ICONmeantemperature: ICONMeantemperature.find(
            (MeanitemToFind) => item.date === MeanitemToFind.date
          )?.meantemperature?.toFixed(1),
          ICONmintemperature: ICONMintemperature.find(
            (MinItemToFind) => item.date === MinItemToFind.date
          )?.mintemperature?.toFixed(1),
          //--------------
          GEM15maxtemperature: GEM15Maxtemperature.find(
            (MaxitemToFind) => item.date === MaxitemToFind.date
          )?.maxtemperature?.toFixed(1),
          GEM15meantemperature: GEM15Meantemperature.find(
            (MeanitemToFind) => item.date === MeanitemToFind.date
          )?.meantemperature?.toFixed(1),
          GEM15mintemperature: GEM15Mintemperature.find(
            (MinItemToFind) => item.date === MinItemToFind.date
          )?.mintemperature?.toFixed(1),
        }))}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          label={{
            dx: -10,
            angle: -90,
            position: "insideMiddleLeft",
            value: "Temperature (°C)",
          }}
        />
        <Tooltip />
        <Legend layout="vertical" verticalAlign="middle" align="right" />
        <Line
          type="monotone"
          name="ERA-5 Max"
          dataKey="Era5maxtemperature"
          stroke={red["400"]}
        />
        <Line
          type="monotone"
          name="ERA-5 Mean"
          dataKey="Era5meantemperature"
          stroke={grey["700"]}
        />
        <Line
          type="monotone"
          name="ERA-5 Min"
          dataKey="Era5mintemperature"
          stroke={blue["400"]}
        />
        <Line
          type="monotone"
          strokeDasharray="4 4 2"
          name="ICON Max"
          dataKey="ICONmaxtemperature"
          stroke={red["700"]}
        />
        <Line
          type="monotone"
          strokeDasharray="4 4 2"
          name="ICON Mean"
          dataKey="ICONmeantemperature"
          stroke={grey["700"]}
        />
        <Line
          type="monotone"
          strokeDasharray="4 4 2"
          name="ICON Min"
          dataKey="ICONmintemperature"
          stroke={blue["700"]}
        />
        <Line
          type="monotone"
          strokeDasharray="1 2 2"
          name="GEM15 Max"
          dataKey="GEM15maxtemperature"
          stroke={red["900"]}
        />
        <Line
          strokeDasharray="1 2 2"
          type="monotone"
          name="GEM15 Mean"
          dataKey="GEM15meantemperature"
          stroke={grey["900"]}
        />
        <Line
          strokeDasharray="1 2 2"
          type="monotone"
          name="GEM15 Min"
          dataKey="GEM15mintemperature"
          stroke={blue["900"]}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TempretureChart;
