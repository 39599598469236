import { Grid } from "@mui/material";
import React from "react";
import { statusType } from "components/data-tables/messages-detail-table/message-detail-table.logic";
import MessagesTable from "components/data-tables/messages-status-table/messages-status-table";

export interface MessagesOverviewScreenProps {}

const MessagesOverviewScreen: React.SFC<MessagesOverviewScreenProps> = () => {
  const HandleStatusClicked = (Provider: string, status: statusType) => {
    window.location.href = `/messaging/status/details?provider=${Provider}&status=${status}`;
  };
  return (
    <Grid container>
      <Grid item md={9} xs={12}>
        <MessagesTable OnStatusClicked={HandleStatusClicked} />
      </Grid>
    </Grid>
  );
};

export default MessagesOverviewScreen;
