import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { setBreadcrumbData } from "store/reducers/breadcrumb/breadcrumb";

export const UseResetBreadcrumb = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setBreadcrumbData([]));
  }, [location.pathname]);
};
