import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    borderTop: "solid 1px #dbdbdb",
    paddingTop: 16,
  },
});

export default useStyles;
