import { Box } from "@mui/material";
import { UseResetBreadcrumb } from "hooks/breadcrumb-reset-hook";
import React from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { GetUser } from "../../api/auth/user/get-user";
import { SetIsCheckingUser, setUser } from "../../store/reducers/app/app-slice";
import { RootState } from "../../store/store";
import Login from "../pages/authentication/login/login";
import Loading from "../pages/loading/loading";
import Footer from "./footer/footer";
import Header from "./header/header";
import SideBar from "./side-bar/side-bar";

interface LayoutProps {}

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const user = useSelector((state: RootState) => state.appReducer.user);

  UseResetBreadcrumb();

  const IsCheckingUser = useSelector(
    (state: RootState) => state.appReducer.IscheckingUser
  );
  const UserQuery = useQuery("user", GetUser);
  React.useEffect(() => {
    if (UserQuery.data?.data) {
      dispatcher(setUser(UserQuery.data.data));
      dispatcher(SetIsCheckingUser(false));
    }
  }, [UserQuery.data?.data.id]);
  const dispatcher = useDispatch();

  return (
    <>
      {user ? (
        <Box display="flex">
          <SideBar />
          <Box width="100%" overflow="hidden">
            <Header />
            <Box p={2}>
              {children}
              <Box pt={2}>
                <Footer />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : IsCheckingUser ? (
        <Loading />
      ) : (
        <Login />
      )}
    </>
  );
};

export default Layout;
