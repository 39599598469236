import { Box, ClickAwayListener, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import AnalyticsMap from "./analytics-map/AnalyticsMap";
import FarmersAnalyticsFiltersOverview from "./filters-overview/filters-overview";
import { useStyles } from "./farmers-analytics-screen.style";
import { IoHandRightSharp } from "react-icons/io5";
import { useDoubleTap } from "use-double-tap";
import { useTheme } from "@mui/system";
import UssdKpiContainer from "./ussd-kpi-container";
import FarmerLabelsUsageContainer from "./farmer-labels-usage-container";
import FarmersGenderChartsContainer from "./farmers-gender-charts-container";
import SMSKpiContainer from "./sms-kpi-container";
import FarmersAgeGroupChartsContainer from "./farmer-age-group-charts-container";
import StatisticsKpiContainer from "./statistics-kpi-container";
import ShowSelectedAnalyticsMapLocationTooltip from "components/standalone-components/show-selected-analytics-map-location-tooltip/show-selected-analytics-map-location-tooltip";
import ProviderFilterContainer from "./provider-filter-container";

interface FarmersAnalyticsPagepProps {}

const FarmersAnalyticsPage: React.FC<FarmersAnalyticsPagepProps> = () => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [isContainerFocused, setIsContainerFocused] = React.useState(false);

  const bindDoubleTap = useDoubleTap(() => {
    setIsContainerFocused(true);
  });

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProviderFilterContainer />
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography className={classes.labelRoot}>FARMER DISTRIBUTION</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ClickAwayListener onClickAway={() => setIsContainerFocused(false)}>
                    <Box height={700} className={`${classes.mapWrapperRoot}`}>
                      {isSmallScreen && !isContainerFocused && (
                        <Box width="100%" height="100%" bgcolor="rgba(255,255,255,.7)" position="absolute" zIndex={1000} display="flex" justifyContent="center" alignItems="center" sx={{ opacity: 0, "&:hover": { opacity: 1 } }} {...bindDoubleTap}>
                          <Box textAlign="center">
                            <IoHandRightSharp size={40} />
                            <Typography>Double Tap To Zoom With Scroller</Typography>
                          </Box>
                        </Box>
                      )}

                      <AnalyticsMap />
                      <Box className={`${classes.generalFilterWrapperRoot}`}>
                        <FarmersAnalyticsFiltersOverview />
                      </Box>
                    </Box>
                  </ClickAwayListener>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.labelRoot}>Demographics</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ShowSelectedAnalyticsMapLocationTooltip>
                    <FarmersGenderChartsContainer />
                  </ShowSelectedAnalyticsMapLocationTooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={`${classes.labelRoot} ${classes.notVisible}`}>Demographics</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ShowSelectedAnalyticsMapLocationTooltip>
                    <FarmersAgeGroupChartsContainer />
                  </ShowSelectedAnalyticsMapLocationTooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.labelRoot}>Farmer Labels</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ShowSelectedAnalyticsMapLocationTooltip>
                    <FarmerLabelsUsageContainer />
                  </ShowSelectedAnalyticsMapLocationTooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ShowSelectedAnalyticsMapLocationTooltip>
                <StatisticsKpiContainer />
              </ShowSelectedAnalyticsMapLocationTooltip>
            </Grid>
            <Grid item xs={12}>
              <ShowSelectedAnalyticsMapLocationTooltip>
                <UssdKpiContainer />
              </ShowSelectedAnalyticsMapLocationTooltip>
            </Grid>
            <Grid item xs={12}>
              <ShowSelectedAnalyticsMapLocationTooltip>
                <SMSKpiContainer />
              </ShowSelectedAnalyticsMapLocationTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FarmersAnalyticsPage;
