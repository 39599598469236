import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  flexBoxWrapper: {
    display: "flex",
    alignItems: "center",
  },
  infoWrapperBox: {
    marginLeft: 20,
  },
  actionWrapper: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },

  nameText: {
    fontFamily: "Roboto",
    fontSize: "40px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.55,
    letterSpacing: "normal",
  },
  subInfoColor: {
    color: "#5d6d6e",
  },
  subItem: {
    display: "flex",
    alignItems: "center",
  },
  subInfoText: {
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
    fontWeight: "normal !important",
    fontStretch: "normal !important",
    fontStyle: "normal !important",
    lineHeight: "1.56 !important",
    letterSpacing: "normal !important",
  },
  flexAlignedCenter: {
    display: "flex",
    alignItems: "center",
  },
  alignSelfCenter: {
    alignSelf: "center !important",
  },
  marginInline5: {
    marginInline: 5,
  },
  textAlignCenter: {
    textAlign: "center",
  },

  iconRoot: {
    flexShrink: 0,
  },
});
