import { useFormik } from "formik";
import { IProvider } from "models/provider";
import React from "react";
import { GetProvider } from "api/provider/get-provider";
import { deleteMultipleSuperOrganizationRelation } from "api/super-organization-relation/delete-multiple-super-organization-relation";
import { deleteSuperOrganizationRelation } from "api/super-organization-relation/delete-super-organization-relation";

export interface IParams {
  sub_org?: string;
  super_org?: string;
  onCancel?: () => any;
  onDisassocated?: () => any;
  open?: boolean;
  viewType: string;
}

export const useLogic = (params: IParams) => {
  let { sub_org, super_org, onCancel, onDisassocated, open } = params;

  const [isLoadingData, setisLoadingData] = React.useState(false);
  const [errors, setErrors] = React.useState("");

  // set sub org inital value
  React.useEffect(() => {
    setisLoadingData(true);
    if (sub_org && open)
      GetProvider({ page: 0, pageSize: 10, id: sub_org, }, (error, resp) => {
        if (error) console.log(error);
        if (resp) form.setFieldValue("sub_org", resp.providers[0]);
        setisLoadingData(false);
      });
  }, [sub_org, open]);

  //set super org inital value
  React.useEffect(() => {
    if (super_org && open)
      GetProvider({ page: 0, pageSize: 10, id: super_org, }, (error, resp) => {
        if (error) console.log(error);
        if (resp) form.setFieldValue("super_orgs", resp.providers);
        setisLoadingData(false);
      });
  }, [super_org, open]);

  const form = useFormik({
    initialValues: {
      sub_org: undefined as IProvider | undefined,
      super_orgs: [] as IProvider[],
    },
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);


      if (params.viewType === 'all') {
        if (sub_org && values?.super_orgs)
          deleteMultipleSuperOrganizationRelation({
            sub_org: sub_org,
            super_org: values.super_orgs.map((item) => item.id),
          }, (error, resp) => {
            if (error) {
              setErrors(error.response?.data?.error)
            } else {
              actions.setSubmitting(false);

              onDisassocated?.();
              actions.resetForm();
            }
          });
      } else {
        deleteSuperOrganizationRelation({
          sub_org: sub_org!,
          super_org: super_org!
        })
      }



    },
  });

  const handleCancel = () => {
    form.resetForm();
    onCancel?.();
  };

  return { form, handleCancel, isLoadingData, errors };
};
