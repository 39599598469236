import { Grid } from "@mui/material";
import * as React from "react";
import CertificateTable from "components/data-tables/certificate-table/certificate-table";

interface CertificationScreenProps {}

const CertificationScreen: React.FunctionComponent<CertificationScreenProps> =
  () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <CertificateTable />
        </Grid>
      </Grid>
    );
  };

export default CertificationScreen;
