import { AxiosError } from "axios";
import { AxiosInstance } from "../../configs/axios";
import { IUser } from "../../models/user";

interface Params {
  id: string;
}

interface Resp {
  data: IUser;
}
type GetUserByIdType = (Params: Params, callback: (error: AxiosError | null, Response: null | Resp) => void) => any;
const GetUserById: GetUserByIdType = (Params, callback) => {
  AxiosInstance.get("/users/" + Params.id)
    .then((resp) => {
      callback(null, {
        data: resp.data
      });
    })
    .catch((error) => callback(error, null));
};

export { GetUserById };
