import { blue, grey } from "@mui/material/colors";
import moment from "moment";
import React from "react";
import {
  CartesianGrid,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Legend,
} from "recharts";
import { GetDataset } from "../../../api/meteoblue/get-dataset";

export interface SoilMoistureChartProps {
  lng: number;
  lat: number;
}

const SoilMoistureChart: React.SFC<SoilMoistureChartProps> = ({ lat, lng }) => {
  const CurrentDate = new Date(Date.now());
  const DateBeforeSixMonths = new Date(Date.now());
  DateBeforeSixMonths.setMonth(DateBeforeSixMonths.getMonth() - 6);
  const [cm07, Setcm07] = React.useState<{ date: string; value: number }[]>([]);
  const [cm728, Setcm728] = React.useState<{ date: string; value: number }[]>(
    []
  );
  const [cm28100, Setcm28100] = React.useState<
    { date: string; value: number }[]
  >([]);
  const [cm100255, Setcm100255] = React.useState<
    { date: string; value: number }[]
  >([]);
  const [aggregated, Setaggregated] = React.useState<
    { date: string; value: number }[]
  >([]);
  React.useEffect(() => {
    GetDataset(
      {
        format: "json",
        timeIntervalsAlignment: "none",
        units: {
          energy: "watts",
          length: "metric",
          temperature: "C",
          velocity: "km/h",
        },
        geomtry: { type: "MultiPoint", coordinates: [[lat, lng, 279]] },
        models: [
          {
            domain: "ERA5T",
            gapFillDomain: null,
            timeResolution: "daily",
            codes: [
              {
                code: 144,
                level: "0-7 cm down",
                aggregation: "mean",
              },
            ],
          },
          {
            domain: "ERA5T",
            gapFillDomain: null,
            timeResolution: "daily",
            codes: [
              {
                code: 144,
                level: "7-28 cm down",
                aggregation: "mean",
              },
            ],
          },
          {
            domain: "ERA5T",
            gapFillDomain: null,
            timeResolution: "daily",
            codes: [
              {
                code: 144,
                level: "28-100 cm down",
                aggregation: "mean",
              },
            ],
          },
          {
            domain: "ERA5T",
            gapFillDomain: null,
            timeResolution: "daily",
            codes: [
              {
                code: 144,
                level: "100-255 cm down",
                aggregation: "mean",
              },
            ],
          },
          {
            domain: "ERA5T",
            gapFillDomain: null,
            timeResolution: "daily",
            codes: [
              {
                code: 144,
                level: "aggregated",
                aggregation: "mean",
                startDepth: 0,
                endDepth: 150,
              },
            ],
          },
        ],
        timeIntervals: [{ start: DateBeforeSixMonths, end: CurrentDate }],
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          Setcm07(
            resp.Data[0].timeIntervals[0].map((item, i) => ({
              date: item,
              value: resp.Data[0].codes[0].dataPerTimeInterval[0].data[0][i],
            }))
          );
          Setcm728(
            resp.Data[1].timeIntervals[0].map((item, i) => ({
              date: item,
              value: resp.Data[1].codes[0].dataPerTimeInterval[0].data[0][i],
            }))
          );
          Setcm28100(
            resp.Data[2].timeIntervals[0].map((item, i) => ({
              date: item,
              value: resp.Data[2].codes[0].dataPerTimeInterval[0].data[0][i],
            }))
          );
          Setcm100255(
            resp.Data[3].timeIntervals[0].map((item, i) => ({
              date: item,
              value: resp.Data[3].codes[0].dataPerTimeInterval[0].data[0][i],
            }))
          );
          Setaggregated(
            resp.Data[4].timeIntervals[0].map((item, i) => ({
              date: item,
              value: resp.Data[4].codes[0].dataPerTimeInterval[0].data[0][i],
            }))
          );
        }
      }
    );
  }, []);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        data={aggregated.map((item, i) => ({
          cm07: cm07
            .find((cm07item) => cm07item.date === item.date)
            ?.value?.toFixed(4),
          cm728: cm728
            .find((cm728item) => cm728item.date === item.date)
            ?.value?.toFixed(4),
          cm28100: cm28100
            .find((cm28100item) => cm28100item.date === item.date)
            ?.value?.toFixed(4),
          cm100255: cm100255
            .find((cm100255item) => cm100255item.date === item.date)
            ?.value?.toFixed(4),
          aggregated: item.value?.toFixed(4),
          date: moment(item.date).format("YYYY-MM"),
        }))}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis
          label={{
            dx: -25,
            angle: -90,
            position: "insideMiddleLeft",
            value: "Soil Moisture (CM)",
          }}
        />
        <Tooltip />
        <Legend layout="vertical" verticalAlign="middle" align="right" />
        <Line
          dot={false}
          type="monotone"
          name="0-7cm"
          dataKey="cm07"
          stroke={blue["200"]}
        />
        <Line
          dot={false}
          type="monotone"
          name="7-28cm"
          dataKey="cm728"
          stroke={blue["300"]}
        />
        <Line
          dot={false}
          type="monotone"
          name="28-100cm"
          dataKey="cm28100"
          stroke={blue["400"]}
        />
        <Line
          dot={false}
          type="monotone"
          name="100-255cm"
          dataKey="cm100255"
          stroke={blue["500"]}
        />
        <Line
          dot={false}
          type="monotone"
          name="aggregated"
          dataKey="aggregated"
          stroke={grey["900"]}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SoilMoistureChart;
