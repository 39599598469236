import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.39,
    letterSpacing: "normal",
    color: "#000",
    textTransform: "uppercase",
  },
});
