import { useAppSelector } from "store/hooks";
import { useState, useEffect } from "react";
import { IProvider } from "models/provider";
import { CreateEwalletUser } from "api/ewallet-users/create-ewallet-user";
import { GetProvider } from "api/provider/get-provider";

export const useLogic = () => {
  const user = useAppSelector((root) => root.appReducer.user);
  const [isOpenUpdateProvider, setisOpenUpdateProvider] = useState(false);
  const [isOpenAddPhoneNoteModal, setisOpenAddPhoneNoteModal] = useState(false);
  const [isOpenAffiliate, setisOpenAffiliate] = useState(false);
  const provider_name = user?.providerName;
  const provider_id = user?.provider_id;
  const [currentProvider, setCurrentProvider] = useState<undefined | IProvider>();
  const [tabsOptionsMenu, setTabsOptionsMenu] = useState([{ label: "Own Organizations", value: "own" }]);

  // const tabsOptionsMenu = [{ label: "All Organizations", value: "all" }];

  useEffect(() => {
    if (user?.menuroles === "admin") setTabsOptionsMenu([{ label: "All Organizations", value: "all" }, ...tabsOptionsMenu]);
  }, [user?.menuroles]);

  const handleOpenUpdateProvider = () => setisOpenUpdateProvider(true);
  const handleOpenAffliate = () => setisOpenAffiliate(true);

  const handleCancelUpdateProvider = () => setisOpenUpdateProvider(false);
  const handleCancelAffiliate = () => setisOpenAffiliate(false);

  const handleEditedProvider = () => {
    setisOpenUpdateProvider(false);
    window.location.reload();
  };
  const handleAffiliated = () => {
    setisOpenAffiliate(false);
    window.location.reload();
  };

  useEffect(() => {
    if (user?.provider_id)
      GetProvider({ page: 0, pageSize: 10, id: user?.provider_id, with_ewallet_user: true }, (error, resp) => {
        if (error?.message) console.log(error.message);
        if (resp) setCurrentProvider(resp.providers[0]);
      });
  }, [user]);

  const handleSetupWallet = () => {
    if (!currentProvider?.phone) {
      setisOpenAddPhoneNoteModal(true);
    } else {
      if (user?.provider_id)
        CreateEwalletUser({
          id: user?.provider_id,
          type: "organization",
          phone: user?.phone
        }).then((resp) => {
          window.location.reload();
        });
    }
  };

  const handleHidePhoneNote = () => setisOpenAddPhoneNoteModal(false);

  return {
    provider_name,
    provider_id,
    isOpenUpdateProvider,
    isOpenAffiliate,
    handleOpenUpdateProvider,
    handleOpenAffliate,
    handleCancelAffiliate,
    handleCancelUpdateProvider,
    handleEditedProvider,
    handleAffiliated,
    tabsOptionsMenu,
    isOpenAddPhoneNoteModal,
    handleHidePhoneNote,
    handleSetupWallet,
    currentProvider,
  };
};
