import {
  removeLoading,
  setBreadcrumbData,
  setLoading,
} from "store/reducers/breadcrumb/breadcrumb";
import { breadcrumb } from "./farm-page.breadcrumb";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { GetFarmInfoByIds } from "api/farm/get-farm-info";
import { TabsProps } from "@mui/material";

export interface IParams {
  SetFarmPageState: (data: any) => any;
  CurrentFarm: any;
}

export const useLogic = (params: IParams) => {
  const { SetFarmPageState, CurrentFarm } = params;

  const dispatch = useAppDispatch();

  const User = useAppSelector((root) => root.appReducer.user);

  const { farm_id } = useParams();

  useEffect(() => {
    if (farm_id) {
      dispatch(setLoading());
      GetFarmInfoByIds({
        page: 0,
        pageSize: 10,
        farm_id,
        with_providers: true,
        with_farmer: true,
        with_last_planted_crop: true
      }, (Farms) => {

        dispatch(
          setBreadcrumbData(
            breadcrumb({
              farm: { name: Farms[0]?.name, id: Farms[0].id ?? "" },
              farmer: {
                name: Farms[0]?.farmer?.first_name,
                id: Farms[0]?.farmer?.id,
              },
            })
          )
        );
        dispatch(removeLoading());
        SetFarmPageState({ CurrentFarm: Farms[0] });
      });
    }
  }, []);

  const handleTabChange: TabsProps["onChange"] = (e, value) =>
    SetFarmPageState({ SelectedTab: value });

  return { User, handleTabChange, farm_id };
};
