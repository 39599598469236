import { AxiosError } from "axios";
import humanize from "humanize-graphql-response";
import { IFarm } from "models/farm";
import { Crop } from "models/crop";
import { AxiosInstance } from "configs/axios";

interface Params {
  page: number;
  pageSize: number;
  farmer_id?: string;
  farm_id?: string;
  provider_id?: string;
  name?: string;
  with_providers?: boolean;
  with_farmer?: boolean;
  with_last_planted_crop?: boolean;
}

interface Response {
  totalCount: number;
  Farms: (IFarm & {
    ownedByFarmer: string | null;
    LastCropActivity: { crop: Crop };
  })[];
}

type GetFarmsBySchemaType = (
  data: Params,
  callback: (error: AxiosError | null, resp: Response | null) => void
) => any;

const GetFarmsBySchema: GetFarmsBySchemaType = (
  Params,
  callback
) => {

  AxiosInstance.get("/farms", { params: Params})
    .then((resp) => {
      callback(null, {
        totalCount: resp.data.data.all_farms.total_count,
        Farms: humanize(resp.data.data.all_farms).nodes.map((farm:any) => ({
          ...farm,
          LastCropActivity: {
            crop: farm.last_planted_crop
          }
        })),
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { GetFarmsBySchema };
