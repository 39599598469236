import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  labelRoot: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.39,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  },
  mapWrapperRoot: { position: "relative" },
  generalFilterWrapperRoot: {
    position: "absolute",
    width: "calc(100% - 30px)",
    bottom: 10,
    zIndex: 400,
    left: "50%",
    transform: "translate(-50%)",
  },
  notVisible: {
    opacity: 0,
  },
});
