// import { Box, Paper } from "@mui/material";
// import MaterialTable from "material-table";
// import { Fields, Schema } from "./schema";
import React from "react";
// import CustomDialog, {
//   CustomDialogProps,
// } from "components/custom-compnents/custom-dialog/custom-dialog";
// import { DeleteCertification } from "api/certification/delete-certification";
import AddCertificationComponent from "components/standalone-components/add-certification-component/add-certification-component";
import { IUser } from "models/user";
import CustomCertificationTable from "components/custom-compnents/custom-certification-table/custom-certificattion-table";
import { useLogic } from "./certification-table.logic";
import RemoveCertificationComponent from "components/standalone-components/remove-cetification-component/remove-certification-component";

interface CertificationTableProps {
  farmer_id?: string;
  user_role?: IUser["menuroles"];
}

const CertificationTable: React.FunctionComponent<CertificationTableProps> = ({
  farmer_id,
  // user_role,
}) => {
  // const [CertificationDataToBeRemoved, SetCertificationDataToBeRemoved] =
  //   React.useState<undefined | Fields>(undefined);
  // const [isSubmitting, SetIsSubmitting] = React.useState(false);
  // const [OpenAddCertification, setOpenAddCertification] = React.useState(false);
  // const handleCancelDeletaion = () =>
  //   SetCertificationDataToBeRemoved(undefined);
  // const DeleteDialogProps: CustomDialogProps = {
  //   Header: "Delete Certification Confirm",
  //   open: Boolean(CertificationDataToBeRemoved),
  //   ActionButtonsProps: {
  //     ButtonsVariant: "ConfirmCancel",
  //     OnCancel: () => handleCancelDeletaion(),
  //     OnConfirm: () => handleDeleteCetification(),
  //     DisableConfirm: isSubmitting,
  //   },
  // };
  // const handleCancelAddCertification = () => setOpenAddCertification(false);
  // const handleDeleteCetification = () => {
  //   SetIsSubmitting(true);
  //   if (CertificationDataToBeRemoved)
  //     DeleteCertification(
  //       { id: CertificationDataToBeRemoved.id },
  //       (error, resp) => {
  //         if (error) {
  //           console.log(error.message);
  //         } else {
  //           SetIsSubmitting(false);
  //           SetCertificationDataToBeRemoved(undefined);
  //           window.location.reload();
  //         }
  //       }
  //     );
  // };
  // const handleAddCertificatonButtonClick = () => setOpenAddCertification(true);
  // const handleAddedCertification = () => {
  //   setOpenAddCertification(false);
  //   window.location.reload();
  // };

  const { tableProps, addDialogProps, removeDilaogProps } = useLogic({
    farmer_id,
  });
  return (
    // <Box component={Paper} p={2}>
    //   <CustomDialog {...DeleteDialogProps}>
    //     Are you sure you want to withdraw the certificate{" "}
    //     {CertificationDataToBeRemoved?.certificateByCertificateId.title} given
    //     to {CertificationDataToBeRemoved?.farmerByFarmerId.name}
    //   </CustomDialog>
    //   <AddCertificationComponent
    //     farmer_id={farmer_id}
    //     open={OpenAddCertification}
    //     onCancel={handleCancelAddCertification}
    //     onAdded={handleAddedCertification}
    //   />
    //   <MaterialTable
    //     {...Schema({
    //       user_role,
    //       farmer_id,
    //       onAddClick: handleAddCertificatonButtonClick,
    //       onDeleteCertificationClick: SetCertificationDataToBeRemoved,
    //     })}
    //   />
    // </Box>
    <>
      {removeDilaogProps && (
        <RemoveCertificationComponent {...removeDilaogProps} />
      )}
      <AddCertificationComponent {...addDialogProps} />
      <CustomCertificationTable {...tableProps} />
    </>
  );
};

export default CertificationTable;
