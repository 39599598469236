import { Grid } from "@mui/material";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";
import FarmInfoList, {
  FarmInfoListProps,
} from "components/custom-compnents/farm-info-list/farm-info-list";
import LocationMap from "components/standalone-components/Location-Map/Location-Map";
import WeatherWidget from "components/standalone-components/weather-widget/weather-widget";
import React from "react";

export interface FarmOverviewSubScreenProps {
  farmInfoListProps?: FarmInfoListProps;
  location: { latitude: number; longitude: number };
  farmName?: string;
}

const FarmOverviewSubScreen: React.FunctionComponent<FarmOverviewSubScreenProps> =
  (props) => {
    const { farmInfoListProps, location, farmName } = props;
    const { latitude, longitude } = location;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTitle label={`Farm (${farmName}) information`} />
            </Grid>
            <Grid item xs={12}>
              <FarmInfoList {...farmInfoListProps} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTitle label="Weather Forecast" />
            </Grid>
            <Grid item xs={12}>
              <WeatherWidget location={{ latitude, longitude }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTitle label="Location on map" />
            </Grid>
            <Grid item xs={12}>
              <LocationMap
                Solid={true}
                location={[{ lat: latitude, lng: longitude }]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

export default FarmOverviewSubScreen;
