import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { SurveyQuestionAnswer } from "models/survey-questions-answer";
import { option } from "components/input-components/select-menu/SelectMenu";

import SingleChoiceItem from "./single-choice-item";
export interface AnswerWithIsInEditMode
  extends Pick<SurveyQuestionAnswer, "next_qid" | "answer" | "id"> {
  IsInEditMode?: boolean;
}
interface SingleChoiceListProps {
  ScreenOptionsList: option[];
  AnswerList: AnswerWithIsInEditMode[];
  OnEditClicked?: (answer_id: string) => any;
  OnDeleteClicked?: (answer_id: string) => any;
  OnAddNewAnswerButtonClicked?: () => any;
  ShowNewAnswerArea?: boolean;
  NewAnswerValue: string;
  NewAnswerNextScreenId: string;
  OnNewAnswerDoneClicked?: () => any;
  OnNewAnswerChanged?: (answer: string) => any;
  OnNewAnswerScreenChanged?: (scrren_id: string) => any;
  OnAnswerChanged?: (answer_id: any, Value: any) => any;
  OnAnswerScreenChanged?: (answer_id: any, screen_id: any) => any;
  OnEditAnswerDone?: (answer_id: string) => any;
}

const SingleChoiceList: React.FunctionComponent<SingleChoiceListProps> = ({
  AnswerList,
  ScreenOptionsList,
  OnEditClicked,
  OnDeleteClicked,

  ShowNewAnswerArea,
  NewAnswerValue,
  NewAnswerNextScreenId,
  OnNewAnswerDoneClicked,
  OnNewAnswerChanged,
  OnNewAnswerScreenChanged,
  OnAnswerChanged,
  OnAnswerScreenChanged,
  OnEditAnswerDone,
}) => {
  return (
    <Box>
      <Grid container spacing={2}>
        {AnswerList.map((item, index) => (
          <SingleChoiceItem
            key={index}
            IsInEditMode={item.IsInEditMode}
            answer={item.answer}
            next_qid={item.next_qid}
            ScreenOptionsList={ScreenOptionsList}
            OnEditClicked={() => OnEditClicked?.(item.id)}
            OnDeleteClicked={() => OnDeleteClicked?.(item.id)}
            OnAnswerChange={(new_answer) =>
              OnAnswerChanged?.(item.id, new_answer)
            }
            OnAnswerScreenChange={(sceen_id) =>
              OnAnswerScreenChanged?.(item.id, sceen_id)
            }
            OnDoneClicked={() => OnEditAnswerDone?.(item.id)}
          />
        ))}
        {ShowNewAnswerArea && (
          <SingleChoiceItem
            IsInEditMode={true}
            answer={NewAnswerValue}
            next_qid={NewAnswerNextScreenId}
            ScreenOptionsList={ScreenOptionsList}
            OnDoneClicked={OnNewAnswerDoneClicked}
            OnAnswerChange={OnNewAnswerChanged}
            OnAnswerScreenChange={OnNewAnswerScreenChanged}
          />
        )}
      </Grid>
    </Box>
  );
};

export default SingleChoiceList;
