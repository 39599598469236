import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Params {
    region_id_list?: string[];
}

type GetRegionGeojsonType = (
    Params: Params,
    callback: (error: AxiosError | null, resp: null | any) => any
) => any;



const GetRegionGeojson: GetRegionGeojsonType = (Params, callback) => {
    AxiosInstance.get("/regions/geojson", {
        params: Params
    })
        .then((resp) => callback(null, resp))
        .catch((error) => callback(error, null));
};

export { GetRegionGeojson };
