import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { broadcast } from "../../models/broadcast";

interface Resp { }
interface Params extends Omit<broadcast, "last_edited_by" | "created_by"> {
  sender_id: string
}
type UpdateBroadcastsType = (
  Params: Partial<Params>,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const UpdateBroadcasts: UpdateBroadcastsType = (Params, callback) => {
  AxiosInstance.patch("/sms-broadcasts/" + Params.id, { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { UpdateBroadcasts };
