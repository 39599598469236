import {
  Grid,
  Menu,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { FunctionComponent } from "react";
import ShinyButton from "../shiny-button/shiny-button";

export interface DateRangePickerProps {
  fromProps?: TextFieldProps & { "data-cy"?: string };
  toProps?: TextFieldProps & { "data-cy"?: string };
  fullWidth?: boolean;
}

const DateRangePicker: FunctionComponent<DateRangePickerProps> = (props) => {
  const { toProps, fromProps, fullWidth } = props;

  const [anchorEl, setanchorEl] = React.useState<HTMLButtonElement | undefined>(
    undefined
  );

  const data = [
    { placeholder: "From", props: fromProps },
    { placeholder: "To", props: toProps },
  ];

  return (
    <>
      <ShinyButton
        fullWidth={fullWidth}
        isShiny={!!fromProps?.value || !!toProps?.value}
        onClick={(e) => setanchorEl(e.currentTarget)}
        data-cy="date-range-filter-button-transactions-table"
      >
        Time Range
      </ShinyButton>
      <Menu
        onClose={() => setanchorEl(undefined)}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
      >
        <Box sx={{ maxWidth: 300, m: 2 }}>
          <Grid container spacing={2}>
            {data.map((item) => (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", gap: 2, alignItems: "center" }}
              >
                <Typography variant="h6" sx={{ width: 70 }}>
                  {item.placeholder}
                </Typography>
                <TextField
                  fullWidth
                  placeholder={item.placeholder}
                  type="datetime-local"
                  {...item.props}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Menu>
    </>
  );
};

export default DateRangePicker;
