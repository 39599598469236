import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import CustomMenuButton from "../custom-menu-button/custom-menu-button";
import CustomMenu from "../custom-menu-component/custom-menu-component";

export interface CustomPagnationProps {
  isNextPageButtonDisabled?: boolean;
  isPreviousPageButtonDisabled?: boolean;
  rowsPerPageOptions?: number[];
  rowsPerPage?: number;
  onRowPerPageChange?: (value: number, index: number) => any;
  onNextPageClick?: () => any;
  onPreviousPageClick?: () => any;
  from: number;

  total: number;
}

const CustomPagnation = (props: CustomPagnationProps) => {
  const { isNextPageButtonDisabled, isPreviousPageButtonDisabled, rowsPerPageOptions, rowsPerPage, onRowPerPageChange, onNextPageClick, onPreviousPageClick, from, total } = props;

  const to = rowsPerPage ? (rowsPerPage >= total ? total : from + rowsPerPage <= total ? from + rowsPerPage : total) : null;


  // Fix first page (from) counter & after search  
  let computedFrom = from;
  if(from < 1 && total){
    computedFrom = 1;
  }

  if(computedFrom < Number(to) && computedFrom > 9){
    computedFrom++;
  }


  const theme = useTheme();

  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box display="flex" flexDirection={isMdScreen ? "column" : "row"}>
      <Grid container spacing={2} alignItems="center">
        <Grid item lg={3} md={4} xs={12}>
          <CustomMenu
            menuData={
              rowsPerPageOptions?.map((item, index) => ({
                label: item.toString(),
                onClick: () => onRowPerPageChange?.(item, index),
              })) || []
            }
          >
            <CustomMenuButton variant="contained" color="secondary" fullWidth>
              {rowsPerPage} Rows Per Page
            </CustomMenuButton>
          </CustomMenu>
        </Grid>
        {!isMdScreen && (
          <Grid item xs={7}>
            <Typography variant="subtitle1">
              Showing { computedFrom } to {to} of {total} results
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box maxWidth="100%" width={isMdScreen ? "100%" : 600} mt={isMdScreen ? 2 : 0}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Button onClick={onPreviousPageClick} disabled={isPreviousPageButtonDisabled} variant="contained" color="secondary" fullWidth>
              Previous Page
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button onClick={onNextPageClick} disabled={isNextPageButtonDisabled} variant="contained" color="secondary" fullWidth>
              Next Page
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomPagnation;

CustomPagnation.defaultProps = {
  rowsPerPageOptions: [10, 50, 100],
  rowsPerPage: 10,
};
