import { getSMSMessagesCountKpi } from "api/kpis/sms-messages";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";

export interface IParams {
  region_id_list?: string[];
  county_id_list?: string[];
  constituency_id_list?: string[];
  ward_id_list?: string[];
  provider_id?: string;
}
export const useLogic = (params: IParams) => {
  const { constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id } = params;

  const [perWeek, setperWeek] = useState<number | undefined>();

  const [perMonth, setperMonth] = useState<number | undefined>();

  const [total, settotal] = useState<number | undefined>();

  const [uniqueperWeek, setuniquePerWeek] = useState<number | undefined>();

  const [uniqueperMonth, setuniquePerMonth] = useState<number | undefined>();

  const [unique, setunique] = useState<number | undefined>();

  const last_week_date = new Date(Date.now());

  last_week_date.setDate(last_week_date.getDate() - 7);

  const last_month_date = new Date();

  last_month_date.setDate(last_month_date.getDate() - 30);

  // const last_year_date = new Date();

  // last_year_date.setDate(last_year_date.getDate() - 365);

  const user = useAppSelector((root) => root.appReducer.user);

  useEffect(() => {
    getSMSMessagesCountKpi({ before_date: last_week_date.toString(), constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id: provider_id ? provider_id : user?.provider_id, status_sent: true }).then((resp) => {
      setperWeek(resp.data.data.cube[0].sms_message.count);
    });

    getSMSMessagesCountKpi({ before_date: last_month_date.toString(), constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id: provider_id ? provider_id : user?.provider_id, status_sent: true }).then((resp) => {
      setperMonth(resp.data.data.cube[0].sms_message.count);
    });

    getSMSMessagesCountKpi({ constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id: provider_id ? provider_id : user?.provider_id, status_sent: true }).then((resp) => {
      settotal(resp.data.data.cube[0].sms_message.count);
    });

    getSMSMessagesCountKpi({ unique_sms_count: true, before_date: last_week_date.toString(), constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id: provider_id ? provider_id : user?.provider_id, status_sent: true }).then((resp) => {
      setuniquePerWeek(resp.data.data.cube[0].sms_message.unique_sms_count);
    });

    getSMSMessagesCountKpi({ unique_sms_count: true, before_date: last_month_date.toString(), constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id: provider_id ? provider_id : user?.provider_id, status_sent: true }).then((resp) => {
      setuniquePerMonth(resp.data.data.cube[0].sms_message.unique_sms_count);
    });

    getSMSMessagesCountKpi({ unique_sms_count: true, constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id: provider_id ? provider_id : user?.provider_id, status_sent: true }).then((resp) => {
      setunique(resp.data.data.cube[0].sms_message.unique_sms_count);
    });
  }, [constituency_id_list?.length, county_id_list?.length, region_id_list?.length, ward_id_list?.length, provider_id]);

  return { perWeek, perMonth, total, uniqueperWeek, uniqueperMonth, unique };
};
