import { Box, BoxProps, Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { GetFarmActivites } from "api/activities/get-farm-activites";
import { GetUsers } from "api/user/get-users";
import { Activity } from "models/activity";
import ActivityStackedInfoList from "components/custom-compnents/activity-stacked-info-list/activity-stacked-info-list";
// import CustomDialog, {
//   CustomDialogProps,
// } from "components/custom-compnents/custom-dialog/custom-dialog";
import CustomDialogV2, {
  CustomDialogV2Props,
} from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";

interface CardWrapperProps {
  Variant: "Card";
  IsCardView?: Boolean;
}
interface CustomWrapperProps {
  Variant: "Modal";
  open: boolean;
  OnOk?: () => any;
}
interface ActivityDetailComponentProps {
  activity_id: string;
  WithLinkButton?: Boolean;
  OnDeleteClicked?: () => any;
  OnEditClicked?: () => any;
  ShowDeleteButton?: boolean;
  ShowEditButton?: boolean;
}

const ActivityDetailComponent: React.FunctionComponent<
  ActivityDetailComponentProps & (CustomWrapperProps | CardWrapperProps)
> = (Props) => {
  const WrapperProps =
    Props.Variant === "Card"
      ? ({
        component: Props.IsCardView && Paper,
        p: Props.IsCardView && 2,
      } as BoxProps)
      : ({
        open: Props.open,
        header: "Activity Detail",
        // ActionButtonsProps: { ButtonsVariant: "Ok", OnOk: Props.OnOk },
      } as CustomDialogV2Props);

  const [ActivityData, SetActivityData] =
    React.useState<Activity | undefined>(undefined);
  const [LastEditorName, SetLastEditorName] =
    React.useState<undefined | string>(undefined);
  React.useEffect(() => {
    GetFarmActivites(
      { page: 0, pageSize: 10, id: Props.activity_id, with_crop: true, with_variety: true },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          GetUsers({ page: 0, pageSize: 10 }, (error, resp) => {
            if (error) {
              console.log(error.message);
            } else if (resp) {
              SetLastEditorName(resp.data[0].name);
            }
          });
          SetActivityData(resp.items[0]);
        }
      }
    );
  }, []);
  const Wrapper = Props.Variant === "Card" ? Box : CustomDialogV2;
  return (
    <Wrapper {...(WrapperProps as any)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {ActivityData ? (
            <ActivityStackedInfoList
              OnDeleteClicked={Props.OnDeleteClicked}
              OnEditClicked={Props.OnEditClicked}
              ShowDeleteButton={Props.ShowDeleteButton}
              ShowEditButton={Props.ShowEditButton}
              aggrLastEditorName={LastEditorName}
              {...ActivityData}
            />
          ) : (
            <Typography>Loading ...</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            color="secondary"
            onClick={Props.Variant === "Modal" ? Props.OnOk : undefined}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ActivityDetailComponent;
