import { DeleteWhatsappMessageTemplate } from "api/whatsapp-templates/delete";
import React from "react";

export interface IParams {
  onDeleted?: () => any;
  id: string;
}
export const useLogic = (params: IParams) => {
  const [isSubmittingDelete, setiIsSubmittingDelete] = React.useState(false);
  const { onDeleted, id } = params;

  const handleDeleteConfirm = () => {
    setiIsSubmittingDelete(true);
    DeleteWhatsappMessageTemplate({ id }).then((resp) => {
      setiIsSubmittingDelete(false);
      onDeleted?.();
    });
  };

  return { isSubmittingDelete, handleDeleteConfirm };
};
