import React, { useEffect, useState } from "react";
import { GeoJSON } from "react-leaflet";
import Leaflet from "leaflet";
import ColorPercentageRange from "./utils/colorRangePercentageFunction";
import { ConstituencyStateItem, CountyStateItem, RegionStateItem, WardsStateItem } from "store/reducers/farmers-analytics-page/map/farmers-analytics-map-reducer";
import { IUser } from "models/user";
import { Region } from "models/region";
import { GetRegionGeojson } from "api/geojson/region-geojson";
import { getFarmersKpi } from "api/kpis/farmers";
export interface RegionLayerProps {
  onClick: (County: any) => void;
  onFarmersPerRegionCountUpdate?: (FarmersCount: Object) => void;
  regionsFarmersCountObject: any;
  SelectedRegionsArray?: RegionStateItem[];
  FarmersTotalCount: number;
  SelectedCounties: CountyStateItem[];
  SelectedConstituencies: ConstituencyStateItem[];
  SelectedWards: WardsStateItem[];
  UserMenuRole: IUser["menuroles"];
  UserProviderId: IUser["provider_id"];
}


const RegionLayer: React.SFC<RegionLayerProps> = ({ onClick = () => null, onFarmersPerRegionCountUpdate = () => null, regionsFarmersCountObject, SelectedRegionsArray, FarmersTotalCount, SelectedCounties, SelectedConstituencies, SelectedWards, UserProviderId, UserMenuRole }) => {
  const [geojson, SetGeoJson] = useState<Region[] | undefined>(undefined);
  useEffect(() => {
    GetRegionGeojson({}, (error, resp) => {
      if (error) {
        console.log(error.message)
      } else {
        SetGeoJson(resp?.data);
        getFarmersKpi({
          per_region: true,
          region_id_list: resp.data.features.map((item: { properties: { region_id: string } }) => item.properties.region_id),
          provider_id: UserProviderId
        })
          .then(result => {
            onFarmersPerRegionCountUpdate(result.data.data.cube
              .reduce((prevObj: any, item: any) => ({ ...prevObj, [`region_${item.regions.region_id}`]: { totalCount: item.farmers.unique_farmer_count } }), {}))
          })
          .catch(err => {
            console.log(err, 'err')
          })
      }
    })
  }, [UserProviderId]);

  // add hover effect
  const highlightFeature = (e: any) => {
    const layer = e.target;

    layer.setStyle({
      color: "#666",
      dashArray: "",
    });

    if (!Leaflet.Browser.ie && !Leaflet.Browser.opera && !Leaflet.Browser.edge) {
      layer.bringToFront();
    }
  };

  // remove hover effect
  const resetHighlight = (e: any) => {
    const layer = e.target;
    const Region_Id = layer.feature.properties.region_id;
    const Current_Region_Farmers_Count = parseInt(regionsFarmersCountObject![`region_${Region_Id}`]?.totalCount || 0);

    const FarmersPercentageInCurrentRegion = (Current_Region_Farmers_Count * 100) / FarmersTotalCount || 0;
    layer.setStyle({
      color: ColorPercentageRange(FarmersPercentageInCurrentRegion),
    });
  };

  // handle click on region
  const showCounty = async (e: any) => {
    regionsFarmersCountObject![`region_${e.target.feature.properties.region_id}`]?.totalCount > 0 && // only click if it's not already selected
      onClick(e.target.feature.properties);
  };

  const GeoJSONProps: any = {
    onEachFeature: (feature: any, layer: any) => {
      const Region_Id = feature.properties.region_id;
      const FarmersCountPerRegion = regionsFarmersCountObject![`region_${Region_Id}`]?.totalCount || 0;
      layer.bindTooltip(`<h5>${feature.properties.region}</h5> 
            (${FarmersCountPerRegion} farmer)`);
      layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: showCounty,
      });
    },

    style: (feature: any) => {
      const Region_Id = feature.properties.region_id;
      const Current_Region_Farmers_Count = parseInt(regionsFarmersCountObject![`region_${Region_Id}`]?.totalCount || 0);

      const FarmersPercentageInCurrentRegion = (Current_Region_Farmers_Count * 100) / FarmersTotalCount || 0;

      return {
        fillColor: !SelectedRegionsArray?.some((item) => item.region_id === Region_Id) ? ColorPercentageRange(FarmersPercentageInCurrentRegion) : "transparent",
        weight: 2,
        opacity: 1,
        dashArray: "",
        fillOpacity: 0.7,
        color: SelectedRegionsArray?.some((item) => item.region_id === Region_Id)
          ? (SelectedCounties.length > 0 && !SelectedCounties.some((SelectedCountiesItem) => SelectedCountiesItem.region_id === Region_Id)) ||
            (SelectedConstituencies.length > 0 && !SelectedConstituencies.some((SelectedConstituenciesItem) => SelectedConstituenciesItem.region_id === Region_Id)) ||
            (SelectedWards.length > 0 && !SelectedWards.some((SelectedWardsItem) => SelectedWardsItem.region_id === Region_Id))
            ? "steelblue"
            : "blue"
          : ColorPercentageRange(FarmersPercentageInCurrentRegion),
        interactive: !SelectedRegionsArray?.some((item) => item.region_id === Region_Id),
      };
    },
    data: geojson,
    key: Math.random(),
    // filter: (geoJsonFeature) =>
    //     !SelectedRegionsArray?.some((item) => item.region_id === geoJsonFeature.properties.region_id),
    // && regionsFarmersCountObject![`region_${geoJsonFeature.properties.region_id}`]?.totalCount > 0, // remove selected region from map and region with no farmers
  };

  return <GeoJSON {...GeoJSONProps} />;
};

export default RegionLayer;
