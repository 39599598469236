import React, { useEffect } from "react";
import { GeoJSON } from "react-leaflet";
import Leaflet from "leaflet";
import ColorPercentageRange from "./utils/colorRangePercentageFunction";
import { WardsStateItem } from "store/reducers/farmers-analytics-page/map/farmers-analytics-map-reducer";
import { IUser } from "models/user";
import { getFarmersKpi } from "api/kpis/farmers";
import { GetConstituenciesGeojson } from "api/geojson/constituencies-geojson";
export interface ConstituencyLayerProps {
  onClick: (Constituency: any) => void;
  SelectedConstituenciesArray: any[];
  ConstituenciesFarmersCountObject: any;
  onFarmersPerConstituencyCountUpdate: (data: any) => void;
  FarmersTotalCount: number;
  LastClickedCounty: any;
  onNewConstituenciesGeoJson: (data: any) => void;
  ConstituenciesGeoJson: any;
  SelectedWards: WardsStateItem[];
  UserMenuRole: IUser["menuroles"];
  UserProviderId: IUser["provider_id"];
}

const ConstituencyLayer: React.SFC<ConstituencyLayerProps> = ({
  onClick = () => null,
  ConstituenciesFarmersCountObject,
  FarmersTotalCount,
  onFarmersPerConstituencyCountUpdate,
  LastClickedCounty,
  SelectedConstituenciesArray,
  onNewConstituenciesGeoJson,
  ConstituenciesGeoJson,
  SelectedWards,
  UserMenuRole,
  UserProviderId,
}) => {
  useEffect(() => {
    if (LastClickedCounty?.constituency_id) {
      GetConstituenciesGeojson({
        counties_id_list: [LastClickedCounty.county_id]
      }, (error, resp) => {
        if (error) {
          console.log(error.message)
        } else {
          getFarmersKpi({
            per_region: true,
            per_county: true,
            per_constituency: true,
            constituency_id_list: resp.data.features.map((item: { properties: { constituency_id: string } }) => item.properties.constituency_id),
            provider_id: UserProviderId
          })
            .then(result => {
              onFarmersPerConstituencyCountUpdate(result.data.data.cube.reduce((prevObj: any, item: any) => ({ ...prevObj, [`constituency_${item.constituencies.constituency_id}`]: { totalCount: item.farmers.unique_farmer_count } }), {}))
            })
            .catch(err => {
              console.log(err, 'err')
            })
          onNewConstituenciesGeoJson(resp?.data);
        }
      })
    }
  }, [LastClickedCounty, UserProviderId]);

  // add hover effect
  const highlightFeature = (e: any) => {
    const layer = e.target;

    layer.setStyle({
      color: "#666",
      dashArray: "",
    });

    if (!Leaflet.Browser.ie && !Leaflet.Browser.opera && !Leaflet.Browser.edge) {
      layer.bringToFront();
    }
  };

  // remove hover effect
  const resetHighlight = (e: any) => {
    const layer = e.target;
    const Constituency_Id = layer.feature.properties.ward_id;
    const Current_Constituency_Farmers_Count = parseInt(ConstituenciesFarmersCountObject![`constituency_${Constituency_Id}`]?.totalCount || 0);
    const FarmersPercentageInCurrentConstituency = (Current_Constituency_Farmers_Count * 100) / FarmersTotalCount || 0;
    layer.setStyle({
      color: ColorPercentageRange(FarmersPercentageInCurrentConstituency),
    });
  };

  // handle click on Constituencies
  const HandleClick = async (e: any) => {
    ConstituenciesFarmersCountObject![`constituency_${e.target.feature.properties.constituency_id}`]?.totalCount > // only click if it's not already selected
      0 && onClick(e.target.feature.properties);
  };

  const GeoJSONProps: any = {
    onEachFeature: (feature: any, layer: any) => {
      const Constituency_Id = feature.properties.constituency_id;
      const FarmersCountPerConstituency = ConstituenciesFarmersCountObject![`constituency_${Constituency_Id}`]?.totalCount || 0;
      layer.bindTooltip(`<h5>${feature.properties.constituency} constituency</h5> <p>${feature.properties.region} * ${feature.properties.county}</p> (${FarmersCountPerConstituency} farmer)`);
      layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: HandleClick,
      });
    },

    style: (feature: any) => {
      const Constituency_Id = feature.properties.constituency_id;
      const Current_Constituency_Farmers_Count = parseInt(ConstituenciesFarmersCountObject![`constituency_${Constituency_Id}`]?.totalCount || 0);
      const FarmersPercentageInCurrentConstituency = (Current_Constituency_Farmers_Count * 100) / FarmersTotalCount || 0;

      return {
        fillColor: !SelectedConstituenciesArray.some((item) => item.constituency_id === Constituency_Id) ? ColorPercentageRange(FarmersPercentageInCurrentConstituency) : "transparent",
        weight: 2,
        opacity: 1,
        dashArray: "0",
        fillOpacity: 0.7,
        color: SelectedConstituenciesArray.some((item) => item.constituency_id === Constituency_Id)
          ? SelectedWards.length > 0 && !SelectedWards.some((SelectedWardsItem) => SelectedWardsItem.constituency_id === Constituency_Id)
            ? "steelblue"
            : "blue"
          : ColorPercentageRange(FarmersPercentageInCurrentConstituency),
        interactive: !SelectedConstituenciesArray.some((item) => item.constituency_id === Constituency_Id),
      };
    },
    data: ConstituenciesGeoJson,
    key: Math.random(),
    // filter: (geoJsonFeature) => {
    //     return (
    //         !SelectedConstituenciesArray.some(
    //             (item) => item.constituency_id === geoJsonFeature.properties.constituency_id
    //         ) && SelectedCountiesArray.some((item) => item.county_id === geoJsonFeature.properties.county_id)
    //         // && ConstituenciesFarmersCountObject![`constituency_${geoJsonFeature.properties.constituency_id}`]
    //         //     ?.totalCount > 0
    //     )
    // },
  };

  return <GeoJSON {...GeoJSONProps} />;
};

export default ConstituencyLayer;
