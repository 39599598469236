import { Box, Link, Paper, Typography } from "@mui/material";

import React from "react";
import Scrollbars from "react-custom-scrollbars";

import { useAppDispatch, useAppSelector } from "store/hooks";

import {
  ConstituencyStateItem,
  CountyStateItem,
  RegionStateItem,
  setConstituenciesFarmersCountObject,
  setConstituenciesGeoJson,
  setCountiesGeoJson,
  setCountiesFarmersCountObject,
  setLastClickedConstituency,
  setLastClickedCounty,
  setLastClickedRegion,
  setLastClickedWards,
  setSelectedConstituencies,
  setSelectedCounties,
  setSelectedRegions,
  setSelectedWards,
  setWardsFarmersCountObject,
  setWardsGeoJson,
  WardsStateItem,
} from "store/reducers/farmers-analytics-page/map/farmers-analytics-map-reducer";

import AnalyticsMapFiltersOverviewFilterItem from "./filters-element/filter-item";

import { useStyles } from "./filters-overview.style";

export interface FarmersAnalyticsFiltersOverviewProps {}

const FarmersAnalyticsFiltersOverview: React.FC<FarmersAnalyticsFiltersOverviewProps> = () => {
  const farmersAnalyticsMapState = useAppSelector((root) => root.farmersAnalyticsMapState);

  const dispatcher = useAppDispatch();

  const HandleRemoveRegionFilter = (payload: RegionStateItem) => {
    let RelatiedCounties = farmersAnalyticsMapState.SelectedCounties.filter((item) => item.region_id === payload.region_id);

    let RelatiedConstituencies = farmersAnalyticsMapState.SelectedConstituencies.filter((item) => item.region_id === payload.region_id);

    let RelatiedWards = farmersAnalyticsMapState.SelectedWards.filter((item) => item.region_id === payload.region_id);

    RemoveSelectedRegions([payload]);

    RemoveSelectedCounties(RelatiedCounties);

    RemoveSelectedConstituenceis(RelatiedConstituencies);

    RemoveSelectedWards(RelatiedWards);
  };

  const RemoveSelectedRegions = (regionsArray: RegionStateItem[]) => {
    let CurrentSelectedRegions = [...farmersAnalyticsMapState.SelectedRegions];
    CurrentSelectedRegions = CurrentSelectedRegions.filter((CurrentSelectedRegionsItem) => !regionsArray.some((item) => item.region_id === CurrentSelectedRegionsItem.region_id));
    let CurrentCountiesFarmersCountObject = {
      ...farmersAnalyticsMapState.CountiesFarmersCountObject,
    };
    let CurrentRegionPayloadCountiesIds: string[] = farmersAnalyticsMapState.CountiesGeoJson.features.filter((CountiesGeoJsonFeatureItem: any) => regionsArray.some((item) => item.region_id === CountiesGeoJsonFeatureItem.properties.region_id)).map((item: any) => item.properties.county_id);
    CurrentRegionPayloadCountiesIds.forEach((item) => delete CurrentCountiesFarmersCountObject[`county_${item}`]);
    let CurrentCountiesGeoJson = {
      ...farmersAnalyticsMapState.CountiesGeoJson,
    };
    CurrentCountiesGeoJson["features"] = CurrentCountiesGeoJson["features"]?.filter((CurrentCountiesGeoJsonFeatureItem: any) => regionsArray.some((item) => item.region_id === CurrentCountiesGeoJsonFeatureItem.properties.region_id));

    dispatcher(setSelectedRegions(CurrentSelectedRegions));
    dispatcher(setLastClickedRegion({}));
    dispatcher(setCountiesFarmersCountObject(CurrentCountiesFarmersCountObject));
    dispatcher(setCountiesGeoJson(CurrentCountiesGeoJson));
  };

  const RemoveSelectedCounties = (CountiesArray: CountyStateItem[]) => {
    let CurrentSelectedCounties = [...farmersAnalyticsMapState.SelectedCounties];
    CurrentSelectedCounties = CurrentSelectedCounties.filter((CurrentSelectedCountiesItem) => !CountiesArray.some((item) => item.county_id === CurrentSelectedCountiesItem.county_id));
    let CurrentConstituenceisFarmersCountObject = {
      ...farmersAnalyticsMapState.ConstituenciesFarmersCountObject,
    };
    let CurrentCouniesPayloadConstituenciesIds: string[] = [...farmersAnalyticsMapState.ConstituenciesGeoJson.features]
      .filter((ConstituenciesGeoJsonFeatureItem: any) => CountiesArray.some((item) => item.county_id === ConstituenciesGeoJsonFeatureItem.properties.county_id))
      .map((item: any) => item.properties.constituency_id);
    CurrentCouniesPayloadConstituenciesIds.forEach((item) => delete CurrentConstituenceisFarmersCountObject[`constituency_${item}`]);
    let CurrentConstituencyGeoJson = {
      ...farmersAnalyticsMapState.ConstituenciesGeoJson,
    };
    CurrentConstituencyGeoJson["features"] = CurrentConstituencyGeoJson["features"]?.filter((CurrentConstituencyGeoJsonFeatureItem: any) => !CountiesArray.some((item) => item.county_id === CurrentConstituencyGeoJsonFeatureItem.properties.county_id));

    dispatcher(setSelectedCounties(CurrentSelectedCounties));
    dispatcher(setLastClickedCounty({}));
    dispatcher(setConstituenciesFarmersCountObject(CurrentConstituenceisFarmersCountObject));
    dispatcher(setConstituenciesGeoJson(CurrentConstituencyGeoJson));
  };

  const RemoveSelectedConstituenceis = (ConstituenciesArray: ConstituencyStateItem[]) => {
    let CurrentSelectedConstituencies = [...farmersAnalyticsMapState.SelectedConstituencies];
    CurrentSelectedConstituencies = CurrentSelectedConstituencies.filter((CurrentSelectedConstituenciesItem) => !ConstituenciesArray.some((item) => item.constituency_id === CurrentSelectedConstituenciesItem.constituency_id));
    let CurrentWardsFarmersCountObject = {
      ...farmersAnalyticsMapState.WardsFarmersCountObject,
    };
    let CurrentConstituencyPayloadWardsIds: string[] = [...farmersAnalyticsMapState.WardsGeoJson.features]
      .filter((WardsGeoJsonFeatureItem: any) => ConstituenciesArray.some((item) => item.constituency_id === WardsGeoJsonFeatureItem.properties.constituency_id))
      .map((item: any) => item.properties.ward_id);
    CurrentConstituencyPayloadWardsIds.forEach((item) => delete CurrentWardsFarmersCountObject[`ward_${item}`]);
    let CurrentWardsGeoJson = { ...farmersAnalyticsMapState.WardsGeoJson };
    CurrentWardsGeoJson["features"] = CurrentWardsGeoJson["features"]?.filter((CurrentWardsGeoJsonFeatureItem: any) => !ConstituenciesArray.some((item) => item.constituency_id === CurrentWardsGeoJsonFeatureItem.properties.constituency_id));

    dispatcher(setSelectedConstituencies(CurrentSelectedConstituencies));
    dispatcher(setLastClickedConstituency({}));
    dispatcher(setWardsFarmersCountObject(CurrentWardsFarmersCountObject));
    dispatcher(setWardsGeoJson(CurrentWardsGeoJson));
  };

  const RemoveSelectedWards = (WardsArray: WardsStateItem[]) => {
    let CurrentSelectedWards = [...farmersAnalyticsMapState.SelectedWards];
    CurrentSelectedWards = CurrentSelectedWards.filter((CurrentSelectedWardsItem) => !WardsArray.some((item) => item.ward_id === CurrentSelectedWardsItem.ward_id));

    dispatcher(setSelectedWards(CurrentSelectedWards));
    dispatcher(setLastClickedWards({}));
  };
  const HandleRemoveCounty = (payload: CountyStateItem) => {
    const RelatiedConstituncies = farmersAnalyticsMapState.SelectedConstituencies.filter((item) => item.county_id === payload.county_id);
    const RelatiedWards = farmersAnalyticsMapState.SelectedWards.filter((item) => item.county_id === payload.county_id);
    RemoveSelectedCounties([payload]);
    RemoveSelectedConstituenceis(RelatiedConstituncies);
    RemoveSelectedWards(RelatiedWards);
  };

  const HandleRemoveConstituency = (payload: ConstituencyStateItem) => {
    const RelatiedWards = farmersAnalyticsMapState.SelectedWards.filter((item) => item.constituency_id === payload.constituency_id);
    RemoveSelectedConstituenceis([payload]);
    RemoveSelectedWards(RelatiedWards);
  };
  const HandleRemoveWard = (payload: WardsStateItem) => {
    RemoveSelectedWards([payload]);
  };

  const handleClearFilters = () => {
    dispatcher(setSelectedWards([]));
    dispatcher(setLastClickedWards({}));
    dispatcher(setSelectedConstituencies([]));
    dispatcher(setLastClickedConstituency({}));
    dispatcher(setWardsFarmersCountObject({}));
    dispatcher(setWardsGeoJson(undefined));
    dispatcher(setSelectedCounties([]));
    dispatcher(setLastClickedCounty({}));
    dispatcher(setConstituenciesFarmersCountObject({}));
    dispatcher(setConstituenciesGeoJson(undefined));
    dispatcher(setSelectedRegions([]));
    dispatcher(setLastClickedRegion({}));
    dispatcher(setCountiesFarmersCountObject({}));
    dispatcher(setCountiesGeoJson(undefined));
  };

  const classes = useStyles();

  return farmersAnalyticsMapState.SelectedWards.length > 0 || farmersAnalyticsMapState.SelectedRegions.length > 0 || farmersAnalyticsMapState.SelectedCounties.length > 0 || farmersAnalyticsMapState.SelectedConstituencies.length > 0 ? (
    <Box component={Paper} px={2}>
      <Scrollbars autoHeight>
        <Box py={1} display="flex" alignItems="center">
          <Typography className={classes.FilterText}>Filters :</Typography>
          {farmersAnalyticsMapState.SelectedRegions.map((item, index) => (
            <AnalyticsMapFiltersOverviewFilterItem
              Variant="region"
              payLoad={item}
              onRemove={HandleRemoveRegionFilter}
              key={index}
              CrossOf={
                (farmersAnalyticsMapState.SelectedCounties.length > 0 && !farmersAnalyticsMapState.SelectedCounties.some((SelectedCountiesItem) => SelectedCountiesItem.region_id === item.region_id)) ||
                (farmersAnalyticsMapState.SelectedConstituencies.length > 0 && !farmersAnalyticsMapState.SelectedConstituencies.some((SelectedConstituenciesItem) => SelectedConstituenciesItem.region_id === item.region_id)) ||
                (farmersAnalyticsMapState.SelectedWards.length > 0 && !farmersAnalyticsMapState.SelectedWards.some((SelectedWardsItem) => SelectedWardsItem.region_id === item.region_id))
              }
            />
          ))}
          {farmersAnalyticsMapState.SelectedCounties.map((item, index) => (
            <AnalyticsMapFiltersOverviewFilterItem
              Variant="county"
              payLoad={item}
              onRemove={HandleRemoveCounty}
              key={index}
              CrossOf={
                (farmersAnalyticsMapState.SelectedConstituencies.length > 0 && !farmersAnalyticsMapState.SelectedConstituencies.some((SelectedConstituenciesItem) => SelectedConstituenciesItem.county_id === item.county_id)) ||
                (farmersAnalyticsMapState.SelectedWards.length > 0 && !farmersAnalyticsMapState.SelectedWards.some((SelectedWardsItem) => SelectedWardsItem.county_id === item.county_id))
              }
            />
          ))}
          {farmersAnalyticsMapState.SelectedConstituencies.map((item, index) => (
            <AnalyticsMapFiltersOverviewFilterItem
              Variant="constituency"
              onRemove={HandleRemoveConstituency}
              payLoad={item}
              key={index}
              CrossOf={farmersAnalyticsMapState.SelectedWards.length > 0 && !farmersAnalyticsMapState.SelectedWards.some((SelectedWardsItem) => SelectedWardsItem.constituency_id === item.constituency_id)}
            />
          ))}

          {farmersAnalyticsMapState.SelectedWards.map((item, index) => (
            <AnalyticsMapFiltersOverviewFilterItem Variant="ward" key={index} onRemove={HandleRemoveWard} payLoad={item} />
          ))}
          <Box ml="auto" flexShrink={0}>
            <Link onClick={handleClearFilters}>Clear Filter</Link>
          </Box>
        </Box>
      </Scrollbars>
    </Box>
  ) : null;
};

export default FarmersAnalyticsFiltersOverview;
