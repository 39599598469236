import { getFarmerLabelsUsage, IResp } from "api/kpis/farmer-labels-usage";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
export interface IParams {
  region_id_list?: string[];
  county_id_list?: string[];
  constituency_id_list?: string[];
  ward_id_list?: string[];
  provider_id?: string;
}

export const useLogic = (params: IParams) => {
  const { constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id } = params;

  const [data, setdata] = useState<IResp["data"]["cube"]>([]);

  const user = useAppSelector((root) => root.appReducer.user);

  useEffect(() => {
    getFarmerLabelsUsage({ constituency_id_list, county_id_list, region_id_list, ward_id_list, label_key: "value chain", provider_id: provider_id ? provider_id : user?.provider_id }).then((resp) => {
      setdata(resp.data.data.cube);
    });
  }, [constituency_id_list?.length, county_id_list?.length, region_id_list?.length, ward_id_list?.length, provider_id]);

  return { data };
};
