import { AxiosError } from "axios";
import { AxiosInstance } from "../../configs/axios";
import { IUser } from "../../models/user";

interface Params {
  page?: number;
  pageSize?: number;
  name?: string;
  email?: string;
  phone?: string;
  menuroles?: string;
  provider_id_list?: string[];
}

interface Resp {
  total: number;
  data: IUser[];
}
type GetUsersType = (Params: Params, callback: (error: AxiosError | null, Response: null | Resp) => void) => any;
const GetUsers: GetUsersType = (Params, callback) => {
  AxiosInstance.get("/users", { params: Params })
    .then((resp) => {
      callback(null, {
        data: resp.data.items,
        total: resp.data.total
      });
    })
    .catch((error) => callback(error, null));
};

export { GetUsers };
