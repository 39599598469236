import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
interface Params {
  lat: number;
  lng: number;
}
type Get14DayDataSetType = (
  Params: Params,
  callback: (error: AxiosError | null, Resp: any | null) => any
) => any;
const Get14DayDataSet: Get14DayDataSetType = (Params, callback) => { 
  AxiosInstance.get(`/meteoblue/packages/trendpro-day`, {
    params: { lat: Params.lat, lon: Params.lng },
  })
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { Get14DayDataSet };
