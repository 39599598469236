import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { IProvider } from "models/provider";

interface Params {
  name?: string;
  is_active?: boolean;
  email?: string;
  phone?: string;
  sms_sig_name?: string;
  is_sub?: boolean;
  type?: string;
  store_gps_lat?: number | null;
  store_gps_lon?: number | null;
}

type AddProviderType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: any | null) => any
) => any;

const AddProvider: AddProviderType = (Params, callback) => {
  AxiosInstance.post("/organizations", { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { AddProvider };
