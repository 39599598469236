import {
  CheckboxProps,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuProps,
  Typography,
} from "@mui/material";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import { useStyles } from "./check-box-items-menu.style";

interface CheckItem extends CheckboxProps {
  label?: string;
  "data-cy"?: string;
}

export interface CheckBoxItemsMenuProps extends MenuProps {
  Items: CheckItem[];
  headerLabel?: string;
  onItemClick?: (index: number) => any;
  convertUnderscoreToSpace?: boolean;
  convertToUpperCase?: boolean;
}

const CheckBoxItemsMenu: React.FunctionComponent<CheckBoxItemsMenuProps> = (
  props
) => {
  const classes = useStyles();
  const {
    Items,
    headerLabel,
    onItemClick,
    convertUnderscoreToSpace,
    convertToUpperCase,
  } = props;

  const adjustedData = Items.map((item) => {
    if (convertUnderscoreToSpace) item["label"] = item.label?.replace("_", " ");
    if (convertToUpperCase) item["label"] = item.label?.toUpperCase();
    return item;
  });

  return (
    <Menu
      MenuListProps={{ disablePadding: true, className: classes.listRoot }}
      className={classes.root}
      PaperProps={{
        className: classes.paperRoot,
      }}
      {...props}
    >
      <ListItem className={classes.headerRoot}>
        <Typography variant="subtitle1" className={classes.headerText}>
          {headerLabel}
        </Typography>
      </ListItem>
      {adjustedData.map(({ label, ...item }, index) => (
        <ListItemButton
          onClick={() => onItemClick?.(index)}
          key={index}
          dense
          className={classes.itemRoot}
          data-cy={item?.["data-cy"]}
        >
          <ListItemIcon>
            <CustomCheckBox {...item} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: "body1" }}
            primary={label}
          />
        </ListItemButton>
      ))}
    </Menu>
  );
};

export default CheckBoxItemsMenu;

CheckBoxItemsMenu.defaultProps = {
  headerLabel: "Add/Remove Columns",
  convertToUpperCase: true,
  convertUnderscoreToSpace: true,
};
