import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { SurveyQuestionAnswer } from "../../models/survey-questions-answer";

interface Resp { }
interface Params {
  survey_id: string;
  question_id: string;
  question?: string;
  sort_order?: number;
}

interface TextQuestionParams {
  type?: "free text" | undefined;
  default_next_qid?: string;
}
interface SingleChoiceParams {
  type?: "single choice";
  answer_list: Pick<SurveyQuestionAnswer, "answer" | "next_qid">[];
}

type UpdateSurveyQuestionType = (
  Params: Params & (SingleChoiceParams | TextQuestionParams),
  callback: (error: AxiosError | null, Resp: Resp | null) => any
) => any;
const UpdateSurveyQuestion: UpdateSurveyQuestionType = (Params, callback) => {
  AxiosInstance.patch(
    `/survey-questions/${Params.survey_id}/${Params.question_id}`,
    { ...Params }
  )
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { UpdateSurveyQuestion };
