import { Alert, Box, Grid } from "@mui/material";
import React from "react";
import CancelSuccessButtonsGroup from "../../custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomDialogV2 from "../../custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CustomTextField from "../../custom-compnents/custom-text-field/custom-text-field";
import { IParams, useLogic } from "./affiliate-organization-dialog.logic";

export interface AffiliateOrganizationDialogProps extends IParams {
  sub_org_name?: string;
  open?: boolean;
  onCancel?: () => any;
}

const AffiliateOrganizationDialog: React.FunctionComponent<AffiliateOrganizationDialogProps> =
  (props) => {
    const { sub_org_name, open, onCancel, ...rest } = props;
    const { form } = useLogic(rest);

    return (
      <CustomDialogV2
        open={open ?? false}
        header={`Affilate ${sub_org_name} To Organization`}
        footer={
          <Box display="flex" justifyContent="flex-end">
            <CancelSuccessButtonsGroup
              RootGridProps={{ width: 300 }}
              CancelButtonProps={{ label: "Cancel", onClick: onCancel }}
              SuccessButtonProps={{
                label: "Affiliate",
                onClick: () => form.submitForm(),
                disabled: form.isSubmitting,
              }}
            />
          </Box>
        }
      >
        <Box width={500} my={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>

              <Alert variant="standard" severity="info">
                Insert UUID provided by super organization
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                placeholder="UUID"
                fullWidth
                value={form.values["super_org"]}
                error={Boolean(form?.errors?.super_org)}
                helperText={form?.errors?.super_org}
                name="super_org"
                onChange={form.handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      </CustomDialogV2>
    );
  };

export default AffiliateOrganizationDialog;
