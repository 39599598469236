import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Params {
  id: string;
  provider_id?: string;
  code: string;
  comment: string;
}

type UpdateProviderCodeType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: any | null) => any
) => any;
export const UpdateProviderCode: UpdateProviderCodeType = (
  Params,
  callback
) => {
  AxiosInstance.patch("provider-codes/" + Params.id, Params)
    .then((resp) => {
      callback(null, resp);
    })
    .catch((error) => {
      callback(error, null);
    });
};
