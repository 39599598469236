import {
  Box,
  FormControlLabel,
  Grid,
  GridProps,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React from "react";
import CustomDialog, {
  CustomDialogProps,
} from "components/custom-compnents/custom-dialog/custom-dialog";
import * as yup from "yup";
import {
  AddBroadcastsMessages,
  ParamsUnion,
} from "../../../api/broadcasts-messages/add-broadcasts-messages";
import { GetBroadcastsMessages } from "../../../api/broadcasts-messages/get-broadcasts-messages";
import {
  UpdateBroadcastsMessages,
  ParamsUnion as UpdateParamsUnion,
} from "../../../api/broadcasts-messages/update-broadcasts-messages";
import { broadcast } from "../../../models/broadcast";
import { GetBroadcasts } from "../../../api/broadcasts/get-broadcasts";
import moment from "moment";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
interface AddUpdateBroadcastMessageProps {
  onCancel: () => any;
  broadcast_id: string;
  open: boolean;
}
interface AddBroadcastMessageProps {
  onAdded: () => any;
  Variant: "Add";
}

interface UpdateBroadcastMessageProps {
  onUpdated: () => any;
  message_id: string;
  Variant: "Update";
}

export type combinedProps = AddUpdateBroadcastMessageProps &
  (AddBroadcastMessageProps | UpdateBroadcastMessageProps);

const AddUpdateBroadcastMessage: React.FunctionComponent<combinedProps> = ({
  onCancel,
  broadcast_id,
  open,
  ...Props
}) => {
  const [InitalValues, SetInitalValues] = React.useState({
    message: "",
    subject: "",
    is_active: false,
    send_on_day_absolute: "",
    send_on_day_relative: 0,
    relative_send_minute: 0,
    relative_send_hour: 0,
  });
  const [IsSubmitting, SetIsSubmitting] = React.useState(false);
  const [IsLoadingData, SetIsLoadingData] = React.useState(true);
  const [MessageBroadcast, SetMessageBroadcast] =
    React.useState<broadcast | undefined>(undefined);

  // const DialogProps: CustomDialogProps = {
  //   Header: "Add New Message",
  //   ActionButtonsProps: {
  //     ButtonsVariant: "ConfirmCancel",
  //     OnCancel: onCancel,
  //     DisableConfirm: IsSubmitting,
  //     OnConfirm: () =>
  //       document.getElementById("addBroadcastMessageSubmitButton")?.click(),
  //   },
  //   open: open,
  // };

  React.useEffect(() => {
    GetBroadcasts(
      { id: broadcast_id, page: 0, pageSize: 10 },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          SetMessageBroadcast(resp.items[0]);
        }
      }
    );
  }, [broadcast_id]);
  const DataArray = [
    {
      name: "subject",
      placeholder: "Insert Message Subject",
      label: "Message Subject",
      type: "text",
    },
    {
      name: "message",
      placeholder: "Insert Message Here ...",
      label: "Message Body",
      multiline: true,
      type: "text",
    },
    {
      name: "send_on_day_absolute",
      placeholder: "Insert Send Date",
      label: "Insert Date",
      type: "datetime-local",
    },
    {
      name: "send_on_day_relative",
      type: "number",
      placeholder: "Insert Day Relative",
      label: "Day",
      GridProps: { xs: 4 } as GridProps,
    },
    {
      name: "relative_send_hour",
      type: "number",
      placeholder: "Insert Hour Relative",
      label: "Hour",
      GridProps: { xs: 4 } as GridProps,
    },
    {
      name: "relative_send_minute",
      type: "number",
      placeholder: "Insert Min Relative",
      label: "Min",
      GridProps: { xs: 4 } as GridProps,
    },
    {
      name: "is_active",
      type: "checkbox",
      defaultValue: true,
      placeholder: "",
      label: "Is Active",
    },
  ];

  const ValidationSchema = yup.object().shape({
    subject: yup.string().required(),
    message: yup.string().required(),
    send_on_day_absolute: !MessageBroadcast?.relative_send_dates
      ? yup.string().required()
      : yup.mixed(),
    send_on_day_relative: MessageBroadcast?.relative_send_dates
      ? yup.string().required()
      : yup.mixed(),
    relative_send_hour: MessageBroadcast?.relative_send_dates
      ? yup.string().required()
      : yup.mixed(),
    relative_send_minute: MessageBroadcast?.relative_send_dates
      ? yup.string().required()
      : yup.mixed(),
  });

  React.useEffect(() => {
    if (Props.Variant === "Update") {
      SetIsLoadingData(true);
      GetBroadcastsMessages(
        {
          page: 0,
          pageSize: 10,
          broadcast_id: broadcast_id,
          id: Props?.message_id,
        },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            // Default set the first item in the array
            const firstItem = resp.items[0];

            SetIsLoadingData(false);
            SetInitalValues({
              message: firstItem?.message,
              is_active: firstItem?.is_active,
              send_on_day_absolute:
                firstItem?.send_on_day_absolute &&
                moment
                  .utc(firstItem?.send_on_day_absolute)
                  .local()
                  .format("YYYY-MM-DDTHH:mm"),
              subject: firstItem?.subject,
              send_on_day_relative: firstItem?.send_on_day_relative,
              relative_send_hour: firstItem?.relative_send_hour,
              relative_send_minute: firstItem?.relative_send_minute,
            });
          }
        }
      );
    }
  }, [Props.Variant === "Update" && Props.message_id]);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={InitalValues}
      onSubmit={(values, action) => {
        SetIsSubmitting(true);
        if (Props.Variant === "Add") {
          const Params: ParamsUnion = MessageBroadcast?.relative_send_dates
            ? {
                relative_send_hour: values.relative_send_hour,
                relative_send_minute: values.relative_send_minute,
                send_on_day_relative: values.send_on_day_relative,
                type: "relative",
                broadcast_id: broadcast_id,
                message: values.message,
                subject: values.subject,
                is_active: values.is_active,
              }
            : {
                send_on_day_absolute: moment(values.send_on_day_absolute)
                  .utc()
                  .format(),
                type: "absolute",
                broadcast_id: broadcast_id,
                message: values.message,
                subject: values.subject,
                is_active: values.is_active,
              };
          AddBroadcastsMessages(Params, (error, resp) => {
            if (error) {
              console.log(error);
            } else if (resp) {
              action.resetForm();
              SetIsSubmitting(false);
              Props.onAdded();
            }
          });
        }
        if (Props.Variant === "Update") {
          const Params: UpdateParamsUnion =
            MessageBroadcast?.relative_send_dates
              ? {
                  relative_send_hour: values.relative_send_hour,
                  relative_send_minute: values.relative_send_minute,
                  send_on_day_relative: values.send_on_day_relative,
                  type: "relative",
                  broadcast_id: broadcast_id,
                  message: values.message,
                  subject: values.subject,
                  id: Props.message_id,
                  is_active: values.is_active,
                }
              : {
                  send_on_day_absolute: moment(values.send_on_day_absolute)
                    .utc()
                    .format(),
                  type: "absolute",
                  broadcast_id: broadcast_id,
                  message: values.message,
                  subject: values.subject,
                  id: Props.message_id,
                  is_active: values.is_active,};

          UpdateBroadcastsMessages(Params, (error, resp) => {
            if (error) {
              console.log(error.message);
            } else if (resp) {
              SetIsSubmitting(false);
              Props.onUpdated();
            }
          });
        }
      }}
      validateOnBlur={false}
      validationSchema={ValidationSchema}
      validateOnChange={false}
    >
      {(FormikProps) => (
        <CustomDialogV2
          open={open}
          header={
            Props.Variant === "Add"
              ? "Add New Message"
              : "Edit Broadcast Message"
          }
          footer={
            <Box display="flex" justifyContent="flex-end">
              <CancelSuccessButtonsGroup
                RootGridProps={{ width: 300 }}
                CancelButtonProps={{ onClick: onCancel, label: "Cancel" }}
                SuccessButtonProps={{
                  onClick: () => FormikProps.submitForm(),
                  disabled: IsSubmitting,
                  label: Props.Variant === "Add" ? "Add Message" : "Save",
                }}
              />
            </Box>
          }
        >
          {/* <CustomDialog {...DialogProps}> */}
          {IsLoadingData && Props.Variant === "Update" ? (
            <Typography>Loading ...</Typography>
          ) : (
            <form onSubmit={FormikProps.handleSubmit}>
              <Box width={350}>
                <Grid container spacing={2}>
                  {DataArray.map((item, index) => {
                    const Textfieldtype = item.multiline
                      ? TextField
                      : CustomTextField;
                    if (item.type === "checkbox") {
                      return (
                        <FormControlLabel
                          style={{
                            marginBottom: 0,
                            marginLeft: 5,
                            marginTop: 5,
                          }}
                          checked={(FormikProps.values as any)[item.name]}
                          control={<Switch name="is_active" />}
                          label={item.label}
                          onChange={FormikProps.handleChange}
                        />
                      );
                    }
                    return (
                      ((MessageBroadcast?.relative_send_dates === false &&
                        item.name != "send_on_day_relative" &&
                        item.name != "relative_send_hour" &&
                        item.name != "relative_send_minute") ||
                        (MessageBroadcast?.relative_send_dates &&
                          item.name != "send_on_day_absolute")) && (
                        <Grid
                          item
                          key={index}
                          {...(item.GridProps ?? { xs: 12 })}
                        >
                          <Textfieldtype
                            value={(FormikProps as any).values[item.name]}
                            error={Boolean(
                              (FormikProps as any).errors[item.name]
                            )}
                            helperText={(FormikProps as any).errors[item.name]}
                            onChange={FormikProps.handleChange}
                            placeholder={item.placeholder}
                            fullWidth
                            name={item.name}
                            size="small"
                            autoFocus={index === 0}
                            multiline={item.multiline}
                            rows={item.multiline ? 4 : undefined}
                            type={item.type}
                            {...(item.type === "number"
                              ? { inputProps: { min: 0 } }
                              : {})}
                          />
                        </Grid>
                      )
                    );
                  })}
                </Grid>
              </Box>
              <button
                style={{ display: "none" }}
                type="submit"
                id="addBroadcastMessageSubmitButton"
              />
            </form>
          )}
          {/* </CustomDialog> */}
        </CustomDialogV2>
      )}
    </Formik>
  );
};

export default AddUpdateBroadcastMessage;
