import Cookies from "js-cookie";

export class authTokenService {
  static SaveTokenLocally(token: string) {
    Cookies.set("token", token);
  }
  static RemoveTokenLocally() {
    Cookies.remove("token");
  }
  static GetTokenLocally() {
    return Cookies.get("token");
  }
}
