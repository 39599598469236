import React, { useEffect } from "react";
import { GeoJSON } from "react-leaflet";
import Leaflet from "leaflet";
import ColorPercentageRange from "./utils/colorRangePercentageFunction";
import { IUser } from "models/user";
import { GetWardsGeojson } from "api/geojson/wards-geojson";
import { getFarmersKpi } from "api/kpis/farmers";
export interface WardLayerProps {
  SelectedConstituenciesArray: any[];
  onClick: (Ward: any) => void;
  SelectedWardsArray: any[];
  LastClickedConstituency: any;
  onFarmersPerWardCountUpdate: (data: any) => void;
  WardsFarmersCountObject: any;
  FarmersTotalCount: number;
  WardsGeoJson: any;
  onNewWardsGeoJson: (data: any) => void;
  UserMenuRole: IUser["menuroles"];
  UserProviderId: IUser["provider_id"];
}

const WardLayer: React.SFC<WardLayerProps> = ({ onClick, SelectedWardsArray, LastClickedConstituency, onFarmersPerWardCountUpdate, WardsFarmersCountObject, FarmersTotalCount, WardsGeoJson, onNewWardsGeoJson, UserMenuRole, UserProviderId }) => {
  useEffect(() => {
    if (LastClickedConstituency?.constituency_id) {
      GetWardsGeojson({
        constituency_id_list: [LastClickedConstituency.constituency_id]
      }, (error, resp) => {
        if (error) {
          console.log(error.message)
        } else {
          getFarmersKpi({
            per_ward: true,
            per_region: true,
            per_county: true,
            per_constituency: true,
            ward_id_list: resp.data.features.map((item: { properties: { ward_id: string } }) => item.properties.ward_id),
            provider_id: UserProviderId
          })
            .then(result => {
              onFarmersPerWardCountUpdate(result.data.data.cube.reduce((prevObj: any, item: any) => ({ ...prevObj, [`ward_${item.wards.ward_id}`]: { totalCount: item.farmers.unique_farmer_count } }), {}))
            })
            .catch(err => {
              console.log(err, 'err')
            })
          onNewWardsGeoJson(resp?.data);
        }
      })

    }

  }, [LastClickedConstituency, UserProviderId]);

  // add hover effect
  const highlightFeature = (e: any) => {
    const layer = e.target;

    layer.setStyle({
      color: "#666",
      dashArray: "",
    });

    if (!Leaflet.Browser.ie && !Leaflet.Browser.opera && !Leaflet.Browser.edge) {
      layer.bringToFront();
    }
  };

  // remove hover effect
  const resetHighlight = (e: any) => {
    const layer = e.target;
    const Ward_Id = layer.feature.properties.ward_id;
    const Current_Ward_Farmers_Count = parseInt(WardsFarmersCountObject![`ward_${Ward_Id}`]?.totalCount || 0);
    const FarmersPercentageInCurrentWard = (Current_Ward_Farmers_Count * 100) / FarmersTotalCount || 0;
    layer.setStyle({
      color: ColorPercentageRange(FarmersPercentageInCurrentWard),
    });
  };

  // handle click on Ward
  const HandleClick = async (e: any) => {
    !SelectedWardsArray.some((item) => item.ward_id === e.target.feature.properties.ward_id) && // only click if it's not already selected
      onClick(e.target.feature.properties);
  };

  const GeoJSONProps: any = {
    onEachFeature: (feature: any, layer: any) => {
      const Ward_Id = feature.properties.ward_id;
      const FarmersCountPerWard = WardsFarmersCountObject![`ward_${Ward_Id}`]?.totalCount || 0;
      layer.bindTooltip(`<h5>${feature.properties.ward} ward</h5> <p>${feature.properties.region} * ${feature.properties.county} * ${feature.properties.constituency}</p> (${FarmersCountPerWard} farmer)`);
      layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: HandleClick,
      });
    },

    style: (feature: any) => {
      const Ward_Id = feature.properties.ward_id;
      const Current_Ward_Farmers_Count = parseInt(WardsFarmersCountObject![`ward_${Ward_Id}`]?.totalCount || 0);
      const FarmersPercentageInCurrentWard = (Current_Ward_Farmers_Count * 100) / FarmersTotalCount || 0;
      return {
        fillColor: SelectedWardsArray.some((item) => item.ward_id === Ward_Id) ? "transparent" : ColorPercentageRange(FarmersPercentageInCurrentWard),
        weight: 2,
        opacity: 1,
        color: SelectedWardsArray.some((item) => item.ward_id === Ward_Id) ? "blue" : ColorPercentageRange(FarmersPercentageInCurrentWard),
        interactive: !SelectedWardsArray.some((item) => item.ward_id === Ward_Id),
        dashArray: "0",
        fillOpacity: 0.7,
      };
    },
    data: WardsGeoJson,
    key: Math.random(),
    // filter: (geoJsonFeature) =>
    //     WardsFarmersCountObject[`ward_${geoJsonFeature.properties.ward_id}`]?.totalCount > 0 &&
    //     !SelectedWardsArray.some((item) => item.ward_id === geoJsonFeature.properties.ward_id) &&
    //     SelectedConstituenciesArray.some(
    //         (item) => item.constituency_id === geoJsonFeature.properties.constituency_id
    //     ),
  };

  return <GeoJSON {...GeoJSONProps} />;
};

export default WardLayer;
