import { ButtonProps, Container, Grid } from "@mui/material";
import AddRemoveColumnsMenu from "../check-box-items-menu/check-box-items.menu";
import CreateButton from "../create-button/create-button";
import CustomMenuButton from "../custom-menu-button/custom-menu-button";
import FiltersButton from "../filter-button/filter-button";
import ImportButton from "../import-button/import-button";
import * as React from "react";
import ShinyButton, { ShinyButtonProps } from "../shiny-button/shiny-button";
import CustomShinySelectedButton from "../custom-shiny-selected-button/custom-shiny-selected-button";

export interface columnsState {
  field: string;
  checked: boolean;
}
export interface CustomFarmersTableActionBarProps {
  addButtonProps?: ButtonProps & { "data-cy"?: string };
  importButtonProps?: ButtonProps & { "data-cy"?: string };
  importLabelsButtonProps?: ButtonProps & { "data-cy"?: string };
  filterButtonProps?: ButtonProps & { "data-cy"?: string };
  columnButtonProps?: ButtonProps & { "data-cy"?: string };
  columnsState?: columnsState[];
  onColumnsStateChange?: (column: string, state: boolean) => any;
  isShowColumnButton?: boolean;
  isShowFilterButton?: boolean;
  isShowImportButton?: boolean;
  isShowImportLabelsButton?: boolean;
  isShowCreateButton?: boolean;
  isShowOnlySelectedFarmers?: boolean;
  showOnlySelectedFarmersButtonProps?: ShinyButtonProps;
}

const CustomFarmersTableActionBar: React.FunctionComponent<
  CustomFarmersTableActionBarProps
> = (props) => {
  const {
    importButtonProps,
    addButtonProps,
    columnButtonProps,
    filterButtonProps,
    columnsState,
    onColumnsStateChange,
    isShowColumnButton,
    isShowCreateButton,
    isShowFilterButton,
    isShowImportButton,
    isShowOnlySelectedFarmers,
    showOnlySelectedFarmersButtonProps,
    importLabelsButtonProps,
    isShowImportLabelsButton,
  } = props;

  const [columnsMenuAchorEl, setColumnsMenuAchorEl] = React.useState(undefined);
  const handleRemoveColumnsMenuClose = () => setColumnsMenuAchorEl(undefined);
  const handleColumnsButtonClick = (e: any) =>
    setColumnsMenuAchorEl(e.currentTarget);

  const ButtonsData = [
    {
      label: "Columns",
      Component: CustomMenuButton,
      props: columnButtonProps,
      isShowing: isShowColumnButton,
    },
    {
      label: "filters",
      Component: FiltersButton,
      props: filterButtonProps,
      isShowing: isShowFilterButton,
    },
    {
      label: "import Contacts",
      Component: ImportButton,
      props: importButtonProps,
      isShowing: isShowImportButton,
    },
    {
      label: "import Labels",
      Component: ImportButton,
      props: importLabelsButtonProps,
      isShowing: isShowImportLabelsButton,
    },
    {
      label: "Create New Farmer",
      Component: CreateButton,
      props: addButtonProps,
      isShowing: isShowCreateButton,
    },
    {
      label: "Only Selected Farmers",
      isShowing: isShowOnlySelectedFarmers,
      Component: CustomShinySelectedButton,
      props: showOnlySelectedFarmersButtonProps,
    },
  ];

  return (
    <>
      <AddRemoveColumnsMenu
        convertToUpperCase={true}
        convertUnderscoreToSpace={true}
        Items={
          columnsState?.map((item, index) => ({
            checked: item.checked,
            label: item.field,
            "data-cy": `farmers-table-show-hide-columns-${item.field?.toLowerCase()}-option`,
          })) || []
        }
        onItemClick={(index) =>
          columnsState &&
          onColumnsStateChange &&
          onColumnsStateChange(
            columnsState[index].field,
            !columnsState[index].checked
          )
        }
        open={Boolean(columnsMenuAchorEl)}
        onClose={handleRemoveColumnsMenuClose}
        anchorEl={columnsMenuAchorEl}
      />
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        {ButtonsData.map(
          (item, index) =>
            item.isShowing && (
              <Grid item lg={isShowImportLabelsButton ? 2 : 3} md={6} xs={12}>
                <item.Component
                  fullWidth
                  onClick={(e) => {
                    if (item.label === "Columns") {
                      handleColumnsButtonClick(e);
                    }
                  }}
                  {...item.props}
                >
                  {item.label}
                </item.Component>
              </Grid>
            )
        )}
      </Grid>
    </>
  );
};

export default CustomFarmersTableActionBar;
