import { Box, CircularProgress, Typography } from "@mui/material";

interface LoadingProps {}

const Loading: React.FunctionComponent<LoadingProps> = () => {
  return (
    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="space-around"
      alignItems="center"
    >
      <Box textAlign="center">
        <CircularProgress color="error" />
        <Box textAlign="center" pt={1.5}>
          <Typography variant="h6">Loading ...</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Loading;
