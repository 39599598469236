import { findAll } from "api/message-template-category/findAll";
import { useDebounce } from "hooks/debounce-hook";
import { IMessageCategory } from "models/message-category";
import { IProvider } from "models/provider";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";

export interface IParams {
  viewType?: "all" | "whatsapp" | "sms";
  value?: string | string[] | IMessageCategory | IMessageCategory[];
}

export const useLogic = (params: IParams) => {
  let { value: propValue } = params;

  const [categories, setcategories] = useState<IMessageCategory[]>([{ id: "aea20081-1672-4b7e-b1d8-afc6723318b1", name: "Cat 1" }]);

  const user = useAppSelector((root) => root.appReducer.user);
  const [loading, setloading] = useState(false);
  const [searchTerm, setsearchTerm] = useState("");
  const debouncedTerm = useDebounce(searchTerm, 800);
  const [value, setvalue] = useState<IMessageCategory | IMessageCategory[] | undefined>();

  // convert value to IMessageCategory/IMessageCategory[] If It's string/string[]

  useEffect(() => {
    setvalue(undefined);
    if (propValue)
      if (typeof propValue === "string" || (Array.isArray(propValue) && (propValue as Array<IProvider | string>).every((item) => typeof item === "string") && propValue.length > 0)) {
        findAll({ page: 1, pageSize: 10, id_list: (typeof value === "string" ? [propValue] : propValue) as string[] }).then((resp) => {
          if (Array.isArray(propValue)) setvalue(resp.data.all_message_categories.nodes);
          else setvalue(resp.data.all_message_categories.nodes[0]);
        });
      } else {
        setvalue(propValue as IProvider | IProvider[]);
      }
  }, [JSON.stringify(propValue)]);

  // get searched options
  useEffect(() => {
    findAll({ page: 1, pageSize: 10, name: debouncedTerm }).then((resp) => {
      setcategories(resp.data.all_message_categories.nodes);
    });
  }, [debouncedTerm]);

  return { categories, loading, setsearchTerm, value };
};
