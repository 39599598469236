import { Alert, Button, Grid } from "@mui/material";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import { FunctionComponent } from "react";
import { IoCloudDownload, IoCloudUpload } from "react-icons/io5";
import { useLogic } from "./add-bulk-farmer-labels.logic";
import { useStyles } from "./add-bulk-farmer-labels.style";

export interface AddBulkFarmerLabelsProps {
  onCancel?: () => any;
  open?: boolean;
  onAdded?: () => any;
}

const AddBulkFarmerLabels: FunctionComponent<AddBulkFarmerLabelsProps> = (
  props
) => {
  const { onCancel, open, onAdded } = props;
  const {
    handleDownloadTemplate,
    handleClickUploadButton,
    handleUploadPrepare,
    error,
    handleSetError,
    dataToUpload,
    handleUpload,
    isSubmitting,
  } = useLogic({ onAdded });

  const classes = useStyles();
  return (
    <CustomDialogV2
      PaperProps={{ sx: { width: 550, maxWidth: "100%" } }}
      header="Import Farmer Labels"
      open={open ?? false}
      footer={
        <CancelSuccessButtonsGroup
          CancelButtonProps={{ label: "Cancel", onClick: onCancel }}
          SuccessButtonProps={{
            onClick: handleUpload,
            label: "Add Labels",
            disabled: dataToUpload.length === 0 || isSubmitting,
            "data-cy": "import-labels-submit-button",
          }}
        />
      }
    >
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={7}>
          <Button
            onClick={handleClickUploadButton}
            startIcon={
              <>
                <IoCloudUpload />
              </>
            }
            color="secondary"
            fullWidth
            className={classes.buttonRoot}
          >
            Upload Excel File
          </Button>
        </Grid>

        <Grid item xs={5}>
          <Button
            onClick={handleDownloadTemplate}
            startIcon={
              <>
                <IoCloudDownload />
              </>
            }
            color="secondary"
            fullWidth
            className={classes.buttonRoot}
          >
            Download Template
          </Button>
        </Grid>
        {dataToUpload.length > 0 && (
          <Grid item xs={12}>
            <Alert
              severity="success"
              data-cy="import-labels-file-success-alert"
            >
              You Are About to Upload {dataToUpload.length} Labels
            </Alert>
          </Grid>
        )}
        {error && dataToUpload.length === 0 && (
          <Grid item xs={12}>
            <Alert severity="error" data-cy="import-labels-file-fail-alert">
              {error}
            </Alert>
          </Grid>
        )}
      </Grid>
      <input
        onChange={(e) =>
          e.currentTarget.files &&
          handleUploadPrepare(e.currentTarget.files).then((message) => {
            if (message) handleSetError(message);
          })
        }
        type={"file"}
        id="labels_input"
        data-cy="labels-input"
        style={{ display: "none" }}
        accept=".xlsx, .xls, .csv"
      />
    </CustomDialogV2>
  );
};

export default AddBulkFarmerLabels;
