// import MaterialTable from "material-table";
// import { fields, Schema } from "./schema";
import * as React from "react";
// import { Box, Paper } from "@mui/material";
import AddUpdateCertificateComponent from "components/standalone-components/add-update-certificate-component/add-update-certificate-component";
import CustomCertificateTable from "components/custom-compnents/custom-certificate-table/custom-certificate-table";
import { useLogic } from "./certificate-table.logic";

interface CertificateTableProps {}

const CertificateTable: React.FunctionComponent<CertificateTableProps> = () => {
  // const [OpenAddCertificateDialog, SetOpenAddCertificateDialog] =
  //   React.useState(false);
  //  const [OpenUpdateCertificateDialog, SetOpenUpdateCertificateDialog] =
  //   React.useState(false);
  // const [CertificateIdToBeUpdated, SetCertificateIdToBeUpdated] =
  //   React.useState("");
  // const handleOpenAddCertificateDialog = () =>
  //   SetOpenAddCertificateDialog(true);
  // const handleCloseAddCertificateDialog = () => {
  //   SetOpenAddCertificateDialog(false);
  //   window.location.reload();
  // };
  // const handleOpenUpdateCertificateDialog = () =>
  //   SetOpenUpdateCertificateDialog(true);

  // const handleCancelUpdateCertificate = () =>
  //   SetOpenUpdateCertificateDialog(false);
  // const handleUpdated = () => {
  //   SetOpenUpdateCertificateDialog(false);
  //   window.location.reload();
  // };
  // const handleUpdateCertificateClick = (fields: fields) => {
  //   SetCertificateIdToBeUpdated(fields.id);
  //   handleOpenUpdateCertificateDialog();
  // };

  const {
    tableProps,
    addCertificateDialogProps,
    updateCertificateDialogProps,
  } = useLogic();

  return (
    // <Box p={2} component={Paper}>
    //   <AddUpdateCertificateComponent
    //     variant="add"
    //     open={OpenAddCertificateDialog}
    //     onCancel={handleCloseAddCertificateDialog}
    //     onAdded={handleCloseAddCertificateDialog}
    //   />
    //   <AddUpdateCertificateComponent
    //     certificate_id={CertificateIdToBeUpdated!}
    //     variant="update"
    //     open={OpenUpdateCertificateDialog}
    //     onCancel={handleCancelUpdateCertificate}
    //     onUpdated={handleUpdated}
    //   />
    //   <MaterialTable
    //     {...Schema({
    //       onAddButtonClick: handleOpenAddCertificateDialog,
    //       onUpdateButtonClick: handleUpdateCertificateClick,
    //     })}
    //   />
    // </Box>
    <>
      {updateCertificateDialogProps && (
        <AddUpdateCertificateComponent {...updateCertificateDialogProps} />
      )}
      <AddUpdateCertificateComponent {...addCertificateDialogProps} />
      <CustomCertificateTable {...tableProps} />
    </>
  );
};

export default CertificateTable;
