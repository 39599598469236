import { AxiosError } from "axios";
// import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";
// import { EnumType, jsonToGraphQLQuery } from "json-to-graphql-query";
import humanize from "humanize-graphql-response";
import { Farmer } from "../../models/farmers";
import _ from "lodash";

interface Params {
  with_farms?: boolean;
  with_providers?: boolean;
  with_farms_count?: boolean;
  with_location?: boolean;
  with_farmer_labels?: boolean;
  ageGroup?: string[];
  gender?: string[];
  ForceKeepEmptyArrayQuery?: boolean;
  NotDeleted?: boolean;
  Ids?: string[];
  Crops?: string[];
  Name?: string;
  FirstName?: string;
  LastName?: string;
  Names?: string[];
  Phone?: string;
  Phones?: string[];
  FarmsCount?: number;
  FarmsOperator?: "greaterThan" | "lessThan" | "greaterThanOrEqualTo" | "equalTo" | "lessThanOrEqualTo";
  page?: number;
  pageSize?: number;
  WardsIds?: string[];
  RegionsIds?: string[];
  CountiesIds?: string[];
  Providers_Ids?: string[];
  CounstituenciesIds?: string[];
  WardIdList?: string[];
  RegionIdList?: string[];
  CountyIdList?: string[];
  ConstituencieIdList?: string[];
  orderBy?: "NAME_ASC" | "NAME_DESC" | "PHONE_ASC" | "PHONE_DESC" | "SOURCE_ASC" | "SOURCE_DESC" | "FARMS_BY_FARMER_ID_COUNT_ASC" | "FARMS_BY_FARMER_ID_COUNT_DESC" | "ACCEPTED_TERMS_AT_ASC" | "ACCEPTED_TERMS_AT_DESC" | "FIRST_NAME_ASC" | "FIRST_NAME_DESC";
  labels?: {
    key_id: string;
    value: string[]
  }[];
  with_farm_activity_crops?: boolean;
}
interface Resp {
  totalCount: number;
  Farmers: Farmer[];
}
const GetFarmers = (Params: Params, callback: (error: null | AxiosError, data: null | Resp) => void) => {
  AxiosInstance.get("/farmers", {
    params: {
      page: Params.page,
      pageSize: Params.pageSize,
      first_name: Params.FirstName,
      last_name: Params.LastName,
      with_farms_count: Params.with_farms_count,
      with_farms: Params.with_farms,
      with_farmer_labels: Params.with_farmer_labels,
      with_location: Params.with_location,
      with_providers: Params.with_providers,
      with_farm_activity_crops: Params.with_farm_activity_crops,
      phone_list: Params.Phones,
      id_list: Params.Ids,
      crop_list: Params.Crops,
      farms_count: Params.FarmsCount ? +Params.FarmsCount : undefined,
      farms_count_operator: Params.FarmsOperator,
      ward_id_list: Params.WardsIds,
      constituency_id_list: Params.CounstituenciesIds,
      county_id_list: Params.CountiesIds,
      region_id_list: Params.RegionsIds,
      label_list: Params.labels,
      provider_id: Params?.Providers_Ids?.length ? Params.Providers_Ids[0] : undefined
    }
  })
    .then((resp) => {
      const farmers = humanize(resp.data.data.all_farmers).nodes.map((item: any) => ({
        ...item,
        birthYear: item.birth_year,
        firstName: item.first_name,
        lastName: item.last_name,
        acceptedTermsAt: item.accepted_terms_at,
        crops: item.crops ? _.uniqBy(item.crops, 'id') : []
      }));
      const totalCount = resp.data.data.all_farmers.total_count;

      callback(null, {
        Farmers: farmers,
        totalCount,
      });
    })
    .catch((error) => callback(error, null));
};

export { GetFarmers };
