import { Box, Button, ButtonProps, Grid, GridProps } from "@mui/material";
import { IoCreateOutline, IoTrashOutline } from "react-icons/io5";

interface EditButtonProps extends ButtonProps {
  label?: string;
}
interface DeleteButtonProps extends ButtonProps {
  label?: string;
}

export interface DeleteEditButtonGroupProps {
  RootGridProps?: GridProps;
  DeleteButtonProps: DeleteButtonProps;
  EditButtonProps: EditButtonProps;
}

const DeleteEditButtonGroup: React.FunctionComponent<
  DeleteEditButtonGroupProps
> = (props) => {
  const { DeleteButtonProps, EditButtonProps, RootGridProps } = props;
  const { label: Deleteabel, ...RestDeleteButtonProps } = DeleteButtonProps;
  const { label: Editlabel, ...RestEditButtonProps } = EditButtonProps;
  return (
    <Grid container spacing={2} justifyContent="flex-end" {...RootGridProps}>
      <Grid item xs={6}>
        {!RestEditButtonProps.hidden && (
          <Button
            fullWidth
            startIcon={<IoCreateOutline />}
            size="medium"
            color="secondary"
            {...RestEditButtonProps}
            data-cy="farmer-info-edit-button"
          >
            {Editlabel}
          </Button>
        )}
      </Grid>
      <Grid item xs={6}>
        {!RestDeleteButtonProps.hidden && (
          <Button
            startIcon={<IoTrashOutline />}
            fullWidth
            color="secondary"
            {...RestDeleteButtonProps}
            data-cy="farmer-info-delete-button"
          >
            {Deleteabel}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default DeleteEditButtonGroup;
