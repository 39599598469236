import { Box } from "@mui/system";
import DevicesTable from "components/data-tables/devices-table/devices-table";

interface DevicesScreenProps { }

const DevicesScreen: React.FunctionComponent<DevicesScreenProps> = () => {
  return (
    <Box>
      <DevicesTable ExcludeActions={["attach"]} />
    </Box>
  );
};

export default DevicesScreen;
