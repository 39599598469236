import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  answerRoot: {
    "&:hover": {
      backgroundColor: "#dbdbdb",
      opacity: 0.3,
      cursor: "pointer",
      borderRadius: 8,
    },
  },
});
