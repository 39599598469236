import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { USSDLOG } from "models/ussd-log";

interface Resp {
  items: USSDLOG[];
  page?: number;
  pages?: number;
  per_page?: number;
  total: number;
}
interface Params {
  page: number;
  pageSize: number;
  farmer_id?: string;
  sortInteraction: "ascending" | "descending";
}
type GetUssdLogType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: Resp | null) => any
) => any;
const GetUssdLog: GetUssdLogType = (Params, callback) => {
  AxiosInstance.get("/ussd-logs", { params: Params })
    .then((resp) => {
      const data = {
        items: resp.data.data.all_ussd_logs.nodes,
        total: resp.data.data.all_ussd_logs.total_count
      };
      callback(null, data);
    })
    .catch((error) => callback(error, null));
};

export { GetUssdLog };
