import {
  Box,
  Chip,
  Switch,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { Service } from "models/service";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
}

export interface Fields extends Service {
  selected: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onRequireFarmSwitchChange?: (service: Fields, state: boolean) => any;
  onRequireLocationSwitchChange?: (service: Fields, state: boolean) => any;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (
  params
) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "ID",
      field: "id",
      hidden: !params.columnState?.find((item) => item.field === "id")?.checked,
      render: (data) => data.id,
    },
    {
      title: "Name",
      field: "name",
      hidden: !params.columnState?.find((item) => item.field === "name")
        ?.checked,
      render: (data) => data.name,
    },
    {
      title: "PUBLISHED",
      field: "published",
      hidden: !params.columnState?.find((item) => item.field === "published")
        ?.checked,
      render: (data) =>
        data.published ? (
          <Chip variant="outlined" label="Published" />
        ) : (
          <Chip variant="outlined" label="Not Published" color="error" />
        ),
    },
    {
      title: "USSD POSITION",
      field: "ussd_position",
      hidden: !params.columnState?.find(
        (item) => item.field === "ussd_position"
      )?.checked,
      render: (data) => data.order,
    },
    {
      title: "REQUIRE LOCATION",
      field: "require_location",
      hidden: !params.columnState?.find(
        (item) => item.field === "require_location"
      )?.checked,
      render: (data) => (
        <Switch
          color="success"
          checked={data.require_location}
          onChange={(e, checked) =>
            params.onRequireLocationSwitchChange?.(data, checked)
          }
        />
      ),
    },
    {
      title: "REQUIRE FARM",
      field: "require_farm",
      hidden: !params.columnState?.find((item) => item.field === "require_farm")
        ?.checked,
      render: (data) => (
        <Switch
          color="success"
          checked={data.require_farm}
          onChange={(e, checked) =>
            params.onRequireFarmSwitchChange?.(data, checked)
          }
        />
      ),
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: (
        <CustomCheckBox
          onChange={(e, value) =>
            params.customSelection?.onSelectAllChange?.(params.data, value)
          }
          checked={params.data.every((item) => item.selected)}
          indeterminate={
            params.data.some((item) => item.selected) &&
            !params.data.every((item) => item.selected)
          }
        />
      ),
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => (
        <CustomCheckBox
          checked={data.selected}
          onChange={(e, value) =>
            params.customSelection?.onRowSelectChange?.(data, value)
          }
        />
      ),
    });
  }

  return {
    actions: [],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      sorting: false,
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
