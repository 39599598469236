import { Alert, Box, Grid } from "@mui/material";
import React from "react";
import CropSimulationInfoStackedList from "components/custom-compnents/crop-simulation-info-stacked-list/crop-simulation-info-stacked-list";
import CropModelSimulationChart from "components/custom-compnents/crop-simulation-chart/crop-simulation-chart";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";
import SmCropSimulationCharts from "components/custom-compnents/sm-crop-simulation-chart/sm-crop-simulation-chart";
import TraCropSimulationCharts from "components/custom-compnents/tra-crop-simulation-chart/tra-crop-simulation-chart";
import { useLogic } from "./crop-simulation-sub-page.logic";
import TwsoCropSimulationChart from "components/custom-compnents/twso-crop-simulation-chart/twso-crop-simulation-chart";
interface CropSimulationSubPageProps {
  farm_id: string;
  //   show?: Boolean;
}

const CropSimulationSubPage: React.FunctionComponent<CropSimulationSubPageProps> =
  ({ farm_id }) => {
    const {
      CropModelSimulationChartData,
      CropSimulationInfo,
      isLoading,
      smCropModelSimulationChartData,
      traCropModelSimulationChartData,
    } = useLogic({ farm_id });

    return (
      <Box>
        <Grid container spacing={2}>
          {!CropSimulationInfo?.modelled_crop && !isLoading && (
            <Grid item xs={12} md={6}>
              <Alert severity="warning">No Data To Show</Alert>
            </Grid>
          )}
          {CropSimulationInfo && !isLoading && (
            <>
              <Grid item md={4} lg={3} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomTitle label="CROP SIMULATION INFO" />
                  </Grid>
                  <Grid item xs={12}>
                    {CropSimulationInfo && (
                      <CropSimulationInfoStackedList {...CropSimulationInfo} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} lg={9} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomTitle label="CROP SIMULATION CHARTS" />
                  </Grid>
                  <Grid item xs={12}>
                    <CropModelSimulationChart
                      Data={CropModelSimulationChartData}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <TwsoCropSimulationChart
                      Data={CropModelSimulationChartData}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    <CustomTitle label="SM Crop Simulation Chart" />
                  </Grid> */}
                  <Grid item xs={12}>
                    <SmCropSimulationCharts
                      Data={smCropModelSimulationChartData}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TraCropSimulationCharts
                      Data={traCropModelSimulationChartData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    );
  };

export default CropSimulationSubPage;
