import { InputBase, InputBaseProps } from "@mui/material";
import React from "react";
import CustomLabel from "../custom-label/custom-label";
import { useStyle } from "./custom-base-input.style";

export interface CustomBaseInputProps extends InputBaseProps {
  helperText?: string;
  "data-cy"?: string;
}

const CustomBaseInput: React.FunctionComponent<CustomBaseInputProps> =
  React.forwardRef((props: CustomBaseInputProps, ref) => {
    const { helperText, error } = props;
    const classes = useStyle();
    return (
      <>
        <InputBase
          {...props}
          ref={ref}
          className={`${classes.root} ${props.className}`}
          inputProps={{ className: classes.inputRoot, ...props.inputProps }}
        />
        {helperText && (
          <CustomLabel
            label={helperText}
            variant={error ? "error" : "success"}
          />
        )}
      </>
    );
  });

export default CustomBaseInput;
