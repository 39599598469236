import { fetchUserBalance } from "api/ewallet-balance/get-ewallet-balance";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";

export interface IParams {
  id?: string;
}

export const useLogic = (params: IParams) => {
  const { id } = params;

  const user = useAppSelector((root) => root.appReducer.user);

  const [balance, setbalance] = useState(0);

  useEffect(() => {
    fetchUserBalance(id ?? user?.provider_id!).then((resp) => {
      if (resp.data) setbalance(resp.data.balance);
    });
  }, [user?.provider_id]);

  return { balance };
};
