import { Dispatch } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { Farm } from "api/farm/get-farm-info";

export interface FarmPageStateType {
  SelectedTab: number;
  CurrentFarm: Farm | null;
}

export interface FarmPageStateDispatcherType {
  SetFarmPageState: (FarmState: Partial<FarmPageStateType>) => void;
}

export interface FarmPageStateProviderType
  extends Record<"FarmPageState", FarmPageStateType>,
    FarmPageStateDispatcherType {}

export default (
  state: FarmPageStateType = { SelectedTab: 0, CurrentFarm: null },
  action: any
) => {
  return action.type === "setFarmPageState"
    ? { ...state, ...action.payload }
    : state;
};

const ProvideFarmPageStateTo = (Component: any, ProvideState = false) => {
  const mapStateToProps = (state: any) => {
    return ProvideState
      ? { FarmPageState: state.FarmPageState as FarmPageStateType }
      : {};
  };

  const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
      SetFarmPageState: (value: Partial<FarmPageStateType>) =>
        dispatch({ type: "setFarmPageState", payload: value }),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export { ProvideFarmPageStateTo };
