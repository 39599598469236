import { Breadcrumbs, Link, Skeleton, Typography } from "@mui/material";
import { IoChevronForwardSharp } from "react-icons/io5";
import { useLogic } from "./breadcrumb-component.logic";

import useStyles from "./breadcrumb-component.style";

interface BreadcrumbComponentProps {}

const BreadcrumbComponent: React.FunctionComponent<BreadcrumbComponentProps> =
  () => {
    const classes = useStyles();
    const { breadcrumbs, isLoading } = useLogic();

    return isLoading ? (
      <Skeleton width={100} />
    ) : (
      <Breadcrumbs
        sx={{ display: "inline-block" }}
        separator={<IoChevronForwardSharp />}
        aria-label="breadcrumb"
      >
        {breadcrumbs.map((item, index) =>
          index < breadcrumbs.length - 1 ? (
            <Link
              className={`${classes.textRoot} ${classes.textNormal}`}
              key={item.key}
              underline="hover"
              color="inherit"
              href={item.match.pathname}
            >
              {item.breadcrumb}
            </Link>
          ) : (
            <Typography
              className={`${classes.textRoot} ${classes.textHighlighted}`}
              key={index}
            >
              {item.breadcrumb}
            </Typography>
          )
        )}
      </Breadcrumbs>
    );
  };

export default BreadcrumbComponent;
