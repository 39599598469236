export interface IParams {
  questionValue?: string;
  answersNumber?: number;
  questionTypeValue?: "single choice" | "free text";
  onQuestionValueChange?: (value: string) => any;
  onQuestionTypeChange?: (value: "single choice" | "free text") => any;
}

export const useLogic = (params: IParams) => {
  const questionData = [
    {
      label: "Question Text",
      isEditable: true,
      value: params.questionValue,
      type: "text",
      onChange: (e: any) =>
        params.onQuestionValueChange?.(e.currentTarget.value),
    },
    {
      label: "Question Type",
      isEditable: true,
      value: params.questionTypeValue,
      type: "menu",
      options: [
        { title: "Single Choice Question", value: "single choice" },
        { title: "Text Question", value: "free text" },
      ],
      onChange: (e: any) => params.onQuestionTypeChange?.(e.target.value),
    },
    {
      label: "Number of Possible Answers ",
      isEditable: false,
      value: params.answersNumber,
      type: "",
    },
  ];
  return { questionData };
};
