import { AxiosInstance } from "configs/axios";
import { IEwalletTransaction } from "models/ewallet-transaction";

export interface IResp {
  page: number;
  pages: number;
  per_page: number;
  total: number;
  items: IEwalletTransaction[];
}

export interface IParams {
  page: number;
  pageSize: 10 | 50 | 100;
  from_date?: string;
  to_date?: string;
  from_user?: string;
  to_user?: string;
  type?: "signup-credit" | "load-from-mpesa" | "withdraw-to-mpesa";
  transactions_status: 'all' | 'successful' | 'failed';
  sort_by?: string;
}

export const fetchEwalletTransactions = async (params: IParams) => await AxiosInstance.get<IResp>(`transactions`, { params });
