import MaterialTable from "@material-table/core";
import CustomPagnation from "components/custom-compnents/custom-pagnation/custom-pagnation";
import { Fields, Params, schema } from "./custom-messages-status-table.schema";
import { Box, Grid } from "@mui/material";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import { CustomPagnationProps } from "components/custom-compnents/custom-pagnation/custom-pagnation";
import CustomFiltersContainer, { CustomFiltersContainerProps } from "../custom-filters-container/custom-filters-container";
import CustomMessagesStatusTableActionBar, { CustomMessagesStatusTableActionBarProps } from "components/custom-compnents/custom-messages-status-table-action-bar/custom-messages-status-table-action-bar";

export interface CustomMessagesStatusTableProps {
  countLabelProps?: CountLabelProps;
  showFilterArea?: boolean;
  filterContainerProps?: CustomFiltersContainerProps;
  pagnationProps?: CustomPagnationProps;
  actionBarProps?: CustomMessagesStatusTableActionBarProps;
  data: Fields[];
  isLoading?: boolean;
  customSelection?: Params["customSelection"];
  onOrderChange?: (orderBy: number, orderDirection: "desc" | "asc") => void | undefined;
  onDeleteButtonClick?: Params["onDeleteButtonClick"];
  onStatusClick?: Params["onStatusClick"];
}

const CustomMessagesStatusTable: React.FunctionComponent<CustomMessagesStatusTableProps> = (props) => {
  const { countLabelProps, showFilterArea, pagnationProps, filterContainerProps, actionBarProps, data, isLoading, customSelection, onOrderChange, onDeleteButtonClick, onStatusClick } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ paddingLeft: 0 }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ paddingLeft: 0 }}>
          <Grid item md={3} xs={12}>
            <CountLabel {...countLabelProps} label="Status" />
          </Grid>
          <Grid item md={9} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Box width={840} maxWidth="100%">
                <CustomMessagesStatusTableActionBar {...actionBarProps} />
              </Box>
            </Box>
          </Grid>
          {showFilterArea && (
            <Grid item xs={12}>
              <CustomFiltersContainer {...filterContainerProps}>{/* <FarmsActivitesTableFilterForm {...filterFormProps} /> */}</CustomFiltersContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          {...schema({
            onStatusClick,
            customSelection,
            data,
            columnState: actionBarProps?.columnsState,
            onDeleteButtonClick: onDeleteButtonClick,
          })}
          isLoading={isLoading}
          onOrderChange={onOrderChange}
        />
      </Grid>
      <Grid item xs={12}>
        {pagnationProps && <CustomPagnation {...pagnationProps} />}
      </Grid>
    </Grid>
  );
};

export default CustomMessagesStatusTable;
