import { Box, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { AddSurvey } from "../../../api/survey/add-survey";
import { UpdateSurvey } from "../../../api/survey/update-survey";
import { GetSurveys } from "../../../api/survey/get-survey";
import moment from "moment";
import * as React from "react";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import ProvidersInput from "components/input-components/providers-input/providers-input";
import { useAppSelector } from "store/hooks";

export interface AddUpdateSurveyProps {
  open: boolean;
  onCancel: () => any;
}

export interface AddSurveyProps {
  variant: "add";
  onAdded: () => any;
}

export interface UpdateSurveyProps {
  variant: "update";
  id: string;
  onUpdated: () => any;
}
export type CombinedType = AddUpdateSurveyProps & (AddSurveyProps | UpdateSurveyProps);
const AddUpdateSurvey: React.FC<CombinedType> = ({ open, onCancel, ...Props }) => {
  const [IsSubmitting, SetIsSubmitting] = React.useState<boolean>(false);

  const [LoadingSurveyInfo, SetLoadingSurveyInfo] = React.useState<boolean>(false);

  const user = useAppSelector((root) => root.appReducer.user);

  const [FomrikInitalData, SetFomrikInitalData] = React.useState({
    active: false,
    title: "",
    jump_code: "",
    provider: { name: user?.menuroles !== "admin" ? user?.providerName : "", id: user?.menuroles !== "admin" ? user?.provider_id : "none" },
    provider_id: "",
    start_time: "",
    expires_at: "",
  });

  const ValidationSchema = yup.object().shape({
    title: yup.string().required(),
    jump_code: yup.string().required("Jump code is required").max(10, "jump code must not be greater thatn 10 letters"),
    provider: yup.object().shape({
      id: yup.string().required().not(["none"], "Organization is required"),
    }),

    expires_at: yup.string().required("Expire At field is required"),
    start_time: yup.string().required("Start Time field is required"),
  });

  React.useEffect(() => {
    if (Props.variant === "update") {
      SetLoadingSurveyInfo(true);
      GetSurveys({ surveys_id_list: [Props.id], page: 0, pageSize: 10 }, (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          if (resp.items.length > 0)
            SetFomrikInitalData({
              ...resp.items[0],
              expires_at: moment.utc(resp.items[0].expires_at).local().format("YYYY-MM-DDTHH:mm:ss"),
              start_time: moment.utc(resp.items[0].start_time).local().format("YYYY-MM-DDTHH:mm:ss"),
            });
          SetLoadingSurveyInfo(false);
        }
      });
    }
  }, [Props.variant === "update" && Props.id]);

  return (
    <Formik
      validationSchema={ValidationSchema}
      initialValues={FomrikInitalData}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        SetIsSubmitting(true);
        if (Props.variant === "add") {
          AddSurvey(
            {
              ...values,
              expires_at: moment(values.expires_at).utc().format(),
              start_time: moment(values.start_time).utc().format(),
              provider_id: values.provider.id ?? "",
            },
            (error, resp) => {
              if (error) {
                // TODO: remove hardcoded message when the api is updated
                actions.setErrors({ jump_code: 'Jump code already exists' });
                SetIsSubmitting(false);
                console.log(error.message);
              } else {
                SetIsSubmitting(false);
                actions.resetForm();
                Props.onAdded();
              }
            }
          );
        } else if (Props.variant === "update") {
          UpdateSurvey(
            {
              ...values,
              expires_at: moment(values.expires_at).utc().format(),
              start_time: moment(values.start_time).utc().format(),
              id: Props.id,
              provider_id: values.provider.id ?? "",
            },
            (error, resp) => {
              if (error) {
                console.log(error.message);
              } else if (resp) {
                SetIsSubmitting(false);
                Props.onUpdated();
              }
            }
          );
        }
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(FomrikProps) => (
        <CustomDialogV2
          open={open}
          header="Add Survey"
          footer={
            <Box display="flex" justifyContent="flex-end">
              <CancelSuccessButtonsGroup
                RootGridProps={{ width: 300 }}
                CancelButtonProps={{ onClick: onCancel, label: "Cancel" }}
                SuccessButtonProps={{
                  onClick: () => FomrikProps.submitForm(),
                  disabled: IsSubmitting || LoadingSurveyInfo,
                  label: "Confirm",
                }}
              />
            </Box>
          }
        >
          <form onSubmit={FomrikProps.handleSubmit}>
            {Props.variant === "update" && LoadingSurveyInfo ? (
              <Typography>Loading ...</Typography>
            ) : (
              <Box maxWidth={350} mt={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomTextField helperText={FomrikProps.errors["title"]} error={Boolean(FomrikProps.errors["title"])} name="title" value={FomrikProps.values["title"]} onChange={FomrikProps.handleChange} fullWidth placeholder="Survey Title" autoFocus size="small" />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField helperText={FomrikProps.errors["jump_code"]} error={Boolean(FomrikProps.errors["jump_code"])} value={FomrikProps.values["jump_code"]} onChange={FomrikProps.handleChange} name="jump_code" size="small" fullWidth placeholder="Jump Code" />
                  </Grid>
                  {user?.menuroles === "admin" && (
                    <Grid item xs={12}>
                      <ProvidersInput onChange={(value) => FomrikProps.setFieldValue("provider", value)} error={Boolean(FomrikProps.errors["provider"])} value={FomrikProps.values["provider"]?.id} helperText={FomrikProps.errors["provider"]?.id} />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <CustomTextField
                      helperText={FomrikProps.errors["start_time"]}
                      error={Boolean(FomrikProps.errors["start_time"])}
                      value={FomrikProps.values["start_time"]}
                      onChange={FomrikProps.handleChange}
                      name="start_time"
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                      label="Start time"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      helperText={FomrikProps.errors["expires_at"]}
                      error={Boolean(FomrikProps.errors["expires_at"])}
                      value={FomrikProps.values["expires_at"]}
                      onChange={FomrikProps.handleChange}
                      name="expires_at"
                      InputLabelProps={{ shrink: true }}
                      type="datetime-local"
                      label="Expire at"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box mx={1}>
                      <FormControlLabel control={<Switch size="small" checked={FomrikProps.values["active"]} onChange={(e, value) => FomrikProps.setFieldValue("active", value)} />} label="Active?" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            <button type="submit" id="submit_button" style={{ display: "none" }} />
          </form>
        </CustomDialogV2>
      )}
    </Formik>
  );
};

export default AddUpdateSurvey;
