import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface breadcrumbItem {
  path: string;
  title: string;
}

export interface breadcrumb {
  isLoading?: boolean;
  items: breadcrumbItem[];
}

const initialState: breadcrumb = {
  isLoading: false,
  items: [],
};

export const breadcrumbState = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    setBreadcrumbData: (state, action: PayloadAction<breadcrumbItem[]>) => {
      state.items = action.payload;
    },
    UseResetBreadcrumb: (state) => {
      state.items = [];
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    removeLoading: (state) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBreadcrumbData, UseResetBreadcrumb, removeLoading, setLoading } =
  breadcrumbState.actions;

export default breadcrumbState.reducer;
