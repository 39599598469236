import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import humanize from "humanize-graphql-response";
import { IFarmerLabel } from "models/farmer-label";

interface Params {
  page?: number;
  pageSize?: number;
  farmer_ids?: string[];
  key_ids?: string[];
  value?: string;
  provider_id?: string;
  distinct?: boolean;
}
interface Resp {
  items: IFarmerLabel[];
  total: number;
}

interface FarmerLableDto {
  id: string;
  farmer_id: string;
  label_key_id: string;
  value: string;
}

type GetFarmerLabelsType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | Resp) => any
) => any;
export const GetFarmerLabelsDistinct: GetFarmerLabelsType = (
  Params,
  callback
) => {
  AxiosInstance.get("/farmer-labels/distinct", { params: Params })
    .then((resp) => {
      callback(null, {
        total: resp.data.total,
        items: resp.data.items.map((item: FarmerLableDto) => ({
          id: item.id,
          value: item.value,
          farmerId: item.farmer_id,
          labelKeyId: item.label_key_id,
        })),
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};
