import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Params {
    constituency_id_list?: string[];
    ward_id_list?: string[];
}

type GetWardsGeojsonType = (
    Params: Params,
    callback: (error: AxiosError | null, resp: null | any) => any
) => any;


const GetWardsGeojson: GetWardsGeojsonType = (Params, callback) => {
    AxiosInstance.get("/wards/geojson", {
        params: Params
    })
        .then((resp) => callback(null, resp))
        .catch((error) => callback(error, null));
};

export { GetWardsGeojson };
