import ShinyButton from "components/custom-compnents/shiny-button/shiny-button";
import CustomFarmerLabelsFilter from "components/custom-compnents/farmer-labels-filter/farmer-labels-filter";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import { IParams, useLogic } from "./farmer-labels-filter.logic";
import { isEqual } from "lodash";

export interface FarmerLabelsFilterProps extends IParams {
  label?: string;
  fullwidth?: boolean;
  "data-cy"?: string;
}

const FarmerLabelsFilter: React.FunctionComponent<FarmerLabelsFilterProps> = (
  props
) => {
  const { label, fullwidth, ...others } = props;
  const {
    open,
    handleOpenButtonClick,
    handleClose,
    handleSetKeySearch,
    loadingKeyOptions,
    keySelectedValue,
    handleKeySelectedValue,
    filterValueOptions,
    loadingValueOptions,
    valueSelectedValue,
    handleSetValueSearch,
    handlesetValueSelectedValue,
    isKeyValueFilled,
    handleAddEntry,
    tempComponentValue,
    handleClearTempValue,
    handleRemoveItem,
    filterKeyOptions,
    handleSubmit,
    keysPage,
    keysSetPage,
    valuesPage,
    valuesSetPage
  } = useLogic(others);

  return (
    <>
      <ShinyButton
        onClick={handleOpenButtonClick}
        isShiny={props.value.length > 0}
        fullWidth={fullwidth}
        data-cy={props?.["data-cy"]}
      >
        {label}
      </ShinyButton>
      <CustomDialogV2 open={open} header="Labels Filter">
        <CustomFarmerLabelsFilter
          isNoPadding={true}
          isEmptyWrapper={true}
          keyOptions={filterKeyOptions}
          keyValue={keySelectedValue}
          keyNoOptionsLabel={loadingKeyOptions ? "Loading ..." : undefined}
          valueNoOptionsLabel={loadingValueOptions ? "Loading ..." : undefined}
          valueOptions={filterValueOptions}
          valueInputDisabled={!keySelectedValue}
          addButtonProps={{
            onClick: handleAddEntry,
            disabled: !isKeyValueFilled,
            "data-cy": "farmers-filter-labels-add-entry-button",
          }}
          value={tempComponentValue}
          clearButtonProps={{
            disabled: tempComponentValue.length === 0,
            onClick: handleClearTempValue,
          }}
          submitButtonProps={{
            onClick: handleSubmit,
            disabled: isEqual(props.value, tempComponentValue),
            "data-cy": "farmers-filter-labels-submit-button",
          }}
          onItemDeleteClick={handleRemoveItem}
          onKeyChange={handleKeySelectedValue}
          valueValue={valueSelectedValue}
          onKeyInputTextChange={handleSetKeySearch}
          onValueChange={handlesetValueSelectedValue}
          onValueInputTextChange={handleSetValueSearch}
          cancelButtonProps={{
            onClick: handleClose,
          }}
          keysPage={keysPage}
          keysSetPage={keysSetPage}
          valuesPage={valuesPage}
          valuesSetPage={valuesSetPage}
          handleSetKeySearch={handleSetKeySearch}
          handleSetValueSearch={handleSetValueSearch}
        />
      </CustomDialogV2>
    </>
  );
};

export default FarmerLabelsFilter;

FarmerLabelsFilter.defaultProps = {
  label: "labels",
};
