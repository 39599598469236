import { Box, Grid } from "@mui/material";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";
import { LatLngBoundsExpression } from "leaflet";
import React, { FunctionComponent } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import HighResVectorCropTypeMapLayer from "./high-res-vector-crop-type-map.layer";
import Legend from "./high-res-vector-crop-type-map.legend";

interface HighResCropTypeMapProps {}

const HighResCropTypeMap: FunctionComponent<HighResCropTypeMapProps> = () => {
  const [bounds, setbounds] = React.useState<undefined | LatLngBoundsExpression>();
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <CustomTitle label="High Resolution Vector Crop Type Map" />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <MapContainer style={{ height: "700px", width: "1000px" }} bounds={bounds}>
            <HighResVectorCropTypeMapLayer onBounds={(bounds) => setbounds(bounds)} />
            <Legend />
            <TileLayer url={`https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?apiKey=${(window as any).env.REACT_APP_HERE_MAP_KEY}`} />
          </MapContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HighResCropTypeMap;
