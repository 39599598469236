import CustomSenderIdsTable from "components/custom-compnents/custom-sender-ids-table/custom-sender-ids-table";

import SenderDetailComponent from "components/standalone-components/sender-detail-component/sender-detail-component";

import AddUpdateSenderId from "components/standalone-components/add-update-sender-id/add-update-sender-id";

import RemoveSenderIdComponent from "components/standalone-components/remove-sender-id-component/remove-sender-id-component";

import React from "react";

import { useLogic } from "./senders-ids-table.logic";

interface SendersIdsTableProps {}

const SendersIdsTable: React.FunctionComponent<SendersIdsTableProps> = () => {
  const { tableProps, addDialogProps, updateDialogProps, deleteDialogProps, idToShowDetails, setidToShowDetails } = useLogic();

  return (
    <>
      {deleteDialogProps && <RemoveSenderIdComponent {...deleteDialogProps} />}
      {updateDialogProps && <AddUpdateSenderId {...updateDialogProps} />}
      <AddUpdateSenderId {...addDialogProps} />
      <SenderDetailComponent id={idToShowDetails} onHide={() => setidToShowDetails("")} open={!!idToShowDetails} />
      <CustomSenderIdsTable {...tableProps} />
    </>
  );
};

export default SendersIdsTable;
