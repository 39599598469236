import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
interface Resp { }
interface Params {
  broadcast_id?: string;
  id: string | string[];
}
type DeleteBroadcastSubscriptionType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | Resp) => any
) => any;
const DeleteBroadcastSubscription: DeleteBroadcastSubscriptionType = (
  Params,
  callback
) => {
  AxiosInstance.delete(
    "/sms-broadcast-subscriptions/" + Params.broadcast_id + "/" +
    (Array.isArray(Params.id) ? Params.id.join(",") : Params.id)
  )
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { DeleteBroadcastSubscription };
