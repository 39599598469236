import { DeleteSender } from "api/senders/delete-senders";
import { DeleteSurvey } from "api/survey/delete-survey";
import React from "react";

export interface IParams {
  onDeleted?: () => any;
  id: string;
}
export const useLogic = (params: IParams) => {
  const [isSubmittingDelete, setiIsSubmittingDelete] = React.useState(false);
  const { onDeleted, id } = params;

  const handleDeleteConfirm = () => {
    setiIsSubmittingDelete(true);

    DeleteSurvey({ id }, (error, resp) => {
      if (error) {
        console.log(error);
      } else {
        setiIsSubmittingDelete(false);
        onDeleted?.();
      }
    });
  };

  return { isSubmittingDelete, handleDeleteConfirm };
};
