import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { BroadcastMessage } from "../../models/broadcasts-message";

export type ParamsUnion = Params & (AbsoluteTimeParams | RelativeTimeParams);
interface Params
  extends Pick<BroadcastMessage, "subject" | "message" | "broadcast_id" | "is_active"> {
  sender_id?: string;
}

interface RelativeTimeParams
  extends Pick<
    BroadcastMessage,
    "relative_send_minute" | "relative_send_hour" | "send_on_day_relative"
  > {
  type: "relative";
}

interface AbsoluteTimeParams
  extends Pick<BroadcastMessage, "send_on_day_absolute"> {
  type: "absolute";
}
interface Resp { }
type AddBroadcastsMessagesType = (
  Params: ParamsUnion,
  callback: (Error: null | AxiosError, Resp: null | Resp) => any
) => any;
const AddBroadcastsMessages: AddBroadcastsMessagesType = (Params, callback) => {
  AxiosInstance.post("/sms-broadcast-messages", { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => callback(error, null));
};

export { AddBroadcastsMessages };
