import Axios, { AxiosError } from "axios";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import humanize from "humanize-graphql-response";
import { IProviderOptins } from "models/providers-optins";
import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";

interface Params {
  FarmersIds?: string[];
  ProvidersIds?: string[];
}

interface Resp {
  totalCount: number;
  allProviderOptins: IProviderOptins[];
}
type GetOptinsType = (
  Params: Params,
  callback: (Error: AxiosError | null, Resp: null | Resp) => any
) => any;
const GetOptins: GetOptinsType = (Params, callback) => {
  const JsonQuery = {
    query: {
      allProviderOptins: {
        totalCount: true,
        __args: {
          filter: {
            farmerId: { in: Params.FarmersIds },
            providerId: { in: Params.ProvidersIds },
          },
        },
        edges: {
          node: {
            id: true,
            farmerId: true,
            providerId: true,
            optinText: true,
          },
        },
      },
    },
  };
  const GraphqlQuery = jsonToGraphQLQuery(cleanDeep(JsonQuery), {
    pretty: true,
  });
  AxiosInstance.get("/ProviderOptins", { params: { GraphqlQuery } })
    .then((resp) =>
      callback(null, {
        totalCount: resp.data.allProviderOptins.totalCount,
        allProviderOptins: humanize(resp.data).allProviderOptins,
      })
    )
    .catch((error) => callback(error, null));
};

export { GetOptins };
