import CSVReader from "react-csv-reader";
import React from "react";
import AddUpdateFarmer from "components/standalone-components/Add-Update-Farmer/Add-Update-Farmer";
import {
  ActionsToExclude,
  CustomSelection,
  Params as duprecateParams,
} from "./schema";
import AddFarmers from "components/standalone-components/add-farmers/add-farmers";
import CustomFarmersTable from "components/custom-compnents/custom-farmers-table/custom-farmers-table";
import { IParams, useLogic } from "./farmers-table.logic";
import { Params } from "components/custom-compnents/custom-farmers-table/custom-farmers-table.schema";
import AddBulkFarmerLabels from "components/standalone-components/add-bulk-farmer-labels/add-bulk-farmer-labels";

export interface FarmersTableProps extends IParams {
  ActionsToExclude?: ActionsToExclude;
  OnSelection?: duprecateParams["OnSelection"];
  SelectedFarmersIds?: string[];
  SelectedFarmersPhones?: string[];
  CustomSelection?: CustomSelection;
  isLoading?: boolean;
  OnFilterSelectedFarmersButtonClicked?: duprecateParams["OnFilterSelectedFarmersButtonClicked"];
  ClickedFilterSelectedFarmersButtonView?: boolean;
  ForceKeepEmptyArrayQuery?: boolean;
  customSelection?: Params["customSelection"];
  isShowColumnButton?: boolean;
  isShowCreateButton?: boolean;
  isShowFilterButton?: boolean;
  isShowImportButton?: boolean;
  isShowImportLabelsButton?: boolean;
  isShowOnlySelectedFarmersFilter?: boolean;
}

const FarmersTable: React.SFC<FarmersTableProps> = ({
  Proiders_Ids,

  SelectedFarmersIds,
  customSelection,
  SelectedFarmersPhones,
  isShowColumnButton,
  isShowCreateButton,
  isShowFilterButton,
  isShowImportButton,
  isShowOnlySelectedFarmersFilter,
  isShowImportLabelsButton,
}) => {
  const {
    data,
    total,
    currentPage,
    pageSize,
    handleNextPageClick,
    handlePreviousPageClick,
    isFirstPage,
    isLastPage,
    handlePerSizeChange,
    openAddFarmer,
    handleCloseAddFarmerModal,
    handleFarmerAdded,
    handleAddFarmerClicked,
    isOpenFilterArea,
    handleFilterButtonClick,
    CsvReaderProps,
    phoneNumbersFilterValue,

    isOpenAddBulkFarmers,
    handleBulkFarmersAdded,
    handleFarmersAddDialogClose,
    handleBulkFarmerDialogOpen,
    farmerFirstNameFilterValue,
    farmerLastNameFilterValue,
    handleFarmerFirstNameFilterChange,
    handleFarmerLastNameFilterChange,
    handleFarmerPhoneFilterValueAdd,
    handleFarmerPhoneFilterValueRemove,
    handleCropsFilterValueAdd,
    handleCropsFilterValueRemove,
    cropsFilterValue,
    farmCountFilterValue,
    handlefarmCountFilterChange,
    isFiltersEmpty,
    handleLocationFilterChange,

    handleFilterCancelClick,
    handleApplyFilterButtonClick,
    isDataLoading,
    columnsState,
    handleColumnsStateChange,
    handlePhoneInputChange,
    handleCropFilterValueChange,
    handleOrderChange,
    PhonesFilterRightButtonProps,
    onlySelectedFarmers,
    handleShowOnlySelectedFarmersButtonClick,
    setLabelsFilterValue,
    labelsFilterValue,
    isOpenAddFarmerLabels,
    handleBulkLabelsAdded,
    handleCancelBulkLabelsAdd,
    handleOpenBulkLabelsUpload,
    user,
  } = useLogic({
    selectedFarmersIds: SelectedFarmersIds,
    selectedFarmersPhones: SelectedFarmersPhones,
    Proiders_Ids,
  });
  return (
    <>
      <AddFarmers
        open={isOpenAddBulkFarmers}
        onCancel={handleFarmersAddDialogClose}
        onAdded={handleBulkFarmersAdded}
      />
      <CSVReader {...CsvReaderProps} />
      <AddUpdateFarmer
        variant="add"
        open={openAddFarmer}
        OnCanceleClicked={handleCloseAddFarmerModal}
        OnFarmerAdded={handleFarmerAdded}
      />
      <AddBulkFarmerLabels
        open={isOpenAddFarmerLabels}
        onAdded={handleBulkLabelsAdded}
        onCancel={handleCancelBulkLabelsAdd}
      />
      <CustomFarmersTable
        onOrderChange={handleOrderChange}
        customSelection={customSelection}
        isLoading={isDataLoading}
        filterContainerProps={{
          CancelSuccessButtonGroupProps: {
            SuccessButtonProps: {
              disabled: isFiltersEmpty,
              onClick: handleApplyFilterButtonClick,
            },
            CancelButtonProps: {
              onClick: handleFilterCancelClick,
            },
          },
        }}
        showFilterArea={isOpenFilterArea}
        filterFormProps={{
          labelsFilterProps: {
            onSubmit: setLabelsFilterValue,
            value: labelsFilterValue,
            "data-cy": "farmers-filter-labels-button",
          },
          onLocationFilterChange: handleLocationFilterChange,
          farmerFirstNameFilterProps: {
            onChange: handleFarmerFirstNameFilterChange,
            value: farmerFirstNameFilterValue,
            "data-cy": "farmers-filter-first-name-input",
          },
          farmerLastNameFilterProps: {
            onChange: handleFarmerLastNameFilterChange,
            value: farmerLastNameFilterValue,
            "data-cy": "farmers-filter-last-name-input",
          },
          cropFilterProps: {
            onAdd: handleCropsFilterValueAdd,
            autoCompleteProps: {
              value: cropsFilterValue,
              onChange: handleCropFilterValueChange,
            },
            chipInputProps: {
              onDelete: handleCropsFilterValueRemove,
            },
            textFieldProps: {
              "data-cy": "farmers-filter-crops-input",
            },
          },
          farmsCountFilterProps: {
            onChange: handlefarmCountFilterChange,
            value: farmCountFilterValue,
            cyTestId: "farmers-filter-farms-count-input",
          },

          farmerPhoneFilterProps: {
            RightButtonProps: PhonesFilterRightButtonProps,
            autoCompleteProps: {
              onChange: handlePhoneInputChange,
              value: phoneNumbersFilterValue,
            },
            onAdd: handleFarmerPhoneFilterValueAdd,
            chipInputProps: {
              onDelete: handleFarmerPhoneFilterValueRemove,
            },
            textFieldProps: {
              "data-cy": "farmers-filter-phone-number-input",
            },
          },
        }}
        data={data}
        actionBarProps={{
          importLabelsButtonProps: {
            onClick: handleOpenBulkLabelsUpload,
            "data-cy": "import-labels-button",
          },
          isShowOnlySelectedFarmers: isShowOnlySelectedFarmersFilter,
          showOnlySelectedFarmersButtonProps: {
            isShiny: onlySelectedFarmers,
            onClick: handleShowOnlySelectedFarmersButtonClick,
          },
          isShowColumnButton,
          isShowCreateButton,
          isShowFilterButton,
          isShowImportButton: isShowImportButton && user?.menuroles !== "user",
          isShowImportLabelsButton:
            isShowImportLabelsButton && user?.menuroles !== "user",
          onColumnsStateChange: handleColumnsStateChange,
          columnsState: columnsState,
          filterButtonProps: {
            disabled: isOpenFilterArea,
            onClick: handleFilterButtonClick,
            "data-cy": "filter-farmers-button",
          },
          importButtonProps: {
            onClick: handleBulkFarmerDialogOpen,
            "data-cy": "import-contacts-button",
          },
          addButtonProps: {
            onClick: handleAddFarmerClicked,
            disabled: openAddFarmer,
            "data-cy": "create-farmer-button",
          },
          columnButtonProps: {
            "data-cy": "farmers-table-show-hide-columns-button",
          },
        }}
        pagnationProps={{
          total,
          from: (currentPage - 1) * pageSize,
          onNextPageClick: handleNextPageClick,
          onPreviousPageClick: handlePreviousPageClick,
          rowsPerPage: pageSize,
          isNextPageButtonDisabled: isLastPage,
          isPreviousPageButtonDisabled: isFirstPage,
          onRowPerPageChange: handlePerSizeChange,
          rowsPerPageOptions: [10, 50, 100, 500],
        }}
        countLabelProps={{
          count: total,
        }}
      />
    </>
  );
};

export default FarmersTable;
