import { Alert, Box, Typography } from "@mui/material";
import EwalletTransactionTable from "components/data-tables/ewallet-transactions-table/ewallet-transactions-table";
import { FunctionComponent } from "react";
import { IParams, useLogic } from "./transactions-tab-box.logic";

interface TransactionsTabBoxProps extends IParams {}

const TransactionsTabBox: FunctionComponent<TransactionsTabBoxProps> = (props) => {
  const { farmer_id } = props;

  const { userHasWallet } = useLogic({ farmer_id });

  return <Box>{typeof userHasWallet === "boolean" && userHasWallet ? <EwalletTransactionTable from_user={farmer_id} to_user={farmer_id} /> : typeof userHasWallet === "boolean" && !userHasWallet ? <Alert severity="warning">No agriWALLET set up</Alert> : <Typography>Loading ...</Typography>}</Box>;
};

export default TransactionsTabBox;
