import React from "react";
import { Grid, Button, Alert, TextField, Divider, Menu, Box } from "@mui/material";
import FarmersTable from "components/data-tables/farmers-table/farmers-table";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";
import CustomInputWithCount from "components/custom-compnents/custom-input-with-count-and-signture/custom-input-with-count-and-signeture";
import CustomMenuButton from "components/custom-compnents/custom-menu-button/custom-menu-button";
import DateMenuButton from "components/custom-compnents/date-menu-button/date-menu-button";
import CustomToggleButton from "components/custom-compnents/custom-toggle-buttons/custom-toggle-buttons";
import { MessageType, useLogic } from "./send-message-screen.logic";
import CustomMenu from "components/custom-compnents/custom-menu-component/custom-menu-component";
import { StaticDateTimePicker } from "@mui/lab";
import MessageTemplateInput from "components/input-components/message-template-input/message-template-input";

export interface DirectMessagingScreenProps { }

const DirectMessagingScreen: React.FC<DirectMessagingScreenProps> = () => {
  const {
    handleFarmersTabChange,
    isShowTable,
    formikForm,
    handleSendAsChange,
    // sendAsOptions,
    DateMenuAnchorEl,
    handleDateButtonclick,
    handleMenuBlur,
    MessageLengthWithSignture,
    maxMessageLength,
    SigntureSentence,
    FarmersTableProps,
    showSuccessMessage,
    handleSendMessageClick,
    isSending,
    messageType,
    handleMessageTypeChange,
    settemplateId,
    whatsappSenders,
    // smsSenders,
    // loadingsmsSenders,
    loadingwhatsappSenders,
    combinedSenders,
  } = useLogic();

  return (
    <Grid container spacing={2}>
      <Menu open={Boolean(DateMenuAnchorEl)} anchorEl={DateMenuAnchorEl} onClose={handleMenuBlur}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDateTimePicker
            disabled={messageType == MessageType.Whatsapp}
            disablePast
            displayStaticWrapperAs="desktop"
            openTo="month"
            value={formikForm.values["datePickerData"]}
            onChange={(value) => formikForm.setFieldValue("datePickerData", value)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Menu>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ alignItems: "center", display: "flex" }}>
            <CustomTitle label="YOUR MESSAGE" />
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <CustomToggleButton
              value={messageType}
              onChange={(value) => handleMessageTypeChange(value)}
              items={[
                { label: "SMS Message", value: MessageType.SMS },
                { label: "WhatsApp Message", value: MessageType.Whatsapp, disabled: whatsappSenders.length === 0, title: whatsappSenders.length === 0 ? "This functionality is not enabled for your organization. Contact us to purchase a WhatsApp Sender ID!" : undefined },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* {((whatsappSenders.length === 0 && messageType === MessageType.Whatsapp && !loadingwhatsappSenders) || (smsSenders.length === 0 && messageType === MessageType.SMS && !loadingsmsSenders)) && (
        <Grid item xs={12}>
          <Alert severity="warning">This functionality is not enabled for your organization. Contact us to purchase a WhatsApp Sender ID!</Alert>
        </Grid>
      )} */}

      <Grid item xs={12}>
        <CustomInputWithCount
          disabled={whatsappSenders.length === 0 && messageType === MessageType.Whatsapp && !loadingwhatsappSenders}
          value={formikForm.values["message"]}
          name="message"
          withLimit={messageType === MessageType.SMS}
          onChange={formikForm.handleChange}
          multiline
          readOnly={messageType === MessageType.Whatsapp}
          rows={20}
          placeholder="Insert message content ..."
          currentLength={MessageLengthWithSignture}
          limit={maxMessageLength}
          signture={SigntureSentence}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <CustomMenu
              menuData={combinedSenders.map((item) => ({
                label: item.title,
                onClick: () => handleSendAsChange(item.value),
              }))}
            >
              <CustomMenuButton fullWidth>Send message as {combinedSenders.find((item) => item.value === formikForm.values["sender_id"])?.title ?? "..."}</CustomMenuButton>
            </CustomMenu>
          </Grid>
          {messageType !== MessageType.SMS &&
            <Grid item xs={12} md={2}>
              <Box bgcolor="white">
                <MessageTemplateInput
                  fullwidth
                  variant={messageType}
                  onChange={(value) => {
                    if (!Array.isArray(value)) {
                      formikForm.setFieldValue("message", messageType === MessageType.Whatsapp ? value?.whatsapp_body ?? "" : value?.sms_body ?? "");
                      settemplateId(value?.id ?? "");
                    }
                  }}
                />

              </Box>
            </Grid>
          }

          {
            messageType === MessageType.SMS &&
            <Grid item xs={12} md={3}>
              <DateMenuButton fullWidth onClick={handleDateButtonclick}>
                {formikForm.values["datePickerData"] ? moment(formikForm.values["datePickerData"]).format("YYYY/MM/DD hh:mm a").toString() : "Schedule message (optional)"}
              </DateMenuButton>
            </Grid>
          }
          <Grid item xs={12} md={2}>
            <CustomToggleButton items={[{ label: "Select Recipients" }]} onChange={handleFarmersTabChange} value={isShowTable ? 0 : undefined} isFullWidth />
          </Grid>

          <Grid item xs={12} md={2}>
            <Button color="secondary" onClick={handleSendMessageClick} fullWidth disabled={isSending || (whatsappSenders.length === 0 && messageType === MessageType.Whatsapp)}>
              {formikForm.values["datePickerData"] ? "Schedule message" : "Send Now"}
            </Button>
          </Grid>


        </Grid>
      </Grid>
      {Object.values(formikForm.errors).length > 0 && (
        <Grid item xs={12}>
          {Object.values(formikForm.errors).map((item, index) => (
            <Alert severity="warning" key={index} style={{ marginBottom: 8 }}>
              {item}
            </Alert>
          ))}
        </Grid>
      )}
      <Grid item xs={12}>
        {showSuccessMessage && (
          <Grid>
            <Alert severity="success">{formikForm.values["datePickerData"] ? "Message has been scheduled successfully." : "Message has been queued successfully."}</Alert>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} hidden={!isShowTable}>
        <Divider />
      </Grid>
      <Grid item xs={12} hidden={!isShowTable}>
        <FarmersTable isShowColumnButton isShowFilterButton {...FarmersTableProps} />
      </Grid>
    </Grid>
  );
};

export default DirectMessagingScreen;
