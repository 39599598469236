import MaterialTable from "@material-table/core";
import CustomPagnation from "components/custom-compnents/custom-pagnation/custom-pagnation";
import { Fields, Params, schema } from "./custom-sender-ids-table.schema";
import { Box, Grid } from "@mui/material";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import { CustomPagnationProps } from "components/custom-compnents/custom-pagnation/custom-pagnation";
import CustomFiltersContainer, { CustomFiltersContainerProps } from "../custom-filters-container/custom-filters-container";
import CustomSenderIdsTableActionBar, { CustomSenderIdsTableActionBarProps } from "../custom-sender-ids-table-action-bar/custom-sender-ids-table-action-bar";
import FarmsActivitesTableFilterForm, { FarmsActivitesTableFilterFormProps } from "components/forms/farms-activites-table-filter-form/farms-activites-table-filter-form";

export interface CustomSenderIdsTableProps {
  countLabelProps?: CountLabelProps;
  showFilterArea?: boolean;
  filterContainerProps?: CustomFiltersContainerProps;
  filterFormProps?: FarmsActivitesTableFilterFormProps;
  pagnationProps?: CustomPagnationProps;
  actionBarProps?: CustomSenderIdsTableActionBarProps;
  data: Fields[];
  isLoading?: boolean;
  customSelection?: Params["customSelection"];
  onOrderChange?: (orderBy: number, orderDirection: "desc" | "asc") => void | undefined;
  onEditButtonClick?: Params["onEditButtonClick"];
  onDeleteButtonClick?: Params["onDeleteButtonClick"];
  onViewButtonClick?: Params["onViewButtonClick"];
  isShowRemoveAction?: boolean;
  isShowEditAction?: boolean;
  isShowViewAction?: boolean;
}

const CustomSenderIdsTable: React.FunctionComponent<CustomSenderIdsTableProps> = (props) => {
  const { countLabelProps, showFilterArea, pagnationProps, filterContainerProps, filterFormProps, actionBarProps, data, isLoading, customSelection, onOrderChange, onEditButtonClick, onDeleteButtonClick, isShowEditAction, isShowRemoveAction, isShowViewAction, onViewButtonClick } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ paddingLeft: 0 }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ paddingLeft: 0 }}>
          <Grid item md={3} xs={12}>
            <CountLabel {...countLabelProps} label="All Sender IDs" />
          </Grid>
          <Grid item md={9} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Box width={840} maxWidth="100%">
                <CustomSenderIdsTableActionBar {...actionBarProps} />
              </Box>
            </Box>
          </Grid>
          {showFilterArea && (
            <Grid item xs={12}>
              <CustomFiltersContainer {...filterContainerProps}>
                <FarmsActivitesTableFilterForm {...filterFormProps} />
              </CustomFiltersContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          {...schema({
            customSelection,
            data,
            columnState: actionBarProps?.columnsState,
            onEditButtonClick,
            onDeleteButtonClick,
            isShowEditAction,
            isShowRemoveAction,
            isShowViewAction,
            onViewButtonClick,
          })}
          isLoading={isLoading}
          onOrderChange={onOrderChange}
        />
      </Grid>
      <Grid item xs={12}>
        {pagnationProps && <CustomPagnation {...pagnationProps} />}
      </Grid>
    </Grid>
  );
};

export default CustomSenderIdsTable;
