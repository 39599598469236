import { DeleteFarmerLabels } from "api/farmer-labels/delete";

import React from "react";

export interface IParams {
  onDeleted?: () => any;
  id_list: string[];
}
export const useLogic = (params: IParams) => {
  const [isSubmittingDelete, setiIsSubmittingDelete] = React.useState(false);
  const { onDeleted, id_list } = params;

  const handleDeleteConfirm = () => {
    setiIsSubmittingDelete(true);

    DeleteFarmerLabels({ key_id: id_list[0] }, (error, resp) => {
      if (error) {
        console.log(error);
      } else {
        setiIsSubmittingDelete(false);
        onDeleted?.();
      }
    });
  };

  return { isSubmittingDelete, handleDeleteConfirm };
};
