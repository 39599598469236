import { GetSenderIds } from "api/senders/get-senders-v2";
import { CustomSenderIdsTableProps } from "components/custom-compnents/custom-sender-ids-table/custom-sender-ids-table";
import { compinedProps } from "components/standalone-components/add-update-sender-id/add-update-sender-id";
import { RemoveSenderIdComponentProps } from "components/standalone-components/remove-sender-id-component/remove-sender-id-component";
import { ISenderId } from "models/sender-id";
import React from "react";

export const useLogic = () => {
  const [isShowAddDialog, setIsShowAddDialog] = React.useState(false);
  const [senderDataToBeUpdated, setSenderDataToBeUpdated] = React.useState<undefined | ISenderId>(undefined);
  const [senderDataToBeDeleted, setSenderDataToBeDeleted] = React.useState<undefined | ISenderId>(undefined);
  const [data, setData] = React.useState<ISenderId[]>([]);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [idToShowDetails, setidToShowDetails] = React.useState("");
  const [columnsState, setColumnsState] = React.useState([
    { checked: true, field: "provider_id" },
    { checked: true, field: "sender_type" },
    { checked: true, field: "status" },
  ]);

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const handleAddedSender = () => {
    setIsShowAddDialog(false);
    refreshData();
  };
  const handleCancelAdd = () => {
    setIsShowAddDialog(false);
  };

  const handleAddButtonClick = () => setIsShowAddDialog(true);

  const handleUpdateCancel = () => setSenderDataToBeUpdated(undefined);
  const handleUpdated = () => {
    setSenderDataToBeUpdated(undefined);
    refreshData();
  };

  const handleUpdateButtonClick = (data: ISenderId) => setSenderDataToBeUpdated(data);

  const handleCancelDelete = () => setSenderDataToBeDeleted(undefined);

  const handleDeleteButtonClick = (data: ISenderId) => setSenderDataToBeDeleted(data);

  const handleDeleted = () => {
    setSenderDataToBeDeleted(undefined);
    refreshData();
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetSenderIds({ page: currentPage, pageSize: perPage }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        setData(resp.allSenders.nodes);
        setTotalCount(resp.allSenders.totalCount);
        setIsLoading(false);
      }
    });
  }, [currentPage, dataUpdateIndex, perPage]);

  const preparedData = data.map((item) => ({ selected: true, ...item }));

  const tableProps: CustomSenderIdsTableProps = {
    onEditButtonClick: handleUpdateButtonClick,
    onDeleteButtonClick: handleDeleteButtonClick,
    onViewButtonClick: (data) => {
      setidToShowDetails(data.id);
    },
    countLabelProps: {
      count: totalCount,
    },
    isShowEditAction: false,
    isShowRemoveAction: true,
    isShowViewAction: true,
    actionBarProps: {
      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
      isShowColumnButton: true,
      isShowCreateButton: true,
      addButtonProps: {
        onClick: handleAddButtonClick,
      },
    },
    isLoading: isLoading,
    data: preparedData,
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };

  const addDialogProps: compinedProps = {
    Variant: "Add",
    onCancel: handleCancelAdd,
    onAdded: handleAddedSender,
    open: isShowAddDialog,
  };

  const updateDialogProps: compinedProps | undefined = senderDataToBeUpdated?.id
    ? {
        Variant: "update",
        onCancel: handleUpdateCancel,
        onUpdated: handleUpdated,
        SenderId: senderDataToBeUpdated?.id,
        open: Boolean(senderDataToBeUpdated),
      }
    : undefined;

  const deleteDialogProps: RemoveSenderIdComponentProps | undefined = senderDataToBeDeleted?.id
    ? {
        id: senderDataToBeDeleted.id,
        onCancel: handleCancelDelete,
        open: !!senderDataToBeDeleted.id,
        onDeleted: handleDeleted,
      }
    : undefined;

  return { tableProps, addDialogProps, updateDialogProps, deleteDialogProps, idToShowDetails, setidToShowDetails };
};
