import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  textRoot: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.57",
    letterSpacing: "normal",
  },
  textNormal: { color: "#5d6d6e" },
  textHighlighted: { color: "#000" },
});

export default useStyles;
