import { Drawer, DrawerProps, Paper } from "@mui/material";
import { Box } from "@mui/system";
import CustomNavigationHeader, {
  CustomNavigationHeaderProps,
} from "../custom-navigation-header/custom-navigation-header";

export interface CustomDrawerProps extends DrawerProps {
  NavigationHeaderProps: CustomNavigationHeaderProps;
  ActionArea?: any;
}

const CustomDrawer: React.FunctionComponent<CustomDrawerProps> = (props) => {
  const { NavigationHeaderProps, ActionArea } = props;
  return (
    <Drawer {...props} PaperProps={{ sx: { width: 420 } }}>
      <Box mx={3} mt={3} boxSizing="border-box">
        <CustomNavigationHeader {...NavigationHeaderProps} />
      </Box>
      <Box mx={3} mb={3}>
        {props.children}
      </Box>
      <Box
        component={Paper}
        square={true}
        borderBottom="unset"
        borderRight="unset"
        borderLeft="unset"
        px={3}
        py={3}
        justifyContent="flex-end"
        display="flex"
        zIndex={1}
      >
        {ActionArea}
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;

CustomDrawer.defaultProps = {
  anchor: "right",
};
