import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app/app-slice";
import DirectMessageLocationFilterState from "./reducers/location-filter-input-slice/direct-message-location-filter-reducer";
import FarmerScreenState from "./reducers/farmer-screen/FarmerScreenReducer";
import FarmPageState from "./reducers/farm-page/farm-page-reducer";
import farmersAnalyticsMapState from "./reducers/farmers-analytics-page/map/farmers-analytics-map-reducer";
import FarmersAnalyticsCropsChartsState from "./reducers/farmers-analytics-page/crops-chart/farmers-analytics-crops-chart-reducer";
import breadcrumbState from "./reducers/breadcrumb/breadcrumb";
import FarmerAnalyticsPageState from "./reducers/farmers-analytics-page/farmer-analytics-page/farmer-analytics-page-slice"
import { enhancer } from "addon-redux";

export const store = configureStore({
  reducer: {
    appReducer,
    DirectMessageLocationFilterState,
    FarmerScreenState,
    FarmPageState,
    farmersAnalyticsMapState,
    FarmersAnalyticsCropsChartsState,
    breadcrumbState,
    FarmerAnalyticsPageState
  },
  enhancers: [enhancer],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
