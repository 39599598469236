import { GetCountyLocation } from "api/county-location/get-county-location";
import { useDebounce } from "hooks/debounce-hook";
import { ICountyLocation } from "models/county-location";
import React, { useState } from "react";

export const useLogic = () => {
  const [counties, setcounties] = useState<ICountyLocation[]>([]);
  const [search_term, setsearch_term] = useState("");
  const debouncedSearchTerm = useDebounce(search_term, 800);

  const handleInputChange = (value: string) => setsearch_term(value);

  React.useEffect(() => {
    GetCountyLocation({ search: debouncedSearchTerm }).then((resp) => {
      setcounties(resp.data);
    });
  }, [debouncedSearchTerm]);

  return { counties, handleInputChange };
};
