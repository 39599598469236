import { GetCertification } from "api/certification/get-certificaion";
import { CustomCertificationTableProps } from "components/custom-compnents/custom-certification-table/custom-certificattion-table";
import { AddCertificationComponentProps } from "components/standalone-components/add-certification-component/add-certification-component";
import { RemoveCertificationComponentProps } from "components/standalone-components/remove-cetification-component/remove-certification-component";
import { Certification } from "models/certification";
import React from "react";
import { OrderByObject } from "./order-by-object";

export interface IParams {
  farmer_id?: string;
}

export const useLogic = (params: IParams) => {
  const { farmer_id } = params;

  const [isOpenAddCertificationDialog, setIsOpenAddCertificationDialog] = React.useState(false);

  const [orderByData, setOrderByData] = React.useState<{ index: number; orderDirection: "asc" | "desc" } | undefined>(undefined);
  const [certificationToBeDeleted, setcertificationToBeDeleted] = React.useState<undefined | Certification>(undefined);
  const [data, setData] = React.useState<Certification[]>([]);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [columnsState, setColumnsState] = React.useState([
    { field: "certificate_name", checked: true },
    { field: "certification_date", checked: true },
    { field: "confirmed_by", checked: true },
  ]);

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const handleAddButtonClick = () => setIsOpenAddCertificationDialog(true);

  const handleCancelAddCertification = () => {
    setIsOpenAddCertificationDialog(false);
  };

  const handleOrderChange: CustomCertificationTableProps["onOrderChange"] = (index, orderDirection) => {
    if (index > -1) setOrderByData({ index, orderDirection });
  };

  const handleAddedCertification = () => {
    setIsOpenAddCertificationDialog(false);
    refreshData();
  };

  const handleCancelDeleteCertificaton = () => setcertificationToBeDeleted(undefined);

  const handleDeletedButtonClick = (data: Certification) => {
    setcertificationToBeDeleted(data);
  };

  const handleDeletedCertification = () => {
    setcertificationToBeDeleted(undefined);
    refreshData();
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetCertification(
      {
        page: currentPage,
        pageSize: perPage,
        // farmerName: FarmerFilterValue,
        farmer_id: farmer_id
      },
      (error, resp) => {
        setIsLoading(false);
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setData(resp.allCertifications.items);
          setTotalCount(resp.allCertifications.totalCount);
        }
      }
    );
  }, [currentPage, perPage, dataUpdateIndex, orderByData?.index, orderByData?.orderDirection]);

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  const tableProps: CustomCertificationTableProps = {
    countLabelProps: {
      count: totalCount,
    },
    onDeleteButtonClick: handleDeletedButtonClick,
    isShowRemoveAction: true,
    actionBarProps: {
      addButtonProps: {
        onClick: handleAddButtonClick,
      },
      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
      isShowColumnButton: true,
      isShowCreateButton: true,
      //   addButtonProps: {
      //     onClick: handleAddCertificationButtonClick,
      //   },
    },
    isLoading: isLoading,
    onOrderChange: handleOrderChange,
    data: preparedData,
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };

  const addDialogProps: AddCertificationComponentProps = {
    farmer_id,
    onAdded: handleAddedCertification,
    onCancel: handleCancelAddCertification,
    open: isOpenAddCertificationDialog,
  };

  const removeDilaogProps: RemoveCertificationComponentProps | undefined =
    certificationToBeDeleted?.id
      ? {
        onCancel: handleCancelDeleteCertificaton,
        open: !!certificationToBeDeleted.id,
        id: certificationToBeDeleted.id,
        onDeleted: handleDeletedCertification,
      }
      : undefined;

  return { tableProps, addDialogProps, removeDilaogProps };
};
