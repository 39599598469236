import { Grid } from "@mui/material";
import React from "react";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import { IParams, useLogic } from "./add-update-farmers.logic";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import SelectButtons from "components/input-components/select-buttons/Select-Buttons";
import { Box } from "@mui/system";

export interface AddUpdateFarmerProps {
  open: boolean;
  OnCanceleClicked?: () => any;
}
export type Props = AddUpdateFarmerProps & IParams;
const AddUpdateFarmer: React.FC<Props> = ({
  open,
  OnCanceleClicked,
  ...props
}) => {
  const { IsSubmitting, FormikData, FormData } = useLogic({
    ...props,
  });

  return (
    <CustomDialogV2
      data-cy="add-edit-farmer-dialog"
      open={open}
      header={props.variant === "add" ? "Add Farmer" : "Edit Farmer"}
      footer={
        <CancelSuccessButtonsGroup
          CancelButtonProps={{ label: "Cancel", onClick: OnCanceleClicked }}
          SuccessButtonProps={{
            label: props.variant === "add" ? "Add Farmer" : "Save",
            disabled: IsSubmitting,
            onClick: FormikData.submitForm,
            "data-cy": "create-update-farmer-submit-button",
          }}
        />
      }
    >
      <Box width={350}>
        <Grid container spacing={2}>
          {FormData.map((item, index) => (
            <Grid key={index} item xs={12}>
              {item.type === "select-buttons" && item.options ? (
                <SelectButtons
                  Options={item.options}
                  onSelect={(value) =>
                    FormikData.setFieldValue(item.name, value.value)
                  }
                  SelectedOption={{
                    title: (FormikData as any).values[item.name],
                    value: (FormikData as any).values[item.name],
                  }}
                />
              ) : (
                <CustomTextField
                  value={(FormikData as any).values[item.name]}
                  fullWidth
                  placeholder={item.placeholder}
                  onChange={FormikData.handleChange}
                  autoFocus={index === 0}
                  name={item.name}
                  error={Boolean((FormikData as any).errors[item.name])}
                  helperText={(FormikData as any).errors[item.name]}
                  data-cy={item?.["data-cy"]}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </CustomDialogV2>
  );
};

export default AddUpdateFarmer;
