export const OrderByObject = {
  0: { asc: "FIRST_NAME_ASC", desc: "FIRST_NAME_DESC" },
  1: { asc: "LAST_NAME_ASC", desc: "LAST_NAME_DESC" },
  2: { asc: "PHONE_ASC", desc: "PHONE_DESC" },
  4: {
    asc: "FARMS_BY_FARMER_ID_COUNT_ASC",
    desc: "FARMS_BY_FARMER_ID_COUNT_DESC",
  },
  6: { asc: "SOURCE_ASC", desc: "SOURCE_DESC" },
  7: {
    asc: "ACCEPTED_TERMS_AT_ASC",
    desc: "ACCEPTED_TERMS_AT_DESC",
  },
  8: { asc: "GENDER_ASC", desc: "GENDER_DESC" },
  9: { asc: "AGE_GROUP_ASC", desc: "AGE_GROUP_DESC" },
};
