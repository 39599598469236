import { Checkbox, CheckboxProps } from "@mui/material";
import CheckBoxIcon from "assets/images/svg/unchecked.svg";
import CheckBoxCheckedIcon from "assets/images/svg/checked.svg";
import CheckBoxDisabledIcon from "assets/images/svg/disabled.svg";
import CheckedBoxDisabledIcon from "assets/images/svg/checked-disabled.svg";

interface CustomCheckBoxProps extends CheckboxProps {}

const CustomCheckBox: React.FunctionComponent<CustomCheckBoxProps> = (
  props
) => {
  const { disabled } = props;

  const CheckedComponent = <img src={CheckBoxCheckedIcon} />;
  const UncheckedComponent = <img src={CheckBoxIcon} />;
  const UnCheckedDisabledComponent = <img src={CheckBoxDisabledIcon} />;
  const CheckedDisabledComponent = <img src={CheckedBoxDisabledIcon} />;

  return (
    <Checkbox
      icon={disabled ? UnCheckedDisabledComponent : UncheckedComponent}
      checkedIcon={disabled ? CheckedDisabledComponent : CheckedComponent}
      {...props}
    />
  );
};

export default CustomCheckBox;
