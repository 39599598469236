import { Chip, Switch, TextField, Typography } from "@mui/material";
import { GetBroadcasts } from "api/broadcasts/get-broadcasts";
import { UpdateBroadcasts } from "api/broadcasts/update-broadcasts";
import { GetProvider } from "api/provider/get-provider";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import SelectMenu, {
  option,
} from "components/input-components/select-menu/SelectMenu";
import SenderInput from "components/input-components/sender-input/sender-input";
import { useFormik } from "formik";
import { broadcast } from "models/broadcast";
import React from "react";
import { useAppSelector } from "store/hooks";
import * as yup from "yup";

export interface IParams {
  broadcast_id: string;
  onUpdated?: () => any;
}

export const useLogic = (params: IParams) => {
  const { broadcast_id, onUpdated } = params;

  const [isInEditMode, setIsInEditMode] = React.useState(false);

  const [isShowDeleteComponent, setIsShowDeleteComponent] =
    React.useState(false);

  const [providerTerm, setProviderTerm] = React.useState("");

  const [providersTotalCount, setProvidersTotalCount] = React.useState(0);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    GetBroadcasts(
      { page: 0, pageSize: 10, id: broadcast_id },
      (error, resp) => {
        if (error) {
          console.log(error);
        } else if (resp) {
          formikForm.setValues(resp.items[0]);
        }
      }
    );
  }, [broadcast_id]);

  const user = useAppSelector((root) => root.appReducer.user);

  const ValidationSchema = yup.object().shape({
    ...(user?.menuroles === "admin"
      ? { provider_id: yup.string().required().not(["none"]) }
      : {}),
    name: yup.string().required(),
    description: yup.string().required(),
  });

  const formikForm = useFormik({
    validationSchema: ValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      provider_id: "",
      name: "",
      description: "",
      is_published: false,
      relative_send_dates: false,
      sender_by_sender_id: {
        id: null,
        at_sender_id: null,
      },
    } as unknown as Partial<broadcast>,
    onSubmit: (values) => {
      setIsSubmitting(true);
      UpdateBroadcasts({ ...values, id: broadcast_id }, (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setIsInEditMode(false);
          setIsSubmitting(false);
          onUpdated?.();
        }
      });
    },
  });

  const handleEditButtonClick = () => setIsInEditMode(true);
  const handleCancelEditButtonClick = () => setIsInEditMode(false);

  const handleDeleteButtonClick = () => setIsShowDeleteComponent(true);

  const handleCancelDeleteButtonClick = () => setIsShowDeleteComponent(false);

  const handleDeleted = () => {
    setIsShowDeleteComponent(false);
  };

  const [providerListOptions, setProviderListOptions] = React.useState<
    option[]
  >([]);

  React.useEffect(() => {
    GetProvider(
      { page: 0, pageSize: 10, search: providerTerm, is_active: true },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setProvidersTotalCount(resp.totalCount);
          setProviderListOptions(
            resp.providers.map((item, index) => ({
              title: item.name,
              value: item.id,
            }))
          );
        }
      }
    );
  }, [providerTerm]);

  const handleSubmit = () => formikForm.submitForm();

  const fieldsData = [
    {
      placeholder: "Broadcast Title",
      inputProps: {},
      isInEditMode: isInEditMode,
      showComponent: (
        <Typography variant="h6">{formikForm.values.name}</Typography>
      ),
      editable: true,
      editComponent: (
        <CustomTextField
          onChange={(e) =>
            formikForm.setFieldValue("name", e.currentTarget.value)
          }
          fullWidth
          value={formikForm.values.name}
          error={!!formikForm.errors.name}
          helperText={formikForm.errors.name}
        />
      ),
    },
    {
      placeholder: "Description",
      textfieldinputPropsProps: {},
      isInEditMode: isInEditMode,
      showComponent: (
        <Typography variant="h6">{formikForm.values.description}</Typography>
      ),
      editable: true,
      editComponent: (
        <TextField
          error={!!formikForm.errors.description}
          helperText={formikForm.errors.description}
          fullWidth
          multiline
          rows={5}
          value={formikForm.values.description}
          onChange={(e) =>
            formikForm.setFieldValue("description", e.currentTarget.value)
          }
        />
      ),
    },
    {
      placeholder: "Organization",
      inputProps: {},
      isInEditMode: isInEditMode,
      showComponent: (
        <Typography variant="h6">
          {
            providerListOptions.find(
              (item) => item.value === formikForm.values.provider_id
            )?.title
          }
        </Typography>
      ),
      editable: true,
      editComponent: (
        <SelectMenu
          error={!!formikForm.errors.provider_id}
          helperText={formikForm.errors.provider_id}
          disabled={user?.menuroles !== "admin"}
          OnChange={(e) =>
            formikForm.setFieldValue("provider_id", e.target.value)
          }
          value={formikForm.values.provider_id}
          options={providerListOptions}
          SearchLabel=""
          onSearchInputChange={(value) => setProviderTerm(value)}
          SearchPlaceholder="Insert Organization Name"
          Searchable={true}
          Placeholder={""}
        />
      ),
    },
    {
      placeholder: "Is Published",

      inputProps: {},
      isInEditMode: isInEditMode,
      showComponent: formikForm.values.is_published ? (
        <Chip variant="outlined" label="Published" color="success" />
      ) : (
        <Chip variant="outlined" label="Not Published" color="error" />
      ),
      editable: true,
      editComponent: (
        <Switch
          color="success"
          checked={formikForm.values.is_published}
          onChange={(e, checked) =>
            formikForm.setFieldValue("is_published", checked)
          }
        />
      ),
    },
    {
      placeholder: "Realtive Send Dates",
      inputProps: {},

      isInEditMode: isInEditMode,
      showComponent: formikForm.values.relative_send_dates ? (
        <Chip variant="outlined" label="Yes" color="default" />
      ) : (
        <Chip variant="outlined" label="No" color="default" />
      ),
      editable: true,
      editComponent: (
        <Switch
          color="success"
          checked={formikForm.values.relative_send_dates}
          onChange={(e, checked) =>
            formikForm.setFieldValue("relative_send_dates", checked)
          }
        />
      ),
    },
    {
      placeholder: "Organization ID",

      inputProps: {},
      isInEditMode: isInEditMode,
      showComponent: (
        <Typography variant="h6">{formikForm.values["provider_id"]}</Typography>
      ),
      editable: false,
    },
    {
      placeholder: "Sender",
      inputProps: {},
      isInEditMode: isInEditMode,
      showComponent: (
        <Typography variant="h6">
          {formikForm.values["sender_by_sender_id"]?.at_sender_id}
        </Typography>
      ),
      editable: true,
      editComponent: (
        <SenderInput
          isUpdate={true}
          senderType="Africastalking_sender"
          providerId={
            user?.menuroles !== "admin"
              ? formikForm.values["provider_id"]
              : undefined
          }
          value={formikForm.values["sender_by_sender_id"]?.id!}
          onChange={(value) => {
            formikForm.setFieldValue("sender_id", value?.id);
            formikForm.setFieldValue("sender_by_sender_id", {
              at_sender_id: value?.atSenderId,
              id: value?.id,
            });
          }}
        />
      ),
    },
  ];
  return {
    fieldsData,
    isInEditMode,
    handleEditButtonClick,
    handleCancelEditButtonClick,
    isShowDeleteComponent,
    handleDeleteButtonClick,
    handleCancelDeleteButtonClick,
    handleDeleted,
    isSubmitting,
    handleSubmit,
  };
};
