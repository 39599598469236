import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Certification } from "models/certification";

interface Resp {
  allCertifications: {
    items: Certification[];
    totalCount: number;
  };
}
interface Params {
  page?: number;
  pageSize?: number;
  farmerName?: string;
  farmer_id?: string;
  confirmed_by?: string;
  certificate_id?: string;
  certification_date?: string;
}

interface CertificationItemDto {
  id: string;
  confirmed_by: string;
  farmer_id: string;
  farmerByFarmerId?: {
    id: string;
    first_name: string;
    last_name: string;
  }
  certification_date: string;
  certificate_id: string;
  certificate_by_certificate_id: {
    title: string;
  };
}

type GetCertificationType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const GetCertification: GetCertificationType = (Params, callback) => {
  AxiosInstance.get("/certifications", { params: Params })
    .then((resp) => {
      callback(null, {
        allCertifications: {
          totalCount: resp.data.data.all_certifications.total_count,
          items: resp.data.data.all_certifications.nodes.map((item: CertificationItemDto) => ({
            id: item.id,
            farmerId: item.farmer_id,
            confirmedBy: item.confirmed_by,
            certificateId: item.certificate_id,
            farmerByFarmerId: item.farmerByFarmerId,
            certificationDate: item.certification_date,
            certificateByCertificateId: item.certificate_by_certificate_id
          }))
        }
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { GetCertification };
