import { AxiosInstance } from "configs/axios";
import { IOrganizationRelation } from "models/organizations-relation";
interface Params {
  super_org: string;
  sub_org?: string;
  bill_to_super?: boolean;
}
type Resp = IOrganizationRelation;

const addSuperOrganizationRelation = (Params: Params) => {
  return AxiosInstance.post<Resp>("/organization-relations", { ...Params });
};

export { addSuperOrganizationRelation };
