import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { SurveyQuestion } from "../../models/survey-questions";

interface Resp {
  items: SurveyQuestion[];
}
interface Params {
  survey_id: string;
  is_active?: true | false;
  id_list?: string[];
}
type GetSurveyQuestionsType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: Resp | null) => any
) => any;
const GetSurveyQuestions: GetSurveyQuestionsType = (Params, callback) => {
  AxiosInstance.get(`/survey-questions/${Params.survey_id}`, {
    params: { ...Params },
  })
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { GetSurveyQuestions };
