import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Resp { }
interface Params {
  id: string;
}

type DeleteCertificationType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const DeleteCertification: DeleteCertificationType = (Params, callback) => {
  AxiosInstance.delete("/certifications/" + Params.id)
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { DeleteCertification };
