import { CircularProgress, Grid, Skeleton, Typography } from "@mui/material";
import CustomSurveyQuestion from "components/custom-compnents/custom-survey-question/custom-survey-question";
import RemoveSurveyQuestionComponent from "../remove-survey-question-component/remove-survey-question-component";
import { IParams, useLogic } from "./survey-questions-panel.logic";
import SetQuestionOrderComponent from "../set-question-order-component/set-question-order-component";
import _ from 'lodash';

interface SurveyQuestionPanelProps extends IParams { }

const SurveyQuestionPanel: React.FunctionComponent<SurveyQuestionPanelProps> = (
  props
) => {
  const {
    questionsData,
    setOpenQuestionId,
    openQuestionId,
    questionsAnswers,
    setQuestionToBeDeleted,
    removeQuestionDialogProps,
    questionOrderDialogProps,
    questionToBeUpdated,
    setQuestionToBeUpdated,
    handleQuestionTextChange,
    handleQuestionTypeChange,
    handleDefaultNextQuestionChange,
    questionAnswerListToBeUpdated,
    handleAddNewAnswer,
    isLoadingEditAnswers,
    isLoadingViewAnswers,
    isLoadingQuestions,
    handleSingleChoiceAnswerChange,
    isSubmittingUpdate,
    handleUpdateConfirmButtonClick,
    setQuestionOrder
  } = useLogic(props);

  return (
    <Grid container spacing={2}>
      {removeQuestionDialogProps && (
        <RemoveSurveyQuestionComponent {...removeQuestionDialogProps} />
      )}
      {questionOrderDialogProps && (
        <SetQuestionOrderComponent {...questionOrderDialogProps} />
      )}
      {isLoadingQuestions ? (
        <Grid item xs={12} textAlign="center">
          <CircularProgress />
          <Typography variant="h6">Loading ...</Typography>
        </Grid>
      ) : (
        _.orderBy(questionsData, 'sort_order', 'desc')
          .filter((item) => item.id !== "none")

          .map((item) => (
            <Grid item xs={12} key={item.id}>
              <CustomSurveyQuestion
                {...({
                  sort_order: item.sort_order,
                  type: questionToBeUpdated?.type ?? item.type,
                  answerScreenList: (questionAnswerListToBeUpdated.length > 0
                    ? questionAnswerListToBeUpdated
                    : questionsAnswers
                  )
                    .filter(
                      (questionAnswerItem) =>
                        item.id === questionAnswerItem.question_id
                    )
                    .map((item) => ({
                      ...item,
                      next_qid: item.next_qid ? item.next_qid : "none",
                    })),
                  default_next_question_id:
                    questionToBeUpdated?.default_next_qid
                      ? questionToBeUpdated?.default_next_qid
                      : item.default_next_qid
                        ? item.default_next_qid
                        : "none",
                } as any)}
                onDefaultNextQuestionChange={handleDefaultNextQuestionChange}
                isInEditMode={questionToBeUpdated?.id === item.id}
                questionList={questionsData}
                questionValue={questionToBeUpdated?.question ?? item.question}
                isLoadingNextScreenList={
                  isLoadingEditAnswers || isLoadingViewAnswers
                }
                onQuestionValueChange={handleQuestionTextChange}
                onAnswerChange={handleSingleChoiceAnswerChange}
                addAnswerButtonProps={{
                  onClick: handleAddNewAnswer,
                  disabled: isLoadingEditAnswers || isLoadingViewAnswers,
                }}
                onQuestionTypeChange={handleQuestionTypeChange}
                deleteButtonProps={{
                  onClick: () => setQuestionToBeDeleted(item),
                }}
                setOrderButtonProps={{
                  onClick: () => setQuestionOrder(item),
                }}
                updateButtonProps={{
                  onClick: handleUpdateConfirmButtonClick,
                  disabled:
                    isLoadingEditAnswers ||
                    isLoadingViewAnswers ||
                    isSubmittingUpdate,
                }}
                cancelButtonProps={{
                  onClick: () => setQuestionToBeUpdated(undefined),
                }}
                editButtonProps={{
                  onClick: () => {
                    if (!openQuestionId)
                      setQuestionToBeUpdated(
                        // item.id === questionToBeUpdated?.id ? undefined : item
                        item
                      );
                  },
                  isSelected: questionToBeUpdated?.id === item.id,
                }}
                openButtonProps={{
                  onClick: () => {
                    if (!questionToBeUpdated?.id)
                      setOpenQuestionId(
                        openQuestionId === item.id ? undefined : item.id
                      );
                  },
                  isSelected: openQuestionId === item.id,
                }}
                isShowDetailArea={
                  openQuestionId === item.id ||
                  questionToBeUpdated?.id === item.id
                }
                questionTypeValue={questionToBeUpdated?.type ?? item.type}
                questionTitle={item.question}
                answersNumber={
                  item.type === "free text"
                    ? 1
                    : questionsAnswers.filter(
                      (questionAnswerItem) =>
                        item.id === questionAnswerItem.question_id
                    ).length
                }
              />
            </Grid>
          ))
      )}
    </Grid>
  );
};

export default SurveyQuestionPanel;
