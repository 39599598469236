import { Box, Grid, TextField, Typography } from "@mui/material";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import SelectMenu from "components/input-components/select-menu/SelectMenu";
import { IParams, useLogic } from "./update-callback-component.logic";

export interface UpdateCallbackComponentProps extends IParams {
  open?: boolean;
  farmer_id?:string;
}

const UpdateCallbackComponent: React.FunctionComponent<UpdateCallbackComponentProps> =
  (props) => {
    const { open } = props;
    const { data, cancelSuccessButtonProps, isLoading } = useLogic(props);
    return (
      <CustomDialogV2
        open={open ?? false}
        header="Update Callback"
        footer={
          <Box display="flex" justifyContent="flex-end">
            <CancelSuccessButtonsGroup
              RootGridProps={{ width: 300 }}
              {...cancelSuccessButtonProps}
            />
          </Box>
        }
      >
        <Grid container spacing={2} width={350}>
          {isLoading && (
            <Grid item xs={12}>
              <Typography>Loading ...</Typography>
            </Grid>
          )}
          {!isLoading &&
            data.map((item, index) => (
              <Grid item xs={12} key={index}>
                {item.type === "text" ? (
                  <TextField
                    {...item.props}
                    fullWidth
                    value={item.value}
                    onChange={item.onChange}
                  />
                ) : item.type === "select-menu" ? (
                  <SelectMenu
                    Placeholder="Select Status"
                    options={item.options ?? []}
                    value={item.value}
                    OnChange={item.onChange}
                    Searchable={false}
                  />
                ) : null}
              </Grid>
            ))}
        </Grid>
      </CustomDialogV2>
    );
  };

export default UpdateCallbackComponent;
