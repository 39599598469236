import { GetProvider } from "api/provider/get-provider";
import { useDebounce } from "hooks/debounce-hook";
import { IProvider } from "models/provider";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { Params } from "api/provider/get-provider";
import _ from "lodash";

export interface ISuperViewParams {
  viewType: "supers";
  subOrgId?: string;
}

export interface ISubViewParams {
  viewType: "subs";
  superOrgId?: string;
}

export interface IGlobalViewParams {
  viewType?: "all";
}

export interface IParams {
  value?: string | string[] | IProvider | IProvider[];
  withOwnProviderOption?: boolean;
}
export type viewType = ISuperViewParams | ISubViewParams | IGlobalViewParams;

export const useLogic = (params: IParams & viewType) => {
  let { value } = params;

  useEffect(() => {
    setproviderValue(undefined);
    if (value)
      if (
        typeof value === "string" ||
        (Array.isArray(value) &&
          (value as Array<IProvider | string>).every(
            (item) => typeof item === "string"
          ) &&
          value.length > 0)
      ) {
        GetProvider(
          {
            page: 0,
            pageSize: 10,
            id: value as string,
          },
          (error, resp) => {
            if (error) {
              console.log(error.message);
            } else if (resp) {
              if (Array.isArray(value)) setproviderValue(resp.providers);
              else setproviderValue(resp.providers[0]);
            }
          }
        );
      } else {
        setproviderValue(value as IProvider | IProvider[]);
      }
  }, [JSON.stringify(value)]);

  const [providers, setproviders] = useState<IProvider[]>([]);
  const [count, setcount] = useState<undefined | number>(undefined);
  const user = useAppSelector((root) => root.appReducer.user);
  const [loading, setloading] = useState(false);
  const [searchTerm, setsearchTerm] = useState("");
  const debouncedTerm = useDebounce(searchTerm, 800);
  const [providerValue, setproviderValue] =
    useState<IProvider | IProvider[] | undefined>();

  // TODO: recheck the logic
  // let filter: Partial<Params> = { super_orgs: [] };

  // if (user?.menuroles === "org_admin") filter["super_org_list"] = [user?.provider_id!];
  // else if (user?.menuroles !== "admin") filter["Providers_ids"] = [user?.provider_id!];

  // if (params.viewType === "supers") filter["sub_org_list"] = [params.subOrgId];

  // if (params.viewType === "subs") filter["super_org_list"]?.push(params.superOrgId);
  //get options
  useEffect(() => {
    setloading(true);
    GetProvider(
      {
        is_active: true,
        page: 0,
        pageSize: 10,
        search: debouncedTerm,
        sub_orgs: params.viewType === "subs",
        super_orgs: params.viewType === "supers",
        id: params.viewType === "supers" ? params.subOrgId : undefined,
        // ...filter,
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setloading(false);
          setproviders((value) =>
            _.uniqBy(
              params.withOwnProviderOption
                ? [
                    ...value,
                    ...resp.providers,
                    {
                      name: user?.providerName!,
                      id: user?.provider_id!,
                    } as any,
                  ]
                : resp.providers,
              "id"
            )
          );
          setcount(resp.totalCount);
        }
      }
    );
  }, [
    debouncedTerm,
    params.viewType,
    params.viewType === "supers" && params.subOrgId,
    params.viewType === "subs" && params.superOrgId,
    params.withOwnProviderOption,
  ]);

  return { providers, count, loading, setsearchTerm, providerValue };
};
