import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Service } from "models/service";

interface Params {
  page: number;
  pageSize: number;
}
interface resp {
  total: number;
  items: Service[];
}
type GetServicesType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | resp) => any
) => any;
const GetServices: GetServicesType = (Params, callback) => {
  AxiosInstance.get("/services", { params: Params})
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { GetServices };
