import { Box, Grid } from "@mui/material";
import React from "react";

interface PageHeaderProps {
  prefix?: any;
  actions?: any;
}

const PageHeader: React.FunctionComponent<PageHeaderProps> = (props) => {
  const { prefix, actions } = props;
  return (
    <Box display="flex">
      <Box width="100%">{prefix}</Box>
      {actions}
    </Box>
  );
};

export default PageHeader;
