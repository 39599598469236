import {
  Box,
  Button,
  Chip,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { ISMS } from "models/sms";
import { IoTrashOutline } from "react-icons/io5";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
  selectedIds?: string[];
}
export interface Fields extends ISMS {
  provider_name?: string;
  selected?: boolean;
  disabledCheckbox?: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onDeleteButtonClick?: (data: Fields) => any;
  isShowDeleteButton?: boolean;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (
  params
) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "ORGANIZATION NAME",
      field: "provider_name",
      hidden: !params.columnState?.find(
        (item) => item.field === "provider_name"
      )?.checked,
      render: (data) => data.provider_name,
      sorting: false,
    },
    {
      title: "PHONE",
      field: "phone",
      hidden: !params.columnState?.find((item) => item.field === "phone")
        ?.checked,
      render: (data) => data.phone,
      sorting: false,
    },
    {
      title: "ARCHIVED",
      field: "archived",
      hidden: !params.columnState?.find((item) => item.field === "archived")
        ?.checked,
      render: (data) =>
        data.archived ? (
          <Chip variant="outlined" label="Yes" />
        ) : (
          <Chip variant="outlined" label="No" />
        ),
      sorting: false,
    },
    {
      title: "SEND AT",
      field: "send_at",
      hidden: !params.columnState?.find((item) => item.field === "send_at")
        ?.checked,
      render: (data) => data.send_at && new Date(data.send_at).toLocaleString(),
      sorting: true,
    },
    {
      title: "STATUS",
      field: "status",
      hidden: !params.columnState?.find((item) => item.field === "status")
        ?.checked,
      render: (data) =>
        data.sent ? (
          <Chip label="Sent" variant="outlined" />
        ) : data.failed ? (
          <Chip label="Failed" color="error" variant="outlined" />
        ) : data.queued ? (
          <Chip label="Queued" color="primary" variant="outlined" />
        ) : data.accepted ? (
          <Chip label="Accepted" color="info" variant="outlined" />
        ) : null,
      sorting: false,
    },
    {
      title: "MESSAGE",
      field: "message",
      hidden: !params.columnState?.find((item) => item.field === "message")
        ?.checked,
      render: (data) => data.content,
      sorting: false,
    },
    {
      title: "Failure reason",
      field: "failure_reason",
      hidden: !params.columnState?.find((item) => item.field === "failure_reason")
        ?.checked,
      render: (data) => {
        return data.failed && data.smsLogs.length ? data.smsLogs[0].at_failure_reason : "-";
      },
      sorting: false,
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: (
        <CustomCheckBox
          onChange={(e, value) =>
            params.customSelection?.onSelectAllChange?.(params.data, value)
          }
          checked={params.data.every((item) => item.selected)}
          indeterminate={
            params.data.some((item) => item.selected) &&
            !params.data.every((item) => item.selected)
          }
        />
      ),
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => (
        <CustomCheckBox
          disabled={data.disabledCheckbox}
          checked={data.selected}
          onChange={(e, value) =>
            params.customSelection?.onRowSelectChange?.(data, value)
          }
        />
      ),
    });
  }

  const actions: MaterialTableProps<Fields>["actions"] = [];

  if (params.isShowDeleteButton)
    actions.push((fields) => ({
      icon: () => (
        <Button variant="text" startIcon={<IoTrashOutline />} color="success">
          Delete
        </Button>
      ),
      onClick: () => params.onDeleteButtonClick?.(fields),
      hidden: !fields.queued,
    }));

  return {
    columns,
    data: params.data,
    icons: tableIcons,
    actions: actions,
    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
