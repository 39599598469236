export enum MessageTemplateStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Declined = "DECLINED",
  InAppeal = "IN_APPEAL",
  Rejected = "REJECTED",
  Pending_deletion = "PENDING_DELETION",
  Deleted = "DELETED",
  Reinstated = "REINSTATED",
  Flagged = "FLAGGED",
  First_paused = "FIRST_PAUSED",
  Second_Paused = "SECOND_PAUSED",
  Disabled = "DISABLED",
}
