import {
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  TabsProps,
  Typography,
} from "@mui/material";
import React from "react";
import { Get14DayDataSet } from "../../../api/meteoblue/get-14-day-dataset";
import Percipitation from "./percipitation";
import Tempreture from "./tempreture";
import { MdInfoOutline } from "react-icons/md";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";
import CustomToggleButton from "components/custom-compnents/custom-toggle-buttons/custom-toggle-buttons";

export interface TempreturePrecipitation14DayForcastProps {
  lat: number;
  lng: number;
}

const TempreturePrecipitation14DayForcast: React.SFC<TempreturePrecipitation14DayForcastProps> =
  ({ lat, lng }) => {
    const [SelectedChart, setSelectedChart] = React.useState("0");
    const TabOptions = [
      { title: "Precipitation", value: "0" },
      { title: "Temperature", value: "1" },
    ];
    const HandleTabChanges: TabsProps["onChange"] = (e, value) => {
      setSelectedChart(value);
    };

    const [DataState, SetDataState] = React.useState<
      {
        date: string;
        temperature_min: number;
        temperature_max: number;
        temperature_mean: number;
        precipitation_spread: number;
        precipitation_probability: number;
        temperature_spread: number;
        precipitation: number;
      }[]
    >([]);
    React.useEffect(() => {
      Get14DayDataSet({ lat, lng }, (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          SetDataState(
            resp.trend_day.time.map((item: any, index: number) => ({
              date: item,
              temperature_min: resp.trend_day.temperature_min[index],
              temperature_max: resp.trend_day.temperature_max[index],
              temperature_mean: resp.trend_day.temperature_mean[index],
              precipitation_spread: resp.trend_day.precipitation_spread[index],
              precipitation_probability:
                resp.trend_day.precipitation_probability[index],
              temperature_spread: resp.trend_day.temperature_spread[index],
              precipitation: resp.trend_day.precipitation[index],
            }))
          );
        }
      });
    }, [lat, lng]);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
              <CustomTitle label="14-day forecast" />
            </Grid>
            <Grid item xs={12} md={8} textAlign="right">
              <CustomToggleButton
                onChange={(value) => setSelectedChart(value)}
                value={SelectedChart}
                items={TabOptions.map((item, index) => ({
                  label: item.title,
                  value: item.value,
                }))}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box component={Paper}>
            {lat && lng ? (
              <>
                <Box mb={2}>
                  {/* <Tabs
                onChange={HandleTabChanges}
                value={SelectedChart}
                variant="fullWidth"
              >
                {TabOptions.map((item, index) => (
                  <Tab label={item.title} value={item.value} key={index}></Tab>
                ))}
              </Tabs> */}
                </Box>
                <Box height={400}>
                  <Box display={SelectedChart !== "0" ? "none" : "inline"}>
                    <Percipitation Data={DataState} />
                  </Box>
                  <Box display={SelectedChart !== "1" ? "none" : "inline"}>
                    <Tempreture Data={DataState} />
                  </Box>
                </Box>
              </>
            ) : (
              <Box textAlign="center">
                <Typography color="textSecondary">
                  <MdInfoOutline size={35} style={{ padding: "0px 5px" }} />
                  this chart requires farm coordinates
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  };

export default TempreturePrecipitation14DayForcast;
