import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";
import { useStyles } from "./custom-label.style";

interface CustomLabelProps {
  variant?: "success" | "error";
  label?: string;
}

const CustomLabel: React.FunctionComponent<CustomLabelProps> = (props) => {
  const { variant, label } = props;
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      {variant === "success" && (
        <IoCheckmarkSharp
          size={20}
          className={`${classes.iconRoot} ${classes.colorSuccess} `}
        />
      )}
      {variant === "error" && (
        <IoCloseSharp
          size={20}
          className={`${classes.iconRoot} ${classes.colorError} `}
        />
      )}
      <Typography
        className={`${classes.textRoot} ${
          variant === "success" && classes.colorSuccess
        } ${variant === "error" && classes.colorError}`}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default CustomLabel;

CustomLabel.defaultProps = {
  variant: "success",
};
