import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Resp {}
interface Params {
  value: string[];
  farmer_id: string;
  label_key_id: string;
}

type CreateFarmerLabelType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const CreateFarmerLabel: CreateFarmerLabelType = (Params, callback) => {
  AxiosInstance.post("/farmer-labels", { ...Params })
    .then((resp) => {
      callback(null, resp.data.data.all_farmer_labels);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { CreateFarmerLabel };
