import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Certificate } from "../../models/certificate";

interface Resp {}
interface Params extends Certificate {}

type UpdateCertificateType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const UpdateCertificate: UpdateCertificateType = (Params, callback) => {
  AxiosInstance.patch("/certificates/" + Params.id , { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { UpdateCertificate };
