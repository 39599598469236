import { IUser } from "models/user";
import React from "react";

export class RouteItem {
  public title: string = "";
  public name: string = "";
  public path: string = "";
  public icon?: React.ElementType = undefined;
  public subList?: RouteItem[] = [];
  public roles?: IUser["menuroles"][];
  public isLink?: boolean;
  public component?: React.ElementType;
  public hideInMenu?: boolean;
  public iconActive?: React.ElementType;

  constructor(object: RouteItem) {
    Object.assign(this, object);
  }
}
