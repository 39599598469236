import { grey, red, blue } from "@mui/material/colors";
import moment from "moment";
import React from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export interface TempretureProps {
  Data: {
    date: string;
    temperature_spread: number;
    temperature_mean: number;
    temperature_max: number;
    temperature_min: number;
  }[];
}

const Tempreture: React.FC<TempretureProps> = ({ Data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={300}
        data={Data.map((item) => ({
          temperature_mean: item.temperature_mean?.toFixed(2),
          temperature_max: item.temperature_max?.toFixed(2),
          temperature_min: item.temperature_min?.toFixed(2),
          date: moment(item.date).format("MMM do"),
          temperature_spread: [
            (item.temperature_mean + item.temperature_spread)?.toFixed(2),
            (item.temperature_mean - item.temperature_spread)?.toFixed(2),
          ],
        }))}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis
          tickFormatter={(v, i) => parseInt(v).toString()}
          label={{
            angle: -90,
            position: "insideMiddleLeft",
            value: "Temperature (°C)",
          }}
          domain={["dataMin - 3", "dataMax + 3"]}
        />
        <Tooltip />
        <Legend layout="vertical" verticalAlign="middle" align="right" />
        <Line
          type="monotone"
          dataKey="temperature_max"
          name="Temperature Max"
          stroke={red["400"]}
        />
        <Line
          type="monotone"
          dataKey="temperature_mean"
          name="Temperature Mean"
          stroke={grey["400"]}
        />
        <Line
          type="monotone"
          dataKey="temperature_min"
          name="Temperature Min"
          stroke={blue["400"]}
        />
        <Area
          dataKey="temperature_spread"
          name="Mean Temperature Uncertainty"
          stroke={grey["400"]}
          opacity={0.1}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Tempreture;
