import { Box, Button, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
// import { IoOpenOutline, IoRemoveSharp } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { ICallback } from "models/callback";
import { IoCreateOutline, IoOpenOutline } from "react-icons/io5";
import { Farmer } from "models/farmers";
import { Link } from "react-router-dom";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
  selectedFarmersIds?: string[];
  selectedByPhone?: boolean;
}
export interface Fields
  extends ICallback,
    Record<"Farmer", Pick<Farmer, "id" | "firstName" | "lastName" | "phone">> {
  selected: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onEditButtonClick?: (data: Fields) => any;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (
  params
) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "CREATED",
      field: "date_created",
      hidden: !params.columnState?.find((item) => item.field === "date_created")
        ?.checked,
      render: (data) => new Date(data.dateCreated).toLocaleString(),
    },
    {
      title: "MODIFIED",
      field: "last_modified",
      hidden: !params.columnState?.find(
        (item) => item.field === "last_modified"
      )?.checked,
      render: (data) => new Date(data.lastModified).toLocaleString(),
    },
    {
      title: "FARMER NAME",
      field: "farmer_name",
      sorting: false,
      hidden: !params.columnState?.find((item) => item.field === "farmer_name")
        ?.checked,
      render: (data) => {
        return (
          <Link to={`/farmers/management/${data.Farmer.id}`}>
            {`${data.Farmer.firstName} ${data.Farmer.lastName}`}
          </Link>
        )
      },
    },
    {
      title: "STATUS",
      field: "status",
      hidden: !params.columnState?.find((item) => item.field === "status")
        ?.checked,
      render: (data) => data.type,
    },
    {
      title: "NOTE",
      field: "notes",
      hidden: !params.columnState?.find((item) => item.field === "notes")
        ?.checked,
      render: (data) => data.notes,
    },
    {
      title: "FARMER PHONE",
      field: "farmer_phone",
      hidden: !params.columnState?.find((item) => item.field === "farmer_phone")
        ?.checked,
      render: (data) => data.Farmer.phone,
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: (
        <CustomCheckBox
          onChange={(e, value) =>
            params.customSelection?.onSelectAllChange?.(params.data, value)
          }
          checked={params.data.every((item) => item.selected)}
          indeterminate={
            params.data.some((item) => item.selected) &&
            !params.data.every((item) => item.selected)
          }
        />
      ),
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => (
        <CustomCheckBox
          checked={data.selected}
          onChange={(e, value) =>
            params.customSelection?.onRowSelectChange?.(data, value)
          }
        />
      ),
    });
  }

  return {
    actions: [
      (fields) => ({
        icon: () => (
          <Button
            variant="text"
            onClick={() => params.onEditButtonClick?.(fields)}
            color="success"
            startIcon={<IoCreateOutline />}
          >
            Edit
          </Button>
        ),
        onClick: () => null,
      }),
    ],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
