import { Grid, Typography } from "@mui/material";
import PeopleTree from "assets/images/svg/people-tree.svg";
import { useStyles } from "./empty-table-label-component.style";
interface EmptyTableLabelComponentProps {
  title?: string;
  paragraph?: string;
}

const EmptyTableLabelComponent: React.FunctionComponent<EmptyTableLabelComponentProps> =
  (props) => {
    const { title, paragraph } = props;
    const classes = useStyles();
    return (
      <Grid container spacing={2} justifyContent="center" textAlign="center">
        <Grid item xs={12}>
          <img src={PeopleTree} width={350} />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h4" className={classes.text}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.text}>
            {paragraph}
          </Typography>
        </Grid>
      </Grid>
    );
  };

export default EmptyTableLabelComponent;

EmptyTableLabelComponent.defaultProps = {
  title: "No records to display",
  paragraph: "You can click on the add new message button to add messages",
};
