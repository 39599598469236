import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface FarmerAnalyticsPageState {
  selectedProviderId?: string;
  selectedProviderName?: string;
}

// Define the initial state using that type
const initialState: FarmerAnalyticsPageState = {
  selectedProviderId: undefined,
  selectedProviderName: undefined,
};

export const farmerAnalyticsPageSlice = createSlice({
  name: "farmer-analytics-page",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setProviderId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedProviderId = action.payload;
    },
    setProviderName: (state, action: PayloadAction<string | undefined>) => {
      state.selectedProviderName = action.payload;
    },
  },
});

export const { setProviderId, setProviderName } = farmerAnalyticsPageSlice.actions;

export default farmerAnalyticsPageSlice.reducer;
