import { AxiosInstance } from "configs/axios";
import { FunctionComponent, useEffect, useState } from "react";
import { GeoJSON, useMap } from "react-leaflet";
import * as L from "leaflet";

interface HighResVectorCropTypeMapLayerProps {
  onBounds?: (bounds: L.LatLngBoundsExpression) => any;
}

const HighResVectorCropTypeMapLayer: FunctionComponent<HighResVectorCropTypeMapLayerProps> = (props) => {
  const { onBounds } = props;
  const [geoJson, setgeoJson] = useState(undefined);

  const map = useMap();

  var feature = L.geoJSON(geoJson);

  if ((feature.getBounds() as any)._northEast) {
    map?.setMaxBounds(feature.getBounds());
    map?.fitBounds(feature.getBounds());
  }

  useEffect(() => {
    AxiosInstance.get("crop-simulations/high-resolution-vector-crop-type").then((resp) => {
      setgeoJson(resp.data);
    });
  }, []);

  useEffect(() => {
    onBounds?.(L.geoJSON(geoJson).getBounds());
  }, [geoJson]);

  const GeoJSONProps: any = {
    style: (feature: any) => {
      const cropType = feature.properties.crop_type; // 0 means maize and 1 beans
      return {
        fillColor: cropType == 0 ? "red" : "yellow",
        weight: 0.5,
        opacity: 1,
        color: "white",
        dashArray: "0",
        fillOpacity: 0.3,
      };
    },

    data: geoJson,
    key: Math.random(),
  };
  return <GeoJSON {...GeoJSONProps} />;
};

export default HighResVectorCropTypeMapLayer;
