import * as React from "react";
import FarmersAnalyticsFilterOverviewConfirmationPopup, {
  FarmersAnalyticsFilterOverviewConfirmationPopupProps,
} from "../confirmation-pop-up";
import { Box, Chip, ChipProps, Tooltip, Typography } from "@mui/material";
import {
  ConstituencyStateItem,
  CountyStateItem,
  RegionStateItem,
  WardsStateItem,
} from "store/reducers/farmers-analytics-page/map/farmers-analytics-map-reducer";

export interface AnalyticsMapFiltersOverviewWardFilterItemProps {
  CrossOf?: Boolean;
}

interface WardFilterTypeInterface {
  Variant: "ward";
  payLoad: WardsStateItem;
  onRemove: (data: WardsStateItem) => void;
}

interface CountyFilterTypeInterface {
  Variant: "county";
  payLoad: CountyStateItem;
  onRemove: (data: CountyStateItem) => void;
}

interface RegionFilterTypeInterface {
  Variant: "region";
  payLoad: RegionStateItem;
  onRemove: (data: RegionStateItem) => void;
}

interface ConstituencyFilterTypeInterface {
  Variant: "constituency";
  payLoad: ConstituencyStateItem;
  onRemove: (data: ConstituencyStateItem) => void;
}

const AnalyticsMapFiltersOverviewWardFilterItem: React.SFC<
  AnalyticsMapFiltersOverviewWardFilterItemProps &
    (
      | RegionFilterTypeInterface
      | WardFilterTypeInterface
      | ConstituencyFilterTypeInterface
      | CountyFilterTypeInterface
    )
> = ({ CrossOf, ...props }) => {
  const [PopupAnchorEl, SetPopupAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const ConfirmationProps: FarmersAnalyticsFilterOverviewConfirmationPopupProps =
    {
      PopupAnchorEl: PopupAnchorEl,
      onCancle: () => SetPopupAnchorEl(null),
      onConfirm: () => {
        SetPopupAnchorEl(null);
        props.Variant === "ward" && props.onRemove(props.payLoad);
        props.Variant === "region" && props.onRemove(props.payLoad);
        props.Variant === "constituency" && props.onRemove(props.payLoad);
        props.Variant === "county" && props.onRemove(props.payLoad);
      },
    };
  let ChipTitle: React.ReactElement = (
    <Box>
      <Typography display="inline">
        {props.Variant.charAt(0).toUpperCase() + props.Variant.slice(1)}:
      </Typography>
      &nbsp;
      <Typography display="inline" style={{ fontWeight: 600 }}>
        {props.Variant === "ward" && props.payLoad.ward}
        {props.Variant === "region" && props.payLoad.region}
        {props.Variant === "county" && props.payLoad.county}
        {props.Variant === "constituency" && props.payLoad.constituency}
      </Typography>
    </Box>
  );
  const ChipProps: ChipProps = {
    label: ChipTitle,
    size: "medium",
    onDelete: (e) => SetPopupAnchorEl(e.currentTarget),
    style: { opacity: CrossOf && 0.4 },
  };
  const TooltipTitle =
    props.Variant === "ward"
      ? `${props.payLoad.region} Region,${props.payLoad.county} County,${props.payLoad.constituency} Constituency`
      : props.Variant === "constituency"
      ? `${props.payLoad.region} Region,${props.payLoad.county} County`
      : props.Variant === "county"
      ? `${props.payLoad.region} Region`
      : "";
  return (
    <Tooltip title={TooltipTitle} arrow>
      <Box m={0.5} display="inline-block">
        <Chip {...ChipProps} />
        <FarmersAnalyticsFilterOverviewConfirmationPopup
          {...ConfirmationProps}
        />
      </Box>
    </Tooltip>
  );
};

export default AnalyticsMapFiltersOverviewWardFilterItem;
