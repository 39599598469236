import {
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import React from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";

interface ContactUsStackedInfoListProp {
  email: string;
  phone: string;
}

const ContactUsStackedInfoList: React.FunctionComponent<
  ContactUsStackedInfoListProp
> = ({ email, phone }) => {
  const Data = [
    { icon: FaPhone, title: "Phone", value: phone },
    { icon: FaEnvelope, title: "Email", value: email },
  ];
  return (
    <Box component={Paper}>
      <List>
        {Data.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <item.icon size={20} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ color: "textSecondary" }}
              secondaryTypographyProps={{ color: "textPrimary" }}
              primary={item.title}
              secondary={item.value}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ContactUsStackedInfoList;
