import { TextField, Grid, Box, Typography } from "@mui/material";
import * as React from "react";
import CustomDialog from "components/custom-compnents/custom-dialog/custom-dialog";
import SelectMenu, {
  option,
} from "components/input-components/select-menu/SelectMenu";
import { Formik } from "formik";
import { AddSurveyQuestion } from "api/survey-questions/add-survey-question";
import * as yup from "yup";
import { UpdateSurveyQuestion } from "api/survey-questions/update-survey-question";
import { GetSurveyQuestions } from "api/survey-questions/get-survey-questions";
import { SurveyQuestion } from "../../../models/survey-questions";
import SingleChoiceList, { AnswerWithIsInEditMode } from "./single-choice-list";
import { GetSurveyQuestionAnswers } from "api/survey-question-answer/get-answer";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CreateButton from "components/custom-compnents/create-button/create-button";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import CustomSubtitle from "components/custom-compnents/custom-subtitle/custom-subtitle";
export interface AddUpdateSurveyQuestionProps {
  open?: boolean;
  OnCancel: () => any;
  survey_id: string;
}

export interface UpdateProps {
  Variant: "update";
  question_id: string;
  OnUpdated: () => any;
}

export interface AddProps {
  Variant: "add";
  OnAdded: () => any;
}

export type combinedType = AddUpdateSurveyQuestionProps &
  (UpdateProps | AddProps);

const AddUpdateSurveyQuestion: React.FC<combinedType> = ({
  open,
  survey_id,
  OnCancel,
  ...Props
}) => {
  const Data = [
    {
      placeholder: "Select Question Type",
      options: [
        { title: "Single Choice", value: "single choice" },
        { title: "Text", value: "free text" },
      ],
      type: "select",
      name: "type",
    },
    {
      label: "Question Text",
      placeholder: "Insert Qustion ...",
      type: "text",
      name: "question",
    },
  ];

  const validationSchema = yup.object().shape({
    type: yup.string().required().oneOf(["single choice", "free text"]),
    question: yup.string().required(),
  });
  React.useEffect(() => {
    if (Props.Variant === "update") {
      SetLoadingData(true);
      GetSurveyQuestions(
        { survey_id, is_active: true, id_list: [Props.question_id] },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            console.log('resp', resp)
            SetQuestion(resp.items[0].question);
            SetType(resp.items[0].type);
            SetLoadingData(false);
            SetDefault_Next_Question(
              resp.items[0].default_next_qid ?? undefined!
            );
          }
        }
      );
      Props.Variant === "update" &&
        GetSurveyQuestionAnswers(
          { question_id: Props.question_id, survey_id },
          (error, resp) => {
            if (error) {
              console.log(error.message);
            } else if (resp) {
              SetAnswerListForSingleChoiceQuestion(
                resp.items
                  .filter((item) => !item.deleted_at)
                  .map((item) => ({
                    answer: item.answer,
                    IsInEditMode: false,
                    next_qid: item.next_qid != "" ? item.next_qid : "none",
                    id: item.id,
                  }))
              );
            }
          }
        );
    }
  }, [Props.Variant === "update" && Props.question_id]);

  React.useEffect(() => {
    GetSurveyQuestions({ is_active: true, survey_id }, (error, resp) => {
      if (error) {
        console.log(error);
      } else if (resp) {
        SetQuestionsOptions(
          resp.items
            .map((item) => ({ title: item.question, value: item.id }))
            .concat([{ title: "Exit Screen", value: "none" }])
        );
      }
    });
  }, []);

  const [Question, SetQuestion] = React.useState("");
  const [type, SetType] =
    React.useState<SurveyQuestion["type"] | "none">("none");
  const [LoadingData, SetLoadingData] = React.useState(true);
  const [Default_Next_Question, SetDefault_Next_Question] =
    React.useState("none");
  const [QuestionsOptions, SetQuestionsOptions] = React.useState<option[]>([]);
  const [OpenNewAnswerArea, SetOpenNewAnswerArea] = React.useState(false);
  const [NewAnswerValue, SetNewAnswerValue] = React.useState("");
  const [NewAnswerScreen, SetNewAnswerScreen] = React.useState("none");
  const [
    AnswerListForSingleChoiceQuestion,
    SetAnswerListForSingleChoiceQuestion,
  ] = React.useState<AnswerWithIsInEditMode[]>([]);
  const HandleEditSingleChoiceAnswerButtonClicked = (answer_id: string) => {
    const targeted_answer_index = AnswerListForSingleChoiceQuestion.findIndex(
      (item) => item.id === answer_id
    );
    const New_Answer_Array = AnswerListForSingleChoiceQuestion.map(
      (item) => item
    );
    New_Answer_Array[targeted_answer_index]["IsInEditMode"] = true;
    SetAnswerListForSingleChoiceQuestion(New_Answer_Array);
  };

  if (type === "free text") {
    Data.push({
      placeholder: "Select Default Next Question",
      options:
        Props.Variant === "update"
          ? QuestionsOptions.filter((item) => item.value != Props.question_id)
          : QuestionsOptions,
      type: "select",
      name: "default_next_question",
    });
  }

  const HandleDeleteSingleChoiceAnswerButtonClicked = (answer_id: string) => {
    const New_Answer_Array = AnswerListForSingleChoiceQuestion.filter(
      (item) => item.id !== answer_id
    );
    SetAnswerListForSingleChoiceQuestion(New_Answer_Array);
  };
  const HandleNewAnswerButtonClicked = () => SetOpenNewAnswerArea(true);
  const HandleNewAnswerDoneClicked = () => {
    if (NewAnswerValue) {
      const New_Answer_Array = AnswerListForSingleChoiceQuestion.map(
        (item) => item
      );
      New_Answer_Array.push({
        answer: NewAnswerValue,
        next_qid: NewAnswerScreen,
        id: (Math.random() * 10000).toString(),
        IsInEditMode: false,
      });
      SetAnswerListForSingleChoiceQuestion(New_Answer_Array);
    }
    SetOpenNewAnswerArea(false);
    SetNewAnswerScreen("none");
    SetNewAnswerValue("");
  };
  const HandleNewAnswerChanged = (answer: string) => {
    SetNewAnswerValue(answer);
  };
  const HandleNewAnswerScreenChanged = (screen_id: string) => {
    SetNewAnswerScreen(screen_id);
  };
  const HandleAnswerChanged = (answer_id: string, newAnswer: string) => {
    const targeted_answer_index = AnswerListForSingleChoiceQuestion.findIndex(
      (item) => item.id === answer_id
    );
    const New_Answer_Array = AnswerListForSingleChoiceQuestion.map(
      (item) => item
    );
    New_Answer_Array[targeted_answer_index]["answer"] = newAnswer;
    SetAnswerListForSingleChoiceQuestion(New_Answer_Array);
  };
  const HandleAnswerScreenChanged = (
    answer_id: string,
    newScreenId: string
  ) => {
    const targeted_answer_index = AnswerListForSingleChoiceQuestion.findIndex(
      (item) => item.id === answer_id
    );
    const New_Answer_Array = AnswerListForSingleChoiceQuestion.map(
      (item) => item
    );
    New_Answer_Array[targeted_answer_index]["next_qid"] = newScreenId;
    SetAnswerListForSingleChoiceQuestion(New_Answer_Array);
  };
  const HandleEditAnswerDone = (answer_id: string) => {
    const targeted_answer_index = AnswerListForSingleChoiceQuestion.findIndex(
      (item) => item.id === answer_id
    );
    const New_Answer_Array = AnswerListForSingleChoiceQuestion.map(
      (item) => item
    );
    New_Answer_Array[targeted_answer_index]["IsInEditMode"] = false;
    SetAnswerListForSingleChoiceQuestion(New_Answer_Array);
  };
  return (
    <Formik
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize={true}
      initialValues={{
        type: type,
        question: Question,
        default_next_qid: Default_Next_Question,
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        if (Props.Variant === "add") {
          AddSurveyQuestion(
            {
              // ...values,
              survey_id,
              type: values.type as SurveyQuestion["type"],
              answer_list: AnswerListForSingleChoiceQuestion.map((item) => ({
                answer: item.answer,
                next_qid: item.next_qid,
              })),
              question: values.question
            },
            (error, resp) => {
              if (error) {
                actions.setErrors(error.response?.data.errors);
              } else if (resp) {
                Props.OnAdded();
                actions.setSubmitting(false);
              }
            }
          );
        } else if (Props.Variant === "update") {
          UpdateSurveyQuestion(
            {
              ...values,
              survey_id,
              question_id: Props.question_id,
              type: values.type as SurveyQuestion["type"],
              answer_list: AnswerListForSingleChoiceQuestion.map((item) => ({
                answer: item.answer,
                next_qid: item.next_qid,
              })),
            },
            (error, resp) => {
              if (error) {
                actions.setErrors(error.response?.data.errors);
              } else if (resp) {
                Props.OnUpdated();
              }
            }
          );
        }
      }}
    >
      {(FormikProps) => (
        // <CustomDialog
        //   open={open}
        //   Header={
        //     Props.Variant === "update" ? "Update Question" : "Add Question"
        //   }
        //   ActionButtonsProps={{
        //     ButtonsVariant: "ConfirmCancel",
        //     OnCancel: OnCancel,
        //     OnConfirm: () => {
        //       document
        //         .getElementById("submit_button_add_update_survey_qustion")
        //         ?.click();
        //     },
        //     DisableConfirm: FormikProps.isSubmitting,
        //   }}
        // >
        <CustomDialogV2
          header={
            Props.Variant === "update" ? "Update Question" : "Add Question"
          }
          open={open ?? false}
          footer={
            <Grid
              container
              spacing={2}
              display="flex"
              justifyContent="flex-end"
            >
              {type === "single choice" && (
                <Grid item xs={5}>
                  <CreateButton
                    fullWidth
                    onClick={HandleNewAnswerButtonClicked}
                  >
                    Add New Answer
                  </CreateButton>
                </Grid>
              )}
              <Grid item xs={7}>
                <CancelSuccessButtonsGroup
                  CancelButtonProps={{ label: "Cancel", onClick: OnCancel }}
                  SuccessButtonProps={{
                    label: "Add Question",
                    disabled: FormikProps.isSubmitting,
                    onClick: () => FormikProps.submitForm(),
                  }}
                />
              </Grid>
            </Grid>
          }
        >
          <Box width={500}>
            {LoadingData && Props.Variant === "update" ? (
              <Typography>Loading ...</Typography>
            ) : (
              <form onSubmit={FormikProps.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} mt={2}>
                    <CustomSubtitle label="Question Details" />
                  </Grid>
                  {Data.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      {item.type === "select" ? (
                        <SelectMenu
                          key={index}
                          error={Boolean(
                            (FormikProps as any).errors[item.name]
                          )}
                          helperText={(FormikProps as any).errors[item.name]}
                          OnChange={(e) => {
                            item.name === "type" &&
                              SetType(e.target.value as any);
                            FormikProps.setFieldValue(
                              item.name,
                              e.target.value
                            );
                          }}
                          value={(FormikProps as any).values[item.name]}
                          options={item.options as option[]}
                          Searchable={false}
                          Placeholder={item.placeholder}
                        />
                      ) : (
                        <CustomTextField
                          error={Boolean(
                            (FormikProps as any).errors[item.name]
                          )}
                          helperText={(FormikProps as any).errors[item.name]}
                          onChange={(FormikProps as any).handleChange}
                          name={item.name}
                          size="small"
                          autoFocus={index === 1 && Props.Variant === "update"}
                          placeholder={item.label}
                          fullWidth
                          value={(FormikProps as any).values[item.name]}
                        />
                      )}
                    </Grid>
                  ))}

                  {type === "single choice" && (
                    <>
                      <Grid item xs={12} mt={2}>
                        <CustomSubtitle label="Answers" />
                      </Grid>
                      <Grid item xs={12}>
                        <SingleChoiceList
                          AnswerList={AnswerListForSingleChoiceQuestion}
                          ScreenOptionsList={
                            Props.Variant == "add"
                              ? QuestionsOptions
                              : QuestionsOptions.filter(
                                (item) => item.value != Props.question_id
                              )
                          }
                          OnEditClicked={
                            HandleEditSingleChoiceAnswerButtonClicked
                          }
                          OnDeleteClicked={
                            HandleDeleteSingleChoiceAnswerButtonClicked
                          }
                          ShowNewAnswerArea={OpenNewAnswerArea}
                          NewAnswerNextScreenId={NewAnswerScreen}
                          NewAnswerValue={NewAnswerValue}
                          OnNewAnswerDoneClicked={HandleNewAnswerDoneClicked}
                          OnNewAnswerChanged={HandleNewAnswerChanged}
                          OnNewAnswerScreenChanged={
                            HandleNewAnswerScreenChanged
                          }
                          OnAnswerChanged={HandleAnswerChanged}
                          OnAnswerScreenChanged={HandleAnswerScreenChanged}
                          OnEditAnswerDone={HandleEditAnswerDone}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <button
                  type="submit"
                  id="submit_button_add_update_survey_qustion"
                  style={{ display: "none" }}
                />
              </form>
            )}
          </Box>
        </CustomDialogV2>
        //  </CustomDialog>
      )}
    </Formik>
  );
};

export default AddUpdateSurveyQuestion;
