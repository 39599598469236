import { Box, Grid } from "@mui/material";
import ProvidersInput, { ProvidersInputProps } from "components/input-components/providers-input/providers-input";
import { IProvider } from "models/provider";
import React from "react";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import CreateButton, { CreateButtonProps } from "../create-button/create-button";
import CustomSearchTextField, { CustomSearchTextFieldProps } from "../custom-search-text-field/custom-search-text-field";

interface FarmerLabelKeysPageHeaderProps {
  labelCountProps?: CountLabelProps;
  searchProps?: CustomSearchTextFieldProps;
  providersData?: IProvider[];
  createButtonProps?: CreateButtonProps;
  showProviderFilter?: boolean;
  onProvidersChange?: ProvidersInputProps["onChange"];
}

const FarmerLabelKeysPageHeader: React.FunctionComponent<FarmerLabelKeysPageHeaderProps> = (props) => {
  const { labelCountProps, searchProps, providersData, createButtonProps, showProviderFilter, onProvidersChange } = props;

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item lg={!showProviderFilter ? 8 : 6} xs={12}>
        <CountLabel label="Farmer Label Keys " {...labelCountProps} />
      </Grid>
      <Grid item lg={!showProviderFilter ? 4 : 6} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={5} xs={12}>
            <CustomSearchTextField fullWidth {...searchProps} />
          </Grid>
          {showProviderFilter && (
            <Grid item lg={4} xs={12}>
              <Box bgcolor="white">
                <ProvidersInput limitTags={1} fullwidth value={providersData} onChange={onProvidersChange} />
              </Box>
            </Grid>
          )}
          <Grid item lg={!showProviderFilter ? 6 : 3} xs={12}>
            <CreateButton fullWidth {...createButtonProps}>
              New Label Key
            </CreateButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FarmerLabelKeysPageHeader;
