import { useState } from "react";
import { useAppSelector } from "store/hooks";

export const useLogic = () => {
  const user = useAppSelector((root) => root.appReducer.user);
  const [selectedTab, setselectedTab] = useState<"all" | "own">(
    user?.menuroles === "admin" ? "all" : "own"
  );
  const handleTabChange = setselectedTab;

  return { selectedTab, handleTabChange };
};
