// import { Box, Button } from "@mui/material";
import CustomMenuButton from "components/custom-compnents/custom-menu-button/custom-menu-button";
import CustomMenu from "components/custom-compnents/custom-menu-component/custom-menu-component";
import CustomTabsComponent from "components/custom-compnents/custom-tabs-component/custom-tabs-component";
// import CustomToggleButton from "components/custom-compnents/custom-toggle-buttons/custom-toggle-buttons";
import PageHeader from "components/custom-compnents/page-header/page-header";
import * as React from "react";
import AddUpdateProviderComponent from "../Add-Update-Provider/Add-Update-Provider";
import AffiliateOrganizationDialog from "../affiliate-organization-dialog/affiliate-organization-dialog";
import { useLogic } from "./provider-page-header.logic";
import { Button } from "@mui/material";
import { CustomMenuProps } from "components/custom-compnents/custom-menu-component/custom-menu-component";
import NoteDialog from "components/custom-compnents/note-dialog/note-dialog";

interface ProviderPageHeaderProps {
  selectedTab?: any;
  onTabChange?: (data: any) => any;
}

const ProviderPageHeader: React.FunctionComponent<ProviderPageHeaderProps> = (props) => {
  const { onTabChange, selectedTab } = props;
  const {
    provider_name,
    provider_id,
    handleCancelUpdateProvider,
    isOpenUpdateProvider,
    handleOpenUpdateProvider,
    handleEditedProvider,
    handleAffiliated,
    handleCancelAffiliate,
    // handleOpenAffliate,
    isOpenAffiliate,
    tabsOptionsMenu,
    isOpenAddPhoneNoteModal,
    handleHidePhoneNote,
    handleSetupWallet,
    currentProvider,
  } = useLogic();
  const menuData: CustomMenuProps["menuData"] = [
    {
      label: "Edit Own Organization Info",
      onClick: handleOpenUpdateProvider,
    },
    {
      label: "Set up agriWALLET",
      onClick: handleSetupWallet,
      hidden: currentProvider && !!currentProvider?.ewallet_user?.id,
    },
    // { label: "Affiliate To Organization", onClick: handleOpenAffliate },
  ];

  return (
    <>
      <AddUpdateProviderComponent variant="update" Provider={{ id: provider_id!, name: provider_name! }} onCancel={handleCancelUpdateProvider} open={isOpenUpdateProvider} onUpdated={handleEditedProvider} />
      <AffiliateOrganizationDialog sub_org={provider_id} sub_org_name={provider_name} onAffiliated={handleAffiliated} open={isOpenAffiliate} onCancel={handleCancelAffiliate} />
      <NoteDialog open={isOpenAddPhoneNoteModal} onHideClick={handleHidePhoneNote} content="Please update phone number on your organization first" header="Note" />
      <PageHeader
        prefix={
          <CustomTabsComponent
            TabData={tabsOptionsMenu}
            TabsProps={{
              value: selectedTab,
              onChange: (e, value) => onTabChange?.(value),
            }}
          />
        }
        actions={
          <CustomMenu menuData={menuData}>
            <Button variant="text" color="success">
              Actions
            </Button>
          </CustomMenu>
          // <Box borderBottom={"solid 1px #dbdbdb"}>
          //   <Button onClick={handleOpenUpdateProvider} variant="text" color="success">
          //     Edit Own Organization
          //   </Button>
          // </Box>
        }
      />
    </>
  );
};

export default ProviderPageHeader;
