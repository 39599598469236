import { Box } from "@mui/material";
import React from "react";
import ProvidersTable from "components/data-tables/providers-table/providers-table";

export interface ProvidersTabBoxProps {
  farmer_id: string;
}

const ProvidersTabBox: React.FC<ProvidersTabBoxProps> = ({ farmer_id }) => {
  return (
    <Box>
      <ProvidersTable farmersIds={[farmer_id]} columnsToExclude={["email", "isActive", "type"]} viewType="all" />
    </Box>
  );
};

export default ProvidersTabBox;
