import { GetBroadcastsMessages } from "api/broadcasts-messages/get-broadcasts-messages";
import { CustomBroadcastMessagesTableProps } from "components/custom-compnents/custom-broadcast-messages-table/custom-broadcast-messages-table";
import { combinedProps } from "components/standalone-components/add-update-broadcast-message/add-update-broadcast-message";
import { RemoveBroadcastMessageComponentProps } from "components/standalone-components/remove-broadcast-message-component/remove-broadcast-message-component";
import { BroadcastMessage } from "models/broadcasts-message";
import React from "react";
import { useAppSelector } from "store/hooks";

export interface IParams {
  broadcast_id?: string;
}

export const useLogic = (params: IParams) => {
  const { broadcast_id } = params;
  const [isShowAddMessageDialog, setIsShowAddMessageDialog] =
    React.useState(false);
  const [isShowUpdateMessageDialog, setiIsShowUpdateMessageDialog] =
    React.useState(false);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [messageToUpdate, setMessageToUpdate] =
    React.useState<undefined | BroadcastMessage>(undefined);
  const [messageDataToDelete, setMessageDataToDelete] =
    React.useState<BroadcastMessage | undefined>(undefined);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [data, setData] = React.useState<BroadcastMessage[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [columnsState, setColumnsState] = React.useState([
    { field: "subject", checked: true },
    { field: "is_active", checked: true },
    { field: "message", checked: true },
    { field: "day_relative", checked: true },
    { field: "hour_relative", checked: true },
    { field: "min_relative", checked: true },
    { field: "send_on_day_absolute", checked: true },
  ]);

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetBroadcastsMessages(
      {
        page: currentPage,
        pageSize: perPage,
        broadcast_id: broadcast_id ?? undefined,
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setIsLoading(false);
          setTotalCount(resp.total);
          setData(resp.items);
        }
      }
    );
  }, [currentPage, perPage, dataUpdateIndex]);

  const handleAddedMessage = () => {
    setIsShowAddMessageDialog(false);
    refreshData();
  };

  const handleCancelAddNewMessage = () => setIsShowAddMessageDialog(false);

  const handleOpenAddMessageDialog = () => setIsShowAddMessageDialog(true);

  const handleCancelEditMessage = () => setiIsShowUpdateMessageDialog(false);

  const handleUpdatedMessage = () => {
    setiIsShowUpdateMessageDialog(false);
    refreshData();
  };

  const user = useAppSelector((root) => root.appReducer.user);

  const preparedData = data.map((item) => ({ ...item, selected: true }));

  const tableProps: CustomBroadcastMessagesTableProps = {
    onRemoveButtonClick: (data) => {
      setMessageDataToDelete(data);
      setIsShowDeleteDialog(true);
    },
    onEditButtonClick: (data) => {
      setMessageToUpdate(data);
      setiIsShowUpdateMessageDialog(true);
    },
    isLoading: isLoading,
    data: preparedData,
    countLabelProps: {
      count: totalCount,
    },
    actionBarProps: {
      addButtonProps: {
        onClick: handleOpenAddMessageDialog,
      },
      isShowCreateButton: user?.menuroles !== "user",
      isShowColumnButton: true,
      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
    },
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };

  const addMessageDialogProps: combinedProps = {
    Variant: "Add",
    open: isShowAddMessageDialog,
    onAdded: handleAddedMessage,
    broadcast_id: broadcast_id as any,
    onCancel: handleCancelAddNewMessage,
  };

  const updateMessageDilaogProps: combinedProps = {
    Variant: "Update",
    broadcast_id: broadcast_id as any,
    message_id: messageToUpdate?.id as any,
    onCancel: handleCancelEditMessage,
    open: isShowUpdateMessageDialog,
    onUpdated: handleUpdatedMessage,
  };

  const handleCancelDelete = () => {
    setIsShowDeleteDialog(false);
  };

  const handleDeleted = () => {
    setIsShowDeleteDialog(false);
    refreshData();
  };

  const removeMessageDialogProps: RemoveBroadcastMessageComponentProps = {
    onCancel: handleCancelDelete,
    onDeleted: handleDeleted,
    open: isShowDeleteDialog,
    id: messageDataToDelete?.id as any,
  };

  return {
    tableProps,
    addMessageDialogProps,
    updateMessageDilaogProps,
    removeMessageDialogProps,
  };
};
