import axios, { AxiosRequestConfig } from "axios";
import { authTokenService } from "../services/auth-token-service/auth-token-service";
import { SetIsCheckingUser, removeUser } from "../store/reducers/app/app-slice";
import { store } from "../store/store";

export const Configs: AxiosRequestConfig = {
  baseURL: (window as any).env.REACT_APP_API_BASEURL,
  headers: {
    Authorization: "Bearer " + authTokenService.GetTokenLocally(),
  },
};

const AxiosInstance = axios.create(Configs);

AxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      //remove token,user,and reolad
      authTokenService.RemoveTokenLocally();
      store.dispatch(SetIsCheckingUser(false));
      store.dispatch(removeUser());
      // window.location.reload();
    }
    return Promise.reject(error);
  }
);
export { AxiosInstance };
