import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { SurveyQuestionAnswer } from "../../models/survey-questions-answer";
interface Resp {}
interface Params {
  survey_id: string;
  question: string;
  sort_order?: number;
  default_next_qid?: string;
}
interface TextQuestionParams {
  type: "free text" | undefined;
}
interface SingleChoiceParams {
  type: "single choice";
  answer_list: Pick<SurveyQuestionAnswer, "answer" | "next_qid">[];
}
type AddSurveyQuestionType = (
  Params: Params & (TextQuestionParams | SingleChoiceParams),
  callback: (error: AxiosError | null, resp: Resp | null) => any
) => any;
const AddSurveyQuestion: AddSurveyQuestionType = (Params, callback) => {
  AxiosInstance.post(`/survey-questions/${Params.survey_id}`, { ...Params })
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { AddSurveyQuestion };
