import { AxiosError } from "axios";
import humanize from "humanize-graphql-response";
import { IProviderCode } from "models/provider-code";
import { AxiosInstance } from "configs/axios";

interface Resp {
  totalCount: number;
  provider_codes: IProviderCode[];
}
interface Params {
  page: number;
  pageSize: number;
  id?: string;
  provider_id?: string;
  code_exact?: string;
  code_include?: string;
  comment?: string;
}
type GetProvidersCodeType = (
  Params: Params,
  callback: (error: AxiosError | null, resp: Resp | null) => any
) => any;
export const GetProvidersCode: GetProvidersCodeType = (Params, callback) => {
  AxiosInstance.get("/provider-codes", { params: Params })
    .then((resp) => {
      callback(null, {
        totalCount: resp.data.data.all_provider_codes.total_count,
        provider_codes: humanize(resp.data.data.all_provider_codes).nodes,
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};
