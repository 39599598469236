import CustomSurveysTable from "components/custom-compnents/custom-surveys-table/custom-surveys-table";
import AddUpdateSurvey from "components/standalone-components/add-update-survey/add-update-survey";
import * as React from "react";

import { Params, Schema } from "./schema";
import { useLogic } from "./survey-table.logic";
import RestartSurvey from "components/standalone-components/restart-survey/restart-survey";

export interface SurveyTableProps {
  ActionsToExclude?: Params["ActionsToExclude"];
}

const SurveyTable: React.FC<SurveyTableProps> = ({ ActionsToExclude }) => {
  const { tableProps, addSurveyDialogProps, restartSurveysDialogProps } = useLogic();

  return (
    <>
      <AddUpdateSurvey {...addSurveyDialogProps} />
      <RestartSurvey {...restartSurveysDialogProps} />
      <CustomSurveysTable {...tableProps} />
    </>
  );
};

export default SurveyTable;
