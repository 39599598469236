import { Box, Typography } from "@mui/material";
import { Alert } from "@mui/material";
import React, { useState } from "react";
import { IProvider } from "models/provider";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import { IoWarningSharp } from "react-icons/io5";
import { CreateEwalletUser } from "api/ewallet-users/create-ewallet-user";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";

export interface SetupAgriWalletModalProps {
  open: boolean;
  Provider?: IProvider;
  onCancel: () => any;
  onCreate: () => any;
}

const SetupAgriWalletModal: React.SFC<SetupAgriWalletModalProps> = ({
  open,
  Provider,
  onCancel,
  onCreate
}) => {
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [Errors, setErrors] = useState("");
  const HandleConfirm = () => {
    setErrors("");
    setIsSubmitting(true);
    if (Provider){
      CreateEwalletUser({
        id: Provider.id,
        type: 'organization',
        phone: Provider.phone
      })
      .then(result => {
        setIsSubmitting(false);
        onCreate();
      })
      .catch(err => {
        setIsSubmitting(false);
        setErrors(err.response.data.message)
      })
    } 
      
  };
  return (
    <CustomDialogV2
      open={open}
      header={
        <Typography variant="h6" display="inline-flex">
          <IoWarningSharp size={22} style={{ marginRight: 5 }} />
          Setup agriWALLET
        </Typography>
      }
      footer={
        <Box display="flex" justifyContent="flex-end">
          <CancelSuccessButtonsGroup
            RootGridProps={{ width: 300 }}
            CancelButtonProps={{
              label: "Cancel",
              onClick: () => {
                onCancel();
                setErrors("");
              },
            }}
            SuccessButtonProps={{
              disabled: IsSubmitting,
              onClick: HandleConfirm,
              label: "Confirm",
            }}
          />
        </Box>
      }
    >
      <Typography variant="subtitle1">
        Are You Sure You want to setup the agriWALLET?
      </Typography>
      {Errors && (
        <Box mt={2}>
          <Alert severity="error">{
            Errors.split('\n').map(item => <p style={{margin: 0}}>{item}</p>)
            }</Alert>
        </Box>
      )}
      {/* </CustomDialog> */}
    </CustomDialogV2>
  );
};

export default SetupAgriWalletModal;
