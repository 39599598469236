import { Box } from "@mui/system";
import ServicesTable from "components/data-tables/services-table/services-Table";

interface ServicesScreenProps {}

const ServicesScreen: React.FunctionComponent<ServicesScreenProps> = () => {
  return (
    <Box>
      <ServicesTable />
    </Box>
  );
};

export default ServicesScreen;
