import { getUSSDInteractionsCountKpi } from "api/kpis/ussd-interactions";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";

export interface IParams {
  region_id_list?: string[];
  county_id_list?: string[];
  constituency_id_list?: string[];
  ward_id_list?: string[];
  provider_id?: string;
}
export const useLogic = (params: IParams) => {
  const { constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id } = params;

  const [week, setweek] = useState<number | undefined>();

  const [month, setmonth] = useState<number | undefined>();

  const [year, setyear] = useState<number | undefined>();

  const user = useAppSelector((root) => root.appReducer.user);

  const last_week_date = new Date();

  last_week_date.setDate(last_week_date.getDate() - 7);

  const last_month_date = new Date();

  last_month_date.setDate(last_month_date.getDate() - 30);

  const last_year_date = new Date();

  last_year_date.setDate(last_year_date.getDate() - 365);

  useEffect(() => {
    getUSSDInteractionsCountKpi({ before_date: last_week_date.toString(), constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id: provider_id ? provider_id : user?.provider_id }).then((resp) => {
      setweek(resp.data.data.cube[0].ussd_log.unique_ussd_count);
    });

    getUSSDInteractionsCountKpi({ before_date: last_month_date.toString(), constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id: provider_id ? provider_id : user?.provider_id }).then((resp) => {
      setmonth(resp.data.data.cube[0].ussd_log.unique_ussd_count);
    });

    getUSSDInteractionsCountKpi({ before_date: last_year_date.toString(), constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id: provider_id ? provider_id : user?.provider_id }).then((resp) => {
      setyear(resp.data.data.cube[0].ussd_log.unique_ussd_count);
    });
  }, [constituency_id_list?.length, county_id_list?.length, region_id_list?.length, ward_id_list?.length, provider_id]);

  return { week, month, year };
};
