import { getSuperOrganizationHierarchy } from "api/super-organization-relation/get-super-organization-hierarchy";
import { Resp } from "api/super-organization-relation/get-super-organization-hierarchy";
import React from "react";
import { MarkerType, ReactFlowProps } from "react-flow-renderer";
import { useAppSelector } from "store/hooks";

export const useLogic = () => {
  const user = useAppSelector((root) => root.appReducer.user);

  const [data, setdata] = React.useState<Resp["nodeDataArray"]>([]);

  const nodes: ReactFlowProps["nodes"] = data
    .map((item) => ({
      id: item.id,
      data: { label: item.name },
      position: { x: 0, y: 0 },
    }))
    .concat({
      id: user?.provider_id!,
      data: { label: user?.providerName! },
      position: { x: 0, y: 0 },
    });

  const edges: ReactFlowProps["edges"] = data.map((item) => ({
    id: `${item.name}-${item.parent}`,
    source: item.id,
    target: item.parent,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  }));

  // function flatToHierarchy(flat: Resp["nodeDataArray"]) {
  //   var roots: INode[] = []; // things without parent

  //   // make them accessible by guid on this map
  //   var all: any = {};

  //   flat.forEach(function (item) {
  //     all[item.id] = item;
  //   });

  //   // connect childrens to its parent, and split roots apart
  //   Object.keys(all).forEach(function (guid) {
  //     var item = all[guid];
  //     if (item.parent === null) {
  //       roots.push(item);
  //     } else if (item.parent in all) {
  //       var p = all[item.parent];
  //       if (!("children" in p)) {
  //         p.children = [];
  //       }
  //       p.children.push(item);
  //     }
  //   });

  //   // done!
  //   return roots;
  // }

  React.useEffect(() => {
    getSuperOrganizationHierarchy({ id: user?.provider_id! }).then((resp) => {
      // setdata(
      //   flatToHierarchy([
      //     ...resp.data.nodeDataArray,
      //     ...[
      //       {
      //         id: user?.provider_id!,
      //         name: user?.provider!,
      //         parent: null as any,
      //       },
      //     ],
      //   ])[0]
      // );

      setdata(resp.data.nodeDataArray);
    });
  }, [user?.provider_id]);

  return { nodes, edges };
};
