import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Resp { }
interface Params {
  value: string[];
  farmer_id: string;
  label_key_id: string;
}

type UpdateFarmerLabelsType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const UpdateFarmerLabels: UpdateFarmerLabelsType = (Params, callback) => {
  AxiosInstance.patch(`/farmer-labels/${Params.farmer_id}/${Params.label_key_id}`, { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { UpdateFarmerLabels };
