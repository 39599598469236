import * as yup from "yup";

export const validation = () =>
  yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    phone: yup
      .string()
      .required(),
    birth_year: yup
      .number()
      .nullable()
      .min(1900)
      .max(new Date().getUTCFullYear()),
  });
