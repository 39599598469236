import { makeStyles } from "@mui/styles";
import BubbleBackground from "assets/images/bubble-wallpaper.png";

export const useStyles = makeStyles({
  containerRoot: {
    backgroundImage: `url('${BubbleBackground}')`,
    backgroundSize: "conver",
  },
  welcomeTextRoot: {
    fontFamily: "Roboto",
    fontSize: "50px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "0.6",
    letterSpacing: "normal",
    textAlign: "center",
  },
  LoginTextRoot: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#a0a8a8",
    marginTop: 16,
  },
  footerRoot: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#a0a8a8",
  },
  homeButtonRoot: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#5d6d6e",
  },
  homeButtonArrowRoot: {
    marginLeft: 5,
  },
});
