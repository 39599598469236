import { Button, ButtonProps } from "@mui/material";
import { useStyles } from "./shiny-button.style";

export interface ShinyButtonProps extends ButtonProps {
  isShiny?: boolean;
  "data-cy"?: string;
}

const ShinyButton: React.FunctionComponent<ShinyButtonProps> = (props) => {
  const { isShiny } = props;

  const classes = useStyles();

  return <Button {...props} className={`${isShiny && classes.shinyYellow}`} />;
};

export default ShinyButton;

ShinyButton.defaultProps = {
  color: "secondary",
};
