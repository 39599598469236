import { Button, ButtonProps } from "@mui/material";
import { IoFilterSharp } from "react-icons/io5";

interface FiltersButtonProps extends ButtonProps {}

const FiltersButton: React.FunctionComponent<FiltersButtonProps> = (props) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<IoFilterSharp />}
      {...props}
    />
  );
};

export default FiltersButton;

FiltersButton.defaultProps = {
  children: "Filters",
};
