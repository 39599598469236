import { AxiosInstance } from "../../../configs/axios";

interface IGetAuthToken {
  email: string;
  password: string;
}
interface IResponse {
  access_token: string;
  token_type: string;
}
export const GetAuthToken = async (data: IGetAuthToken) =>
  AxiosInstance.post<IResponse>("auth/login", data);
