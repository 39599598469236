// import { Box, Paper } from "@mui/material";
// import MaterialTable from "material-table";
import React from "react";
// import { IQualityCheck } from "models/quality-check";
import { IUser } from "models/user";
import AddUpdateQualityCheck from "components/standalone-components/add-update-quality-check/add-update-quality-check";
import { Params } from "./schema";
import CustomQualityCheckTable from "components/custom-compnents/custom-quality-check-table/custom-quality-check-table";
import { useLogic } from "./quality-check-table.logic";
interface QualityCheckTableProps {
  aggregation_activity_id?: string;
  User_id: IUser["id"];
  ActionsToExclude?: Params["ActionsToExclude"];
}
// const TableRef = React.createRef<any>();
const QualityCheckTable: React.FunctionComponent<QualityCheckTableProps> = ({
  aggregation_activity_id,
  User_id,
  ActionsToExclude,
}) => {
  // const [OpenAddQualityCheck, SetOpenAddQualityCheck] = React.useState(false);
  // const [OpenUpdateQualityCheck, SetOpenUpdateQualityCheck] =
  //   React.useState(false);
  // const [QualityCheckToBeUpdated, SetQualityCheckToBeUpdated] =
  //   React.useState<undefined | IQualityCheck>(undefined);
  // const HandleCancelAddQualityCheck = () => SetOpenAddQualityCheck(false);
  // const HandleAdded = () => {
  //   SetOpenAddQualityCheck(false);
  //   TableRef.current?.onQueryChange();
  // };
  // const HandleCancelUpdateQualityCheck = () => SetOpenUpdateQualityCheck(false);
  // const HandleUpdatedQualityCheck = () => {
  //   SetOpenUpdateQualityCheck(false);
  //   TableRef.current?.onQueryChange();
  // };
  // const HandleAddButtonClicked = () => SetOpenAddQualityCheck(true);
  // const HandleUpdateButtonClicked = (Data: Fields) => {
  //   SetQualityCheckToBeUpdated(Data);
  //   SetOpenUpdateQualityCheck(true);
  // };

  const {
    tableProps,
    QualityCheckToBeUpdated,
    handleUpdatedQualityCheck,
    handleCancelUpdateQualityCheck,
    handleAddQualityCheck,
    OpenAddQualityCheck,
    HandleCancelAddQualityCheck,
  } = useLogic({
    aggregation_activity_id,
  });
  return (
    <>
      <CustomQualityCheckTable {...tableProps} />
      {QualityCheckToBeUpdated && (
        <AddUpdateQualityCheck
          variant="update"
          open={Boolean(QualityCheckToBeUpdated)}
          onCancel={handleCancelUpdateQualityCheck}
          onUpdated={handleUpdatedQualityCheck}
          quality_check_id={QualityCheckToBeUpdated?.id}
        />
      )}
      {aggregation_activity_id && (
        <AddUpdateQualityCheck
          variant="add"
          onAdded={handleAddQualityCheck}
          open={OpenAddQualityCheck}
          aggregation_activity_id={aggregation_activity_id}
          onCancel={HandleCancelAddQualityCheck}
        />
      )}

      {/* <MaterialTable
        tableRef={TableRef}
        {...Schema({
          ActionsToExclude,
          aggregation_activity_id,
          OnAddButtonClicked: HandleAddButtonClicked,
          OnUpdateButtonClicked: HandleUpdateButtonClicked,
          User_id: User_id,
        })}
      ></MaterialTable> */}
    </>
  );
};

export default QualityCheckTable;
