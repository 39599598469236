import { Box, MenuItem, Select, SelectProps, TextField, TextFieldProps, useMediaQuery, useTheme } from "@mui/material";
import ProvidersInput, { ProvidersInputProps } from "components/input-components/providers-input/providers-input";
import { FunctionComponent } from "react";
import Scrollbars from "react-custom-scrollbars";

export interface UsersTableFormProps {
  nameFilterProps?: TextFieldProps;
  emailFilterProps?: TextFieldProps;
  phoneFilterProps?: TextFieldProps;
  rolesFilterProps?: SelectProps;
  providerFilterProps?: ProvidersInputProps;
}

const UsersTableForm: FunctionComponent<UsersTableFormProps> = (props) => {
  const { emailFilterProps, nameFilterProps, phoneFilterProps, providerFilterProps, rolesFilterProps } = props;

  const filtersData = [
    { label: "Organization", props: providerFilterProps, type: "provider" },
    { label: "Name", props: nameFilterProps, type: "text" },
    { label: "Email", props: emailFilterProps, type: "text" },
    { label: "Phone", props: phoneFilterProps, type: "text" },

    {
      label: "Roles",
      props: rolesFilterProps,
      type: "select-menu",
      items: [
        { label: "Roles", value: "" },
        { label: "Admin", value: "admin" },
        { label: "Org Admin", value: "org_admin" },
        { label: "Manager", value: "manager" },
        { label: "User", value: "user" },
      ],
    },
  ];

  const theme = useTheme();

  const medScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Scrollbars autoHeight autoHide autoHeightMax={"none"}>
      <Box display={"flex"} gap={2} p={0.5} flexDirection={medScreen ? "column" : "row"}>
        {filtersData.map((item) => (
          <Box minWidth={medScreen ? "100%" : 250}>
            {item.type === "text" ? (
              <TextField placeholder={item.label} fullWidth {...(item.props as TextFieldProps)} />
            ) : item.type === "select-menu" ? (
              <Select displayEmpty fullWidth variant="outlined" {...(item.props as SelectProps)}>
                {item?.items?.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            ) : item.type === "provider" ? (
              <ProvidersInput fullwidth {...(item.props as ProvidersInputProps)} />
            ) : null}
          </Box>
        ))}
      </Box>
    </Scrollbars>
  );
};

export default UsersTableForm;
