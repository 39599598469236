import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

export interface FarmerUpdatePhoneAndNameForm {
  // farmer_name: string;
  firstName: string;
  lastName: string;
  farmer_phone: string;
  birthYear?: string;
  gender?: string;
}

const UpdateFarmerNameAndphoneApi = (
  Id: string,
  {
    firstName,
    lastName,
    farmer_phone,
    birthYear,
    gender,
  }: FarmerUpdatePhoneAndNameForm,
  callback?: (error: AxiosError | null, data: any) => void
) => {
  AxiosInstance.patch("/farmers/" + Id, {
    first_name: firstName,
    last_name: lastName,
    phone: farmer_phone,
    birth_year: birthYear ? birthYear : null,
    gender,
  })
    .then((resp) => {
      callback && callback(null, resp);
    })
    .catch((error) => callback && callback(error, null));
};

export { UpdateFarmerNameAndphoneApi };
