import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles({
  root: { overflowX: "visible" },
  paperRoot: {
    padding: "24px 30px 29px 31px",
    borderRadius: "10px",
    border: "solid 1px #dbdbdb",
    maxWidth: "100%",
    overflowY: "unset",
    maxHeight: "unset",
  },
  headerRoot: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.39,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    marginBottom: 8,
  },
  ButtonAreaRoot: {},
});
