import { Grid } from "@mui/material";
import React from "react";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import CreateButton, {
  CreateButtonProps,
} from "../create-button/create-button";
import CustomSearchTextField, {
  CustomSearchTextFieldProps,
} from "../custom-search-text-field/custom-search-text-field";

interface FarmerLabelsPageHeaderProps {
  labelCountProps?: CountLabelProps;
  searchProps?: CustomSearchTextFieldProps;
  createButtonProps?: CreateButtonProps;
  isShowCreateButton?: boolean;
}

const FarmerLabelsPageHeader: React.FunctionComponent<FarmerLabelsPageHeaderProps> =
  (props) => {
    const {
      labelCountProps,
      searchProps,
      createButtonProps,
      isShowCreateButton,
    } = props;

    return (
      <Grid alignItems="center" container spacing={2}>
        <Grid item lg={6} xs={12}>
          <CountLabel label="Farmer Label" {...labelCountProps} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={7} xs={12}>
              <CustomSearchTextField fullWidth {...searchProps} />
            </Grid>
            {isShowCreateButton && (
              <Grid item lg={5} xs={12}>
                <CreateButton fullWidth {...createButtonProps}>
                  Add New Label
                </CreateButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

export default FarmerLabelsPageHeader;
