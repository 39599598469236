import { AxiosError, AxiosResponse } from "axios";
import { AxiosInstance } from "configs/axios";

const Detach_Device = (
  device_id: string,
  callback: (error: AxiosError | null, Data: AxiosResponse | null) => any
) => {
  AxiosInstance.delete(`/devices/${device_id}`, { params: { device_id } })
    .then((data) => callback(null, data))
    .catch((error) => callback(error, null));
};

export { Detach_Device };
