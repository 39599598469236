import React from "react";
import {
  TextField,
  Select,
  MenuItem,
  SelectProps,
  InputAdornment,
  Typography,
  Box,
} from "@mui/material";
import { debounce } from "lodash";
import { IoChevronUpOutline } from "react-icons/io5";

export interface option {
  title: string;
  value: string;
}
export interface SelectMenuCommonProps {
  padding?: number;
  value: undefined | option["value"];
  options: option[];
  Placeholder?: string;
  OnChange: SelectProps["onChange"];
  icon?: React.ReactElement;
  helperText?: string;
  error?: boolean;
  label?: string;
  disabled?: boolean;
}

export interface SearchableProps {
  Searchable: true;
  onSearchInputChange: (value: string) => void;
  SearchLabel: string;
  SearchPlaceholder: string;
  LoadingSearch?: Boolean;
}
export interface NonSearchableProps {
  Searchable: false;
}

export type SelectMenuProps = SelectMenuCommonProps &
  (SearchableProps | NonSearchableProps);

const SelectMenu: React.FunctionComponent<SelectMenuProps> = ({
  padding,
  value,
  options,
  Placeholder,
  OnChange,
  icon,
  helperText,
  error,
  label,
  disabled,
  ...props
}) => {
  const [MenuAnchorEl, SetMenuAnchorEl] = React.useState<any>(undefined);
  const SelectProps: SelectProps = {
    disabled,
    label,
    error,
    onOpen: (e) => SetMenuAnchorEl(e.currentTarget),
    startAdornment: icon && (
      <InputAdornment position="start">{icon}</InputAdornment>
    ),
    onChange: OnChange,
    defaultValue: "none",
    value: value,
    variant: "outlined",
    fullWidth: true,
    SelectDisplayProps: { style: { padding: padding ? padding : 10 } },
    MenuProps: {
      onClose: () => SetMenuAnchorEl(undefined),
      open: Boolean(MenuAnchorEl),
      anchorEl: MenuAnchorEl,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    },
  };
  const HandleItemClick = () => SetMenuAnchorEl(undefined);
  if (props.Searchable) {
    var debouncedChangeInput = debounce(props.onSearchInputChange, 500);
  }
  return (
    <>
      <Select {...SelectProps} IconComponent={IoChevronUpOutline}>
        {props.Searchable && (
          <Box p={2} mt={0}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label={props.SearchLabel}
              placeholder={props.SearchPlaceholder}
              autoFocus
              onChange={(e) => debouncedChangeInput(e.currentTarget.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </Box>
        )}

        <MenuItem value="none" disabled>
          {Placeholder}
        </MenuItem>
        {props.Searchable && props.LoadingSearch ? (
          <MenuItem disabled>Loading ...</MenuItem>
        ) : (
          options.map((item, index) => (
            <MenuItem
              key={index}
              value={item.value}
              onClick={() => HandleItemClick()}
            >
              {item.title}
            </MenuItem>
          ))
        )}
      </Select>
      {helperText && (
        <Box mx={1.5}>
          <Typography
            color="error"
            variant="caption"
            style={{ fontWeight: 100 }}
          >
            {helperText}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SelectMenu;
