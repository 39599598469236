import { Box, Button, Grid, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import { GetSenderIds } from "api/senders/get-senders-v2";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import { ISenderId } from "models/sender-id";
import React, { FunctionComponent } from "react";

interface SenderDetailComponentProps {
  onHide?: () => any;
  id: string;
  open: boolean;
}

const SenderDetailComponent: FunctionComponent<SenderDetailComponentProps> = (props) => {
  const { id, open, onHide } = props;

  const [data, setdata] = React.useState<ISenderId | undefined>();
  const [loading, setloading] = React.useState(true);

  React.useEffect(() => {
    setloading(true);
    GetSenderIds({ page: 0, pageSize: 10, id: id || undefined }, (error, resp) => {
      if (error) console.log(error.message);

      if (resp) setdata(resp?.allSenders.nodes[0]);
      setloading(false);
    });
  }, [id]);

  const schema = [
    { label: "ID", value: data?.id },
    { label: "Type", value: data?.senderType },
    { label: "Status", value: data?.status ? "Active" : "Inactvie" },
    { label: "AT Sender ID", value: data?.atSenderId },
    { label: "Phone Number", value: data?.phoneNumber },
    { label: "Organization ID", value: data?.providerId },
    { label: "User ID", value: data?.userId },
    // { label: "AT Authetication Key", value: data?.atAuthKey },
    { label: "AT Username", value: data?.atUsername },
    // { label: "Base Url", value: data?.baseUrl },
    // { label: "infobip Authetication Key", value: data?.infobipAuthKey },
  ];

  return (
    <CustomDialogV2
      header={"Sender Details"}
      open={open}
      footer={
        !loading ? (
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button onClick={onHide} fullWidth>
              Hide
            </Button>
          </Box>
        ) : (
          <></>
        )
      }
    >
      <Grid container spacing={2} sx={{ maxWidth: 400 }}>
        {loading ? (
          <Grid item>
            <Typography>Loading ...</Typography>
          </Grid>
        ) : (
          schema
            .filter((item) => item.value)
            .map((item) => (
              <Grid item xs={12} key={item.label}>
                <Paper sx={{ overflow: "hidden" }}>
                  <ListItem dense component={"div"}>
                    <ListItemText primary={item.label} secondary={item.value} secondaryTypographyProps={{ style: { whiteSpace: "pre-wrap", wordBreak: "break-all" } }} />
                  </ListItem>
                </Paper>
              </Grid>
            ))
        )}
      </Grid>
    </CustomDialogV2>
  );
};

export default SenderDetailComponent;
