import {
  Autocomplete,
  AutocompleteChangeReason,
  Button,
  ButtonProps,
  Chip,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useStyles } from "./farmer-labels-filter.style";
import React, { useState } from "react";
import LabelsInput from "components/input-components/labels-input/labels-input";
import LabelsKeyInput from "components/input-components/labels-key-input/labels-key-input";

export interface Key {
  label: string;
  value: string;
}

export interface Value {
  label: string;
  value: string;
}

export interface KeyValues {
  key: { id: string; label: string };
  values: { id: string; label: string }[];
}
interface FarmerLabelsFilterProps {
  addButtonProps?: ButtonProps & { "data-cy"?: string };
  clearButtonProps?: ButtonProps;
  value?: KeyValues[];
  onItemDeleteClick?: (item: KeyValues, index: number) => any;
  keyOptions: Key[];
  valueOptions: Value[];
  keyValue?: Key | null;
  valueValue: Value[];
  onKeyInputTextChange?: (value: string) => any;
  onValueInputTextChange?: (value: string) => any;
  submitButtonProps?: ButtonProps & { "data-cy"?: string };
  keyInputDisabled?: boolean;
  valueInputDisabled?: boolean;
  keyNoOptionsLabel?: string;
  valueNoOptionsLabel?: string;
  cancelButtonProps?: ButtonProps;
  isEmptyWrapper?: boolean;
  isNoPadding?: boolean;
  onKeyChange?: (value: Key, reason: AutocompleteChangeReason) => any;
  onValueChange?: (value: Value[], reason: AutocompleteChangeReason) => any;
  keysPage?: number;
  keysSetPage?: (page: number) => any;
  valuesPage?: number;
  valuesSetPage?: (page: number) => any;
  handleSetKeySearch?: (search: string) => any;
  handleSetValueSearch?: (search: string) => any;
}

const FarmerLabelsFilter: React.FunctionComponent<FarmerLabelsFilterProps> = (
  props
) => {
  const classes = useStyles();
  const [openLabelKeys, setOpenLabelKeys] = useState(false);
  const [openLabelValues, setOpenLabelValues] = useState(false);

  const {
    addButtonProps,
    clearButtonProps,
    value,
    onItemDeleteClick,
    keyOptions,
    valueOptions,
    valueValue,
    keyValue,
    onKeyInputTextChange,
    onValueInputTextChange,
    submitButtonProps,
    valueInputDisabled,
    keyInputDisabled,
    keyNoOptionsLabel,
    valueNoOptionsLabel,
    cancelButtonProps,
    isEmptyWrapper,
    isNoPadding,
    onKeyChange,
    onValueChange,
    keysPage,
    keysSetPage,
    valuesPage,
    valuesSetPage,
    handleSetKeySearch,
    handleSetValueSearch,
  } = props;

  const InputData = [
    {
      name: "farmer_key",
      placeholder: "Farmer Key",
      options: keyOptions,
      value: keyValue,
      onInputTextChange: onKeyInputTextChange,
      disabled: keyInputDisabled,
      noOptionsLabel: keyNoOptionsLabel,
      "data-cy": "farmers-filter-labels-farmer-key-input",
      extraProps: {
        open: openLabelKeys,
        onFocus: () => {
          setOpenLabelKeys(true);
        },
        onBlur: () => {
          setOpenLabelKeys(false);
        },
        onChange: (e: any, value: any, reason: any) => {
          if (!value?.label.includes("Load more..."))
            onKeyChange!(value, reason);
        },
        filterOptions: (options: any, state: any) => {
          let newOptions = [...options] as any;
          return newOptions;
        },
        renderOption: (props: any, item: any, state: any) => {
          let lastItem = keyOptions.slice().pop();

          let isLastOption = lastItem && lastItem.value === item.value;

          return !isLastOption ? (
            <li {...props} key={item.key}>
              {item.label}
            </li>
          ) : (
            <>
              <li {...props} key={item.key}>
                {item.label}
              </li>
              <li onClick={(e) => e.stopPropagation()}>
                <Button
                  style={{ height: "40px" }}
                  size="small"
                  className={classes.loadMoreBtn}
                  fullWidth
                  variant="text"
                  onClick={() => {
                    console.log("clicked");
                    handleSetKeySearch!("");
                    keysSetPage!(keysPage! + 1);
                  }}
                >
                  Load more
                </Button>
              </li>
            </>
          );
        },
      },
    },
    {
      name: "farmer_label",
      placeholder: "Farmer Label",
      Multiple: true,
      options: valueOptions,
      value: valueValue,
      onInputTextChange: onValueInputTextChange,
      disabled: valueInputDisabled,
      noOptionsLabel: valueNoOptionsLabel,
      "data-cy": "farmers-filter-labels-farmer-label-input",
      extraProps: {
        open: openLabelValues,
        onFocus: () => {
          setOpenLabelValues(true);
        },
        onBlur: () => {
          setOpenLabelValues(false);
        },
        onChange: (
          e: any,
          value: any | Value[],
          reason: AutocompleteChangeReason
        ) => {
          onValueChange!(value, reason);
        },
        filterOptions: (options: any, state: any) => {
          let newOptions = [...options] as any;

          return newOptions;
        },
        renderOption: (props: any, item: any) => {
          let lastItem = valueOptions.slice().pop();

          let isLastOption = lastItem && lastItem.value === item.value;

          return !isLastOption ? (
            <li {...props} key={item.key}>
              {item.label}
            </li>
          ) : (
            <>
              <li {...props} key={item.key}>
                {item.label}
              </li>
              <li onClick={(e) => e.stopPropagation()}>
                <Button
                  style={{ height: "40px" }}
                  size="small"
                  className={classes.loadMoreBtn}
                  fullWidth
                  variant="text"
                  onClick={
                    valueValue
                      ? () => {
                          handleSetValueSearch!("");
                          valuesSetPage!(valuesPage! + 1);
                        }
                      : () => {}
                  }
                >
                  Load more
                </Button>
              </li>
            </>
          );
        },
      } as any,
    },
  ];
  const ActionComponentsData = [
    { placeholder: "Add Entry", color: "primary", props: addButtonProps },
    { placeholder: "Clear Set", color: "inherit", props: clearButtonProps },
    {
      placeholder: "Submit",
      color: "success",
      props: submitButtonProps,
      gridProps: { xs: 12 },
    },
    {
      placeholder: "Cancel",
      color: "inherit",
      props: cancelButtonProps,
      gridProps: { xs: 12 },
      variant: "text",
    },
  ];

  return (
    <Box
      component={!isEmptyWrapper ? Paper : undefined}
      p={isNoPadding ? 0 : 2}
      display="inline-block"
      width={350}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {value?.map((item, index) => (
            <Chip
              key={item.key.id}
              sx={{ m: 0.5 }}
              variant="outlined"
              label={`${item.key.label} : ${item.values
                .map((item) => item.label)
                .join(", ")}`}
              onDelete={() => onItemDeleteClick?.(item, index)}
            />
          ))}
        </Grid>
        {InputData.map((inputItem) => (
          <Grid item xs={12} key={inputItem.placeholder}>
            {inputItem.name === "farmer_label" ? (
              <LabelsInput
                value={valueValue}
                key_id={keyValue?.value}
                onChange={(value, reason) => {
                  if (value) onValueChange?.(value, reason);
                }}
              />
            ) : inputItem.name === "farmer_key" ? (
              <LabelsKeyInput
                value={keyValue}
                onChange={(value, reason) => {
                  onKeyChange?.(value, reason);
                }}
              />
            ) : (
              <Autocomplete
                filterSelectedOptions
                noOptionsText={inputItem.noOptionsLabel}
                disabled={inputItem.disabled}
                disablePortal
                options={inputItem.options as any}
                multiple={inputItem.Multiple}
                value={inputItem.value as any}
                renderInput={(params) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      onChange={(e) => {
                        inputItem.onInputTextChange?.(e.currentTarget.value);
                      }}
                      {...params}
                      fullWidth
                      placeholder={inputItem.placeholder}
                    />
                  </div>
                )}
                data-cy={inputItem?.["data-cy"]}
                {...inputItem.extraProps}
              />
            )}
          </Grid>
        ))}
        {ActionComponentsData.map((item) => (
          <Grid item xs={6} {...item.gridProps} key={item.placeholder}>
            <Button
              variant={(item.variant ?? "contained") as any}
              fullWidth
              color={item.color as any}
              {...item.props}
            >
              {item.placeholder}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FarmerLabelsFilter;
