import AddUpdateAgentCode from "components/standalone-components/add-update-agent-code/add-update-agent-code";
import CustomAgentCodesTable from "components/custom-compnents/custom-agent-codes-table/custom-agent-codes-table";
import RemoveAgentCodeComponent from "components/standalone-components/remove-agent-code-component/remove-agent-code-component";
import { useLogic } from "./providers-codes-table.logic";

type props = {
  _token: string;
};

const ProviderCodeTable: any = ({ _token }: props) => {
  const { tableProps, deleteDialogProps, addDialogProps, updateDialogProps } = useLogic();
  return (
    <>
      {updateDialogProps && <AddUpdateAgentCode {...updateDialogProps} />}
      <AddUpdateAgentCode {...addDialogProps} />
      {deleteDialogProps && <RemoveAgentCodeComponent {...deleteDialogProps} />}
      <CustomAgentCodesTable {...tableProps} />
    </>
  );
};

export default ProviderCodeTable;
