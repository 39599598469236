import { Chip, Grid, TabProps } from "@mui/material";
import React from "react";
import { FarmPageStateProviderType, ProvideFarmPageStateTo } from "store/reducers/farm-page/farm-page-reducer";
import ManagmentActivities from "../managment-activities-sub-page/managment-activities-sub-page";
import WeatherAnalysisScreen from "../weather-analysis-sub-page/weather-analysis";
import CustomTabsComponent from "components/custom-compnents/custom-tabs-component/custom-tabs-component";
import FarmOverviewSubScreen, { FarmOverviewSubScreenProps } from "components/pages/farm-overview-sub-screen/farm-overview-sub-screen";

import FarmMonitoringSubPage from "../farm-monitoring-sub-page/farm-monitoring-sub-page";

import { IoCloudy, IoLeaf, IoSettings, IoSpeedometer, IoTv } from "react-icons/io5";
import { useLogic } from "./farm-page.logic";
import CropSimulationSubPage from "../crop-simulation-sub-page/crop-simulation-sub-page";

export interface FarmProps extends FarmPageStateProviderType {}

const Farm: React.FC<FarmProps> = ({ FarmPageState, SetFarmPageState }) => {
  const { SelectedTab, CurrentFarm } = FarmPageState;

  const { User, handleTabChange, farm_id } = useLogic({
    SetFarmPageState,
    CurrentFarm,
  });

  const farmOverviewSubScreenProps: FarmOverviewSubScreenProps = {
    farmName: CurrentFarm?.name,
    location: {
      latitude: CurrentFarm?.lat ?? 0,
      longitude: CurrentFarm?.lng ?? 0,
    },
    farmInfoListProps: {
      crop: CurrentFarm?.LastFarmActivity?.cropByCropId?.name,
      location: CurrentFarm
        ? {
            lat: CurrentFarm?.lat?.toFixed(4)?.toString(),
            lng: CurrentFarm?.lng?.toFixed(4)?.toString(),
          }
        : undefined,
      size: CurrentFarm?.size,
      ownedByFarmer: Boolean(CurrentFarm?.ownedByFarmer),
    },
  };

  const LabelArray: TabProps[] = [
    { label: "Farm Overview", value: 0, icon: <IoLeaf size={25} /> },
    // { label: "Monitoring", value: 1, icon: <IoTv size={25} /> },
    { label: "Weather Analysis", value: 2, icon: <IoCloudy size={25} /> },
    {
      label: (
        <>
          Crop Simulation <Chip label="BETA" variant="filled" color="error" sx={{ bgcolor: "error.main", ml: 1 }} size="small" />
        </>
      ),
      value: 3,
      icon: <IoSettings size={25} />,
      disabled: false,
    },
    {
      label: "Management Activities",
      value: 4,
      icon: <IoSpeedometer size={30} />,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomTabsComponent
          TabData={LabelArray}
          TabsProps={{
            value: SelectedTab,
            onChange: handleTabChange,
            allowScrollButtonsMobile: true,
            scrollButtons: "auto",
            variant: "scrollable",
          }}
        />
      </Grid>
      <Grid item md={12} sm={12} sx={{ maxWidth: "100%" }}>
        {SelectedTab === 0 && <FarmOverviewSubScreen {...farmOverviewSubScreenProps} />}

        <WeatherAnalysisScreen
          show={SelectedTab === 2}
          location={{
            latitude: CurrentFarm?.lat,
            longitude: CurrentFarm?.lng,
          }}
        />
        {/* {SelectedTab === 1 && CurrentFarm && <FarmMonitoringSubPage farm_id={CurrentFarm?.id} location={[{ lat: CurrentFarm?.lat, lng: CurrentFarm?.lng }]} />} */}
        {SelectedTab === 3 && CurrentFarm?.id && <CropSimulationSubPage farm_id={CurrentFarm?.id} />}
        {/* <MonitoringScreen
          location={[
            {
              lat: FarmPageState.CurrentFarm?.lat,
              lng: FarmPageState.CurrentFarm?.lng,
            },
          ]}
          farm_id={farm_id!}
        /> */}
        <ManagmentActivities show={SelectedTab === 4} FarmId={farm_id!} User={User!} />
      </Grid>
    </Grid>
  );
};

export default ProvideFarmPageStateTo(Farm, true);
