import { Grid } from "@mui/material";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import CreateButton, {
  CreateButtonProps,
} from "../create-button/create-button";
import CustomSearchTextField from "../custom-search-text-field/custom-search-text-field";
import { CustomTextFieldProps } from "../custom-text-field/custom-text-field";

export interface CustomSearchAddCountLabelActionBarProps {
  countLabelProps?: CountLabelProps;
  searchFieldProps?: CustomTextFieldProps;
  createButton?: CreateButtonProps;
}

const CustomSearchAddCountLabelActionBar: React.FunctionComponent<CustomSearchAddCountLabelActionBarProps> =
  (props) => {
    const { countLabelProps, createButton, searchFieldProps } = props;
    return (
      <Grid container spacing={2}>
        <Grid item xl={7}>
          <CountLabel {...countLabelProps} />
        </Grid>
        <Grid item xl={3}>
          <CustomSearchTextField fullWidth {...searchFieldProps} />
        </Grid>
        <Grid item xl={2}>
          <CreateButton fullWidth {...createButton} />
        </Grid>
      </Grid>
    );
  };

export default CustomSearchAddCountLabelActionBar;
