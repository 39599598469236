import { AddMessage } from "api/message/add-message";
import { GetProvider } from "api/provider/get-provider";
// import { GetSender } from "api/senders/get-senders";
import { getSenderByProviderId } from "api/senders/get-sender-by-provider-id";
import { AddWhatsappMessage } from "api/whatsapp-messages/add-whatsapp-message";
import { FarmersTableProps } from "components/data-tables/farmers-table/farmers-table";
import { useFormik } from "formik";
import { differenceBy, uniq } from "lodash";
import { IProvider } from "models/provider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { validationSchema } from "./send.message.screen.validation";
// import { GetSenderIds } from "api/senders/get-senders-v2";
import { ISenderId } from "models/sender-id";
import { GetSenderIds } from "api/senders/get-senders-v2";

export enum MessageType {
  Whatsapp = "whatsapp_template",
  SMS = "sms",
}

export const useLogic = () => {
  const [loadingwhatsappSenders, setloadingwhatsappSenders] = useState(true);

  const [loadingsmsSenders, setloadingsmsSenders] = useState(true);

  const [whatsappSenders, setwhatsappSenders] = useState<ISenderId[]>([]);

  const [smsSenders, setsmsSenders] = useState<ISenderId[]>([]);

  const [templateId, settemplateId] = useState("");

  const [messageType, setmessageType] = useState<MessageType>(MessageType.SMS);

  const user = useAppSelector((root) => root.appReducer.user);

  const UserMenuroles = user?.menuroles;

  const UserProviderId = user?.provider_id;

  const [isSending, setIsSending] = React.useState(false);

  const [UserProvider, SetUserProvider] =
    React.useState<undefined | IProvider>(undefined);

  // const agriBORA_id = (window as any).env.REACT_APP_AGRIBORA_PROVIDER_ID;
  
  const [selectedTab, setSelectedTab] = React.useState<undefined | number>(undefined);

  const [DateMenuAnchorEl, setDateMenuAnchorEl] = React.useState(undefined);

  const combinedSenders = [
    ...(messageType === MessageType.SMS
      ? [
          ...smsSenders.map((item) => ({
            title: item.atSenderId,
            value: item.id,
          })),
        ]
      : whatsappSenders.map((item) => ({
          title: item.phoneNumber,
          value: item.id,
        }))),
  ];

  // React.useEffect(() => {
  //   GetSenderIds({ page: 0, pageSize: 10, provider_id: user?.provider_id }, (error, resp) => {
  //     if (error) {
  //       console.log(error.message);
  //     } else if (resp) {
  //       setSendAsOptions((currentSenders) => [...currentSenders, ...resp.allSenders.nodes.map((item) => ({ title: item.phoneNumber, value: item.id }))]);
  //     }
  //   });
  // }, []);

  const [showSuccessMessage, SetshowSuccessMessage] =
    React.useState<boolean>(false);

  const formikForm = useFormik({
    initialValues: {
      message: "",
      receivers: [],
      sender_id: undefined,
      datePickerData: null,
      datePickerCheckbox: "",
    },
    onSubmit: (values, actions) => {
      setIsSending(true);
      if (messageType === MessageType.SMS)
        values.sender_id &&
          AddMessage(
            {
              sender_id: values.sender_id!,
              content: values.message,
              phone_numbers: values.receivers,
              send_at: values.datePickerData
                ? moment(values.datePickerData).utc().format()
                : undefined,
              // scheduled: Boolean(values.datePickerData) ? 1 : 0,
              content_type: "free_text",
              provider_id: UserProviderId!,
              broadcast_message_id: null,
              template_id: null,
            },
            (error, resp) => {
              if (error) {
                if (error.response?.status === 400) {
                  actions.setErrors(error.response?.data?.message);
                } else {
                  actions.setErrors([
                    "Something went wrong, try again later.",
                  ] as any);
                }
              } else if (resp) {
                HandleSuccess();
              }
            }
          );
      if (messageType === MessageType.Whatsapp)
        AddWhatsappMessage({
          content: values.message,
          phone_numbers: values.receivers,
          whatsapp_template_id: templateId,
          sender_id: values.sender_id!,
        }).then((resp) => {
          HandleSuccess();
        });
    },
    validationSchema: validationSchema({
      validateSender: messageType === MessageType.SMS,
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  
  const SigntureSentence =
    formikForm.values["sender_id"] ==
    smsSenders.find((sender) => sender.is_shared && sender.senderType === "Africastalking_sender")?.id
    ? ` -sent by ${UserProvider?.smsSigName ?? ""} via agriBORA`
    : ( UserProvider?.smsSigName ? `-sent by ${UserProvider?.smsSigName}` : "");

  const MessageLengthWithSignture =
    formikForm.values["message"].length + SigntureSentence.length;

  const maxMessageLength = 160;

  const isShowTable = selectedTab === 0;

  const handleSendAsChange = (value?: string) =>
    formikForm.setFieldValue("sender_id", value);

  const handleDateButtonclick = (value: any) =>
    setDateMenuAnchorEl(value.currentTarget);

  const handleFarmersTabChange = (value: number) =>
    setSelectedTab(selectedTab !== 0 ? value : undefined);

  const handleMessageTypeChange = (value: MessageType) => {
    if (value !== messageType) {
      setmessageType(value);
      formikForm.setFieldValue("message", "");
      formikForm.setFieldValue("datePickerData", undefined);
      formikForm.setFieldValue("sender_id", undefined);
    }
  };

  // React.useEffect(() => {
  //   GetSender({ page: 0, pageSize: 100, status: "active" }, (error, resp) => {
  //     if (error) {
  //       console.log(error.message);
  //     } else if (resp) {
  //       setSendAsOptions([
  //         // ...(UserMenuroles === "admin" || UserProviderId === agriBORA_id ? [{ title: "AGRIBORA", value: "1" }] : []),
  //         ...sendAsOptions,
  //         ...resp.items.map((item) => ({
  //           title: item.at_sender_id,
  //           value: item.id,
  //         })),
  //       ]);
  //     }
  //   });
  // }, []);

  React.useEffect(() => {
    UserProviderId &&
      GetProvider(
        { page: 0, pageSize: 10, is_active: true, id: UserProviderId },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            SetUserProvider(resp.providers[0]);
          }
        }
      );
  }, []);

  const HandleSuccess = () => {
    SetshowSuccessMessage(true);
    // setIsSending(false);
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  // get user's provider whatsapp senders
  useEffect(() => {
    GetSenderIds(
      { page: 0, pageSize: 10, sender_type: "Infobip_sender" },
      (error, resp) => {
        setloadingwhatsappSenders(false);
        setwhatsappSenders(resp?.allSenders.nodes!);
      }
    );
  }, []);

  // get user's provider sms senders
  useEffect(() => {
    GetSenderIds(
      { page: 0, pageSize: 10, sender_type: "Africastalking_sender" },
      (error, resp) => {
        setloadingsmsSenders(false);
        setsmsSenders(resp?.allSenders.nodes!);
      }
    );
  }, []);

  const FarmersTableProps: Pick<
    FarmersTableProps,
    | "ActionsToExclude"
    | "OnSelection"
    | "Proiders_Ids"
    | "customSelection"
    | "SelectedFarmersPhones"
  > = {
    ActionsToExclude: [
      "ShowFarmer",
      "AddFarmer",
      "Refresh",
      "ShowSelectedFarmersOnly",
    ],
    ...(UserMenuroles != "admin"
      ? { Proiders_Ids: UserProviderId ? [UserProviderId] : undefined }
      : {}),
    SelectedFarmersPhones: formikForm.values["receivers"],
    customSelection: {
      onSelectAllChange: (data, isChecked) => {
        if (isChecked)
          formikForm.setFieldValue(
            "receivers",
            uniq([
              ...formikForm.values["receivers"],
              ...data.map((item) => item.phone),
            ])
          );
        if (!isChecked)
          formikForm.setFieldValue(
            "receivers",
            uniq(
              differenceBy(
                data.map((item) => item.phone),
                formikForm.values["receivers"]
              )
            )
          );
      },
      onRowSelectChange: (data, isChecked) => {
        if (isChecked)
          formikForm.setFieldValue("receivers", [
            ...uniq(formikForm.values["receivers"]),
            data.phone,
          ]);
        if (!isChecked)
          formikForm.setFieldValue(
            "receivers",
            formikForm.values["receivers"].filter(
              (formikReceiversValueItem: any) =>
                data.phone != formikReceiversValueItem
            )
          );
      },
    },
  };

  const handleMenuBlur = () => setDateMenuAnchorEl(undefined);

  const handleSendMessageClick = () => {
    if(formikForm.values["sender_id"]) {
      formikForm.setFieldValue('message', formikForm.values['message'] + SigntureSentence);
    }
    formikForm.submitForm()
  };
  
  return {
    isSending,
    loadingsmsSenders,
    loadingwhatsappSenders,
    smsSenders,
    whatsappSenders,
    settemplateId,
    handleMessageTypeChange,
    messageType,
    handleFarmersTabChange,
    isShowTable,
    formikForm,
    handleSendAsChange,
    DateMenuAnchorEl,
    handleDateButtonclick,
    handleMenuBlur,
    MessageLengthWithSignture,
    maxMessageLength,
    SigntureSentence,
    FarmersTableProps,
    showSuccessMessage,
    handleSendMessageClick,
    combinedSenders,
  };
};
