import * as React from "react";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import sortBy from "lodash/sortBy";
import cleanDeep from "clean-deep";
import uniqBy from "lodash/uniqBy";
import { AxiosInstance } from "configs/axios";
import { Constituency } from "models/constituency";
import { Region } from "models/region";
import { County } from "models/county";
import { Ward } from "models/ward";
import { GetWards } from "api/location/wards";

export interface WardInputProps {
  value: Array<Ward>;
  SelectedRegions: Array<Region>;
  SelectedCounties: Array<County>;
  SelectedConstituency: Array<Constituency>;
  options: Array<Ward>;
  onChange: (value: Array<Ward>) => void;
  onOptionsChange: (value: Array<Ward>) => void;
}

const WardInput: React.SFC<WardInputProps> = ({
  value,
  SelectedConstituency,
  SelectedCounties,
  SelectedRegions,
  options,
  onOptionsChange,
  onChange,
}) => {
  const [loading, SetLoading] = React.useState(true);
  const uniqOptions: Array<Ward> = uniqBy(options, "id");
  const sortedOptions: Array<Ward> = sortBy(
    uniqOptions,
    (item) => item?.constituencyByConstituencyId?.name
  );
  React.useEffect(
    () => HandleInputChange(null),
    [SelectedRegions, SelectedCounties, SelectedConstituency]
  ); // get data every single time Region or Counties or Constituencey tags change
  const HandleInputChange = (e: any) => {
    onOptionsChange && onOptionsChange([]);

    SetLoading(true);

    GetWards(
      {
        page: 0,
        pageSize: 100,
        region_id_list: SelectedRegions
          ? SelectedRegions.map((item: Region) => item.regionId)
          : undefined,
        county_id_list: SelectedCounties
          ? SelectedCounties.map((item: County) => item.countyId)
          : undefined,
        constituency_id_list: SelectedConstituency
          ? SelectedConstituency.map(
              (item: Constituency) => item.constituencyId
            )
          : undefined,
        // ward_id_list: [],
        is_attached_to_farmers: true,
        with_constituency: true,
        with_county: true,
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else {
          SetLoading(false);
          onOptionsChange && onOptionsChange(resp?.allWards.items!);
        }
      }
    );
  };

  return (
    <Autocomplete
      loading={loading}
      onChange={(e, value) => onChange && onChange(value)}
      size="small"
      groupBy={(option) => option.constituencyByConstituencyId.name}
      value={value}
      multiple
      options={sortedOptions.filter((item) =>
        value.every((valueItem) => valueItem.id !== item.id)
      )}
      getOptionLabel={(option) => option?.name}
      fullWidth
      style={{ minWidth: 300 }}
      onInputChange={HandleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Ward"
          data-cy="farmers-location-filter-ward-select"
        />
      )}
    />
  );
};

export default WardInput;
