import { Grid } from "@mui/material";
import CustomInfoCardWithIcon from "components/custom-compnents/custom-info-card-with-icon/custom-info-card-with-icon";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";
import { FunctionComponent } from "react";
import { IoLeaf, IoMap, IoPeople } from "react-icons/io5";
import { IParams, useLogic } from "./statistics-kpi.logic";

interface StatisticsKpiProps extends IParams { }

const StatisticsKpi: FunctionComponent<StatisticsKpiProps> = (props) => {
  const { farmersCount, farmsCount, estimatedMaizeYield } = useLogic(props);

  const kpiDataArray = [
    { value: farmersCount, icon: IoPeople, title: "All Farmers" },
    { value: farmsCount, icon: IoMap, title: "All Farms" },
    // { value: estimatedMaizeYield, icon: IoLeaf, title: "Estimated Maize Yield (kg)" },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomTitle label="Statistics" />
      </Grid>
      {kpiDataArray.map((item, index) => (
        <Grid key={index} item xs={12}>
          <CustomInfoCardWithIcon actionValue={item.value?.toString()} icon={item.icon} primaryText={item.title} />
        </Grid>
      ))}
    </Grid>
  );
};

export default StatisticsKpi;
