import { getEstimatedAggregatedyield } from "api/kpis/estimated-aggregated-yield";
import { getFarmersKpi } from "api/kpis/farmers";
import { getFarmsKpi } from "api/kpis/farms";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";

export interface IParams {
  region_id_list?: string[];
  county_id_list?: string[];
  constituency_id_list?: string[];
  ward_id_list?: string[];
  provider_id?: string;
}
export const useLogic = (params: IParams) => {
  const { constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id } = params;

  const [farmersCount, setfarmersCount] = useState<number | undefined>();

  const [farmsCount, setfarmsCount] = useState<number | undefined>();

  const [estimatedMaizeYield, setestimatedMaizeYield] = useState<number | undefined>();

  const user = useAppSelector((root) => root.appReducer.user);

  useEffect(() => {
    getFarmersKpi({ constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id: provider_id ? provider_id : user?.provider_id }).then((resp) => {
      setfarmersCount(resp.data.data.cube[0].farmers.unique_farmer_count);
    })

    getFarmsKpi({ constituency_id_list, county_id_list, region_id_list, ward_id_list, provider_id: provider_id ? provider_id : user?.provider_id }).then((resp) => {
      setfarmsCount(resp.data.data.cube[0].farms.unique_farms_count);
    });

    getEstimatedAggregatedyield({
      // farmFilter: {
      //   farmerByFarmerId: {
      //     providerOptinsByFarmerId: {
      //       some: {
      //         providerId: {
      //           equalTo: provider_id ? provider_id : user?.provider_id,
      //         },
      //       },
      //     },
      //     wardByWardId: {
      //       wardId: { in: ward_id_list },
      //       constituencyId: { in: constituency_id_list },
      //       constituencyByConstituencyId: {
      //         countyId: { in: county_id_list },
      //         countyByCountyId: {
      //           regionId: { in: region_id_list },
      //         },
      //       },
      //     },
      //   },
      // },
      provider_id : provider_id || user?.provider_id
    }).then((resp) => {
      setestimatedMaizeYield(resp.data);
    });
  }, [constituency_id_list?.length, county_id_list?.length, region_id_list?.length, ward_id_list?.length, provider_id]);

  return { farmersCount, farmsCount, estimatedMaizeYield };
};
