import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Params {
  id: string;
}

type DeleteAggregationActivityType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: any | null) => any
) => any;

const DeleteAggregationActivity: DeleteAggregationActivityType = (
  Params,
  callback
) => {
  AxiosInstance.delete(`/farm-activites/aggregation/${Params.id}`, {
    params: { ...Params },
  })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { DeleteAggregationActivity };
