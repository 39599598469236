import { AxiosInstance } from "configs/axios";

export interface IResp {
  data: {
    cube: {
      farmer_labels: {
        count: number;
        value: string;
      };
    }[];
  }
}

export interface IParams {
  region_id_list?: string[];
  county_id_list?: string[];
  constituency_id_list?: string[];
  ward_id_list?: string[];
  label_key?: string;
  provider_id?: string;
}

export const getFarmerLabelsUsage = async (Params: IParams) => {
  //   const graphQL = `
  //   query($filter:RootWhereInput){ 
  //     cube(limit: 5000,where: $filter) {
  //       farmerLabels(orderBy: {count: desc } ) {
  //         count
  //         value
  //       }

  //     }
  //   }
  // `;

  //   const variables = {
  //     filter: {
  //       regions: { regionId: { in: params.region_id_list } },
  //       constituencies: { constituencyId: { in: params.constituency_id_list } },
  //       wards: { wardId: { in: params.ward_id_list } },
  //       counties: { countyId: { in: params.county_id_list } },
  //       labelKeys: { key: { equals: params.key } },
  //       providerOptins: { providerId: { equals: params.provider_id } },
  //     },
  //   };

  return await AxiosInstance.get<IResp>("/farmer-analytics/find-farmer-labels-usage-kpi", {
    params: Params
  });
};
