import { GetUsers } from "api/user/get-users";
import { CustomUsersTableProps } from "components/custom-compnents/custom-users-table/custom-users-table";
import { combinedProps } from "components/standalone-components/add-update-user-modal/add-update-user-modal";
import { RemoveUserComponentProps } from "components/standalone-components/remove-user-component/remove-user-component";
import { debounce } from "lodash";
import { IUser } from "models/user";
import React from "react";

export const useLogic = () => {
  const [userDataToBeDeleted, setUserDataToBeDeleted] = React.useState<IUser | undefined>(undefined);
  const [userDataToBeUpdated, setUserDataToBeUpdated] = React.useState<IUser | undefined>(undefined);
  const [isShowAddUserDialog, setIsShowAddUserDialog] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [data, setData] = React.useState<IUser[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpenFilterArea, setIsOpenFilterArea] = React.useState(false);
  const [emailFilterValue, setemailFilterValue] = React.useState("");
  const [nameFilterValue, setnameFilterValue] = React.useState("");
  const [providerIdValue, setproviderIdValue] = React.useState("");
  const [phoneFilterValue, setPhoneFilterValue] = React.useState("");
  const [rolesFilterValue, setRolesFilterValue] = React.useState("");

  const [columnsState, setColumnsState] = React.useState([
    { field: "id", checked: false },
    { field: "name", checked: true },
    { field: "email", checked: true },
    { field: "phone", checked: false },
    { field: "roles", checked: true },
    { field: "provider", checked: true },
  ]);

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchTextChange = (value:string) => {
    setSearchText(value)
  }

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const handleAdded = () => {
    setIsShowAddUserDialog(false);
    refreshData();
  };

  const handleFilterButtonClick = () => setIsOpenFilterArea(true);

  const handleCancelAdd = () => setIsShowAddUserDialog(false);

  const handleUserUpdated = () => {
    setUserDataToBeUpdated(undefined);
    refreshData();
  };

  const handleCancelUpdate = () => {
    setUserDataToBeUpdated(undefined);
  };

  const clearFilters = () => {
    setRolesFilterValue("");
    setPhoneFilterValue("");
    setnameFilterValue("");
    setemailFilterValue("");
    setproviderIdValue("");
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetUsers({ 
      page: currentPage, 
      pageSize: perPage,  
      provider_id_list: providerIdValue ? [providerIdValue] : undefined,
      email: emailFilterValue || undefined, 
      phone: phoneFilterValue || undefined, 
      name: nameFilterValue || undefined, 
      menuroles:  rolesFilterValue|| undefined, 
    }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        setIsLoading(false);
        setData(resp.data);
        setTotalCount(resp.total);
      }
    });
  }, [currentPage, perPage, dataUpdateIndex, searchText]);

  const isFilterEmpty = !emailFilterValue && !nameFilterValue && !providerIdValue && !phoneFilterValue && !rolesFilterValue;

  const showFilterArea = isOpenFilterArea || !isFilterEmpty;

  const tableProps: CustomUsersTableProps = {
    showFilterArea: showFilterArea,
    onDeleteButtonClick: setUserDataToBeDeleted,
    onEditButtonClick: setUserDataToBeUpdated,
    isLoading: isLoading,
    data: preparedData,
    countLabelProps: {
      count: totalCount,
    },
    filterFormProps: {
      emailFilterProps: { value: emailFilterValue, onChange: (e) => setemailFilterValue(e.currentTarget.value) },
      nameFilterProps: { value: nameFilterValue, onChange: (e) => setnameFilterValue(e.currentTarget.value) },
      phoneFilterProps: { value: phoneFilterValue, onChange: (e) => setPhoneFilterValue(e.currentTarget.value) },

      rolesFilterProps: { value: rolesFilterValue, onChange: (e) => setRolesFilterValue(e.target.value as string) },
      providerFilterProps: { value: providerIdValue, onChange: (value) => setproviderIdValue(!Array.isArray(value) && value?.id ? value?.id : "") },
    },
    filterContainerProps: {
      CancelSuccessButtonGroupProps: {
        SuccessButtonProps: {
          disabled: isFilterEmpty,
          onClick: () => {
            refreshData();
            setCurrentPage(0);
          },
        },
        CancelButtonProps: {
          onClick: () => {
            clearFilters();
            setIsOpenFilterArea(false);
            refreshData();
          },
        },
      },
    },
    isShowEditAction: true,
    isShowRemoveAction: true,
    actionBarProps: {
      filterButtonProps: {
        onClick: handleFilterButtonClick,
      },
      searchTextFieldProps: {
        onChange: (e) => handleSearchTextChange(e.currentTarget.value),
      },
      isShowSearchTextField: false,
      addButtonProps: {
        onClick: () => setIsShowAddUserDialog(true),
      },
      isShowCreateButton: true,
      isShowColumnButton: true,
      isShowFilterButton: true,

      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
    },
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };

  const addDialogProps: combinedProps = {
    Variant: "add",
    onAdded: handleAdded,
    OnCancel: handleCancelAdd,
    open: isShowAddUserDialog,
    Shape: "modal",
  };

  const handleDeletedUser = () => {
    setUserDataToBeDeleted(undefined);
    refreshData();
  };

  const handleCancelDeleteUser = () => {
    setUserDataToBeDeleted(undefined);
  };

  const updateDialogProps: combinedProps | undefined = userDataToBeUpdated?.id
    ? {
        Variant: "update",
        onUpdate: handleUserUpdated,
        OnCancel: handleCancelUpdate,
        Shape: "modal",
        UserId: userDataToBeUpdated.id,
        open: !!userDataToBeUpdated.id,
      }
    : undefined;

  const deleteUserDialogProps: RemoveUserComponentProps | undefined = userDataToBeDeleted?.id
    ? {
        id: userDataToBeDeleted?.id,
        open: !!userDataToBeDeleted?.id,
        onCancel: handleCancelDeleteUser,
        onDeleted: handleDeletedUser,
      }
    : undefined;

  return { tableProps, addDialogProps, updateDialogProps, deleteUserDialogProps };
};
