import { Box, Divider, Grid, Paper, Toolbar, Typography } from "@mui/material";
import {
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoCreateOutline,
  IoTrashOutline,
} from "react-icons/io5";
import LabelText from "../label-text/label-text";
import RoundButton, { RoundButtonProps } from "../round-button/round-button";
import { useStyles } from "./farmer-label-component.style";

interface FarmerLabelComponentProps {
  editButtonProps?: RoundButtonProps;
  deleteButtonProps?: RoundButtonProps;
  expandButtonProps?: RoundButtonProps;
  title?: string;
  labels?: string[];
  openSubPanel?: boolean;
  isShowEditButton?: boolean;
  isShowDeleteButton?: boolean;
}

const FarmerLabelComponent: React.FunctionComponent<FarmerLabelComponentProps> =
  (props) => {
    const {
      title,
      deleteButtonProps,
      editButtonProps,
      labels,
      openSubPanel,
      expandButtonProps,
      isShowDeleteButton,
      isShowEditButton,
    } = props;
    const classes = useStyles();

    return (
      <Box component={Paper} py={1}>
        <Toolbar>
          <>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <Typography variant="h6">{title}</Typography>
              </Grid>
            </Grid>
            <Box ml="auto" display="flex" gap={1.5} alignItems="center">
              <RoundButton
                {...expandButtonProps}
                className={`${openSubPanel && classes.shiny}`}
              >
                {openSubPanel ? (
                  <IoChevronUpOutline size={20} />
                ) : (
                  <IoChevronDownOutline size={20} />
                )}
              </RoundButton>
              {isShowEditButton && (
                <RoundButton {...editButtonProps}>
                  <IoCreateOutline size={20} />
                </RoundButton>
              )}
              {isShowDeleteButton && (
                <RoundButton {...deleteButtonProps}>
                  <IoTrashOutline size={20} />
                </RoundButton>
              )}
            </Box>
          </>
        </Toolbar>
        {openSubPanel && (
          <>
            <Divider sx={{ mt: 1 }} />
            <Box m={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <LabelText>Label values</LabelText>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {labels?.map((item) => (
                      <Grid item xs={12} key={item}>
                        <Typography variant="h6" className={`${classes.label}`}>
                          {item}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    );
  };

export default FarmerLabelComponent;
