import { ButtonProps, Container, Grid } from "@mui/material";
import AddRemoveColumnsMenu from "../check-box-items-menu/check-box-items.menu";
import CustomMenuButton from "../custom-menu-button/custom-menu-button";
import FiltersButton from "../filter-button/filter-button";
import * as React from "react";
import ResendButton from "../resend-button/resend-button";
import ArchiveButton from "../archive-button/archive-button";
import DeleteButton from "../delete-button/delete-button";

export interface columnsState {
  field: string;
  checked: boolean;
}
export interface CustomMessagesDetailTableActionBarProps {
  resendButtonProps?: ButtonProps;
  deleteButtonProps?: ButtonProps;
  importButtonProps?: ButtonProps;
  filterButtonProps?: ButtonProps;
  columnButtonProps?: ButtonProps;
  columnsState?: columnsState[];
  onColumnsStateChange?: (column: string, state: boolean) => any;
  isShowColumnButton?: boolean;
  isShowFilterButton?: boolean;
  isShowImportButton?: boolean;
  isShowResendButton?: boolean;
  isShowArchiveButton?: boolean;
  isShowDeleteButton?: boolean;
  archiveButtonProps?: ButtonProps;
}

const CustomMessagesDetailTableActionBar: React.FunctionComponent<CustomMessagesDetailTableActionBarProps> = (props) => {
  const { resendButtonProps, columnButtonProps, filterButtonProps, columnsState, onColumnsStateChange, isShowColumnButton, isShowResendButton, isShowFilterButton, isShowArchiveButton, archiveButtonProps, deleteButtonProps, isShowDeleteButton } = props;

  const [columnsMenuAchorEl, setColumnsMenuAchorEl] = React.useState(undefined);
  const handleRemoveColumnsMenuClose = () => setColumnsMenuAchorEl(undefined);
  const handleColumnsButtonClick = (e: any) => setColumnsMenuAchorEl(e.currentTarget);

  const ButtonsData = [
    {
      label: "Columns",
      Component: CustomMenuButton,
      props: columnButtonProps,
      isShowing: isShowColumnButton,
    },
    {
      label: "filters",
      Component: FiltersButton,
      props: filterButtonProps,
      isShowing: isShowFilterButton,
    },
    {
      label: "Re-send Messages",
      Component: ResendButton,
      props: resendButtonProps,
      isShowing: isShowResendButton,
    },
    {
      label: "Archive Messages",
      Component: ArchiveButton,
      props: archiveButtonProps,
      isShowing: isShowArchiveButton,
    },
    {
      label: "Delete Queued",
      Component: DeleteButton,
      props: deleteButtonProps,
      isShowing: isShowDeleteButton,
    },
  ];

  return (
    <>
      <Container disableGutters>
        <AddRemoveColumnsMenu
          Items={
            columnsState?.map((item, index) => ({
              checked: item.checked,
              label: item.field,
            })) || []
          }
          onItemClick={(index) => columnsState && onColumnsStateChange && onColumnsStateChange(columnsState[index].field, !columnsState[index].checked)}
          open={Boolean(columnsMenuAchorEl)}
          onClose={handleRemoveColumnsMenuClose}
          anchorEl={columnsMenuAchorEl}
        />
        <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
          {ButtonsData.map(
            (item, index) =>
              item.isShowing && (
                <Grid item lg={3} md={6} xs={12}>
                  <item.Component
                    fullWidth
                    onClick={(e) => {
                      if (item.label === "Columns") {
                        handleColumnsButtonClick(e);
                      }
                    }}
                    {...item.props}
                  >
                    {item.label}
                  </item.Component>
                </Grid>
              )
          )}
        </Grid>
      </Container>
    </>
  );
};

export default CustomMessagesDetailTableActionBar;
