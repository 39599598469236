import { CancelSuccessButtonsGroupProps } from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import {
  get_callbacks,
  update_callback,
} from "components/data-tables/callbacks-table/api";
import { useFormik } from "formik";
import React from "react";

export interface IParams {
  onCancel?: () => any;
  onUpdated?: () => any;
  id?: string;
  farmer_id?: string;
}
export const useLogic = (params: IParams) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const formikForm = useFormik({
    initialValues: { notes: "", type: undefined },
    onSubmit: (values) => {
      setIsSubmitting(true);
      update_callback(
        { ...values, id: params.id! },
        (error, response) => {
          if (error) {
            console.log(error.message);
          } else if (response) {
            params.onUpdated?.();
            setIsSubmitting(false);
          }
        }
      );
    },
  });

  React.useEffect(() => {
    setIsLoading(true);
    get_callbacks({ page: 0, pageSize: 10, farmer_id: params.farmer_id, with_farmer: true }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        formikForm.setFieldValue("type", resp.callbacks[0].type);
        formikForm.setFieldValue("notes", resp.callbacks[0].notes);
        setIsLoading(false);
      }
    });
  }, [params.id]);

  const data = [
    {
      type: "text",
      props: { multiline: true, rows: 5, placeholder: "Notes" },
      value: formikForm.values["notes"],
      onChange: (e: any) => {
        formikForm.setFieldValue("notes", e.currentTarget.value);
      },
    },
    {
      type: "select-menu",
      value: formikForm.values["type"],
      onChange: (e: any) => {
        formikForm.setFieldValue("type", e.target.value);
      },
      options: [
        { title: "Done", value: "done" },
        { title: "Waiting", value: "waiting" },
      ],
    },
  ];

  const cancelSuccessButtonProps: CancelSuccessButtonsGroupProps = {
    CancelButtonProps: {
      onClick: params.onCancel,
      label: "Cancel",
    },
    SuccessButtonProps: {
      onClick: () => formikForm.submitForm(),
      label: "Update",
      disabled: isSubmitting,
    },
  };

  return { data, cancelSuccessButtonProps, isLoading };
};
