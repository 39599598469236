import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { Provider } from "react-redux";
import { store } from "../store/store";
import { Default } from "../theme/default/default";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "../components/layout/layout";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "../configs/react-query";
import { SnackbarProvider } from "notistack";
import MainRouter from "components/routers/main-routers/main-router";
import "css/global.css";

const App = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={3}>
        {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
        <ThemeProvider theme={Default}>
          <CssBaseline />
          <Router>
            <Layout>
              <MainRouter />
            </Layout>
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  </Provider>
);

export default App;
