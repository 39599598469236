import {
  Box,
  ButtonBase,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { GetAuthToken } from "../../../../api/auth/login/login";
import { authTokenService } from "../../../../services/auth-token-service/auth-token-service";
import LoginForm from "../../../forms/login-form/login-form";
import LoginWallpaper from "assets/images/login-wallpaper.jpg";
import { useStyles } from "./login.style";
import LogoCropped from "assets/images/agribora-logo-cropped.png";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { useTheme } from "@mui/system";

interface LoginProps {}

const Login: React.FunctionComponent<LoginProps> = () => {
  const LoginMutation = useMutation(GetAuthToken, {
    onSuccess: async (data) => {
      authTokenService.SaveTokenLocally(data.data.access_token);
      window.location.reload();
    },
  });
  const FormikLoginForm = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: (values, action) => {
      LoginMutation.mutate(values);
    },
  });

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  return (
    <Grid container>
      {!isMd && (
        <Grid item lg={6}>
          <Box
            style={{
              verticalAlign: "middle",
              height: "100%",
              width: "100%",
              backgroundImage: `url('${LoginWallpaper}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </Grid>
      )}

      <Grid item lg={6} xs={12}>
        {" "}
        <Box className={`${classes.containerRoot}`}>
          <Box display="flex" flexDirection="column" minHeight="100vh">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mx={6}
              py={3}
            >
              <img src={LogoCropped} alt="logo" />
              <a href={(window as any).env.REACT_APP_AGRIBORA_MAIN_WEBSITE_URL}>
                <ButtonBase className={`${classes.homeButtonRoot}`}>
                  Back Home{" "}
                  <IoArrowForwardCircleOutline
                    size={25}
                    className={`${classes.homeButtonArrowRoot}`}
                  />
                </ButtonBase>
              </a>
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="calc(100vh - 150px)"
            >
              <Box>
                <Typography
                  variant="h4"
                  className={`${classes.welcomeTextRoot}`}
                >
                  Welcome back!
                </Typography>
                <Typography className={`${classes.LoginTextRoot}`}>
                  Please login to your account.
                </Typography>
                <Box mt={2} maxWidth={400}>
                  <LoginForm
                    isError={LoginMutation.isError}
                    isSubmitButtonDisabled={LoginMutation.isLoading}
                    onFormSubmit={FormikLoginForm.handleSubmit}
                    onEmailChange={FormikLoginForm.handleChange}
                    onPasswordChange={FormikLoginForm.handleChange}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.footerRoot}>
              © agriBORA {new Date().getFullYear()}. All rights reserved.
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
