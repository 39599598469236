import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
interface Params {
  id: string;
}
type Resp = number;
type DeleteBroadcastsMessagesType = (
  Params: Params,
  callback: (Error: null | AxiosError, Resp: null | Resp) => any
) => any;
const DeleteBroadcastsMessages: DeleteBroadcastsMessagesType = (
  Params,
  callback
) => {
  AxiosInstance.delete("/sms-broadcast-messages/" + Params.id)
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => callback(error, null));
};

export { DeleteBroadcastsMessages };
