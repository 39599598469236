import { GetProvider } from "api/provider/get-provider";
import { deleteSmsMessage } from "api/sms/delete-sms";
import { GetSMS } from "api/sms/get-sms";
import { CustomMessagesDetailTableProps } from "components/custom-compnents/custom-messages-detail-page/custom-messages-detail-table";
import { Fields } from "components/custom-compnents/custom-messages-detail-page/custom-messages-detail-table.schema";
import { uniq } from "lodash";
import React from "react";
import { useAppSelector } from "store/hooks";

export type statusType = "queued" | "sent" | "failed" | "accepted";

export interface IParams {
  status_list?: statusType[];
  provider_id_list?: string[];
}

export const useLogic = (params: IParams) => {
  const [showDeleteConfirmationModal, setshowDeleteConfirmationModal] = React.useState(false);
  const [messageIdToDelete, setMessageIdToDelete] = React.useState<string | undefined>();
  const [isSubmittingDelete, setisSubmittingDelete] = React.useState(false);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [data, setData] = React.useState<Fields[]>([]);
  const [orderBy, setOrderBy] = React.useState(["SEND_AT_DESC"]);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isShowFilterArea, setIsShowFilterArea] = React.useState(false);
  const [messageTextFilter, setMessageTextFilter] = React.useState("");

  const user = useAppSelector((root) => root.appReducer.user);

  const [providersFilter, setProvidersFilter] = React.useState<
    {
      label: string;
      checked: boolean;
      value: string;
    }[]
  >([]);

  const [archiveFilter, setArchiveFilter] = React.useState([
    { label: "Archived", checked: false, value: "archived" },
    { label: "UnArchived", checked: false, value: "unarchived" },
  ]);

  const [sendStatusFilter, setSendStatusFilter] = React.useState([
    {
      label: "Sent",
      checked: !!params.status_list?.find((item) => item === "sent"),
      value: "sent",
    },
    {
      label: "Failed",
      checked: !!params.status_list?.find((item) => item === "failed"),
      value: "failed",
    },
    {
      label: "Queued",
      checked: !!params.status_list?.find((item) => item === "queued"),
      value: "queued",
    },
    {
      label: "Accepted",
      checked: !!params.status_list?.find((item) => item === "accepted"),
      value: "accepted",
    },
  ]);

  const [columnsState, setColumnsState] = React.useState([
    ...(user?.menuroles === "admin" ? [{ field: "provider_name", checked: true }] : []),
    { field: "phone", checked: true },
    { field: "archived", checked: true },
    { field: "send_at", checked: true },
    { field: "status", checked: true },
    { field: "message", checked: true },
    { field: "failure_reason", checked: false },
  ]);

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handleDeleteConfirmation = () => {
    const id_list: string[] = messageIdToDelete ? [messageIdToDelete] : selectedIds;

    setisSubmittingDelete(true);

    deleteSmsMessage({ id_list }).then((resp) => {
      setMessageIdToDelete(undefined);
      setisSubmittingDelete(false);
      setSelectedIds([]);
      setshowDeleteConfirmationModal(false);
      refreshData();
    });
  };

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  React.useEffect(() => {
    GetProvider({ page: 0, pageSize: 10, id: user?.menuroles !== "admin" ? user?.provider_id! : undefined }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        setProvidersFilter(
          resp.providers.map((item, index) => ({
            label: item.name,
            value: item.id,
            checked: !!params.provider_id_list?.find((filterItem) => filterItem === item?.id),
          }))
        );
      }
    });
  }, [params.provider_id_list, user?.menuroles, user?.provider_id]);

  const clearFilters = () => {
    setMessageTextFilter("");
    setArchiveFilter(archiveFilter.map((item) => ({ ...item, checked: false })));
    setSendStatusFilter(sendStatusFilter.map((item) => ({ ...item, checked: false })));
    setProvidersFilter(providersFilter.map((item) => ({ ...item, checked: false })));
  };

  const handleCancelFilter = () => {
    refreshData();
    clearFilters();
    setIsShowFilterArea(false);
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const handleApplyFilter = () => {
    refreshData();
    setCurrentPage(0);
  };

  const handleFilterButtonClick = () => setIsShowFilterArea(true);

  const handleArchiveItemClick = (index: number) => {
    const FilterValueClone = archiveFilter.map((item) => item);
    FilterValueClone[index] = {
      ...FilterValueClone[index],
      checked: !FilterValueClone[index].checked,
    };
    setArchiveFilter(FilterValueClone);
  };

  const handleSendStatusItemClick = (index: number) => {
    const FilterValueClone = sendStatusFilter.map((item) => item);
    FilterValueClone[index] = {
      ...FilterValueClone[index],
      checked: !FilterValueClone[index].checked,
    };
    setSendStatusFilter(FilterValueClone);
  };

  const handleProviderItemClick = (index: number) => {
    const FilterValueClone = providersFilter.map((item) => item);
    FilterValueClone[index] = {
      ...FilterValueClone[index],
      checked: !FilterValueClone[index].checked,
    };
    setProvidersFilter(FilterValueClone);
  };

  const handleRowSelectChange = (fields: Fields, value: boolean) => {
    if (!value) setSelectedIds((selected) => selected.filter((item) => item !== fields.id));
    if (value && !selectedIds.includes(fields.id)) setSelectedIds((selected) => [...selected, fields.id]);
  };

  const handleSelectAllChange = (fields: Fields[], value: boolean) => {
    if (!value) setSelectedIds((selected) => selected.filter((item) => !fields.some((someIetm) => someIetm.id === item)));
    if (value) {
      setSelectedIds((selected) => uniq([...selected, ...fields.filter((item) => item.queued).map((item) => item.id)]));
    }
  };

  const handleDeleteButtonClick = (fields: Fields) => {
    setMessageIdToDelete(fields.id);
    setshowDeleteConfirmationModal(true);
  };

  const handleCancelDelete = () => {
    setshowDeleteConfirmationModal(false);
    setMessageIdToDelete(undefined);
  };

  const handleBulkDeleteButtonClick = () => {
    setshowDeleteConfirmationModal(true);
  };

  const isFilterEmpty = !messageTextFilter && providersFilter.every((item) => !item.checked) && sendStatusFilter.every((item) => !item.checked) && archiveFilter.every((item) => !item.checked);

  const providersFilterValue = JSON.stringify(providersFilter.map((item) => item.value));

  React.useEffect(() => {
    setIsLoading(true);
    if (providersFilter.length > 0) {
      GetSMS(
        {
          provider_id_list: providersFilter.filter((item) => item.checked).map((item) => item.value),
          text: messageTextFilter || undefined,
          statusArchived: archiveFilter.filter((item) => item.checked).map((item) => item.value),
          statusAccepted: sendStatusFilter.find((item) => item.value === "accepted" && item.checked) ? !!sendStatusFilter.find((item) => item.value === "accepted" && item.checked) : undefined,
          statusFailed: sendStatusFilter.find((item) => item.value === "failed" && item.checked) ? !!sendStatusFilter.find((item) => item.value === "failed" && item.checked) : undefined,
          statusQueued: sendStatusFilter.find((item) => item.value === "queued" && item.checked) ? !!sendStatusFilter.find((item) => item.value === "queued" && item.checked) : undefined,
          statusSent: sendStatusFilter.find((item) => item.value === "sent" && item.checked) ? !!sendStatusFilter.find((item) => item.value === "sent" && item.checked) : undefined,
          pageSize: perPage,
          page: currentPage,
          sort_by: orderBy,
          provider_id: user?.menuroles !== "admin" ? user?.provider_id! : providersFilter.filter((item) => item.checked).map((item) => item.value)[0],
        },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            const CurrentProvidersIdsList = resp.items.map((item) => item.provider_id);
            if (user?.menuroles === "admin") {
              GetProvider({ page: 0, pageSize: 10, id: CurrentProvidersIdsList[0] }, (error, ProviderResp) => {
                if (error) {
                  console.log(error.message);
                } else if (ProviderResp) {
                  const MessagesWithProviderName = resp.items.map((item) => ({
                    ...item,
                    provider_name: ProviderResp.providers.find((ProviderItem) => ProviderItem.id === item.provider_id)?.name!,
                  }));
                  setData(MessagesWithProviderName);
                  setTotalCount(resp.totalCount);
                  setIsLoading(false);
                }
              });
            } else {
              setData(
                resp.items.map((item) => ({
                  ...item,
                  provider_name: "",
                }))
              );
              setTotalCount(resp.totalCount);
              setIsLoading(false);
            }
          }
        }
      );
    }
  }, [user?.menuroles, user?.provider_id, dataUpdateIndex, perPage, currentPage, orderBy, JSON.stringify(providersFilter.map((item) => item.value))]);

  const preparedData = data.map((item) => ({ ...item, selected: selectedIds.includes(item.id), disabledCheckbox: !item.queued }));


  const handleOrderChange: CustomMessagesDetailTableProps["onOrderChange"] = (
    orderBy,
    orderDirection
  ) => {
    if (orderBy != -1) {
      setOrderBy(["SEND_AT_" + orderDirection.toUpperCase()]);
    }

    console.log(orderBy, orderDirection, "order");
  };
  const tableProps: CustomMessagesDetailTableProps = {
    showFilterArea: isShowFilterArea || !isFilterEmpty,
    countLabelProps: {
      count: totalCount,
    },
    filterContainerProps: {
      CancelSuccessButtonGroupProps: {
        CancelButtonProps: {
          onClick: handleCancelFilter,
        },
        SuccessButtonProps: {
          onClick: handleApplyFilter,
          disabled: isFilterEmpty,
        },
      },
    },
    filterFormProps: {
      statusMenuFilterProps: {
        Items: sendStatusFilter,
        onItemClick: handleSendStatusItemClick,
      },

      archiveMenuFilterProps: {
        Items: archiveFilter,
        onItemClick: handleArchiveItemClick,
      },
      messageTextFilterProps: {
        onChange: (e) => setMessageTextFilter(e.currentTarget.value),
        value: messageTextFilter,
      },
      providersMenuFilterProps: {
        Items: providersFilter,
        onItemClick: handleProviderItemClick,
      },
    },
    actionBarProps: {
      filterButtonProps: {
        onClick: handleFilterButtonClick,
      },
      deleteButtonProps: {
        disabled: selectedIds.length === 0,
        onClick: handleBulkDeleteButtonClick,
      },
      isShowFilterButton: true,
      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
      isShowColumnButton: true,
      isShowDeleteButton: user?.menuroles !== "user",
    },
    customSelection:
      user?.menuroles !== "user"
        ? {
          onRowSelectChange: handleRowSelectChange,
          onSelectAllChange: handleSelectAllChange,
        }
        : undefined,
    onDeleteButtonClick: handleDeleteButtonClick,
    isShowDeleteButton: user?.menuroles !== "user",
    isLoading: isLoading,
    onOrderChange: handleOrderChange,
    data: preparedData,
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };

  return { tableProps, showDeleteConfirmationModal, handleCancelDelete, isSubmittingDelete, handleDeleteConfirmation };
};
