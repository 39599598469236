import * as yup from "yup";

interface IParams {
  validatePassword?: boolean;
  validatePrvoiderOnOrgAdminOnly?: boolean;
  ignoreProviderValidation?: boolean;
}
export const validationSchema = (params: IParams) =>
  yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    menuroles: yup.string().required(),
    password: params.validatePassword ? yup.string().required() : yup.string(),
    provider_id: !params.ignoreProviderValidation
      ? params.validatePrvoiderOnOrgAdminOnly
        ? yup.string().when("menuroles", {
          is: "org_admin",
          then: yup.string().required().uuid("Organization Must Be Selected"),
        })
        : yup.string().required().uuid("Organization Must Be Selected")
      : yup.string().optional(),
  });
