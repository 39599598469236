import { Box, Dialog, DialogProps, Typography } from "@mui/material";

import { useStyle } from "./custom-dialog-v2.style";

export interface CustomDialogV2Props extends DialogProps {
  header?: any;
  footer?: React.ReactElement;
  "data-cy"?: string;
}

const CustomDialogV2: React.FunctionComponent<CustomDialogV2Props> = (
  props
) => {
  const classes = useStyle();
  const { header, footer } = props;
  return (
    <Dialog
      {...props}
      className={classes.root}
      PaperProps={{ className: classes.paperRoot, ...props.PaperProps }}
    >
      {typeof header === "string" ? (
        <Typography className={classes.headerRoot}>{header}</Typography>
      ) : (
        header
      )}
      {props.children}
      {footer && (
        <Box mt={2} className={classes.ButtonAreaRoot}>
          {footer}
        </Box>
      )}
    </Dialog>
  );
};

export default CustomDialogV2;
