import BalanceHolder, { BalanceHolderProps } from "components/custom-compnents/balance-holder/balance-holder";
import { FunctionComponent } from "react";
import { IParams, useLogic } from "./ewallet-balance.logic";

interface EwalletBalanceProps extends Pick<BalanceHolderProps, "hideLabel" | "variant">, IParams {}

const EwalletBalance: FunctionComponent<EwalletBalanceProps> = (props) => {
  const { id, ...rest } = props;
  const { balance } = useLogic({ id });
  return <BalanceHolder value={balance} {...rest} />;
};

export default EwalletBalance;
