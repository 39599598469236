import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Resp { }
interface Params {
  certificate_id: string;
  confirmed_by: string;
  farmer_id: string;
  certification_date: string;
}

type CreateCertificaionType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const CreateCertificaion: CreateCertificaionType = (Params, callback) => {
  AxiosInstance.post("/certifications", { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { CreateCertificaion };
