// import { Box, Paper } from "@mui/material";
// import MaterialTable from "material-table";
import React from "react";

// import { Schema } from "./schema";
import CustomMessagesStatusTable from "components/custom-compnents/custom-messages-status-table/custom-messages-status-table";
import { useLogic } from "./messages-status-table.logic";
import { statusType } from "components/custom-compnents/custom-messages-status-table/custom-messages-status-table.schema";

export interface MessagesTableProps {
  OnStatusClicked?: (ProivderId: string, status: statusType) => any;
}

const MessagesTable: React.FC<MessagesTableProps> = (props) => {
  const { OnStatusClicked } = props;
  const { tableProps } = useLogic({ OnStatusClicked });
  return (
    <>
      <CustomMessagesStatusTable {...tableProps} />
    </>
  );
};

export default MessagesTable;
