import { Box } from "@mui/material";
import React from "react";
import BroadcastsTable from "components/data-tables/broadcasts-table/broadcasts-table";

interface BroadcastsProps {}

const Broadcasts: React.FunctionComponent<BroadcastsProps> = () => {
  return (
    <Box>
      <BroadcastsTable />
    </Box>
  );
};

export default Broadcasts;
