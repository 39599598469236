import { Box, Grid } from "@mui/material";
import React from "react";
import { IUser } from "models/user";
import FarmsActivitiesTable, {
  FarmsActivitiesTableProps,
} from "components/data-tables/farms-activities-table/farms-activities-table";

interface ManagmentActivitiesProps {
  show: Boolean;
  FarmId: string;
  User: IUser;
}

const ManagmentActivities: React.FunctionComponent<ManagmentActivitiesProps> =
  ({ show, FarmId, User }) => {
    const ExcludeActions: FarmsActivitiesTableProps["ActionsToExclude"] =
      User.menuroles === "user"
        ? ["update", "delete", "add"]
        : ["delete", "update"];
    return (
      <Box hidden={!show}>
        <Grid container spacing={2}>
          <Grid item md={10} xs={12}>
            <FarmsActivitiesTable
              Farm_Id={FarmId}
              ActionsToExclude={ExcludeActions}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

export default ManagmentActivities;
