import Data from "configs/routes";
import { Route, Routes } from "react-router-dom";
import { useRouteutils } from "hooks/routes-hook";
import { Can } from "@casl/react";
import ability from "configs/ability";
import { RouteItem } from "classes/route-item";

interface MainRouterProps {}

const MainRouter: React.FunctionComponent<MainRouterProps> = () => {
  const routerHook = useRouteutils();
  return (
    <Routes>
      {routerHook
        .getFlatRoutesArray(Data.Lists)
        .filter((item) => item.component)
        .map((item, key) => (
          <Route
            key={key}
            path={item.pathUrl}
            element={
              item.component && (
                <Can ability={ability()} I="see" this={new RouteItem(item)}>
                  <item.component />
                </Can>
              )
            }
          />
        ))}
    </Routes>
  );
};

export default MainRouter;
