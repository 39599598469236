import MaterialTable from "@material-table/core";
import CustomPagnation from "components/custom-compnents/custom-pagnation/custom-pagnation";
import { Fields, Params, schema } from "./custom-agent-codes-table.schema";
import { Box, Grid } from "@mui/material";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import { CustomPagnationProps } from "components/custom-compnents/custom-pagnation/custom-pagnation";
import CustomFiltersContainer, {
  CustomFiltersContainerProps,
} from "../custom-filters-container/custom-filters-container";
import CustomAgentCodesTableActionBar, {
  CustomAgentCodesTableActionBarProps,
} from "../custom-agent-codes-table-action-bar/custom-agent-codes-table-action-bar";
import AgentCodesTableFilterForm, {
  AgentCodesTableFilterFormProps,
} from "components/forms/agent-codes-table-filter-form/agent-codes-table-filter-form";

export interface CustomAgentCodesTableProps {
  countLabelProps?: CountLabelProps;
  showFilterArea?: boolean;
  filterContainerProps?: CustomFiltersContainerProps;
  filterFormProps?: AgentCodesTableFilterFormProps;
  pagnationProps?: CustomPagnationProps;
  actionBarProps?: CustomAgentCodesTableActionBarProps;
  data: Fields[];
  isLoading?: boolean;
  customSelection?: Params["customSelection"];
  onOrderChange?: (
    orderBy: number,
    orderDirection: "desc" | "asc"
  ) => void | undefined;
  onEditButtonClick?: Params["onEditButtonClick"];
  onDeleteButtonClick?: Params["onDeleteButtonClick"];
  isShowRemoveAction?: boolean;
  isShowEditAction?: boolean;
}

const CustomAgentCodesTable: React.FunctionComponent<CustomAgentCodesTableProps> =
  (props) => {
    const {
      countLabelProps,
      showFilterArea,
      pagnationProps,
      filterContainerProps,
      filterFormProps,
      actionBarProps,
      data,
      isLoading,
      customSelection,
      onOrderChange,
      onEditButtonClick,
      onDeleteButtonClick,
      isShowEditAction,
      isShowRemoveAction,
    } = props;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ paddingLeft: 0 }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingLeft: 0 }}
          >
            <Grid item md={3} xs={12}>
              <CountLabel {...countLabelProps} label="All Agent Codes" />
            </Grid>
            <Grid item md={9} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Box width={840} maxWidth="100%">
                  <CustomAgentCodesTableActionBar {...actionBarProps} />
                </Box>
              </Box>
            </Grid>
            {showFilterArea && (
              <Grid item xs={12}>
                <CustomFiltersContainer {...filterContainerProps}>
                  <AgentCodesTableFilterForm {...filterFormProps} />
                </CustomFiltersContainer>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            {...schema({
              customSelection,
              data,
              columnState: actionBarProps?.columnsState,
              onEditButtonClick,
              onDeleteButtonClick,
              isShowEditAction,
              isShowRemoveAction,
            })}
            isLoading={isLoading}
            onOrderChange={onOrderChange}
          />
        </Grid>
        <Grid item xs={12}>
          {pagnationProps && <CustomPagnation {...pagnationProps} />}
        </Grid>
      </Grid>
    );
  };

export default CustomAgentCodesTable;
