import { AxiosError, AxiosResponse } from "axios";
import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";
import { EnumType, jsonToGraphQLQuery } from "json-to-graphql-query";
import humanize from "humanize-graphql-response";
import { Fields } from "./fields";

interface Params {
  page: number;
  pageSize: number;
  farmer_id?: string;
  with_farmer?: boolean;
  phone?: string;
  firstname?: string;
  lastname?: string;
  provider_id?:string;
  type?: string[];
  note?: string;
}

interface Response {
  totalCount: number;
  callbacks: Fields[];
}
type callbackFunctionType = (
  error: AxiosError | null,
  data: Response | null
) => any;
type get_callbacks_type = (
  Params: Params,
  callback: callbackFunctionType
) => any;
const get_callbacks: get_callbacks_type = (
 Params,
  callback
) => {
  
AxiosInstance.get("/callbacks", { params: Params})
    .then((resp) =>
      callback(null, {
        callbacks: humanize(resp.data.data.all_callbacks).nodes.map((cb:any) => ({
          ...cb,
          dateCreated: cb.date_created,
          lastModified: cb.last_modified,
          Farmer: {
            id: cb.farmer_by_farmer_id.id,
            firstName: cb.farmer_by_farmer_id.first_name,
            lastName: cb.farmer_by_farmer_id.last_name,
            phone: cb.farmer_by_farmer_id.phone,
          }
        })),
        totalCount: resp.data.data.all_callbacks?.total_count,
      })
    )
    .catch((error) => callback(error, null));
};




interface UpdateParams {
    id: string;
    farmer_id?: string;
    type?: string;
    notes?: string;
}

const update_callback = (
  Params: UpdateParams,
  callback: (error: AxiosError | null, response: AxiosResponse | null) => any
): any => {
  AxiosInstance.patch("/callbacks/" + Params.id, {
    ...Params
  })
    .then((response) => callback(null, response))
    .catch((error) => {
      callback(error, null);
    });
};

export { get_callbacks, update_callback };
