import { AddQualityCheck } from "api/quality-check/add-quality-check";
import { GetQualityCheck } from "api/quality-check/get-quality-check";
import { UpdateQualityCheck } from "api/quality-check/update-quality-check";
import { CancelSuccessButtonsGroupProps } from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import { CustomDialogV2Props } from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import { option } from "components/input-components/select-menu/SelectMenu";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";

interface Params {
  onCancel?: () => any;
  open?: boolean;
}

interface DataType {
  type: "none" | "visual-check" | "aflatoxin" | "ripeness" | "size" | "moisture-content";
  status: "none" | "draft" | "published";
  report: string;
  passed: boolean;
}

export interface AddProps {
  variant: "add";
  onAdded?: () => any;
  aggregation_activity_id: string;
}
export interface UpdateProps {
  variant: "update";
  onUpdated?: () => any;
  quality_check_id: string;
}

type ParamsType = (AddProps | UpdateProps) & Params;

export const useLogic = ({ onCancel, ...Props }: ParamsType) => {
  const [IsSubmitting, SetIsSubmitting] = React.useState(false);
  const [IsLoadingData, SetIsLoadingData] = React.useState(false);

  const [initalData, SetInitalData] = React.useState<DataType>({
    type: "none",
    status: "none",
    report: "",
    passed: false,
  });

  const Validation = yup.object().shape({
    passed: yup.boolean().required(),
    report: yup.string().optional(),
    status: yup.string().required().oneOf(["draft", "published"]),
    type: yup.string().required().oneOf(["visual-check", "aflatoxin", "size", "moisture-content", "ripeness"]),
  });

  const dialogProps: CustomDialogV2Props = {
    open: Props.open ?? false,
    header: Props.variant === "add" ? "Add Quality Check" : "Edit Quality Check",
  };

  const formikForm = useFormik({
    enableReinitialize: true,
    validationSchema: Validation,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      type: "none",
      status: "none",
      report: "",
      passed: false,
    },
    onSubmit: (values, action) => {
      SetIsSubmitting(true);
      Props.variant === "add" &&
        values.status !== "none" &&
        values.type !== "none" &&
        AddQualityCheck(
          {
            aggregation_id: Props.aggregation_activity_id,
            passed: values.passed,
            type: values.type as any,
            status: values.status as any,
            report: values.report,
          },
          (error, resp) => {
            if (error) {
              console.log(error.message);
            } else if (resp) {
              SetIsSubmitting(false);
              action.resetForm();
              Props.onAdded?.();
              action.resetForm();
            }
          }
        );
      Props.variant === "update" &&
        values.type !== "none" &&
        values.status !== "none" &&
        UpdateQualityCheck(
          {
            passed: values.passed,
            type: values.type as any,
            status: values.status as any,
            report: values.report,
            id: Props.quality_check_id,
          },
          (error, resp) => {
            if (error) {
              console.log(error.message);
            } else if (resp) {
              SetIsSubmitting(false);
              action.resetForm();
              Props.onUpdated?.();
              action.resetForm();
            }
          }
        );
    },
  });

  const TypeOptions: option[] = [
    { title: "Visual Check", value: "visual-check" },
    { title: "Aflatoxin", value: "aflatoxin" },
    { title: "Moisture Content", value: "moisture-content" },
    { title: "Size", value: "size" },
    { title: "Ripeness", value: "ripeness" },
  ];

  const StatusOptions: option[] = [
    { title: "Draft", value: "draft" },
    { title: "Published", value: "published" },
  ];

  const Fields = [
    {
      name: "type",
      placeholder: "Quality Check Type",
      type: "menu",
      options: TypeOptions,
      disabled: initalData.status === "published",
      value: formikForm.values["type"],
      onChange: (e: any) => formikForm.setFieldValue("type", e.target.value),
      helperText: formikForm.errors["type"],
      error: Boolean(formikForm.errors["type"]),
    },
    {
      name: "status",
      placeholder: "Quality Check Status",
      type: "menu",
      options: StatusOptions,
      WarningOnPuplished: "This action cannot be reversed after confirmation",
      disabled: initalData.status === "published",
      value: formikForm.values["status"],
      onChange: (e: any) => formikForm.setFieldValue("status", e.target.value),
      helperText: formikForm.errors["status"],
      error: Boolean(formikForm.errors["status"]),
    },
    {
      name: "report",
      placeholder: "Insert Your Report ...",
      label: "Report",
      type: "text",
      multiline: true,
      rowsMax: 5,
      disabled: initalData.status === "published",
      value: formikForm.values["report"],
      onChange: (e: any) => formikForm.setFieldValue("report", e.currentTarget.value),
      helperText: formikForm.errors["report"],
      error: Boolean(formikForm.errors["report"]),
    },
    {
      name: "passed",
      type: "checkbox",
      label: "Is Passed?",
      disabled: initalData.status === "published",
      value: formikForm.values["passed"],
      onChange: (e: any, checked: boolean) => formikForm.setFieldValue("passed", checked),
      helperText: formikForm.errors["passed"],
      error: Boolean(formikForm.errors["passed"]),
    },
  ];

  const isStatusPublished = formikForm.values["status"] === "published";

  React.useEffect(() => {
    if (Props.open) {
      SetIsLoadingData(true);
      Props.variant === "update" &&
        GetQualityCheck({ page: 0, pageSize: 10, id: Props.quality_check_id }, (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            SetIsLoadingData(false);
            SetInitalData(resp.items[0]);
            formikForm.setValues(resp.items[0]);
          }
        });
    }
  }, [Props.variant === "update" && Props.quality_check_id, Props.open]);

  const cancelSuccessButtonGroupProps: CancelSuccessButtonsGroupProps = {
    RootGridProps: {
      maxWidth: 300,
    },
    CancelButtonProps: { onClick: onCancel, label: "Cancel" },
    SuccessButtonProps: {
      onClick: () => formikForm.submitForm(),
      label: Props.variant === "add" ? "Add" : "Save",
      disabled: IsSubmitting || initalData.status === "published",
    },
  };
  return {
    cancelSuccessButtonGroupProps,
    Fields,
    isStatusPublished,
    IsLoadingData,
    dialogProps,
  };
};
