import {
  Box,
  BoxProps,
  Grid,
  GridProps,
  OutlinedTextFieldProps,
  TextField,
} from "@mui/material";
import { FunctionComponent } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LocationFilter, {
  LocationFilterProps,
} from "components/input-components/location-filter/location-filter";
import FarmerLabelsFilter, {
  FarmerLabelsFilterProps,
} from "components/standalone-components/farmer-labels-filter/farmer-labels-filter";
import InputWithOperator, {
  InputWithOperatorProps,
} from "components/input-components/input-with-operator/input-with-operator";
import CustomChipsInputComponent, {
  CustomChipsInputComponentProps,
} from "../custom-chips-input-component/custom-chips-input-component";
import CustomMenuButton from "../custom-menu-button/custom-menu-button";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import useStyles from "./dynamic-form.styles";

export interface TextfieldProps
  extends Omit<OutlinedTextFieldProps, "variant"> {
  type: "text";
}

export interface ExtendedLocationInputProps
  extends Pick<LocationFilterProps, "menuProps" | "onChange"> {
  label: string;
}

export interface LocationInputProps extends ExtendedLocationInputProps {
  type: "location";
}

export interface LabelsInputProps extends FarmerLabelsFilterProps {
  type: "label";
}

export interface OperatorInputProps extends InputWithOperatorProps {
  type: "operator-input";
}

export interface ChipsInputProps extends CustomChipsInputComponentProps {
  type: "chips";
}

interface Input {
  gridProps?: GridProps;
  slideContainerProps?: BoxProps;
  inputProps: (
    | ChipsInputProps
    | OperatorInputProps
    | LabelsInputProps
    | LocationInputProps
    | TextfieldProps
  ) & { "data-cy"?: string };
}

interface DynamicFormProps {
  type: "grid" | "bar";
  schema: Input[];
}

const Wrapper = (props: any) => {
  const classes = useStyles();
  return props.type === "bar" ? (
    <Slider className={classes.slider} {...props}>
      {props.children}
    </Slider>
  ) : (
    <Grid container {...props}>
      {props.children}
    </Grid>
  );
};

const ItemWrapper = (props: any) =>
  props.type === "bar" ? (
    <Box {...props} px={2}>
      {props.children}
    </Box>
  ) : (
    <Grid item {...props}>
      {props.children}
    </Grid>
  );

const DynamicForm: FunctionComponent<DynamicFormProps> = (props) => {
  const { type } = props;

  const settings =
    type === "bar"
      ? {
          nextArrow: <IoChevronForwardOutline color="black" />,
          prevArrow: <IoChevronBackOutline color="black" />,
          infinite: false,
          responsive: [
            {
              breakpoint: 4024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        }
      : { spacing: 2 };

  return (
    <Wrapper {...(settings as any)} type={type}>
      {props.schema.map((item, index) => (
        <ItemWrapper key={index} type={type}>
          {item.inputProps.type === "text" && (
            <TextField
              fullWidth
              {...(item.inputProps as TextfieldProps)}
              variant="outlined"
            />
          )}

          {item.inputProps.type === "operator-input" && (
            <InputWithOperator
              fullWidth
              {...(item.inputProps as InputWithOperatorProps)}
            />
          )}

          {item.inputProps.type === "label" && (
            <FarmerLabelsFilter
              {...(item.inputProps as unknown as FarmerLabelsFilterProps)}
              fullwidth
            />
          )}

          {item.inputProps.type === "chips" && item.inputProps && (
            <CustomChipsInputComponent
              {...(item.inputProps as unknown as CustomChipsInputComponentProps)}
              textFieldProps={{
                ...(
                  item.inputProps as unknown as CustomChipsInputComponentProps
                ).textFieldProps,
                fullWidth: true,
              }}
            />
          )}

          {item.inputProps.type === "location" && (
            <LocationFilter
              {...(item.inputProps as unknown as LocationFilterProps)}
            >
              <CustomMenuButton
                fullWidth
                data-cy="farmers-location-filter-button"
              >
                {
                  (item.inputProps as unknown as ExtendedLocationInputProps)
                    .label
                }
              </CustomMenuButton>
            </LocationFilter>
          )}
        </ItemWrapper>
      ))}
    </Wrapper>
  );
};

export default DynamicForm;
