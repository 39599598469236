import { AxiosError } from "axios";
import { AxiosInstance } from "../../configs/axios";

interface Params {
  name: string;
  password: string;
  email: string;
  role: string;
  phone: string;
  token?: string;
  provider_id?: string;
  age?: number | null;
  gender?: 'male' | 'female' | null
}

type callbackType = (error: null | AxiosError, resp: null | any) => any;
type AddUserType = (Params: Params, callback: callbackType) => any;
const AddUser: AddUserType = (Params, callback) => {
  AxiosInstance.post("/users", { ...Params, phone: Params.phone || null })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { AddUser };
