import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, FunctionComponent } from "react";
import { SendersInputProps, useLogic } from "./sender-input.logic";
import { ISenderId } from "models/sender-id";
import ClearIcon from "@mui/icons-material/Clear";

interface SenderInputProps extends SendersInputProps {
  onChange?: (value: ISenderId | null) => any;
  isUpdate?: boolean;
}

const SenderInput: FunctionComponent<SenderInputProps> = (
  props: SenderInputProps
) => {
  const { options, loading, value, setsearchTerm, setValue, searchTerm } =
    useLogic(props.isUpdate ? { ...props, value: "" } : props);

  return (
    <div>
      <Autocomplete
        key={value?.id + " " + props.providerId}
        loading={loading}
        clearOnBlur={false}
        disableClearable
        onChange={(e, value) => {
          props.onChange?.(value);
          setValue(value!);
        }}
        openText={searchTerm}
        getOptionLabel={(item) => item.atSenderId}
        options={options}
        value={value}
        onInputChange={(e, value) => {
          setsearchTerm(value);
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => (
          <div>
            <TextField
              {...params}
              placeholder="Senders"
              error={props.error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                    {value && (
                      <ClearIcon
                        onClick={() => {
                          setsearchTerm("");
                          props.onChange?.(null);
                          setValue(undefined);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </Fragment>
                ),
              }}
            />
            {props.error && (
              <Box mx={1.5}>
                <Typography color="error" variant="caption">
                  {props.error}
                </Typography>
              </Box>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default SenderInput;
