import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { BroadcastMessage } from "../../models/broadcasts-message";

export type ParamsUnion = Params & (AbsoluteTimeParams | RelativeTimeParams);
interface Params
  extends Pick<
    BroadcastMessage,
    "subject" | "message" | "id" | "broadcast_id" | "is_active"
  > {
  sender_id?: string
}

interface RelativeTimeParams
  extends Pick<
    BroadcastMessage,
    "relative_send_minute" | "relative_send_hour" | "send_on_day_relative"
  > {
  type: "relative";
}

interface AbsoluteTimeParams
  extends Pick<BroadcastMessage, "send_on_day_absolute"> {
  type: "absolute";
}

interface Resp { }
type UpdateBroadcastsMessagesType = (
  Params: ParamsUnion,
  callback: (Error: null | AxiosError, Resp: null | Resp) => any
) => any;
const UpdateBroadcastsMessages: UpdateBroadcastsMessagesType = (
  Params,
  callback
) => {
  AxiosInstance.patch("/sms-broadcast-messages/" + Params.id, {
    ...Params,
  })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => callback(error, null));
};

export { UpdateBroadcastsMessages };
