import * as L from "leaflet";
import "proj4leaflet";
import "proj4";

const EPSG_32736 = new L.Proj.CRS("EPSG:32736", "+proj=utm +zone=36 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs");

const EPSG_3857 = new L.Proj.CRS("EPSG:3857", "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs");

const EPSG_32636 = new L.Proj.CRS("EPSG:32636", "+proj=utm +zone=36 +ellps=WGS84 +datum=WGS84 +units=m +no_defs", {});
const EPSG_32637 = new L.Proj.CRS("EPSG:32637", "+proj=utm +zone=37 +ellps=WGS84 +datum=WGS84 +units=m +no_defs");
const EPSG_32737 = new L.Proj.CRS("EPSG:32737", "+proj=utm +zone=37 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs");

export { EPSG_32736, EPSG_32636, EPSG_32637, EPSG_32737, EPSG_3857 };
