import ReactFlow, { MiniMap, Controls, ReactFlowProps, Position } from "react-flow-renderer";
import { useLogic } from "./organizations-tree-diagram.logic";
import dagre from "dagre";
import { Box, Paper } from "@mui/material";

interface OrganizationsTreeDiagramProps {}

const OrganizationsTreeDiagram: React.FunctionComponent<OrganizationsTreeDiagramProps> = () => {
  const { nodes, edges } = useLogic();

  const dagreGraph = new dagre.graphlib.Graph();

  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 172;

  const nodeHeight = 36;

  const getLayoutedElements = (nodes: ReactFlowProps["nodes"], edges: ReactFlowProps["edges"], direction = "TB") => {
    const isHorizontal = direction === "LR";

    dagreGraph.setGraph({ rankdir: direction });

    nodes?.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges?.forEach((edge) => {
      dagreGraph.setEdge(edge.target, edge.source);
    });

    dagre.layout(dagreGraph);

    nodes?.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? Position.Left : Position.Bottom;
      node.sourcePosition = isHorizontal ? Position.Right : Position.Top;

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });

    return { nodes, edges };
  };

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges);

  return (
    <Box component={Paper}>
      <div id="treeWrapper" style={{ height: "50em" }}>
        <ReactFlow nodes={layoutedNodes} edges={layoutedEdges} fitView={true}>
          <MiniMap />
          <Controls showInteractive={false} />
        </ReactFlow>
      </div>
    </Box>
  );
};

export default OrganizationsTreeDiagram;
