import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../../models/user";

export interface AppState {
  IscheckingUser: boolean;
  user?: IUser;
  isDrawerOpen?: boolean;
  mapApiKey: string;
}

const initialState: AppState = {
  IscheckingUser: true,
  isDrawerOpen: false,
  mapApiKey: "VTzx86MpNi5BZ2Wg9v2caL6vZ_lH9b7SOn5ZnTneDj4",
};

export const appSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    removeUser: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.user = undefined;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    SetIsCheckingUser: (state, action: PayloadAction<boolean>) => {
      state.IscheckingUser = action.payload;
    },
    openDrawer: (state) => {
      state.isDrawerOpen = true;
    },
    closeDrawer: (state) => {
      state.isDrawerOpen = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  removeUser,
  SetIsCheckingUser,
  setUser,
  openDrawer,
  closeDrawer,
} = appSlice.actions;

export default appSlice.reducer;
