import { List } from "@mui/material";
import SideMenuSubItem, {
  SideMenuSubItemProps,
} from "../side-menu-sub-item/side-menu-sub-item";
import useStyles from "./side-menu-sub-list.style";

export interface SideMenuSubListProps {
  data?: SideMenuSubItemProps[];
}

const SideMenuSubList: React.FunctionComponent<SideMenuSubListProps> = (
  props
) => {
  const classes = useStyles();
  const { data } = props;
  return (
    <List className={[classes.root].join(" ")}>
      {data?.map((item, key) => (
        <SideMenuSubItem key={key}  {...item} />
      ))}
    </List>
  );
};

export default SideMenuSubList;
