import { Box, Button, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
import { IoOpenOutline } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { Activity } from "models/activity";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
  selectedFarmersIds?: string[];
  selectedByPhone?: boolean;
}
export interface Fields extends Activity {
  selected: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onViewButtonClick?: (data: Fields) => any;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (
  params
) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "Activity",
      field: "activity",
      hidden: !params.columnState?.find((item) => item.field === "activity")
        ?.checked,
      render: (data) => data.activity,
    },
    {
      title: "Date",
      field: "date",
      hidden: !params.columnState?.find((item) => item.field === "date")
        ?.checked,
      render: (data) => `${data.year}/${data.month}/${data.day}`,
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: (
        <CustomCheckBox
          onChange={(e, value) =>
            params.customSelection?.onSelectAllChange?.(params.data, value)
          }
          checked={params.data.every((item) => item.selected)}
          indeterminate={
            params.data.some((item) => item.selected) &&
            !params.data.every((item) => item.selected)
          }
        />
      ),
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => (
        <CustomCheckBox
          checked={data.selected}
          onChange={(e, value) =>
            params.customSelection?.onRowSelectChange?.(data, value)
          }
        />
      ),
    });
  }

  return {
    actions: [
      (fields) => ({
        icon: () => (
          <Button
            variant="text"
            startIcon={<IoOpenOutline />}
            onClick={() => params.onViewButtonClick?.(fields)}
            color="success"
          >
            View
          </Button>
        ),
        onClick: () => null,
      }),
    ],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
