import { GetSenderIds } from "api/senders/get-senders-v2";
import { useDebounce } from "hooks/debounce-hook";
import { ISenderId } from "models/sender-id";
import { IUser } from "models/user";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useAppSelector } from "store/hooks";


export interface SendersInputProps {
  placeholder?: string;
  error?: boolean;
  providerId?: string;
  senderType?: "Africastalking_sender" | "Infobip_sender"
  value: string | any;
  onInitialValues?: (sender: ISenderId) => any
  isInitialLoad?: boolean;
}

export const useLogic = (params: SendersInputProps) => {

  const user = useAppSelector((root) => root.appReducer.user as IUser);

  const [value, setValue] = useState<ISenderId | undefined>();

  const [searchTerm, setsearchTerm] = useState("");

  const debonucedTerm = useDebounce(searchTerm, 800);

  const fetchSenders = async () => {
    return await new Promise((resolve, resject) => {
      GetSenderIds(
        {
          page: 0,
          pageSize: 10,
          provider_id: params.providerId || (user?.menuroles !== 'admin' ? user?.provider_id : undefined),
          sender_type: params.senderType,
          at_sender_id: debonucedTerm,
          status: true
        },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else {
            let defaultFirstSender = resp?.allSenders.nodes[0];

            if (params.onInitialValues && defaultFirstSender && params.isInitialLoad) {
              params.onInitialValues(defaultFirstSender)
              setValue(defaultFirstSender)
            }
            resolve(resp?.allSenders.nodes!)
          }
        }
      );
    })
  }

  const res = useQuery(['senders', debonucedTerm], fetchSenders);


  return { options: (res.data || []) as ISenderId[], setsearchTerm, loading: res.isLoading, value, setValue, searchTerm };
};
