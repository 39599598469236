import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
interface Params {
  provider_id?: string;
  code: string;
  comment: string;
}


type AddProviderType = (
  Params: Params,
  callback: (error: AxiosError | null, resp: null | any) => any
) => any;
export const AddProviderCode: AddProviderType = (Params, callback) => {
  AxiosInstance.post("provider-codes", { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};
