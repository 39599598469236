import { GetProvidersCode } from "api/providers-code/get-providers-code";
import { CustomAgentCodesTableProps } from "components/custom-compnents/custom-agent-codes-table/custom-agent-codes-table";
import { CombinedType } from "components/standalone-components/add-update-agent-code/add-update-agent-code";
import { RemoveAgentCodeComponentProps } from "components/standalone-components/remove-agent-code-component/remove-agent-code-component";
import { IProviderCode } from "models/provider-code";
import React from "react";
import { useAppSelector } from "store/hooks";

export const useLogic = () => {
  const user = useAppSelector((root) => root.appReducer.user);
  const [codeDataToBeUpdated, setCodeDataToBeUpdated] = React.useState<undefined | IProviderCode>(undefined);
  const [isShowAddDialog, setIsShowAddDialog] = React.useState(false);
  const [codeDataToBeDeleted, setCodeDataToBeDeleted] = React.useState<undefined | IProviderCode>(undefined);
  const [isShowFilterArea, setIsShowFilterArea] = React.useState(false);
  const [codeTextFilter, setCodeTextFilter] = React.useState("");
  const [providerTextFilter, setProviderTextFilter] = React.useState("");
  const [commentTextFilter, setCommentTextFilter] = React.useState("");
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [data, setData] = React.useState<IProviderCode[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [columnsState, setColumnsState] = React.useState([
    { field: "code", checked: true },
    { field: "provider", checked: true },
    { field: "comment", checked: true },
  ]);

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const isEmptyFilter = !codeTextFilter && !commentTextFilter && !providerTextFilter;

  const handleFilterButtonClick = () => {
    setIsShowFilterArea(!isShowFilterArea);
  };

  const handleApplyFilterClick = () => {
    refreshData();
    setCurrentPage(0);
  };

  const resetFilters = () => {
    setCodeTextFilter("");
    setCommentTextFilter("");
    setProviderTextFilter("");
  };

  const handleCancelFilterButtonClick = () => {
    resetFilters();
    setIsShowFilterArea(false);
    refreshData();
  };

  const handleCancelDelete = () => setCodeDataToBeDeleted(undefined);

  const handleDeleted = () => {
    setCodeDataToBeDeleted(undefined);
    refreshData();
  };

  const handleCancelAdd = () => setIsShowAddDialog(false);

  const handleAdded = () => {
    setIsShowAddDialog(false);
    refreshData();
  };

  const handleUpdated = () => {
    setCodeDataToBeUpdated(undefined);
    refreshData();
  };

  const handleCancelUpdate = () => {
    setCodeDataToBeUpdated(undefined);
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetProvidersCode(
      // page: number;
      // pageSize: number;
      // provider_id?: string;
      // code_exact?: string;
      // code_include?: string;
      // comment?: string;
      {
        page: currentPage,
        pageSize: perPage,
        code_include: codeTextFilter,
        provider_id: user?.menuroles !== "admin" ? user?.provider_id! : undefined,
      },
      (error, resp) => {
        setIsLoading(false);
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setData(resp.provider_codes);
          setTotalCount(resp.totalCount);
        }
      }
    );
  }, [currentPage, perPage, dataUpdateIndex]);

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  const tableProps: CustomAgentCodesTableProps = {
    onEditButtonClick: setCodeDataToBeUpdated,
    onDeleteButtonClick: setCodeDataToBeDeleted,
    showFilterArea: isShowFilterArea,
    filterContainerProps: {
      CancelSuccessButtonGroupProps: {
        SuccessButtonProps: {
          disabled: isEmptyFilter,
          onClick: handleApplyFilterClick,
        },
        CancelButtonProps: {
          onClick: handleCancelFilterButtonClick,
        },
      },
    },
    filterFormProps: {
      isShowCodeFilter: true,
      isShowCommentFilter: true,
      isShowProviderFilter: true,
      codeFilterProps: {
        value: codeTextFilter,
        onChange: (e) => setCodeTextFilter(e.currentTarget.value),
        "data-cy": "filter-agent-code-code-field"
      },
      commentFilterProps: {
        value: commentTextFilter,
        onChange: (e) => setCommentTextFilter(e.currentTarget.value),
        "data-cy": "filter-agent-code-comment-field"
      },
      providerFilterProps: {
        value: providerTextFilter,
        onChange: (e) => setProviderTextFilter(e.currentTarget.value),
        "data-cy": "filter-agent-code-provider-field"
      },
    },
    isLoading: isLoading,
    data: preparedData,
    countLabelProps: {
      count: totalCount,
    },
    isShowEditAction: true,
    isShowRemoveAction: true,

    actionBarProps: {
      addButtonProps: {
        onClick: () => setIsShowAddDialog(true),
        "data-cy": "add-agent-code-button"
      },
      filterButtonProps: {
        onClick: handleFilterButtonClick,
        disabled: !isEmptyFilter,
        "data-cy": "filter-agent-code-button"
      },
      isShowFilterButton: true,
      isShowCreateButton: true,
      isShowColumnButton: true,
      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
    },
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };

  const deleteDialogProps: undefined | RemoveAgentCodeComponentProps = codeDataToBeDeleted?.id
    ? {
      id: codeDataToBeDeleted?.id,
      onCancel: handleCancelDelete,
      onDeleted: handleDeleted,
      open: !!codeDataToBeDeleted?.id,
    }
    : undefined;

  const addDialogProps: CombinedType = {
    Variant: "add",
    onCancel: handleCancelAdd,
    onAdded: handleAdded,
    open: isShowAddDialog,
  };

  const updateDialogProps: CombinedType | undefined = codeDataToBeUpdated?.id
    ? {
      Variant: "update",
      onCancel: handleCancelUpdate,
      onUpdate: handleUpdated,
      open: !!codeDataToBeUpdated.id,
      AgentCodeObject: codeDataToBeUpdated,
    }
    : undefined;
  return { tableProps, deleteDialogProps, addDialogProps, updateDialogProps };
};
