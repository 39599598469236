import {
  Autocomplete,
  AutocompleteChangeReason,
  TextField,
} from "@mui/material";
import { ICountyLocation } from "models/county-location";
import { useLogic } from "./county-input.logic";

interface CountyInputProps {
  placeholder?: string;
  value?: ICountyLocation;
  onChange?: (
    value: ICountyLocation | null,
    reason: AutocompleteChangeReason
  ) => any;
}

const CountyInput: React.FunctionComponent<CountyInputProps> = (props) => {
  const { placeholder, value, onChange } = props;
  const { counties, handleInputChange } = useLogic();
  return (
    <Autocomplete
      renderInput={(props) => (
        <TextField {...props} placeholder={placeholder} />
      )}
      getOptionLabel={(item) => item.county}
      onInputChange={(e, value) => handleInputChange(value)}
      value={value}
      options={counties}
      onChange={(e, value, reason) => onChange?.(value, reason)}
    />
  );
};

export default CountyInput;

CountyInput.defaultProps = {
  placeholder: "Search County",
};
