import * as React from "react";
import CustomDialog from "components/custom-compnents/custom-dialog/custom-dialog";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import { Box } from "@mui/material";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";

export interface ConfirmChangeModalProps {
  ServiceName?: string;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  DisableConfirm?: boolean;
}

const ConfirmChangeModal: React.SFC<ConfirmChangeModalProps> = ({
  ServiceName,
  onCancel,
  open,
  onConfirm,
  DisableConfirm,
}) => {
  return (
    <CustomDialogV2
      header="Require Location"
      open={open}
      footer={
        <Box display="flex" justifyContent="flex-end">
          <CancelSuccessButtonsGroup
            RootGridProps={{ width: 300 }}
            CancelButtonProps={{ label: "Cancel", onClick: onCancel }}
            SuccessButtonProps={{
              label: "Confirm",
              onClick: onConfirm,
              disabled: DisableConfirm,
            }}
          />
        </Box>
      }
    >
      {/* <CustomDialog
        open={open}
        Header="Require Location"
        ActionButtonsProps={{
          DisableConfirm: DisableConfirm,
          ButtonsVariant: "ConfirmCancel",
          OnCancel: onCancel,
          OnConfirm: onConfirm,
        }}
      > */}
      Are you sure you want to make this change for {ServiceName} Service?
      {/* </CustomDialog> */}
    </CustomDialogV2>
  );
};

export default ConfirmChangeModal;
