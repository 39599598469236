import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    borderRadius: "6px",
    backgroundColor: "#fff",
    "& fieldset": {
      border: "solid 1px #c7d0d1",
    },
  },
  InputRoot: {
    height: "44px",
    borderRadius: "6px",
    // overflow: "hidden",
  },
  inputRoot: {
    height: "9px",
    borderRadius: "6px",
  },
});
