import * as yup from "yup";

interface IParams {
  validateSender?: boolean;
}

export const validationSchema = (params: IParams) =>
  yup.object().shape({
    message: yup.string().required(),
    receivers: yup.array().min(1, "Please select at least one recipient."),
    sender_id: params.validateSender ? yup.string().required("Please select a sender.") : yup.string().optional(),
    datePickerData: yup.mixed().test("datePickerData", "The Scheduled date must be in the future.", (value) => {
      if (value) {
        const schema = yup.date().min(new Date(Date.now()), "The Scheduled date must be in the future.");
        return schema.isValidSync(value);
      }
      return true;
    }),
  });
