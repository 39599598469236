import { Box, Typography } from "@mui/material";
import { Alert } from "@mui/material";
import React, { useState } from "react";
import { RemoveProvider } from "api/provider/remove-provider";
import { IProvider } from "models/provider";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import { IoWarningSharp } from "react-icons/io5";
import CancelDeleteButtonsGroup from "components/custom-compnents/cancel-delete-buttons-group/cancel-delete-buttons-group";

export interface RemoveProviderModalProps {
  open: boolean;
  Provider?: IProvider;
  onCancel: () => any;
  onDelete: () => any;
}

const RemoveProviderModal: React.SFC<RemoveProviderModalProps> = ({
  open,
  Provider,
  onCancel,
  onDelete,
}) => {
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [Errors, setErrors] = useState("");
  const HandleConfirmRemove = () => {
    setErrors("");
    setIsSubmitting(true);
    if (Provider)
      RemoveProvider({ id: Provider?.id }, (error, resp) => {
        setIsSubmitting(false);
        if (error) {
          if (error?.response?.status === 422) {
            setErrors(error?.response?.data?.message)
          } else {
            setErrors(error.response?.data.errors);
          }
        } else {
          onDelete();
        }
      });
  };
  return (
    <CustomDialogV2
      open={open}
      header={
        <Typography variant="h6" display="inline-flex">
          <IoWarningSharp size={22} style={{ marginRight: 5 }} />
          Delete a Organization
        </Typography>
      }
      footer={
        <Box display="flex" justifyContent="flex-end">
          <CancelDeleteButtonsGroup
            RootGridProps={{ width: 300 }}
            CancelButtonProps={{
              label: "Cancel",
              onClick: () => {
                onCancel();
                setErrors("");
              },
            }}
            DeleteButtonProps={{
              disabled: IsSubmitting,
              onClick: HandleConfirmRemove,
              label: "Delete Organization",
            }}
          />
        </Box>
      }
    >
      {/* <CustomDialog
        open={open}
        Header="Remove Provider"
        ActionButtonsProps={{
          ButtonsVariant: "ConfirmCancel",
          OnCancel: () => {
            onCancel();
            setErrors("");
          },
          OnConfirm: HandleConfirmRemove,
          DisableConfirm: IsSubmitting,
        }}
      > */}
      <Typography variant="subtitle1">
        Are You Sure You want to Delete{" "}
        <mark style={{ background: "none", fontWeight: 600 }}>
          {Provider?.name}
        </mark>{" "}
        Organization?
      </Typography>
      {Errors && (
        <Box mt={2}>
          <Alert severity="error">{Errors}</Alert>
        </Box>
      )}
      {/* </CustomDialog> */}
    </CustomDialogV2>
  );
};

export default RemoveProviderModal;
