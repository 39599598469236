import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Survey } from "models/survey";
interface Resp extends Survey {}

interface Params {
  page: number;
  pageSize: number;
  survey_id_list?: string[];
  is_deleted?: boolean;
  provider_id_list?: string[];
}
type GetSurveyByIdType = (
  Params: Params,
  callback: (error: AxiosError | null, Resp: Resp | null) => any
) => any;
const GetSurveyById: GetSurveyByIdType = (Params, callback) => {
  AxiosInstance.get("/surveys", { params: Params})
    .then((resp) => callback(null, resp.data.items[0]))
    .catch((error) => callback(error, null));
};

export { GetSurveyById };
