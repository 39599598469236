import { GetFarmers } from "api/farmer/get-farmer";
import { Fields } from "components/custom-compnents/custom-farmers-table/custom-farmers-table.schema";
import React from "react";
import { useSnackbar } from "notistack";
import { CSVReaderProps } from "react-csv-reader";
import { InputWithOperatorElementValue } from "components/input-components/input-with-operator/input-with-operator";
import { FilterState } from "components/input-components/location-filter/filter-menu";
import { isEmpty } from "lodash";
import { CustomFarmersTableActionBarProps } from "components/custom-compnents/custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { CustomFarmersTableProps } from "components/custom-compnents/custom-farmers-table/custom-farmers-table";
import { OrderByObject } from "./order-by-object";
import { useAppSelector } from "store/hooks";
import { IoCloudUpload } from "react-icons/io5";
import { KeyValues } from "components/custom-compnents/farmer-labels-filter/farmer-labels-filter";
import { Ward } from "models/ward";
import { Constituency } from "models/constituency";
import { Region } from "models/region";
import { County } from "models/county";

export interface selectedFarmersIds {
  selectedFarmersIds?: string[];
  selectedFarmersPhones?: string[];
}

export interface IParams extends selectedFarmersIds {
  Proiders_Ids?: string[];
}

export const useLogic = ({ selectedFarmersIds, selectedFarmersPhones, Proiders_Ids }: IParams) => {
  const [labelsFilterValue, setLabelsFilterValue] = React.useState<KeyValues[]>([]);

  const [onlySelectedFarmers, setOnlySelectedFarmers] = React.useState(false);

  const [data, setData] = React.useState<Fields[]>([]);

  const [isOpenAddFarmerLabels, setIsOpenAddFarmerLabels] = React.useState(false);

  const [isDataLoading, setIsDataLoading] = React.useState(false);

  const [total, setTotal] = React.useState(0);

  const [currentPage, setCurrentPage] = React.useState(1);

  const [pageSize, setPageSize] = React.useState(10);

  const [openAddFarmer, setOpenAddFarmer] = React.useState(false);

  const isLastPage = total <= pageSize * currentPage;

  const isFirstPage = currentPage - 1 === 0;

  const [isOpenFilterArea, setIsOpenFilterArea] = React.useState(false);

  const handleFilterButtonClick = () => setIsOpenFilterArea(true);

  const [phoneNumbersFilterValue, setPhoneNumbersFilterValue] = React.useState<string[]>([]);

  const [cropsFilterValue, setCropsFilterValue] = React.useState<string[]>([]);

  const [farmerFirstNameFilterValue, setFarmerFirstNameFilterValue] = React.useState("");

  const [farmerLastNameFilterValue, setFarmerLastNameFilterValue] = React.useState("");

  const [isOpenAddBulkFarmers, setIsOpenAddBulkFarmers] = React.useState(false);

  const [farmCountFilterValue, setFarmCountFilterValue] = React.useState<undefined | InputWithOperatorElementValue>();

  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);

  const [isApplyFilter, setIsApplyFilter] = React.useState<number | false>(false);

  const [locationFilterValue, setLocationFilterValue] = React.useState<FilterState>({
    SelectedRegions: [],
    SelectedConstituencies: [],
    SelectedCounties: [],
    SelectedWards: [],
  });

  const [columnsState, setCoulmnsState] = React.useState<{ field: string; checked: boolean }[]>([
    {
      checked: true,
      field: "first_name",
    },
    {
      checked: true,
      field: "last_name",
    },
    {
      field: "phone",
      checked: true,
    },
    {
      field: "location",
      checked: true,
    },
    {
      field: "farms_count",
      checked: false,
    },
    {
      field: "labels",
      checked: false,
    },
    {
      field: "crops",
      checked: false,
    },
    {
      field: "source",
      checked: false,
    },
    {
      field: "acceptedTermsAt",
      checked: false,
    },
  ]);

  const isFiltersEmpty =
    !farmCountFilterValue &&
    !farmerFirstNameFilterValue &&
    !farmerLastNameFilterValue &&
    cropsFilterValue.length === 0 &&
    phoneNumbersFilterValue.length === 0 &&
    locationFilterValue?.SelectedConstituencies?.length === 0 &&
    locationFilterValue?.SelectedCounties?.length === 0 &&
    locationFilterValue?.SelectedRegions?.length === 0 &&
    locationFilterValue?.SelectedWards?.length === 0 &&
    labelsFilterValue.length === 0;

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseAddFarmerModal = () => setOpenAddFarmer(false);

  const handleFarmerAdded = () => {
    setOpenAddFarmer(false);
    window.location.reload();
  }

  const handleAddFarmerClicked = () => setOpenAddFarmer(true);

  const user = useAppSelector((root) => root.appReducer.user);

  const handlefarmCountFilterChange = (value: InputWithOperatorElementValue) => setFarmCountFilterValue(value);

  const handleFarmerFirstNameFilterChange = (e: any) => setFarmerFirstNameFilterValue(e.currentTarget.value);

  const handleFarmerLastNameFilterChange = (e: any) => setFarmerLastNameFilterValue(e.currentTarget.value);

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const handleFilterCancelClick = () => {
    setCropsFilterValue([]);
    setFarmerFirstNameFilterValue("");
    setFarmerLastNameFilterValue("");
    setFarmCountFilterValue(undefined);
    setPhoneNumbersFilterValue([]);
    setLabelsFilterValue([]);
    setLocationFilterValue({
      SelectedRegions: [],
      SelectedConstituencies: [],
      SelectedCounties: [],
      SelectedWards: [],
    });
    setIsOpenFilterArea(false);
    setIsApplyFilter(false);
  };

  const [orderByData, setOrderByData] = React.useState<{ index: number; orderDirection: "asc" | "desc" } | undefined>(undefined);

  const handlePhoneInputChange = (e: any, value: string[]) => {
    setPhoneNumbersFilterValue(value);
  };

  const CsvReaderProps: CSVReaderProps = {
    inputId: "csvPhoneInputFarmersTableMessagePage",
    inputName: "csvInput",
    inputStyle: { display: "none" },
    onFileLoaded: (data) => {
      if (data?.length > 500) {
        enqueueSnackbar("Can't Select More Than 500 Item", {
          variant: "error",
        });
      } else {
        setPhoneNumbersFilterValue(data.map((item) => item[0]));
      }
      (document.getElementById("csvPhoneInputFarmersTableMessagePage") as HTMLInputElement).value = "";
    },
  };

  const handleNextPageClick = () => !isLastPage && setCurrentPage(currentPage + 1);

  const handlePerSizeChange = (value: number, index: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePreviousPageClick = () => !isFirstPage && setCurrentPage(currentPage - 1);

  const handleAddPhonesFilelicked = () => (document.querySelector("input[name=csvInput]") as HTMLElement).click();

  const handleFarmersAddDialogClose = () => setIsOpenAddBulkFarmers(false);

  const handleBulkFarmersAdded = () => {
    refreshData();
    setIsOpenAddBulkFarmers(false);
  };

  const handleBulkFarmerDialogOpen = () => setIsOpenAddBulkFarmers(true);

  const handleFarmerPhoneFilterValueAdd = (value: string) => setPhoneNumbersFilterValue([...phoneNumbersFilterValue, value]);

  const handleFarmerPhoneFilterValueRemove = (value: string) => setPhoneNumbersFilterValue(phoneNumbersFilterValue.filter((item) => item !== value));

  const handleCropsFilterValueAdd = (value: string) => {
    setCropsFilterValue([...cropsFilterValue, value]);
  };

  const handleCropsFilterValueRemove = (value: string) => setCropsFilterValue(cropsFilterValue.filter((item) => item !== value));

  const handleOrderChange: CustomFarmersTableProps["onOrderChange"] = (index, orderDirection) => {
    if (index > -1) setOrderByData({ index, orderDirection });
  };

  const handleLocationFilterChange = (value: any) => {
    setLocationFilterValue(value);
  };

  const handleApplyFilterButtonClick = () => {
    setIsApplyFilter(typeof isApplyFilter == "number" ? isApplyFilter + 1 : 1);
    setCurrentPage(1);
    if (isFiltersEmpty) setIsOpenFilterArea(false);
  };

  const handleCropFilterValueChange = (e: any, value: string[]) => {
    setCropsFilterValue(value);
  };

  const handleShowOnlySelectedFarmersButtonClick = () => {
    setOnlySelectedFarmers(!onlySelectedFarmers);
  };

  const handleBulkLabelsAdded = () => {
    setIsOpenAddFarmerLabels(false);
    refreshData();
  };

  const handleOpenBulkLabelsUpload = () => setIsOpenAddFarmerLabels(true);

  const handleCancelBulkLabelsAdd = () => {
    setIsOpenAddFarmerLabels(false);
  };

  const handleColumnsStateChange: CustomFarmersTableActionBarProps["onColumnsStateChange"] = (column, state) => {
    const newColumnsState = columnsState.map((item) => item);
    const columnIndex = newColumnsState.findIndex((item) => item.field === column);
    newColumnsState[columnIndex] = {
      ...newColumnsState[columnIndex],
      checked: state,
    };

    setCoulmnsState(newColumnsState);
  };

  const HandleAddPhonesFilelicked = () => (document.querySelector("input[name=csvInput]") as HTMLElement).click();


  type LocationFilterType = {
    WardsIds?: string[];
    CounstituenciesIds?: string[];
    CountiesIds?: string[];
    RegionsIds?: string[];
  }

  let locationFilterParams: LocationFilterType = {};



  if (locationFilterValue && !isEmpty(locationFilterValue)) {
    const {
      SelectedRegions,
      SelectedCounties,
      SelectedConstituencies,
      SelectedWards
    } = locationFilterValue;

    locationFilterParams.WardsIds = SelectedWards.length ? SelectedWards.map((item: Ward) => item.wardId) : undefined;

    locationFilterParams.CounstituenciesIds = SelectedConstituencies.length ? SelectedConstituencies.map((item: Constituency) => item.constituencyId) : undefined;

    locationFilterParams.CountiesIds = SelectedCounties.length ? SelectedCounties.map((item: County) => item.countyId) : undefined;

    locationFilterParams.RegionsIds = SelectedRegions.length ? SelectedRegions.map((item: Region) => item.regionId) : undefined;
  }




  var providersIdsArray = Proiders_Ids;
  if (user?.menuroles !== "admin" && user?.provider_id) providersIdsArray = [user?.provider_id];

  React.useEffect(() => {
    setIsDataLoading(true);
    GetFarmers(
      {
        ...(isApplyFilter
          ? {
            Phones: phoneNumbersFilterValue,

            FirstName: farmerFirstNameFilterValue,
            LastName: farmerLastNameFilterValue,
            FarmsCount: farmCountFilterValue && (farmCountFilterValue.value as any),
            FarmsOperator: farmCountFilterValue && (farmCountFilterValue.operator as any),
            Crops: cropsFilterValue,
            ...locationFilterParams,
          }
          : {}),
        labels: labelsFilterValue.map((item) => ({
          key_id: item.key.id,
          value: item.values.map((item) => item.label)
        }))
        ,
        Ids: onlySelectedFarmers ? selectedFarmersIds : undefined,
        ForceKeepEmptyArrayQuery: onlySelectedFarmers,
        Providers_Ids: providersIdsArray,
        page: currentPage - 1,
        pageSize,
        orderBy: typeof orderByData?.index === "number" ? (OrderByObject as any)[orderByData?.index!][orderByData?.orderDirection] : undefined,
        with_farms: true,
        with_providers: true,
        with_farms_count: true,
        with_farmer_labels: true,
        with_location: true,
        with_farm_activity_crops: true,
      },
      (error, resp) => {
        if (error) {
        } else if (resp) {
          setIsDataLoading(false);
          setData(
            resp.Farmers.map((item) => ({
              ...item,
              selected: false,
            }))
          );
          setTotal(resp.totalCount);
        }
      }
    );
  }, [pageSize, currentPage, onlySelectedFarmers, isApplyFilter, orderByData?.index, orderByData?.orderDirection, dataUpdateIndex]);

  const preparedData = data.map((item) => ({
    ...item,
    selected: Boolean(selectedFarmersIds?.find((selectedFarmeritem) => selectedFarmeritem === item.id)) || Boolean(selectedFarmersPhones?.find((selectedFarmeritem) => selectedFarmeritem === item.phone)),
  }));

  const PhonesFilterRightButtonProps = {
    content: <IoCloudUpload color="#5d6d6e" />,
    ButtonProps: {
      onClick: HandleAddPhonesFilelicked,
    },
  };

  return {
    data: preparedData,
    total,
    pageSize,
    currentPage,
    handleNextPageClick,
    handlePreviousPageClick,
    isLastPage,
    isFirstPage,
    handlePerSizeChange,
    openAddFarmer,
    handleCloseAddFarmerModal,
    handleFarmerAdded,
    handleAddFarmerClicked,
    isOpenFilterArea,
    handleFilterButtonClick,
    CsvReaderProps,
    phoneNumbersFilterValue,
    handleAddPhonesFilelicked,
    isOpenAddBulkFarmers,
    handleFarmersAddDialogClose,
    handleBulkFarmersAdded,
    handleBulkFarmerDialogOpen,
    farmerFirstNameFilterValue,
    farmerLastNameFilterValue,
    handleFarmerFirstNameFilterChange,
    handleFarmerLastNameFilterChange,
    handleFarmerPhoneFilterValueAdd,
    handleFarmerPhoneFilterValueRemove,
    handleCropsFilterValueAdd,
    handleCropsFilterValueRemove,
    cropsFilterValue,
    farmCountFilterValue,
    handlefarmCountFilterChange,
    handleLocationFilterChange,
    isFiltersEmpty,
    isApplyFilter,
    handleFilterCancelClick,
    handleApplyFilterButtonClick,
    isDataLoading,
    columnsState,
    handleColumnsStateChange,
    handlePhoneInputChange,
    handleCropFilterValueChange,
    handleOrderChange,
    PhonesFilterRightButtonProps,
    onlySelectedFarmers,
    handleShowOnlySelectedFarmersButtonClick,
    setLabelsFilterValue,
    labelsFilterValue,
    isOpenAddFarmerLabels,
    handleBulkLabelsAdded,
    handleCancelBulkLabelsAdd,
    handleOpenBulkLabelsUpload,
    user,
  };
};
