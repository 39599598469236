import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  customInputRoot: {
    "& .MuiOutlinedInput-root": {
      borderRight: "none",
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  operatorWrapper: {
    borderRadius: "6px",
    border: "solid 1px #dbdbdb",
    backgroundColor: "#dbdbdb",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    display: "flex",
    alignItem: "center",
    paddingInline: 16,
    color: "#5d6d6e",
  },
});
