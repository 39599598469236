import { Box } from "@mui/material";
import React from "react";
import MessageHistoryTable from "components/data-tables/message-history-table/message-history-table";

export interface MessageHistoryTabBoxProps {
  farmer_phone?: string;
}

const MessageHistoryTabBox: React.FC<MessageHistoryTabBoxProps> = ({
  farmer_phone,
}) => {
  return (
    <Box>
      <MessageHistoryTable by_farmers_phone={farmer_phone} />
    </Box>
  );
};

export default MessageHistoryTabBox;
