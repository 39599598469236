import { AxiosInstance } from "configs/axios";
import { IWhatsappMessage } from "models/whatsapp-message";

interface Params extends Pick<IWhatsappMessage, "whatsapp_template_id"> {
  sender_id?: string;
  phone_numbers: string[];
  content: string;
}

const AddWhatsappMessage = (Params: Params) => AxiosInstance.post("/whatsapp-messages", {
  ...Params
}, {
  params: {
    sender_id: Params.sender_id
  }
});

export { AddWhatsappMessage };
