import { Alert, Box, Grid } from "@mui/material";
import CancelDeleteButtonsGroup from "components/custom-compnents/cancel-delete-buttons-group/cancel-delete-buttons-group";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import ProvidersInput from "components/input-components/providers-input/providers-input";
import { IParams, useLogic } from "./disassociate-organization-dialog.logic";

export interface DisassociateOrganizationDialogProps extends IParams {}

const DisassociateOrganizationDialog: React.FunctionComponent<DisassociateOrganizationDialogProps> =
  (props) => {
    const { form, handleCancel, isLoadingData, errors } = useLogic(props);

    return (
      <CustomDialogV2
        open={props.open ?? false}
        header="Disassociate From Organization"
        footer={
          <Box display="flex" justifyContent="flex-end">
            <CancelDeleteButtonsGroup
              RootGridProps={{ width: 300 }}
              CancelButtonProps={{ label: "Cancel", onClick: handleCancel }}
              DeleteButtonProps={{
                disabled: form.isSubmitting,
                label: "Disassociate",
                onClick: () => form.submitForm(),
              }}
            />
          </Box>
        }
      >
        <Box width={500} my={1}>
          <Grid container spacing={2}>
            {isLoadingData && (
              <Grid item xs={12}>
                Loading...
              </Grid>
            )}
            {!isLoadingData && (
              <Grid item xs={12}>
                you are about to disassociate {form.values["sub_org"]?.name}{" "}
                from{" "}
                {form.values["super_orgs"].map((item) => item.name).join(",")}
              </Grid>
            )}
            {!props.sub_org && (
              <Grid item xs={12}>
                <ProvidersInput
                  key={"subs-" + Math.random().toString().slice(3)}
                  viewType="subs"
                  superOrgId={props.super_org!}
                  value={form.values["sub_org"]}
                  fullwidth
                  onChange={(value) => form.setFieldValue("sub_org", value)}
                  placeholder="Select Sub Organizaion To Disassociate"
                />
              </Grid>
            )}
            {!props.super_org && (
              <Grid item xs={12}>
                <ProvidersInput
                  key={"supers-" + Math.random().toString().slice(3)}
                  viewType="supers"
                  subOrgId={form.values["sub_org"]?.id!}
                  value={form.values["super_orgs"]}
                  fullwidth
                  onChange={(value) => form.setFieldValue("super_orgs", value)}
                  placeholder="Select Super Organizaion To Disassociate"
                />
              </Grid>
            )}

            {errors && (
              <Grid item xs={12}>
                <Alert variant="outlined" severity="error">
                  {errors}
                </Alert>
              </Grid>
            )}
          </Grid>
        </Box>
      </CustomDialogV2>
    );
  };

export default DisassociateOrganizationDialog;
