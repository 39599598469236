import { GetBroadcasts } from "api/broadcasts/get-broadcasts";
import { CustomBroadcastsTableProps } from "components/custom-compnents/custom-broadcasts-table/custom-broadcasts-table";
import { CustomFarmersTableProps } from "components/custom-compnents/custom-farmers-table/custom-farmers-table";
import { AddUpdateBroadcastComponentProps } from "components/standalone-components/add-update-broadcast/add-update-broadcast";
import { broadcast } from "models/broadcast";
import React from "react";
import { useAppSelector } from "store/hooks";
import { OrderByObject } from "./order-by-object";

export const useLogic = () => {
  const user = useAppSelector((root) => root.appReducer.user);
  const [orderByData, setOrderByData] = React.useState<{ index: number; orderDirection: "asc" | "desc" } | undefined>({
    index: 1,
    orderDirection: 'asc'
  });
  const [isOpenAddBroadcastDialog, setIsOpenAddBroadcastDialog] = React.useState(false);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [data, setData] = React.useState<broadcast[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  // filtering
  const [isOpenFilterArea, setIsOpenFilterArea] = React.useState(false);
  const [textFilter, setTextFilter] = React.useState("");


  const defaultColumns = [
    { field: "title", checked: true },
    { field: "description", checked: true },
    { field: "is_published", checked: true },
  ]
  if (user?.menuroles === 'admin') {
    defaultColumns.unshift({ field: "organization", checked: true })
  }

  const [columnsState, setColumnsState] = React.useState(defaultColumns);

  const handleOrderChange: CustomFarmersTableProps["onOrderChange"] = (index, orderDirection) => {
    if (index > -1) setOrderByData({ index, orderDirection });
  };


  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const HandleAddBroadcastClicked = () => setIsOpenAddBroadcastDialog(true);

  const HandleCancelAddBroadcast = () => setIsOpenAddBroadcastDialog(false);

  const HandleAddedBroadcast = () => {
    setIsOpenAddBroadcastDialog(false);
    refreshData();
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetBroadcasts({
      page: currentPage,
      pageSize: perPage,
      sort_by: typeof orderByData?.index === "number" ? (OrderByObject as any)[orderByData?.index!][orderByData?.orderDirection] : undefined,
      search: textFilter || undefined
    }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        setData(resp.items);
        setTotalCount(resp.total);
        setIsLoading(false);
      }
    });
  }, [dataUpdateIndex, currentPage, perPage, orderByData?.index, orderByData?.orderDirection]);

  const preparedData = data.map((item) => ({ ...item, selected: true }));


  const addBroadcastDilaogProps: AddUpdateBroadcastComponentProps = {
    Variant: "Add",
    UserProviderId: user?.provider_id as string,
    UserMenurules: user?.menuroles as any,
    open: isOpenAddBroadcastDialog,
    OnCancel: HandleCancelAddBroadcast,
    OnAdded: HandleAddedBroadcast,
  };


  const handleFilterButtonClick = () => setIsOpenFilterArea(true);

  const clearFilters = () => {
    setTextFilter("");
  };


  const isFilterEmpty = !textFilter;

  const showFilterArea = isOpenFilterArea || !isFilterEmpty;

  const tableProps: CustomBroadcastsTableProps = {
    showFilterArea: showFilterArea,
    onOrderChange: handleOrderChange,
    isLoading: isLoading,
    data: preparedData,
    countLabelProps: {
      count: totalCount,
    },
    filterFormProps: {
      textFilterProps: { value: textFilter, onChange: (e) => setTextFilter(e.currentTarget.value) },
    },
    filterContainerProps: {
      CancelSuccessButtonGroupProps: {
        SuccessButtonProps: {
          disabled: isFilterEmpty,
          onClick: () => {
            refreshData();
            setCurrentPage(0);
          },
        },
        CancelButtonProps: {
          onClick: () => {
            clearFilters();
            setIsOpenFilterArea(false);
            refreshData();
          },
        },
      },
    },
    actionBarProps: {
      filterButtonProps: {
        onClick: handleFilterButtonClick,
      },
      addButtonProps: {
        onClick: HandleAddBroadcastClicked,
      },
      isShowCreateButton: user?.menuroles !== "user",
      isShowColumnButton: true,
      isShowFilterButton: true,
      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
    },
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };
  return { tableProps, addBroadcastDilaogProps };
};
