import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import humanize from "humanize-graphql-response";
import { IQualityCheck } from "models/quality-check";

interface Params {
  page: number;
  pageSize: number;
  id?: string;
  provider_id?: string;
  aggregation_id?: string;
  with_editor?: boolean;
  with_provider?: boolean;
}
interface Resp {
  items: IQualityCheck[];
  totalCount: number;
}
type GetQualityCheckType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | Resp) => any
) => any;

export const GetQualityCheck: GetQualityCheckType = (Params, callback) => {


  AxiosInstance.get("/quality-checks", { params: Params })
    .then((resp) => {
      callback(null, {
        totalCount: resp.data.data.all_quality_checks.total_count,
        items: humanize(resp.data.data.all_quality_checks).nodes.map((item: any) => ({
          last_editor: item.lastEditor,
          last_editor_data: item.last_editor_data,
          last_edit_date: item.lastEditDate,
          aggregation_id: item.aggregationId,
          id: item.id,
          type: item.type,
          report: item.report,
          passed: item.passed,
          status: item.status,
        })),
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};
