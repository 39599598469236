import { Box, Button, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
// import { IoOpenOutline, IoRemoveSharp } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { IDevice } from "models/device";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
  selectedFarmersIds?: string[];
  selectedByPhone?: boolean;
}
export interface Fields extends IDevice {
  selected: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onDeleteButtonClick?: (data: Fields) => any;
  hideDetachAction?: boolean;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (
  params
) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "IMEI",
      field: "imei",
      hidden: !params.columnState?.find((item) => item.field === "imei")
        ?.checked,
      render: (data) => data.imei,
    },
    {
      title: "PHONE",
      field: "phone_number",
      hidden: !params.columnState?.find((item) => item.field === "phone_number")
        ?.checked,
      render: (data) => data.phone_number,
    },
    {
      title: "Assigned Farmer Id",
      field: "assigned_farmer_id",
      hidden: !params.columnState?.find(
        (item) => item.field === "assigned_farmer_id"
      )?.checked,
      render: (data) => data.assigned_farmer_id,
    },
    {
      title: "LAST LOCATION",
      field: "last_location",
      hidden: !params.columnState?.find(
        (item) => item.field === "last_location"
      )?.checked,
      render: (data) =>
        data.last_location_lat && data.last_location_long
          ? `${data.last_location_lat} / ${data.last_location_long}`
          : "-",
    },
    {
      title: "BATTERY STATUS",
      field: "last_battery_level",
      hidden: !params.columnState?.find(
        (item) => item.field === "last_battery_level"
      )?.checked,
      render: (data) => data.last_battery_level ?? "-",
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: (
        <CustomCheckBox
          onChange={(e, value) =>
            params.customSelection?.onSelectAllChange?.(params.data, value)
          }
          checked={params.data.every((item) => item.selected)}
          indeterminate={
            params.data.some((item) => item.selected) &&
            !params.data.every((item) => item.selected)
          }
        />
      ),
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => (
        <CustomCheckBox
          checked={data.selected}
          onChange={(e, value) =>
            params.customSelection?.onRowSelectChange?.(data, value)
          }
        />
      ),
    });
  }

  return {
    actions: !params.hideDetachAction ? [
      (fields) => ({
        icon: () => (
          <Button
            variant="text"
            onClick={() => params.onDeleteButtonClick?.(fields)}
            color="success"
          >
            Detach
          </Button>
        ),
        onClick: () => null,
      }),
    ] : [],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
