import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Params {
  id: string;
}
type DeleteUserType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: any | null) => any
) => any;
const DeleteUser: DeleteUserType = (Params, callback) => {
  AxiosInstance.delete("/users/" + Params.id)
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => callback(error, null));
};

export { DeleteUser };
