export interface IParams {
  farmer: { name: string; id: string };
  farm: { name: string; id: string };
}

export const breadcrumb = (params: IParams) => {
  const { farm, farmer } = params;

  return [
    { title: "Home", path: "/" },
    { title: "Farmer Management", path: "/farmers/overview" },
    {
      title: `${farmer.name}`,
      path: `/farmers/${farmer.id}`,
    },
    {
      title: farm.name,
      path: `/farms/${farm.id}`,
    },
  ].filter((item) => item.title); // shape the array of exists paths
};
