import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./side-menu-sub-item.style";

export interface SideMenuSubItemProps {
  isActive?: boolean;
  title?: string;
  onClick?: () => any;
  href?: string;
}

const SideMenuSubItem: React.FunctionComponent<SideMenuSubItemProps> = (
  props
) => {
  const { isActive, title, onClick, href } = props;
  const classes = useStyles();
  const LinkComponent = href ? Link : React.Fragment;

  return (
    <LinkComponent to={href as any}>
      <ListItemButton dense disableGutters onClick={onClick}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={title}
            className={`${classes.textRoot} ${isActive && classes.textActive}`}
          />
        </ListItem>
      </ListItemButton>
    </LinkComponent>
  );
};

export default SideMenuSubItem;
