import { Grid } from "@mui/material";
import CheckBoxItemsMenu, {
  CheckBoxItemsMenuProps,
} from "components/custom-compnents/check-box-items-menu/check-box-items.menu";
import CustomMenuButton from "components/custom-compnents/custom-menu-button/custom-menu-button";
import CustomTextField, {
  CustomTextFieldProps,
} from "components/custom-compnents/custom-text-field/custom-text-field";

import * as React from "react";

export interface CertificateTableFilterFormProps {
  descriptionFilterProps?: CustomTextFieldProps;
  titleFilterProps?: CustomTextFieldProps;
  certificateTypeMenuFilterProps?: Pick<
    CheckBoxItemsMenuProps,
    "Items" | "onItemClick"
  >;
  isShowTitleFilter?: boolean;
}

const CertificateTableFilterForm: React.FunctionComponent<CertificateTableFilterFormProps> =
  (props) => {
    const {
      descriptionFilterProps,
      certificateTypeMenuFilterProps,
      titleFilterProps,
      isShowTitleFilter,
    } = props;

    const [certificatesTypeMenuAnchorEl, setcertificatesTypeMenuAnchorEl] =
      React.useState();

    const handlecertificatesTypeMenuOpen = (e: any) =>
      setcertificatesTypeMenuAnchorEl(e.currentTarget);

    const handlecertificatesTypeMenuClose = () =>
      setcertificatesTypeMenuAnchorEl(undefined);

    const FieldsData = [
      {
        label: "Title",
        type: "text",
        props: titleFilterProps,
        hidden: !isShowTitleFilter,
      },
      {
        label: "Description",
        type: "text",
        props: descriptionFilterProps,
      },
      {
        label: "Certificate Type",
        type: "check-menu",
        props: certificateTypeMenuFilterProps,
        MenuProps: certificateTypeMenuFilterProps,
        open: Boolean(certificatesTypeMenuAnchorEl),
        onClose: handlecertificatesTypeMenuClose,
        onOpen: handlecertificatesTypeMenuOpen,
        anchorEl: certificatesTypeMenuAnchorEl,
        headerLabel: "Certificate Type",
      },
    ];

    return (
      <Grid container spacing={2}>
        {FieldsData.filter((item) => !item.hidden).map(
          ({ type, ...item }, index) => (
            <Grid item lg={3} xs={12} key={index}>
              {type === "text" && (
                <CustomTextField
                  placeholder={item.label}
                  fullWidth
                  {...(item.props as CustomTextFieldProps)}
                />
              )}
              {type === "check-menu" && item.MenuProps && (
                <>
                  <CheckBoxItemsMenu
                    {...item.MenuProps}
                    onClose={item.onClose}
                    headerLabel="Activities"
                    open={item.open ?? false}
                    anchorEl={item.anchorEl}
                  />
                  <CustomMenuButton onClick={item.onOpen}>
                    {item.label}
                  </CustomMenuButton>
                </>
              )}
            </Grid>
          )
        )}
      </Grid>
    );
  };

export default CertificateTableFilterForm;
