import { Box, Paper, Slider, Typography, Skeleton, Stack } from "@mui/material";
import cleanDeep from "clean-deep";
import React from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  MapContainerProps,
  WMSTileLayer,
} from "react-leaflet";
import { EPSG_32636, EPSG_32637, EPSG_32736, EPSG_32737 } from "./crs";
import "leaflet/dist/leaflet.css";
import { Configs } from "configs/axios";
import L from "leaflet";

delete (L as any).Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export type crs = "EPSG:32736" | "EPSG:32636" | "EPSG:32637" | "EPSG:32737";
export interface LocationMapProps {
  location: {
    lat: number | null | undefined;
    lng: number | null | undefined;
  }[];
  Apikey?: string;
  Solid?: boolean;
  Loading?: Boolean;
  maxZoom?: number;
  minZoom?: number;
  dragging?: boolean;
  zoomControl?: boolean;
  scrollWheelZoom?: boolean;
  height?: number | string;
  width?: string | number;
  wmsInfo?: {
    service: string;
    version: string;
    request: string;
    layers: string;
    format: string;
    width: number;
    height: number;
    transparent: boolean;
    styles: string;
    url?: string;
    crs: crs;
    time: string;
    Authorization?: string;
    WmsUrl: string;
  };
}

const LocationMap: React.FC<LocationMapProps> = ({
  location,
  Apikey = (window as any).env.REACT_APP_HERE_MAP_KEY,
  Solid,
  Loading,
  minZoom,
  maxZoom,
  zoomControl,
  dragging,
  scrollWheelZoom,
  wmsInfo,
  height,
  width,
}) => {
  const MapContainerProps: MapContainerProps = cleanDeep({
    key: JSON.stringify(location),
    bounds: location.map((item) => [item.lat, item.lng] as [number, number]),
    zoom: 18,
    style: { width: width ?? "100%", height: height ?? "500px" },
    scrollWheelZoom: scrollWheelZoom,
    maxZoom: maxZoom,
    minZoom: minZoom,
    dragging: dragging,
    zoomControl: zoomControl,
  });
  const [WMSOpacitySliderValue, SetWMSOpacitySliderValue] = React.useState(100);
  return Loading ? (
    <Skeleton width="100%" height="300px" />
  ) : (
    <Box
      component={Paper}
      p={!Solid ? 1 : undefined}
      overflow="hidden"
      position="relative"
    >
      {!Array.isArray(MapContainerProps.bounds) ||
      MapContainerProps.bounds.length === 0 ? (
        <Box
          height="300PX"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography color="textSecondary" variant="h6">
            No Information
          </Typography>
        </Box>
      ) : (
        <MapContainer
          {...MapContainerProps}
          boundsOptions={{ noMoveStart: false }}
        >
          <TileLayer
            url={`https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?apiKey=${Apikey}`}
          />
          {location
            .filter(
              (item) =>
                typeof item.lat === "number" && typeof item.lng === "number"
            )
            .map((item, index) => (
              <Marker
                key={index}
                position={[item.lat as number, item.lng as number]}
              ></Marker>
            ))}
          {wmsInfo && (
            <WMSTileLayer
              opacity={WMSOpacitySliderValue / 100}
              layers={wmsInfo.layers}
              crs={
                wmsInfo?.crs === "EPSG:32736"
                  ? EPSG_32736
                  : wmsInfo.crs === "EPSG:32636"
                  ? EPSG_32636
                  : wmsInfo.crs === "EPSG:32637"
                  ? EPSG_32637
                  : wmsInfo.crs === "EPSG:32737"
                  ? EPSG_32737
                  : undefined
              }
              params={{
                ...wmsInfo,
              }}
              url={wmsInfo.url ?? Configs.baseURL + "wms"}
            />
          )}
        </MapContainer>
      )}
      {wmsInfo && (
        <Box
          px={2}
          py={1}
          sx={{ position: "absolute" }}
          component={Paper}
          bottom="10px"
          zIndex={1000}
          width="95%"
          display="flex"
          alignItems="center"
          left="50%"
          style={{ transform: "translate(-50%)" }}
        >
          <Typography
            id="discrete-slider-custom"
            variant="subtitle2"
            color="textSecondary"
            sx={{ mr: 2 }}
          >
            Opacity:
          </Typography>
          <Stack
            spacing={2}
            direction="row"
            sx={{ width: "100%" }}
            alignItems="center"
          >
            <Box mx={0.2}>
              <Typography variant="caption">0%</Typography>
            </Box>
            <Slider
              size="small"
              min={0}
              max={100}
              step={1}
              value={WMSOpacitySliderValue}
              onChange={(e, value) =>
                !Array.isArray(value) && SetWMSOpacitySliderValue(value)
              }
              sx={{ margin: "0px !important", mx: "4px !important" }}
            />
            <Box mx={0.5}>
              <Typography variant="caption">100%</Typography>
            </Box>
          </Stack>
          {/* <Slider
            value={WMSOpacitySliderValue}
            step={1}
            min={0}
            max={100}
            marks={[
              { value: 0, label: "0%" },
              { value: 100, label: "100%" },
            ]}
          /> */}
        </Box>
      )}
    </Box>
  );
};

export default LocationMap;
