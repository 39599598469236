export interface IParams {
  farmer: { firstName?: string; lastName?: string; id: string };
}

export const breadcrumb = (params: IParams) => {
  const { farmer } = params;

  return [
    { title: "Home", path: "/" },
    { title: "Farmer Managment", path: "/farmers/overview" },
    {
      title:
        farmer.firstName || farmer.lastName
          ? `${farmer.firstName ?? ""} ${farmer.lastName ?? ""}`
          : "UNKNOWN",

      path: `/farmers/${farmer.id}`,
    },
  ].filter((item) => item.title); // shape the array of exists paths
};
