import { Tooltip } from "@mui/material";
import { FunctionComponent, ReactElement } from "react";
import { useAppSelector } from "store/hooks";

interface ShowSelectedAnalyticsMapLocationTooltipProps {
  children: ReactElement;
}

const ShowSelectedAnalyticsMapLocationTooltip: FunctionComponent<ShowSelectedAnalyticsMapLocationTooltipProps> = (props) => {
  const farmersAnalyticsMapState = useAppSelector((root) => root.farmersAnalyticsMapState);

  const { SelectedRegions, SelectedConstituencies, SelectedCounties, SelectedWards } = farmersAnalyticsMapState;
  return (
    <Tooltip
      title={
        SelectedWards.length > 0
          ? `Wards : ${SelectedWards.map((item) => item.ward).join(" , ")}`
          : SelectedConstituencies.length > 0
          ? `Constituncies : ${SelectedConstituencies.map((item) => item.constituency).join(" , ")}`
          : SelectedCounties.length > 0
          ? `Counties : ${SelectedCounties.map((item) => item.county).join(" , ")}`
          : SelectedRegions.length > 0
          ? `Regions : ${SelectedRegions.map((item) => item.region).join(" , ")}`
          : ""
      }
      arrow
    >
      {props.children}
    </Tooltip>
  );
};

export default ShowSelectedAnalyticsMapLocationTooltip;
