import SmsMessagesKpi from "components/standalone-components/sms-messages-kpi/sms-messages-kpi";
import StatisticsKpi from "components/standalone-components/statistics-kpi/statistics-kpi";
import { FunctionComponent } from "react";
import { useAppSelector } from "store/hooks";

interface StatisticsKpiContainerProps {}

const StatisticsKpiContainer: FunctionComponent<StatisticsKpiContainerProps> = () => {
  const farmersAnalyticsMapState = useAppSelector((root) => root.farmersAnalyticsMapState);

  const farmerAnalyticsPageState = useAppSelector((root) => root.FarmerAnalyticsPageState);
  return (
    <>
      <StatisticsKpi
        constituency_id_list={farmersAnalyticsMapState.SelectedConstituencies.map((item) => item.constituency_id)}
        region_id_list={farmersAnalyticsMapState.SelectedCounties.length === 0 && farmersAnalyticsMapState.SelectedConstituencies.length === 0 && farmersAnalyticsMapState.SelectedWards.length === 0 ? farmersAnalyticsMapState.SelectedRegions.map((item) => item.region_id) : undefined}
        county_id_list={farmersAnalyticsMapState.SelectedConstituencies.length === 0 && farmersAnalyticsMapState.SelectedWards.length === 0 ? farmersAnalyticsMapState.SelectedCounties.map((item) => item.county_id) : undefined}
        ward_id_list={farmersAnalyticsMapState.SelectedWards.length === 0 ? farmersAnalyticsMapState.SelectedWards.map((item) => item.ward_id) : undefined}
        provider_id={farmerAnalyticsPageState.selectedProviderId}
      />
    </>
  );
};

export default StatisticsKpiContainer;
