import { Box } from "@mui/material";
import React from "react";
import { Farmer } from "models/farmers";
import CallbacksTable from "components/data-tables/callbacks-table/callbacks-table";

export interface CallbacksTabBoxProps {
  Farmer: Farmer;
}

const CallbacksTabBox: React.SFC<CallbacksTabBoxProps> = ({ Farmer }) => {
  return (
    <Box>
      <CallbacksTable
        farmer_id={Farmer.id}
        columnsToExclude={["farmer_name", "farmer_phone"]}
        sorting={true}
      />
    </Box>
  );
};

export default CallbacksTabBox;
