import { connect } from "react-redux";
import { Region } from "models/region";
import { County } from "models/county";
import { Constituency } from "models/constituency";
import { Ward } from "models/ward";

export interface DirectMessageLocationFilterState {
  SelectedRegions: Array<Region>;
  AvailableRegions: Array<Region>;
  SelectedCounties: Array<County>;
  AvailableCounties: Array<County>;
  SelectedConstituencies: Array<Constituency>;
  AvailableConstituencies: Array<Constituency>;
  SelectedWards: Array<Ward>;
  AvailableWards: Array<Ward>;
  open?: Boolean;
  filledButton?: Boolean;
}
const DirectMessageLocationFilterState = (
  state: DirectMessageLocationFilterState = {
    open: false,
    filledButton: false,
    SelectedConstituencies: [],
    AvailableConstituencies: [],
    SelectedCounties: [],
    AvailableCounties: [],
    SelectedRegions: [],
    AvailableRegions: [],
    SelectedWards: [],
    AvailableWards: [],
  },
  action: any
) => {
  return action.type === "setDirectMessageLocationFilterState"
    ? { ...state, ...action.payload }
    : state;
};

const ProvideDirectMessageLocationFilterStateTo: any = (
  Component: any,
  ProvideState = false
) => {
  const mapStateToProps = (state: any) => {
    return ProvideState
      ? {
        DirectMessageLocationFilterState:
          state.DirectMessageLocationFilterState,
      }
      : {};
  };

  const mapDispatchToProps = (dispatch: any) => {
    return {
      SetDirectMessageLocationFilterState: (value: any) =>
        dispatch({
          type: "setDirectMessageLocationFilterState",
          payload: value,
        }),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export { ProvideDirectMessageLocationFilterStateTo };
export default DirectMessageLocationFilterState;
