import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { broadcast } from "../../models/broadcast";

interface Resp { }
interface Params
  extends Omit<broadcast, "id" | "created_by" | "last_edited_by"> {
  sender_id: string
}
type AddBroadcastsType = (
  Params: Partial<Params>,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const AddBroadcasts: AddBroadcastsType = (Params, callback) => {
  AxiosInstance.post("/sms-broadcasts", { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { AddBroadcasts };
