import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Survey } from "models/survey";
interface Resp {
  page: number;
  per_page: number;
  pages: number;
  total: number;
  items: Survey[];
}
interface Params {
  page: number;
  pageSize: number;
  surveys_id_list?: string[];
  is_deleted?: boolean;
  provider_id_list?: string[];
}
type GetSurveysType = (
  Params: Params,
  callback: (error: AxiosError | null, Resp: Resp | null) => any
) => any;
const GetSurveys: GetSurveysType = (Params, callback) => {
  AxiosInstance.get("/surveys", { params: Params })
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { GetSurveys };
