import { Box } from "@mui/material";
import * as React from "react";
import MessagesDetailTable from "components/data-tables/messages-detail-table/message-detail-table";
import { useQuery } from "hooks/use-query";
import { useAppSelector } from "store/hooks";
import { statusType } from "components/data-tables/messages-detail-table/message-detail-table.logic";

interface MessageDetailScreenProps {}

const MessageDetailScreen: React.FunctionComponent<MessageDetailScreenProps> = () => {
  let query = useQuery();
  const user = useAppSelector((root) => root.appReducer.user);

  return (
    <Box>
      <MessagesDetailTable provider_id_list={query.get("provider")?.split(",")} status_list={(query.get("status")?.split(",") as statusType[]) ?? undefined} />
    </Box>
  );
};

export default MessageDetailScreen;
