import { Grid } from "@mui/material";
import MessageCategoryInput, {
  MessageCategoryInputProps,
} from "components/input-components/message-category-input/message-category-input";

import * as React from "react";

export interface WhatsappMessagesTemplatesTableFilterProps {
  categoryFilterProps?: MessageCategoryInputProps;
  isShowCategoryFilter?: boolean;
}

const WhatsappMessagesTemplatesTableFilter: React.FunctionComponent<WhatsappMessagesTemplatesTableFilterProps> =
  (props) => {
    const { categoryFilterProps, isShowCategoryFilter } = props;

    const FieldsData = [
      {
        label: "Category Filter",
        type: "category-input",
        props: categoryFilterProps,
        show: isShowCategoryFilter,
      },
    ];

    return (
      <Grid container spacing={2}>
        {FieldsData.filter((item) => item.show).map(
          ({ type, ...item }, index) => (
            <Grid item lg={2} xs={12} key={index}>
              {type === "category-input"}
              <MessageCategoryInput {...item.props} />
            </Grid>
          )
        )}
      </Grid>
    );
  };

export default WhatsappMessagesTemplatesTableFilter;
