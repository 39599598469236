import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import BroadcastsMessagesTable from "components/data-tables/broadcasts-messages-table/broadcasts-messages-table";
import FarmersTable from "components/data-tables/farmers-table/farmers-table";
import ShowEditBroadcastComponent from "components/standalone-components/show-edit-broadcast-component/show-edit-broadcast-component";
import CustomTabsComponent from "components/custom-compnents/custom-tabs-component/custom-tabs-component";
import { IoOpenOutline } from "react-icons/io5";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import { useLogic } from "./broadcasts-detail-screen.logic";

interface BroadcastsDetailScreenProps { }

const BroadcastsDetailScreen: React.FunctionComponent<BroadcastsDetailScreenProps> =
  () => {
    const {
      isShowEditDeleteDrawer,
      setIsShowEditDeleteDrawer,
      navigate,
      handleSubscripeConfirm,
      HandleDayZeroDialogCancel,
      OpenDayZeroDialog,
      IsSubmittingDayZeroDialog,
      DayZeroFieldProps,
      TabsOptionArray,
      TabValue,
      SetTabValue,
      BroadcastInfo,
      UserMenurules,
      UserId,
      FarmersTableProps,
      UserProvider,
      PossibleCreatorUserProviderId,
      id,
    } = useLogic();

    return (
      <Box>
        {id && (
          <ShowEditBroadcastComponent
            broadcast_id={id}
            open={isShowEditDeleteDrawer}
            onBackClick={() => setIsShowEditDeleteDrawer(false)}
            onDeleted={() => navigate("/messaging/broadcasts")}
            ActionsToExclude={
              UserMenurules === "admin" ||
                (UserMenurules == "user" &&
                  UserId == BroadcastInfo?.created_by) ||
                ((UserMenurules === "manager" ||
                  UserMenurules === "org_admin") &&
                  (BroadcastInfo?.provider_id == UserProvider ||
                    UserProvider == PossibleCreatorUserProviderId))
                ? []
                : ["update", "delete", "add"]
            }
          />
        )}
        <CustomDialogV2
          header="Day Zero"
          open={OpenDayZeroDialog}
          footer={
            <Box display="flex" justifyContent="flex-end">
              <CancelSuccessButtonsGroup
                CancelButtonProps={{
                  label: "Cancel",
                  onClick: HandleDayZeroDialogCancel,
                }}
                SuccessButtonProps={{
                  label: "Confirm",
                  disabled: IsSubmittingDayZeroDialog,
                  onClick: handleSubscripeConfirm,
                }}
                RootGridProps={{ width: 300 }}
              />
            </Box>
          }
        >
          {/* <CustomDialog {...SubscriptionDialogWithDayZeroProps}> */}
          <Box>
            <Typography>
              This broadcast have relative send dates, this mean you have to set
              date for the day zero to send the messages after
            </Typography>
            <Box mt={2}>
              <TextField {...DayZeroFieldProps} />
            </Box>
          </Box>
          {/* </CustomDialog> */}
        </CustomDialogV2>
        {/* <AddUpdateBroadcast {...AddUpdateBroadcastProps} /> */}
        {/* <CustomDialog {...CustomDialogProps}>
          Are you sure you want to delete this broadcast ?
        </CustomDialog> */}
        <Grid container spacing={2}>
          {/* <Grid item md={4} xl={3} xs={12}>
            {BroadcastInfo && (
              <StackedBroadcastInfoList
                {...StackedBroadcastInfoListProps(BroadcastInfo)}
              />
            )}
          </Grid> */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex">
                  <Box width="100%">
                    <CustomTabsComponent
                      TabData={TabsOptionArray.map((Item) => ({
                        label: Item.title,
                        value: Item.value,
                        icon: <Item.icon size={20} />,
                      }))}
                      TabsProps={{
                        value: TabValue,
                        onChange: (e, value) => SetTabValue(value),
                      }}
                    />
                  </Box>
                  <Box borderBottom={"solid 1px #dbdbdb"}>
                    <Button
                      variant="text"
                      color="success"
                      startIcon={<IoOpenOutline />}
                      onClick={() => setIsShowEditDeleteDrawer(true)}
                    >
                      Broadcast Information
                    </Button>
                  </Box>
                </Box>
                {/* <TabsComponent
                  value={TabValue}
                  optionsArray={TabsOptionArray}
                  OnChange={SetTabValue}
                /> */}
              </Grid>
              <Grid item xs={12}>
                <Box hidden={TabValue !== 0}>
                  <BroadcastsMessagesTable
                    broadcast_id={id}
                    ActionsToExclude={
                      UserMenurules === "admin" ||
                        (UserMenurules == "user" &&
                          UserId == BroadcastInfo?.created_by) ||
                        ((UserMenurules === "manager" ||
                          UserMenurules === "org_admin") &&
                          (BroadcastInfo?.provider_id == UserProvider ||
                            UserProvider == PossibleCreatorUserProviderId))
                        ? []
                        : ["update", "delete", "add"]
                    }
                  />
                </Box>
                {TabValue === 1 && (
                  <Box>
                    {BroadcastInfo?.provider_id && (
                      <FarmersTable {...FarmersTableProps(BroadcastInfo)} />
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

export default BroadcastsDetailScreen;
