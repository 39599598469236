import { Button, ButtonProps, Grid, GridProps } from "@mui/material";

interface CancelButtonProps extends ButtonProps {
  label?: string;
}

interface DeleteButtonProps extends ButtonProps {
  label?: string;
  "data-cy"?: string;
}

export interface CancelDeleteButtonsGroupProps {
  CancelButtonProps: CancelButtonProps;
  DeleteButtonProps: DeleteButtonProps;
  RootGridProps?: GridProps;
}

const CancelDeleteButtonsGroup: React.FunctionComponent<
  CancelDeleteButtonsGroupProps
> = (props) => {
  const { CancelButtonProps, DeleteButtonProps, RootGridProps } = props;
  const { label: Cancellabel, ...RestCancelButtonProps } = CancelButtonProps;
  const { label: Deletelabel, ...RestDeleteButtonProps } = DeleteButtonProps;

  return (
    <Grid container spacing={2} justifyContent="flex-end" {...RootGridProps}>
      <Grid item xs={6}>
        <Button fullWidth color="secondary" {...RestCancelButtonProps}>
          {Cancellabel}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          size="medium"
          color="error"
          {...RestDeleteButtonProps}
        >
          {Deletelabel}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CancelDeleteButtonsGroup;
