import { useLocation, useNavigate } from "react-router-dom";
import hasSubArray from "../utils/has-sub-array";
import getPathFromUrl from "../utils/get-path-from-url";
import Data from "../configs/routes";
import { RouteItem } from "classes/route-item";

export const useRouteutils = () => {
  const navigate = useNavigate();
  const Location = useLocation();

  const RedirectToPath = (pathArray: string[]) => {
    navigate(pathArray.join("/"));
  };

  const IsPartOfPath = (pathArray: string[], location: any = Location) => {
    const locationArray = getPathFromUrl(location.pathname).split("/").slice(0);
    return hasSubArray(locationArray, pathArray);
  };

  interface IFlatRoute extends RouteItem {
    pathUrl: string;
  }
  const getFlatRoutesArray: (RoutesArray?: RouteItem[]) => IFlatRoute[] = (
    RoutesArray = Data.Lists
  ) => {
    let children: any = [];
    const flattenMembers = RoutesArray.map((RouteItem: any) => {
      if (RouteItem.subList && RouteItem.subList.length) {
        children = [
          ...children,
          ...RouteItem.subList.map((SubItem: any) => ({
            ...SubItem,
            pathUrl: [RouteItem.pathUrl ?? RouteItem.path, SubItem.path].join(
              "/"
            ),
          })),
        ];
      }
      return {
        // icon: RouteItem.icon,
        // name: RouteItem.name,
        // path: RouteItem.path,
        // title: RouteItem.name,
        // component: RouteItem.component,
        ...RouteItem,
        pathUrl: "/" + (RouteItem.pathUrl ?? RouteItem.path),
      };
    });
    return flattenMembers.concat(
      children.length ? getFlatRoutesArray(children) : children
    );
  };

  return { IsPartOfPath, RedirectToPath, getFlatRoutesArray };
};
