import { GetCertificate } from "api/certificate/get-certificate";
import { CustomCertificateTableProps } from "components/custom-compnents/custom-certificate-table/custom-certificate-table";
import { combinedType } from "components/standalone-components/add-update-certificate-component/add-update-certificate-component";
import { Certificate } from "models/certificate";
import React from "react";

export const useLogic = () => {
  const [certificateToBeUpdated, setCertificateToBeUpdated] = React.useState<undefined | Certificate>(undefined);
  const [isOpenAddCertificateDilaog, setIsOpenAddCertificateDilaog] = React.useState(false);
  const [titleFilterValue, setTitleFilterValue] = React.useState("");
  const [descriptionFilterValue, setDescriptionFilterValue] = React.useState("");
  const [typeFilterValue, setTypeFilterValue] = React.useState([{ label: "Training", checked: false, value: "training" }]);
  const [isOpenFilterArea, setIsOpenFilterArea] = React.useState(false);
  const [data, setData] = React.useState<Certificate[]>([]);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [columnsState, setColumnsState] = React.useState([
    { field: "title", checked: true },
    { field: "description", checked: true },
    { field: "certificate_type", checked: true },
  ]);

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFilterButtonClick = () => {
    setIsOpenFilterArea(true);
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const clearFilters = () => {
    setDescriptionFilterValue("");
    setTypeFilterValue(typeFilterValue.map((item) => ({ ...item, checked: false })));
    setTitleFilterValue("");
  };

  const handleCancelFilter = () => {
    clearFilters();
    setIsOpenFilterArea(false);
    refreshData();
  };

  const handleTypeFilterItemClick = (index: number) => {
    const FilterValueClone = typeFilterValue.map((item) => item);
    FilterValueClone[index] = {
      ...FilterValueClone[index],
      checked: !FilterValueClone[index].checked,
    };
    setTypeFilterValue(FilterValueClone);
  };

  const handleApplyFilterClick = () => {
    refreshData();
    setCurrentPage(0);
  };

  const handleAddedCertificate = () => {
    setIsOpenAddCertificateDilaog(false);
    refreshData();
  };

  const handleCancelAddCertificate = () => {
    setIsOpenAddCertificateDilaog(false);
  };

  const handleAddCertificateButtonClick = () => {
    setIsOpenAddCertificateDilaog(true);
  };

  const handleCancelUpdate = () => {
    setCertificateToBeUpdated(undefined);
  };

  const handleUpdatedCertificate = () => {
    setCertificateToBeUpdated(undefined);
    refreshData();
  };

  const isFiltersEmpty = !descriptionFilterValue && !titleFilterValue && typeFilterValue.every((item) => !item.checked);

  React.useEffect(() => {
    setIsLoading(true);
    GetCertificate(
      {
        title: titleFilterValue,
        description: descriptionFilterValue,
        pageSize: perPage,
        page: currentPage
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setIsLoading(false);
          //   resolve({
          //     data: resp.allCertificates.nodes,
          //     page: query.page,
          //     totalCount: resp.allCertificates.totalCount,
          //   });
          setData(resp.items);
          setTotalCount(resp.totalCount);
        }
      }
    );
  }, [dataUpdateIndex, currentPage, perPage]);

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  const tableProps: CustomCertificateTableProps = {
    onEditButtonClick: setCertificateToBeUpdated,
    countLabelProps: {
      count: totalCount,
    },
    filterContainerProps: {
      CancelSuccessButtonGroupProps: {
        CancelButtonProps: {
          onClick: handleCancelFilter,
        },
        SuccessButtonProps: {
          disabled: isFiltersEmpty,
          onClick: handleApplyFilterClick,
        },
      },
    },
    isShowEditAction: true,
    isShowRemoveAction: true,
    showFilterArea: isOpenFilterArea,
    filterFormProps: {
      titleFilterProps: {
        onChange: (e) => setTitleFilterValue(e.currentTarget.value),
      },
      descriptionFilterProps: {
        onChange: (e) => setDescriptionFilterValue(e.currentTarget.value),
      },
      isShowTitleFilter: true,
      certificateTypeMenuFilterProps: {
        onItemClick: handleTypeFilterItemClick,
        Items: typeFilterValue,
      },
    },
    actionBarProps: {
      filterButtonProps: {
        onClick: handleFilterButtonClick,
      },
      isShowFilterButton: true,
      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
      isShowColumnButton: true,
      isShowCreateButton: true,
      addButtonProps: {
        onClick: handleAddCertificateButtonClick,
      },
    },
    isLoading: isLoading,
    data: preparedData,
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };

  const addCertificateDialogProps: combinedType = {
    variant: "add",
    onAdded: handleAddedCertificate,
    onCancel: handleCancelAddCertificate,
    open: isOpenAddCertificateDilaog,
  };

  const updateCertificateDialogProps: combinedType | undefined = certificateToBeUpdated?.id
    ? {
      variant: "update",
      certificate_id: certificateToBeUpdated.id,
      onCancel: handleCancelUpdate,
      onUpdated: handleUpdatedCertificate,
      open: Boolean(certificateToBeUpdated.id),
    }
    : undefined;

  return {
    tableProps,
    addCertificateDialogProps,
    updateCertificateDialogProps,
  };
};
