import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Params {
  day: number;
  month: number;
  year: number;
  aggr_last_editor: string;
  aggr_quantity_kg: number;
  aggr_payment_method: string;
  aggr_status: string;
  aggr_total_price_ksh: number;
  farm_id: string;
  crop_id: string;
  variety_id?: string;
}

type AddAggregationActivityType = (Params: Partial<Params>, callback: (error: null | AxiosError, resp: any | null) => any) => any;

const AddAggregationActivity: AddAggregationActivityType = (Params, callback) => {
  Params.variety_id = Params.variety_id ? Params.variety_id : undefined;
  AxiosInstance.post("/farm-activites/aggregation", {
    ...Params,
  })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { AddAggregationActivity };
