import { SettingsCellTwoTone } from "@mui/icons-material";
import { CustomCallbacksTableProps } from "components/custom-compnents/custom-callbacks-table/custom-callbacks-table";
import { Fields } from "components/custom-compnents/custom-callbacks-table/custom-callbacks-table.schema";
import { UpdateCallbackComponentProps } from "components/standalone-components/update-callback-component/update-callback-component";
import React from "react";
import { get_callbacks } from "./api";

export type columnsToExclude = ("farmer_name" | "farmer_phone" | "last_modified" | "date_created" | "type" | "notes")[];

export interface IParams {
  farmer_id?: string;
  columnsToExclude?: columnsToExclude;
}
export const useLogic = (params: IParams) => {
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [data, setData] = React.useState<Omit<Fields, "selected">[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [callbackIdToBeEdited, setCallbackIdToBeEdited] = React.useState<undefined | string>(undefined);
  const [isShowFilterArea, setIsShowFilterArea] = React.useState(false);
  const [noteFilterValue, setNoteFilterValue] = React.useState("");
  const [farmerNameFilterValue, setFarmerNameFilterValue] = React.useState("");
  const [farmerLastNameFilterValue, setFarmerLastNameFilterValue] = React.useState("");
  const [farmerPhoneFilterValue, setFarmerPhoneFilterValue] =
    React.useState("");
  const [statusFilterValue, setStatusFilterValue] = React.useState([
    {
      label: "Done",
      value: "done",
      checked: false,
    },
    {
      label: "Waiting",
      value: "waiting",
      checked: false,
    },
  ]);
  const [columnsState, setColumnsState] = React.useState([
    { field: "date_created", checked: true },
    { field: "last_modified", checked: true },
    { field: "farmer_name", checked: true },
    { field: "status", checked: true },
    { field: "notes", checked: true },
    { field: "farmer_phone", checked: true },
  ]);

  React.useEffect(() => {
    setIsLoading(true);
    get_callbacks(
      {
        // OrderKey,
        // farmer_name: farmerNameFilterValue,
        firstname: farmerNameFilterValue || undefined,
        lastname: farmerLastNameFilterValue || undefined,
        page: currentPage,
        pageSize: perPage,
        phone: farmerPhoneFilterValue || undefined,
        note: noteFilterValue || undefined,
        type: statusFilterValue
          .filter((item) => item.checked)
          .map((item) => item.value) || undefined,
        farmer_id: params.farmer_id || undefined,
        with_farmer: true
      },
      (error, data) => {
        if (error) {
          console.log(error.message);
        } else if (data?.callbacks) {
          setData(data?.callbacks);
          setTotalCount(data?.totalCount);
          setIsLoading(false);
        }
      }
    );
  }, [currentPage, perPage, dataUpdateIndex]);

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setFarmerNameFilterValue("");
    setFarmerLastNameFilterValue("");
    setFarmerPhoneFilterValue("");
    setNoteFilterValue("");
    setStatusFilterValue(statusFilterValue.map((item) => ({ ...item, checked: false })));
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleCancelFilter = () => {
    refreshData();
    clearFilters();
    setIsShowFilterArea(false);
  };

  const handleApplyFilter = () => {
    refreshData();
    setCurrentPage(0);
  };

  const handleFilterButtonClick = () => setIsShowFilterArea(true);

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handleStatusItemClick = (index: number) => {
    const statusFilterValueClone = statusFilterValue.map((item) => item);
    statusFilterValueClone[index] = {
      ...statusFilterValueClone[index],
      checked: !statusFilterValueClone[index].checked,
    };
    setStatusFilterValue(statusFilterValueClone);
  };

  const handleCancelUpdate = () => setCallbackIdToBeEdited(undefined);

  const handleUpdatedCallback = () => {
    setCallbackIdToBeEdited(undefined);
    refreshData();
  };

  const isFiltersEmpty =
    !farmerNameFilterValue &&
    !farmerLastNameFilterValue &&
    !farmerPhoneFilterValue &&
    !noteFilterValue &&
    statusFilterValue.every((item) => !item.checked);

  const editcallbackComponentProps: UpdateCallbackComponentProps = {
    open: Boolean(callbackIdToBeEdited),
    id: callbackIdToBeEdited,
    farmer_id: data.find(item => item.id === callbackIdToBeEdited)?.Farmer?.id,
    onCancel: handleCancelUpdate,
    onUpdated: handleUpdatedCallback,
  };

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  const tableProps: CustomCallbacksTableProps = {
    countLabelProps: {
      count: totalCount,
    },
    filterFormProps: {
      noteFilterProps: {
        value: noteFilterValue,
        onChange: (e) => setNoteFilterValue(e.currentTarget.value),
      },
      farmerNameFilterProps: {
        value: farmerNameFilterValue,
        onChange: (e) => setFarmerNameFilterValue(e.currentTarget.value),
      },
      farmerLastNameFilterProps: {
        value: farmerLastNameFilterValue,
        onChange: (e) => setFarmerLastNameFilterValue(e.currentTarget.value),
      },
      farmerPhoneFilterProps: {
        value: farmerPhoneFilterValue,
        onChange: (e) => setFarmerPhoneFilterValue(e.currentTarget.value),
      },

      isShowFarmerNameFilter: !params.columnsToExclude?.find(
        (item) => item === "farmer_name"
      ),
      isShowFarmerLastNameFilter: !params.columnsToExclude?.find(
        (item) => item === "farmer_name"
      ),
      isShowFarmerPhoneFilter: !params.columnsToExclude?.find(
        (item) => item === "farmer_phone"
      ),
      statusMenuFilterProps: {
        Items: statusFilterValue,
        onItemClick: handleStatusItemClick,
      },
    },
    filterContainerProps: {
      CancelSuccessButtonGroupProps: {
        SuccessButtonProps: {
          disabled: isFiltersEmpty,
          onClick: handleApplyFilter,
        },
        CancelButtonProps: {
          onClick: handleCancelFilter,
        },
      },
    },
    isLoading: isLoading,
    data: preparedData,
    onEditButtonClick: (data) => setCallbackIdToBeEdited(data.id),
    showFilterArea: isShowFilterArea,
    actionBarProps: {
      isShowColumnButton: true,
      isShowFilterButton: true,
      columnsState: columnsState.filter((item) => !params.columnsToExclude?.some((columnToExcludeItem) => columnToExcludeItem === item.field)),
      filterButtonProps: {
        onClick: handleFilterButtonClick,
      },
      onColumnsStateChange: handleColumnStateChange,
    },
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };
  return { tableProps, editcallbackComponentProps };
};
