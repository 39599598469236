import { Box, Paper, Typography } from "@mui/material";
import React from "react";

export interface WeatherWidgetProps {
  location: {
    latitude: number | undefined;
    longitude: number | undefined;
  };
  Header?: string;
}

const WeatherWidget: React.SFC<WeatherWidgetProps> = ({
  location,
  Header = "Weather Forecast",
}) => {
  return location.latitude && location.longitude ? (
    <Box
      component={Paper}
      p={2}
      textAlign="center"
      display="inline-block"
      width="100%"
    >
      <Typography
        style={{ textAlign: "left" }}
        color="textSecondary"
        variant="body2"
      >
        {Header}
      </Typography>
      <iframe
        title="meteoblue weather forcast"
        src={`https://www.meteoblue.com/en/weather/widget/daily/${location.latitude}N${location.longitude}E?geoloc=fixed&days=4&tempunit=CELSIUS&windunit=KILOMETER_PER_HOUR&precipunit=MILLIMETER&coloured=coloured&pictoicon=0&pictoicon=1&maxtemperature=0&maxtemperature=1&mintemperature=0&mintemperature=1&windspeed=0&windspeed=1&windgust=0&winddirection=0&winddirection=1&uv=0&humidity=0&precipitation=0&precipitation=1&precipitationprobability=0&precipitationprobability=1&spot=0&spot=1&pressure=0&layout=light`}
        frameBorder="0"
        scrolling="NO"
        allowTransparency={true}
        sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
        style={{ width: "100%", height: 400 }}
      ></iframe>
    </Box>
  ) : null;
};

export default WeatherWidget;
