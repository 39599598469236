import * as React from "react";
import { Box } from "@mui/material";
import DevicesTable from "components/data-tables/devices-table/devices-table";
import { Farmer } from "models/farmers";

export interface DevicesTabBoxProps {
  Farmer: Farmer;
}

const DevicesTabBox: React.FC<DevicesTabBoxProps> = ({ Farmer }) => {
  return (
    <Box>
      <DevicesTable
        by_farmer={Farmer}
        assigned_devices={true}
        ExcludeFields={["assigned_farmer_id"]}
      />
    </Box>
  );
};

export default DevicesTabBox;
