import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { CropModelingRun } from "models/crop-modeling-run";
interface Params {
  farm_id?: string;
}
interface Resp {
  runs: CropModelingRun[];
  next_page_token: string;
}

type GetMlFlowRunsType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const GetMlFlowRuns: GetMlFlowRunsType = (Params, callback) => {
  AxiosInstance.get("/MLFlow/Runs", { params: { farm_id: Params.farm_id } })
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { GetMlFlowRuns };
