import { Button, ButtonProps, Grid, GridProps } from "@mui/material";

interface CancelButtonProps extends ButtonProps {
  label?: string;
}

interface SuccessButtonProps extends ButtonProps {
  label?: string;
  "data-cy"?: string;
}
export interface CancelSuccessButtonsGroupProps {
  CancelButtonProps: CancelButtonProps;
  SuccessButtonProps: SuccessButtonProps;
  RootGridProps?: GridProps;
}

const CancelSuccessButtonsGroup: React.FunctionComponent<
  CancelSuccessButtonsGroupProps
> = (props) => {
  const { CancelButtonProps, SuccessButtonProps, RootGridProps } = props;
  const { label: Cancellabel, ...RestCancelButtonProps } = CancelButtonProps;
  const { label: Successlabel, ...RestSuccessButtonProps } = SuccessButtonProps;

  return (
    <Grid container spacing={2} justifyContent="flex-end" {...RootGridProps}>
      <Grid item xs={6}>
        <Button fullWidth color="secondary" {...RestCancelButtonProps}>
          {Cancellabel}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          size="medium"
          color="success"
          {...RestSuccessButtonProps}
        >
          {Successlabel}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CancelSuccessButtonsGroup;

CancelSuccessButtonsGroup.defaultProps = {
  CancelButtonProps: { label: "Cancel" },
  SuccessButtonProps: { label: "Confirm" },
};
