import { Grid } from "@mui/material";
import CustomTextField, {
  CustomTextFieldProps,
} from "components/custom-compnents/custom-text-field/custom-text-field";

import * as React from "react";

export interface BroadcastsTableFilterFormProps {
  textFilterProps?: CustomTextFieldProps;
  isShowTextSearchFilter?: boolean;
}

const BroadcastsTableFilterForm: React.FunctionComponent<BroadcastsTableFilterFormProps> =
  (props) => {
    const { textFilterProps } = props;

    const FieldsData = [
      {
        label: "Search",
        type: "text",
        props: textFilterProps,
      },
    ];

    return (
      <Grid container spacing={2}>
        {FieldsData.map(({ type, ...item }, index) => (
          <Grid item lg={2} xs={12} key={index}>
            {type === "text" && (
              <CustomTextField
                placeholder={item.label}
                fullWidth
                {...(item.props as CustomTextFieldProps)}
              />
            )}
          </Grid>
        ))}
      </Grid>
    );
  };

export default BroadcastsTableFilterForm;
