import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  ButtonBase,
  ButtonBaseProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { SyntheticEvent } from "react";
import { useStyles } from "./custom-chips-input-component.style";

export interface CustomChipsInputComponentProps {
  chipInputProps?: any;
  autoCompleteProps: {
    value: string[];
    onChange?:
      | ((
          event: SyntheticEvent<Element, Event>,
          value: string[],
          reason: AutocompleteChangeReason,
          details?: AutocompleteChangeDetails<string> | undefined
        ) => void)
      | undefined;
  };
  textFieldProps?: TextFieldProps & { "data-cy"?: string };
  onAdd?: (value: string) => any;
  RightButtonProps?: {
    content: any;
    ButtonProps?: ButtonBaseProps;
  };
}

const CustomChipsInputComponent: React.FunctionComponent<
  CustomChipsInputComponentProps
> = (props) => {
  const { onAdd, autoCompleteProps, textFieldProps, RightButtonProps } = props;
  const classes = useStyles();
  return (
    <Autocomplete
      className={`${classes.root}`}
      multiple
      open={false}
      options={autoCompleteProps.value}
      value={autoCompleteProps.value}
      onChange={autoCompleteProps.onChange}
      ChipProps={{
        variant: "outlined",
        size: "small",
      }}
      renderInput={(params) => (
        <Box
          display="flex"
          alignItems="stretch"
          className={`${
            RightButtonProps && classes.TextFieldWrapperInputWithoutRightBorders
          }`}
        >
          <TextField
            hiddenLabel
            onKeyPress={(e) =>
              e.key === "Enter" && onAdd?.((e.target as any).value)
            }
            {...params}
            {...textFieldProps}
            className={`${classes.textFieldRoot}`}
          />
          {RightButtonProps && (
            <ButtonBase
              className={classes.rightSideButtonRoot}
              {...RightButtonProps?.ButtonProps}
            >
              {RightButtonProps?.content}
            </ButtonBase>
          )}
        </Box>
      )}
    />
  );
};

export default CustomChipsInputComponent;
