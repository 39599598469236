import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Params {
    constituency_id_list?: string[];
    counties_id_list?: string[];
}

type GetConstituenciesGeojsonType = (
    Params: Params,
    callback: (error: AxiosError | null, resp: null | any) => any
) => any;


const GetConstituenciesGeojson: GetConstituenciesGeojsonType = (Params, callback) => {
    AxiosInstance.get("/constituencies/geojson", {
        params: Params
    })
        .then((resp) => callback(null, resp))
        .catch((error) => callback(error, null));
};

export { GetConstituenciesGeojson };
