import { AxiosError } from "axios";
import { Activity } from "models/activity";
import humanize from "humanize-graphql-response";
import { AxiosInstance } from "configs/axios";
interface Resp {
  items: Activity[];
  total: number;
}
interface Params {
  id?: string;
  page: number;
  pageSize: number;
  provider_id?: string;
  activity_list?: string[];
  with_providers?: boolean;
  with_crop?: boolean;
  with_variety?: boolean;
  farm_id?: string
}
type GetFarmActivitesType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | Resp) => any
) => void;

const GetFarmActivites: GetFarmActivitesType = (Params, callback) => {

  AxiosInstance.get("/farm-activites", { params: Params })
    .then((resp) => {
      callback(null, {
        total: resp.data.data.all_farm_activities.total_count,
        items: humanize(resp.data.data.all_farm_activities).nodes.map((activity: any) => ({
          ...activity,
          farmId: activity.farm_id,
          aggrLastEditor: activity.aggr_last_editor,
          aggrQuantityKg: activity.aggr_quantity_kg,
          harvestingQuantityKg: activity.harvesting_quantity_kg,
          aggrPaymentMethod: activity.aggr_payment_method,
          aggrStatus: activity.aggr_status,
          aggrTotalPriceKsh: activity.aggr_total_price_ksh,
          fertQuantityKg: activity.fert_quantity_kg,
          fertType: activity.fert_type,
          plantingQuantityKg: activity.planting_quantity_kg,
        })),
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { GetFarmActivites };
