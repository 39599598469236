import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Img } from "react-image";
import { MdInfoOutline } from "react-icons/md";
import { Configs } from "configs/axios";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";

export interface AgroMeteogramProps {
  location: { latitude: number | undefined; longitude: number | undefined };
  Header?: string;
}

const AgroMeteogram: React.FC<AgroMeteogramProps> = ({ location }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomTitle label="Forecast Meteogram" />
      </Grid>
      <Grid item xs={12}>
        <Box display="inline-block" p={2} component={Paper}>
          {location.latitude && location.longitude ? (
            <Img
              width="100%"
              src={`${Configs.baseURL}/meteoblue/visimage/meteogram_agro?lat=${location.latitude}&lon=${location.longitude}`}
            />
          ) : (
            <Box textAlign="center">
              <Typography color="textSecondary">
                <MdInfoOutline size={35} style={{ padding: "0px 5px" }} />
                this chart requires farm coordinates
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AgroMeteogram;
