import { Grid } from "@mui/material";
import HighResCropTypeMap from "components/standalone-components/high-res-vector-crop-type-map/high-res-vector-crop-type-map";
import MainInChweleCropTypeMap from "components/standalone-components/maiz-in-chwele-crop-type-map/maiz-in-chwele-crop-type-map";
import { FunctionComponent } from "react";

interface CropMapsPageProps {}

const CropMapsPage: FunctionComponent<CropMapsPageProps> = () => {
  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <MainInChweleCropTypeMap />
      </Grid> */}
      <Grid item xs={12}>
        <HighResCropTypeMap />
      </Grid>
    </Grid>
  );
};

export default CropMapsPage;
