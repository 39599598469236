import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  labelRoot: {
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
    fontWeight: "normal !important",
    fontStretch: "normal !important",
    fontStyle: "normal !important",
    lineHeight: 3.13,
    letterSpacing: "normal !important",
    color: "#5d6d6e",
  },
});
