import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Params {
  title: string;
  jump_code: string;
  provider_id: string;
  start_time: string;
  expires_at: string;
  active: boolean;
}
interface Resp {}
type AddSurveyType = (
  Params: Params,
  callback: (error: AxiosError | null, resp: null | Resp) => any
) => any;
const AddSurvey: AddSurveyType = (Params, callback) => {
  AxiosInstance.post("/surveys", { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => callback(error, null));
};

export { AddSurvey };
