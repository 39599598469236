import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Box,
  BoxProps,
} from "@mui/material";
import React from "react";

interface Item {
  hidden?: boolean;
  label: string | React.ReactNode;
  icon?: React.ElementType;
  onClick?: (index: number) => any;
  "data-cy"?: string;
}
export interface CustomMenuProps {
  menuData?: Item[];
  menuChildren?: any;
  open?: boolean;
  onChildrenClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  anchorEl?: Element | ((element: Element) => Element) | null | undefined;
  fitContainer?: boolean;
  buttonContainerProps?: BoxProps & { "data-cy"?: string };
  disabled?: boolean;
}

const CustomMenu: React.FunctionComponent<CustomMenuProps> = (props) => {
  const {
    menuData,
    menuChildren,
    open,
    onChildrenClick,
    anchorEl,
    fitContainer,
    disabled,
    buttonContainerProps,
  } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(undefined);
  const handleClick = (e: any) => setMenuAnchorEl(e.currentTarget);
  const handleClose = () => setMenuAnchorEl(undefined);

  return (
    <>
      {!disabled && (
        <Menu
          onClose={handleClose}
          open={typeof open !== "undefined" ? open : Boolean(menuAnchorEl)}
          anchorEl={typeof anchorEl !== "undefined" ? anchorEl : menuAnchorEl}
          onBlur={handleClose}
          MenuListProps={{
            sx: {
              width: fitContainer
                ? ((anchorEl || menuAnchorEl) as any)?.offsetWidth
                : undefined,
            },
          }}
        >
          {menuData
            ?.filter((item) => !item.hidden)
            ?.map((item, index) => (
              <ListItemButton
                dense
                key={index}
                onClick={() => {
                  item.onClick?.(index);
                  handleClose();
                }}
                data-cy={item?.["data-cy"]}
              >
                <ListItemText primary={item.label} />
                <ListItemIcon>{item.icon && <item.icon />}</ListItemIcon>
              </ListItemButton>
            ))}
          {menuChildren}
        </Menu>
      )}
      <Box
        display="flex"
        {...buttonContainerProps}
        onClick={(e) => {
          handleClick(e);
          onChildrenClick?.(e);
        }}
      >
        {props.children}
      </Box>
    </>
  );
};

export default CustomMenu;
