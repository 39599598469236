import { Button, ButtonProps } from "@mui/material";
import { IoTrashOutline } from "react-icons/io5";

interface DeleteButtonProps extends ButtonProps {}

const DeleteButton: React.FunctionComponent<DeleteButtonProps> = (props) => {
  return <Button variant="contained" color="secondary" startIcon={<IoTrashOutline />} {...props} />;
};

export default DeleteButton;

DeleteButton.defaultProps = {
  children: "Delete",
};
