import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import React from "react";

import { Alert } from "@mui/material";

import SelectMenu from "components/input-components/select-menu/SelectMenu";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import {
  AddProps,
  UpdateProps,
  useLogic,
} from "./add-update-quality-check.logic";

interface AddUpdateQualityCheckProps {
  onCancel?: () => any;
  open?: boolean;
}

const AddUpdateQualityCheck: React.FunctionComponent<
  AddUpdateQualityCheckProps & (AddProps | UpdateProps)
> = (Props) => {
  // const Validation = yup.object().shape({
  //   passed: yup.boolean().required(),
  //   report: yup.string().optional(),
  //   status: yup.string().required().oneOf(["draft", "published"]),
  //   type: yup.string().required().oneOf(["visual-check", "aflatoxin"]),
  // });

  const {
    cancelSuccessButtonGroupProps,
    Fields,
    isStatusPublished,
    IsLoadingData,
    dialogProps,
  } = useLogic({
    open: Props.open,
    onCancel: Props.onCancel,
    ...(Props.variant === "add"
      ? {
          onAdded: Props.onAdded,
          variant: Props.variant,
          aggregation_activity_id: Props.aggregation_activity_id,
        }
      : {
          onUpdated: Props.onUpdated,
          quality_check_id: Props.quality_check_id,
          variant: Props.variant,
        }),
  });

  return (
    <CustomDialogV2 {...dialogProps}>
      {/* <CustomDialog {...CustomDialogProps}> */}
      {Props.variant === "update" && IsLoadingData ? (
        <Typography>Loading ...</Typography>
      ) : (
        <Box width={350} sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            {Fields.map((item, index) => (
              <>
                <Grid item xs={12} key={index}>
                  {item.type === "menu" && (
                    <SelectMenu
                      Searchable={false}
                      OnChange={item.onChange as any}
                      options={item.options!}
                      value={item.value as any}
                      Placeholder={item.placeholder}
                      helperText={item.helperText}
                      error={item.error}
                      disabled={item.disabled}
                    />
                  )}
                  {item.type === "text" && (
                    <TextField
                      placeholder={item.placeholder}
                      variant="outlined"
                      size="small"
                      value={item.value}
                      name={item.name}
                      fullWidth
                      rows={item.rowsMax}
                      multiline={item.multiline}
                      onChange={item.onChange as any}
                      maxRows={item.rowsMax}
                      helperText={item.helperText}
                      error={item.error}
                      disabled={item.disabled}
                    />
                  )}
                  {item.type === "checkbox" && (
                    <FormControlLabel
                      control={
                        <Switch
                          color="success"
                          defaultChecked
                          checked={item.value as any}
                          disabled={item.disabled}
                        />
                      }
                      label={item.label as string}
                      onChange={item.onChange}
                    />
                  )}
                </Grid>
                {item.WarningOnPuplished && isStatusPublished && (
                  <Grid item xs={12}>
                    <Alert severity="warning">{item.WarningOnPuplished}</Alert>
                  </Grid>
                )}
              </>
            ))}
            <Grid
              item
              xs={12}
              textAlign="right"
              justifyContent="flex-end"
              display="flex"
            >
              <CancelSuccessButtonsGroup {...cancelSuccessButtonGroupProps} />
            </Grid>
          </Grid>
        </Box>
      )}
      {/* </CustomDialog> */}
    </CustomDialogV2>
  );
};

export default AddUpdateQualityCheck;
