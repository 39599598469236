import { CircularProgress, Grid } from "@mui/material";
import CustomPagnation, {
  CustomPagnationProps,
} from "components/custom-compnents/custom-pagnation/custom-pagnation";
import FarmerLabelComponent from "components/custom-compnents/farmer-label-component/farmer-label-component";
import FarmerLabelsPageHeader from "components/custom-compnents/farmer-labels-page-header/farmers-labels-page-header";
import AddUpdateFarmerLabel from "components/standalone-components/add-update-farmer-label-component/add-update-farmer-label-component";
import RemoveFarmerLabelsComponent from "components/standalone-components/remove-farmer-labels-component/remove-farmer-labels-component";
import { FunctionComponent } from "react";
import { IParams, useLogic } from "./farmer-labels-sub-page.logic";

interface FarmerLabelsSubPageProps extends IParams { }

const FarmerLabelsSubPage: FunctionComponent<FarmerLabelsSubPageProps> = (
  props
) => {
  const {
    data,
    handleNextPageClick,
    handlePerPageChange,
    handlePreviousPageClick,
    isLoading,
    totalCount,
    currentPage,
    perPage,
    handleSearchChange,
    opendItemSubPanelIds,
    toggleOpenItemSubPanel,
    handleDeleted,
    handleOpenAddLabelModal,
    openAddLabelModal,
    handleCancelAddLabel,
    handleAdded,
    handleCancelUpdate,
    handleItemIdToBeUpdated,
    itemIdToBeUpdated,
    handleUpdated,
    handleDeleteClick,
    labelsIdToBeDeleted,
    handleCancelDelete,
    isShowAddButton,
    isShowDeleteButton,
    isShowEditButton,
  } = useLogic(props);

  const pagnationProps: CustomPagnationProps = {
    from: perPage * currentPage,
    total: totalCount,
    isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
    isPreviousPageButtonDisabled: currentPage === 0,
    onRowPerPageChange: handlePerPageChange,
    onNextPageClick: handleNextPageClick,
    onPreviousPageClick: handlePreviousPageClick,
    rowsPerPage: perPage,
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FarmerLabelsPageHeader
          isShowCreateButton={isShowAddButton}
          createButtonProps={{ onClick: handleOpenAddLabelModal }}
          labelCountProps={{ count: totalCount }}
          searchProps={{
            onChange: (e) => handleSearchChange(e.currentTarget.value),
          }}
        />
      </Grid>
      {isLoading && (
        <Grid item xs={12} textAlign="center">
          <CircularProgress size={40} />
        </Grid>
      )}
      {!isLoading &&
        data.map((item) => (
          <Grid item xs={12}>
            <FarmerLabelComponent
              isShowDeleteButton={isShowDeleteButton}
              isShowEditButton={isShowEditButton}
              editButtonProps={{
                onClick: () => handleItemIdToBeUpdated(item.id),
              }}
              expandButtonProps={{
                onClick: () => toggleOpenItemSubPanel(item.id),
              }}
              openSubPanel={opendItemSubPanelIds.includes(item.id)}
              labels={item?.farmerLabelsByLabelKeyId?.nodes.map(
                (item) => item.value
              )}
              title={item.key}
              deleteButtonProps={{
                onClick: () => {
                  handleDeleteClick(
                    [item?.id]
                  )
                }
              }}
            />
          </Grid>
        ))}

      <Grid item xs={12}>
        <CustomPagnation {...pagnationProps} />
      </Grid>


      <AddUpdateFarmerLabel
        farmer_id={props.farmer_id}
        variant="add"
        onAdded={handleAdded}
        open={openAddLabelModal}
        onCancel={handleCancelAddLabel}
      />


      {itemIdToBeUpdated && <AddUpdateFarmerLabel
        farmer_id={props.farmer_id}
        variant="update"
        onCancel={handleCancelUpdate}
        key_id={itemIdToBeUpdated}
        onUpdated={handleUpdated}
        open={Boolean(itemIdToBeUpdated)}
      />}
      
      <RemoveFarmerLabelsComponent
        id_list={labelsIdToBeDeleted}
        open={labelsIdToBeDeleted.length > 0}
        onDeleted={handleDeleted}
        onCancel={handleCancelDelete}
      />
    </Grid>
  );
};

export default FarmerLabelsSubPage;
