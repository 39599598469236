import { Box, Button, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
import { IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { IProviderCode } from "models/provider-code";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
}
export interface Fields extends IProviderCode {
  selected: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onEditButtonClick?: (data: Fields) => any;
  onDeleteButtonClick?: (data: Fields) => any;
  isShowRemoveAction?: boolean;
  isShowEditAction?: boolean;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (
  params
) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "CODE",
      field: "code",
      hidden: !params.columnState?.find((item) => item.field === "code")
        ?.checked,
      render: (data) => (
        <span data-cy="agent-code-table-code">{data.code}</span>
      ),
    },
    {
      title: "ORGANIZATION",
      field: "provider",
      hidden: !params.columnState?.find((item) => item.field === "provider")
        ?.checked,
      render: (data) => (
        <span data-cy="agent-code-table-organization">
          {data?.provider?.name}
        </span>
      ),
    },
    {
      title: "COMMENT",
      field: "comment",
      hidden: !params.columnState?.find((item) => item.field === "comment")
        ?.checked,
      render: (data) => (
        <span data-cy="agent-code-table-comment">{data.comment}</span>
      ),
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: (
        <CustomCheckBox
          onChange={(e, value) =>
            params.customSelection?.onSelectAllChange?.(params.data, value)
          }
          checked={params.data.every((item) => item.selected)}
          indeterminate={
            params.data.some((item) => item.selected) &&
            !params.data.every((item) => item.selected)
          }
        />
      ),
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => (
        <CustomCheckBox
          checked={data.selected}
          onChange={(e, value) =>
            params.customSelection?.onRowSelectChange?.(data, value)
          }
        />
      ),
    });
  }

  return {
    actions: [
      (fields) => ({
        hidden: !params.isShowEditAction,
        icon: () => (
          <Button
            variant="text"
            startIcon={<IoCreateOutline />}
            onClick={() => params.onEditButtonClick?.(fields)}
            color="success"
            data-cy="edit-agent-code-button"
          >
            Edit
          </Button>
        ),
        onClick: () => null,
      }),
      (fields) => ({
        hidden: !params.isShowRemoveAction,
        icon: () => (
          <Button
            variant="text"
            startIcon={<IoTrashOutline />}
            onClick={() => params.onDeleteButtonClick?.(fields)}
            color="success"
            data-cy="delete-agent-code-button"
          >
            Delete
          </Button>
        ),
        onClick: () => null,
      }),
    ],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
