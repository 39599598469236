import { Box, Button, Chip, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
import { IoCreateOutline, IoOpenOutline, IoTrashOutline } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { ISenderId } from "models/sender-id";
import { Senders } from "enums/senders";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
}
export interface Fields extends ISenderId {
  selected: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onEditButtonClick?: (data: Fields) => any;
  onDeleteButtonClick?: (data: Fields) => any;
  onViewButtonClick?: (data: Fields) => any;
  isShowRemoveAction?: boolean;
  isShowEditAction?: boolean;
  isShowViewAction?: boolean;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (params) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    // {
    //   title: "PROVIDER NAME",
    //   field: "provider_name",
    //   hidden: !params.columnState?.find((item) => item.field === "provider_name")?.checked,
    //   render: (data) => data.providerId,
    // },
    {
      title: "Organization ID",
      field: "providerId",
      hidden: !params.columnState?.find((item) => item.field === "provider_id")?.checked,
      render: (data) => data.providerId,
    },
    // TODO: check the logic
    {
      title: "Sender Type",
      field: "sender_type",
      hidden: !params.columnState?.find((item) => item.field === "sender_type")?.checked,
      render: (data) => Senders[data.senderType],
    },
    {
      title: "STATUS",
      field: "status",
      hidden: !params.columnState?.find((item) => item.field === "status")?.checked,
      render: (data) => (data.status ? <Chip label="active" color="primary" variant="outlined" /> : <Chip label="inactive" variant="outlined" />),
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: <CustomCheckBox onChange={(e, value) => params.customSelection?.onSelectAllChange?.(params.data, value)} checked={params.data.every((item) => item.selected)} indeterminate={params.data.some((item) => item.selected) && !params.data.every((item) => item.selected)} />,
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => <CustomCheckBox checked={data.selected} onChange={(e, value) => params.customSelection?.onRowSelectChange?.(data, value)} />,
    });
  }

  return {
    actions: [
      (fields) => ({
        hidden: !params.isShowViewAction,
        icon: () => (
          <Button variant="text" onClick={() => params.onViewButtonClick?.(fields)} color="success" startIcon={<IoOpenOutline />}>
            View
          </Button>
        ),
        onClick: () => null,
      }),
      (fields) => ({
        hidden: !params.isShowEditAction,
        icon: () => (
          <Button variant="text" startIcon={<IoCreateOutline />} onClick={() => params.onEditButtonClick?.(fields)} color="success">
            Edit
          </Button>
        ),
        onClick: () => null,
      }),
      (fields) => ({
        hidden: !params.isShowRemoveAction,
        icon: () => (
          <Button variant="text" startIcon={<IoTrashOutline />} onClick={() => params.onDeleteButtonClick?.(fields)} color="success">
            Delete
          </Button>
        ),
        onClick: () => null,
      }),
    ],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
