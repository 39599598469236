import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { IQualityCheck } from "../../models/quality-check";

interface Params
  extends Pick<
    IQualityCheck,
    "type" | "status" | "report" | "passed" | "aggregation_id"
  > { }

type AddQualityCheckType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: any | null) => any
) => any;

const AddQualityCheck: AddQualityCheckType = (Params, callback) => {
  AxiosInstance.post("/quality-checks", { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { AddQualityCheck };
