import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";

export interface IResp {
  data: {
    cube: [
      {
        sms_message: {
          count?: number;
          unique_sms_count?:number;
        };
      }
    ];
  }
}

export interface IParams {
  date_range?: "This month" | "This week" | "This year";
  before_date?: string;
  region_id_list?: string[];
  county_id_list?: string[];
  constituency_id_list?: string[];
  ward_id_list?: string[];
  unique_sms_count?: boolean;
  provider_id?: string;
  status_sent?: boolean;
}

export const getSMSMessagesCountKpi = async (Params: IParams) => {
//   const graphQL = `
//   query  ($filter:RootWhereInput,$uniqueSMSCount:Boolean!){ 
//     cube (where:$filter){
       
//       smsMessage(orderBy: {dateCreated: asc } ) {
//         uniqueSMSCount @include(if:$uniqueSMSCount)
//         count @skip (if:$uniqueSMSCount)
//       }
//     }
//   }
// `;

//   const variables = {
//     uniqueSMSCount: !!params.uniqueSMSCount,
//     filter: {
//       smsMessage: {
//         sendAt: { inDateRange: params.date_range, afterDate: params.before_date },
//         providerId: { equals: params.provider_id },
//         statusSent: { equals: params.statusSent?.toString() },
//       },

//       regions: { regionId: { in: params.region_id_list } },
//       constituencies: { constituencyId: { in: params.constituency_id_list } },
//       wards: { wardId: { in: params.ward_id_list } },
//       counties: { countyId: { in: params.county_id_list } },
//       providerOptins: { providerId: { equals: params.provider_id } },
//     },
//   };

  return await AxiosInstance.get<IResp>("/farmer-analytics/find-sms-count-kpi", {params: Params});
};
