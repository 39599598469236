import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Sender } from "models/sender";
interface Abstract {
  provider_id: string;
  status: boolean;
}

interface AfParams {
  sender_type: "Africastalking_sender";
  at_sender_id: string;
  at_auth_key: string;
  at_username: string;
}

interface InfobipParams {
  sender_type: "Infobip_sender";
  phone_number: string;
  base_url: string;
  infobip_auth_key: string;
}

export type Params = Abstract & (AfParams | InfobipParams);

type AddSenderType = (Params: Params, callback: (error: null | AxiosError, Resp: null | Sender) => any) => any;
const AddSender: AddSenderType = (Params, callback) => {
  AxiosInstance.post("/senders", { ...Params })
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { AddSender };
