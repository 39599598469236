// export interface IParams {}

import { ICountyLocation } from "models/county-location";
import { useState } from "react";

export const useLogic = () => {
  const [location, setlocation] =
    useState<ICountyLocation | undefined>(undefined);

  const handleCountyInputChange = (
    value: ICountyLocation | null,
    reason: any
  ) => {
    value ? setlocation(value) : setlocation(undefined);
  };

  return { location, handleCountyInputChange };
};
