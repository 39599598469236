import { Grid } from "@mui/material";
import CustomInfoCardWithIcon from "components/custom-compnents/custom-info-card-with-icon/custom-info-card-with-icon";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";
import { FunctionComponent } from "react";
import { IoSwapHorizontalSharp } from "react-icons/io5";
import { IParams, useLogic } from "./ussd-interactions-kpi-section.logic";

interface UssdInteractionsKpiSectionProps extends IParams {}

const UssdInteractionsKpiSection: FunctionComponent<UssdInteractionsKpiSectionProps> = (props) => {
  const { month, week, year } = useLogic(props);

  const kpiDataArray = [
    { value: week, icon: IoSwapHorizontalSharp, title: "USSD Interactions In Last 7 Days" },
    { value: month, icon: IoSwapHorizontalSharp, title: "USSD Interactions In Last 30 Days" },
    { value: year, icon: IoSwapHorizontalSharp, title: "USSD Interactions In Last 365 Days" },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomTitle label="Farmer Activity" />
      </Grid>
      {kpiDataArray.map((item) => (
        <Grid item xs={12}>
          <CustomInfoCardWithIcon actionValue={item.value?.toString()} icon={item.icon} primaryText={item.title} />
        </Grid>
      ))}
    </Grid>
  );
};

export default UssdInteractionsKpiSection;
