import { GetFarmerLabelsDistinct } from "api/farmer-labels/get-distinct";
import { useDebounce } from "hooks/debounce-hook";
import { IFarmerLabel } from "models/farmer-label";
import { useEffect, useState } from "react";
import { Value } from "./labels-input";

export interface IParams {
  key_id?: string;
}

export const useLogic = (params: IParams) => {
  const { key_id } = params;

  const [options, setOptions] = useState<Value[]>([]);

  const [searchTerm, setSearchTerm] = useState("");

  const [page, setPage] = useState(1);

  const [isLastResult, setisLastResult] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const [isLoading, setIsLoading] = useState(true);

  const [totalCount, setTotalCount] = useState(0);

  // reset pagenation if search or key_id changes
  useEffect(() => {
    setPage(1);
  }, [debouncedSearchTerm, key_id]);

  useEffect(() => {
    if (!key_id) {
      setOptions([]);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      GetFarmerLabelsDistinct(
        {
          distinct: true,
          value: debouncedSearchTerm,
          page,
          pageSize: 10,
          key_ids: key_id ? [key_id] : undefined,
        },
        (error, resp) => {
          if (error) console.log(error);
          else if (resp) {
            if (resp.items.length < 10) setisLastResult(true);
            if (resp.items.length >= 10) setisLastResult(true);
            setTotalCount(resp.total);
            setIsLoading(false);
            setOptions(
              resp.items.map((item) => ({ value: item.id, label: item.value }))
            );
          }
        }
      );
    }
  }, [debouncedSearchTerm, page, key_id]);

  return {
    options,
    setSearchTerm,
    isLoading,
    isLastResult,
    totalCount,
    page,
    setPage,
  };
};
