import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  switchButtonRoot: {
    marginInline: "4px",
  },
  switchButtonSelected: {
    color: "#44b16e",
    borderColor: "#44b16e !important",
  },
});
