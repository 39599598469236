import { DeleteBroadcasts } from "api/broadcasts/delete-broadcasts";
import { DeleteUser } from "api/user/delete-user";
import { AxiosInstance } from "configs/axios";
import React from "react";

export interface IParams {
  onDeleted?: () => any;
  id: string;
}
export const useLogic = (params: IParams) => {
  const [isSubmittingDelete, setiIsSubmittingDelete] = React.useState(false);
  const { onDeleted, id } = params;

  const handleDeleteConfirm = () => {
    setiIsSubmittingDelete(true);
    AxiosInstance.delete("/provider-codes/" + id).then((response) => {
      setiIsSubmittingDelete(false);
      onDeleted?.();
    });
  };

  return { isSubmittingDelete, handleDeleteConfirm };
};
