import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
interface Params {
  page?: number;
  pageSize?: number;
}
interface Resp {
  msgs: {
    provider_id: string;
    sms_status: { queued: number; accepted: number; failed: number; sent: number };
  }[];
  providers: { [id: string]: string }[];
  totalCount: number;
}

type GetMessagesStatusType = (Params: Params, callback: (error: null | AxiosError, Resp: null | Resp) => any) => any;
const GetMessagesStatus: GetMessagesStatusType = (Params, callback) => {
  AxiosInstance.get("/sms-messages-statistics", {
    params: {
      ...Params
    }
  })
    .then((resp) => callback(null, {
      ...resp.data,
      msgs: resp.data.items.map((item: any) => ({ ...item, sms_status: { ...item.sms_status, accepted: item.sms_status.pending } })),
      totalCount: resp.data.total
    }))
    .catch((error) => callback(error, null));
};

export { GetMessagesStatus };
