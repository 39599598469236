import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { IDevice } from "../../../models/device";

interface Params {
  page: number;
  pageSize: number;
  assigned_devices?: boolean;
  imei?: number;
}

const get_unattached_devices = (
  Params: Params,
  callback: (
    error: AxiosError | null,
    data: {
      items: IDevice[];
      total: number;
      page: number;
      per_page: number;
    } | null
  ) => any
) => {
  AxiosInstance.get("/devices", { params: Params })
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

const attach_device_to_farmer = (
  { farmer_id, device_id_list }: { farmer_id: string; device_id_list: string[] },
  callback: (error?: AxiosError) => any
): any => {
  AxiosInstance.post("/devices/" + farmer_id, { device_id_list })
    .then((resp) => callback())
    .catch((error) => callback(error));
};

export { get_unattached_devices, attach_device_to_farmer };
