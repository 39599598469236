import MaterialTable from "@material-table/core";
import CustomPagnation from "components/custom-compnents/custom-pagnation/custom-pagnation";
import {
  Fields,
  Params,
  schema,
} from "./custom-broadcast-messages-table.schema";
import { Box, Grid } from "@mui/material";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import { CustomPagnationProps } from "components/custom-compnents/custom-pagnation/custom-pagnation";
import CustomFiltersContainer, {
  CustomFiltersContainerProps,
} from "../custom-filters-container/custom-filters-container";
import CustomBroadcastMessagesTableActionBar, {
  CustomBroadcastMessagesTableActionBarProps,
} from "../custom-broadcast-messages-table-action-bar/custom-broadcast-messages-table-action-bar";
import CallbacksTableFilterForm, {
  CallbacksTableFilterFormProps,
} from "components/forms/callbacks-table-filter-form/callbacks-table-filter-form";
// import FarmsActivitesTableFilterForm, {
//   FarmsActivitesTableFilterFormProps,
// } from "components/forms/farms-activites-table-filter-form/farms-activites-table-filter-form";

export type Actions = "add" | "delete" | "update";

export interface CustomBroadcastMessagesTableProps {
  countLabelProps?: CountLabelProps;
  showFilterArea?: boolean;
  filterContainerProps?: CustomFiltersContainerProps;
  //   filterFormProps?: FarmsActivitesTableFilterFormProps;
  pagnationProps?: CustomPagnationProps;
  actionBarProps?: CustomBroadcastMessagesTableActionBarProps;
  filterFormProps?: CallbacksTableFilterFormProps;
  data: Fields[];
  isLoading?: boolean;
  customSelection?: Params["customSelection"];
  onEditButtonClick?: Params["onEditButtonClick"];
  onRemoveButtonClick?: Params["onRemoveButtonClick"];
  onOrderChange?: (
    orderBy: number,
    orderDirection: "desc" | "asc"
  ) => void | undefined;
  ActionsToExclude?: Actions[];
}

const CustomBroadcastMessagesTable: React.FunctionComponent<CustomBroadcastMessagesTableProps> =
  (props) => {
    const {
      countLabelProps,
      showFilterArea,
      pagnationProps,
      filterContainerProps,
      filterFormProps,
      actionBarProps,
      data,
      isLoading,
      customSelection,
      onOrderChange,
      onEditButtonClick,
      onRemoveButtonClick,
      ActionsToExclude
    } = props;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ paddingLeft: 0 }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingLeft: 0 }}
          >
            <Grid item md={3} xs={12}>
              <CountLabel
                {...countLabelProps}
                label="All Broadcasts Messages"
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Box width={840} maxWidth="100%">
                  <CustomBroadcastMessagesTableActionBar {...actionBarProps} />
                </Box>
              </Box>
            </Grid>
            {showFilterArea && (
              <Grid item xs={12}>
                <CustomFiltersContainer {...filterContainerProps}>
                  <CallbacksTableFilterForm {...filterFormProps} />
                  {/* <FarmsActivitesTableFilterForm {...filterFormProps} /> */}
                </CustomFiltersContainer>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            {...schema({
              onEditButtonClick,
              onRemoveButtonClick,
              customSelection,
              data,
              columnState: actionBarProps?.columnsState,
              ActionsToExclude
            })}
            isLoading={isLoading}
            onOrderChange={onOrderChange}
          />
        </Grid>
        <Grid item xs={12}>
          {pagnationProps && <CustomPagnation {...pagnationProps} />}
        </Grid>
      </Grid>
    );
  };

export default CustomBroadcastMessagesTable;
