import React from "react";
// import MaterialTable from "material-table";
// import Schema, { FieldsToExclude, Params } from "./schema";
// import { Box, Paper } from "@mui/material";
// import ColumnsCheckComponent, {
//   dataType,
// } from "components/custom-compnents/columns-check-dialog/columns-check-dialog";
import CustomCallbacksTable from "components/custom-compnents/custom-callbacks-table/custom-callbacks-table";
import { columnsToExclude, useLogic } from "./callbacks-table.logic";
import UpdateCallbackComponent from "components/standalone-components/update-callback-component/update-callback-component";

export interface CallbacksTableProps {
  title?: string;
  farmer_id?: string;
  sorting?: boolean;
  columnsToExclude?: columnsToExclude;
}

const CallbacksTable: React.FC<CallbacksTableProps> = ({
  // title,
  farmer_id,
  columnsToExclude,
}) => {
  // const [OpenColumnsDialog, SetOpenColumnsDialog] = React.useState(false);
  // const HandleColumnsOk = () => SetOpenColumnsDialog(false);
  // const [ColumnsData, SetColumnsData] = React.useState<dataType[]>([
  //   { label: "Created", checked: true, name: "dateCreated" },
  //   { label: "Modified", checked: true, name: "lastModified" },
  //   { label: "Farmer Name", checked: true, name: "farmer_name" },
  //   { label: "Status", checked: true, name: "type" },
  //   { label: "Note", checked: true, name: "notes" },
  //   { label: "Farmer Phone", checked: true, name: "farmer_phone" },
  // ]);
  // const HandleColumnsButtonClicked = () => SetOpenColumnsDialog(true);

  const { tableProps, editcallbackComponentProps } = useLogic({
    farmer_id,
    columnsToExclude,
  });
  return (
    <>
      <UpdateCallbackComponent {...editcallbackComponentProps} farmer_id={farmer_id!} />
      <CustomCallbacksTable {...tableProps} />
    </>
    // <Box p={2} component={Paper}>
    //   <ColumnsCheckComponent
    //     onOk={HandleColumnsOk}
    //     open={OpenColumnsDialog}
    //     onChange={(newData) => SetColumnsData(newData)}
    //     data={ColumnsData}
    //   />
    //   <MaterialTable
    //     {...Schema({
    //       title,
    //       farmer_id,
    //       excludedFields: ColumnsData.filter((item) => !item.checked)
    //         .map((item) => item.name)
    //         .concat(excludedFields || []) as FieldsToExclude,
    //       OnColumnsButtonClicked: HandleColumnsButtonClicked,
    //     })}
    //   />
    // </Box>
  );
};

export default CallbacksTable;
