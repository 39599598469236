import { FunctionComponent } from "react";
import CustomWhatsappMessagesTemplateTable from "components/custom-compnents/custom-whatsapp-messages-templates-table/custom-whatsapp-messages-template-table";
import { useLogic } from "./whatsapp-templates-table.logic";
import AddUpdateWhatsappTemplate from "components/standalone-components/add-update-whatsapp-template/add-update-whatsapp-template";
import RemoveWhatsappTemplateDialog from "components/standalone-components/remove-whatsapp-template-dialog/remove-whatsapp-template-dialog";
import ShowMessageTemplateDetailsDialog from "components/standalone-components/show-message-template-details-dialog/show-message-template-details-dialog";
import { IMessageCategory } from "models/message-category";
import { Alert, Grid } from "@mui/material";

interface WhatsappTemplatesTableProps {}

const WhatsappTemplatesTable: FunctionComponent<WhatsappTemplatesTableProps> = () => {
  const {
    data,
    columnsState,
    handleColumnStateChange,
    handleNextPageClick,
    handlePerPageChange,
    handlePreviousPageClick,
    isLoading,
    perPage,
    currentPage,
    totalCount,
    handleCancelAdd,
    openAddDialog,
    onAdded,
    handleOpenAddDialog,
    handleCancelUpdate,
    handleUpdated,
    onEditButtonClick,
    rowToUpdate,
    handleCancelDelete,
    handleRemoveButtonClick,
    handleRemoved,
    rowToRemove,
    handleCancelShowDetails,
    handleShowDetails,
    rowToShowDetails,
    setshowFilterArea,
    showFilterArea,
    categoryFilter,
    setcategoryFilter,
    handleApplyFilterClick,
    handleCancelFilterButtonClick,
    loadingwhatsappSenders,
    whatsappSenders,
  } = useLogic();



  return (
    <>
      <ShowMessageTemplateDetailsDialog open={!!rowToShowDetails} id={rowToShowDetails?.id ?? ""} onOk={handleCancelShowDetails} />
      <RemoveWhatsappTemplateDialog id={rowToRemove?.id ?? ""} onCancel={handleCancelDelete} onDeleted={handleRemoved} open={Boolean(rowToRemove)} />
      <AddUpdateWhatsappTemplate variant="update" open={!!rowToUpdate} id={rowToUpdate?.id ?? ""} onCancel={handleCancelUpdate} onUpdated={handleUpdated} />
      <AddUpdateWhatsappTemplate variant="add" open={openAddDialog} onCancel={handleCancelAdd} onAdded={onAdded} />
      <Grid container spacing={2}>
        {whatsappSenders.length === 0 && !loadingwhatsappSenders && (
          <Grid item xs={12}>
            <Alert severity="warning">This functionality is not enabled for your organization. Contact us to purchase a WhatsApp Sender ID!</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomWhatsappMessagesTemplateTable
            data={data}
            countLabelProps={{
              count: totalCount,
            }}
            actionBarProps={{
              filterButtonProps: {
                onClick: () => setshowFilterArea(true),
              },
              addButtonProps: {
                disabled: whatsappSenders?.length === 0,
                onClick: handleOpenAddDialog,
              },
              isShowColumnButton: true,
              isShowFilterButton: false,
              isShowCreateButton: true,

              columnsState,
              onColumnsStateChange: handleColumnStateChange,
            }}
            filterFormProps={{
              categoryFilterProps: {
                value: categoryFilter,
                onChange: (value) => value && setcategoryFilter(value as IMessageCategory[]),
              },
              isShowCategoryFilter: true,
            }}
            filterContainerProps={{
              CancelSuccessButtonGroupProps: {
                SuccessButtonProps: {
                  onClick: handleApplyFilterClick,
                },
                CancelButtonProps: {
                  onClick: handleCancelFilterButtonClick,
                },
              },
            }}
            isLoading={isLoading}
            onEditButtonClick={onEditButtonClick}
            onDeleteButtonClick={handleRemoveButtonClick}
            onViewButtonClick={handleShowDetails}
            isShowViewAction={true}
            // isShowEditAction={true}
            isShowRemoveAction={true}
            showFilterArea={showFilterArea}
            pagnationProps={{
              from: perPage * currentPage,
              total: totalCount,
              isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
              isPreviousPageButtonDisabled: currentPage === 0,
              onRowPerPageChange: handlePerPageChange,
              onNextPageClick: handleNextPageClick,
              onPreviousPageClick: handlePreviousPageClick,
              rowsPerPage: perPage,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WhatsappTemplatesTable;
