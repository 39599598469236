import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Activity } from "models/activity";

interface Params {
  day: number;
  month: number;
  year: number;
  aggr_last_editor: string;
  aggr_quantity_kg: number;
  aggr_payment_method: string;
  aggr_status: string;
  aggr_total_price_ksh: number;
  activity_id: string;
  farm_id: string;
  crop_id: string;
  variety_id?: string;
}

type UpdateAggregationActivityType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: any | null) => any
) => any;

const UpdateAggregationActivity: UpdateAggregationActivityType = (
  Params,
  callback
) => {
  AxiosInstance.patch(`/farm-activites/aggregation/${Params.activity_id}`, {
    ...Params,
  })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { UpdateAggregationActivity };
