import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";

export interface IResp {
  data: {
    cube: [
      {
        ussd_log: {
          unique_ussd_count: number;
        };
      }
    ];
  }
}

export interface IParams {
  date_range?: "This month" | "This week" | "This year";
  before_date?: string;
  region_id_list?: string[];
  county_id_list?: string[];
  constituency_id_list?: string[];
  ward_id_list?: string[];
  provider_id?: string;
}

export const getUSSDInteractionsCountKpi = async (Params: IParams) => {
//   const graphQL = `
//   query ($filter:RootWhereInput)  { 
//     cube(where: $filter) {
//       ussdLog {
//         uniqueUSSDCount
//       }
//     }
//   }
// `;

//   const variables = {
//     filter: {
//       ussdLog: {
//         interaction: { inDateRange: params.date_range, afterDate: params.before_date },
//       },
//       regions: { regionId: { in: params.region_id_list } },
//       constituencies: { constituencyId: { in: params.constituency_id_list } },
//       wards: { wardId: { in: params.ward_id_list } },
//       counties: { countyId: { in: params.county_id_list } },
//       providerOptins: { providerId: { equals: params.provider_id } },
//     },
//   };

  return await AxiosInstance.get<IResp>("/farmer-analytics/find-ussd-kpi", { params: Params});
};
