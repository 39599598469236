import { Alert, Box, CircularProgress, Grid, Typography } from "@mui/material";
import CancelDeleteButtonsGroup from "components/custom-compnents/cancel-delete-buttons-group/cancel-delete-buttons-group";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CustomDrawer from "components/custom-compnents/custom-drawer/custom-drawer";
import CustomMenuButton from "components/custom-compnents/custom-menu-button/custom-menu-button";
import CustomMenu from "components/custom-compnents/custom-menu-component/custom-menu-component";
import CustomShowEditCard from "components/custom-compnents/custom-show-edit-card/custom-show-edit-card";
import CustomSubtitle from "components/custom-compnents/custom-subtitle/custom-subtitle";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import DeleteEditButtonGroup from "components/custom-compnents/delete-edit-button-group/delete-edit-button-group";
import { IoWarning } from "react-icons/io5";
import { useLogic } from "./show-edit-aggregation-event-component.logic";

interface ShowEditDeleteAggregationEventComponentProps {
  farm_id: string;
  activityId: string;
  open?: boolean;
  onBackClick?: () => any;
}

const ShowEditDeleteAggregationEventComponent: React.FunctionComponent<ShowEditDeleteAggregationEventComponentProps> =
  (props) => {
    const { activityId, farm_id, open, onBackClick } = props;
    const {
      EventDetailsData,
      cropDetailsData,
      isInEditMode,
      editDeleteButtonGroupProps,
      isLoadingData,
      ConfirmCancelButtonGroupProps,
      OpenDeleteDialog,
      deleteActionGroup,
      isUserhasPermissionToUpdateAndDeleteActivity,
    } = useLogic({
      activityId,
      farm_id,
    });

    return (
      <CustomDrawer
        NavigationHeaderProps={{
          label: "Activity Information",
          backButtonProps: { onClick: onBackClick },
        }}
        open={open}
        ActionArea={
          isUserhasPermissionToUpdateAndDeleteActivity &&
          (isInEditMode ? (
            <CancelSuccessButtonsGroup {...ConfirmCancelButtonGroupProps} />
          ) : (
            <DeleteEditButtonGroup {...editDeleteButtonGroupProps} />
          ))
        }
      >
        <CustomDialogV2
          open={OpenDeleteDialog}
          header={
            <Typography
              color="#ff5252"
              variant="h6"
              display="flex"
              alignItems="center"
            >
              <IoWarning size={25} style={{ marginRight: 5 }} /> Delete
              Aggergation Event
            </Typography>
          }
          footer={
            <Box display="flex" justifyContent="flex-end">
              <Box width={300}>
                <CancelDeleteButtonsGroup {...deleteActionGroup} />
              </Box>
            </Box>
          }
        >
          <Box width={450}>
            <Typography variant="subtitle1">
              are you sure you want to delete this aggregation event ?
            </Typography>
          </Box>
        </CustomDialogV2>
        {isLoadingData ? (
          <Box textAlign="center">
            <CircularProgress size={20} />
          </Box>
        ) : (
          <>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              sx={{ mt: 1 }}
            >
              <Grid item xs={12}>
                <CustomSubtitle label="Crop Details" />
              </Grid>
              {cropDetailsData.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <CustomShowEditCard
                    editComponent={
                      <>
                        {item.type === "menu" && (
                          <CustomMenu menuData={item.menuData}>
                            <CustomMenuButton
                              disabled={item.disabled}
                              helperText={item.helperText}
                              error={item.error}
                            >
                              {item.loading
                                ? item.loadingLabel
                                : item.isEmpty
                                  ? item.emptyLabel
                                  : item.disabled
                                    ? item.disabledLabel
                                    : item.value ?? item.label}
                            </CustomMenuButton>
                          </CustomMenu>
                        )}
                        {item.type === "text" && (
                          <CustomTextField
                            error={item.error}
                            helperText={item.helperText}
                            fullWidth
                            placeholder={item.label}
                            value={item.value}
                            onChange={item.onChange}
                          />
                        )}
                      </>
                    }
                    ShowNode={item.value}
                    label={item.label}
                    editable={isInEditMode}
                  />
                </Grid>
              ))}
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ mt: 3 }}
              justifyContent="flex-end"
            >
              <Grid item xs={12}>
                <CustomSubtitle label="Event Details" />
              </Grid>
              {EventDetailsData.map((item) => (
                <Grid item {...item.gridProps}>
                  <CustomShowEditCard
                    label={item.label}
                    editComponent={
                      <>
                        {item.type === "menu" && (
                          <CustomMenu menuData={item.menuData}>
                            <CustomMenuButton
                              error={item.error}
                              helperText={item.helperText}
                            >
                              {item.value ?? item.label}
                            </CustomMenuButton>
                          </CustomMenu>
                        )}
                        {item.type === "text" && (
                          <CustomTextField
                            helperText={item.helperText}
                            error={Boolean(item.helperText)}
                            onChange={item.onChange}
                            fullWidth
                            placeholder={item.label}
                            value={item.value}
                          />
                        )}
                      </>
                    }
                    ShowNode={item.value}
                    editable={isInEditMode}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </CustomDrawer>
    );
  };

export default ShowEditDeleteAggregationEventComponent;
