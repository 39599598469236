import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
// import { IoOpenOutline, IoRemoveSharp } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
  selectedFarmersIds?: string[];
  selectedByPhone?: boolean;
}
export interface Fields {
  selected: boolean;
  send_at: string;
  provider_id: string;
  last_status: string;
  status_date: string;
  content: string;
  status_accepted: boolean;
  status_failed: boolean;
  status_queued: boolean;
  status_sent: boolean;
}

enum MessageStatus {
  Accepted = 'Accepted',
  Failed = 'Failed',
  Queued = 'Queued',
  Sent = 'Sent',
}


const getStatus = (fields: Fields): MessageStatus => {
  if (fields.status_accepted) {
    return MessageStatus.Accepted
  }
  if (fields.status_failed) {
    return MessageStatus.Failed
  }

  if (fields.status_queued) {
    return MessageStatus.Queued
  }

  return MessageStatus.Sent
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onDeleteButtonClick?: (data: Fields) => any;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (params) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "SEND AT",
      field: "send_at",
      hidden: !params.columnState?.find((item) => item.field === "send_at")?.checked,
      render: (data) => data.send_at && new Date(data.send_at).toLocaleString(),
      sorting: true,
    },
    {
      title: "Organization",
      field: "provider",
      hidden: !params.columnState?.find((item) => item.field === "provider")?.checked,
      render: (data) => data.provider_id,
      sorting: false,
    },
    {
      title: "STATUS",
      field: "last_status",
      hidden: !params.columnState?.find((item) => item.field === "last_status")?.checked,
      render: (data) => {
        return getStatus(data);
      },
      sorting: false,
    },
    {
      title: "STATUS DATE",
      field: "status_date",
      hidden: !params.columnState?.find((item) => item.field === "status_date")?.checked,
      render: (data) => data.status_date && new Date(data.status_date).toLocaleString(),
      sorting: false,
    },
    {
      title: "MESSAGES",
      field: "messages",
      hidden: !params.columnState?.find((item) => item.field === "messages")?.checked,
      render: (data) => data.content,
      sorting: false,
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: <CustomCheckBox onChange={(e, value) => params.customSelection?.onSelectAllChange?.(params.data, value)} checked={params.data.every((item) => item.selected)} indeterminate={params.data.some((item) => item.selected) && !params.data.every((item) => item.selected)} />,
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => <CustomCheckBox checked={data.selected} onChange={(e, value) => params.customSelection?.onRowSelectChange?.(data, value)} />,
    });
  }

  return {
    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
