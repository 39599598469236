import { Grid, Paper, Typography } from "@mui/material";
import ProvidersInput from "components/input-components/providers-input/providers-input";
import { IProvider } from "models/provider";
import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setProviderId, setProviderName } from "store/reducers/farmers-analytics-page/farmer-analytics-page/farmer-analytics-page-slice";

interface ProviderFilterContainerProps { }

const ProviderFilterContainer: FunctionComponent<ProviderFilterContainerProps> = () => {
  const user = useAppSelector((root) => root.appReducer.user);

  const dispatcher = useAppDispatch();

  const farmerAnalyticsPageState = useAppSelector((root) => root.FarmerAnalyticsPageState);

  return (
    <Grid container justifyContent="space-between" spacing={2} direction="row-reverse">
      <Grid item xs={12} md={4} lg={3} alignItems="center">
        {(user?.menuroles === "admin" || user?.menuroles === "org_admin") && (
          <Paper>
            <ProvidersInput
              viewType="subs"
              value={farmerAnalyticsPageState.selectedProviderId}
              superOrgId={user?.provider_id || ""}
              onChange={(value) => {
                dispatcher(setProviderName((value as IProvider)?.name));
                dispatcher(setProviderId((value as IProvider)?.id));
              }}
            />
          </Paper>
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={6}>
        <Typography variant="h6">KPIs for</Typography>
        <Typography variant="h3" sx={(theme) => ({ color: theme.palette.success.main })}>
          {farmerAnalyticsPageState.selectedProviderName ?? user?.providerName}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProviderFilterContainer;
