import React, { useEffect } from "react";
import { throttle } from "lodash";
import { attach_device_to_farmer, get_unattached_devices } from "./Api";
import { IDevice } from "models/device";
import { Farmer } from "models/farmers";
import CustomAttachDevices from "components/custom-compnents/custom-attach-devices/custom-attach-devices";

export interface AttachDeviceDialogProps {
  show?: boolean;
  onAttached?: () => void;
  Farmer?: Farmer;
  OnIgnore?: () => any;
}

const AttachDeviceDialog: React.FC<AttachDeviceDialogProps> = ({ show, OnIgnore, onAttached, Farmer }) => {
  const [options, SetOptions] = React.useState<IDevice[]>([]);

  const [SelectedOption, SetSelectedOption] = React.useState<IDevice[]>([]);

  const [isSubmitting, SetIsSubmitting] = React.useState(false);

  const HandleConfirm = () => {
    SetIsSubmitting(true);
    Farmer &&
      SelectedOption &&
      attach_device_to_farmer(
        {
          farmer_id: Farmer?.id,
          device_id_list: SelectedOption.map((item) => item.id),
        },
        (error) => {
          SetIsSubmitting(false);
          if (error) {
          } else {
            SetSelectedOption([]);
            onAttached && onAttached();
          }
        }
      );
  };

  const throttleFunction = throttle(
    (value) => {
      get_unattached_devices({
        page: 0,
        pageSize: 50,
        imei: value || undefined,
        assigned_devices: false
      }, (error, response) => {
        if (error) {
        } else if (response) {
          SetOptions(response.items);
        }
      });
    },
    1300,
    { leading: false }
  );

  useEffect(() => {
    throttleFunction("");
  }, []);

  return (
    <CustomAttachDevices
      open={show ?? false}
      options={options}
      onInputChange={(value) => {
        SetOptions([]);
        throttleFunction(value);
      }}
      value={SelectedOption}
      onChange={SetSelectedOption}
      name={`${Farmer?.firstName} ${Farmer?.lastName}`}
      actionButtonsProps={{
        CancelButtonProps: {
          onClick: OnIgnore,
        },
        SuccessButtonProps: {
          onClick: HandleConfirm,
          disabled: !SelectedOption || isSubmitting,
        },
      }}
    />
  );
};

export default AttachDeviceDialog;
