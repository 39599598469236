import { Button, ButtonProps } from "@mui/material";
import { IoCalendar } from "react-icons/io5";

interface DateMenuButtonProps extends ButtonProps {}

const DateMenuButton: React.FunctionComponent<DateMenuButtonProps> = (
  props
) => {
  return (
    <Button
      color="secondary"
      style={{ justifyContent: "space-between" }}
      endIcon={<IoCalendar style={{ marginLeft: 10 }} />}
      {...props}
    />
  );
};

export default DateMenuButton;
