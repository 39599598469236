import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { IEwalletTransaction } from "models/ewallet-transaction";
import moment from "moment";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
}
export interface Fields extends IEwalletTransaction {
  selected: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onEditButtonClick?: (data: Fields) => any;
  onDeleteButtonClick?: (data: Fields) => any;
  isShowRemoveAction?: boolean;
  isShowEditAction?: boolean;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (
  params
) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "ID",
      field: "id",
      hidden: !params.columnState?.find((item) => item.field === "id")?.checked,
      render: (data) => data.id,
    },

    {
      title: "From User",
      field: "from_ewallet_name",
      hidden: !params.columnState?.find((item) => item.field === "From User")
        ?.checked,
      render: (data) => (
        <span data-cy="transactions-table-sender-user-name">
          {data.from_ewallet_name ?? "-"}
        </span>
      ),
    },

    {
      title: "To User",
      field: "from_ewallet_name",
      hidden: !params.columnState?.find((item) => item.field === "To User")
        ?.checked,
      render: (data) => (
        <span data-cy="transactions-table-receiver-user-name">
          {data.to_ewallet_name ?? "-"}
        </span>
      ),
    },

    {
      title: "Amount",
      field: "amount",
      hidden: !params.columnState?.find((item) => item.field === "Amount")
        ?.checked,
      render: (data) => data.amount,
    },

    {
      title: "Mpesa Charge",
      field: "mpesa_charge",
      hidden: !params.columnState?.find((item) => item.field === "Mpesa Charge")
        ?.checked,
      render: (data) => data.mpesa_charge,
    },

    {
      title: "Type",
      field: "type",
      hidden: !params.columnState?.find((item) => item.field === "Type")
        ?.checked,
      render: (data) => data.type?.replaceAll("-", " "),
    },

    {
      title: "Time",
      field: "timestamp",
      hidden: !params.columnState?.find((item) => item.field === "Time")
        ?.checked,
      render: (data) => (
        <span data-cy="transactions-table-transaction-date">
          {moment
            .utc(data.timestamp)
            .format("YYYY/MM/DD hh:mma")
            .toLocaleString()}
        </span>
      ),
    },

    {
      title: "Request Id",
      field: "mpesa_req_id",
      hidden: !params.columnState?.find((item) => item.field === "Request Id")
        ?.checked,
      render: (data) => data.mpesa_req_id,
    },

    {
      title: "Response Code",
      field: "mpesa_resp_code",
      hidden: !params.columnState?.find(
        (item) => item.field === "Response Code"
      )?.checked,
      render: (data) => data.mpesa_resp_code,
    },

    {
      title: "Response Description",
      field: "mpesa_resp_descr",
      hidden: !params.columnState?.find(
        (item) => item.field === "Response Description"
      )?.checked,
      render: (data) => data.mpesa_resp_descr,
    },

    {
      title: "Response Code",
      field: "mpesa_result_code",
      hidden: !params.columnState?.find(
        (item) => item.field === "Response Code"
      )?.checked,
      render: (data) => data.mpesa_result_code,
    },

    {
      title: "Result Description",
      field: "mpesa_result_descr",
      hidden: !params.columnState?.find(
        (item) => item.field === "Result Description"
      )?.checked,
      render: (data) => data.mpesa_result_descr,
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: (
        <CustomCheckBox
          onChange={(e, value) =>
            params.customSelection?.onSelectAllChange?.(params.data, value)
          }
          checked={params.data.every((item) => item.selected)}
          indeterminate={
            params.data.some((item) => item.selected) &&
            !params.data.every((item) => item.selected)
          }
        />
      ),
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => (
        <CustomCheckBox
          checked={data.selected}
          onChange={(e, value) =>
            params.customSelection?.onRowSelectChange?.(data, value)
          }
        />
      ),
    });
  }

  return {
    // actions: [
    //   (fields) => ({
    //     hidden: !params.isShowEditAction,
    //     icon: () => (
    //       <Button variant="text" startIcon={<IoCreateOutline />} onClick={() => params.onEditButtonClick?.(fields)} color="success">
    //         Edit
    //       </Button>
    //     ),
    //     onClick: () => null,
    //   }),
    //   (fields) => ({
    //     hidden: !params.isShowRemoveAction,
    //     icon: () => (
    //       <Button variant="text" startIcon={<IoTrashOutline />} onClick={() => params.onDeleteButtonClick?.(fields)} color="success">
    //         Delete
    //       </Button>
    //     ),
    //     onClick: () => null,
    //   }),
    // ],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
