import React from "react";
import { TextField, Grid, Box, Tooltip, IconButton } from "@mui/material";
import { FaMinus, FaCheck, FaCog } from "react-icons/fa";
import SelectMenu, {
  option,
} from "components/input-components/select-menu/SelectMenu";
import { SurveyQuestionAnswer } from "models/survey-questions-answer";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";

interface SingleChoiceItemProps
  extends Pick<SurveyQuestionAnswer, "answer" | "next_qid"> {
  ScreenOptionsList: option[];
  IsInEditMode?: boolean;
  OnEditClicked?: () => any;
  OnDeleteClicked?: () => any;
  OnAnswerChange?: (new_answer: string) => any;
  OnAnswerScreenChange?: (screen_id: any) => any;
  OnDoneClicked?: () => any;
}

const SingleChoiceItem: React.FunctionComponent<SingleChoiceItemProps> = ({
  ScreenOptionsList,
  OnEditClicked,
  answer,
  next_qid,
  IsInEditMode,
  OnDeleteClicked,
  OnAnswerChange,
  OnAnswerScreenChange,
  OnDoneClicked,
}) => {
  return (
    <>
      <Grid item xs={5}>
        <CustomTextField
          disabled={!IsInEditMode}
          size="small"
          value={answer}
          placeholder="Add Answer"
          onChange={(e) => OnAnswerChange?.(e.currentTarget.value)}
          autoFocus={IsInEditMode}
        />
      </Grid>
      <Grid item xs={2}>
        <Box
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {IsInEditMode ? (
            <Tooltip title="ok">
              <IconButton size="small" onClick={OnDoneClicked}>
                <FaCheck />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Edit Answer" onClick={OnEditClicked}>
              <IconButton size="small">
                <FaCog />
              </IconButton>
            </Tooltip>
          )}
          {!IsInEditMode && (
            <Tooltip title="Remove answer">
              <IconButton size="small" onClick={OnDeleteClicked}>
                <FaMinus />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Grid>
      <Grid item xs={5}>
        <SelectMenu
          options={ScreenOptionsList}
          OnChange={(e) => OnAnswerScreenChange?.(e.target.value)}
          value={next_qid}
          Searchable={false}
          Placeholder="Select Screen"
          disabled={!IsInEditMode}
        />
      </Grid>
    </>
  );
};

export default SingleChoiceItem;
