import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  shiny: {
    border: "solid 1px #44b16e",
    color: "#44b16e",
  },
  label: {
    padding: 8,
    borderRadius: 5,
    "&:hover": {
      background: "#dbdbdb",
    },
  },
});

export { useStyles };
