import * as React from "react";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { Constituency } from "models/constituency";
import { Region } from "models/region";
import { County } from "models/county";
import { GetConstituencies } from "api/location/constituencies";

export interface ConstituencyInputProps {
  value: Array<Constituency>;
  SelectedRegions: Array<Region>;
  SelectedCounties: Array<County>;
  options: Array<Constituency>;
  onChange: (value: Array<Constituency>) => void;
  onOptionsChange: (value: Array<Constituency>) => void;
}

const ConstituencyInput: React.SFC<ConstituencyInputProps> = ({
  value,
  onChange,
  SelectedRegions,
  SelectedCounties,
  options,
  onOptionsChange,
}) => {
  const uniqOptions: Array<Constituency> = uniqBy(options, "id");
  const sortedOptions: Array<Constituency> = sortBy(
    uniqOptions,
    (item) => item?.countyByCountyId?.name
  );

  const [loading, SetLoading] = React.useState(false);

  React.useEffect(
    () => HandleInputChange(null),
    [SelectedRegions, SelectedCounties]
  ); // get data every single time Region or Counties tags change
  const HandleInputChange = (e: any) => {
    onOptionsChange && onOptionsChange([]);

    SetLoading(true);

    GetConstituencies(
      {
        page: 0,
        pageSize: 100,
        county_id_list: SelectedCounties
          ? SelectedCounties.map((item: County) => item.countyId)
          : undefined,
        region_id_list: SelectedRegions
          ? SelectedRegions.map((item: Region) => item.regionId)
          : undefined,
        is_attached_to_farmers: true,
        with_county: true,
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else {
          SetLoading(false);
          onOptionsChange && onOptionsChange(resp?.allConstituencies.items!);
        }
      }
    );
  };
  return (
    <Autocomplete
      loading={loading}
      onChange={(e, value) => onChange && onChange(value)}
      size="small"
      multiple
      value={value}
      groupBy={(option) => option.countyByCountyId.name}
      options={sortedOptions.filter((item) =>
        value.every((valueItem) => valueItem.id !== item.id)
      )}
      getOptionLabel={(option) => option?.name}
      fullWidth
      style={{ minWidth: 300 }}
      onInputChange={HandleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Constituency"
          data-cy="farmers-constituency-filter-country-select"
        />
      )}
    />
  );
};

export default ConstituencyInput;
