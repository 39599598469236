import { Alert, Box, Button, Grid, TextFieldProps } from "@mui/material";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import { FormEventHandler } from "react";

interface LoginFormProps {
  onEmailChange?: TextFieldProps["onChange"];
  onPasswordChange?: TextFieldProps["onChange"];
  onFormSubmit?: FormEventHandler<HTMLFormElement>;
  isError?: boolean;
  isSubmitButtonDisabled?: boolean;
}

const LoginForm: React.FunctionComponent<LoginFormProps> = (props) => {
  const FieldsData = [
    {
      label: "Email Address",
      required: true,
      name: "email",
      onChange: props.onEmailChange,
      type: "email",
      role: "textbox",
    },
    {
      label: "Password",
      required: true,
      name: "password",
      onChange: props.onPasswordChange,
      type: "password",
      role: "textbox",
    },
  ];
  return (
    <form onSubmit={props.onFormSubmit} data-testid="login-form">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.isError && (
            <Alert severity="error">username or password is not correct</Alert>
          )}
        </Grid>
        {FieldsData.map((item, index) => (
          <Grid item xs={12} key={index}>
            <CustomTextField
              inputProps={{
                style: { background: "white" },
                role: item.role,
              }}
              placeholder={item.label}
              required={item.required}
              fullWidth
              autoFocus={index === 0}
              onChange={item.onChange}
              name={item.name}
              type={item.type}
            />
          </Grid>
        ))}
      </Grid>
      <Box mt={5}>
        <Button
          color="success"
          data-testid="confirm"
          size="large"
          fullWidth
          type="submit"
          disabled={props.isSubmitButtonDisabled}
        >
          Login
        </Button>
      </Box>
    </form>
  );
};

export default LoginForm;
