import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { SurveyQuestionAnswer } from "models/survey-questions-answer";

interface Resp {
  items: SurveyQuestionAnswer[];
}
interface Params {
  survey_id: string;
  is_active?: boolean;
  question_id: string;
}
type GetSurveyQuestionAnswersType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: Resp | null) => any
) => any;
const GetSurveyQuestionAnswers: GetSurveyQuestionAnswersType = (
  Params,
  callback
) => {
  AxiosInstance.get(
    `/survey-question-answers/${Params.survey_id}/${Params.question_id}`,
    { params: { ...Params } }
  )
    .then((resp) => {
      callback(null, {
        items: resp.data.items.map((answer:any) => ({
          ...answer,
          uid: Math.floor(Math.random() * 99999)
        }))
      })
    })
    .catch((error) => callback(error, null));
};

export { GetSurveyQuestionAnswers };
