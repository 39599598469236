import CustomDialog, {
  CustomDialogProps,
} from "components/custom-compnents/custom-dialog/custom-dialog";
import * as React from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { FormikConfig, useFormik } from "formik";
import { Certificate } from "../../../models/certificate";
import { CreateCertificate } from "api/certificate/create-certificate";
import { GetCertificate } from "api/certificate/get-certificate";
import { UpdateCertificate } from "api/certificate/update-certificate";
import CustomDialogV2, {
  CustomDialogV2Props,
} from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";

interface AddUpdateCertificationComponentProps {
  open?: boolean;
  onCancel?: () => any;
}

interface AddCertitificationComponentProps {
  variant: "add";
  onAdded?: () => any;
}
interface UpdateCertitificationComponentProps {
  variant: "update";
  onUpdated?: () => any;
  certificate_id: string;
}

type FormValueProps = Pick<Certificate, "title" | "description" | "type">;

export type combinedType = AddUpdateCertificationComponentProps &
  (AddCertitificationComponentProps | UpdateCertitificationComponentProps);

const AddUpdateCertificationComponent: React.FunctionComponent<combinedType> =
  ({ open, onCancel, ...props }) => {
    const [Loadingata, SetLoadingData] = React.useState(true);
    const handleSubmit: FormikConfig<FormValueProps>["onSubmit"] = (
      value,
      action
    ) => {
      action.setSubmitting(true);
      if (props.variant === "add") {
        CreateCertificate(value, (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            action.setSubmitting(false);
            props.onAdded?.();
          }
        });
      } else if (props.variant === "update") {
        UpdateCertificate(
          { id: props.certificate_id, ...value },
          (error, resp) => {
            if (error) {
              console.log(error.message);
            } else if (resp) {
              action.setSubmitting(false);
              props.onUpdated?.();
            }
          }
        );
      }
    };

    React.useEffect(() => {
      if (props.variant === "update") {
        SetLoadingData(true);
        GetCertificate({ page:0, pageSize: 1, id: props.certificate_id }, (error, resp) => {
          if (error) {
            console.log(error);
          } else if (resp) {
            const certificate = resp.items[0];
            CreateCertificateFormik.setValues({
              description: certificate.description,
              title: certificate.title,
              type: certificate.type,
            });
            SetLoadingData(false);
          }
        });
      }
    }, [props.variant === "update" && props.certificate_id]);
    const CreateCertificateFormik = useFormik({
      initialValues: { title: "", description: "", type: "training" },
      onSubmit: handleSubmit,
    });
    const WrapperModal: CustomDialogV2Props = {
      open: open ?? false,
      header: props.variant === "add" ? "Add Certificate" : "Edit Certificate",
      // ActionButtonsProps: {
      //   ButtonsVariant: "ConfirmCancel",
      //   OnCancel: onCancel,
      //   OnConfirm: () => CreateCertificateFormik.submitForm(),
      //   DisableConfirm: CreateCertificateFormik.isSubmitting,
      // },
      footer: (
        <Box display={"flex"} justifyContent="flex-end">
          <CancelSuccessButtonsGroup
            CancelButtonProps={{
              onClick: onCancel,
              label: "Cancel",
            }}
            SuccessButtonProps={{
              onClick: () => CreateCertificateFormik.submitForm(),
              disabled: CreateCertificateFormik.isSubmitting,
              label: props.variant === "add" ? "Add Certificate" : "Save",
            }}
            RootGridProps={{ width: 340 }}
          />
        </Box>
      ),
    };

    const DataList = [
      {
        name: "title",
        Label: "Title",
        placeholder: "Insert Certificate Title",
        value: CreateCertificateFormik.values["title"],
        onChange: CreateCertificateFormik.handleChange,
      },
      {
        name: "description",
        Label: "Description",
        placeholder: "Insert Certificate Description",
        multiline: true,
        rowMax: 4,
        onChange: CreateCertificateFormik.handleChange,
        value: CreateCertificateFormik.values["description"],
      },
      {
        name: "type",
        Label: "Type",
        placeholder: "Insert Certificate Type",
        isStatic: true,
        onChange: CreateCertificateFormik.handleChange,
        value: "training",
      },
    ];
    return (
      <CustomDialogV2 {...WrapperModal}>
        {/* <CustomDialog {...WrapperModal}> */}
        <Box maxWidth={350} py={1}>
          {Loadingata && props.variant === "update" ? (
            <Typography>Loading ...</Typography>
          ) : (
            <Grid container spacing={2}>
              {DataList.map((item, index) => {
                const Wrapper = item.multiline ? TextField : CustomTextField;
                return (
                  <Grid item key={index} xs={12}>
                    <Wrapper
                      disabled={item.isStatic}
                      value={item.value}
                      autoFocus={index === 0}
                      fullWidth
                      placeholder={item.placeholder}
                      maxRows={item.rowMax}
                      rows={item.rowMax}
                      multiline={item.multiline}
                      size="small"
                      name={item.name}
                      onChange={item.onChange}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>

        {/* </CustomDialog> */}
      </CustomDialogV2>
    );
  };

export default AddUpdateCertificationComponent;
