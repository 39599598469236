import { Box } from "@mui/material";
import React from "react";
import FarmsTable from "components/data-tables/farms-table/farms-table";

export interface FarmsTabBoxProps {
  farmer_id: string;
}

const FarmsTabBox: React.SFC<FarmsTabBoxProps> = ({ farmer_id }) => {
  return (
    <Box>
      <FarmsTable by_farmer_id={farmer_id} />
    </Box>
  );
};

export default FarmsTabBox;
