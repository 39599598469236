import {
  Grid,
  Avatar,
  Box,
  Paper,
  Typography,
  useMediaQuery,
  ButtonProps,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { wardType } from "models/administrative-level";
import {
  IoCalendarNumberSharp,
  IoCallSharp,
  IoChevronForwardOutline,
  IoLocation,
  IoRadioButtonOnOutline,
} from "react-icons/io5";
import DeleteEditButtonGroup from "../delete-edit-button-group/delete-edit-button-group";
import { useStyles } from "./custom-farmer-info-card.styles";

export interface CustomFarmerInfoCardProps {
  name?: string;
  phone?: string;
  src?: string;
  editButtonProps?: ButtonProps;
  deleteButtonProps?: ButtonProps;
  // TODO: fix the type
  location?: any;
  showActionArea?: boolean;
  gender?: "male" | "female";
  birthYear?: number;
}

const CustomFarmerInfoCard: React.FunctionComponent<
  CustomFarmerInfoCardProps
> = (props) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const {
    deleteButtonProps,
    editButtonProps,
    name,
    phone,
    src,
    location,
    showActionArea,
    gender,
    birthYear,
  } = props;

  return (
    <Box component={Paper} p={3}>
      <Box
        className={`${
          !isMd ? classes.flexBoxWrapper : classes.textAlignCenter
        }`}
      >
        <Avatar
          sx={{ width: 150, height: 150, display: "inline-flex" }}
          src={src}
        />
        <Box className={`${classes.infoWrapperBox}`}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              marginBottom={2}
              marginTop={isMd ? 2 : undefined}
            >
              <Typography
                variant="h3"
                className={`${classes.nameText}`}
                data-cy="farmer-name-typography"
              >
                {name}
              </Typography>
            </Grid>
            {!isSm && (
              <>
                {phone && (
                  <Grid
                    item
                    xs={12}
                    className={`${classes.subInfoColor} ${classes.subItem}`}
                  >
                    <IoCallSharp size={20} className={`${classes.iconRoot}`} />
                    <Typography
                      marginLeft={1}
                      className={`${classes.subInfoText}`}
                    >
                      {phone}
                    </Typography>
                  </Grid>
                )}
                {gender && (
                  <Grid
                    item
                    xs={12}
                    className={`${classes.subInfoColor} ${classes.subItem}`}
                  >
                    <IoRadioButtonOnOutline
                      size={20}
                      className={`${classes.iconRoot}`}
                    />
                    <Typography
                      marginLeft={1}
                      className={`${classes.subInfoText}`}
                    >
                      {gender}
                    </Typography>
                  </Grid>
                )}
                {birthYear ? (
                  <Grid
                    item
                    xs={12}
                    className={`${classes.subInfoColor} ${classes.subItem}`}
                  >
                    <IoCalendarNumberSharp
                      size={20}
                      className={`${classes.iconRoot}`}
                    />
                    <Typography
                      marginLeft={1}
                      className={`${classes.subInfoText}`}
                    >
                      {birthYear}
                    </Typography>
                  </Grid>
                ) : null}
                {location && (
                  <Grid
                    item
                    xs={12}
                    className={`${classes.subInfoColor} ${classes.subItem}`}
                    sx={{ overflow: "hidden" }}
                  >
                    <IoLocation size={20} className={`${classes.iconRoot}`} />
                    <Typography
                      display="inline-flex"
                      marginLeft={1}
                      className={`${classes.subInfoText}`}
                    >
                      {location.region}
                      <IoChevronForwardOutline
                        className={`${classes.marginInline5} ${classes.alignSelfCenter}`}
                      />
                      {location.county}
                      <IoChevronForwardOutline
                        className={`${classes.marginInline5} ${classes.alignSelfCenter}`}
                      />
                      {location.constituency}
                      <IoChevronForwardOutline
                        className={`${classes.marginInline5} ${classes.alignSelfCenter}`}
                      />
                      {location.ward}
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>
      </Box>
      {showActionArea && (
        <Box className={classes.actionWrapper}>
          <DeleteEditButtonGroup
            // RootGridProps={{ width: 300 }}
            DeleteButtonProps={{ label: "Delete", ...deleteButtonProps }}
            EditButtonProps={{ label: "Edit", ...editButtonProps }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomFarmerInfoCard;
