import { GetMlFlowRuns } from "api/mlflow/get-mlfow";
import { CropSimulationChartDataItem } from "components/custom-compnents/crop-simulation-chart/crop-simulation-chart";
import { CropSimulationInfoStackedListProps } from "components/custom-compnents/crop-simulation-info-stacked-list/crop-simulation-info-stacked-list";
import { SmCropSimulationChartDataItem } from "components/custom-compnents/sm-crop-simulation-chart/sm-crop-simulation-chart";
import { TraCropSimulationChartDataItem } from "components/custom-compnents/tra-crop-simulation-chart/tra-crop-simulation-chart";
import moment from "moment";
import React from "react";
export interface IParams {
  farm_id: string;
}
export const useLogic = (params: IParams) => {
  const { farm_id } = params;

  React.useEffect(() => {
    GetMlFlowRuns({ farm_id }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        if (resp.runs) {
          setIsLoading(false);
          SetCropSimulationInfo({
            modelled_crop: resp.runs[0].data.params.find(
              (item) => item.key == "crop_name"
            )?.value!,
            modelled_variety: resp.runs[0].data.params.find(
              (item) => item.key == "variety_name"
            )?.value!,
            planting_date: resp.runs[0].data.params.find(
              (item) => item.key == "crop_start_date"
            )?.value!,
            harvesting_date: resp.runs[0].data.params.find(
              (item) => item.key == "crop_end_date"
            )?.value!,
            predicted_yield: resp.runs[0].data.metrics.find(
              (item) => item.key == "TWSO"
            )?.value!,
            accuracy: resp.runs[0].data.metrics.find(
              (item) => item.key == "accuracy"
            )?.value!,
          });
          const smValuesArray = Object.entries(
            JSON.parse(
              resp.runs[0].data.params.find((item) => item.key == "SM_ALL")
                ?.value!
            )
          );
          const traValuesArray = Object.entries(
            JSON.parse(
              resp.runs[0].data.params.find((item) => item.key == "TRA_ALL")
                ?.value!
            )
          );

          setTraCropModelSimulationChartData(
            traValuesArray.map((item) => ({
              time: moment(parseInt(item[0])).format("YYYY-MM-DD"),
              tra: item[1],
            })) as TraCropSimulationChartDataItem[]
          );

          setSmCropModelSimulationChartData(
            smValuesArray.map((item) => ({
              time: moment(parseInt(item[0])).format("YYYY-MM-DD"),
              sm: item[1],
            })) as SmCropSimulationChartDataItem[]
          );
          const tagpValuesArray = Object.entries(
            JSON.parse(
              resp.runs[0].data.params.find((item) => item.key == "TAGP_ALL")
                ?.value!
            )
          );

          const twsoValuesArray = Object.entries(
            JSON.parse(
              resp.runs[0].data.params.find((item) => item.key == "TWSO_ALL")
                ?.value!
            )
          );
          const longestDatesArray =
            twsoValuesArray.length > tagpValuesArray.length
              ? twsoValuesArray
              : tagpValuesArray;
          SetCropModelSimulationChartData(
            longestDatesArray.map((item) => ({
              time: moment(parseInt(item[0])).format("YYYY-MM-DD"),
              tagp: tagpValuesArray?.find(
                (tagpItem) => tagpItem[0] === item[0]
              )?.[1],
              twso: twsoValuesArray?.find(
                (tagpItem) => tagpItem[0] === item[0]
              )?.[1],
            })) as CropSimulationChartDataItem[]
            // .filter(
            //   (item) => item.tagp != null
            // )
          );
        }
      }
    });
  }, [farm_id]);

  const [CropModelSimulationChartData, SetCropModelSimulationChartData] =
    React.useState<CropSimulationChartDataItem[]>([]);

  const [smCropModelSimulationChartData, setSmCropModelSimulationChartData] =
    React.useState<SmCropSimulationChartDataItem[]>([]);

  const [traCropModelSimulationChartData, setTraCropModelSimulationChartData] =
    React.useState<TraCropSimulationChartDataItem[]>([]);

  const [CropSimulationInfo, SetCropSimulationInfo] =
    React.useState<CropSimulationInfoStackedListProps | null>();

  const [isLoading, setIsLoading] = React.useState(true);
  return {
    CropModelSimulationChartData,
    smCropModelSimulationChartData,
    traCropModelSimulationChartData,
    CropSimulationInfo,
    isLoading,
  };
};
