import Axios, { AxiosError } from "axios";
import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";
import humanize from "humanize-graphql-response";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { Variety } from "models/variety";

interface Params {
 id?: string;
 page: number;
 pageSize: number;
 name?: string;
 with_crops?: boolean;
 crop_id?: string;
}
interface Resp {
  items: Variety[];
  totalCount: number;
}
type GetVarietyType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | Resp) => any
) => any;
export const GetVariety: GetVarietyType = (Params, callback) => {
  // const Json_GraphQL_Query = {
  //   query: {
  //     varieties: {
  //       totalCount: true,
  //       __aliasFor: "allVarieties",
  //       __args: {
  //         offset:
  //           Params.page && Params.per_page
  //             ? Params.page * Params.per_page
  //             : null,
  //         first: Params.per_page,
  //         filter: {
  //           id: { in: Params.id_list },
  //           cropId: { in: Params.crop_id_list },
  //         },
  //       },
  //       edges: {
  //         node: {
  //           name: true,
  //           id: true,
  //           cropId: true,
  //         },
  //       },
  //     },
  //   },
  // };
  // const GraphQL = jsonToGraphQLQuery(cleanDeep(Json_GraphQL_Query), {
  //   pretty: true,
  // });

  AxiosInstance.get("/varieties", { params: Params })
    .then((resp) => {
      callback(null, {
        totalCount: resp.data.data.all_varieties.total_count,
        items: humanize(resp.data.data.all_varieties).nodes.map((item: any) => ({
          name: item.name,
          crop_id: item.crop_id,
          id:item.id
        })),
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};
