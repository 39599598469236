import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  inputRoot: {
    padding: 0,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    "&::-webkit-input-placeholder": {
      color: "#5d6d6e",
    },
    letterSpacing: "normal",
    textAlign: "left",
    color: "#5d6d6e",
  },
  root: {
    padding: "11px 15px",
    borderRadius: "6px",
    border: "solid 1px #c7d0d1",
    backgroundColor: "#fff",
  },
});
