import { AxiosInstance } from "configs/axios";
import { IEwalletUser } from "models/ewallet-user";

export interface IResp {
  page: number;
  pages: number;
  per_page: number;
  total: number;
  items: IEwalletUser[];
}

export interface IParams {
  page: number;
  pageSize: 10 | 50 | 100;
  search?: string;
}

export const fetchEwalletUsers = async (params: IParams) => await AxiosInstance.get<IResp>(`ewallet/users/search`, { params });
