import { Box, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import * as React from "react";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import ProvidersInput from "components/input-components/providers-input/providers-input";
import { useAppSelector } from "store/hooks";
import { IoWarningSharp } from "react-icons/io5";
import CancelDeleteButtonsGroup from "components/custom-compnents/cancel-delete-buttons-group/cancel-delete-buttons-group";

export interface RestartSurveyProps {
  open: boolean;
  onCancel: () => any;
  onAdded: () => any;
}


const RestartSurvey: React.FC<RestartSurveyProps> = ({ open, onCancel, onAdded }) => {
  const [IsSubmitting, SetIsSubmitting] = React.useState<boolean>(false);

  const [LoadingSurveyInfo, SetLoadingSurveyInfo] = React.useState<boolean>(false);

  return (
    <CustomDialogV2
      header={
        <Typography variant="h6" display="inline-flex">
          <IoWarningSharp size={22} style={{ marginRight: 5 }} />
          Restart ussd service
        </Typography>
      }
      open={open}
      footer={
        <Box display="flex" justifyContent="flex-end">
          <CancelDeleteButtonsGroup
            RootGridProps={{ maxWidth: 300 }}
            CancelButtonProps={{
              label: "Cancel",
              onClick: onCancel,
            }}
            DeleteButtonProps={{
              label: "Restart",
              onClick: onAdded,
              disabled: false,
            }}
          />
        </Box>
      }
    >
      <Box maxWidth={500} mt={1}>
        <span>
          Are you sure you want to restart the ussd service
        </span>
      </Box>
    </CustomDialogV2>
  );
};

export default RestartSurvey;
