import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import humanize from "humanize-graphql-response";
import { Crop } from "models/crop";

interface Params {
 page: number;
 pageSize: number;
 name?: string;
}
interface Resp {
  items: Crop[];
  totalCount: number;
}
type GetCropType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | Resp) => any
) => any;
export const GetCrop: GetCropType = (Params, callback) => {
  // const Json_GraphQL_Query = {
  //   query: {
  //     crops: {
  //       totalCount: true,
  //       __aliasFor: "allCrops",
  //       __args: {
  //         offset:
  //           Params.page && Params.per_page
  //             ? Params.page * Params.per_page
  //             : null,
  //         first: Params.per_page,
  //         filter: {
  //           id: { in: Params.id_list },
  //         },
  //       },
  //       edges: {
  //         node: {
  //           name: true,
  //           id: true,
  //         },
  //       },
  //     },
  //   },
  // };
  // const GraphQL = jsonToGraphQLQuery(cleanDeep(Json_GraphQL_Query), {
  //   pretty: true,
  // });

  AxiosInstance.get("/crops", {params: Params})
    .then((resp) => {

      callback(null, {
        totalCount: resp.data.data.all_crops.total_count,
        items: humanize(resp.data.data.all_crops).nodes.map((item: any) => ({
          id: item.id,
          name: item.name,
        })),
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};
