import { createTheme } from "@mui/material/styles";
import { alpha } from "@mui/material";

export const SideBarPlaette = {
  background: "#3c4b64",
  textColor: "white",
  SelectedItemBgColor: "#3c4b77",
  SelectedItemTextColor: "white",
  ItemTextColor: "#ddd",
};

export const Default = createTheme({
  shape: { borderRadius: 6 },
  components: {
    MuiSwitch: {
      defaultProps: {
        color: "success",
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: false,
          color: "success",
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: "#44b16e",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          background: "#44b16e",
        },
        track: {
          background: "#44b16e",
          borderColor: "#44b16e",
        },
        thumb: {
          background: "#44b16e",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          "&.Mui-selected": {
            color: "#44b16e !important",
          },

          minHeight: 60,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          background: "#f5f5f5",
        },
        deleteIcon: { color: "#d4d4d4" },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          "&:hover": {
            background: alpha("#44b16e", 0.1),
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: 30,
        },

        head: {
          backgroundColor: "#fafafa !important",
          fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.56,
          letterSpacing: "normal",
          textAlign: "left",
          color: "#707070",
          whiteSpace: "nowrap",
          textTransform: "uppercase",
          paddingBlock: 20,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: "solid 1px #dbdbdb",
          borderRadius: "10px !important",
          overflow: "hidden !important",
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          background: "white",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 36,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: "rgba(0, 0, 0, 0.2)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingTop: "11px !important",
          paddingBottom: "11px !important",
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: { color: "#6FA6D4" },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            paddingBlock: "0px !important",
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "& fieldset": {
              border: "unset !important",
              boxShadow: "0px 0px 0px 1px #44b16e",
            },
          },

          "&:hover": {
            "&:not(.Mui-focused)": {
              "& fieldset": {
                border: "solid 1px #44b16e !important",
              },
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontFamily: "Roboto",
          fontSize: "18px",
          fontWeight: 500,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.39,
          letterSpacing: "normal",
        },
        body1: {
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 3.57,
          letterSpacing: "normal",
        },
        h4: {
          fontFamily: "Roboto",
          fontSize: "30px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 0.83,
          letterSpacing: "normal",
        },
        subtitle1: {
          fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: 500,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 3.13,
          letterSpacing: "normal",
        },
        root: {
          fontFamily: ["Roboto", "sans-serif"].join(","),
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        outlined: {
          border: "solid 1px #dbdbdb",
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiCheckbox: {
      // defaultProps: {
      //   icon: <img src={CheckBoxIcon} />,
      //   checkedIcon: <img src={CheckBoxCheckedIcon} />,
      // },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
          border: "solid 1px #dbdbdb",
        },
      },
      defaultProps: {
        BackdropProps: {
          style: {
            opacity: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            pacity: "0.49 !important",
            border: "solid 1px #dbdbdb !important",
            backgroundColor: "#dbdbdb !important",
          },
          overflow: "hidden",
          fontFamily: "Roboto",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.5,
          letterSpacing: "0.16px",
          textAlign: "center",
          textTransform: "capitalize",
        },
        sizeMedium: {
          padding: "10px 16px",
          fontSize: 16,
        },

        containedSecondary: {
          background: "white",
          border: "solid 1px #dbdbdb",
          color: "#5d6d6e",
          ":hover": { backgroundColor: "white" },
        },
        outlined: {
          color: "#5d6d6e",
        },
      },
      defaultProps: {
        disableElevation: true,
        variant: "contained",
      },
    },
  },
  palette: {
    error: {
      main: "#ff5252",
    },
    secondary: { main: "#fff", contrastText: "#dbdbdb", light: "#f0f0f0" },
    success: { main: "#44b16e", contrastText: "#fff" },
    background: {
      default: "#f5f5f5",
    },
  },
});
