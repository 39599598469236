import { StandardTextFieldProps, TextField } from "@mui/material";
import React from "react";
import { useStyles } from "./custom-text-field.style";

export interface CustomTextFieldProps extends StandardTextFieldProps {
  "data-cy"?: string;
}

const CustomTextField = React.forwardRef((props: CustomTextFieldProps, ref) => {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      className={`${classes.root}`}
      InputProps={{
        ref: ref,
        className: `${classes.InputRoot}`,
        ...(props.InputProps as any),
      }}
      inputProps={{ className: `${classes.inputRoot}` }}
    />
  );
});

export default CustomTextField;
