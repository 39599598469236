import { Box, Grid } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { GetUser } from "../../../api/auth/user/get-user";
import AddUpdateUserModal from "../../standalone-components/add-update-user-modal/add-update-user-modal";

interface ProfileProps {}

const Profile: React.FunctionComponent<ProfileProps> = () => {
  const UserResponse = useQuery("user", GetUser);

  return UserResponse.data?.data ? (
    <Box display="inline-block">
      <AddUpdateUserModal
        Shape="card"
        Variant="update"
        UserId={UserResponse.data.data.id}
        onUpdate={() => window.location.reload()}
        OnCancel={() => null}
      />
    </Box>
  ) : (
    <></>
  );
};

export default Profile;
