import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Certificate } from "../../models/certificate";

interface Resp {
  items: Certificate[];
  totalCount: number;
}
interface Params {
  id?: string;
  page?: number;
  pageSize?: number;
  description?: string;
  title?: string;
}
type GetCertificateType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const GetCertificate: GetCertificateType = (Params, callback) => {

  AxiosInstance.get(`/certificates${Params?.id ? '/' + Params.id : ''}`, { params: Params })
    .then((resp) => {
      callback(null, {
        items: resp.data.data.all_certificates.nodes,
        totalCount: resp.data.data.all_certificates.total_count
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { GetCertificate };
