import { FunctionComponent } from "react";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import { IParams, useLogic } from "./show-message-template-details-dialog.logic";
import { Button, Grid, ListItem, ListItemText, Paper } from "@mui/material";

interface ShowMessageTemplateDetailsDialogProps extends IParams { }

const ShowMessageTemplateDetailsDialog: FunctionComponent<ShowMessageTemplateDetailsDialogProps> = (props) => {

  const { data } = useLogic(props);

  const fields = [
    { label: "Template Name", value: data?.display_name ?? data?.name },
    { label: "Category", value: data?.category },
    { label: "Access Level", value: data?.access_level },
    { label: "Status", value: data?.status },
    { label: "Language", value: data?.language },
    { label: "Organization Id", value: data?.provider_id },
    { label: "Body", value: data?.whatsapp_body },
  ];
  return (
    <CustomDialogV2
      open={props.open}
      header="Template Details"
      footer={
        <Button color="secondary" onClick={props.onOk} fullWidth>
          Ok
        </Button>
      }
    >
      <Grid container sx={{ width: 400 }} spacing={2}>
        {fields.map((item) => (
          <Grid item xs={12}>
            <Paper>
              <ListItem dense component={"div"}>
                <ListItemText primary={item.label} secondary={item.value} />
              </ListItem>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </CustomDialogV2>
  );
};

export default ShowMessageTemplateDetailsDialog;
