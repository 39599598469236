import { Box, Button, Grid } from "@mui/material";
import React from "react";
// import { TabOptionType } from "components/custom-compnents/option-tabs/option-tabs";
// import SurveyStackedInfo from "components/custom-compnents/survey-stacked-info/survey-stacked-info";
// import { FaListAlt, FaUsers } from "react-icons/fa";
import FarmersTable from "components/data-tables/farmers-table/farmers-table";
// import AddUpdateSurvey from "components/standalone-components/add-update-survey/add-update-survey";
// import { Survey } from "models/survey";
// import { GetSurveys } from "api/survey/get-survey";
// import CustomDialog from "components/custom-compnents/custom-dialog/custom-dialog";
// import { DeleteSurvey } from "api/survey/delete-survey";
// import QuestionsPanel from "./questions-panel";
// import { GetSurveyParticipants } from "../../../api/survey-participants/get-participants";
// import { SurveyParticipants } from "../../../models/survey-participants";
// import { CustomSelection } from "components/data-tables/farmers-table/schema";
// import { AddSurveyParticipants } from "../../../api/survey-participants/add-participants";
// import { useParams } from "react-router-dom";
import ShowEditSurveyComponent from "components/standalone-components/show-edit-survey-component/show-edit-survey-component";
import CustomTabsComponent from "components/custom-compnents/custom-tabs-component/custom-tabs-component";
import { IoOpenOutline } from "react-icons/io5";
import { useLogic } from "./survey-detail-screen.logic";
// import CustomSearchAddCountLabelActionBarStories from "components/custom-compnents/custom-search-add-count-label-action-bar/custom-search-add-count-label-action-bar.stories";
// import CustomSearchTextFieldStories from "components/custom-compnents/custom-search-text-field/custom-search-text-field.stories";
import CustomSearchAddCountLabelActionBar from "components/custom-compnents/custom-search-add-count-label-action-bar/custom-search-add-count-label-action-bar";
import AddUpdateSurveyQuestion from "components/standalone-components/add-update-survey-questions/add-update-survey-question";
import SurveyQuestionPanel from "components/standalone-components/survey-questions-panel/survey-questions-panel";

export interface SurveyDetailScreenProps { }

const SurveyDetailScreen: React.SFC<SurveyDetailScreenProps> = () => {
  // const HandleTabChange = (value: any) => SetTabValue(value);
  // const theme = useTheme();
  // const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const [openUpdateModal, SetOpenUpdateModal] = React.useState(false);
  // const HandleCancelUpdate = () => SetOpenUpdateModal(false);
  // const HandleOnUpdate = () => window.location.reload();
  // const HandleEditClicked = () => SetOpenUpdateModal(true);
  // const [SurveyData, SetSurveyData] =
  //   React.useState<Survey | undefined>(undefined);
  // const [OpenDeleteSurveyDialog, SetOpenDeleteSurveyDialog] =
  //   React.useState(false);
  // const HandleConfirmDelete = () => {
  //   SetIsSubmittingDelete(true);
  //   id &&
  //     DeleteSurvey({ id: id }, (error, resp) => {
  //       if (error) {
  //         console.log(error.message);
  //       } else if (resp) {
  //         SetIsSubmittingDelete(false);
  //         window.location.href = "/surveys";
  //       }
  //     });
  // };
  // const HandleCancelDelete = () => SetOpenDeleteSurveyDialog(false);
  // const HandleDeleteButtonClicked = () => SetOpenDeleteSurveyDialog(true);
  // const [IsSubmittingDelete, SetIsSubmittingDelete] = React.useState(false);
  // const [LoadingFarmersTable, SetLoadingFarmerTable] = React.useState(false);
  // const [ShowOnlySelectedFarmers, SetShowOnlySelectedFarmers] =
  //   React.useState(false);
  // React.useEffect(() => {
  //   // id &&
  //   //   GetSurveys(
  //   //     { surveys_ids: [id], page: 0, pageSize: 10 },
  //   //     (error, resp) => {
  //   //       if (error) {
  //   //         console.log(error.message);
  //   //       } else if (resp) {
  //   //         SetSurveyData(resp.items[0]);
  //   //       }
  //   //     }
  //   //   );
  //   id &&
  //     GetSurveyParticipants({ survey_id: id }, (error, resp) => {
  //       if (error) {
  //         console.log(error);
  //       } else if (resp) {
  //         SetDefaultSelectedFarmers(resp);
  //       }
  //     });
  // }, [id]);

  // const [DefaultSelectedFarmers, SetDefaultSelectedFarmers] = React.useState<
  //   SurveyParticipants[]
  // >([]);

  // const HandleSelectionChange: CustomSelection["OnSelectionChange"] = (
  //   Fields,
  //   checkedState
  // ) => {
  //   let newSelectedFarmersIdArray = DefaultSelectedFarmers.map(
  //     (item) => item.user_id
  //   );
  //   if (
  //     checkedState &&
  //     !newSelectedFarmersIdArray.find((item) => item === Fields.id)
  //   ) {
  //     newSelectedFarmersIdArray.push(Fields.id);
  //   }
  //   if (
  //     !checkedState &&
  //     newSelectedFarmersIdArray.find((item) => item === Fields.id)
  //   ) {
  //     newSelectedFarmersIdArray = newSelectedFarmersIdArray.filter(
  //       (item) => item !== Fields.id
  //     );
  //   }

  //   SetLoadingFarmerTable(true);

  //   id &&
  //     AddSurveyParticipants(
  //       { survey_id: id, users_id_list: newSelectedFarmersIdArray },
  //       (error, resp) => {
  //         if (error) {
  //           console.log(error);
  //         } else if (resp) {
  //           SetDefaultSelectedFarmers(resp.data.items);
  //           SetLoadingFarmerTable(false);
  //         }
  //       }
  //     );
  // };

  // const HandleBulkSelectChange: CustomSelection["OnCustomSelectAllChanged"] = (
  //   Type,
  //   Fields
  // ) => {
  //   let newSelectedFarmersIdArray = DefaultSelectedFarmers.map(
  //     (item) => item.user_id
  //   );
  //   if (Type === "all-selected" && Fields) {
  //     newSelectedFarmersIdArray = newSelectedFarmersIdArray.concat(
  //       Fields?.map((item) => item.id)
  //     );
  //   }
  //   if (Type === "none-selected" && Fields) {
  //     newSelectedFarmersIdArray = newSelectedFarmersIdArray.filter(
  //       (item) => !Fields.some((FieldsItem) => FieldsItem.id === item)
  //     );
  //   }

  //   SetLoadingFarmerTable(true);
  //   id &&
  //     AddSurveyParticipants(
  //       { survey_id: id, users_id_list: newSelectedFarmersIdArray },
  //       (error, resp) => {
  //         if (error) {
  //           console.log(error);
  //         } else if (resp) {
  //           SetDefaultSelectedFarmers(resp.data.items);
  //           SetLoadingFarmerTable(false);
  //         }
  //       }
  //     );
  // };
  // const HandleFilterSelectedFarmersButtonClicked = () =>
  //   SetShowOnlySelectedFarmers(!ShowOnlySelectedFarmers);

  // const handleBackButtonClick = () => setIsShowEditDeleteDrawer(false);

  const {
    TabsData,
    TabValue,
    SetTabValue,
    setIsShowEditDeleteDrawer,
    DefaultSelectedFarmers,
    HandleBulkSelectChange,
    HandleSelectionChange,
    ShowOnlySelectedFarmers,
    handleBackButtonClick,
    isShowEditDeleteDrawer,
    HandleFilterSelectedFarmersButtonClicked,
    LoadingFarmersTable,
    id,
    handleDeleted,
    handleUpdated,
    actionBarProps,
    addQuestionDialogProps,
    setLoadedQuestionsCount,
  } = useLogic();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex">
          <Box width="100%">
            {addQuestionDialogProps && (
              <AddUpdateSurveyQuestion {...addQuestionDialogProps} />
            )}

            <CustomTabsComponent
              TabData={TabsData.map((Item) => ({
                label: Item.label,
                value: Item.value,
                icon: Item.icon ? <Item.icon size={20} /> : undefined,
                disabled: Item.disabled,
              }))}
              TabsProps={{
                value: TabValue,
                onChange: (e, value) => SetTabValue(value),
              }}
            />
          </Box>
          <Box borderBottom={"solid 1px #dbdbdb"}>
            <Button
              variant="text"
              color="success"
              startIcon={<IoOpenOutline />}
              onClick={() => setIsShowEditDeleteDrawer(true)}
            >
              Survey Information
            </Button>
          </Box>
        </Box>
        {/* <TabsComponent
                  value={TabValue}
                  optionsArray={TabsOptionArray}
                  OnChange={SetTabValue}
                /> */}
      </Grid>
      <Grid item xs={12} mt={1}>
        <CustomSearchAddCountLabelActionBar {...actionBarProps} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {/* <Grid item lg={3} md={4} xs={12}>
          <SurveyStackedInfo
            DeleteButtonDisabled={OpenDeleteSurveyDialog}
            SurveyData={SurveyData}
            onEditClicked={HandleEditClicked}
            EditButtonDisabled={openUpdateModal}
            OnDeleteClicked={HandleDeleteButtonClicked}
          />
        </Grid> */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                <OptionTabs
                  variant={smScreen ? "fullWidth" : "standard"}
                  Data={TabsData}
                  value={TabValue}
                  onChange={HandleTabChange}
                  display={smScreen ? "block" : "inline-block"}
                />
              </Grid> */}
              <Grid item xs={12}>
                {TabValue === 1 && (
                  <FarmersTable
                    isLoading={LoadingFarmersTable}
                    ActionsToExclude={["AddFarmer", "AddPhoneCsvFile"]}
                    ForceKeepEmptyArrayQuery={true}
                    ClickedFilterSelectedFarmersButtonView={
                      ShowOnlySelectedFarmers
                    }
                    OnFilterSelectedFarmersButtonClicked={
                      HandleFilterSelectedFarmersButtonClicked
                    }
                    CustomSelection={{
                      DefaultSelectedFarmers: DefaultSelectedFarmers.map(
                        (item) => item.user_id
                      ),
                      OnCustomSelectAllChanged: HandleBulkSelectChange,
                      OnSelectionChange: HandleSelectionChange,
                      FilterByDefaultSelectedFarmers: ShowOnlySelectedFarmers,
                    }}
                  />
                )}
                {TabValue === 0 && id && (
                  // <Box maxWidth={800}>
                  //   {id && <QuestionsPanel survey_id={id} />}
                  // </Box>
                  <SurveyQuestionPanel
                    survey_id={id}
                    onQuestionsCountLoad={setLoadedQuestionsCount}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        {id && (
          <ShowEditSurveyComponent
            id={id}
            open={isShowEditDeleteDrawer}
            onBackClick={handleBackButtonClick}
            onDeleted={handleDeleted}
            onUpdated={handleUpdated}
          />
        )}
        {/* <CustomDialog
        Header="Delete Survey"
        open={OpenDeleteSurveyDialog}
        ActionButtonsProps={{
          ButtonsVariant: "ConfirmCancel",
          OnConfirm: HandleConfirmDelete,
          OnCancel: HandleCancelDelete,
          DisableConfirm: IsSubmittingDelete,
        }}
      >
        Are you sure you want to delete {SurveyData?.title} Survey?
      </CustomDialog> */}
        {/* {id && (
        <AddUpdateSurvey
          id={id}
          variant="update"
          open={openUpdateModal}
          onCancel={HandleCancelUpdate}
          onUpdated={HandleOnUpdate}
        />
      )} */}
      </Box>
    </Grid>
  );
};

export default SurveyDetailScreen;
