import {
  Box,
  Button,
  Grid,
  Popover,
  PopoverProps,
  Typography,
} from "@mui/material";
import * as React from "react";

export interface FarmersAnalyticsFilterOverviewConfirmationPopupProps {
  PopupAnchorEl: HTMLButtonElement | null;
  onCancle: () => void;
  onConfirm: () => void;
}

const FarmersAnalyticsFilterOverviewConfirmationPopup: React.SFC<
  FarmersAnalyticsFilterOverviewConfirmationPopupProps
> = ({ PopupAnchorEl, onCancle, onConfirm }) => {
  const PopoverProps: PopoverProps = {
    anchorEl: PopupAnchorEl,
    open: Boolean(PopupAnchorEl),
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    PaperProps: { elevation: 1 },
  };
  return (
    <Popover {...PopoverProps}>
      <Box p={1} minWidth={180}>
        <Typography>Remove This Filter ?</Typography>
        <Box my={1}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                color="primary"
                onClick={onConfirm}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth size="small" onClick={onCancle}>
                Cancle
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Popover>
  );
};

export default FarmersAnalyticsFilterOverviewConfirmationPopup;
