import { blue, grey } from "@mui/material/colors";
import moment from "moment";
import React from "react";
import {
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ComposedChart,
  ErrorBar,
  Scatter,
  Line,
} from "recharts";

export interface PercipitationProps {
  Data: {
    precipitation_probability: number;
    precipitation_spread: number;
    precipitation: number;
    date: string;
  }[];
}

const Percipitation: React.FC<PercipitationProps> = ({ Data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={150}
        height={40}
        data={Data.map((item) => ({
          date: moment(item.date).format("MMM-DD"),
          precipitation_probability: item.precipitation_probability?.toFixed(2),
          precipitation: item.precipitation,
        }))}
      >
        <XAxis dataKey="date" />
        <YAxis
          label={{
            angle: -90,
            position: "insideMiddleLeft",
            value: "Precipitation (mm)",
          }}
          yAxisId="precipitation"
          dataKey="precipitation"
        />
        <YAxis
          label={{
            angle: -90,
            position: "insideMiddleRight",
            value: "Precipitation Probability (%)",
          }}
          yAxisId="precipitation_probability"
          orientation="right"
          dataKey="precipitation_probability"
          max={100}
          min={0}
        />
        <Tooltip />
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          wrapperStyle={{ width: 150, whiteSpace: "break-spaces" }}
        />
        <Bar
          yAxisId="precipitation"
          dataKey="precipitation"
          fill={blue["900"]}
          name="Precipitation"
          barSize={25}
        />
        <Line
          yAxisId="precipitation_probability"
          dataKey="precipitation_probability"
          stroke={grey["400"]}
          name="Precipitation  Probability"
        />
        <Scatter
          yAxisId="precipitation"
          name="Precipitation Spread"
          data={Data.map((item) => ({
            date: moment(item.date).format("MMM do"),
            precipitation: item.precipitation,
            precipitation_spread:
              item.precipitation - item.precipitation_spread < 0
                ? [item.precipitation, item.precipitation_spread]
                : [item.precipitation_spread, item.precipitation_spread],
          }))}
          opacity={0}
        >
          <ErrorBar
            dataKey="precipitation_spread"
            width={4}
            strokeWidth={2}
            stroke="black"
            direction="y"
          />
        </Scatter>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Percipitation;
