import { ListItem, ListItemText, Grid } from "@mui/material";

import React from "react";
import {
  IoCalendarNumberSharp,
  IoFlaskSharp,
  IoLeaf,
  IoLocate,
  IoScaleSharp,
} from "react-icons/io5";
import CustomInfoCardWithIcon from "../custom-info-card-with-icon/custom-info-card-with-icon";

export interface CropSimulationInfoStackedListProps {
  modelled_crop: string;
  modelled_variety: string;
  planting_date: string;
  harvesting_date: string;
  predicted_yield: string;
  accuracy: string;
}

const CropSimulationInfoStackedList: React.FunctionComponent<CropSimulationInfoStackedListProps> =
  ({
    harvesting_date,
    modelled_crop,
    modelled_variety,
    planting_date,
    predicted_yield,
    accuracy,
  }) => {
    const DataList = [
      { label: "modelled crop", value: modelled_crop, icon: IoLeaf },
      {
        label: "modelled variety",
        value: modelled_variety,
        icon: IoFlaskSharp,
      },
      {
        label: "planting date",
        value: planting_date,
        icon: IoCalendarNumberSharp,
      },
      {
        label: "harvesting date",
        value: harvesting_date,
        icon: IoCalendarNumberSharp,
      },
      {
        label: "predicted yield",
        value: predicted_yield
          ? parseInt(predicted_yield)?.toFixed(0) + " kg/ha"
          : "No Data",
        icon: IoScaleSharp,
      },
      {
        label: "accuracy",
        value: accuracy,
        icon: IoLocate,
      },
    ];
    return (
      <Grid container spacing={2}>
        {DataList.map((item, index) => (
          <Grid item xs={12}>
            <CustomInfoCardWithIcon
              titleText={item.label}
              primaryText={item.value}
              icon={item.icon}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

export default CropSimulationInfoStackedList;
