import { findAll } from "api/whatsapp-templates/findAll";
import { IMessageTemplate } from "models/message-template";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
export interface IParams {
  id: string;
  open: boolean;
  onOk?: () => any;
}
export const useLogic = (params: IParams) => {
  const [data, setdata] = useState<undefined | IMessageTemplate>(undefined);
  const user = useAppSelector((root) => root.appReducer.user);

  useEffect(() => {
    findAll(
      {
        page: 0,
        pageSize: 10,
        id: params.id,
        without_approval: true,
        // created_by: parseInt(user?.id!),
        // provider_id: user?.provider_id!,
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else {
          const node = resp?.items[0];
          setdata(node);
        }
      }
    );
  }, [params.id]);

  return { data };
};
