import { Button, ButtonProps } from "@mui/material";
import { IoShareSocialOutline } from "react-icons/io5";

export interface AffiliateButtonProps extends ButtonProps {}

const AffiliateButton: React.FunctionComponent<AffiliateButtonProps> = (
  props
) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<IoShareSocialOutline />}
      {...props}
    />
  );
};

export default AffiliateButton;

AffiliateButton.defaultProps = {
  children: "Affiliate To",
};
