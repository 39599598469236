import { Box, Grid, Alert } from "@mui/material";

import React from "react";
import ContactUsStackedInfoList from "components/custom-compnents/contact-us-stacked-info-list/contact-us-stacked-info-list";

interface ContactInfoScreenProps {}

const ContactInfoScreen: React.FunctionComponent<
  ContactInfoScreenProps
> = () => {
  const Data = {
    phone:
      "+254 768548707 | +254 720291735 (between 9 AM and 5 PM on week days)",
    email: "help@agribora.com",
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} xl={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                If you have any questions or feedback, please contact us!
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <ContactUsStackedInfoList email={Data.email} phone={Data.phone} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactInfoScreen;
