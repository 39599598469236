export interface IParams {
  pathname?: string;
  queries: {
    key: string;
    value: string;
  }[];
}
export const ConvertQueriesToUrl = ({ pathname = "/", queries }: IParams) => {
  return `${pathname}?${queries
    .map((item) => item.key + "=" + item.value)
    .join("&")}`;
};
