import { FunctionComponent } from "react";
import { Box, Grid, Paper } from "@mui/material";
import { PieChart, Pie, ResponsiveContainer, Legend, Tooltip } from "recharts";
import { IParams, useLogic } from "./farmers-genders-chart.logic";
import { purple, blue, cyan } from "@mui/material/colors";
import CustomSubtitle from "components/custom-compnents/custom-subtitle/custom-subtitle";

interface FarmersGendersChartsProps extends IParams {}

const FarmersGendersCharts: FunctionComponent<FarmersGendersChartsProps> = (props) => {
  const { data } = useLogic(props);

  return (
    <Paper sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box p={2}>
            <CustomSubtitle label="Gender" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer width={"100%"} aspect={1.1}>
            <PieChart width={1000} height={1000}>
              <Pie
                data={data.map((item) => ({
                  name: item.farmers.gender ? item.farmers.gender : "unknown",
                  value: item.farmers.unique_farmer_count,
                  fill: item.farmers.gender === "male" ? blue["500"] : item.farmers.gender === "female" ? purple["500"] : cyan["700"],
                }))}
                dataKey="value"
                outerRadius={"85%"}
                innerRadius={"45%"}
              ></Pie>
              <Legend layout="horizontal" verticalAlign="bottom" align="center" height={36} />
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FarmersGendersCharts;
