import React from "react";

import AddUpdateUserModal from "components/standalone-components/add-update-user-modal/add-update-user-modal";

import CustomUsersTable from "components/custom-compnents/custom-users-table/custom-users-table";
import { useLogic } from "./user-table.logic";
import RemoveUserComponent from "components/standalone-components/remove-user-component/remove-user-component";

export interface UsersTableProps {
  _token?: string;
}

const UsersTable: React.FC<UsersTableProps> = ({ _token }) => {
  const { tableProps, addDialogProps, updateDialogProps, deleteUserDialogProps } = useLogic();
  return (
    <>
      {deleteUserDialogProps && <RemoveUserComponent {...deleteUserDialogProps} />}
      {updateDialogProps && <AddUpdateUserModal {...updateDialogProps} />}
      <AddUpdateUserModal {...addDialogProps} />
      <CustomUsersTable {...tableProps} />
    </>
  );
};

export default UsersTable;
