import { fetchUserBalance } from "api/ewallet-balance/get-ewallet-balance";
import { useEffect, useState } from "react";

export interface IParams {
  farmer_id: string;
}

export const useLogic = (params: IParams) => {
  const { farmer_id } = params;

  const [userHasWallet, setuserHasWallet] = useState<boolean | null>(null);

  useEffect(() => {
    fetchUserBalance(farmer_id)
      .then((resp) => {
        setuserHasWallet(true);
      })
      .catch((error) => {
        setuserHasWallet(false);
      });
  }, [farmer_id]);

  return { userHasWallet };
};
