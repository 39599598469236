import { Button, ButtonProps } from "@mui/material";
import { IoArrowUndoCircleOutline } from "react-icons/io5";

interface ResendButtonProps extends ButtonProps {}

const ResendButton: React.FunctionComponent<ResendButtonProps> = (props) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<IoArrowUndoCircleOutline />}
      {...props}
    />
  );
};

export default ResendButton;

ResendButton.defaultProps = {
  children: "Re-send",
};
