import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { BroadcastMessage } from "models/broadcasts-message";
interface Params {
  page: number;
  pageSize: number;
  broadcast_id?: string;
  id?: string;
}
interface Resp {
  items: BroadcastMessage[];
  total: number;
}
type GetBroadcastsMessagesType = (
  Params: Params,
  callback: (Error: null | AxiosError, Resp: null | Resp) => any
) => any;
const GetBroadcastsMessages: GetBroadcastsMessagesType = (Params, callback) => {
  AxiosInstance.get("/sms-broadcast-messages", {
    params: Params,
  })
    .then((resp) => {
      callback(null, {
        items: resp.data.data.all_sms_broadcast_messages.nodes,
        total: resp.data.data.all_sms_broadcast_messages.total_count
      });
    })
    .catch((error) => callback(error, null));
};

export { GetBroadcastsMessages };
