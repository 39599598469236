import { Box } from "@mui/system";
import SendersIdsTable from "components/data-tables/senders-ids-table/senders-ids-table";

interface SenderIdsScreenProps {}

const SenderIdsScreen: React.FunctionComponent<SenderIdsScreenProps> = () => {
  return (
    <Box>
      <SendersIdsTable />
    </Box>
  );
};

export default SenderIdsScreen;
