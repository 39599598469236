import { Box } from "@mui/material";
import * as React from "react";
import SurveyTable from "components/data-tables/surveys-table/survey-table";
import { useAppSelector } from "store/hooks";

export interface SurveyScreenProps {}

const SurveyScreen: React.FunctionComponent<SurveyScreenProps> = () => {
  const user = useAppSelector((root) => root.appReducer.user);
  return (
    <Box>
      <SurveyTable
        ActionsToExclude={user?.menuroles != "admin" ? ["Add", "Show"] : []}
      />
    </Box>
  );
};

export default SurveyScreen;
