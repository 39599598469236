import React, { useEffect, useState } from "react";
import { IEwalletTransaction } from "models/ewallet-transaction";
import { fetchEwalletTransactions } from "api/ewallet-transactions/ewallet-transactions";
import { IValue } from "components/input-components/ewallet-users-input/ewallet-users-input";
import { useAppSelector } from "store/hooks";
import { CustomFarmersTableProps } from "components/custom-compnents/custom-farmers-table/custom-farmers-table";
import { OrderByObject } from "./order-by-object";

export interface IParams {
  from_user?: string;
  to_user?: string;
}

export const useLogic = (params: IParams) => {
  const { from_user, to_user } = params;
  const user = useAppSelector((root) => root.appReducer.user);
  const [dateFromFilter, setdateFromFilter] = React.useState<string | undefined>(undefined);
  const [dateToFilter, setdateToFilter] = React.useState<string | undefined>(undefined);
  const [isOpenFilterArea, setIsOpenFilterArea] = React.useState(false);
  const [usersFilter, setusersFilter] = useState<undefined | IValue>(undefined);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [data, setData] = React.useState<IEwalletTransaction[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [orderByData, setOrderByData] = React.useState<{ index: number; orderDirection: "asc" | "desc" } | undefined>(undefined);
  const [columnsState, setColumnsState] = React.useState([
    { field: "id", checked: false },
    { field: "From User", checked: true },
    { field: "To User", checked: true },
    { field: "Amount", checked: true },
    { field: "Mpesa Charge", checked: false },
    { field: "Type", checked: true },
    { field: "Time", checked: true },
    { field: "Request Id", checked: false },
    { field: "Response Code", checked: false },
    { field: "Response Description", checked: false },
    { field: "Response Code", checked: false },
    { field: "Result Description", checked: false },
  ]);

  const handleOrderChange: CustomFarmersTableProps["onOrderChange"] = (index, orderDirection) => {
    if (index > -1) setOrderByData({ index, orderDirection });
  };

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const clearFilters = () => {
    setdateFromFilter(undefined);

    setdateToFilter(undefined);

    setusersFilter(undefined);
  };

  const isFiltersEmpty = !dateFromFilter && !dateToFilter && !usersFilter?.value;

  const handleCancelFilters = () => {
    clearFilters();
    refreshData();

    setIsOpenFilterArea(false);
  };

  React.useEffect(() => {
    setIsLoading(true);

    fetchEwalletTransactions({
      page: currentPage + 1,
      pageSize: perPage as any,
      sort_by: typeof orderByData?.index === "number" ? (OrderByObject as any)[orderByData?.index!][orderByData?.orderDirection] : undefined,
      from_user: from_user ? from_user : (usersFilter?.operator === "from_user" || !usersFilter?.operator) && usersFilter?.value ? usersFilter?.value.id : undefined,
      to_user: to_user ? to_user : (usersFilter?.operator === "to_user" || !usersFilter?.operator) && usersFilter?.value ? usersFilter?.value.id : undefined,
      from_date: dateFromFilter,
      to_date: dateToFilter,
      transactions_status: 'successful'
    })
      .then((resp) => {
        setData(resp.data.items);
        setTotalCount(resp.data.total);
      })
      .catch((error) => console.log(error.message))
      .finally(() => setIsLoading(false));
  }, [currentPage, perPage, orderByData?.index, orderByData?.orderDirection, dataUpdateIndex, from_user, to_user]);

  return {
    isFiltersEmpty,
    handleCancelFilters,
    setIsOpenFilterArea,
    setdateToFilter,
    setdateFromFilter,
    dateToFilter,
    dateFromFilter,
    isLoading,
    refreshData,
    handleNextPageClick,
    handlePreviousPageClick,
    handlePerPageChange,
    handleColumnStateChange,
    handleOrderChange,
    preparedData,
    perPage,
    currentPage,
    totalCount,
    columnsState,
    isOpenFilterArea,
    setusersFilter,
    usersFilter,
    user,
  };
};
