import { Box } from "@mui/material";
import FarmersTable from "components/data-tables/farmers-table/farmers-table";
import { useAppSelector } from "store/hooks";

interface FarmersOverviewProps {}

const FarmersOverview: React.FunctionComponent<FarmersOverviewProps> = () => {
  const user = useAppSelector((root) => root.appReducer.user);
  const canCreate =
    user?.menuroles === "admin" ||
    user?.menuroles === "manager" ||
    user?.menuroles === "org_admin";

  return (
    <Box>
      <FarmersTable
        isShowColumnButton={true}
        isShowFilterButton={true}
        isShowImportButton={true}
        isShowImportLabelsButton={true}
        isShowCreateButton={canCreate}
      />
    </Box>
  );
};

export default FarmersOverview;
