import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
// import { IOrganizationRelation } from "models/organizations-relation";
interface Params {
  super_org: string[];
  sub_org: string;
}
type Resp = "ok";
type callbackType = (error: null | AxiosError, resp: Resp | null) => any;

const deleteMultipleSuperOrganizationRelation = (Params: Params, callback: callbackType) => {
  AxiosInstance.delete<Resp>("/organization-relations/multiple", {
    data: {
      ...Params
    }
  }).then((res) => {
    callback(null, "ok")
  })
    .catch(err => {
      callback(err, null)
    })
};

export { deleteMultipleSuperOrganizationRelation };
