import MaterialTable from "@material-table/core";
import CustomPagnation from "components/custom-compnents/custom-pagnation/custom-pagnation";
import { Fields, Params, schema } from "./custom-devices-table.schema";
import { Box, Grid } from "@mui/material";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import { CustomPagnationProps } from "components/custom-compnents/custom-pagnation/custom-pagnation";
import CustomFiltersContainer, {
  CustomFiltersContainerProps,
} from "../custom-filters-container/custom-filters-container";
import CustomDevicesTableActionBar, {
  CustomDevicesTableActionBarProps,
} from "../custom-devices-table-action-bar/custom-devices-table-action-bar";
// import FarmsActivitesTableFilterForm, {
//   FarmsActivitesTableFilterFormProps,
// } from "components/forms/farms-activites-table-filter-form/farms-activites-table-filter-form";

export interface CustomDevicesTableProps {
  countLabelProps?: CountLabelProps;
  showFilterArea?: boolean;
  filterContainerProps?: CustomFiltersContainerProps;
  //   filterFormProps?: FarmsActivitesTableFilterFormProps;
  pagnationProps?: CustomPagnationProps;
  actionBarProps?: CustomDevicesTableActionBarProps;
  data: Fields[];
  isLoading?: boolean;
  customSelection?: Params["customSelection"];
  onOrderChange?: (
    orderBy: number,
    orderDirection: "desc" | "asc"
  ) => void | undefined;
  onDeleteButtonClick?: Params["onDeleteButtonClick"];
  hideDetachAction?: boolean;
}

const CustomDevicesTable: React.FunctionComponent<CustomDevicesTableProps> = (
  props
) => {
  const {
    countLabelProps,
    showFilterArea,
    pagnationProps,
    filterContainerProps,
    // filterFormProps,
    actionBarProps,
    data,
    isLoading,
    customSelection,
    onOrderChange,
    onDeleteButtonClick,
    hideDetachAction
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ paddingLeft: 0 }}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingLeft: 0 }}
        >
          <Grid item md={3} xs={12}>
            <CountLabel {...countLabelProps} label="All Devices" />
          </Grid>
          <Grid item md={9} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Box width={840} maxWidth="100%">
                <CustomDevicesTableActionBar {...actionBarProps} />
              </Box>
            </Box>
          </Grid>
          {showFilterArea && (
            <Grid item xs={12}>
              <CustomFiltersContainer {...filterContainerProps}>
                {/* <FarmsActivitesTableFilterForm {...filterFormProps} /> */}
              </CustomFiltersContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          {...schema({
            customSelection,
            data,
            columnState: actionBarProps?.columnsState,
            onDeleteButtonClick: onDeleteButtonClick,
            hideDetachAction
          })}
          isLoading={isLoading}
          onOrderChange={onOrderChange}
        />
      </Grid>
      <Grid item xs={12}>
        {pagnationProps && <CustomPagnation {...pagnationProps} />}
      </Grid>
    </Grid>
  );
};

export default CustomDevicesTable;
