import { UpdateSurveyQuestion } from "api/survey-questions/update-survey-question";
import React from "react";

export interface IParams {
  onDeleted?: () => any;
  id: string;
  survey_id: string;
}
export const useLogic = (params: IParams) => {
  const [isSubmittingDelete, setiIsSubmittingDelete] = React.useState(false);
  const { onDeleted, id, survey_id } = params;

  const handleDeleteConfirm = () => {
    setiIsSubmittingDelete(true);

    UpdateSurveyQuestion({ question_id: id, survey_id, sort_order: 1 }, (error, resp) => {
      if (error) {
        console.log(error);
      } else if (resp) {
        setiIsSubmittingDelete(false);
        onDeleted?.();
      }
    });
  };

  return { isSubmittingDelete, handleDeleteConfirm };
};
