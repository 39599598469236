import * as React from "react";
import { Typography } from "@mui/material";
import { IDevice } from "../../../models/device";
import { Detach_Device } from "./Api";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelDeleteButtonsGroup from "components/custom-compnents/cancel-delete-buttons-group/cancel-delete-buttons-group";
import { Box } from "@mui/system";

export interface DetachDeviceConfirmModalProps {
  open: boolean;
  OnDetach: () => void;
  OnIgnore: () => void;
  Device?: IDevice;
}

const DetachDeviceConfirmModal: React.SFC<DetachDeviceConfirmModalProps> = ({
  open,
  OnDetach,
  OnIgnore,
  Device,
}) => {
  const [isSubmitting, SetIsSubmitting] = React.useState(false);
  return (
    // <CustomDialog
    //   Header="Detach Confirmation"
    //   open={open}
    //   ActionButtonsProps={{
    //     ButtonsVariant: "ConfirmCancel",
    //     OnConfirm: () => {
    //       SetIsSubmitting(true);
    //       Device &&
    //         Detach_Device(Device.id, (error, response) => {
    //           if (error) {
    //             console.log(error.response?.data);
    //           } else {
    //             SetIsSubmitting(false);
    //             OnDetach();
    //           }
    //         });
    //     },
    //     OnCancel: OnIgnore,
    //     DisableConfirm: isSubmitting,
    //   }}
    // >
    //   <Typography display="inline" color="textPrimary">
    //     Are you sure you want to detach device with imei{" "}
    //     <Typography display="inline" style={{ fontWeight: 600 }}>
    //       {Device?.imei}
    //     </Typography>{" "}
    //     ?
    //   </Typography>
    // </CustomDialog>
    <>
      <CustomDialogV2
        header="Detach Confirmation"
        open={open}
        footer={
          <Box display="flex" justifyContent="flex-end">
            <CancelDeleteButtonsGroup
              RootGridProps={{ width: 300 }}
              CancelButtonProps={{
                onClick: OnIgnore,
                label: "Cancel",
              }}
              DeleteButtonProps={{
                label: "Detach",
                disabled: isSubmitting,
                onClick: () => {
                  SetIsSubmitting(true);
                  Device &&
                    Detach_Device(Device.id, (error, response) => {
                      if (error) {
                        console.log(error.response?.data);
                      } else {
                        SetIsSubmitting(false);
                        OnDetach();
                      }
                    });
                },
              }}
            />
          </Box>
        }
      >
        <Typography display="inline" color="textPrimary">
          Are you sure you want to detach device with imei{" "}
          <Typography display="inline" style={{ fontWeight: 600 }}>
            {Device?.imei}{" "}
          </Typography>{" "}
          ?{" "}
        </Typography>
      </CustomDialogV2>
    </>
  );
};

export default DetachDeviceConfirmModal;
