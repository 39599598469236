import { useAppSelector } from "store/hooks";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { useRouteutils } from "../../../../hooks/routes-hook";

export const useLogic = () => {
  const { getFlatRoutesArray } = useRouteutils();

  const RouteItemsArray = getFlatRoutesArray();

  const { isLoading, ...breadcrumbState } = useAppSelector(
    (root) => root.breadcrumbState
  );

  let breadcrumbsHook = useReactRouterBreadcrumbs(
    RouteItemsArray.map((item: any) => ({
      path: item.pathUrl,
      breadcrumb: item.title,
    }))
  );

  const breadcrumbs =
    breadcrumbState.items.length > 0
      ? breadcrumbState.items.map((item) => ({
          breadcrumb: item.title,
          match: { pathname: item.path },
          key: item.path,
        }))
      : breadcrumbsHook;

  return { breadcrumbs, isLoading };
};
