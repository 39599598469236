import { Button, ButtonProps, Tooltip } from "@mui/material";
import { useStyles } from "./custom-toggle-buttons.style";

interface ButtonData {
  label: string;
  value?: any;
  disabled?: boolean;
  title?: string;
}
export interface CustomToggleButtonProps {
  items?: ButtonData[];
  value?: any;
  onChange?: (value: any) => any;
  isFullWidth?: boolean;
  disabled?: boolean;
}

const CustomToggleButton: React.FunctionComponent<CustomToggleButtonProps> = (
  props
) => {
  const classes = useStyles();

  const buttonsProps = (isSelected: boolean) =>
    ({
      color: "secondary",
      className: `${classes.switchButtonRoot} ${
        isSelected && classes.switchButtonSelected
      }`,
    } as ButtonProps);

  const { items, value, onChange, isFullWidth, disabled } = props;

  return (
    <>
      {items?.map((item, index) => (
        <Tooltip title={item.title ?? ""}>
          <span>
            <Button
              style={{ marginBottom: "8px" }}
              disabled={disabled || item.disabled}
              fullWidth={isFullWidth}
              {...buttonsProps(
                item.value ? item.value === value : index === value
              )}
              onClick={() => onChange?.(item.value ?? index)}
            >
              {item.label}
            </Button>
          </span>
        </Tooltip>
      ))}
    </>
  );
};

export default CustomToggleButton;
