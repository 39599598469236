import { Button, Grid, ListItem, ListItemText, Paper } from "@mui/material";
import React from "react";
import { Activity } from "models/activity";

interface ActivityStackedInfoListProps extends Partial<Activity> {
  ShowDeleteButton?: boolean;
  ShowEditButton?: boolean;
  OnDeleteClicked?: () => any;
  OnEditClicked?: () => any;
  aggrLastEditorName?: string;
}

interface DataType {
  value: any;
  label: string;
}
const ActivityStackedInfoList: React.FunctionComponent<
  ActivityStackedInfoListProps
> = (Props) => {
  let DataArray: DataType[] = [
    { label: "Activity", value: Props.activity },
    {
      label: "Date",
      value: `${Props.year}-${Props.month}${Props.day ? `-${Props.day}` : ""}`,
    },

    { label: "Crop", value: `${Props.crop?.name}` },
    { label: "Variety", value: Props.variety?.name },

    { label: "Status", value: Props.aggrStatus },
    { label: "Aggregation Quantity (kg)", value: Props.aggrQuantityKg },
    { label: "Total Price (KSh)", value: Props.aggrTotalPriceKsh },
    { label: "Payment Method", value: Props.aggrPaymentMethod },

    { label: "Fertilizer Quantity (kg)", value: Props.fertQuantityKg },
    { label: "Fertilizer Type", value: Props.fertType },

    { label: "Harvesting Quantity (kg)", value: Props.harvestingQuantityKg },
    { label: "Planting Quantity (kg)", value: Props.plantingQuantityKg },
    { label: "Last Editor", value: Props.aggrLastEditorName },
  ];
  DataArray = DataArray.filter((item) => item.value);
  return (
    <Grid container spacing={1}>
      {DataArray.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Paper>
            <ListItem dense component={"div"}>
              <ListItemText primary={item.label} secondary={item.value} />
            </ListItem>
          </Paper>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {Props.ShowEditButton && (
            <Grid item md={6} xs={12}>
              <Button
                onClick={Props.OnEditClicked}
                fullWidth
                variant="contained"
                color="primary"
              >
                Edit
              </Button>
            </Grid>
          )}
          {Props.ShowDeleteButton && (
            <Grid item md={6} xs={12} spacing={1}>
              <Button
                onClick={Props.OnDeleteClicked}
                fullWidth
                variant="contained"
                color="secondary"
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActivityStackedInfoList;
