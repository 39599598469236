import { List } from "@mui/material";
import SideMenuListItem, {
  SideMenuListItemProps,
} from "../side-menu-list-item/side-menu-list-item";
import SideMenuSubList, {
  SideMenuSubListProps,
} from "../side-menu-sub-list/side-menu-sub-list";

type ItemType = {
  itemData: SideMenuListItemProps;
  subMenuData: SideMenuSubListProps;
  showSubMenu?: Boolean;
};
export interface SideMenuListProps {
  data?: ItemType[];
}

const SideMenuList: React.FunctionComponent<SideMenuListProps> = (props) => {
  const { data } = props;
  return (
    <List>
      {data?.map((item, key) => (
        <>
          <SideMenuListItem key={key} {...item.itemData} />
          {item.showSubMenu &&
            item.subMenuData.data &&
            item.subMenuData.data?.length > 0 && (
              <SideMenuSubList {...item.subMenuData} />
            )}
        </>
      ))}
    </List>
  );
};

export default SideMenuList;
