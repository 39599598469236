import { Grid, List } from "@mui/material";
import { IoExpand, IoLeaf, IoLocation, IoPerson } from "react-icons/io5";
import CustomInfoCardWithIcon from "../custom-info-card-with-icon/custom-info-card-with-icon";

export interface FarmInfoListProps {
  crop?: string;
  size?: number;
  ownedByFarmer?: boolean;
  location?: { lat: string; lng: string };
}

const FarmInfoList: React.FunctionComponent<FarmInfoListProps> = (props) => {
  const { crop, size, ownedByFarmer, location } = props;
  const dataArray = [
    {
      label: "Current Crop",
      value: crop || "No Information",
      icon: IoLeaf,
    },
    {
      label: "Size estimated by Farmer",
      value: size?.toString() || "No Size",
      icon: IoExpand,
    },
    {
      label: "Ownership",
      value: ownedByFarmer ? "owned by farmer" : "Not owned by farmer",
      icon: IoPerson,
    },
    {
      label: "Location",
      value: location ? `${location?.lat} / ${location?.lng}` : "No Location",
      icon: IoLocation,
    },
  ];
  return (
    <Grid spacing={2} container>
      {dataArray.map((item, index) => (
        <Grid item xs={12}>
          <CustomInfoCardWithIcon
            key={index}
            icon={item.icon}
            primaryText={item.value}
            titleText={item.label}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FarmInfoList;
