import { Box, Button, Chip, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
import { IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { IMessageTemplate } from "models/message-template";
import { MessageTemplateStatus } from "enums/message-template-status";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
}
export interface Fields extends IMessageTemplate {
  selected?: boolean;
  showDeleteButton?: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onEditButtonClick?: (data: Fields) => any;
  onDeleteButtonClick?: (data: Fields) => any;
  onViewButtonClick?: (data: Fields) => any;
  isShowRemoveAction?: boolean;
  isShowEditAction?: boolean;
  isShowViewAction?: boolean;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (params) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "Template Name",
      field: "name",
      hidden: !params.columnState?.find((item) => item.field === "template name")?.checked,
      render: (data) => data.display_name,
    },
    {
      title: "Categories",
      field: "categories",
      hidden: !params.columnState?.find((item) => item.field === "category")?.checked,
      render: (data) => data.categories?.map((item) => <Chip label={item.name} variant="outlined" sx={{ mx: 0.5 }} />),
    },
    {
      title: "Status",
      field: "status",
      hidden: !params.columnState?.find((item) => item.field === "status")?.checked,
      render: (data) =>
        data.status === MessageTemplateStatus.Approved ? <Chip label="Approved" color="success" variant="outlined" /> : data.status === MessageTemplateStatus.Pending ? <Chip label="Pending" color="warning" variant="outlined" /> : <Chip label={data.status} color="error" variant="outlined" />,
    },
    {
      title: "Access Level",
      field: "access_level",
      hidden: !params.columnState?.find((item) => item.field === "access level")?.checked,
      render: (data) => data.access_level,
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: <CustomCheckBox onChange={(e, value) => params.customSelection?.onSelectAllChange?.(params.data, value)} checked={params.data.every((item) => item.selected)} indeterminate={params.data.some((item) => item.selected) && !params.data.every((item) => item.selected)} />,
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => <CustomCheckBox checked={data.selected} onChange={(e, value) => params.customSelection?.onRowSelectChange?.(data, value)} />,
    });
  }

  return {
    actions: [
      (fields) => ({
        hidden: !params.isShowViewAction,
        icon: () => (
          <Button variant="text" startIcon={<IoCreateOutline />} onClick={() => params.onViewButtonClick?.(fields)} color="success">
            View
          </Button>
        ),
        onClick: () => null,
      }),
      (fields) => ({
        hidden: !params.isShowEditAction,
        icon: () => (
          <Button variant="text" startIcon={<IoCreateOutline />} onClick={() => params.onEditButtonClick?.(fields)} color="success">
            Edit
          </Button>
        ),
        onClick: () => null,
      }),
      (fields) => ({
        hidden: !params.isShowRemoveAction || !fields.showDeleteButton,
        icon: () => (
          <Button variant="text" startIcon={<IoTrashOutline />} onClick={() => params.onDeleteButtonClick?.(fields)} color="success">
            Delete
          </Button>
        ),
        onClick: () => null,
      }),
    ],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
