export const query = `
query($filter:MessageCategoryFilter,$first:Int,$offset:Int){
    allMessageCategories(filter:$filter,first:$first,offset:$offset){
      nodes{
        id
        name 
      }
    }
  }
`;
