import { Button, ButtonProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useStyles } from "./custom-menu-button.style";

interface CustomMenuButtonProps extends ButtonProps {
  openedArrow?: boolean;
  helperText?: string;
  error?: boolean;
  "data-cy"?: string;
}

const CustomMenuButton: React.FunctionComponent<CustomMenuButtonProps> = (
  props
) => {
  const { openedArrow, helperText, error } = props;
  const classes = useStyles();
  return (
    <>
      <Button
        color="secondary"
        fullWidth
        className={`${classes.root} ${error && classes.errorBorder}`}
        {...props}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          {props.children}
          {openedArrow ? (
            <>
              <IoChevronUp />
            </>
          ) : (
            <>
              <IoChevronDown />
            </>
          )}
        </Box>
      </Button>
      {helperText && (
        <Typography
          className={`${classes.helperTypeography} ${
            error && classes.errorColor
          }`}
        >
          {helperText}
        </Typography>
      )}
    </>
  );
};

export default CustomMenuButton;
