import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  textRoot: {
    paddingLeft: 45,
    opacity: 0.65,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#5d6d6e",
  },
  textActive: {
    opacity: 10,
    color: "black",
    fontWeight: 500,
  },
});

export default useStyles;
