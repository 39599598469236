import { GetQualityCheck } from "api/quality-check/get-quality-check";
import { GetUsers } from "api/user/get-users";
import { CustomQualityCheckTableProps } from "components/custom-compnents/custom-quality-check-table/custom-quality-check-table";
import { IQualityCheck } from "models/quality-check";
import React from "react";
import { useAppSelector } from "store/hooks";

export type Actions = "add" | "update";

interface Params {
  aggregation_activity_id?: string;
  actionsToExclude?: Actions[];
}

interface data extends IQualityCheck {
  selected: boolean;
}

export const useLogic = ({ aggregation_activity_id }: Params) => {
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [data, setData] = React.useState<data[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [QualityCheckToBeUpdated, SetQualityCheckToBeUpdated] = React.useState<undefined | IQualityCheck>(undefined);
  const [OpenAddQualityCheck, SetOpenAddQualityCheck] = React.useState(false);
  const user = useAppSelector((root) => root.appReducer.user);

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const [columnsState, setColumnsState] = React.useState([
    { field: "type", checked: true },
    { field: "last_editor", checked: true },
    { field: "last_edit_date", checked: true },
    { field: "status", checked: true },
    { field: "report", checked: true },
    { field: "passed", checked: true },
  ]);

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetQualityCheck(
      {
        page: currentPage,
        pageSize: perPage,
        with_editor: true,
        aggregation_id: aggregation_activity_id,
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          const Last_Edit_Users_Ids = resp.items.map((item) => item.last_editor);
          GetUsers({ page: 0, pageSize: 10 }, (error, UsersResp) => {
            if (error) {
              console.log(error.message);
              setData(resp.items.map((item) => ({ ...item, selected: false })));
              setTotalCount(resp.totalCount);
              setIsLoading(false);
            } else if (UsersResp) {
              const itemsWithUsersName = resp.items.map((item) => ({
                ...item,
                editor: UsersResp.data.find((UserItem) => UserItem.id == item.last_editor)?.name,
              }));
              setData(itemsWithUsersName.map((item) => ({ ...item, selected: false })));
              setTotalCount(resp.totalCount);
              setIsLoading(false);
            }
          });
        }
      }
    );
  }, [currentPage, perPage, dataUpdateIndex]);

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleAddQualityCheck = () => {
    SetOpenAddQualityCheck(false);
    refreshData();
  };

  const handleUpdatedQualityCheck = () => {
    SetQualityCheckToBeUpdated(undefined);
    refreshData();
  };

  const handleCancelUpdateQualityCheck = () => {
    SetQualityCheckToBeUpdated(undefined);
  };

  const HandleCancelAddQualityCheck = () => {
    SetOpenAddQualityCheck(false);
  };

  const handleAddQualityCheckButtonClicked = () => {
    SetOpenAddQualityCheck(true);
  };

  const idsListToShowEditingButtonFor = data
    .filter((item) => {
      return item.last_editor == user?.id;
    })
    .map((item) => item.id);

  const tableProps: CustomQualityCheckTableProps = {
    idsListToShowEditing: idsListToShowEditingButtonFor,
    data,
    isLoading,
    onEditButtonClick: SetQualityCheckToBeUpdated,
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
    countLabelProps: {
      count: totalCount,
    },
    actionBarProps: {
      addButtonProps: {
        onClick: handleAddQualityCheckButtonClicked,
      },
      onColumnsStateChange: handleColumnStateChange,
      columnsState,
      isShowColumnButton: true,
      isShowCreateButton: user?.menuroles !== "user",
    },
  };
  return {
    tableProps,
    QualityCheckToBeUpdated,
    handleUpdatedQualityCheck,
    handleCancelUpdateQualityCheck,
    OpenAddQualityCheck,
    handleAddQualityCheck,
    HandleCancelAddQualityCheck,
  };
};
