import React, { useEffect } from "react";
import { MapContainer, MapContainerProps, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import RegionLayer from "./LayerRegions";
import CountyLayer from "./LayerCounties";
import ConstituencyLayer from "./LayerConstituencies";
import WardLayer from "./LayerWards";
import { Box, Paper } from "@mui/material";
import { getAllFarmersCount } from "../../../../api/map-api/farmers-count";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  setConstituenciesFarmersCountObject,
  setConstituenciesGeoJson,
  setCountiesFarmersCountObject,
  setCountiesGeoJson,
  setFarmersTotalCount,
  setLastClickedConstituency,
  setLastClickedCounty,
  setLastClickedRegion,
  setLastClickedWards,
  setRegionsFarmersCountObject,
  setSelectedConstituencies,
  setSelectedCounties,
  setSelectedRegions,
  setSelectedWards,
  setWardsFarmersCountObject,
  setWardsGeoJson,
} from "store/reducers/farmers-analytics-page/map/farmers-analytics-map-reducer";
import { cloneDeep } from "lodash";

interface AnalyticsMapProps { }

const AnalyticsMap: React.FC<AnalyticsMapProps> = () => {
  const user = useAppSelector((root) => root.appReducer.user);

  const { menuroles, provider_id } = user!;

  const farmerAnalyticsPageState = useAppSelector((root) => root.FarmerAnalyticsPageState);

  const farmersAnalyticsMapState = useAppSelector((root) => root.farmersAnalyticsMapState);

  const apiKey = useAppSelector((root) => root.appReducer.mapApiKey);

  const dispatcher = useAppDispatch();

  const MapContainerProps: MapContainerProps = {
    center: [0.023, 37.9062],
    zoom: 5.5,
    maxZoom: 9,
    minZoom: 5.5,
    style: { width: "100%", height: "100%" },
  };

  useEffect(() => {
    getAllFarmersCount({ page: 0, pageSize: 10, provider_id: farmerAnalyticsPageState.selectedProviderId ? farmerAnalyticsPageState.selectedProviderId : provider_id }, (data: any) =>
      // SetFarmersAnalyticsMapState({
      //   FarmersTotalCount: data.allFarmers.totalCount,
      // })

      dispatcher(setFarmersTotalCount(data.totalCount))
    );
  }, [farmerAnalyticsPageState.selectedProviderId]);

  const HandleFarmersPerCountyUpdate = (data: any) => {
    let CurrentCountiesFarmersCountObject = farmersAnalyticsMapState.CountiesFarmersCountObject;
    CurrentCountiesFarmersCountObject = {
      ...CurrentCountiesFarmersCountObject,
      ...data,
    };
    // SetfarmersAnalyticsMapState({
    //   CountiesFarmersCountObject: CurrentCountiesFarmersCountObject,
    // });
    dispatcher(setCountiesFarmersCountObject(CurrentCountiesFarmersCountObject));
  };

  const HandleOnFarmersPerRegionCountUpdate = (data: any) => {
    // SetfarmersAnalyticsMapState({ regionsFarmersCountObject: data });
    dispatcher(setRegionsFarmersCountObject(data));
  };

  const HandleRegionClick = (region: any) => {
    const CurrentRegionsArray = [...farmersAnalyticsMapState.SelectedRegions];
    CurrentRegionsArray.push(region);

    // SetfarmersAnalyticsMapState({
    //   SelectedRegions: CurrentRegionsArray,
    //   LastClickedRegion: region,
    // });

    dispatcher(setSelectedRegions(CurrentRegionsArray));
    dispatcher(setLastClickedRegion(region));
  };

  const HandleCountyClick = (County: any) => {
    let CurrentCountiesArray = [...farmersAnalyticsMapState.SelectedCounties];
    CurrentCountiesArray.push(County);
    // SetfarmersAnalyticsMapState({
    //   SelectedCounties: CurrentCountiesArray,
    //   LastClickedCounty: County,
    // });
    dispatcher(setSelectedCounties(CurrentCountiesArray));
    dispatcher(setLastClickedCounty(County));
  };
  const HandleConstituencyClick = (Constituency: any) => {
    let CurrentConstituenciesArray = [...farmersAnalyticsMapState.SelectedConstituencies];
    CurrentConstituenciesArray.push(Constituency);
    // SetfarmersAnalyticsMapState({
    //   SelectedConstituencies: CurrentConstituenciesArray,
    //   LastClickedConstituency: Constituency,
    // });

    dispatcher(setSelectedConstituencies(CurrentConstituenciesArray));
    dispatcher(setLastClickedConstituency(Constituency));
  };

  const HandleFarmersPerConstituencyUpdate = (data: any) => {
    let CurrentConstituencyFarmersCountObject = farmersAnalyticsMapState.ConstituenciesFarmersCountObject;
    CurrentConstituencyFarmersCountObject = {
      ...CurrentConstituencyFarmersCountObject,
      ...data,
    };
    // SetfarmersAnalyticsMapState({
    //   ConstituenciesFarmersCountObject: CurrentConstituencyFarmersCountObject,
    // });

    dispatcher(setConstituenciesFarmersCountObject(CurrentConstituencyFarmersCountObject));
  };

  const HandleWardClick = (Ward: any) => {
    let CurrentWardsArray = [...farmersAnalyticsMapState.SelectedWards];
    CurrentWardsArray.push(Ward);
    // SetfarmersAnalyticsMapState({
    //   SelectedWards: CurrentWardsArray,
    //   LastClickedWards: Ward,
    // });

    dispatcher(setSelectedWards(CurrentWardsArray));
    dispatcher(setLastClickedWards(Ward));
  };
  const HandleFarmersPerWardUpdate = (data: any) => {
    let CurrentWardFarmersCountObject = farmersAnalyticsMapState.WardsFarmersCountObject;
    CurrentWardFarmersCountObject = {
      ...CurrentWardFarmersCountObject,
      ...data,
    };
    // SetfarmersAnalyticsMapState({
    //   WardsFarmersCountObject: CurrentWardFarmersCountObject,
    // });

    dispatcher(setWardsFarmersCountObject(CurrentWardFarmersCountObject));
  };

  const HandleCountiesGeoJsonUpdate = (data: any) => {
    let CurrentCountiesGeoJson = farmersAnalyticsMapState.CountiesGeoJson || {
      features: [],
    };
    let CurrentFeaturesArray = cloneDeep(CurrentCountiesGeoJson.features);
    CurrentFeaturesArray.push(...data.features);
    // SetfarmersAnalyticsMapState({
    //   CountiesGeoJson: { ...data, features: CurrentFeaturesArray },
    // });

    dispatcher(setCountiesGeoJson({ ...data, features: CurrentFeaturesArray }));
  };
  const HandleConstituenciesGeoJsonUpdate = (data: any) => {
    let CurrentConstituenciesGeoJson = farmersAnalyticsMapState.ConstituenciesGeoJson || { features: [] };
    let CurrentFeaturesArray = cloneDeep(CurrentConstituenciesGeoJson.features);
    CurrentFeaturesArray.push(...data.features);
    // SetfarmersAnalyticsMapState({
    //   ConstituenciesGeoJson: { ...data, features: CurrentFeaturesArray },
    // });

    dispatcher(setConstituenciesGeoJson({ ...data, features: CurrentFeaturesArray }));
  };
  const HandleWardsGeoJsonUpdate = (data: any) => {
    let CurrentWardsGeoJson = farmersAnalyticsMapState.WardsGeoJson || {
      features: [],
    };
    let CurrentFeaturesArray = cloneDeep(CurrentWardsGeoJson.features);
    CurrentFeaturesArray.push(...data.features);
    // SetfarmersAnalyticsMapState({
    //   WardsGeoJson: { ...data, features: CurrentFeaturesArray },
    // });

    dispatcher(setWardsGeoJson({ ...data, features: CurrentFeaturesArray }));
  };

  return (
    <Box component={Paper} width="100%" height="100%" overflow="hidden" position="relative">
      <MapContainer {...MapContainerProps}>
        <TileLayer url={`https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/reduced.day/{z}/{x}/{y}/256/png8?apiKey=${apiKey}`} />
        {/* <WorldLayer /> */}
        <WardLayer
          UserMenuRole={menuroles}
          UserProviderId={farmerAnalyticsPageState.selectedProviderId ? farmerAnalyticsPageState.selectedProviderId : provider_id}
          WardsGeoJson={farmersAnalyticsMapState.WardsGeoJson}
          onNewWardsGeoJson={HandleWardsGeoJsonUpdate}
          FarmersTotalCount={farmersAnalyticsMapState.FarmersTotalCount}
          WardsFarmersCountObject={farmersAnalyticsMapState.WardsFarmersCountObject}
          onFarmersPerWardCountUpdate={HandleFarmersPerWardUpdate}
          LastClickedConstituency={farmersAnalyticsMapState.LastClickedConstituency}
          SelectedWardsArray={farmersAnalyticsMapState.SelectedWards}
          onClick={HandleWardClick}
          SelectedConstituenciesArray={farmersAnalyticsMapState.SelectedConstituencies}
        />
        <ConstituencyLayer
          UserMenuRole={menuroles}
          UserProviderId={farmerAnalyticsPageState.selectedProviderId ? farmerAnalyticsPageState.selectedProviderId : provider_id}
          ConstituenciesGeoJson={farmersAnalyticsMapState.ConstituenciesGeoJson}
          onNewConstituenciesGeoJson={HandleConstituenciesGeoJsonUpdate}
          SelectedConstituenciesArray={farmersAnalyticsMapState.SelectedConstituencies}
          LastClickedCounty={farmersAnalyticsMapState.LastClickedCounty}
          onFarmersPerConstituencyCountUpdate={HandleFarmersPerConstituencyUpdate}
          ConstituenciesFarmersCountObject={farmersAnalyticsMapState.ConstituenciesFarmersCountObject}
          SelectedWards={farmersAnalyticsMapState.SelectedWards}
          onClick={HandleConstituencyClick}
          FarmersTotalCount={farmersAnalyticsMapState.FarmersTotalCount}
        />

        <CountyLayer
          UserMenuRole={menuroles}
          UserProviderId={farmerAnalyticsPageState.selectedProviderId ? farmerAnalyticsPageState.selectedProviderId : provider_id}
          CountiesGeoJson={farmersAnalyticsMapState.CountiesGeoJson}
          onNewCountiesGeoJson={HandleCountiesGeoJsonUpdate}
          FarmersTotalCount={farmersAnalyticsMapState.FarmersTotalCount}
          LastClickedRegion={farmersAnalyticsMapState.LastClickedRegion}
          SelectedCountiesArray={farmersAnalyticsMapState.SelectedCounties}
          SelectedConstituencies={farmersAnalyticsMapState.SelectedConstituencies}
          SelectedWards={farmersAnalyticsMapState.SelectedWards}
          onClick={HandleCountyClick}
          onFarmersPerCountyCountUpdate={HandleFarmersPerCountyUpdate}
          CountiesFarmersCountObject={farmersAnalyticsMapState.CountiesFarmersCountObject}
        />
        <RegionLayer
          UserMenuRole={menuroles}
          UserProviderId={farmerAnalyticsPageState.selectedProviderId ? farmerAnalyticsPageState.selectedProviderId : provider_id}
          SelectedConstituencies={farmersAnalyticsMapState.SelectedConstituencies}
          SelectedWards={farmersAnalyticsMapState.SelectedWards}
          SelectedCounties={farmersAnalyticsMapState.SelectedCounties}
          FarmersTotalCount={farmersAnalyticsMapState.FarmersTotalCount}
          SelectedRegionsArray={farmersAnalyticsMapState.SelectedRegions}
          onClick={HandleRegionClick}
          onFarmersPerRegionCountUpdate={HandleOnFarmersPerRegionCountUpdate}
          regionsFarmersCountObject={farmersAnalyticsMapState.regionsFarmersCountObject}
        />
      </MapContainer>
    </Box>
  );
};

export default AnalyticsMap;
