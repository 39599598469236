// import { Box, Paper } from "@mui/material";
import * as React from "react";
// import MaterialTable, { MaterialTableProps } from "material-table";
import { ExcludeActions, ExcludeFields, Fields, Schema } from "./schema";
import AttachDeviceDialog from // AttachDeviceDialogProps,
  "components/standalone-components/Attach-Device-Dialog/Attach-Device-Dialog";
import { Farmer } from "models/farmers";
// import { useRef } from "react";
import DetachDeviceConfirmModal from // DetachDeviceConfirmModalProps,
  "components/standalone-components/Detach-Device-Dialog/DetachConfirmDialog";
// import { IDevice } from "models/device";
// import ColumnsCheckComponent, {
//   dataType,
// } from "components/custom-compnents/columns-check-dialog/columns-check-dialog";
import CustomDevicesTable from "components/custom-compnents/custom-devices-table/custom-devices-table";
import { useLogic } from "./devices-table.logic";

export interface DevicesTableProps {
  by_farmer?: Farmer;
  assigned_devices?: boolean;
  ExcludeFields?: ExcludeFields;
  ExcludeActions?: ExcludeActions;
}

const DevicesTable: React.FC<DevicesTableProps> = ({
  by_farmer,
  assigned_devices,
  ExcludeFields,
  ExcludeActions,
}) => {
  // const [ShowColumnsDialog, SetShowColumnsDialog] = React.useState(false);
  // const HandleColumnsOkButton = () => SetShowColumnsDialog(false);
  // const [ColumnsData, SetColumnsData] = React.useState<dataType[]>([
  //   { label: "IMEI", checked: true, name: "imei" },
  //   { label: "Phone", checked: true, name: "phone_number" },
  //   { label: "Assigned Farmer", checked: true, name: "assigned_farmer_id" },
  //   { label: "Last Location", checked: true, name: "last_location" },
  //   { label: "Battery Status", checked: true, name: "battery_status" },
  // ]);
  // const ref = useRef() as React.MutableRefObject<any>;
  // const [opendAttacher, SetOpendAttacher] = React.useState(false);

  // const HandleDeviceAttachClick = () => SetOpendAttacher(true);
  // const HandleIgnore = () => SetOpendAttacher(false);
  // const HandleAttached = () => {
  //   SetOpendAttacher(false);
  //   ref?.current?.onQueryChange && ref?.current?.onQueryChange();
  // };

  // const MaterialTableProps: MaterialTableProps<Fields> = {
  //   tableRef: ref,
  //   ...Schema({
  //     assigned_devices,
  //     by_farmer_id: by_farmer?.id,
  //     onAttachClick: handleDeviceAttachClick,
  //     onDetachDevice: (Device) => SetDetachDeviceInfo(Device),
  //     ExcludeFields: ColumnsData.filter((item) => !item.checked)
  //       .map((item) => item.name)
  //       .concat((ExcludeFields as string[]) || []) as ExcludeFields,
  //     ExcludeActions,
  //     OnColumnsButtonClicked: () => SetShowColumnsDialog(true),
  //   }),
  // };


  const shouldHideDialog = React.useMemo(() => {
    return !!ExcludeActions?.find((action) => action === 'attach')
  }, [ExcludeActions])


  const {
    devicesTableProps,
    AttachDeviceDialogProps,
    DetachDeviceConfirmModalProps,
  } = useLogic({
    assigned_devices: assigned_devices,
    farmer: by_farmer,
  });

  return (
    // <React.Fragment>
    //   <AttachDeviceDialog {...AttachDeviceDialogProps} />
    //   <ColumnsCheckComponent
    //     onChange={(newData) => SetColumnsData(newData)}
    //     data={ColumnsData}
    //     open={ShowColumnsDialog}
    //     onOk={HandleColumnsOkButton}
    //   />

    //   <Box p={3} component={Paper}>
    //     <MaterialTable {...MaterialTableProps} />
    //   </Box>
    // </React.Fragment>
    <>
      <DetachDeviceConfirmModal {...DetachDeviceConfirmModalProps} />
      {
        !shouldHideDialog &&
        <AttachDeviceDialog {...AttachDeviceDialogProps} />
      }
      <CustomDevicesTable {...devicesTableProps} hideDetachAction={shouldHideDialog} />
    </>
  );
};

export default DevicesTable;
