import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";

export interface IResp {
  cube: {
    farms: {
      unique_farms_count: number;
    };
  }[];
}

export interface IParams {
  region_id_list?: string[];
  county_id_list?: string[];
  constituency_id_list?: string[];
  ward_id_list?: string[];
  provider_id?: string;
}

export const getFarmsKpi = async (params: IParams) => {

  return await AxiosInstance.get("/farmer-analytics/find-farms-count-kpi", {
    params
  });
};
