import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { GetProvider } from "api/provider/get-provider";
import SelectMenu, {
  option,
} from "components/input-components/select-menu/SelectMenu";
import * as yup from "yup";
import { AddBroadcasts } from "api/broadcasts/add-broadcasts";
import { UpdateBroadcasts } from "../../../api/broadcasts/update-broadcasts";
import { GetBroadcasts } from "../../../api/broadcasts/get-broadcasts";
import { IUser } from "models/user";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import SenderInput from "components/input-components/sender-input/sender-input";
import { broadcast } from "models/broadcast";
import { ISenderId } from "models/sender-id";

interface AddUpdateBroadcastProps {
  open: boolean;
  OnCancel: () => any;

  UserProviderId: IUser["provider_id"];
  UserMenurules: IUser["menuroles"];
}

interface AddBroadcastProps {
  Variant: "Add";
  OnAdded: () => any;
}
interface UpdateBroadcastProps {
  Variant: "Update";
  onUpdated: () => any;
  Broadcast_id: string;
}
export type AddUpdateBroadcastComponentProps = AddUpdateBroadcastProps &
  (AddBroadcastProps | UpdateBroadcastProps);
const AddUpdateBroadcast: React.FunctionComponent<AddUpdateBroadcastComponentProps> =
  ({ open, OnCancel, UserMenurules, UserProviderId, ...Props }) => {
    const [isInitialLoad, setIsinitialLoad] = React.useState(true);
    const [IsSubmitting, SetIsSubmitting] = React.useState(false);
    const [IsLoadingData, SetIsLoadingData] = React.useState(false);
    const [ProviderListOptions, SetProviderListOptions] = React.useState<
      option[]
    >([]);
    const [ProviderTerm, SetProviderTerm] = React.useState("");
    const [ProvidersTotalCount, SetProvidersTotalCount] = React.useState(0);
    const [InitValues, SetInitValues] = React.useState<Partial<broadcast>>({
      provider_id: undefined as unknown as string,
      name: "",
      description: "",
      is_published: false,
      relative_send_dates: false,
      sender_by_sender_id: {
        id: "",
        at_sender_id: "",
      },
    });
    // const DialogProps: CustomDialogProps = {
    //   ActionButtonsProps: {
    //     ButtonsVariant: "ConfirmCancel",
    //     OnConfirm: () =>
    //       document.getElementById("BroadcastSubmitButton")?.click(),
    //     OnCancel: OnCancel,
    //     DisableConfirm: IsSubmitting,
    //   },
    //   Header:
    //     Props.Variant === "Add" ? "Add New Broadcast" : "Update Broadcast",
    //   open: open,
    // };

    const DataArray = [
      {
        name: "name",
        placeholder: "Insert Broadcast Name...",
        label: "Broadcast Name",
      },
      {
        name: "description",
        placeholder: "Insert Broadcast Description ...",
        label: "Broadcast Description ",
        multiline: true,
      },
      {
        name: "provider_id",
        placeholder: `${ProviderListOptions.length} of ${ProvidersTotalCount} Organization`,
        type: "list",
        disabled: UserMenurules !== "admin",
        hidden: UserMenurules !== "admin",
      },
      {
        name: "sender_id",
        type: "sender",
      },
      {
        name: "relative_send_dates",
        label: "Relative send date?",
        type: "switch",
      },
      { name: "is_published", label: "Published?", type: "switch" },
    ];

    React.useEffect(() => {
      GetProvider(
        { page: 0, pageSize: 10, search: ProviderTerm, is_active: true },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            SetProvidersTotalCount(resp.totalCount);
            SetProviderListOptions(
              resp.providers.map((item, index) => ({
                title: item.name,
                value: item.id,
              }))
            );
          }
        }
      );
    }, [ProviderTerm]);

    React.useEffect(() => {
      if (Props.Variant === "Update")
        GetBroadcasts(
          { page: 0, pageSize: 10, id: Props.Broadcast_id },
          (error, resp) => {
            if (error) {
              console.log(error);
            } else if (resp) {
              SetInitValues(resp.items[0]);
            }
          }
        );
    }, [Props.Variant === "Update" && Props.Broadcast_id]);

    const ValidationSchema = yup.object().shape({
      ...(UserMenurules === "admin"
        ? {
            provider_id: yup
              .string()
              .required()
              .not(["none"])
              .label("Organization"),
          }
        : {}),
      name: yup.string().required(),
      description: yup.string().required(),
      sender_by_sender_id: yup.object({
        id: yup.string().required("sender is required"),
      }),
    });
    return (
      <Formik
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, actions) => {
          SetIsSubmitting(true);
          Props.Variant === "Add" &&
            AddBroadcasts(
              {
                provider_id: values.provider_id,
                name: values.name,
                description: values.description,
                is_published: values.is_published,
                relative_send_dates: values.relative_send_dates,
                sender_id: values.sender_by_sender_id?.id,
              },
              (error, resp) => {
                if (error) {
                  console.log(error.message);
                } else if (resp) {
                  SetIsSubmitting(false);
                  actions.resetForm();
                  Props.OnAdded();
                }
              }
            );
          Props.Variant === "Update" &&
            UpdateBroadcasts(
              {
                ...values,
                id: Props.Broadcast_id,
                sender_id: values?.sender_by_sender_id?.id!,
              },
              (error, resp) => {
                if (error) {
                  console.log(error.message);
                } else if (resp) {
                  SetIsSubmitting(false);
                  actions.resetForm();
                  Props.onUpdated();
                }
              }
            );
        }}
        initialValues={InitValues}
      >
        {(FormikProps) => (
          <CustomDialogV2
            open={open}
            header={
              Props.Variant === "Add" ? "Add New Broadcast" : "Update Broadcast"
            }
            footer={
              <Box display="flex" justifyContent="flex-end">
                <CancelSuccessButtonsGroup
                  RootGridProps={{ width: 300 }}
                  CancelButtonProps={{ label: "Cancel", onClick: OnCancel }}
                  SuccessButtonProps={{
                    label: "Confirm",
                    disabled: IsSubmitting,
                    onClick: () => FormikProps.submitForm(),
                  }}
                />
              </Box>
            }
          >
            {/* <CustomDialog {...DialogProps}> */}
            {Props.Variant === "Update" && IsLoadingData ? (
              <Typography>Loading ...</Typography>
            ) : (
              <Box width={350} mt={1}>
                <form onSubmit={FormikProps.handleSubmit}>
                  <Grid container spacing={2}>
                    {DataArray.map(
                      (item, index) =>
                        !item.disabled && (
                          <Grid item key={index} xs={12}>
                            {item.type === "list" ? (
                              <SelectMenu
                                disabled={
                                  item.disabled ||
                                  (item.name === "sender_id" &&
                                    !FormikProps.values["provider_id"])
                                }
                                error={Boolean(
                                  (FormikProps as any).errors[item.name]
                                )}
                                helperText={
                                  (FormikProps as any).errors[item.name]
                                }
                                OnChange={(e) => {
                                  FormikProps.setFieldValue(
                                    item.name,
                                    e.target.value
                                  );
                                  FormikProps.setFieldValue(
                                    "sender_by_sender_id",
                                    undefined
                                  );
                                }}
                                value={(FormikProps as any).values[item.name]}
                                options={ProviderListOptions}
                                SearchLabel=""
                                onSearchInputChange={(value) =>
                                  SetProviderTerm(value)
                                }
                                SearchPlaceholder={`Insert Organization Name`}
                                Searchable={true}
                                Placeholder={item.placeholder}
                              />
                            ) : item.type === "switch" ? (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      (FormikProps as any).values[item.name]
                                    }
                                    onChange={(e) =>
                                      (FormikProps as any).setFieldValue(
                                        item.name,
                                        e.target.checked
                                      )
                                    }
                                  />
                                }
                                label={item.label as any}
                              />
                            ) : item.type === "sender" ? (
                              <SenderInput
                                key={FormikProps.values["provider_id"]}
                                isInitialLoad={isInitialLoad}
                                onInitialValues={(sender: ISenderId) => {
                                  setIsinitialLoad(false);
                                  FormikProps.setFieldValue(
                                    "sender_by_sender_id",
                                    sender
                                  );
                                }}
                                error={
                                  (FormikProps as any).errors[
                                    "sender_by_sender_id"
                                  ] &&
                                  (FormikProps as any).errors[
                                    "sender_by_sender_id"
                                  ].id
                                    ? (FormikProps as any).errors[
                                        "sender_by_sender_id"
                                      ].id
                                    : undefined
                                }
                                senderType="Africastalking_sender"
                                providerId={FormikProps.values["provider_id"]}
                                value={
                                  FormikProps.values["sender_by_sender_id"]!
                                }
                                onChange={(value) =>
                                  FormikProps.setFieldValue(
                                    "sender_by_sender_id",
                                    value
                                  )
                                }
                              />
                            ) : (
                              <TextField
                                error={Boolean(
                                  (FormikProps as any).errors[item.name]
                                )}
                                helperText={
                                  (FormikProps as any).errors[item.name]
                                }
                                value={(FormikProps as any).values[item.name]}
                                placeholder={item.placeholder}
                                // label={item.label}
                                name={item.name}
                                autoFocus={index === 0}
                                variant="outlined"
                                size="small"
                                fullWidth
                                multiline={item.multiline}
                                rows={item.multiline ? 4 : undefined}
                                onChange={FormikProps.handleChange}
                              />
                            )}
                          </Grid>
                        )
                    )}
                  </Grid>
                  <button
                    type="submit"
                    id="BroadcastSubmitButton"
                    style={{ display: "none" }}
                  />
                </form>
              </Box>
            )}
            {/* </CustomDialog> */}
          </CustomDialogV2>
        )}
      </Formik>
    );
  };

export default AddUpdateBroadcast;
