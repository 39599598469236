import { Grid } from "@mui/material";
import WhatsappTemplatesTable from "components/data-tables/whatsapp-templates-table/whatsapp-templates-table";
import { FunctionComponent } from "react";

interface MessageTemplatesPageProps {}

const MessageTemplatesPage: FunctionComponent<MessageTemplatesPageProps> = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WhatsappTemplatesTable />
      </Grid>
    </Grid>
  );
};

export default MessageTemplatesPage;
