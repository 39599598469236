import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Constituency } from "models/constituency";

interface Params {
  page: number;
  pageSize: number;
  name?: string;
  region_id_list?: string[];
  county_id_list?: string[];
  constituency_id_list?: string[];
  is_attached_to_farmers?: boolean;
  with_county?: boolean;
}
interface Resp {
  allConstituencies: {
    items: Constituency[];
    totalCount: number;
  }
}

interface ConstituencyDto {
  id: string;
  name: string;
  county_id: string;
  constituency_id: string;
  county_by_county_id: {
    name: string;
    region_id: string;
  }
}

type GetConstituenciesType = (
  Params: Params,
  callback: (error: AxiosError | null, resp: null | Resp) => any
) => any;

const GetConstituencies: GetConstituenciesType = (Params, callback) => {
  AxiosInstance.get("/constituencies", { params: Params })
    .then((resp) => {
      callback(null, {
        allConstituencies: {
          items: resp.data.all_constituencies.nodes.map((item: ConstituencyDto) => ({
            id: item.id,
            name: item.name,
            constituencyId: item.constituency_id,
            countyId: item.county_id,
            countyByCountyId: {
              name: item.county_by_county_id.name,
              regionId: item.county_by_county_id.region_id
            }
          })),
          totalCount: resp.data.all_constituencies.total_count
        }
      });
    })
    .catch((error) => callback(error, null));
};

export { GetConstituencies };
