import React, { useEffect } from "react";
import { GeoJSON } from "react-leaflet";
import Leaflet from "leaflet";
import ColorPercentageRange from "./utils/colorRangePercentageFunction";
import { ConstituencyStateItem, WardsStateItem } from "store/reducers/farmers-analytics-page/map/farmers-analytics-map-reducer";
import { IUser } from "models/user";
import { GetCountiesGeojson } from "api/geojson/county-geojson";
import { getFarmersKpi } from "api/kpis/farmers";
export interface CountyLayerProps {
  onClick: (County?: any) => void;
  onFarmersPerCountyCountUpdate: (data: any) => void;
  CountiesFarmersCountObject: any;
  SelectedCountiesArray: any[];
  LastClickedRegion: any;
  FarmersTotalCount: number;
  onNewCountiesGeoJson: (data: any) => void;
  CountiesGeoJson: any;
  SelectedConstituencies: ConstituencyStateItem[];
  SelectedWards: WardsStateItem[];
  UserMenuRole: IUser["menuroles"];
  UserProviderId: IUser["provider_id"];
}

const CountyLayer: React.SFC<CountyLayerProps> = ({
  onClick = () => null,
  onFarmersPerCountyCountUpdate = () => null,
  CountiesFarmersCountObject,
  SelectedCountiesArray,
  LastClickedRegion,
  FarmersTotalCount,
  onNewCountiesGeoJson,
  CountiesGeoJson,
  SelectedConstituencies,
  SelectedWards,
  UserMenuRole,
  UserProviderId,
}) => {
  useEffect(() => {
    if (LastClickedRegion?.region_id) {
      GetCountiesGeojson({
        region_id_list: [LastClickedRegion.region_id]
      }, (error, resp) => {
        if (error) {
          console.log(error.message)
        } else {
          getFarmersKpi({
            per_region: true,
            per_county: true,
            county_id_list: resp.data.features.map((item: { properties: { county_id: string } }) => item.properties.county_id),
            provider_id: UserProviderId
          })
            .then(result => {
              onFarmersPerCountyCountUpdate(result.data.data.cube.reduce((prevObj: any, item: any) => ({ ...prevObj, [`county_${item.counties.county_id}`]: { totalCount: item.farmers.unique_farmer_count } }), {}))
            })
            .catch(err => {
              console.log(err, 'err')
            })
          onNewCountiesGeoJson(resp?.data);

        }
      })



      // AxiosInstance.post(Url, {
      //   regions_ids: [LastClickedRegion.region_id],
      // }).then((response) => {
      //   let AdjustedGeoJson = response.data;
      //   const Current_Region_Counties_array = AdjustedGeoJson.features.map((item: any) => item.properties.county_id);
      //   getFarmersPercentageInCounties(
      //     {
      //       CountiesId: Current_Region_Counties_array,
      //       ProviderId: UserProviderId,
      //     },
      //     (data) => {
      //       onFarmersPerCountyCountUpdate!(data);
      //     }
      //   );
      //   onNewCountiesGeoJson(AdjustedGeoJson);
      // });


    }


  }, [LastClickedRegion, UserProviderId]);

  // add hover effect
  const highlightFeature = (e: any) => {
    const layer = e.target;

    layer.setStyle({
      color: "#666",
      dashArray: "",
    });

    if (!Leaflet.Browser.ie && !Leaflet.Browser.opera && !Leaflet.Browser.edge) {
      layer.bringToFront();
    }
  };

  // remove hover effect
  const resetHighlight = (e: any) => {
    const layer = e.target;
    const County_Id = layer.feature.properties.county_id;
    const Current_Region_Farmers_Count = parseInt(CountiesFarmersCountObject![`county_${County_Id}`]?.totalCount || 0);

    const FarmersPercentageInCurrentCounty = (Current_Region_Farmers_Count * 100) / FarmersTotalCount || 0;
    layer.setStyle({
      weight: 2,
      color: ColorPercentageRange(FarmersPercentageInCurrentCounty),
    });
  };

  // handle click on region
  const HandleClick = async (e: any) => {
    CountiesFarmersCountObject![`county_${e.target.feature.properties.county_id}`]?.totalCount > 0 && // only click if it's not already selected
      onClick(e.target.feature.properties);
  };

  const GeoJSONProps: any = {
    onEachFeature: (feature: any, layer: any) => {
      const County_Id = feature.properties.county_id;
      const FarmersCountPerCounty = CountiesFarmersCountObject![`county_${County_Id}`]?.totalCount || 0;
      layer.bindTooltip(`<h5>${feature.properties.county} county</h5> <p>${feature.properties.region}</p> (${FarmersCountPerCounty} farmer)`);

      layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: HandleClick,
      });
    },

    style: (feature: any) => {
      const County_Id = feature.properties.county_id;
      const Current_Region_Farmers_Count = parseInt(CountiesFarmersCountObject![`county_${County_Id}`]?.totalCount || 0);

      const FarmersPercentageInCurrentCounty = (Current_Region_Farmers_Count * 100) / FarmersTotalCount || 0;

      return {
        fillColor: !SelectedCountiesArray.some((item) => item.county_id === County_Id) ? ColorPercentageRange(FarmersPercentageInCurrentCounty) : "transparent",
        weight: 2,
        opacity: 1,
        dashArray: "0",
        fillOpacity: 0.7,
        color: SelectedCountiesArray.some((item) => item.county_id === County_Id)
          ? (SelectedConstituencies.length > 0 && !SelectedConstituencies.some((SelectedConstituenciesItem) => SelectedConstituenciesItem.county_id === County_Id)) || (SelectedWards.length > 0 && !SelectedWards.some((SelectedWardsItem) => SelectedWardsItem.county_id === County_Id))
            ? "steelblue"
            : "blue"
          : ColorPercentageRange(FarmersPercentageInCurrentCounty),
        interactive: !SelectedCountiesArray.some((item) => item.county_id === County_Id),
      };
    },
    data: CountiesGeoJson,
    key: Math.random(),
    // filter: (geoJsonFeature) => {
    //     return (
    //         !SelectedCountiesArray.some((item) => item.county_id === geoJsonFeature.properties.county_id) &&
    //         SelectedRegionsArray.some((item) => item.region_id === geoJsonFeature.properties.region_id)
    //         //&& CountiesFarmersCountObject![`county_${geoJsonFeature.properties.county_id}`]?.totalCount > 0 // remove counties if higher Own Region Not Selected yet .. and dosn't have any farmers in it
    //     )
    // },
  };

  return <GeoJSON {...GeoJSONProps} />;
};

export default CountyLayer;
