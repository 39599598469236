import { GetSurveyQuestionAnswers } from "api/survey-question-answer/get-answer";
import { GetSurveyQuestions } from "api/survey-questions/get-survey-questions";
import { UpdateSurveyQuestion } from "api/survey-questions/update-survey-question";
import { ISingleChoiceAnswerItem } from "components/custom-compnents/custom-survey-question/custom-survey-question";
import { SurveyQuestion } from "models/survey-questions";
import { SurveyQuestionAnswer } from "models/survey-questions-answer";
import React from "react";
import { RemoveSurveyQuestionComponentProps } from "../remove-survey-question-component/remove-survey-question-component";

export interface IParams {
  survey_id: string;
  onQuestionsCountLoad?: (data: number) => any;
}

export const useLogic = (params: IParams) => {
  const [questionsData, setQuestionsData] = React.useState<SurveyQuestion[]>(
    []
  );

  const [questionsAnswers, setQuestionsAnswers] = React.useState<
    SurveyQuestionAnswer[]
  >([]);

  const [openQuestionId, setOpenQuestionId] =
    React.useState<string | undefined>(undefined);

  const [questionToBeDeleted, setQuestionToBeDeleted] =
    React.useState<undefined | SurveyQuestion>(undefined);

  const [questionOrder, setQuestionOrder] =
    React.useState<undefined | SurveyQuestion>(undefined);

  const [questionToBeUpdated, setQuestionToBeUpdated] =
    React.useState<undefined | SurveyQuestion>(undefined);

  const [questionAnswerListToBeUpdated, setQuestionAnswerListToBeUpdated] =
    React.useState<SurveyQuestionAnswer[]>([]);

  const [isLoadingViewAnswers, setIsLoadingViewAnswers] = React.useState(false);

  const [isLoadingEditAnswers, setIsLoadingEditAnswers] = React.useState(false);

  const [isLoadingQuestions, setIsLoadingQuestions] = React.useState(false);

  const [isSubmittingUpdate, setIsSubmittingUpdate] = React.useState(false);

  const { survey_id, onQuestionsCountLoad } = params;

  const handleCancelDeleteQuestion = () => {
    setQuestionToBeDeleted(undefined);
  };

  const handleSetQuestionOrder = () => {
    setQuestionOrder(undefined);
  };

  const handleDeletedQuestion = () => window.location.reload();

  const handleQuestionTextChange = (value: string) => {
    if (questionToBeUpdated)
      setQuestionToBeUpdated({ ...questionToBeUpdated, question: value });
  };

  const handleQuestionTypeChange = (value: any) => {
    if (questionToBeUpdated)
      setQuestionToBeUpdated({ ...questionToBeUpdated, type: value });
  };

  const handleAddNewAnswer = () => {
    if (questionToBeUpdated)
      setQuestionAnswerListToBeUpdated([
        ...questionAnswerListToBeUpdated,
        {
          uid: Math.floor(Math.random() * 99999),
          answer: "",
          next_qid: "none",
          question_id: questionToBeUpdated?.id,
          id: "",
          deleted_at: "",
          sort_order: questionAnswerListToBeUpdated.length + 1,
        },
      ]);
  };

  const handleDefaultNextQuestionChange = (value: string) => {
    if (questionToBeUpdated)
      setQuestionToBeUpdated({
        ...questionToBeUpdated,
        default_next_qid: value,
      });
  };

  const handleUpdateConfirmButtonClick = () => {
    if (questionToBeUpdated) {
      setIsSubmittingUpdate(true);
      UpdateSurveyQuestion(
        {
          survey_id,
          question_id: questionToBeUpdated?.id,
          question: questionToBeUpdated.question,
          type: questionToBeUpdated.type,
          default_next_qid:
            questionToBeUpdated.default_next_qid ?? undefined,
          answer_list: questionAnswerListToBeUpdated
            .filter((item) => item.answer)
            .map((item) => ({
              answer: item.answer,
              next_qid: item.next_qid,
            })),
        },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            setIsSubmittingUpdate(false);
            window.location.reload();
          }
        }
      );
    }
  };

  const handleSingleChoiceAnswerChange = (data: ISingleChoiceAnswerItem) => {

    let arrayClone = questionAnswerListToBeUpdated.map((item) => item);
    const itemIndexToUpdate = arrayClone.findIndex(
      (item) => item.uid === data.uid
    );
    arrayClone[itemIndexToUpdate] = data;
    setQuestionAnswerListToBeUpdated(arrayClone);
  };

  // get questions once
  React.useEffect(() => {
    setIsLoadingQuestions(true);
    GetSurveyQuestions({ survey_id, is_active: true }, (error, resp) => {
      if (error) {
        console.log(error.message);
      }
      if (resp) {
        setIsLoadingQuestions(false);
        onQuestionsCountLoad?.(resp.items.length);
        // with exist screen mock data
        setQuestionsData([
          ...resp.items,
          {
            question: "exit screen",
            id: "none",
            type: "free text",
            default_next_qid: "",
            sort_order: 0,
            survey_id,
            deleted_at: "",
          },
        ]);
      }
    });
  }, []);

  // get answers when open question id change
  React.useEffect(() => {
    if (openQuestionId) {
      setIsLoadingViewAnswers(true);
      GetSurveyQuestionAnswers(
        { survey_id, question_id: openQuestionId, is_active: true },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {

            setIsLoadingViewAnswers(false);
            setQuestionsAnswers([
              ...resp.items
                .filter((item) => !item.deleted_at)
                .map((item) => ({
                  ...item,
                  next_qid: item.next_qid ? item.next_qid : "none"
                })),
            ]);
          }
        }
      );
    }
  }, [openQuestionId]);

  // get answers when questionToUpdate id change
  React.useEffect(() => {
    if (questionToBeUpdated?.id) {
      setIsLoadingEditAnswers(true);
      GetSurveyQuestionAnswers(
        { survey_id, question_id: questionToBeUpdated?.id, is_active: true },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            setIsLoadingEditAnswers(false);
            setQuestionAnswerListToBeUpdated([
              ...resp.items
                .filter((item) => !item.deleted_at)
                .map((item) => ({
                  ...item,
                  next_qid: item.next_qid ? item.next_qid : "none",
                })),
            ]);
          }
        }
      );
    }
  }, [questionToBeUpdated?.id]);

  const removeQuestionDialogProps:
    | RemoveSurveyQuestionComponentProps
    | undefined = questionToBeDeleted?.id
      ? {
        id: questionToBeDeleted?.id,
        survey_id: questionToBeDeleted?.survey_id,
        onCancel: handleCancelDeleteQuestion,
        onDeleted: handleDeletedQuestion,
        open: !!questionToBeDeleted,
      }
      : undefined;

  const questionOrderDialogProps:
    | RemoveSurveyQuestionComponentProps
    | undefined = questionOrder?.id
      ? {
        id: questionOrder?.id,
        survey_id: questionOrder?.survey_id,
        onCancel: handleSetQuestionOrder,
        onDeleted: handleDeletedQuestion,
        open: !!questionOrder,
      }
      : undefined;

  return {
    questionsData,
    setOpenQuestionId,
    openQuestionId,
    questionsAnswers,
    setQuestionToBeDeleted,
    removeQuestionDialogProps,
    questionOrderDialogProps,
    questionToBeUpdated,
    setQuestionToBeUpdated,
    handleQuestionTextChange,
    handleQuestionTypeChange,
    handleDefaultNextQuestionChange,
    questionAnswerListToBeUpdated,
    handleAddNewAnswer,
    isLoadingViewAnswers,
    isLoadingEditAnswers,
    handleSingleChoiceAnswerChange,
    isLoadingQuestions,
    isSubmittingUpdate,
    handleUpdateConfirmButtonClick,
    setQuestionOrder
  };
};
