import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { IQualityCheck } from "models/quality-check";

interface Params
  extends Pick<IQualityCheck, "type" | "status" | "report" | "passed"> {
  id: string;
}

type UpdateQualityCheckType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: any | null) => any
) => any;

const UpdateQualityCheck: UpdateQualityCheckType = (Params, callback) => {
  AxiosInstance.patch("/quality_checks/" + Params.id, { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { UpdateQualityCheck };
