import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { County } from "models/county";

interface Params {
  page: number;
  pageSize: number;
  name?: string;
  region_id_list?: string[];
  county_id_list?: string[];
  is_attached_to_farmers?: boolean;
  with_region?: boolean;
}
interface Resp {
  allCounties: {
    items: County[];
    totalCount: number;
  }
}

interface CountyDto {
  id: string;
  name: string;
  region_id: string;
  county_id: string;
  region_by_region_id: {
    name: string;
  }
}

type GetCountiesType = (
  Params: Params,
  callback: (error: AxiosError | null, resp: null | Resp) => any
) => any;

const GetCounties: GetCountiesType = (Params, callback) => {
  AxiosInstance.get("/counties", { params: Params })
    .then((resp) => {
      callback(null, {
        allCounties: {
          items: resp.data.data.all_counties.nodes.map((item: CountyDto) => ({
            id: item.id,
            name: item.name,
            regionId: item.region_id,
            countyId: item.county_id,
            regionByRegionId: item.region_by_region_id
          })),
          totalCount: resp.data.data.all_counties.total_count
        }
      });
    })
    .catch((error) => callback(error, null));
};

export { GetCounties };
