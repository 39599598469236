import { FunctionComponent } from "react";
import CustomDialogV2 from "../custom-dialog-v2/cusotm-dialog-v2";
import { Button } from "@mui/material";

interface NoteDialogProps {
  open?: boolean;
  header?: string;
  content?: string;
  onHideClick?: () => void;
}

const NoteDialog: FunctionComponent<NoteDialogProps> = (props) => {
  const { open, header, content, onHideClick } = props;
  return (
    <CustomDialogV2
      open={open ?? false}
      footer={
        <Button onClick={onHideClick} fullWidth>
          Hide
        </Button>
      }
      header={header}
    >
      {content}
    </CustomDialogV2>
  );
};

export default NoteDialog;
