import { AxiosInstance } from "configs/axios";
// import { IOrganizationRelation } from "models/organizations-relation";
interface Params {
  super_org: string;
  sub_org: string;
}
type Resp = "ok";

const deleteSuperOrganizationRelation = (Params: Params) => {
  return AxiosInstance.delete<Resp>("/organization-relations", { data: { ...Params } });
};

export { deleteSuperOrganizationRelation };
