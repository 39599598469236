import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Ward } from "models/ward";

interface Params {
    page: number;
    pageSize: number;
    name?: string;
    region_id_list?: string[];
    county_id_list?: string[];
    constituency_id_list?: string[];
    ward_id_list?: string[];
    is_attached_to_farmers?: boolean;
    with_constituency?: boolean;
    with_county?: boolean;
}
interface Resp {
    allWards: {
        items: Ward[];
        totalCount: number;
    }
}

interface WardDto {
    id: string;
    name: string;
    ward_id: string;
    constituency_id: string;
    lat: number;
    lng: number;
    constituency_by_constituency_id: {
        name: string;
        county_id: string;
        county_by_county_id: {
            region_id: string;
        }
    }
}

type GetWardsType = (
    Params: Params,
    callback: (error: AxiosError | null, resp: null | Resp) => any
) => any;

const GetWards: GetWardsType = (Params, callback) => {
    AxiosInstance.get("/wards", { params: Params })
        .then((resp) => {
            callback(null, {
                allWards: {
                    items: resp.data.all_wards.nodes.map((item: WardDto) => ({
                        id: item.id,
                        name: item.name,
                        constituencyId: item.constituency_id,
                        wardId: item.ward_id,
                        lat: item.lat,
                        lng: item.lng,
                        constituencyByConstituencyId: {
                            name: item.constituency_by_constituency_id.name,
                            countyId: item.constituency_by_constituency_id.county_id,
                            countyByCountyId: {
                                regionId: item.constituency_by_constituency_id.county_by_county_id.region_id
                            }
                        }
                    })),
                    totalCount: resp.data.all_wards.total_count
                }
            });
        })
        .catch((error) => callback(error, null));
};

export { GetWards };
