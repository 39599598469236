import { Grid } from "@mui/material";
import DateRangePicker, { DateRangePickerProps } from "components/custom-compnents/date-range-picker/date-range-picker";
import EwalletUsersInput, { EwalletUsersInputProps } from "components/input-components/ewallet-users-input/ewallet-users-input";
import { FunctionComponent } from "react";

export interface EwalletTransactionFilterFormProps {
  ewalletUsersFilterProps?: EwalletUsersInputProps;
  dateRangeFilterProps?: DateRangePickerProps;
  showEwalletUsersFilter?: boolean;
  showDateRangeFilter?: boolean;
}

const EwalletTransactionFilterForm: FunctionComponent<EwalletTransactionFilterFormProps> = (props) => {
  const { dateRangeFilterProps, ewalletUsersFilterProps, showDateRangeFilter, showEwalletUsersFilter } = props;

  const FieldsData = [
    {
      label: "eWallet User",
      type: "ewallet-user",
      props: ewalletUsersFilterProps,
      hidden: showEwalletUsersFilter,
    },
    {
      label: "Date Range",
      type: "date-range",
      props: dateRangeFilterProps,
      hidden: showDateRangeFilter,
    },
  ];

  return (
    <Grid container spacing={2}>
      {FieldsData.map(({ type, ...item }, index) => (
        <Grid item lg={3} xs={12} key={index}>
          {type === "date-range" && <DateRangePicker fullWidth {...(item.props as DateRangePickerProps)} />}
          {type === "ewallet-user" && <EwalletUsersInput fullWidth {...(item.props as EwalletUsersInputProps)} />}
        </Grid>
      ))}
    </Grid>
  );
};

export default EwalletTransactionFilterForm;
