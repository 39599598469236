import { Box, Grid, FormControlLabel, Switch, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Formik, FormikConfig } from "formik";
import { AddProvider } from "../../../api/provider/add-provider";
import { IProvider } from "models/provider";
import * as yup from "yup";
import { Alert } from "@mui/material";
import { GetProvider } from "api/provider/get-provider";
import { UpdateProvider } from "api/provider/update-provider";
import SelectMenu from "components/input-components/select-menu/SelectMenu";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import { useAppSelector } from "store/hooks";
import cleanDeep from "clean-deep";
export interface AddUpdateProviderComponentProps {
  open?: boolean;
  onCancel: () => void;
}

export interface UpdateProviderComponentProps {
  variant: "update";
  Provider?: Pick<IProvider, "id" | "name">;
  onUpdated?: () => void;
}

export interface AddProviderComponentProps {
  variant: "add";
  onAdded?: () => void;
  is_sub_org?: boolean;
}

export type propsType = AddUpdateProviderComponentProps &
  (UpdateProviderComponentProps | AddProviderComponentProps);

const SubmitButtonRef = React.createRef<HTMLButtonElement>();
const AddUpdateProviderComponent: React.FC<propsType> = ({
  open,
  onCancel,
  ...props
}) => {
  const [IsSubmitting, setIsSubmitting] = React.useState(false);
  const [LoadingData, SetLoadingData] = React.useState(false);
  const [InitalValues, SetInitalValues] = React.useState<
    Pick<
      Fields,
      | "email"
      | "isActive"
      | "name"
      | "phone"
      | "smsSigName"
      | "type"
      | "store_gps_lat"
      | "store_gps_lon"
    >
  >({
    email: "",
    isActive: true,
    name: "",
    phone: "",
    type: "enterprise",
    smsSigName: undefined,
    store_gps_lat: undefined,
    store_gps_lon: undefined,
  });
  const TypeOptions: { title: IProvider["type"]; value: IProvider["type"] }[] =
    [
      { title: "enterprise", value: "enterprise" },
      { title: "hub", value: "hub" },
    ];
  const user = useAppSelector((root) => root.appReducer.user);

  interface Fields extends Omit<IProvider, "id" | "autoAssignSources"> { }
  const FieldsData = [
    {
      name: "name",
      label: "Name",
      placeholder: "Insert Name...",
      type: "text",
      defaultValue: "",
    },
    {
      name: "phone",
      label: "Phone",
      placeholder: "Insert Phone...",
      type: "text",
      defaultValue: "",
    },
    {
      name: "email",
      label: "E-mail",
      placeholder: "Insert email address...",
      type: "text",
      defaultValue: "",
    },
    {
      name: "type",
      label: "Type",
      placeholder: "Select Type",
      type: "select",
      defaultValue: "",
    },
    {
      name: "smsSigName",
      label: "SMS SIGNATURE NAME",
      placeholder: "Insert SIGNATURE NAME",
      type: "text",
      defaultValue: "",
    },
    {
      name: "store_gps_lat",
      label: "lat",
      placeholder: "Insert latitude ...",
      type: "number",
      defaultValue: null,
      gridProps: {
        xs: 6,
      },
    },
    {
      name: "store_gps_lon",
      label: "lng",
      placeholder: "Insert longitude ...",
      type: "number",
      defaultValue: null,
      gridProps: {
        xs: 6,
      },
    },
    {
      name: "isActive",
      label: "Is Active?",
      placeholder: "",
      type: "checkbox",
      defaultValue: true,
      hidden: user?.menuroles !== "admin",
    },
  ];

  const ValidationSchema = yup.object().shape({
    name: yup.string().required(),
    type: yup.string().required(),
    store_gps_lat: yup.number().typeError('Please enter a valid latitude').nullable(),
    store_gps_lon: yup.number().typeError('Please enter a valid longitude').nullable(),
  });

  useEffect(() => {
    if (props.variant === "update") {
      SetLoadingData(true);
      if (props.variant === "update" && props.Provider)
        GetProvider(
          { id: props.Provider.id, page: 0, pageSize: 10 },
          (error, resp) => {
            SetLoadingData(false);
            if (error) {
              console.log(error.message);
              onCancel();
            } else if (resp) {
              SetInitalValues(
                resp.providers.map((item) => ({
                  ...item,
                  type: item.type.toLowerCase() as IProvider["type"],
                }))[0]
              );
            }
          }
        );
    }
  }, [props.variant === "update" && props.Provider]);

  const HandleSubmit: FormikConfig<Fields>["onSubmit"] = (values, actions) => {
    setIsSubmitting(true);
    if (props.variant === "add")
      AddProvider(
        {
          name: values.name,
          email: values.email,
          phone: values.phone,
          sms_sig_name: values.smsSigName,
          is_sub: props.is_sub_org,
          type: values.type,
          is_active: values.isActive,
          store_gps_lat: !values.store_gps_lat ? null : Number(values.store_gps_lat),
          store_gps_lon: !values.store_gps_lon ? null : Number(values.store_gps_lon),
        },
        (error, resp) => {
          setIsSubmitting(false);
          if (error) {
            actions.setFieldError(
              "GeneralErrorField",
              error?.response?.data.message
            );
          } else if (resp) {
            props.onAdded?.();
            SetInitalValues({
              email: "",
              isActive: true,
              name: "",
              phone: "",
              type: "enterprise",
              smsSigName: undefined,
              store_gps_lat: undefined,
              store_gps_lon: undefined,
            });
            // actions.resetForm();
          }
        }
      );
    else if (props.variant === "update" && props.Provider)
      UpdateProvider(
        {
          id: props.Provider?.id,
          name: values.name,
          email: values.email,
          type: values.type,
          phone: values.phone,
          sms_sig_name: values.smsSigName,
          store_gps_lat:
            !values.store_gps_lat ? null : Number(values.store_gps_lat),
          store_gps_lon:
            !values.store_gps_lon ? null : Number(values.store_gps_lon),
        },
        (error, resp) => {
          setIsSubmitting(false);
          if (error) {
            actions.setFieldError(
              "GeneralErrorField",
              error.response?.data.message
            );
          } else if (resp) {
            props.onUpdated && props.onUpdated();
            // actions.resetForm();
            SetInitalValues({
              email: "",
              isActive: true,
              name: "",
              phone: "",
              type: "enterprise",
              smsSigName: undefined,
              store_gps_lat: undefined,
              store_gps_lon: undefined,
            });
          }
        }
      );
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={ValidationSchema}
      onSubmit={HandleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={InitalValues}
    >
      {(FormikProps) => (
        <CustomDialogV2
          open={open ?? false}
          header={props.variant === "add" ? "Add Organization" : "Edit Organization"}
          footer={
            <Box display="flex" justifyContent="flex-end">
              <CancelSuccessButtonsGroup
                RootGridProps={{ width: 300 }}
                CancelButtonProps={{ label: "Cancel", onClick: onCancel }}
                SuccessButtonProps={{
                  disabled: IsSubmitting,
                  label: props.variant === "add" ? "Add" : "Save",
                  onClick: () => FormikProps.submitForm(),
                }}
              />
            </Box>
          }
        >
          {props.variant === "update" && LoadingData ? (
            <Typography>Loading ...</Typography>
          ) : (
            <form onSubmit={FormikProps.handleSubmit}>
              <Box maxWidth={350} mt={1}>
                <Grid container spacing={2}>
                  {(FormikProps.errors as any)["GeneralErrorField"] && (
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Alert severity="error">
                          {(FormikProps.errors as any)["GeneralErrorField"]}
                        </Alert>
                      </Box>
                    </Grid>
                  )}
                  {FieldsData.filter((item) => !item.hidden).map(
                    (item, index) => (
                      <Grid key={index} item xs={12} {...item.gridProps}>
                        {item.type === "checkbox" ? (
                          <FormControlLabel
                            style={{ marginBottom: 0 }}
                            checked={(FormikProps.values as any)[item.name]}
                            control={<Switch name="checkedA" />}
                            label={item.label}
                            onChange={(e, checked) =>
                              FormikProps.setFieldValue("isActive", checked)
                            }
                          />
                        ) : item.type === "select" ? (
                          <SelectMenu
                            Searchable={false}
                            options={TypeOptions.map((item) => ({
                              ...item,
                              title: item.title.toUpperCase(),
                            }))}
                            Placeholder={item.placeholder}
                            error={Boolean(
                              (FormikProps.errors as any)[item.type]
                            )}
                            helperText={(FormikProps.errors as any)[item.type]}
                            value={(FormikProps.values as any)[item.name]}
                            OnChange={(e) =>
                              FormikProps.setFieldValue("type", e.target.value)
                            }
                          />
                        ) : item.type === "number" ? (
                          <CustomTextField
                            type={"text"}
                            helperText={(FormikProps.errors as any)[item.name]}
                            value={(FormikProps.values as any)[item.name]}
                            onChange={FormikProps.handleChange}
                            name={item.name}
                            autoFocus={index === 0}
                            size="small"
                            fullWidth
                            //label={item.label}
                            placeholder={item.placeholder}
                            error={(FormikProps.errors as any)[item.name]}
                          />
                        ) : (
                          <CustomTextField
                            type={item.type}
                            helperText={(FormikProps.errors as any)[item.name]}
                            value={(FormikProps.values as any)[item.name]}
                            onChange={FormikProps.handleChange}
                            name={item.name}
                            autoFocus={index === 0}
                            fullWidth
                            size="small"
                            //label={item.label}
                            placeholder={item.placeholder}
                            error={(FormikProps.errors as any)[item.name]}
                          />
                        )}
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
              <button
                type="submit"
                ref={SubmitButtonRef}
                style={{ display: "none" }}
              />
            </form>
          )}
        </CustomDialogV2>
      )}
    </Formik>
  );
};

export default AddUpdateProviderComponent;
