import { Button, ButtonProps } from "@mui/material";
import { IoArrowDownSharp, IoCloudUpload } from "react-icons/io5";

export interface ImportButtonProps extends ButtonProps {}

const ImportButton: React.FunctionComponent<ImportButtonProps> = (props) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<IoCloudUpload />}
      {...props}
    />
  );
};

export default ImportButton;

ImportButton.defaultProps = {
  children: "Import",
};
