import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Params {
  ward_id_list: string[];
}

interface Resp {
  items: string[];
}
type CheckUnexistWardsType = (Params: Params, callback: (error: AxiosError | null, Response: null | Resp) => void) => any;
const CheckUnexistWards: CheckUnexistWardsType = (Params, callback) => {
  AxiosInstance.get("/wards/find-non-exists-wards", {
    params: {
      ward_id_list: Params.ward_id_list
    }
  })
    .then((resp) => {
      callback(null, {
        items: resp.data
      });
    })
    .catch((error) => callback(error, null));
};

export { CheckUnexistWards };
