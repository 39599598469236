import { TextFieldProps } from "@mui/material";
import { FarmersTableProps } from "components/data-tables/farmers-table/farmers-table";
import { broadcast } from "models/broadcast";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { selectionProps } from "components/custom-compnents/custom-farmers-table/custom-farmers-table.schema";
import { useAppSelector } from "store/hooks";
import React from "react";
import { BroadcastSubscription } from "models/broadcast-subscription";
import { CustomSelection, Fields } from "components/data-tables/farmers-table/schema";
import { DeleteBroadcastSubscription } from "api/broadcasts-subscription/delete-subscription";
import { uniqBy } from "lodash";
import { AddSubscription } from "api/broadcasts-subscription/add-subscription";
import { GetBroadcastsSubscription } from "api/broadcasts-subscription/get-broadcasts-subscription";
import { GetBroadcasts } from "api/broadcasts/get-broadcasts";
import { GetProvider } from "api/provider/get-provider";
import { IoMailSharp, IoPersonSharp } from "react-icons/io5";

interface BroadcastExtenedInfo extends broadcast {
  provider_name?: string;
}

export const useLogic = () => {
  const user = useAppSelector((root) => root.appReducer.user);

  const UserProvider = user?.provider_id;

  const UserMenurules = user?.menuroles;

  const UserId = user?.id;

  const { id } = useParams();

  const [BroadcastInfo, SetBroadcastInfo] = React.useState<undefined | BroadcastExtenedInfo>(undefined);

  const [SubscripedFarmers, SetSubscripedFarmers] = React.useState<BroadcastSubscription[]>([]);

  const [isShowEditDeleteDrawer, setIsShowEditDeleteDrawer] = React.useState(false);

  const [DayZero, SetDayZero] = React.useState("");

  const [OpenDayZeroDialog, SetOpenDayZeroDialog] = React.useState(false);

  const [FarmersToChangeSubscription, SetFarmersToChangeSubscription] = React.useState<Fields[]>([]);

  const [TabValue, SetTabValue] = React.useState(0);

  const [IsTableLoading, SetIsTableLoading] = React.useState(false);

  const [IsSubmittingDayZeroDialog] = React.useState(false);

  const [PossibleCreatorUserProviderId, SetPossibleCreatorUserProviderId] = React.useState("");

  const [ShowOnlySelectedFarmers, SetShowOnlySelectedFarmers] = React.useState(false);

  const TabsOptionArray = [
    { title: "Messages", value: 0, icon: IoMailSharp },
    { title: "Farmers", value: 1, icon: IoPersonSharp },
  ];
  const HandleShowOnlySelectedFarmersButtonClicked = () => SetShowOnlySelectedFarmers(!ShowOnlySelectedFarmers);
  React.useEffect(() => {
    id &&
      GetBroadcasts({ page: 0, pageSize: 10, id }, (error, resp) => {
        if (error) {
          console.log(error);
        } else if (resp) {
          if (UserMenurules === "admin") {
            GetProvider({ page: 0, pageSize: 10, id: resp.items[0].provider_id }, (error, ProviderResp) => {
              if (error) {
                console.log(error.message);
              } else if (ProviderResp) {
                let Broadcast: BroadcastExtenedInfo = resp.items[0];
                Broadcast["provider_name"] = ProviderResp.providers[0].name;
                SetBroadcastInfo(Broadcast);
              }
            });
          } else {
            SetBroadcastInfo(resp.items[0]);
          }
        }
      });

    GetBroadcastsSubscription({ broadcast_id: id }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        SetSubscripedFarmers(resp.items);
      }
    });
  }, [id, UserMenurules]);

  const HandleDayZeroDialogCancel = () => SetOpenDayZeroDialog(false);

  const HandleFarmersChange: CustomSelection["OnSelectionChange"] = (Fields, checkState) => {
    SetFarmersToChangeSubscription([Fields]);
    if (checkState) {
      if (BroadcastInfo?.relative_send_dates) {
        SetOpenDayZeroDialog(true);
      } else {
        Subscripe([Fields]);
      }
    } else {
      Unsubscripe([Fields]);
    }
  };

  const Subscripe = (data: Fields[], day_zero?: string) => {
    SetIsTableLoading(true);
    AddSubscription(
      {
        broadcast_id: id!,
        farmer_id: data?.map((item, index) => item.id),
        ...({ day_zero } ?? {}),
      },
      (error, resp) => {
        if (error) {
          console.log(error);
        } else if (resp) {
          console.log(resp, "subscriptions");
          SetSubscripedFarmers([...SubscripedFarmers, ...resp.items]);

          SetIsTableLoading(false);
        }
      }
    );
  };

  const Unsubscripe = (data: Fields[]) => {
    console.log(data);
    SetIsTableLoading(true);
    DeleteBroadcastSubscription(
      {
        id: uniqBy(
          SubscripedFarmers.filter((ItemToBeFilterd) => data.some((someFarmerItem) => ItemToBeFilterd.farmer_id === someFarmerItem.id)),
          (e) => e.id
        ).map((item) => item.id),
        broadcast_id: id,
      },
      (error, resp) => {
        if (error) {
          console.log(error);
        } else if (resp) {
          SetSubscripedFarmers(SubscripedFarmers.filter((Filteritem) => !data.some((someFarmeritem) => someFarmeritem.id === Filteritem.farmer_id)));
          SetIsTableLoading(false);
        }
      }
    );
  };

  const HandleCustomSelectChange: selectionProps["onSelectAllChange"] = (data, isChecked) => {
    data && SetFarmersToChangeSubscription(data);

    if (isChecked && data) {
      if (BroadcastInfo?.relative_send_dates) {
        SetOpenDayZeroDialog(true);
      } else {
        data && Subscripe(data);
      }
    } else if (!isChecked && data) {
      Unsubscripe(data);
    }
  };

  const DayZeroFieldProps: TextFieldProps = {
    value: DayZero,
    onChange: (e) => SetDayZero(e.currentTarget.value),
    placeholder: "Insert Day Zero",
    type: "datetime-local",
    fullWidth: true,
    variant: "outlined",
    size: "small",
    autoFocus: true,
  };

  const FarmersTableProps: (b: broadcast) => FarmersTableProps = (BroadcastInfo) => ({
    isShowOnlySelectedFarmersFilter: true,
    isShowColumnButton: true,
    isShowFilterButton: true,
    isShowImportButton: false,
    isLoading: IsTableLoading,
    Proiders_Ids: [BroadcastInfo?.provider_id!],
    ForceKeepEmptyArrayQuery: true,
    OnFilterSelectedFarmersButtonClicked: HandleShowOnlySelectedFarmersButtonClicked,
    ClickedFilterSelectedFarmersButtonView: ShowOnlySelectedFarmers,
    ActionsToExclude: ["AddFarmer"],
    ...(UserMenurules == "admin" || (UserMenurules == "user" && UserId == BroadcastInfo.created_by) || ((UserMenurules === "manager" || UserMenurules === "org_admin") && (BroadcastInfo?.provider_id == UserProvider || UserProvider == PossibleCreatorUserProviderId))
      ? {
        SelectedFarmersIds: SubscripedFarmers.map((item) => item.farmer_id),
        customSelection: {
          onSelectAllChange: HandleCustomSelectChange,
          onRowSelectChange: HandleFarmersChange,
        },
      }
      : {}),
  });

  const handleSubscripeConfirm = () => {
    if (DayZero != "") {
      Subscripe(FarmersToChangeSubscription, moment(DayZero).utc().format());
      SetOpenDayZeroDialog(false);
    }
  };

  const navigate = useNavigate();
  return {
    isShowEditDeleteDrawer,
    setIsShowEditDeleteDrawer,
    navigate,
    handleSubscripeConfirm,
    HandleDayZeroDialogCancel,
    OpenDayZeroDialog,
    IsSubmittingDayZeroDialog,
    DayZeroFieldProps,
    TabsOptionArray,
    TabValue,
    SetTabValue,
    UserMenurules,
    BroadcastInfo,
    UserId,
    FarmersTableProps,
    UserProvider,
    PossibleCreatorUserProviderId,
    id,
  };
};
