import { Autocomplete, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { IDevice } from "models/device";
import CancelSuccessButtonsGroup, {
  CancelSuccessButtonsGroupProps,
} from "../cancel-success-buttons-group/cancel-success-buttons-group";
import CustomDialogV2 from "../custom-dialog-v2/cusotm-dialog-v2";

interface CustomAttachDevicesProps {
  open: boolean;
  name?: string;
  actionButtonsProps?: CancelSuccessButtonsGroupProps;
  options: IDevice[];
  value?: IDevice[];
  onChange?: (values: IDevice[]) => any;
  onInputChange?: (value: string) => any;
}

const CustomAttachDevices: React.FunctionComponent<CustomAttachDevicesProps> = (
  props
) => {
  const {
    open,
    name,
    actionButtonsProps,
    options,
    value,
    onChange,
    onInputChange,
  } = props;

  return (
    <CustomDialogV2
      header={`Attach Device To ( ${name} )`}
      open={open}
      footer={
        <Box display="flex" justifyContent="flex-end">
          <CancelSuccessButtonsGroup
            CancelButtonProps={{
              label: "Cancel",
              ...actionButtonsProps?.CancelButtonProps,
            }}
            SuccessButtonProps={{
              label: "Attach Device",
              ...actionButtonsProps?.SuccessButtonProps,
            }}
            RootGridProps={{ width: 300 }}
          />
        </Box>
      }
    >
      <Box width={500} mt={1}>
        <Autocomplete
          multiple
          fullWidth
          value={value}
          onInputChange={(e, value) => onInputChange?.(value)}
          getOptionLabel={(data) => `${data.imei} (${data.phone_number})`}
          onChange={(e, values) => onChange?.(values)}
          options={options}
          renderInput={(props) => (
            <TextField
              {...props}
              placeholder="Search by devices IMEI (type imei)"
            />
          )}
        />
      </Box>
    </CustomDialogV2>
  );
};

export default CustomAttachDevices;
