import { Box, Chip, InputBase, InputBaseProps, Paper, Typography } from "@mui/material";
import { useMemo } from "react";

interface CustomInputWithCountProps extends InputBaseProps {
  signture?: string;
  limit?: number;
  currentLength?: number;
  withLimit?: boolean;
}

const CustomInputWithCount: React.FunctionComponent<CustomInputWithCountProps> = (props) => {

  const newProps = useMemo(() => props, [props]);
  const { signture, currentLength, limit, withLimit } = newProps;

  return (
    <Box component={Paper} overflow="hidden">
      <InputBase {...newProps} sx={{ px: 2, py: 1.5 }} fullWidth />
      <Box p={2} pt={0} pb={1} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2" lineHeight="1.5">
          {signture}
        </Typography>

        {withLimit && (currentLength || limit) && <Chip label={`${currentLength} / ${limit}`} />}
      </Box>
    </Box>
  );
};

export default CustomInputWithCount;
