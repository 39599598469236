import { getSenderByProviderId } from "api/senders/get-sender-by-provider-id";
import { GetSenderIds } from "api/senders/get-senders-v2";
import { findAll } from "api/whatsapp-templates/findAll";
import { Fields } from "components/custom-compnents/custom-whatsapp-messages-templates-table/custom-whatsapp-messages-template-table.schema";
import { MessageType } from "components/pages/send-message-screen/send-message-screen.logic";
import { IMessageCategory } from "models/message-category";
import { IMessageTemplate } from "models/message-template";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";

export const useLogic = () => {
  const [loadingwhatsappSenders, setloadingwhatsappSenders] = useState(true);

  const user = useAppSelector((root) => root.appReducer.user);

  const [categoryFilter, setcategoryFilter] = useState<IMessageCategory[]>([]);

  const [showFilterArea, setshowFilterArea] = useState(false);

  const [rowToShowDetails, setrowToShowDetails] = useState<undefined | Fields>(undefined);

  const [whatsappSenders, setwhatsappSenders] = useState<any[]>([]);

  const [rowToRemove, setrowToRemove] = useState<undefined | Fields>(undefined);

  const [rowToUpdate, setrowToUpdate] = useState<undefined | Fields>(undefined);

  const [openAddDialog, setopenAddDialog] = useState(false);

  const [dataUpdateIndex, setDataUpdateIndex] = useState(0);

  const [currentPage, setCurrentPage] = useState(0);

  const [perPage, setPerPage] = useState(10);

  const [data, setData] = useState<Fields[]>([]);

  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [columnsState, setColumnsState] = useState([
    { checked: true, field: "template name" },
    { checked: true, field: "categories" },
    { checked: true, field: "status" },
    { checked: true, field: "access level" },
  ]);

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const handleCancelAdd = () => setopenAddDialog(false);

  const handleOpenAddDialog = () => setopenAddDialog(true);

  const onAdded = () => {
    setopenAddDialog(false);
    refreshData();
  };

  const onEditButtonClick = (fields: Fields) => {
    setrowToUpdate(fields);
  };

  const handleUpdated = () => {
    setrowToUpdate(undefined);
    refreshData();
  };

  const handleCancelUpdate = () => setrowToUpdate(undefined);

  const handleRemoveButtonClick = (fields: Fields) => setrowToRemove(fields);

  const handleCancelDelete = () => setrowToRemove(undefined);

  const handleRemoved = () => {
    setrowToRemove(undefined);
    refreshData();
  };

  const handleCancelShowDetails = () => setrowToShowDetails(undefined);

  const handleShowDetails = (fields: Fields) => setrowToShowDetails(fields);

  const handleApplyFilterClick = () => {
    refreshData();
  };

  const resetFilters = () => {
    setcategoryFilter([]);
  };

  const handleCancelFilterButtonClick = () => {
    resetFilters();
    setshowFilterArea(false);
    refreshData();
  };

  // get user's provider whatsapp senders
  useEffect(() => {
    GetSenderIds({ page: 0, pageSize: 10, sender_type: "Infobip_sender" }, (error, resp) => {
      setloadingwhatsappSenders(false);
      if (resp) {
        setwhatsappSenders(resp?.allSenders.nodes);
      }
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    findAll(
      {
        page: currentPage,
        pageSize: perPage,
        with_categories: true,
        // created_by: parseInt(user?.id!),
        // provider_id: user?.provider_id!,
        templateType: MessageType.Whatsapp,
        without_approval: true,
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else {
          setIsLoading(false);
          resp &&
            setData(
              resp?.items.map((item: Fields) => ({
                ...item,
                // showDeleteButton: user?.menuroles === "admin" || user?.id === item.created_by.toString()
              }))
            );

          setTotalCount(resp?.total_count!);
        }
      }
    );
  }, [dataUpdateIndex, currentPage, perPage]);

  return {
    loadingwhatsappSenders,
    whatsappSenders,
    data,
    isLoading,
    columnsState,
    handleNextPageClick,
    handleColumnStateChange,
    handlePreviousPageClick,
    handlePerPageChange,
    perPage,
    totalCount,
    currentPage,
    openAddDialog,
    handleCancelAdd,
    onAdded,
    handleOpenAddDialog,
    rowToUpdate,
    handleCancelUpdate,
    handleUpdated,
    onEditButtonClick,
    rowToRemove,
    handleRemoveButtonClick,
    handleCancelDelete,
    handleRemoved,
    rowToShowDetails,
    handleCancelShowDetails,
    handleShowDetails,
    showFilterArea,
    setshowFilterArea,
    setcategoryFilter,
    categoryFilter,
    handleApplyFilterClick,
    handleCancelFilterButtonClick,
  };
};
