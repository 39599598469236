import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { broadcast } from "../../models/broadcast";

interface Resp {
  items: broadcast[];
  total: number;
}
interface Params {
  page: number;
  pageSize: number;
  provider_id?: string;
  id?: string;
  sort_by?: string;
  search?: string;
}
type GetBroadcastsType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const GetBroadcasts: GetBroadcastsType = (Params, callback) => {
  AxiosInstance.get("/sms-broadcasts", { params: { ...Params } })
    .then((resp) => {
      callback(null, {
        items: resp.data.data.all_sms_broadcasts.nodes.map((item: broadcast) => ({
          ...item,
          sender_by_sender_id: item.sender_by_sender_id
        })),
        total: resp.data.data.all_sms_broadcasts.total_count
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { GetBroadcasts };
