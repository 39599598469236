import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { IProvider } from "models/provider";

interface Params {
  id: string;
  name?: string;
  is_active?: boolean;
  email?: string;
  phone?: string;
  sms_sig_name?: string;
  is_sub?: boolean;
  type?: string;
  store_gps_lat?: number | null;
  store_gps_lon?: number | null;
}
type UpdateProviderType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | any) => any
) => any;
const UpdateProvider: UpdateProviderType = (Params, callback) => {
  AxiosInstance.patch("/organizations/" + Params.id, { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { UpdateProvider };
