import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FunctionComponent } from "react";
import { IoCloseSharp } from "react-icons/io5";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CreateButton from "components/custom-compnents/create-button/create-button";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import {
  IAdd,
  IUpdate,
  useLogic,
} from "./add-update-farmer-label-component.logic";

interface AddUpdateFarmerLabelProps {
  open?: boolean;
  onCancel?: () => any;
}

const AddUpdateFarmerLabel: FunctionComponent<
  AddUpdateFarmerLabelProps & (IAdd | IUpdate)
> = (props) => {
  const {
    labelsData,
    handleAddLabelInput,
    handleDeleteLabelInput,
    keyOptions,
    handleKeySearchTerm,
    loadingKeys,
    handleInsertLabelText,
    keyValue,
    handleKeySelect,
    loading,
    handleAdd,
    handleUpdate,
    isSubmitting,
  } = useLogic(props);

  const { open, onCancel } = props;
  const inputData = [
    {
      placeholder: "Search for key ...",
      type: "autocomplete",
      disabled: props.variant === "update",
    },
    {
      placeholder: "Insert label value",
      type: "text",
      data: labelsData,
    },
  ];

  return (
    <CustomDialogV2
      PaperProps={{
        sx: {
          width: 600,
          maxWidth: "100%",
        },
      }}
      header={
        props.variant === "update"
          ? "Update Farmer Labels"
          : "Add Farmer Labels"
      }
      footer={
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <CreateButton
              fullWidth
              onClick={handleAddLabelInput}
              disabled={isSubmitting}
            >
              Add Label
            </CreateButton>
          </Grid>
          <Grid item md={8} xs={12}>
            <CancelSuccessButtonsGroup
              CancelButtonProps={{
                label: "Cancel",
                onClick: onCancel,
                disabled: isSubmitting,
              }}
              SuccessButtonProps={{
                disabled: isSubmitting,
                label:
                  props.variant === "update" ? "Update" : "Attach to Farmer",
                onClick: props.variant === "update" ? handleUpdate : handleAdd,
              }}
            />
          </Grid>
        </Grid>
      }
      open={open ?? false}
    >
      <Grid container spacing={2}>
        {loading && (
          <Grid item xs={12}>
            <Typography>Loading ...</Typography>
          </Grid>
        )}
        {!loading &&
          inputData.map((item) => (
            <>
              {item.type === "autocomplete" && (
                <Grid item xs={12}>
                  <Autocomplete
                    filterSelectedOptions
                    disabled={item.disabled}
                    onChange={(e, value) => value && handleKeySelect(value)}
                    loading={loadingKeys}
                    value={keyValue}
                    filterOptions={(options, state) => options}
                    loadingText="Loading ..."
                    onInputChange={(e, value) => handleKeySearchTerm(value)}
                    getOptionLabel={(item) => item.key}
                    options={loadingKeys ? [] : keyOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={item.placeholder}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              )}
              {item.type === "text" &&
                item.data?.map((InputItem, index) => (
                  <Grid item xs={12}>
                    <CustomTextField
                      onChange={(e) =>
                        handleInsertLabelText(index, e.currentTarget.value)
                      }
                      value={InputItem.value}
                      fullWidth
                      placeholder={item.placeholder}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => handleDeleteLabelInput(index)}
                          >
                            <IconButton>
                              <IoCloseSharp />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                ))}
            </>
          ))}
      </Grid>
    </CustomDialogV2>
  );
};

export default AddUpdateFarmerLabel;
