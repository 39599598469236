import { Box, Tab, TabProps, Tabs, TabsProps } from "@mui/material";
import { useStyles } from "./custom-tabs-component.style";

interface CustomTabsComponentProps {
  TabsProps?: TabsProps;
  TabData?: TabProps[];
}

const CustomTabsComponent: React.FunctionComponent<CustomTabsComponentProps> = (props) => {
  const classes = useStyles();
  const { TabData, TabsProps } = props;
  return (
    <Box className={classes.root}>
      <Tabs {...TabsProps}>
        {TabData?.map((item, index) => (
          <Tab iconPosition="start" {...item} style={{ textTransform: "none" }} />
        ))}
      </Tabs>
    </Box>
  );
};

export default CustomTabsComponent;
