import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";
import CountyInput from "components/input-components/county-input/county-input";
import { useLogic } from "./weather-analytics-page.logic";

interface WeatherAnalyticsPageProps {}

const WeatherAnalyticsPage: React.FunctionComponent<WeatherAnalyticsPageProps> = () => {
  const { location, handleCountyInputChange } = useLogic();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomTitle label="Seasonal Anomaly Forecast" />
      </Grid>
      <Grid item xs={12}>
        <Box bgcolor="white">
          <CountyInput value={location} onChange={handleCountyInputChange} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {location?.centroid_lat && location.centroid_lon ? (
          <Box component={Paper} display="inline-block" p={1}>
            <img src={`${(window as any).env.REACT_APP_API_BASEURL}/meteoblue/visimage/meteogram_seasonal?lat=${location?.centroid_lat}&lon=${location?.centroid_lon}`} />
          </Box>
        ) : (
          <Alert severity="info">Please Select Location</Alert>
        )}
      </Grid>
    </Grid>
  );
};

export default WeatherAnalyticsPage;
