import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    height: "62px",
    borderLeft: "solid 5px transparent",
  },
  primaryTextRoot: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#5d6d6e",
  },
  primaryTextSelected: {
    color: "#44b16e",
  },
  iconWrapperRoot: {
    minWidth: 35,
  },
  iconWrapperSelected: {
    color: "#44b16e",
  },
  actionWrapperRoot: {
    display: "flex",
    marginRight: 5,
    color: "#5d6d6e",
  },
  actionWrapperSelected: {
    color: "#44b16e",
  },
  rootSelected: {
    backgroundColor: "#ececec",
    borderLeftColor: "#44b16e",
  },
});

export default useStyles;
