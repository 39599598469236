import CustomFooter from "components/custom-compnents/footer/footer";
interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = () => {
  return (
    <>
      <CustomFooter />
    </>
  );
};

export default Footer;
