import { AxiosError } from "axios";
import { ISMS } from "models/sms";
import humanize from "humanize-graphql-response";
import { AxiosInstance } from "configs/axios";

interface Params {
  page: number;
  pageSize: number;
  id?: string;
  text?: string;
  provider_id?: string;
  provider_id_list?: string[];
  phone?: string;
  broadcast_message_id?: string;
  statusAccepted?: boolean;
  statusFailed?: boolean;
  statusQueued?: boolean;
  statusSent?: boolean;
  statusArchived?: string[];
  sort_by?: string[];
}
interface Resp {
  totalCount: number;
  items: ISMS[];
}

type GetSMSType = (Params: Params, callback: (error: null | AxiosError, Resp: null | Resp) => any) => any;
const GetSMS: GetSMSType = (Params, callback) => {
  AxiosInstance.get("/sms-messages", { params: Params })
    .then((resp) =>
      callback(null, {
        totalCount: resp.data.all_sms_messages.total_count,
        items: humanize(resp.data.all_sms_messages).nodes.map((item: any) => ({
          archived: item.status_archived,
          content: item.content,
          id: item.id,
          phone: item.phone,
          short_code: item.short_code,
          provider_id: item.provider_id,
          send_at: item.send_at,
          failed: item.status_failed,
          sent: item.status_sent,
          queued: item.status_queued,
          accepted: item.status_accepted,
          smsLogs: item.sms_logs_by_sms_id.nodes
        })),
      })
    )
    .catch((error) => callback(error, null));
};

export { GetSMS };
