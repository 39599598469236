import { FunctionComponent } from "react";
import CustomEwalletTransactionTable, {
  CustomEwalletTransactionTableProps,
} from "components/custom-compnents/ewallet-transactions-table/ewallet-transactions-table";
import { IParams, useLogic } from "./ewallet-transactions-table.logic";

interface EwalletTransactionTableProps extends IParams {}

const EwalletTransactionTable: FunctionComponent<
  EwalletTransactionTableProps
> = (props) => {
  const { ...rest } = props;

  const {
    handleCancelFilters,
    setIsOpenFilterArea,
    setdateToFilter,
    setdateFromFilter,
    dateFromFilter,
    dateToFilter,
    preparedData,
    columnsState,
    handleColumnStateChange,
    handleNextPageClick,
    handlePerPageChange,
    handlePreviousPageClick,
    isLoading,
    isOpenFilterArea,
    refreshData,
    currentPage,
    perPage,
    handleOrderChange,
    totalCount,
    isFiltersEmpty,
    setusersFilter,
    usersFilter,
    user,
  } = useLogic(rest);

  const tableProps: CustomEwalletTransactionTableProps = {
    onOrderChange: handleOrderChange,
    isLoading: isLoading,
    data: preparedData,

    countLabelProps: {
      count: totalCount,
    },
    filterFormProps: {
      ewalletUsersFilterProps: {
        fullWidth: true,
        disabled:
          !!props.from_user || !!props.to_user || user?.menuroles !== "admin",
        value: {
          operator: usersFilter?.operator,
          value: usersFilter?.value?.id,
        },
        onChange: setusersFilter,
        "data-cy": "user-filter-input-transactions-table",
      },
      showDateRangeFilter: true,
      showEwalletUsersFilter: true,
      dateRangeFilterProps: {
        fromProps: {
          value: dateFromFilter,
          onChange: (e) => setdateFromFilter(e.currentTarget.value),
          "data-cy": "date-from-filter-input-transactions-table",
        },
        toProps: {
          value: dateToFilter,
          onChange: (e) => setdateToFilter(e.currentTarget.value),
          "data-cy": "date-to-filter-input-transactions-table",
        },
      },
    },
    filterContainerProps: {
      CancelSuccessButtonGroupProps: {
        CancelButtonProps: { onClick: handleCancelFilters },
        SuccessButtonProps: {
          disabled: isFiltersEmpty,
          onClick: () => refreshData(),
          "data-cy": "apply-filters-button-transactions-table",
        },
      },
    },
    actionBarProps: {
      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
      filterButtonProps: {
        onClick: () => setIsOpenFilterArea(true),
        "data-cy": "filters-button-transactions-table",
      },
      isShowFilterButton: true,
    },
    showFilterArea: isOpenFilterArea,
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };

  return <CustomEwalletTransactionTable {...tableProps} />;
};

export default EwalletTransactionTable;
