import { Autocomplete, TextField } from "@mui/material";
import { FunctionComponent } from "react";
import { MessageTemplateInputProps, useLogic } from "./message-template-input.logic";

const MessageTemplateInput: FunctionComponent<MessageTemplateInputProps> = (props) => {
  const { limitTags, error, helperText, fullwidth, onChange, placeholder, value } = props;

  const { options, loading, setsearchTerm } = useLogic(props);

  return (
    <Autocomplete
      loading={loading}
      loadingText="Loading ..."
      filterSelectedOptions
      onInputChange={(e, value) => setsearchTerm(value)}
      getOptionLabel={(value) => (!Array.isArray(value) ? value.display_name ?? value.name : "")}
      value={value}
      multiple={Array.isArray(value)}
      limitTags={limitTags}
      onChange={(e, value, reason) => onChange?.(value as any, reason)}
      options={options}
      renderInput={(props) => <TextField {...props} fullWidth={fullwidth} placeholder={placeholder} error={error} helperText={helperText} />}
    />
  );
};

export default MessageTemplateInput;

MessageTemplateInput.defaultProps = {
  placeholder: "Search Template",
};
