import { Typography } from "@mui/material";
import { useTheme } from "@mui/material";

export interface CountLabelProps {
  label?: string;
  count?: number;
}

const CountLabel: React.FunctionComponent<CountLabelProps> = (props) => {
  const { count, label } = props;
  const theme = useTheme();
  return (
    <>
      <Typography variant="h6" display="inline-block">
        {label}
      </Typography>{" "}
      <Typography
        variant="h6"
        display="inline-block"
        color={theme.palette.success.main}
      >
        ({count})
      </Typography>
    </>
  );
};

export default CountLabel;

CountLabel.defaultProps = {
  label: "ALL FARMERS",
  count: 0,
};
