import { Box, Grid, MenuItem, TextField } from "@mui/material";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import ProvidersInput from "components/input-components/providers-input/providers-input";
import { MessageTemplateAccessLevel } from "enums/messages-template-access-level";
import { WhatsappMessageTemplateCategory } from "enums/whatsapp-message-template-category";
import { FunctionComponent } from "react";
import { IParams, useLogic } from "./add-update-whatsapp-template.logic";
import VariablePlaceholderInput from "./variable-placeholder-input";

interface AddUpdateWhatsappTemplateProps {
  onCancel?: () => any;
}

const AddUpdateWhatsappTemplate: FunctionComponent<AddUpdateWhatsappTemplateProps & IParams> = (props) => {
  const { languageOptions, form, templateBodyVariables, removeVariableFromBody, insertVariableToTemplate, setvariableInput, variableInput, user } = useLogic(props);
  const fields = [
    { type: "text", label: "Template Name", name: "name", error: Boolean(form.errors["name"]), helperText: form.errors["name"], value: form.values["name"], onChange: form.handleChange },
    {
      type: "provider",
      hidden: user?.menuroles !== "admin",
      label: "Select Organization",
      name: "provider_id",
      error: Boolean(form.errors["provider_id"]),
      helperText: form.errors["provider_id"],
      value: form.values["provider_id"],
      onChange: (value: any) => form.setFieldValue("provider_id", value.id),
    },
    { type: "select", label: "Access Level", name: "access_level", error: Boolean(form.errors["access_level"]), helperText: undefined, value: form.values["access_level"], onChange: form.handleChange, options: Object.values(MessageTemplateAccessLevel).map((item) => ({ value: item, label: item })) },
    { type: "select", label: "Languages", name: "language", options: languageOptions.map((item) => ({ label: item, value: item })), error: Boolean(form.errors["language"]), helperText: form.errors["language"], value: form.values["language"], onChange: form.handleChange },
    // {
    //   type: "select",
    //   label: "Categories",
    //   name: "category",
    //   error: Boolean(form.errors["category"]),
    //   helperText: form.errors["category"],
    //   value: form.values["category"],
    //   onChange: form.handleChange,
    //   options: Object.values(WhatsappMessageTemplateCategory).map((item) => ({ value: item, label: item })),
    // },
    { type: "text", id: "body", label: "Body", name: "body", error: Boolean(form.errors["body"]), helperText: form.errors["body"], value: form.values["body"], onChange: form.handleChange, rows: 7, multiline: true },
  ];
  return (
    <CustomDialogV2 header={"Add Whatsapp Template"} open={props.open ?? false} footer={<CancelSuccessButtonsGroup CancelButtonProps={{ label: "Cancel", onClick: props.onCancel }} SuccessButtonProps={{ label: "Create", onClick: () => form.submitForm(), disabled: form.isSubmitting }} />}>
      <Box maxWidth={400}>
        <Grid container spacing={2}>
          {fields
            .filter((item) => !item.hidden)
            .map((item) => (
              <Grid item xs={12} key={item.name}>
                {item.type === "text" && <TextField inputProps={{ id: item.id }} rows={item.rows} multiline={item.multiline} fullWidth placeholder={item.label} error={item.error} helperText={item.helperText} value={item.value} onChange={item.onChange} name={item.name} />}
                {item.type === "select" && (
                  <TextField fullWidth select placeholder={item.label} error={item.error} helperText={item.helperText} value={item.value} onChange={item.onChange} name={item.name}>
                    <MenuItem value={"none"} disabled={true}>
                      {item.label}
                    </MenuItem>
                    {item.options?.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </TextField>
                )}
                {item.type === "provider" && <ProvidersInput placeholder={item.label} error={item.error} helperText={item.helperText} value={item.value} onChange={item.onChange} />}
              </Grid>
            ))}
          {/* <Grid item xs={12}>
            <VariablePlaceholderInput type="text" fullWidth viewType="add" placeholder={"Insert Template Variable"} onChange={(e) => setvariableInput(e.currentTarget.value)} value={variableInput} onAdd={insertVariableToTemplate} variant={"outlined"} />
          </Grid>
          {templateBodyVariables.map((item) => (
            <Grid item xs={12}>
              <VariablePlaceholderInput type="text" fullWidth viewType="remove" disabled={true} value={item} onRemove={() => removeVariableFromBody(item)} variant={"outlined"} />
            </Grid>
          ))} */}
        </Grid>
      </Box>
    </CustomDialogV2>
  );
};

export default AddUpdateWhatsappTemplate;
