import {
  Box,
  ButtonProps,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Toolbar,
  Typography,
} from "@mui/material";
import SelectMenu from "components/input-components/select-menu/SelectMenu";
import { SurveyQuestion } from "models/survey-questions";
import { SurveyQuestionAnswer } from "models/survey-questions-answer";
import {
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoCreateOutline,
  IoMenuSharp,
  IoPlayForward,
  IoTrashOutline,
  IoCheckmark
} from "react-icons/io5";
import CancelSuccessButtonsGroup from "../cancel-success-buttons-group/cancel-success-buttons-group";
import CreateButton from "../create-button/create-button";
import CustomTextField from "../custom-text-field/custom-text-field";
import LabelText from "../label-text/label-text";
import RoundButton, { RoundButtonProps } from "../round-button/round-button";
import { IParams, useLogic } from "./custom-survey-question.logic";
import { useStyles } from "./custom-survey-question.style";
import React from "react";

export interface ISingleChoiceAnswerItem extends SurveyQuestionAnswer { }

interface ISingleChoiceQuestionProps {
  type: "single choice";
  answerScreenList: ISingleChoiceAnswerItem[];
  onAnswerChange?: (data: ISingleChoiceAnswerItem) => any;
}

interface ITextQuestionProps {
  type: "free text";
  onDefaultNextQuestionChange?: (data: string) => any;
  default_next_question_id: string;
}

interface CustomSurveyQuestionProps extends IParams {
  isInEditMode?: boolean;
  isShowDetailArea?: boolean;
  updateButtonProps?: ButtonProps;
  setOrderButtonProps: ButtonProps;
  cancelButtonProps?: ButtonProps;
  addAnswerButtonProps?: ButtonProps;
  openButtonProps?: RoundButtonProps;
  deleteButtonProps?: RoundButtonProps;
  editButtonProps?: RoundButtonProps;
  questionList?: SurveyQuestion[];
  questionTitle?: string;
  isLoadingNextScreenList?: boolean;
  sort_order?: number;
}

export type combinedType = (ISingleChoiceQuestionProps | ITextQuestionProps) &
  CustomSurveyQuestionProps;

const CustomSurveyQuestion: React.FunctionComponent<combinedType> = (props) => {
  const {
    isInEditMode,
    isShowDetailArea,
    addAnswerButtonProps,
    cancelButtonProps,
    updateButtonProps,
    deleteButtonProps,
    editButtonProps,
    setOrderButtonProps,
    openButtonProps,
    questionList,
    questionTitle,
    isLoadingNextScreenList,
    sort_order,
    ...otherProps
  } = props;
  const { questionData } = useLogic(otherProps);

  const classes = useStyles();
  return (
    <Box component={Paper} py={1} style={{ backgroundColor: sort_order && sort_order > 0 ? '#44b16e36' : '' }}>
      <Toolbar>
        <Typography variant="h6">{questionTitle}</Typography>
        <Box ml="auto" display="flex" gap={1.5} alignItems="center">
          <RoundButton {...openButtonProps}>
            {isShowDetailArea ? (
              <IoChevronDownOutline size={20} />
            ) : (
              <IoChevronUpOutline size={20} />
            )}
          </RoundButton>
          <RoundButton {...editButtonProps}>
            <IoCreateOutline size={20} />
          </RoundButton>
          <RoundButton {...deleteButtonProps}>
            <IoTrashOutline size={20} />
          </RoundButton>
          <RoundButton {...setOrderButtonProps}>
            <IoCheckmark size={20} />
          </RoundButton>
          {/* <Box ml={1}>
            <IoMenuSharp size={25} style={{ cursor: "grab" }} />
          </Box> */}
        </Box>
      </Toolbar>
      {
        isShowDetailArea && (
          <>
            <Divider />
            <Box mt={3}>
              <Toolbar>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      {questionData.map((item) => (
                        <Grid item xs={12}>
                          {(!isInEditMode || item.isEditable) && (
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <LabelText>{item.label}</LabelText>
                              </Grid>
                              <Grid item xs={12}>
                                {isInEditMode &&
                                  (item.type == "text" ? (
                                    <CustomTextField
                                      fullWidth
                                      value={item.value}
                                      onChange={item.onChange}
                                    />
                                  ) : (
                                    <SelectMenu
                                      options={item.options ?? []}
                                      value={item.value as any}
                                      OnChange={item.onChange}
                                      Searchable={false}
                                    />
                                  ))}
                                {!isInEditMode && (
                                  <Typography variant="h6">
                                    {item.value}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid
                          container
                          px={1}
                          spacing={isInEditMode ? 2 : undefined}
                        >
                          {props.type === "single choice" && (
                            <>
                              <Grid item xs={5}>
                                <LabelText>Answer Options</LabelText>
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={5}>
                                <LabelText>Next Screen</LabelText>
                              </Grid>
                            </>
                          )}
                          {props.type === "free text" && (
                            <Grid item xs={12}>
                              <LabelText>Default Next Question Screen</LabelText>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {isLoadingNextScreenList ? (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            {[
                              new Array(props.type === "free text" ? 1 : 3)
                                .fill(0)
                                .map((item) => (
                                  <React.Fragment key={item}>
                                    <Grid item xs={5}>
                                      <Skeleton height={40} />
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={5}>
                                      <Skeleton height={40} />
                                    </Grid>
                                  </React.Fragment>
                                )),
                            ]}
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          {props.type === "single choice" &&
                            props.answerScreenList?.map((item) => (
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  className={`${!isInEditMode && classes.answerRoot
                                    }`}
                                  p={1}
                                  spacing={isInEditMode ? 2 : undefined}
                                >
                                  <Grid item xs={5}>
                                    {isInEditMode && (
                                      <CustomTextField
                                        fullWidth
                                        value={item.answer}
                                        onChange={(e) =>
                                          props.onAnswerChange?.({
                                            ...item,
                                            answer: e.currentTarget
                                              .value as string,
                                          })
                                        }
                                      />
                                    )}
                                    {!isInEditMode && (
                                      <Typography variant="h6">
                                        {item.answer}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <IoPlayForward size={20} />
                                  </Grid>
                                  <Grid item xs={5}>
                                    {!isInEditMode && (
                                      <Typography variant="h6">
                                        {
                                          questionList?.find(
                                            (questionItem) =>
                                              questionItem.id === item.next_qid
                                          )?.question
                                        }
                                      </Typography>
                                    )}
                                    {isInEditMode && (
                                      <SelectMenu
                                        options={(questionList ?? []).map(
                                          (item) => ({
                                            title: item.question,
                                            value: item.id,
                                          })
                                        )}
                                        Searchable={false}
                                        value={item.next_qid}
                                        OnChange={(e) =>
                                          props.onAnswerChange?.({
                                            ...item,
                                            next_qid: e.target.value as string,
                                          })
                                        }
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}

                          {props.type === "free text" && (
                            <Grid item xs={12}>
                              <Grid
                                container
                                className={`${!isInEditMode && classes.answerRoot
                                  }`}
                                p={1}
                                spacing={isInEditMode ? 2 : undefined}
                              >
                                <Grid item xs={12}>
                                  {isInEditMode && (
                                    <SelectMenu
                                      options={(questionList ?? []).map(
                                        (item) => ({
                                          title: item.question,
                                          value: item.id,
                                        })
                                      )}
                                      Searchable={false}
                                      value={props.default_next_question_id}
                                      OnChange={(e) =>
                                        props.onDefaultNextQuestionChange?.(
                                          e.target.value as string
                                        )
                                      }
                                    />
                                  )}
                                  {!isInEditMode && (
                                    <Typography variant="h6">
                                      {
                                        props.questionList?.find(
                                          (item) =>
                                            item.id ===
                                            props.default_next_question_id
                                        )?.question
                                      }
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </>
                      )}

                      {isInEditMode && (
                        <Grid item xs={12}>
                          <Grid
                            container
                            my={1}
                            p={1}
                            spacing={isInEditMode ? 2 : undefined}
                          >
                            <Grid item xs={8}>
                              {props.type === "single choice" && (
                                <CreateButton {...addAnswerButtonProps}>
                                  Add New Answer
                                </CreateButton>
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              <CancelSuccessButtonsGroup
                                CancelButtonProps={{
                                  label: "Cancel",
                                  ...cancelButtonProps,
                                }}
                                SuccessButtonProps={{
                                  label: "Update",
                                  ...updateButtonProps,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </Box>
          </>
        )
      }
    </Box >
  );
};

export default CustomSurveyQuestion;
