import * as React from "react";
import { IParams, useLogic } from "./message-detail-table.logic";
import CustomMessagesDetailTable from "components/custom-compnents/custom-messages-detail-page/custom-messages-detail-table";
import CustomConfirmationDialog from "components/custom-compnents/custom-confirmation-dialog/custom-confirmation-dialog";

interface MessagesDetailTableProps extends IParams {}

const MessagesDetailTable: React.FunctionComponent<MessagesDetailTableProps> = (Props) => {
  const { provider_id_list, status_list } = Props;

  const { tableProps, showDeleteConfirmationModal, handleCancelDelete, isSubmittingDelete, handleDeleteConfirmation } = useLogic({ provider_id_list, status_list });
  return (
    <>
      <CustomMessagesDetailTable {...tableProps} />
      <CustomConfirmationDialog open={showDeleteConfirmationModal} confirmButtonProps={{ onClick: handleDeleteConfirmation, disabled: isSubmittingDelete }} header="Delete Messages" cancelButtonProps={{ onClick: handleCancelDelete }}>
        You are about to deleted messages
      </CustomConfirmationDialog>
    </>
  );
};

export default MessagesDetailTable;
