import { Grid } from "@mui/material";
import CustomInfoCardWithIcon from "components/custom-compnents/custom-info-card-with-icon/custom-info-card-with-icon";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";
import { FunctionComponent } from "react";
import { IoMailSharp } from "react-icons/io5";
import { IParams, useLogic } from "./sms-messages-kpi.logic";

interface SmsMessagesKpiProps extends IParams {}

const SmsMessagesKpi: FunctionComponent<SmsMessagesKpiProps> = (props) => {
  const { perMonth, perWeek, total, uniqueperMonth, unique, uniqueperWeek } = useLogic(props);

  const kpiDataArray = [
    { value: perWeek, icon: IoMailSharp, title: "SMS In last 7 days" },
    { value: perMonth, icon: IoMailSharp, title: "SMS In Last 30 Days" },
    { value: total, icon: IoMailSharp, title: "SMS (Total)" },
    { value: uniqueperWeek, icon: IoMailSharp, title: "Unique SMS In Last 7 Days" },
    { value: uniqueperMonth, icon: IoMailSharp, title: "Unique SMS In Last 30 Days" },
    { value: unique, icon: IoMailSharp, title: "Unique SMS (Total)" },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomTitle label="Messaging" />
      </Grid>
      {kpiDataArray.map((item) => (
        <Grid item xs={12}>
          <CustomInfoCardWithIcon actionValue={item.value?.toString()} icon={item.icon} primaryText={item.title} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SmsMessagesKpi;
