import React from "react";
import {
  Grid,
  Box,
  ButtonProps,
  Typography,
  Paper,
  PaperProps,
  Button,
  Alert,
} from "@mui/material";
import CustomDialogV2, {
  CustomDialogV2Props,
} from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import CustomToggleButton from "components/custom-compnents/custom-toggle-buttons/custom-toggle-buttons";
import { Params, useLogic } from "./add-update-user-modal.logic";
import ProvidersInput, {
  ProvidersInputProps,
  Value,
} from "components/input-components/providers-input/providers-input";
import { IProvider } from "models/provider";

export type combinedProps = Params;

const AddUpdateUserModal: React.FC<combinedProps> = (props) => {
  const { form, isLoadingUser, user, options, validationErrors } =
    useLogic(props);

  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    handleChange,
    isSubmitting,
  } = form;

  const FormSchema = [
    {
      name: "name",
      label: "Name",
      placeholder: "Insert new name...",
      type: "text",
      value: values["name"],
      error: Boolean(errors["name"]),
      helperText: errors["name"],
      onChange: handleChange,
      autoFocus: true,
      size: "small",
      fullWidth: true,
    },
    {
      name: "email",
      label: "E-mail Address",
      placeholder: "Insert new email...",
      type: "email",
      value: values["email"],
      error: Boolean(errors["email"]),
      helperText: errors["email"],
      onChange: handleChange,
      size: "small",
      fullWidth: true,
    },
    {
      name: "password",
      label: "Password",
      placeholder: "Insert new Password...",
      type: "password",
      value: values["password"],
      error: Boolean(errors["password"]),
      helperText: errors["password"],
      onChange: handleChange,
      size: "small",
      fullWidth: true,
    },
    {
      name: "menuroles",
      label: "Role",
      placeholder: "",
      type: "SelectButtons",
      value: values["menuroles"],
      disabled: user?.menuroles !== "admin" && user?.menuroles !== "org_admin",
      isFullWidth: false,
      items: options.map((item) => ({ label: item.title, ...item })),
    },
    {
      name: "phone",
      label: "Phone",
      placeholder: "Insert new phone number...",
      type: "text",
      value: values["phone"],
      error: Boolean(errors["phone"]),
      helperText: errors["phone"],
      onChange: handleChange,
      size: "small",
      fullWidth: true,
    },
    {
      // hide when the user try to update him self and he is not admin
      // hide when thr org_admin try to add/update user with user/manager role
      hidden:
        (form.values["menuroles"] !== "org_admin" &&
          user?.menuroles !== "admin") ||
        (user?.menuroles !== "admin" &&
          props.Variant === "update" &&
          props.UserId === user?.id),
      name: "provider_id",
      type: "menu",
      value: values["provider_id"],
      error: Boolean(errors["provider_id"]),
      helperText: errors["provider_id"],
      OnChange: (value: Value) =>
        setFieldValue("provider_id", (value as IProvider)?.id ?? undefined),
    },
    {
      name: "age",
      type: "number",
      placeholder: "Insert birth year",
      value: values["age"],
      error: Boolean(errors["age"]),
      helperText: errors["age"],
      onChange: handleChange,
      fullWidth: true,
    },
    {
      name: "gender",
      label: "Gender",
      placeholder: "",
      type: "SelectButtons",
      value: values["gender"],
      isFullWidth: false,
      items: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
      ],
    },
  ];

  // Set Wrapper Props
  let ModalProps: CustomDialogV2Props | PaperProps | undefined;

  if (props.Shape === "card")
    ModalProps = {
      component: Box,
      p: 2,
    } as PaperProps;

  if (props.Shape === "modal")
    ModalProps = {
      open: props.open ?? false,
      header:
        props.Variant === "add"
          ? "Add New User"
          : props.Variant === "update"
          ? "Edit User"
          : "",
      footer: (
        <Box display="flex" justifyContent="flex-end">
          <CancelSuccessButtonsGroup
            CancelButtonProps={{
              label: "Cancel",
              onClick: props.OnCancel && props.OnCancel,
            }}
            SuccessButtonProps={{
              label: props.Variant === "add" ? "Add" : "Save",
              disabled: isSubmitting,
              onClick: () => form.submitForm(),
            }}
            RootGridProps={{ width: 300 }}
          />
        </Box>
      ),
    };

  const buttonProps: ButtonProps = {
    type: "submit",
    id: "Submit-Form",
    style: { display: props.Shape !== "card" ? "none" : undefined },
    fullWidth: true,
    color: "success",
    onClick: () => form.submitForm(),
  };

  const Wrapper = props.Shape === "card" ? Paper : CustomDialogV2;
  return (
    <Wrapper {...(ModalProps as any)}>
      {props.Variant === "update" && isLoadingUser ? (
        <Typography>Loading ...</Typography>
      ) : (
        <form onSubmit={handleSubmit} id="User-form">
          <Box my={1} maxWidth={400}>
            <Grid container spacing={2}>
              {FormSchema.filter((item) => !item.hidden).map((item, index) => (
                <Grid item key={index} xs={12}>
                  {item.type === "menu" ? (
                    <ProvidersInput
                      viewType={
                        user?.menuroles === "org_admin" ? "subs" : undefined
                      }
                      withOwnProviderOption
                      error={item.error}
                      helperText={item.helperText as string}
                      fullwidth
                      value={item.value!}
                      onChange={
                        item.OnChange as ProvidersInputProps["onChange"]
                      }
                    />
                  ) : item.type === "SelectButtons" ? (
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "6px",
                        border: "1px solid #c7d0d1",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "15px",
                          display: "block",
                          padding: "8px",
                          borderBottom: "1px solid #c7d0d1",
                          marginBottom: "12px",
                          borderRadius: "0px",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.name}
                      </label>
                      <CustomToggleButton
                        onChange={(value) => {
                          setFieldValue(item.name, value);
                        }}
                        value={item.value}
                        disabled={item.disabled}
                        isFullWidth={item.isFullWidth}
                        items={item.items}
                      />
                    </div>
                  ) : (
                    <CustomTextField
                      value={item.value}
                      autoFocus={item.autoFocus}
                      onChange={item.onChange}
                      name={item.name}
                      error={item.error}
                      helperText={item.helperText}
                      type={item.type}
                      placeholder={item.placeholder}
                      size={item.size as any}
                      fullWidth={item.fullWidth}
                    />
                  )}
                </Grid>
              ))}

              <Grid item xs={12}>
                {validationErrors && (
                  <Alert severity="error">{validationErrors}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  {...buttonProps}
                  disabled={
                    form.isSubmitting ||
                    (props.Variant === "update" && isLoadingUser)
                  }
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    </Wrapper>
  );
};

export default AddUpdateUserModal;
