import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import moment from "moment";

type aggregationType =
  | "p90"
  | "p75"
  | "p50"
  | "p25"
  | "p10"
  | "maxIgnoreNaN"
  | "minIgnoreNaN"
  | "meanIgnoreNaN"
  | "sumIgnoreNaN"
  | "stddev"
  | "sum"
  | "max"
  | "min"
  | "mean";
interface Params {
  units: {
    temperature: "C" | "K" | "F";
    velocity: "m/s" | "mph" | "km/h" | "kn" | "bft";
    length: "metric" | "imperial";
    energy: "watts" | "joules";
  };
  geomtry: {
    type: "MultiPoint";
    coordinates: [number, number, number][];
  };
  format: "json";
  timeIntervals: { start: Date; end: Date }[];
  timeIntervalsAlignment: "none";
  models: (
    | Object
    | {
      gapFillDomain:
      | "ERA5T"
      | null
      | "NEMSGLOBAL"
      | "ARC2"
      | "CHIRPS2"
      | "CMORPH";
    }
  )[];
}
interface Resp {
  Data: {
    codes: {
      aggregation: aggregationType;
      code: number;
      dataPerTimeInterval: { data: number[][]; gapFillRatio: number }[];
      level: "string";
      unit: "string";
      variable: "string";
    }[];
    domain: string;
    geometry: {
      coordinates: Params["geomtry"]["coordinates"];
      locationNames: string[];
      type: Params["geomtry"]["type"];
    };
    timeResolution: string;
    timeIntervals: string[][];
  }[];
}
type GetDatasetType = (
  Params: Params,
  callback: (error: AxiosError | null, Resp: Resp | null) => any
) => any;

const GetDataset: GetDatasetType = (Params: Params, callback) => {
  const MeteblueQuerys: any = new Object();
  const DateFormatter = (Date: Date) => moment(Date).format("YYYY-MM-DDTHH:mm");
  MeteblueQuerys["units"] = Params.units;
  MeteblueQuerys["geometry"] = Params.geomtry;
  MeteblueQuerys["format"] = Params.format;
  MeteblueQuerys["timeIntervals"] = Params.timeIntervals.map(
    (item) => `${DateFormatter(item.start)}/${DateFormatter(item.end)}`
  );
  MeteblueQuerys["timeIntervalsAlignment"] = Params.timeIntervalsAlignment;
  MeteblueQuerys["queries"] = Params.models;
  AxiosInstance.post("/meteoblue/dataset/query", {
    json: MeteblueQuerys ,
  }, { headers: { "content-type": "application/json" } })
    .then((resp) => {
      callback(null, { Data: resp.data });
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { GetDataset };
