import { makeStyles } from "@mui/styles";
import { APP_BAR_HEIGHT } from "constants/app-bar";

interface IParams {
  disableHeaderToolbarGutter?: boolean;
}

const useStyles = ({ disableHeaderToolbarGutter }: IParams) =>
  makeStyles({
    appBarRoot: {
      height: APP_BAR_HEIGHT,
      borderLeft: "none",
    },
    toolbarRoot: {
      height: APP_BAR_HEIGHT,
      padding: disableHeaderToolbarGutter ? undefined : "0px 45px",
    },
    dividerRoot: {
      height: 40,
    },
    userAreaWrapperRoot: {
      display: "flex",
      alignItems: "center",
    },
    mobileBreadcrumbWrapper: {
      width: "100%",
      height: "100%",
      background: "white",
      position: "absolute",
      top: "0px",
      left: "0px",
      zIndex: 10000,
      display: "flex",
      gap: "8px",
      justifyContent: "space-between",
    },
    breadcrumbComponentToolbar: {
      width: "max-content",
      justifyContent: "space-between",
      gap: 20,
      minWidth: "100%",
      alignItems: "center",
      height: "100%",
    },
  });

export default useStyles;
