import { Box, FormControl, FormControlLabel, FormHelperText, Grid, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as React from "react";
import * as yup from "yup";
import { AddSender, Params } from "api/senders/add-senders";
import { GetSenderIds } from "api/senders/get-senders-v2";
import { UpdateSender } from "api/senders/update-sender";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import ProvidersInput from "components/input-components/providers-input/providers-input";
import { Value } from "components/input-components/providers-input/providers-input";
import { Params as UpdateParams } from "api/senders/update-sender";

interface AddUpdateSenderIdProps {
  open: boolean;
  onCancel: () => any;
}

interface AddSenderIdProps {
  Variant: "Add";
  onAdded: () => any;
}

interface UpdateSenderIdProps {
  Variant: "update";
  onUpdated: () => any;
  SenderId: string;
}
export type compinedProps = AddUpdateSenderIdProps & (UpdateSenderIdProps | AddSenderIdProps);

const AddUpdateSenderId: React.FunctionComponent<compinedProps> = ({ open, onCancel, ...props }) => {
  const [LoadingUpdateData, SetLoadingUpdateData] = React.useState(false);

  const ValidationSchema = yup.object().shape({
    type: yup.string().oneOf(["Africastalking_sender", "Infobip_sender"]).required(),

    provider_id: yup.string().uuid("Organization is a required field").required().label("Organization"),

    at_sender_id: yup.string().when("type", {
      is: "at",
      then: yup.string().required().label("At Sender ID"),
      otherwise: yup.string().nullable(),
    }),

    at_auth_key: yup.string().when("type", {
      is: "at",
      then: yup.string().required().label("Authentication Key"),
      otherwise: yup.string().nullable(),
    }),

    at_username: yup.string().when("type", {
      is: "at",
      then: yup.string().required().label("Username"),
      otherwise: yup.string().nullable(),
    }),
    phone_number: yup.string().when("type", {
      is: "Infobip_sender",
      then: yup.string().required().label("Phone Number"),
      otherwise: yup.string().nullable(),
    }),
    base_url: yup.string().when("type", {
      is: "Infobip_sender",
      then: yup.string().required().label("Base Url"),
      otherwise: yup.string().nullable(),
    }),
    infobip_auth_key: yup.string().when("type", {
      is: "Infobip_sender",
      then: yup.string().required().label("Authentication Key"),
      otherwise: yup.string().nullable(),
    }),
  });

  const form = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { at_sender_id: "", provider_id: "", status: false, type: "", at_auth_key: "", at_username: "", phone_number: "", base_url: "", infobip_auth_key: "" },
    validationSchema: ValidationSchema,
    onSubmit: (values, action) => {
      form.setSubmitting(true);
      props.Variant === "Add" &&
        AddSender({
          ...values,
          sender_type: values.type as 'Africastalking_sender' | 'Infobip_sender'
        }, (error, resp) => {
          if (error) {
            console.log(error);
          } else if (resp) {
            form.setSubmitting(false);
            props.onAdded();
            action.resetForm();
          }
        });
      props.Variant === "update" &&
        UpdateSender({ ...values, id: props.SenderId } as UpdateParams, (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            form.setSubmitting(false);
            props.onUpdated();
            action.resetForm();
          }
        });
    },
  });

  const atSenderFields = [
    {
      name: "at_sender_id",
      placeholder: "Sender ID",
      type: "text",
      value: form.values["at_sender_id"],
      onChange: (e: any) => form.setFieldValue("at_sender_id", e.currentTarget.value),
    },
    {
      name: "at_auth_key",
      placeholder: "Authentication Key",
      type: "text",
      value: form.values["at_auth_key"],
      onChange: (e: any) => form.setFieldValue("at_auth_key", e.currentTarget.value),
    },
    {
      name: "at_username",
      placeholder: "Username",
      type: "text",
      value: form.values["at_username"],
      onChange: (e: any) => form.setFieldValue("at_username", e.currentTarget.value),
    },
    {
      name: "provider_id",
      placeholder: "Select Organization",
      type: "provider",
      value: form.values["provider_id"],
      onChange: (value: Value) => !Array.isArray(value) && form.setFieldValue("provider_id", value?.id),
    },
    { name: "status", placeholder: "Active?", type: "switch", value: "", onChange: (e: any, checked: boolean) => form.setFieldValue("status", checked) },
  ];

  const infobip = [
    { name: "phone_number", type: "text", placeholder: "Phone Number", value: form.values["phone_number"], onChange: (e: any) => form.setFieldValue("phone_number", e.currentTarget.value) },
    { name: "base_url", type: "text", placeholder: "Base Url", value: form.values["base_url"], onChange: (e: any) => form.setFieldValue("base_url", e.currentTarget.value) },
    { name: "infobip_auth_key", type: "text", placeholder: "Authentication Key", value: form.values["infobip_auth_key"], onChange: (e: any) => form.setFieldValue("infobip_auth_key", e.currentTarget.value) },
    {
      name: "provider_id",
      placeholder: "Select Organization",
      type: "provider",
      value: form.values["provider_id"],
      onChange: (value: Value) => !Array.isArray(value) && form.setFieldValue("provider_id", value?.id),
    },
    { name: "status", placeholder: "Active?", type: "switch", value: form.values["status"], onChange: (e: any, checked: boolean) => form.setFieldValue("status", checked) },
  ];

  React.useEffect(() => {
    if (props.Variant === "update") {
      SetLoadingUpdateData(true);
      GetSenderIds({ page: 0, pageSize: 10, id: props.SenderId }, (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          SetLoadingUpdateData(false);
          const values = resp.allSenders.nodes[0];
          form.setValues({
            at_auth_key: values.atAuthKey,
            at_sender_id: values.atSenderId,
            at_username: values.atUsername,
            base_url: values.baseUrl,
            infobip_auth_key: values.infobipAuthKey,
            phone_number: values.phoneNumber,
            provider_id: values.providerId,
            status: values.status,
            type: values.senderType === "Africastalking_sender" ? "Africastalking_sender" : "Infobip_sender",
          });
        }
      });
    }
  }, [props.Variant === "update" && props.SenderId]);

  const SchemaToUse = form.values["type"] == "Africastalking_sender" ? atSenderFields : form.values["type"] === "Infobip_sender" ? infobip : [];

  return (
    <CustomDialogV2
      header={props.Variant === "Add" ? "Add Sender ID" : "Edit Sender ID"}
      open={open}
      footer={
        <Box display="flex" justifyContent="flex-end">
          <CancelSuccessButtonsGroup
            CancelButtonProps={{ label: "Cancel", onClick: onCancel }}
            SuccessButtonProps={{
              label: "Save",
              disabled: form.isSubmitting,
              onClick: () => form.submitForm(),
            }}
            RootGridProps={{ width: 300 }}
          />
        </Box>
      }
    >
      {LoadingUpdateData ? (
        <Typography>Loading ...</Typography>
      ) : (
        <form onSubmit={form.handleSubmit}>
          <Box width={350}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl error={Boolean(form.errors["type"])} fullWidth>
                  <Select disabled={props.Variant === "update"} value={form.values["type"]} displayEmpty fullWidth label="Age" onChange={(e) => form.setFieldValue("type", e.target.value as any)}>
                    <MenuItem value={""}>Select Sender Type</MenuItem>
                    <MenuItem value={"Africastalking_sender"}>Africa's Talking</MenuItem>
                    <MenuItem value={"Infobip_sender"}>Infobip</MenuItem>
                  </Select>
                  {form.errors["type"] && <FormHelperText>{form.errors["type"]}</FormHelperText>}
                </FormControl>
              </Grid>

              {SchemaToUse.map((item, index) => (
                <Grid key={index} item xs={12}>
                  {item.type === "switch" ? (
                    <FormControlLabel control={<Switch checked={(form.values as any)[item.name]} onChange={item.onChange as any} />} label={item.placeholder as string} />
                  ) : item.type === "provider" ? (
                    <ProvidersInput error={Boolean((form as any).errors[item.name])} helperText={(form as any).errors[item.name]} value={item.value as any} onChange={item.onChange as any} />
                  ) : (
                    <TextField name={item.name} onChange={form.handleChange} placeholder={item.placeholder} size="small" fullWidth error={Boolean((form as any).errors[item.name])} helperText={(form as any).errors[item.name]} value={(form as any).values[item.name]} autoFocus={index === 0} />
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </form>
      )}
    </CustomDialogV2>
  );
};

export default AddUpdateSenderId;
