import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { GetFarmActivites } from "api/activities/get-farm-activites";
import { DeleteAggregationActivity } from "api/aggregation/delete-aggregation";
import { Activity } from "models/activity";
import { IUser } from "models/user";
// import ActivityDetailComponent from "components/standalone-components/activity-detail-component/activity-detail-component";
// import AddUpdateAggregationActivity from "components/standalone-components/add-update-aggregation-activity/add-update-aggregation-activity";
import CustomDialog from "components/custom-compnents/custom-dialog/custom-dialog";
import QualityCheckTable from "components/data-tables/quality-check-table/quality-check-table";
import { Params } from "components/data-tables/quality-check-table/schema";
import { useParams } from "react-router-dom";
import { GetUser } from "api/auth/user/get-user";
import ShowEditAggregationEventComponent from "components/standalone-components/show-edit-aggregation-event-component/show-edit-aggregation-event-component";

interface AggregationScreenProps { }

const AggregationScreen: React.FunctionComponent<AggregationScreenProps> =
  () => {
    const [User_id, SetUser_id] = React.useState<undefined | string>();
    const [User_menuroles, SetUser_menuroles] =
      React.useState<IUser["menuroles"] | undefined>();

    const { activity_id } = useParams();
    const [AggregationData, SetAggregationData] =
      React.useState<undefined | Activity>(undefined);
    // const [OpenDeleteDialog, SetOpenDeleteDialog] = React.useState(false);
    // const [IsSubmittingDelete, SetIsSubmittingDelete] = React.useState(false);
    // const [OpenUpdateAggregationModal, SetOpenUpdateAggregationModal] =
    //   React.useState(false);
    const [OpenUpdateAggregationDrawer, SetOpenUpdateAggregationDrawer] =
      React.useState(false);

    const handleShowActivityButtonClick = () =>
      SetOpenUpdateAggregationDrawer(true);

    const handleBackButtonClick = () => {
      SetOpenUpdateAggregationDrawer(false);
    };

    React.useEffect(() => {
      GetFarmActivites(
        { page: 0, pageSize: 10, id: activity_id },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            if (resp.items.length === 0) window.history.back();
            SetAggregationData(resp.items[0]);
          }
        }
      );

      GetUser().then((resp) => {
        SetUser_menuroles(resp.data.menuroles);
        SetUser_id(resp.data.id);
      });
    }, []);

    // const HandleConfirmDelete = () => {
    //   SetIsSubmittingDelete(true);
    //   AggregationData &&
    //     DeleteAggregationActivity(
    //       { id: AggregationData.id, farm_id: AggregationData.farmId },
    //       (error, resp) => {
    //         if (error) {
    //           console.log(error.message);
    //         } else if (resp) {
    //           SetIsSubmittingDelete(false);
    //           SetOpenDeleteDialog(false);
    //           window.history.back();
    //         }
    //       }
    //     );
    // };
    // const HandleCancelDelete = () => {
    //   SetOpenDeleteDialog(false);
    // };

    // const HandleDeleteButtonClicked = () => {
    //   SetOpenDeleteDialog(true);
    // };
    // const HandleUpdateButtonClicked = () => {
    //   SetOpenUpdateAggregationModal(true);
    // };
    // const HandleUpdated = () => {
    //   SetOpenUpdateAggregationModal(false);
    //   window.location.reload();
    // };
    // const HandleCancelUpdate = () => {
    //   SetOpenUpdateAggregationModal(false);
    // };
    const ActionsToExclude: Params["ActionsToExclude"] =
      User_menuroles === "user" ? ["Add"] : [];

    return (
      <>
        {AggregationData && (
          <ShowEditAggregationEventComponent
            activityId={AggregationData.id}
            farm_id={AggregationData.farmId}
            open={OpenUpdateAggregationDrawer}
            onBackClick={handleBackButtonClick}
          />
        )}

        {/* {AggregationData && (
          <AddUpdateAggregationActivity
            farm_id={AggregationData.farmId}
            open={OpenUpdateAggregationModal}
            Variant="Update"
            OnCancel={HandleCancelUpdate}
            OnUpdate={HandleUpdated}
            Activity_Id={AggregationData.id}
          />
        )} */}
        {/* <CustomDialog
          open={OpenDeleteDialog}
          Header="Delete Aggergation Event"
          ActionButtonsProps={{
            DisableConfirm: IsSubmittingDelete,
            ButtonsVariant: "ConfirmIgnore",
            OnConfirm: HandleConfirmDelete,
            OnIgnore: HandleCancelDelete,
          }}
        >
          <Typography>
            are you sure you want to delete this aggregation event ?
          </Typography>
        </CustomDialog> */}
        <Grid container spacing={2}>
          {/* <Grid item md={3} xs={12}>
            {AggregationData && activity_id && (
              <ActivityDetailComponent
                OnDeleteClicked={HandleDeleteButtonClicked}
                // OnEditClicked={HandleUpdateButtonClicked}
                Variant="Card"
                activity_id={activity_id}
                ShowDeleteButton={
                  User_menuroles === "admin" || User_menuroles === "manager"
                } //only the user whom there provider own this aggregation can see this page and there for ,there is no need to check provider_id
                ShowEditButton={
                  User_menuroles === "admin" || User_menuroles === "manager"
                }
              />
            )}
          </Grid> */}
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="end"
          >
            <Button
              variant="text"
              color="success"
              onClick={handleShowActivityButtonClick}
            >
              Aggregation Activity Information
            </Button>
            <Divider sx={{ width: "100%" }} />
          </Grid>
          <Grid item xs={12}>
            {User_id && (
              <QualityCheckTable
                aggregation_activity_id={activity_id}
                User_id={User_id}
                ActionsToExclude={ActionsToExclude}
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  };

export default AggregationScreen;
