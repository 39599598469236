import React from "react";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";

export interface option {
  title: string;
  value: string;
  "data-cy"?: string;
}
export interface SelectButtonsProps {
  Options: option[];
  SelectedOption: option | undefined;
  onSelect: (option: option) => void;
  helperText?: string;
}

const SelectButtons: React.SFC<SelectButtonsProps> = ({
  Options,
  SelectedOption,
  onSelect,
  helperText,
}) => {
  return (
    <React.Fragment>
      <ButtonGroup
        disableElevation
        variant="outlined"
        color="primary"
        fullWidth
      >
        {Options.map((item, index) => (
          <Button
            color="success"
            key={index}
            variant={
              item.value === SelectedOption?.value ? "contained" : undefined
            }
            onClick={() =>
              item.value !== SelectedOption?.value && onSelect(item)
            }
            data-cy={item?.["data-cy"]}
          >
            {item.title}
          </Button>
        ))}
      </ButtonGroup>
      {helperText && (
        <Box mx={2}>
          <Typography
            variant="caption"
            color="error"
            style={{ fontWeight: 100 }}
          >
            {helperText}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default SelectButtons;
