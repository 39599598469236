import { AxiosInstance } from "configs/axios";
import { IOrganizationRelation } from "models/organizations-relation";
interface Params {
  super_org: string;
  sub_org?: string;
  bill_to_super?: boolean;
}
type Resp = IOrganizationRelation;

const updateSuperOrganizationRelation = (Params: Params) => {
  return AxiosInstance.patch<Resp>("/organization-relations", { ...Params }, {
    params: Params
  });
};

export { updateSuperOrganizationRelation };
