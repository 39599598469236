import { IconButton } from "@mui/material";
import { IoSettingsOutline } from "react-icons/io5";
import CustomMenu, {
  CustomMenuProps,
} from "../custom-menu-component/custom-menu-component";

interface ActionButtonMenuProps {
  menuProps?: CustomMenuProps;
}

const ActionButtonMenu: React.FunctionComponent<ActionButtonMenuProps> = (
  props
) => {
  const { menuProps } = props;
  return (
    <CustomMenu {...menuProps}>
      <IconButton>
        <IoSettingsOutline />
      </IconButton>
    </CustomMenu>
  );
};

export default ActionButtonMenu;
