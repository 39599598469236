import * as React from "react";
import { ProvideDirectMessageLocationFilterStateTo } from "store/reducers/location-filter-input-slice/direct-message-location-filter-reducer";
import FilterMenu, { FilterState } from "./filter-menu";

import CustomMenu, {
  CustomMenuProps,
} from "components/custom-compnents/custom-menu-component/custom-menu-component";

export interface LocationFilterProps {
  DirectMessageLocationFilterState: any;
  SetDirectMessageLocationFilterState: any;
  onChange?: (value: FilterState) => void;
  menuProps: Pick<CustomMenuProps, "open" | "onChildrenClick" | "anchorEl">;
  "data-cy"?: string;
}

interface ModalProps {
  Open?: boolean;
}

const LocationFilter: React.FC<LocationFilterProps & (ModalProps | null)> = ({
  DirectMessageLocationFilterState,
  SetDirectMessageLocationFilterState,
  onChange,
  ...props
}) => {
  const FilterMenuProps = {
    onSetFilter: (value: FilterState) => {
      onChange!(value);
    },
    onResetFilter: () => {
      SetDirectMessageLocationFilterState({ open: false });
      SetDirectMessageLocationFilterState({ filledButton: false });
      onChange!({} as FilterState);
    },
    open: true,
  };

  return (
    <CustomMenu
      anchorEl={props.menuProps.anchorEl}
      open={props.menuProps.open}
      onChildrenClick={props.menuProps.onChildrenClick}
      menuChildren={<FilterMenu {...FilterMenuProps} />}
    >
      {props.children}
    </CustomMenu>
  );
};

export default ProvideDirectMessageLocationFilterStateTo(LocationFilter, true);
