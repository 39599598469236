import { makeStyles } from "@mui/styles";
import { APP_BAR_HEIGHT } from "constants/app-bar";

const useStyles = makeStyles({
  logo_wrapper_root: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: APP_BAR_HEIGHT - 2,
  },
  logo_expanded_root: {},
});

export default useStyles;
