import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { IFarmerLabel } from "models/farmer-label";

interface Resp {}
interface Params {
  labels: Pick<IFarmerLabel, "value" | "farmerId" | "labelKeyId">[];
}

type BulkAddFarmersLabelsType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;
const BulkAddFarmersLabels: BulkAddFarmersLabelsType = (Params, callback) => {
  AxiosInstance.post("/farmer-labels/bulk", { 
    labels: Params.labels.map(item => ({ ...item, farmer_id: item.farmerId, value: [item.value], label_key_id: item.labelKeyId}))
   })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { BulkAddFarmersLabels };
