import { Dispatch } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { IFarm } from "models/farm";
import { Farmer } from "models/farmers";

export interface FarmerScreenStateType {
  SelectedTab: number;
  Farmer: Farmer | undefined;
  Farms: IFarm[];
}

export interface FarmerScreenStateDispatcherType {
  SetFarmerScreenState: (FarmState: Partial<FarmerScreenStateType>) => void;
}

export interface FarmerScreenStateProviderType
  extends Record<"FarmerScreenState", FarmerScreenStateType>,
    FarmerScreenStateDispatcherType {}

export default (
  state: FarmerScreenStateType = {
    SelectedTab: 0,
    Farmer: undefined,
    Farms: [],
  },
  action: any
) => {
  return action.type === "setFarmerScreenState"
    ? { ...state, ...action.payload }
    : state;
};

const ProvideFarmerScreenStateTo = (Component: any, ProvideState = false) => {
  const mapStateToProps = (state: any) => {
    return ProvideState
      ? { FarmerScreenState: state.FarmerScreenState as FarmerScreenStateType }
      : {};
  };

  const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
      SetFarmerScreenState: (value: Partial<FarmerScreenStateType>) =>
        dispatch({ type: "setFarmerScreenState", payload: value }),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export { ProvideFarmerScreenStateTo };
