import { ExtractSubjectType, InferSubjects, PureAbility } from "@casl/ability";
import { IUser } from "models/user";
import { store } from "store/store";
import { AbilityBuilder, Ability } from "@casl/ability";
import { RouteItem } from "classes/route-item";

type actions = "see";
type subjects = InferSubjects<typeof RouteItem>;
type AppAbility = PureAbility<[actions, subjects]>;

const { can, build } = new AbilityBuilder<AppAbility>(Ability);

export default function defineAbilityForUser(
  User: IUser | undefined = store.getState().appReducer.user
) {
  can("see", RouteItem, { roles: { $in: [User?.menuroles] } });

  return build({
    detectSubjectType: (item) =>
      item.constructor as ExtractSubjectType<subjects>,
  });
}
