import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

type Cell = string | number | boolean | typeof Date
export type Row = Cell[]


interface BulkItem {
  farmerId: string;
  labelKeyId: string;
  value: string;
}


interface Resp {
  items: BulkItem[];
}
interface Params {
  providerId?: string;
  fileContent?: Row[];
}

type BulkValidateFarmersLabelsType = (
  Params: Params,
  callback: (error: null | AxiosError, Resp: null | Resp) => any
) => any;

const BulkValidateFarmersLabels: BulkValidateFarmersLabelsType = (
  Params,
  callback
) => {
  AxiosInstance.post("/farmer-labels/bulk-validate", {
    ...Params,
  })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { BulkValidateFarmersLabels };
