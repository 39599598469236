import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { authTokenService } from "services/auth-token-service/auth-token-service";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openDrawer, removeUser } from "store/reducers/app/app-slice";
import { useTheme } from "@mui/material";
import React from "react";

export const useLogic = () => {
  const [
    isShowBreadcrumbToolbarForSmallDevices,
    setIsShowBreadcrumbToolbarForSmallDevices,
  ] = React.useState(false);

  const theme = useTheme();
  const user = useAppSelector((state) => state.appReducer.user);
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();

  const Logout = () => {
    authTokenService.RemoveTokenLocally();
    dispatcher(removeUser());
  };
  const HandleSettingItemClick = () => {
    navigate("/profile");
  };

  const handleOpenDrawer = () => dispatcher(openDrawer());

  const handleCloseBreadcrumbToolbar = () =>
    setIsShowBreadcrumbToolbarForSmallDevices(false);

  const handleOpenBreadcrumbToolbar = () =>
    setIsShowBreadcrumbToolbarForSmallDevices(true);

  const isLargeOrLessScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isMedOrLessScreen = useMediaQuery(theme.breakpoints.down("md"));

  return {
    isLargeOrLessScreen,
    HandleSettingItemClick,
    Logout,
    user,
    handleOpenDrawer,
    isMedOrLessScreen,
    isShowBreadcrumbToolbarForSmallDevices,
    handleCloseBreadcrumbToolbar,
    handleOpenBreadcrumbToolbar,
  };
};
