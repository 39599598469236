import { IFarmerLabelKey } from "models/farmer-label-key";
import React from "react";
import { GetFarmerLabelsKeys } from "api/farmer-labels-keys/get";
import { useAppSelector } from "store/hooks";
import { useDebounce } from "hooks/debounce-hook";
import { AddFarmerLabelKey } from "api/farmer-labels-keys/add-farmer-label-key";
// import { GetProvider } from "api/provider/get-provider";
import { IProvider } from "models/provider";
import { UpdateFarmerLabelKey } from "api/farmer-labels-keys/update-farmer-label-key";
import { ProvidersInputProps } from "components/input-components/providers-input/providers-input";

export const useLogic = () => {
  const user = useAppSelector((root) => root.appReducer.user);

  const { menuroles, provider_id } = user!;

  const [data, setData] = React.useState<IFarmerLabelKey[]>([]);
  const [itemToUpdateId, setItemToUpdateId] = React.useState("");
  const [keyToUpdateData, setKeyToUpdateData] =
    React.useState<Pick<IFarmerLabelKey, "key" | "providerId"> | undefined>();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [keySearchTerm, setKeySearchTerm] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [openAddPanel, setOpenAddPanel] = React.useState(false);
  const debouncedSearchTerm = useDebounce(keySearchTerm, 800);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [providers, setProviders] = React.useState<IProvider[]>([]);
  const [loadingAdd, setLoadingAdd] = React.useState(false);
  const [itemIdToDelete, setItemIdToDelete] = React.useState("");
  const providerFilterIds = providers.map((item) => item.id);

  const [keyToAdd, setKeyToAdd] = React.useState<
    Pick<IFarmerLabelKey, "key" | "providerId"> | undefined
  >(menuroles !== "admin" ? { key: "", providerId: provider_id } : undefined);
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);

  const handleUpdateItemInvoke = (id: string) => {
    setItemToUpdateId(id);
    setKeyToUpdateData(data.find((item) => item.id === id));
  };

  const handleDeleteItemInvoke = (id: string) => {
    setItemIdToDelete(id);
  };

  const handleCancelUpdate = () => {
    setItemToUpdateId("");
    setKeyToUpdateData(undefined);
  };

  const handleProvidersChange: ProvidersInputProps["onChange"] = (value) => {
    Array.isArray(value) && setProviders(value as any);
    setCurrentPage(0);
  };

  const handleNewSearchTerm = (value: string) => {
    setKeySearchTerm(value);
    setCurrentPage(0);
  };

  const handleOpenAddPanel = () => setOpenAddPanel(true);

  const handleCancelAdd = () => {
    resetAddKeyState();
    setOpenAddPanel(false);
  };

  const handleCancelDelete = () => {
    setItemIdToDelete("");
  };

  const handleDeleted = () => {
    setItemIdToDelete("");
    refreshData();
  };

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const resetAddKeyState = () => {
    setKeyToAdd(
      menuroles !== "admin" ? { key: "", providerId: provider_id } : undefined
    );
  };

  const handleAddKey = () => {
    if (keyToAdd) {
      setLoadingAdd(true);
      AddFarmerLabelKey(
        {
          provider_id: keyToAdd.providerId,
          key: keyToAdd.key,
        },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            resetAddKeyState();
            setOpenAddPanel(false);
            refreshData();
            setLoadingAdd(false);
          }
        }
      );
    }
  };

  const handleKeyUpdate = () => {
    if (keyToUpdateData && itemToUpdateId) {
      setLoadingUpdate(true);
      UpdateFarmerLabelKey(
        {
          id: itemToUpdateId,
          key: keyToUpdateData.key,
          provider_id: keyToUpdateData.providerId,
        },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            refreshData();
            setKeyToUpdateData(undefined);
            setItemToUpdateId("");
            setLoadingUpdate(false);
          }
        }
      );
    }
  };

  const handleSetAddValue = (
    value: Partial<Pick<IFarmerLabelKey, "key" | "providerId">>
  ) => {
    const term: Pick<IFarmerLabelKey, "key" | "providerId"> = {
      key: "",
      providerId: "",
    };
    term["key"] = keyToAdd?.key ?? "";
    term["providerId"] = keyToAdd?.providerId ?? "";
    if (typeof value.key === "string") term["key"] = value.key;
    if (value.providerId) term["providerId"] = value.providerId;
    setKeyToAdd(term);
  };

  const handleSetUpdateValue = (
    value: Partial<Pick<IFarmerLabelKey, "key" | "providerId">>
  ) => {
    const term: Pick<IFarmerLabelKey, "key" | "providerId"> = {
      key: "",
      providerId: "",
    };
    term["key"] = keyToUpdateData?.key ?? "";
    term["providerId"] = keyToUpdateData?.providerId ?? "";
    if (typeof value.key === "string") term["key"] = value.key;
    if (value.providerId) term["providerId"] = value.providerId;
    setKeyToUpdateData(term);
  };

  const isAddValid = Boolean(keyToAdd?.key) && Boolean(keyToAdd?.providerId);

  const isUpdateValid =
    Boolean(keyToUpdateData?.key) &&
    Boolean(keyToUpdateData?.providerId) &&
    (data.find((item) => item.id === itemToUpdateId)?.key !==
      keyToUpdateData?.key ||
      data.find((item) => item.id === itemToUpdateId)?.providerId !==
        keyToUpdateData?.providerId);

  const pagnationProps = {
    from: perPage * currentPage,
    total: totalCount,
    isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
    isPreviousPageButtonDisabled: currentPage === 0,
    onRowPerPageChange: handlePerPageChange,
    onNextPageClick: handleNextPageClick,
    onPreviousPageClick: handlePreviousPageClick,
    rowsPerPage: perPage,
  };

  React.useEffect(() => {
    setLoading(true);
    GetFarmerLabelsKeys(
      {
        page: currentPage,
        pageSize: perPage,
        key: debouncedSearchTerm,
        provider_id_list:
          provider_id && menuroles !== "admin"
            ? [provider_id]
            : providerFilterIds,
        // key_search: debouncedSearchTerm,
        with_farmers_count: true,
      },
      (error, resp) => {
        if (error) {
          console.log(error);
        }
        if (resp) {
          setLoading(false);
          setTotalCount(resp.totalCount);
          setData(resp.items);
        }
      }
    );
  }, [
    debouncedSearchTerm,
    currentPage,
    perPage,
    dataUpdateIndex,
    JSON.stringify(providerFilterIds),
  ]);

  return {
    data,
    itemToUpdateId,
    menuroles,
    handleUpdateItemInvoke,
    handleCancelUpdate,
    totalCount,
    handleNewSearchTerm,
    loading,
    handleOpenAddPanel,
    handleCancelAdd,
    openAddPanel,
    pagnationProps,
    handleAddKey,
    isAddValid,
    providers,
    handleSetAddValue,
    keyToAdd,
    provider_id,
    loadingAdd,
    handleSetUpdateValue,
    keyToUpdateData,
    handleKeyUpdate,
    loadingUpdate,
    isUpdateValid,
    itemIdToDelete,
    handleDeleteItemInvoke,
    handleCancelDelete,
    handleDeleted,
    providerFilterIds,
    handleProvidersChange,
  };
};
