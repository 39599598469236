import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";

interface Params {
    region_id_list?: string[];
    county_id_list?: string[];
}

type GetCountiesGeojsonType = (
    Params: Params,
    callback: (error: AxiosError | null, resp: null | any) => any
) => any;


const GetCountiesGeojson: GetCountiesGeojsonType = (Params, callback) => {
    AxiosInstance.get("/counties/geojson", {
        params: Params
    })
        .then((resp) => callback(null, resp))
        .catch((error) => callback(error, null));
};

export { GetCountiesGeojson };
