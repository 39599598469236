import { Chip, Switch, Typography } from "@mui/material";
import { GetProvider } from "api/provider/get-provider";
import { GetSurveyById } from "api/survey/get-survey-by-id";
import { UpdateSurvey } from "api/survey/update-survey";
import { CustomDrawerProps } from "components/custom-compnents/custom-drawer/custom-drawer";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import SelectMenu, {
  option,
} from "components/input-components/select-menu/SelectMenu";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { useAppSelector } from "store/hooks";
import * as yup from "yup";
import { RemoveSurveyComponentProps } from "../remove-survey-component/remove-survey-component";

export interface IParams {
  id: string;
  onUpdated?: () => any;
  onDeleted?: () => any;
  onBackClick?: () => any;
}

export const useLogic = (params: IParams) => {
  const { id, onDeleted, onUpdated, onBackClick } = params;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isInEditMode, setIsInEditMode] = React.useState(false);
  const [providerTerm, setProviderTerm] = React.useState("");
  const [isShowDeleteComponent, setIsShowDeleteComponent] =
    React.useState(false);
  const [providerListOptions, setProviderListOptions] = React.useState<
    option[]
  >([]);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleEditButtonClick = () => setIsInEditMode(true);
  const handleCancelEditButtonClick = () => setIsInEditMode(false);

  const handleDeleteButtonClick = () => setIsShowDeleteComponent(true);

  const handleCancelDeleteButtonClick = () => setIsShowDeleteComponent(false);

  const handleDeleted = () => {
    setIsShowDeleteComponent(false);
    onDeleted?.();
  };

  const ValidationSchema = yup.object().shape({
    title: yup.string().required(),
    jump_code: yup
      .string()
      .required("Jump code is required")
      .max(10, "jump code must not be greater thatn 10 letters"),
    provider_id: yup.string().required().not(["none"], "Organization is required"),
    // start_time:
    //     Props.variant == 'add'
    //         ? yup.date().required().min(new Date(Date.now()), 'Start time cannot be in the past')
    //         : yup.date().required(),
    // expires_at: yup.date().required('Expire at is a required field').min(yup.ref('start_time')),
    expires_at: yup.string().required("Expire At field is required"),
    start_time: yup.string().required("Start Time field is required"),
  });

  const handleSubmit = () => formikForm.submitForm();

  React.useEffect(() => {
    GetProvider(
      { page: 0, pageSize: 10, search: providerTerm },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setProviderListOptions(
            resp.providers.map((item, index) => ({
              title: item.name,
              value: item.id,
            }))
          );
        }
      }
    );
  }, [providerTerm]);

  React.useEffect(() => {
    setIsLoading(true);
    GetSurveyById({ page: 0, pageSize: 10, survey_id_list: [id] }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        formikForm.setValues({
          ...resp,

          provider_id: resp.provider_id,
          expires_at: moment
            .utc(resp.expires_at)
            .local()
            .format("YYYY-MM-DDTHH:mm:ss"),
          start_time: moment
            .utc(resp.start_time)
            .local()
            .format("YYYY-MM-DDTHH:mm:ss"),
        });
        setIsLoading(false);
      }
    });
  }, [id]);

  const user = useAppSelector((root) => root.appReducer.user);

  const navigationHeaderProps: CustomDrawerProps["NavigationHeaderProps"] = {
    label: isInEditMode ? "Update Survey Information" : "Survey Information",
    backButtonProps: {
      onClick: onBackClick,
    },
  };

  const formikForm = useFormik({
    validationSchema: ValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      active: false,
      title: "",
      jump_code: "",
      provider: { name: "", id: "none" },
      provider_id: "",
      start_time: "",
      expires_at: "",
    },
    onSubmit: (values) => {
      setIsSubmitting(true);
      UpdateSurvey(
        {
          ...values,
          expires_at: moment(values.expires_at).utc().format(),
          start_time: moment(values.start_time).utc().format(),
          id,
          provider_id: values.provider_id,
        },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            setIsInEditMode(false);
            setIsSubmitting(false);
            onUpdated?.();
          }
        }
      );
    },
  });

  const fieldsData = [
    {
      placeholder: "Survey Name",
      inputProps: {},
      isInEditMode: isInEditMode,
      showComponent: (
        <Typography variant="h6">{formikForm.values.title}</Typography>
      ),
      editable: true,
      editComponent: (
        <CustomTextField
          onChange={(e) =>
            formikForm.setFieldValue("title", e.currentTarget.value)
          }
          fullWidth
          value={formikForm.values.title}
          error={!!formikForm.errors.title}
          helperText={formikForm.errors.title}
        />
      ),
    },
    {
      placeholder: "Jump Code",
      textfieldinputPropsProps: {},
      isInEditMode: isInEditMode,
      showComponent: (
        <Typography variant="h6">{formikForm.values.jump_code}</Typography>
      ),
      editable: true,
      editComponent: (
        <CustomTextField
          error={!!formikForm.errors.jump_code}
          helperText={formikForm.errors.jump_code}
          fullWidth
          value={formikForm.values.jump_code}
          onChange={(e) =>
            formikForm.setFieldValue("jump_code", e.currentTarget.value)
          }
        />
      ),
    },
    {
      placeholder: "Organization",
      inputProps: {},
      isInEditMode: isInEditMode,
      showComponent: (
        <Typography variant="h6">
          {
            providerListOptions.find(
              (item) => item.value === formikForm.values.provider_id
            )?.title
          }
        </Typography>
      ),
      editable: true,
      editComponent: (
        <SelectMenu
          error={!!formikForm.errors.provider_id}
          helperText={formikForm.errors.provider_id}
          disabled={user?.menuroles !== "admin"}
          // error={Boolean((FormikProps as any).errors[item.name])}
          // helperText={(FormikProps as any).errors[item.name]}
          OnChange={(e) =>
            formikForm.setFieldValue("provider_id", e.target.value)
          }
          value={formikForm.values.provider_id}
          options={providerListOptions}
          SearchLabel=""
          onSearchInputChange={(value) => setProviderTerm(value)}
          SearchPlaceholder="Insert Organization Name"
          Searchable={true}
          Placeholder={""}
        />
      ),
    },
    {
      placeholder: "Start Date",

      inputProps: {},
      isInEditMode: isInEditMode,
      showComponent: (
        <Typography variant="h6">
          {moment(formikForm.values.start_time).format("YYYY/MM/DD HH:mm a")}
        </Typography>
      ),
      editable: true,
      editComponent: (
        <CustomTextField
          error={!!formikForm.errors.start_time}
          helperText={formikForm.errors.start_time}
          fullWidth
          type="datetime-local"
          value={formikForm.values.start_time}
          onChange={(e) =>
            formikForm.setFieldValue("start_time", e.currentTarget.value)
          }
        />
      ),
    },
    {
      placeholder: "Expiry Date",

      inputProps: {},
      isInEditMode: isInEditMode,
      showComponent: (
        <Typography variant="h6">
          {moment(formikForm.values.expires_at).format("YYYY/MM/DD HH:mm a")}
        </Typography>
      ),
      editable: true,
      editComponent: (
        <CustomTextField
          error={!!formikForm.errors.expires_at}
          helperText={formikForm.errors.expires_at}
          fullWidth
          type="datetime-local"
          value={formikForm.values.expires_at}
          onChange={(e) =>
            formikForm.setFieldValue("expires_at", e.currentTarget.value)
          }
        />
      ),
    },

    {
      placeholder: "Status",
      inputProps: {},

      isInEditMode: isInEditMode,
      showComponent: formikForm.values.active ? (
        <Chip variant="outlined" label="Active" color="default" />
      ) : (
        <Chip variant="outlined" label="Inactive" color="default" />
      ),
      editable: true,
      editComponent: (
        <Switch
          color="success"
          checked={formikForm.values.active}
          onChange={(e, checked) => formikForm.setFieldValue("active", checked)}
        />
      ),
    },
  ];

  const deleteDialogProps: RemoveSurveyComponentProps = {
    id,
    onCancel: handleCancelDeleteButtonClick,
    open: isShowDeleteComponent,
    onDeleted: handleDeleted,
  };

  return {
    navigationHeaderProps,
    fieldsData,
    isInEditMode,
    handleCancelDeleteButtonClick,
    handleCancelEditButtonClick,
    handleDeleteButtonClick,
    handleEditButtonClick,
    handleSubmit,
    isSubmitting,
    isShowDeleteComponent,
    deleteDialogProps,
  };
};
