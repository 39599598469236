import * as React from "react";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { GetCounties } from "api/location/counties";
import { County } from "models/county";
import { Region } from "models/region";

export interface CountyInputProps {
  onChange?: (value: Array<County>) => void;
  options: Array<County>;
  OnOptionsChange?: (value: County[]) => void;
  SelectedRegions: Array<Region>;
  value: Array<County>;
}

const CountyInput: React.SFC<CountyInputProps> = ({
  OnOptionsChange,
  onChange,
  options,
  SelectedRegions,
  value,
}) => {
  const [loading, SetLoading] = React.useState(true);
  const uniqOptions: Array<County> = uniqBy(options, "id");
  const sortedOptions: Array<County> = sortBy(
    uniqOptions,
    (option) => option?.regionId
  );

  React.useEffect(() => HandleInputChange(null), [SelectedRegions]); // get data every single time Region tags change

  const HandleInputChange = (e: any) => {
    SetLoading(true);

    OnOptionsChange && OnOptionsChange([]);

    // set region name to search for
    // const variables = { filter: { name: { includesInsensitive: e?.currentTarget?.value ? e?.currentTarget?.value : null }, regionId: { in: SelectedRegions.map((item) => item.regionId) } } };

    GetCounties(
      {
        page: 0,
        pageSize: 100,
        is_attached_to_farmers: true,
        with_region: true,
        region_id_list:
          SelectedRegions && SelectedRegions.length
            ? SelectedRegions.map((item) => item.regionId)
            : undefined,
      },
      (error, resp) => {
        SetLoading(false);
        if (error) {
          console.log(error.message);
        } else {
          OnOptionsChange && OnOptionsChange(resp?.allCounties.items!);
        }
      }
    );
  };

  return (
    <Autocomplete
      groupBy={(item) => item.regionByRegionId.name}
      value={value}
      size="small"
      loading={loading}
      multiple
      onChange={(e, value) => onChange && onChange(value)}
      options={sortedOptions.filter((item) =>
        value.every((valueItem) => valueItem.id !== item.id)
      )}
      getOptionLabel={(option) => option?.name}
      fullWidth
      style={{ minWidth: 300 }}
      onInputChange={HandleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select County"
          data-cy="farmers-location-filter-country-select"
        />
      )}
    />
  );
};

export default CountyInput;
