import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    justifyContent: "space-between",
  },
  errorColor: { color: "#ff5252 !important" },
  helperTypeography: {
    lineHeight: "1 !important",
    fontSize: 12,
    margin: "5px 15px",
  },
  errorBorder: {
    "&:focus": {
      boxShadow: "0px 0px 0px 1px #ff5252 !important",
    },
    borderColor: "#ff5252 !important",
  },
});
