// import { Box, Paper } from "@mui/material";
import React from "react";
// import MaterialTable from "material-table";
// import { Schema } from "./schema";
import CustomMessagesHistoryTable from "components/custom-compnents/custom-messages-history-table/custom-messages-history-table";
import { useLogic } from "./messages-history-table.logic";

export interface MessageHistoryTableProps {
  by_farmers_phone?: string;
}

const MessageHistoryTable: React.FC<MessageHistoryTableProps> = ({
  by_farmers_phone,
}) => {
  const { tableProps } = useLogic({ by_farmers_phone });
  return (
    <>
      <CustomMessagesHistoryTable {...tableProps} />
    </>
    // <Box component={Paper} p={2}>
    //   <MaterialTable {...Schema({ by_farmers_phone })} />
    // </Box>
  );
};

export default MessageHistoryTable;
