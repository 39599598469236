import {
  Autocomplete,
  AutocompleteChangeReason,
  TextField,
} from "@mui/material";
import { IMessageCategory } from "models/message-category";
import { IParams, useLogic } from "./message-category.logic";

export type Value =
  | IMessageCategory
  | IMessageCategory[]
  | null
  | (IMessageCategory | IMessageCategory[])[];

export interface MessageCategoryInputProps extends IParams {
  onChange?: (value: Value, reason: AutocompleteChangeReason) => any;
  placeholder?: string;
  fullwidth?: boolean;
  limitTags?: number;
  error?: boolean;
  helperText?: string;
}

const MessageCategoryInput: React.FunctionComponent<MessageCategoryInputProps> =
  (props) => {
    const {
      value: propValue,
      onChange,
      placeholder,
      fullwidth,
      limitTags,
      error,
      helperText,
      ...rest
    } = props;
    const { loading, categories, setsearchTerm, value } = useLogic({
      value: propValue,
      ...rest,
    });

    return (
      <Autocomplete
        limitTags={limitTags}
        fullWidth={fullwidth}
        loading={loading}
        loadingText="Loading ..."
        filterSelectedOptions
        options={
          loading
            ? []
            : categories.concat(
                value ? (Array.isArray(value) ? value : [value]) : []
              )
        }
        getOptionLabel={(provider) =>
          !Array.isArray(provider) ? provider.name : ""
        }
        value={value ? value : Array.isArray(propValue) ? [] : null}
        multiple={Array.isArray(propValue)}
        renderInput={(props) => (
          <TextField
            {...props}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
          />
        )}
        onChange={(e, value, reason) => onChange?.(value, reason)}
        onInputChange={(e, value) => setsearchTerm(value)}
      />
    );
  };

export default MessageCategoryInput;

MessageCategoryInput.defaultProps = {
  placeholder: "Search Category",
  viewType: "all",
};
