import { Box, Chip, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
// import { IoOpenOutline, IoRemoveSharp } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";

export type statusType = "queued" | "sent" | "failed" | "accepted";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
  selectedFarmersIds?: string[];
  selectedByPhone?: boolean;
}
export interface Fields {
  selected: boolean;
  provider: string;
  queued: number;
  accepted: number;
  sent: number;
  failed: number;
  provider_id: string;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onDeleteButtonClick?: (data: Fields) => any;
  onStatusClick?: (providerId: string, Status: statusType) => any;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (params) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "Organization",
      field: "provider",
      hidden: !params.columnState?.find((item) => item.field === "provider")?.checked,
      render: (data) => data.provider,
      sorting: false,
    },
    {
      title: "QUEUED",
      field: "queued",
      hidden: !params.columnState?.find((item) => item.field === "queued")?.checked,
      render: (data) => <Chip onClick={() => params.onStatusClick?.(data.provider_id, "queued")} label={data.queued} variant="outlined" clickable color="default" />,
      sorting: false,
    },
    {
      title: "ACCEPTED",
      field: "accepted",
      hidden: !params.columnState?.find((item) => item.field === "accepted")?.checked,
      render: (data) => <Chip onClick={() => params.onStatusClick?.(data.provider_id, "accepted")} label={data.accepted} variant="outlined" clickable color="info" />,
      sorting: false,
    },
    {
      title: "SENT",
      field: "sent",
      hidden: !params.columnState?.find((item) => item.field === "sent")?.checked,
      render: (data) => <Chip onClick={() => params.onStatusClick?.(data.provider_id, "sent")} label={data.sent} clickable variant="outlined" color="primary" />,
      sorting: false,
    },
    {
      title: "FAILED",
      field: "failed",
      hidden: !params.columnState?.find((item) => item.field === "failed")?.checked,
      render: (data) => <Chip onClick={() => params.onStatusClick?.(data.provider_id, "failed")} label={data.failed} color="error" variant="outlined" clickable />,
      sorting: false,
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: <CustomCheckBox onChange={(e, value) => params.customSelection?.onSelectAllChange?.(params.data, value)} checked={params.data.every((item) => item.selected)} indeterminate={params.data.some((item) => item.selected) && !params.data.every((item) => item.selected)} />,
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => <CustomCheckBox checked={data.selected} onChange={(e, value) => params.customSelection?.onRowSelectChange?.(data, value)} />,
    });
  }

  return {
    // actions: [
    //   (fields) => ({
    //     icon: () => (
    //       <Button
    //         variant="text"
    //         onClick={() => params.onDeleteButtonClick?.(fields)}
    //         color="success"
    //       >
    //         Detach
    //       </Button>
    //     ),
    //     onClick: () => null,
    //   }),
    // ],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
