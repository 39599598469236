import { Button, ButtonProps, Toolbar, Typography } from "@mui/material";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { useStyles } from "./custom-navigation-header.style";

export interface CustomNavigationHeaderProps {
  label?: string;
  backButtonProps?: ButtonProps;
}

const CustomNavigationHeader: React.FunctionComponent<CustomNavigationHeaderProps> =
  (props) => {
    const classes = useStyles();
    const { label, backButtonProps } = props;
    return (
      <Toolbar sx={{ justifyContent: "space-between" }} disableGutters>
        <Typography variant="h6">{label}</Typography>
        <Button
          {...backButtonProps}
          color="inherit"
          className={`${classes.backButtonRoot}`}
          variant="text"
          endIcon={<IoChevronForwardCircleOutline size="25px" />}
        >
          Back
        </Button>
      </Toolbar>
    );
  };

export default CustomNavigationHeader;
