import { GetFarmerLabelsKeys, Resp } from "api/farmer-labels-keys/get";
import { BulkAddFarmersLabels } from "api/farmer-labels/bulk-add";
import { difference, uniq } from "lodash";
import { IFarmerLabel } from "models/farmer-label";
import React from "react";
import readXlsxFile from "read-excel-file";
import { useAppSelector } from "store/hooks";
import DownloadFile from "utils/download-file";
import { AddBulkFarmerLabelsProps } from "./add-bulk-farmer-labels";
import { GetFarmers } from "api/farmer/get-farmer";
import { Farmer } from "models/farmers";
import { BulkValidateFarmersLabels } from "api/farmer-labels/bulk-validate";

interface Params {
  onAdded?: AddBulkFarmerLabelsProps["onAdded"];
}
export const useLogic = (params: Params) => {
  const { onAdded } = params;

  const [dataToUpload, setDataToUplaod] = React.useState<
    Pick<IFarmerLabel, "farmerId" | "value" | "labelKeyId">[]
  >([]);

  const [error, setError] = React.useState("");

  const provider_id = useAppSelector(
    (root) => root.appReducer.user?.provider_id
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleDownloadTemplate = () => {
    DownloadFile(`/files/agriBORA_farmer-label-upload_template.xlsx`);
  };

  const handleUpload = () => {
    setIsSubmitting(true);
    BulkAddFarmersLabels({ labels: dataToUpload }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        setIsSubmitting(false);
        setDataToUplaod([]);
        onAdded?.();
      }
    });
  };

  const handleSetError = (value: string) => setError(value);

  const handleClickUploadButton = () => {
    document.getElementById("labels_input")?.click();
  };

  const handleUploadPrepare = async (files: FileList) => {
    setDataToUplaod([]);
    if (!files || files?.length === 0) return "";
    const fileName = files[0].name;
    const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (extension !== "xlsx" && extension !== "xls") return "Invalid File Type";
    const fileContentAsRows = await readXlsxFile(files[0]);
    if (fileContentAsRows.length === 1) return "Empty File";
    const isFileIndexed = fileContentAsRows[0][0] === "farmer_phone";
    if (!isFileIndexed) return "File is Not Indexed";

    if (fileContentAsRows.length > 501)
      return "Number of entries should be less than 500";
    if (fileContentAsRows.some((item) => !item[0] || !item[1] || !item[2]))
      return "Uploaded file contains empty cells";

    // remove the header and keep only the data
    const fileContentWithoutHeader = fileContentAsRows.filter(
      (item, index) => index !== 0
    );

    await BulkValidateFarmersLabels(
      { providerId: provider_id, fileContent: fileContentWithoutHeader },
      (error, resp) => {
        if (error) {
          setError(error.response?.data.message);
        } else {
          setDataToUplaod(resp?.items!);
        }
      }
    );
  };

  return {
    handleDownloadTemplate,
    handleClickUploadButton,
    handleUploadPrepare,
    error,
    handleSetError,
    dataToUpload,
    handleUpload,
    isSubmitting,
  };
};
