import LogoImage from "assets/images/agribora-logo-cropped.png";
import { Img, ImgProps } from "react-image";

interface LogoProps {
  expandedLogoProps?: Omit<ImgProps, "src" | "alt">;
}

const Logo: React.FunctionComponent<LogoProps> = (props) => {
  return (
    <Img
      src={LogoImage}
      alt="Logo"
      {...props.expandedLogoProps}
      style={{ width: 160, height: 50 }}
    />
  );
};

export default Logo;
