import { Grid, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import CancelSuccessButtonsGroup, {
  CancelSuccessButtonsGroupProps,
} from "../cancel-success-buttons-group/cancel-success-buttons-group";
import CustomDialogV2, {
  CustomDialogV2Props,
} from "../custom-dialog-v2/cusotm-dialog-v2";
import CustomMenuButton from "../custom-menu-button/custom-menu-button";
import CustomMenu from "../custom-menu-component/custom-menu-component";
import CustomSubtitle from "../custom-subtitle/custom-subtitle";
import CustomTextField from "../custom-text-field/custom-text-field";
import React from "react";

interface MenuItem {
  value: string;
  label: string;
}

export interface CustomAddUpdateAggregationEventComponentProps {
  dialogProps: CustomDialogV2Props;
  yearValue?: string;
  monthValue?: string;
  dayValue?: string;
  totalPriceValue?: string;

  quantityValue?: string;
  isVarietyFieldDisabled?: boolean;
  isVarietyFieldEmpty?: boolean;
  isVarietyFieldLoading?: boolean;
  selectCropTitleValue?: string;
  selectVarietyValue?: string;
  selectPaymentTitleValue?: string;
  selectStatusTitleValue?: string;
  onYearChange?: (value: string) => any;
  onMonthChange?: (value: string) => any;
  onDayChange?: (value: string) => any;
  onTotalPriceChange?: (value: string) => any;
  onCropValueChange?: (value: MenuItem) => any;
  onVarietyValueChange?: (value: MenuItem) => any;
  onPaymentValueChange?: (value: MenuItem) => any;
  onStatusValueChange?: (value: MenuItem) => any;
  onQuantityChange?: (value: string) => any;
  CropsMenuItems?: MenuItem[];
  VarietyMenuItems?: MenuItem[];
  CancelSuccessButtonsGroupProps: CancelSuccessButtonsGroupProps;
  paymentMethodMenuItems?: MenuItem[];
  statusMenuItems?: MenuItem[];
  DayErrorHelper?: string;
  YearErrorHelper?: string;
  MonthErrorHelper?: string;
  quantityErrorHelper?: string;
  totalPriceErrorHelper?: string;
  cropErrorHelper?: string;
  varietyErrorHelper?: string;
  paymentMethodErrorHelper?: string;
  statusErrorHelper?: string;
}

const CustomAddUpdateAggregationEventComponent: React.FunctionComponent<CustomAddUpdateAggregationEventComponentProps> =
  (props) => {
    const {
      DayErrorHelper,
      YearErrorHelper,
      MonthErrorHelper,
      dayValue,
      monthValue,
      onCropValueChange,
      onDayChange,
      onMonthChange,
      onPaymentValueChange,
      onStatusValueChange,
      onTotalPriceChange,
      onVarietyValueChange,
      onYearChange,
      selectCropTitleValue,
      selectPaymentTitleValue,
      selectStatusTitleValue,
      selectVarietyValue,
      totalPriceValue,
      yearValue,
      quantityValue,
      isVarietyFieldDisabled,
      isVarietyFieldLoading,
      CancelSuccessButtonsGroupProps,
      VarietyMenuItems,
      CropsMenuItems,
      paymentMethodMenuItems,
      statusMenuItems,
      dialogProps,
      isVarietyFieldEmpty,
      onQuantityChange,
      quantityErrorHelper,
      totalPriceErrorHelper,
      cropErrorHelper,
      paymentMethodErrorHelper,
      statusErrorHelper,
      varietyErrorHelper,
    } = props;
    const cropDetailsData = [
      {
        label: "Select Crop",
        type: "menu",
        value: selectCropTitleValue,
        helperText: cropErrorHelper,
        error: Boolean(cropErrorHelper),
        menuData: CropsMenuItems?.map((item) => ({
          ...item,
          onClick: () => onCropValueChange?.(item),
        })),
      },
      {
        label: "Select Variety",
        helperText: varietyErrorHelper,
        error: Boolean(varietyErrorHelper),
        type: "menu",
        disabledLabel: "Select crop first to select variety",
        emptyLabel: "No Result Found",
        isEmpty: isVarietyFieldEmpty,
        loadingLabel: "Loading ...",
        value: selectVarietyValue,
        disabled: isVarietyFieldDisabled,
        loading: isVarietyFieldLoading,
        menuData: VarietyMenuItems?.map((item) => ({
          ...item,
          onClick: () => onVarietyValueChange?.(item),
        })),
      },
      {
        label: "Quantity (Kg)",
        type: "text",
        value: quantityValue,
        onChange: (e: any) => onQuantityChange?.(e.currentTarget.value),
        helperText: quantityErrorHelper,
        error: Boolean(quantityErrorHelper),
      },
    ];

    const EventDetailsData = [
      {
        label: "Year",
        type: "text",
        helperText: YearErrorHelper,
        error: Boolean(YearErrorHelper),
        gridProps: { xs: 4 },
        value: yearValue,
        onChange: (e: any) => onYearChange?.(e.currentTarget.value),
      },
      {
        label: "Month",
        type: "text",
        helperText: MonthErrorHelper,
        error: Boolean(MonthErrorHelper),
        gridProps: { xs: 4 },
        value: monthValue,
        onChange: (e: any) => onMonthChange?.(e.currentTarget.value),
      },
      {
        label: "Day",
        type: "text",
        helperText: DayErrorHelper,
        error: Boolean(DayErrorHelper),
        gridProps: { xs: 4 },
        value: dayValue,
        onChange: (e: any) => onDayChange?.(e.currentTarget.value),
      },
      {
        label: "Total Price (KSh)",
        helperText: totalPriceErrorHelper,
        error: Boolean(totalPriceErrorHelper),
        type: "text",
        gridProps: { xs: 12 },
        value: totalPriceValue,
        onChange: (e: any) => onTotalPriceChange?.(e.currentTarget.value),
      },
      {
        label: "Select payment method",
        helperText: paymentMethodErrorHelper,
        error: Boolean(paymentMethodErrorHelper),
        type: "menu",
        gridProps: { xs: 12 },
        menuData: paymentMethodMenuItems?.map((item) => ({
          ...item,
          onClick: () => onPaymentValueChange?.(item),
        })),
        value: selectPaymentTitleValue,
      },
      {
        label: "Select Status",
        helperText: statusErrorHelper,
        error: Boolean(statusErrorHelper),
        type: "menu",
        gridProps: { xs: 12 },
        value: selectStatusTitleValue,
        menuData: statusMenuItems?.map((item) => ({
          ...item,
          onClick: () => onStatusValueChange?.(item),
        })),
      },
    ];

    return (
      <CustomDialogV2 header="Add Aggregation Event" {...dialogProps}>
        <Box maxWidth={400}>
          <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <CustomSubtitle label="Crop Details" />
            </Grid>
            {cropDetailsData.map((item, index) => (
              <Grid item xs={12} key={index}>
                {item.type === "menu" && (
                  <CustomMenu menuData={item.menuData}>
                    <CustomMenuButton
                      disabled={item.disabled}
                      helperText={item.helperText}
                      error={item.error}
                    >
                      {item.loading
                        ? item.loadingLabel
                        : item.isEmpty
                        ? item.emptyLabel
                        : item.disabled
                        ? item.disabledLabel
                        : item.value ?? item.label}
                    </CustomMenuButton>
                  </CustomMenu>
                )}
                {item.type === "text" && (
                  <CustomTextField
                    error={item.error}
                    helperText={item.helperText}
                    fullWidth
                    placeholder={item.label}
                    value={item.value}
                    onChange={item.onChange}
                  />
                )}
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} sx={{ mt: 3 }} justifyContent="flex-end">
            <Grid item xs={12}>
              <CustomSubtitle label="Event Details" />
            </Grid>
            {EventDetailsData.map((item) => (
              <Grid item {...item.gridProps}>
                {item.type === "menu" && (
                  <CustomMenu menuData={item.menuData}>
                    <CustomMenuButton
                      error={item.error}
                      helperText={item.helperText}
                    >
                      {item.value ?? item.label}
                    </CustomMenuButton>
                  </CustomMenu>
                )}
                {item.type === "text" && (
                  <CustomTextField
                    helperText={item.helperText}
                    error={Boolean(item.helperText)}
                    onChange={item.onChange}
                    fullWidth
                    placeholder={item.label}
                    value={item.value}
                  />
                )}
              </Grid>
            ))}
            <Grid item xs={9}>
              {CancelSuccessButtonsGroupProps && (
                <CancelSuccessButtonsGroup
                  CancelButtonProps={{
                    label: "Cancel",
                    ...CancelSuccessButtonsGroupProps.CancelButtonProps,
                  }}
                  SuccessButtonProps={{
                    label: "Add Event",
                    ...CancelSuccessButtonsGroupProps.SuccessButtonProps,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </CustomDialogV2>
    );
  };

export default CustomAddUpdateAggregationEventComponent;

CustomAddUpdateAggregationEventComponent.defaultProps = {
  paymentMethodMenuItems: [
    { label: "Cash", value: "cash" },
    { label: "Mpesa ", value: "mpesa" },
  ],
  statusMenuItems: [
    { label: "Draft", value: "draft" },
    {
      label: "Awaiting Quality Checks",
      value: "awaiting_quality_checks",
    },
    {
      label: "Awaiting Payment",
      value: "awaiting_payment",
    },
    { label: "Done", value: "done" },
  ],
};
