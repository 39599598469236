import { blue } from "@mui/material/colors";
import moment from "moment";
import * as React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { GetDataset } from "api/meteoblue/get-dataset";

export interface PrecipitationChartProps {
  lat: number;
  lng: number;
}

const PrecipitationChart: React.SFC<PrecipitationChartProps> = ({
  lat,
  lng,
}) => {
  const [ERA5ModalData, setERA5ModalData] = React.useState<
    { name: string; "ERA-5": number }[]
  >([]);
  const [CHIRPS2ModalData, setCHIRPS2ModalData] = React.useState<
    { name: string; CHIRPS2: number }[]
  >([]);
  const CurrentDate = new Date(Date.now());
  const DateBeforeThreeYear = new Date(Date.now());
  DateBeforeThreeYear.setFullYear(CurrentDate.getFullYear() - 2);
  React.useEffect(() => {
    GetDataset(
      {
        format: "json",
        timeIntervalsAlignment: "none",
        units: {
          energy: "watts",
          length: "metric",
          temperature: "C",
          velocity: "km/h",
        },
        geomtry: { type: "MultiPoint", coordinates: [[lat, lng, 279]] },
        models: [
          {
            domain: "ERA5T",
            gapFillDomain: null,
            timeResolution: "hourly",
            codes: [
              {
                code: 193,
                level: "2 m elevation corrected",
              },
            ],
            transformations: [
              {
                type: "aggregateMonthly",
                aggregation: "mean",
              },
            ],
          },
          {
            domain: "CHIRPS2",
            gapFillDomain: null,
            timeResolution: "daily",
            codes: [
              {
                code: 61,
                level: "sfc",
              },
            ],
            transformations: [
              {
                type: "aggregateMonthly",
                aggregation: "mean",
              },
            ],
          },
        ],
        timeIntervals: [{ start: DateBeforeThreeYear, end: CurrentDate }],
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setERA5ModalData(
            resp.Data[0].codes[0].dataPerTimeInterval[0].data[0].map(
              (value, index) => ({
                "ERA-5": value,
                name: resp.Data[0].timeIntervals[0][index],
              })
            )
          );

          setCHIRPS2ModalData(
            resp.Data[1].codes[0].dataPerTimeInterval[0].data[0].map(
              (value, index) => ({
                CHIRPS2: value,
                name: resp.Data[1].timeIntervals[0][index],
              })
            )
          );
        }
      }
    );
  }, []);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={ERA5ModalData.map((item) => ({
          name: moment(item.name).format("MMM YYYY"),
          "ERA-5": item["ERA-5"]?.toFixed(1),
          CHIRPS2: CHIRPS2ModalData.find(
            (CHIRPS2item) => CHIRPS2item.name === item.name
          )?.CHIRPS2?.toFixed(1),
        }))}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis
          label={{
            angle: -90,
            position: "insideMiddleLeft",
            value: "Total Precipitation (mm)",
          }}
        />
        <Tooltip />
        <Legend layout="vertical" verticalAlign="middle" align="right" />
        <Bar dataKey="ERA-5" fill={`#5d6d6e`} />
        <Bar dataKey="CHIRPS2" fill="#ff8373" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PrecipitationChart;
