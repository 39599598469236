import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Service } from "../../models/service";

export interface Params extends Partial<Service> {
  subscribe_texts?: string;
  confirmation_message?: string;
}


type UpdateServiceType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | Service) => any
) => any;
const UpdateService: UpdateServiceType = (Params, callback) => {
  AxiosInstance.patch("/services/" + Params.id, { ...Params })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export { UpdateService };
