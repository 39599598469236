import {
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import useStyles from "./side-menu-list-item.style";
import { IoChevronDownOutline, IoChevronForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import React from "react";

export interface SideMenuListItemProps {
  isActive?: boolean;
  title?: string;
  icon?: React.ElementType;
  iconActive?: React.ElementType;
  onClick?: () => any;
  hideArrow?: boolean;
  isOpenedArrow?: boolean;
  href?: string;
}

const SideMenuListItem: React.FunctionComponent<SideMenuListItemProps> = (
  props
) => {
  const classes = useStyles();
  const {
    isActive,
    title,
    icon: Icon,
    iconActive: IconActive,
    onClick,
    hideArrow,
    isOpenedArrow,
    href,
  } = props;

  const LinkComponent = href ? Link : React.Fragment;

  return (
    <LinkComponent to={href as any}>
      <ListItemButton
        onClick={onClick}
        className={`${classes.root} ${isActive && classes.rootSelected}`}
      >
        <ListItemIcon
          className={`${classes.iconWrapperRoot} ${
            isActive && classes.iconWrapperSelected
          }`}
        >
          {isActive
            ? IconActive && <IconActive size={22} />
            : Icon && <Icon size={22} />}
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            className: `${classes.primaryTextRoot} ${
              isActive && classes.primaryTextSelected
            }`,
          }}
        />
        {!hideArrow && (
          <ListItemSecondaryAction
            className={`${classes.actionWrapperRoot} ${
              isActive && classes.actionWrapperSelected
            }`}
          >
            {isOpenedArrow ? (
              <IoChevronDownOutline size={20} />
            ) : (
              <IoChevronForwardOutline size={20} />
            )}
          </ListItemSecondaryAction>
        )}
      </ListItemButton>
    </LinkComponent>
  );
};

export default SideMenuListItem;
