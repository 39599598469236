import { CustomFarmsTableProps } from "components/custom-compnents/custom-farms-table/custom-farms-table";
import { IFarm } from "models/farm";
import React from "react";
import { GetFarmsBySchema } from "./api";
import { debounce } from "lodash";

export interface IParams {
  by_farmer_id?: string;
}
export const useLogic = (params: IParams) => {
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [data, setData] = React.useState<IFarm[]>([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [columnsState, setColumnsState] = React.useState([
    { field: "name", checked: true },
    { field: "current_crop", checked: true },
    { field: "status", checked: true },
  ]);

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchTextChange = debounce((value: string) => setSearchText(value), 900);

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetFarmsBySchema(
      {
        page: currentPage,
        name: searchText,
        pageSize: perPage,
        farmer_id: params?.by_farmer_id ? params.by_farmer_id : undefined,
        with_last_planted_crop: true
      },
      (error, resp) => {
        if (resp) {
          setIsLoading(false);
          //   resolve({ data: resp.Farms, totalCount: resp.totalCount, page });
          setTotalCount(resp.totalCount);
          setData(resp.Farms);
        } else if (error) {
          console.log(error.message);
        }
      }
    );
  }, [dataUpdateIndex, perPage, currentPage, searchText]);

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  const tableProps: CustomFarmsTableProps = {
    isLoading: isLoading,
    data: preparedData,
    actionBarProps: {
      columnsState,
      isShowColumnButton: true,
      onColumnsStateChange: handleColumnStateChange,
      isShowSearchTextField: true,
      searchTextFieldProps: {
        onChange: (e) => handleSearchTextChange(e.currentTarget.value),
      },
    },
    countLabelProps: {
      count: totalCount,
    },
    pagnationProps: {
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      total: totalCount,
      from: perPage * currentPage,
      rowsPerPage: perPage,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
    },
  };
  return { tableProps };
};
