import { Box, Button, ButtonProps, ListItem, Paper } from "@mui/material";
import * as React from "react";
import ConstituencyInput, {
  ConstituencyInputProps,
} from "./constituency-input/constituency-input";
import CountyInput, { CountyInputProps } from "./county-input/county-input";
import RegionInput from "./region-input/region-input";
import WardInput, { WardInputProps } from "./ward-input/WardInput";
import {
  DirectMessageLocationFilterState,
  ProvideDirectMessageLocationFilterStateTo,
} from "store/reducers/location-filter-input-slice/direct-message-location-filter-reducer";
import { Region } from "models/region";
import { County } from "models/county";
import { Constituency } from "models/constituency";
import { Ward } from "models/ward";
import { RegionLayerProps } from "components/pages/farmers-analytics-screen/analytics-map/LayerRegions";

export interface FilterMenuProps {
  onSetFilter?: (FilterState: FilterState) => void;
  onResetFilter?: () => void;
  DirectMessageLocationFilterState: DirectMessageLocationFilterState;
  SetDirectMessageLocationFilterState: any;
}

export interface FilterState {
  SelectedRegions: Array<Region>;
  SelectedCounties: Array<County>;
  SelectedConstituencies: Array<Constituency>;
  SelectedWards: Array<Ward>;
}

const FilterMenu: React.FC<FilterMenuProps> = ({
  onSetFilter,
  onResetFilter,
  DirectMessageLocationFilterState,
  SetDirectMessageLocationFilterState,
}) => {
  // React.useEffect(() => {
  //   SetDirectMessageLocationFilterState({
  //     SelectedCounties:
  //       DirectMessageLocationFilterState.SelectedCounties.filter((item) => {
  //         const so = DirectMessageLocationFilterState.SelectedRegions.some(
  //           (RegionItem: Region) => {

  //             console.log(RegionItem, 'RegionItem', item, 'item')
  //             return RegionItem.regionId === item.regionId;
  //           }
  //         );
  //         return so;
  //       }
  //       ),
  //   });
  // }, [DirectMessageLocationFilterState.SelectedRegions]); // change selected counties when ever Regions Tags Change

  // React.useEffect(() => {
  //   SetDirectMessageLocationFilterState({
  //     SelectedConstituencies:
  //       DirectMessageLocationFilterState.SelectedConstituencies.filter(
  //         (item) =>
  //           DirectMessageLocationFilterState.SelectedCounties.some(
  //             (CountyItem: County) => CountyItem.countyId === item.countyId
  //           ) ||
  //           DirectMessageLocationFilterState.SelectedRegions.some(
  //             (RegionItem: Region) => RegionItem.regionId === item.countyByCountyId.regionId
  //           )
  //       ),
  //   });
  // }, [
  //   DirectMessageLocationFilterState.SelectedRegions,
  //   DirectMessageLocationFilterState.SelectedCounties,
  // ]); // change selected Constituencies when ever Regions or Counties Tags Change

  // React.useEffect(() => {
  //   SetDirectMessageLocationFilterState({
  //     SelectedWards: DirectMessageLocationFilterState.SelectedWards.filter(
  //       (item: Ward) =>
  //         DirectMessageLocationFilterState.SelectedRegions.some(
  //           (RegionItem: Region) =>
  //             RegionItem.regionId === item.constituencyByConstituencyId.countyByCountyId.regionId
  //         ) ||
  //         DirectMessageLocationFilterState.SelectedCounties.some(
  //           (CountyItem: County) =>
  //             CountyItem.countyId === item.constituencyByConstituencyId.countyId
  //         ) ||
  //         DirectMessageLocationFilterState.SelectedConstituencies.some(
  //           (ConstituenciesItem: Constituency) =>
  //             ConstituenciesItem.constituencyId === item.constituencyId
  //         )
  //     ),
  //   });
  // }, [
  //   DirectMessageLocationFilterState.SelectedRegions,
  //   DirectMessageLocationFilterState.SelectedCounties,
  //   DirectMessageLocationFilterState.SelectedConstituencies,
  // ]); // change selected wards when ever Regions or Counties or Constituencies Tags Change

  const regionInputProps = {
    onChange: (value: any) =>
      SetDirectMessageLocationFilterState({ SelectedRegions: value }),
    options: DirectMessageLocationFilterState.AvailableRegions,
    OnOptionsChange: (value: any) =>
      SetDirectMessageLocationFilterState({ AvailableRegions: value }),
    value: DirectMessageLocationFilterState.SelectedRegions,
  };

  const countyInputProps: CountyInputProps = {
    options: DirectMessageLocationFilterState.AvailableCounties,
    onChange: (value) =>
      SetDirectMessageLocationFilterState({ SelectedCounties: value }),
    OnOptionsChange: (value) =>
      SetDirectMessageLocationFilterState({ AvailableCounties: value }),
    SelectedRegions: DirectMessageLocationFilterState.SelectedRegions,
    value: DirectMessageLocationFilterState.SelectedCounties,
  };
  const constituencyInputProps: ConstituencyInputProps = {
    value: DirectMessageLocationFilterState.SelectedConstituencies,
    options: DirectMessageLocationFilterState.AvailableConstituencies,
    onChange: (value) =>
      SetDirectMessageLocationFilterState({ SelectedConstituencies: value }),
    SelectedRegions: DirectMessageLocationFilterState.SelectedRegions,
    SelectedCounties: DirectMessageLocationFilterState.SelectedCounties,
    onOptionsChange: (value) =>
      SetDirectMessageLocationFilterState({ AvailableConstituencies: value }),
  };

  const HandleFilterReset = () => {
    SetDirectMessageLocationFilterState({
      SelectedConstituencies: [],
      SelectedCounties: [],
      SelectedRegions: [],
      SelectedWards: [],
      FilledButton: false,
    });
    onResetFilter!();
  };

  const wardInputProps: WardInputProps = {
    value: DirectMessageLocationFilterState.SelectedWards,
    options: DirectMessageLocationFilterState.AvailableWards,
    onChange: (value) =>
      SetDirectMessageLocationFilterState({ SelectedWards: value }),
    onOptionsChange: (value) =>
      SetDirectMessageLocationFilterState({ AvailableWards: value }),
    SelectedConstituency:
      DirectMessageLocationFilterState.SelectedConstituencies,
    SelectedCounties: DirectMessageLocationFilterState.SelectedCounties,
    SelectedRegions: DirectMessageLocationFilterState.SelectedRegions,
  };

  const SetFilterButtonProps: ButtonProps = {
    disabled:
      DirectMessageLocationFilterState.SelectedCounties.length == 0 &&
      DirectMessageLocationFilterState.SelectedRegions.length == 0 &&
      DirectMessageLocationFilterState.SelectedWards.length == 0 &&
      DirectMessageLocationFilterState.SelectedConstituencies.length == 0,
    size: "small",
    fullWidth: true,
    variant: "contained",
    color: "success",
    onClick: () => {
      onSetFilter!({
        SelectedConstituencies:
          DirectMessageLocationFilterState.SelectedConstituencies,
        SelectedCounties: DirectMessageLocationFilterState.SelectedCounties,
        SelectedRegions: DirectMessageLocationFilterState.SelectedRegions,
        SelectedWards: DirectMessageLocationFilterState.SelectedWards,
      });
      SetDirectMessageLocationFilterState({ open: false, filledButton: true });
    },
  };

  console.log(
    DirectMessageLocationFilterState,
    "DirectMessageLocationFilterState"
  );

  const ResetButtonProps: ButtonProps = {
    size: "small",
    fullWidth: true,
    variant: "outlined",
    color: "secondary",
    onClick: HandleFilterReset,
  };
  return (
    <Box minWidth={300}>
      <ListItem>
        <RegionInput {...regionInputProps} />
      </ListItem>
      <ListItem>
        <CountyInput {...countyInputProps} />
      </ListItem>
      <ListItem>
        <ConstituencyInput {...constituencyInputProps} />
      </ListItem>
      <ListItem>
        <WardInput {...wardInputProps} />
      </ListItem>
      <ListItem>
        <Button
          {...SetFilterButtonProps}
          data-cy="farmers-location-filter-apply-button"
        >
          Apply
        </Button>
        &nbsp;
        <Button {...ResetButtonProps}>Clear</Button>
      </ListItem>
    </Box>
  );
};

export default ProvideDirectMessageLocationFilterStateTo(FilterMenu, true);
