import { AxiosInstance } from "configs/axios";
const humanize = require("humanize-graphql-response");

export interface Crop {
  name: string;
}
export interface FarmActivity {
  cropByCropId: Crop;
}

export interface Farmer {
  id: string;
  first_name: string;
  last_name: string;
}
export interface Farm {
  id: string;
  ownedByFarmer: null | string;
  name: string;
  size: number;
  lat: number;
  lng: number;
  LastFarmActivity: FarmActivity;
  farmer: Farmer;
}


interface Params {
  farm_id?: string;
  farmer_id?: string;
  page?: number;
  pageSize?: number;
  provider_id?: string;
  name?: string;
  with_providers?: boolean;
  with_farmer?: boolean;
  with_last_planted_crop?: boolean;
}

const GetFarmInfoByIds = (
  Params: Params,
  callback: (data: Farm[]) => void
) => {
 
  AxiosInstance.get("/farms/", { params: Params})
    .then((resp) => {
      let HumanizedData = humanize(resp.data.data.all_farms).nodes;
      HumanizedData = HumanizedData.map((item: any) => ({
        ...item,
        id: item.id,
        name: item.name,
        farmer: item.farmer,
        ownedByFarmer: item.owned_by_farmer,
        LastFarmActivity: {cropByCropId: item.last_planted_crop},
      }));
      callback(HumanizedData);
    })
    .catch((error) => console.log(error));
};

export { GetFarmInfoByIds };
