import { Box, Typography, useTheme } from "@mui/material";
import { FunctionComponent } from "react";
import { IoWalletOutline } from "react-icons/io5";
import CustomInfoCardWithIcon from "../custom-info-card-with-icon/custom-info-card-with-icon";

export interface BalanceHolderProps {
  value?: number;
  hideLabel?: boolean;
  variant?: "raw" | "card";
  title?: string;
}

const BalanceHolder: FunctionComponent<BalanceHolderProps> = (props) => {
  const { value, variant, hideLabel, title } = props;

  const theme = useTheme();

  return variant === "raw" ? (
    <Box display="flex" alignItems="center">
      <IoWalletOutline
        color={theme.palette.success.main}
        size={30}
        style={{ margin: 12 }}
      />

      <Box flexShrink={0}>
        <Typography height={16} variant="subtitle2">
          {value} KSh.
        </Typography>
        {!hideLabel && (
          <Typography variant="caption" color="GrayText">
            {title}
          </Typography>
        )}
      </Box>
    </Box>
  ) : (
    <CustomInfoCardWithIcon
      icon={IoWalletOutline}
      titleText={title}
      primaryText={`${value} KSh.`}
      primaryTextTestId="balance-holder-value"
    />
  );
};

export default BalanceHolder;

BalanceHolder.defaultProps = {
  variant: "raw",
  title: "agriWALLET",
};
