import { useEffect } from "react";
import L from "leaflet";
import "assets/css/legend.css";
import { useMap } from "react-leaflet";

function Legend() {
  const map = useMap();
  const getColor = ["red","yellow","white"];

  useEffect(() => {
    if (map) {
      const legend = new L.Control({ position: "bottomright" });

      legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");

        const labels = ["Maize","Beans","Border"];
        const view: string[] = [];

        for (let i = 0; i < labels.length; i++) {
          view.push('<i style="background:' + getColor[i] + '"></i> ' + labels[i]);
        }

        div.innerHTML = view.join("</br>");

        return div;
      };

      legend.addTo(map);
    }
  }, [map]);
  return null;
}

export default Legend;
