import { Box } from "@mui/material";
import UsersTable from "components/data-tables/users-table/users-table";

interface UsersScreenProps {}

const UsersScreen: React.FunctionComponent<UsersScreenProps> = () => {
  return (
    <Box>
      <UsersTable />
    </Box>
  );
};

export default UsersScreen;
