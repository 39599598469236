import { AxiosError } from "axios";
import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { IFarm } from "models/farm";
const humanize = require("humanize-graphql-response");

interface params {
  id?: string;
  ids?: string[];
  farmer_ids?: string[];
  farmer_id?: string;
  page?: number;
  pageSize?: number;
  CountyIdList?: string[];
  WardIdList?: string[];
  ConstituencieIdList?: string[];
  RegionIdList?: string[];
  deleted?: boolean | "-";
  ProviderIdList?: string[];
}
interface Response {
  totalCount: number;
  Farms: IFarm[];
}
const get_Farms = (
  params: params,
  callback: (error: AxiosError | null, resp: Response | null) => any
) => {
 
  AxiosInstance.get("/farms/", { params})
    .then((resp) => {
      callback(null, {
        totalCount: resp.data.data.all_farms.total_count,
        Farms: humanize(resp.data.data.all_farms).nodes,
      })
    })
    .catch((error) => callback(error, null));
};

export { get_Farms };
