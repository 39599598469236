import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    slider: {
        '& .slick-list': {
            padding: '5px 0'
        }
    }
});

export default useStyles;
