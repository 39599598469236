import { Typography } from "@mui/material";
import { TypographyProps } from "@mui/system";

interface LabelTextProps extends TypographyProps {}

const LabelText: React.FunctionComponent<LabelTextProps> = (props) => {
  const style = {
    opacity: 0.65,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#5d6d6e",
  };
  return <Typography {...props} style={style as any} />;
};

export default LabelText;
