import cleanDeep from "clean-deep";
import React from "react";
import { AddFarmers, BulkFarmer } from "../../../api/farmer/add-farmers";
import { AddBulkFarmers } from "api/farmer/add-bulk-farmers";

interface FarmersListValues {
  phone: string;
  firstName: string;
  lastName: string;
  ward_id?: string;
  gender?: string;
  birthYear?: string;
}
const SubmitBulkUpload = (
  FarmersListValues: FarmersListValues[],
  SetIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  SetFarmersListValues: React.Dispatch<
    React.SetStateAction<FarmersListValues[]>
  >,
  OnFarmerAdded?: () => any,
  SetErrorArray?: React.Dispatch<React.SetStateAction<string[]>>
) => {
  SetIsSubmitting(true);
  AddBulkFarmers(
    {
      farmers: FarmersListValues.map((item) => {

        return {
          first_name: item.firstName,
          last_name: item.lastName,
          birth_year: item.birthYear ?
            !isNaN(parseInt(item.birthYear)) ?
              parseInt(item.birthYear) :
              undefined :
            undefined,
          phone:
            item.phone.toString().indexOf("+") == 0
              ? item.phone
              : "+" + item.phone,
          gender: item.gender as any,
          ward_id: item.ward_id
        } as BulkFarmer
      })
    },
    (error, resp) => {
      if (error) {
        SetErrorArray && SetErrorArray(error.response?.data.message as any)
      } else if (resp) {
        SetIsSubmitting(false);
        SetFarmersListValues([]);
        OnFarmerAdded?.();
      }
    }
  );
};

export default SubmitBulkUpload;
