import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Sender } from "../../models/sender";
interface Abstract {
  provider_id: string;
  at_sender_id: string;
  status: boolean;
  id: string;
}

interface AfParams {
  type: "af";
  at_sender_id: string;
  at_auth_key: string;
  at_username: string;
}

interface InfobipParams {
  type: "infobip";
  phone_number: string;
  base_url: string;
  infobip_auth_key: string;
}

export type Params = Abstract & (AfParams | InfobipParams);

type UpdateSenderType = (Params: Params, callback: (error: null | AxiosError, Resp: null | Sender) => any) => any;
const UpdateSender: UpdateSenderType = (Params, callback) => {
  AxiosInstance.put("/sender_ids/" + Params.id, { ...Params })
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { UpdateSender };
