import { DeleteFarmerLabelKey } from "api/farmer-labels-keys/delete-farmer-label-key";
import React from "react";

export interface IParams {
  onDeleted?: () => any;
  id: string;
}
export const useLogic = (params: IParams) => {
  const [isSubmittingDelete, setiIsSubmittingDelete] = React.useState(false);
  const { onDeleted, id } = params;

  const handleDeleteConfirm = () => {
    setiIsSubmittingDelete(true);

    DeleteFarmerLabelKey({ id }, (error, resp) => {
      if (error) {
        console.log(error);
      } else {
        setiIsSubmittingDelete(false);
        onDeleted?.();
      }
    });
  };

  return { isSubmittingDelete, handleDeleteConfirm };
};
