import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  primaryTextRoot: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
    fontStretch: "normal !important",
    fontStyle: "normal !important",
    lineHeight: "1.39 !important",
    letterSpacing: "normal !important",
    color: "#000 !important",
  },
  actionTextRoot: {
    fontFamily: "Roboto !important",
    fontSize: "36px !important",
    fontWeight: "500 !important",
    fontStretch: "normal !important",
    fontStyle: "normal !important",
    lineHeight: "0.69 !important",
    letterSpacing: "normal !important",
    color: "#000 !important",
  },
  iconRoot: {
    color: "#44b16e",
  },
  iconWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minWidth: 0,
  },
  root: {
    padding: 4,
    paddingInline: 24,
  },
  dividerRoot: {
    height: "60px !important",
    marginInline: "12px !important",
    marginBlock: "10px !important",
  },
  primaryAreaRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginInline: "10px !important",
  },
  titleTextRoot: {
    opacity: 0.65,
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
    fontWeight: "normal !important",
    fontStretch: "normal !important",
    fontStyle: "normal !important",
    lineHeight: "2.2 !important",
    letterSpacing: "normal !important",
    color: "#5d6d6e",
  },
});
