import { IoCheckmarkDoneSharp } from "react-icons/io5";
import ShinyButton, { ShinyButtonProps } from "../shiny-button/shiny-button";

interface CustomShinySelectedButtonProps extends ShinyButtonProps {}

const CustomShinySelectedButton: React.FunctionComponent<CustomShinySelectedButtonProps> =
  (props) => {
    return <ShinyButton startIcon={<IoCheckmarkDoneSharp />} {...props} />;
  };

export default CustomShinySelectedButton;

CustomShinySelectedButton.defaultProps = {
  children: "only Selected",
};
