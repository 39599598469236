import { GetFarmerLabelsDistinct } from "api/farmer-labels/get-distinct";
import { useDebounce } from "hooks/debounce-hook";
import { useEffect, useState } from "react";
import { Value } from "./labels-key-input";
import { GetFarmerLabelsKeys } from "api/farmer-labels-keys/get";

export const useLogic = () => {
  const [options, setOptions] = useState<Value[]>([]);

  const [searchTerm, setSearchTerm] = useState("");

  const [page, setPage] = useState(1);

  const [isLastResult, setisLastResult] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const [isLoading, setIsLoading] = useState(true);

  const [totalCount, setTotalCount] = useState(0);

  // reset pagenation if search or key_id changes
  useEffect(() => {
    setPage(1);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setIsLoading(true);
    GetFarmerLabelsKeys(
      {
        key: debouncedSearchTerm ? debouncedSearchTerm : undefined,
        page: page - 1,
        pageSize: 10,
      },
      (error, resp) => {
        if (error) console.log(error);
        else if (resp) {
          if (resp.items.length < 10) setisLastResult(true);
          if (resp.items.length >= 10) setisLastResult(true);
          setTotalCount(resp.totalCount);
          setIsLoading(false);
          setOptions(
            resp.items.map((item) => ({ value: item.id, label: item.key }))
          );
        }
      }
    );
  }, [debouncedSearchTerm, page]);

  return {
    options,
    setSearchTerm,
    isLoading,
    isLastResult,
    totalCount,
    page,
    setPage,
  };
};
