// import { Box, Paper } from "@mui/material";
// import MaterialTable from "material-table";
import React from "react";
import { useLogic } from "./broadcasts-table.logic";
import CustomBroadcastsTable from "components/custom-compnents/custom-broadcasts-table/custom-broadcasts-table";
import AddUpdateBroadcast from "components/standalone-components/add-update-broadcast/add-update-broadcast";
// import { Schema } from "./schema";

interface BroadcastsTableProps {}

const BroadcastsTable: React.FC<BroadcastsTableProps> = () => {
  // const HandleAddBroadcastClicked = () => SetOpenAddBroadcastDialog(true);
  // const HandleCancelAddBroadcast = () => SetOpenAddBroadcastDialog(false);
  // const HandleAddedBroadcast = () => {
  //   SetOpenAddBroadcastDialog(false);
  //   window.location.reload();
  // };
  // const [OpenAddBroadcastDialog, SetOpenAddBroadcastDialog] =
  //   React.useState(false);

  const { tableProps, addBroadcastDilaogProps } = useLogic();

  return (
    // <Box component={Paper} p={2}>
    //   <AddUpdateBroadcast
    //     UserProviderId={UserProviderId}
    //     UserMenurules={Usermenuroles}
    //     open={OpenAddBroadcastDialog}
    //     OnCancel={HandleCancelAddBroadcast}
    //     OnAdded={HandleAddedBroadcast}
    //     Variant="Add"
    //   />
    //   <MaterialTable
    //     {...Schema({ OnAddBroadcastClicked: HandleAddBroadcastClicked })}
    //   />
    // </Box>
    <>
      <AddUpdateBroadcast {...addBroadcastDilaogProps} />
      <CustomBroadcastsTable {...tableProps} />
    </>
  );
};

export default BroadcastsTable;
