import { AxiosInstance } from "configs/axios";
import { IMessageTemplate } from "models/message-template";
import { MessageType } from "components/pages/send-message-screen/send-message-screen.logic";
import { AxiosError } from "axios";
import { Fields } from "components/custom-compnents/custom-whatsapp-messages-templates-table/custom-whatsapp-messages-template-table.schema";

export interface Params {
  page: number;
  pageSize: number;
  id?: string;
  name?: string;
  templateType?: MessageType;
  // provider_id: string;
  // access_level?: string;
  with_categories?: Boolean;
  without_approval?: boolean;
}

interface Resp {
  total_count: number;
  items: Fields[];
}

type FindAllTemplatesType = (Params: Params, callback: (error: AxiosError | null, Response: null | Resp) => void) => any;

export const findAll: FindAllTemplatesType = async (params, callback) => {
  return AxiosInstance.get("/templates", { params })
    .then((resp) => {
      callback(null, {
        total_count: resp.data.data.all_templates.total_count,
        items: resp.data.data.all_templates.nodes,
      });
    })
    .catch((err) => callback(err, null));
};
