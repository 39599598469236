import { CustomDevicesTableProps } from "components/custom-compnents/custom-devices-table/custom-devices-table";
import { IDevice } from "models/device";
import { useState, useEffect } from "react";
import { Get_devices } from "api/device/get-devices";
import { AttachDeviceDialogProps } from "components/standalone-components/Attach-Device-Dialog/Attach-Device-Dialog";
import { Farmer } from "models/farmers";
import { DetachDeviceConfirmModalProps } from "components/standalone-components/Detach-Device-Dialog/DetachConfirmDialog";
import { debounce } from "lodash";

interface IParams {
  farmer?: Farmer;
  assigned_devices?: boolean;
}

export const useLogic = (params: IParams) => {
  const { assigned_devices, farmer } = params;
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<IDevice[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  //   const [showFilterArea, setShowFilterArea] = useState(false);
  const [dataUpdateIndex, setDataUpdateIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isOpenAttachDevice, setIsOpenAttachDevice] = useState(false);

  const [DetachDeviceInfo, SetDetachDeviceInfo] = useState<IDevice | undefined>(undefined);
  const [columnsState, setColumnsState] = useState([
    { checked: true, field: "imei" },
    { checked: true, field: "phone_number" },
    { checked: true, field: "assigned_farmer_id" },
    { checked: true, field: "last_location" },
    { checked: true, field: "last_battery_level" },
  ]);

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handleNextPageClick = () => {
    if (totalCount >= perPage * (currentPage + 1)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPageButtonClick = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const handleDeviceAttachClick = () => setIsOpenAttachDevice(true);

  const handleOnChange = (field: string, checked: boolean) => {
    const targetedItemIndex = columnsState.findIndex((item) => item.field === field);
    const FilterdArray = columnsState.map((item) => item);
    FilterdArray[targetedItemIndex] = {
      ...FilterdArray[targetedItemIndex],
      checked,
    };

    setColumnsState(FilterdArray);
  };

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  useEffect(() => {
    setIsLoadingData(true);
    Get_devices(
      {
        pageSize: perPage,
        page: currentPage + 1,
        farmer_id: farmer?.id,
        imei: searchText || undefined,
        assigned_devices,
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setData(resp.items);
          setTotalCount(resp.total);
          setIsLoadingData(false);
        }
      }
    );
  }, [dataUpdateIndex, perPage, currentPage, farmer?.id, assigned_devices, searchText]);

  const HandleIgnore = () => setIsOpenAttachDevice(false);
  const HandleAttached = () => {
    setIsOpenAttachDevice(false);
    refreshData();
  };

  const handleSearchTextChange = debounce((value: string) => {
    setSearchText(value);
    setCurrentPage(0);
  }, 900);

  const devicesTableProps: CustomDevicesTableProps = {
    data: preparedData,
    isLoading: isLoadingData,
    onDeleteButtonClick: (data) => SetDetachDeviceInfo(data),
    actionBarProps: {
      columnsState: columnsState,

      addButtonProps: {
        onClick: handleDeviceAttachClick,
      },
      onColumnsStateChange: handleOnChange,
      isShowColumnButton: true,
      isShowCreateButton: !!farmer,
      isShowSearchTextField: true,
      searchTextFieldProps: {
        onChange: (e) => handleSearchTextChange(e.currentTarget.value),
      },
    },
    pagnationProps: {
      from: currentPage * perPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      rowsPerPage: perPage,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageButtonClick,
    },
    countLabelProps: { count: totalCount },
  };

  const AttachDeviceDialogProps: AttachDeviceDialogProps = {
    show: isOpenAttachDevice,
    OnIgnore: HandleIgnore,
    onAttached: HandleAttached,
    Farmer: farmer,
  };

  const DetachDeviceConfirmModalProps: DetachDeviceConfirmModalProps = {
    open: Boolean(DetachDeviceInfo),
    OnIgnore: () => SetDetachDeviceInfo(undefined),
    OnDetach: () => {
      refreshData();
      SetDetachDeviceInfo(undefined);
    },
    Device: DetachDeviceInfo,
  };

  return {
    devicesTableProps,
    AttachDeviceDialogProps,
    DetachDeviceConfirmModalProps,
  };
};
