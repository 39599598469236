import { AxiosInstance } from "configs/axios";
import cleanDeep from "clean-deep";

export interface IParams {
  // cropName: string;
  // farmFilter?: {
  //   farmerByFarmerId?: {
  //     providerOptinsByFarmerId?: {
  //       some?: {
  //         providerId?: { equalTo?: string };
  //       };
  //     };
  //     wardByWardId?: {
  //       wardId?: { in?: string[] };
  //       constituencyId?: { in?: string[] };
  //       constituencyByConstituencyId?: {
  //         countyId?: { in?: string[] };
  //         countyByCountyId?: { regionId?: { in?: string[] } };
  //       };
  //     };
  //   };
  // };
  provider_id?: string;
}

export interface IResp {
  data: number;
}

export const getEstimatedAggregatedyield = async (Params: IParams) => {
//   const { cropName, farmFilter } = params;

//   const graphQL = `
//   query EstimatedMaizeyieldKPI($farmFilter:FarmFilter){
//     allFarms(filter:$farmFilter){
     
//       nodes{
//         farmActivitiesByFarmId(filter:{activity: { includesInsensitive: "harvesting" },cropByCropId:{name:{equalToInsensitive:"${cropName}"}}},  orderBy: LAST_MODIFIED_DESC,
//                 first: 1){
//        nodes{
//         harvestingQuantityKg
//       }
//         }
//       }
//     }
//   }
// `;

//   const variables = cleanDeep({
//     farmFilter,
//   });

  return await AxiosInstance.get("/farmer-analytics/find-estimated-maize-yield-kpi", { params: Params }).then((resp) => {
    // resp.data = (resp.data as IResp).allFarms.nodes.reduce((prev, curr) => prev + curr.farmActivitiesByFarmId.nodes.reduce((activity_prev, activity_cur) => activity_prev + (activity_cur?.harvestingQuantityKg ?? 0), 0), 0) as number;
    // TODO: fix when backend function is ready
    return { data: -1};
    // return resp.data.data.cube[0].farms.unique_farms_count;
  });
};
