import { Box, ButtonBase, TextField } from "@mui/material";
import CustomBaseInput from "components/custom-compnents/custom-base-input/custom-base-input";
import CustomMenu from "components/custom-compnents/custom-menu-component/custom-menu-component";
import * as React from "react";
import {
  FaEquals,
  FaGreaterThan,
  FaGreaterThanEqual,
  FaLessThan,
  FaLessThanEqual,
} from "react-icons/fa";
import { useStyle } from "./input-with-operator.style";

export interface InputWithOperatorElementValue {
  value?: string;
  operator?: string;
}
export interface InputWithOperatorProps {
  placeholder?: string;
  onChange?: (value: InputWithOperatorElementValue) => any;
  fullWidth?: boolean;
  value?: InputWithOperatorElementValue;
  cyTestId?: string;
}

const InputWithOperator: React.SFC<InputWithOperatorProps> = ({
  placeholder,
  onChange,
  fullWidth,
  value = { operator: "equalTo", value: "" },
  cyTestId,
}) => {
  // const [SelectedValue, SetSelectedValue] = React.useState("equal");
  // const [LastInputValue, SetLastInputValue] = React.useState("");
  let OptionsList: {
    label: React.ReactElement;
    value: string;
    title: string;
    "data-cy"?: string;
  }[];

  OptionsList = [
    {
      label: <FaGreaterThan />,
      value: "greaterThan",
      title: "Greater Than",
      "data-cy": "farmers-filter-farms-count-greater-than-operator",
    },
    {
      label: <FaLessThan />,
      value: "lessThan",
      title: "Less Than",
      "data-cy": "farmers-filter-farms-count-less-than-operator",
    },
    {
      label: <FaGreaterThanEqual />,
      value: "greaterThanOrEqualTo",
      title: "Greater Than or Equal",
      "data-cy": "farmers-filter-farms-count-greater-than-or-equal-operator",
    },
    {
      label: <FaEquals />,
      value: "equalTo",
      title: "Equal To",
      "data-cy": "farmers-filter-farms-count-equal-operator",
    },
    {
      label: <FaLessThanEqual />,
      value: "lessThanOrEqualTo",
      title: "Less Or Equal",
      "data-cy": "farmers-filter-farms-count-less-than-or-equal-operator",
    },
  ];

  const classes = useStyle();

  return (
    <Box display="flex" justifyContent="center">
      <TextField
        fullWidth={fullWidth}
        placeholder={placeholder}
        onChange={(e) => {
          //   SetLastInputValue(e.currentTarget.value);
          onChange?.({
            value: e.currentTarget.value,
            operator: value?.operator,
          });
        }}
        className={classes.customInputRoot}
        value={value?.value}
        data-cy={cyTestId}
      />
      <CustomMenu
        menuData={OptionsList.map((item) => ({
          onClick: () => {
            onChange?.({ value: value?.value, operator: item.value });
          },
          label: item.label,
          "data-cy": item?.["data-cy"],
        }))}
      >
        <Box
          className={classes.operatorWrapper}
          component={ButtonBase}
          data-cy="farmers-filter-farms-operator-button"
        >
          {
            OptionsList.find((item, index) => item.value === value?.operator)
              ?.label
          }
        </Box>
      </CustomMenu>
    </Box>
  );
};

export default InputWithOperator;
