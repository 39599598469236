import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
interface Params {
  id: string;
  survey_id: string;
}
interface Resp {}
type DeleteSurveyQuestionType = (
  Params: Params,
  callback: (error: AxiosError | null, Resp: Resp | null) => any
) => any;
const DeleteSurveyQuestion: DeleteSurveyQuestionType = (Params, callback) => {
  AxiosInstance.delete(
    `/survey-questions/${Params.survey_id}/${Params.id}`
  )
    .then((resp) => {
      callback(null, resp)
    })
    .catch((error) => callback(error, null));
};
export { DeleteSurveyQuestion };
