import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import PrecipitationChart from "./Precipitation-Chart";
import TempretureChart from "./Tempreture-Chart";
import SoilMoistureChart from "./Soil-Moisture-Chart";
import { MdInfoOutline } from "react-icons/md";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";
import CustomToggleButton from "components/custom-compnents/custom-toggle-buttons/custom-toggle-buttons";

export interface TempretureAndPrecipitationProps {
  lat: number;
  lng: number;
}

const TempretureAndPrecipitation: React.SFC<TempretureAndPrecipitationProps> =
  ({ lat, lng }) => {

    const [SelectedChart, setSelectedChart] = React.useState("0");
    const TabOptions = [
      { title: "Precipitation", value: "0" },
      { title: "Temperature", value: "1" },
      { title: "Soil Moisture", value: "2" },
    ];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
              <CustomTitle label="Historic Weather" />
            </Grid>
            <Grid item xs={12} md={8} textAlign="right">
              <CustomToggleButton
                onChange={(value) => setSelectedChart(value)}
                value={SelectedChart}
                items={TabOptions.map((item, index) => ({
                  label: item.title,
                  value: item.value,
                }))}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box component={Paper}>
            {lat && lng ? (
              <>
                <Box mb={2}>
                  {/* <Tabs
                onChange={(e, option) => setSelectedChart(option)}
                value={SelectedChart}
                variant="fullWidth"
              >
                {TabOptions.map((item, index) => (
                  <Tab label={item.title} value={item.value} />
                ))}
              </Tabs> */}
                </Box>
                <Box height={400}>
                  <Box display={SelectedChart !== "0" ? "none" : "inline"}>
                    <PrecipitationChart lat={lat} lng={lng} />
                  </Box>
                  <Box display={SelectedChart !== "1" ? "none" : "inline"}>
                    <TempretureChart lng={lng} lat={lat} />
                  </Box>
                  <Box display={SelectedChart !== "2" ? "none" : "inline"}>
                    <SoilMoistureChart lat={lat} lng={lng} />
                  </Box>
                </Box>
              </>
            ) : (
              <Box textAlign="center">
                <Typography color="textSecondary">
                  <MdInfoOutline size={35} style={{ padding: "0px 5px" }} />
                  this chart requires farm coordinates
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  };

export default TempretureAndPrecipitation;
