import { Box, Button, Chip, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
// import { IoOpenOutline, IoRemoveSharp } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { IoOpenOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Survey } from "models/survey";
import moment from "moment";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
  selectedFarmersIds?: string[];
  selectedByPhone?: boolean;
}
export interface Fields extends Survey {
  selected: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  showViewAction?: boolean;
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (params) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "TITLE",
      field: "title",
      hidden: !params.columnState?.find((item) => item.field === "title")?.checked,
      render: (data) => data.title,
    },
    {
      title: "JUMP CODE",
      field: "jump_code",
      hidden: !params.columnState?.find((item) => item.field === "jump_code")?.checked,
      render: (data) => data.jump_code,
    },
    {
      title: "Organization",
      field: "provider",
      hidden: !params.columnState?.find((item) => item.field === "provider")?.checked,
      render: (data) => data?.provider?.name,
    },
    {
      title: "START DATE",
      field: "start_date",
      hidden: !params.columnState?.find((item) => item.field === "start_date")?.checked,
      render: (data) => moment.utc(data.start_time).local().format("YYYY/MM/DD hh:mm a"),
    },
    {
      title: "EXPIRY DATE",
      field: "expiry_date",
      hidden: !params.columnState?.find((item) => item.field === "expiry_date")?.checked,
      render: (data) => moment.utc(data.expires_at).local().format("YYYY/MM/DD hh:mm a"),
    },
    {
      title: "ACTIVE?",
      field: "active",
      hidden: !params.columnState?.find((item) => item.field === "active")?.checked,
      render: (data) => (data.active ? <Chip variant="outlined" label="Active" /> : <Chip variant="outlined" label="Inactive" color="error" />),
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: <CustomCheckBox onChange={(e, value) => params.customSelection?.onSelectAllChange?.(params.data, value)} checked={params.data.every((item) => item.selected)} indeterminate={params.data.some((item) => item.selected) && !params.data.every((item) => item.selected)} />,
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => <CustomCheckBox checked={data.selected} onChange={(e, value) => params.customSelection?.onRowSelectChange?.(data, value)} />,
    });
  }

  return {
    actions: [
      (fields) => ({
        hidden: !params.showViewAction,
        icon: () => (
          <Link to={`/surveys/${fields.id}`}>
            <Button variant="text" onClick={() => null} color="success" startIcon={<IoOpenOutline />}>
              View
            </Button>
          </Link>
        ),
        onClick: () => null,
      }),
    ],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
