import { Box } from "@mui/system";
import ProviderCodeTable from "components/data-tables/provider-codes-table/providers-codes-table";

interface AgentCodesScreenProps {}

const AgentCodesScreen: React.FunctionComponent<AgentCodesScreenProps> = () => {
  return (
    <Box>
      <ProviderCodeTable />
    </Box>
  );
};

export default AgentCodesScreen;
