import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import humanize from "humanize-graphql-response";
import { IFarmerLabelKey } from "models/farmer-label-key";

interface Params {
  page: number;
  pageSize: number;
  key?: string;
  provider_id_list?: string[];
  farmers_id_list?: string[];
  with_farmers_count?: boolean;
}
export interface Resp {
  items: IFarmerLabelKey[];
  totalCount: number;
}
type GetFarmerLabelsKeysType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | Resp) => any
) => any;
export const GetFarmerLabelsKeys: GetFarmerLabelsKeysType = (
  Params,
  callback
) => {
  AxiosInstance.get("/farmer-label-keys", { params: Params })
    .then((resp) => {
      callback(null, {
        totalCount: resp.data.data.all_label_keys.total_count,
        items: humanize(resp.data.data.all_label_keys).nodes.map(
          (item: any) => ({
            id: item.id,
            key: item.key,
            providerId: item.provider_id,
            farmers_count: item.farmers_count,
            // TODO
            farmerLabelsByLabelKeyId: item.farmer_labels_by_label_key_id,
            providerByProviderId: item.provider_by_provider_id,
          })
        ),
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};
