import { GetFarmerLabelsKeys } from "api/farmer-labels-keys/get";
import { debounce } from "lodash";
import { IFarmerLabelKey } from "models/farmer-label-key";
import React from "react";
import { useAppSelector } from "store/hooks";

export interface IParams {
  farmer_id: string;
}

export const useLogic = (params: IParams) => {
  const { farmer_id } = params;
  const [data, setData] = React.useState<IFarmerLabelKey[]>([]);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [KeyIdToDelete, setKeyIdToDelete] = React.useState("");
  const [openAddLabelModal, setOpenAddLabelModal] = React.useState(false);
  const [opendItemSubPanelIds, setOpendItemSubPanelIds] = React.useState<string[]>([]);
  const [itemIdToBeUpdated, setItemIdToBeUpdated] = React.useState("");

  const [labelsIdToBeDeleted, setLabelsIdToBeDeleted] = React.useState<string[]>([]);
  const user = useAppSelector((root) => root.appReducer.user);

  const isShowAddButton = user?.menuroles === "admin" || user?.menuroles === "manager" || user?.menuroles === "org_admin";
  const isShowEditButton = user?.menuroles === "admin" || user?.menuroles === "manager" || user?.menuroles === "org_admin";
  const isShowDeleteButton = user?.menuroles === "admin" || user?.menuroles === "manager" || user?.menuroles === "org_admin";

  const toggleOpenItemSubPanel = (id: string) => {
    const is_exists = opendItemSubPanelIds.includes(id);
    if (is_exists) setOpendItemSubPanelIds(opendItemSubPanelIds.filter((item) => item !== id));
    if (!is_exists) setOpendItemSubPanelIds([...opendItemSubPanelIds, id]);
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleItemIdToBeUpdated = (id: string) => setItemIdToBeUpdated(id);

  const handleAdded = () => {
    setOpenAddLabelModal(false);
    refreshData();
  };

  const handleDeleteClick = (value: string[]) => {
    setLabelsIdToBeDeleted(value);
  };

  const handleSetKeyToDelete = (id: string) => setKeyIdToDelete(id);

  const handleCancelUpdate = () => {
    setItemIdToBeUpdated("");
  };

  const handleSearchChange = debounce((value) => {
    setSearchTerm(value);
    setCurrentPage(0);
  }, 800);

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const handleDeleted = () => {
    refreshData();
    setLabelsIdToBeDeleted([]);
  };

  const handleUpdated = () => {
    refreshData();
    setItemIdToBeUpdated("");
  };

  const handleOpenAddLabelModal = () => setOpenAddLabelModal(true);

  const handleCancelAddLabel = () => setOpenAddLabelModal(false);

  const handleCancelDelete = () => setLabelsIdToBeDeleted([]);

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetFarmerLabelsKeys(
      {
        pageSize: perPage,
        page: currentPage,
        key: searchTerm,
        farmers_id_list: [farmer_id],
        // key_search: searchTerm,
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setTotalCount(resp.totalCount);
          setIsLoading(false);
          setData(resp.items);
        }
      }
    );
  }, [dataUpdateIndex, perPage, currentPage, searchTerm]);

  return {
    data,
    isLoading,
    totalCount,
    handleNextPageClick,
    handlePerPageChange,
    handlePreviousPageClick,
    perPage,
    currentPage,
    handleSearchChange,
    KeyIdToDelete,
    handleSetKeyToDelete,
    opendItemSubPanelIds,
    toggleOpenItemSubPanel,
    handleDeleted,
    handleOpenAddLabelModal,
    openAddLabelModal,
    handleCancelAddLabel,
    handleAdded,
    handleCancelUpdate,
    handleItemIdToBeUpdated,
    itemIdToBeUpdated,
    handleUpdated,
    handleDeleteClick,
    labelsIdToBeDeleted,
    handleCancelDelete,
    isShowAddButton,
    isShowDeleteButton,
    isShowEditButton,
  };
};
