import { GetFarmActivites } from "api/activities/get-farm-activites";
import { AddAggregationActivity } from "api/aggregation/add-aggregation";
import { UpdateAggregationActivity } from "api/aggregation/update-aggregation";
import { GetCrop } from "api/crops/get-crops";
import { GetVariety } from "api/variety/get-variety";
import { CustomAddUpdateAggregationEventComponentProps } from "components/custom-compnents/custom-add-update-aggregation-event-component/custom-add-update-aggregation-event-component";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";

interface IUseLogic {
  variant: string;
  OnAddedOrUpdate: () => any;
  activityId?: string;
  farm_id?: string;
}

export const useLogic = ({
  variant,
  OnAddedOrUpdate,
  activityId,
  farm_id,
}: IUseLogic) => {
  const ValidationSchema = yup.object().shape({
    aggr_total_price_ksh: yup.number().required().label("Price"),
    aggr_quantity_kg: yup.number().required().label("Quantity"),
    aggr_status: yup
      .object()
      .shape({
        value: yup
          .string()
          .required()
          .oneOf([
            "draft",
            "awaiting_quality_checks",
            "awaiting_payment",
            "done",
          ]),
      })

      .label("Status"),
    aggr_payment_method: yup
      .object()
      .shape({
        value: yup.string().required().oneOf(["mpesa", "cash"]),
      })

      .label("payment"),
    day: yup.string().required().min(1).max(2),
    month: yup.string().required().min(1).max(2),
    year: yup.string().required().min(4).max(4),
    crop: yup
      .object()
      .shape({
        value: yup.string().uuid().required(),
      })
      .label("crop"),
  });

  const [IsSubmitting, SetIsSubmitting] = React.useState(false);

  const FormikForm = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidationSchema,
    initialValues: {
      month: "",
      day: "",
      year: "",
      aggr_quantity_kg: "",
      aggr_status: { label: "", value: "" },
      aggr_total_price_ksh: "",
      aggr_payment_method: { label: "", value: "" },
      crop: { label: "", value: "" },
      variety: { label: "", value: "" },
    },
    onSubmit: (values, action) => {
      SetIsSubmitting(true);
      variant === "Add" &&
        farm_id &&
        AddAggregationActivity(
          {
            farm_id,
            variety_id: values.variety.value,
            crop_id: values.crop.value,
            // aggr_payment_method: values.aggr_payment_method.value as any,
            // aggr_quantity_kg: parseInt(values.aggr_quantity_kg!),
            // aggr_status: values.aggr_status.value as string,
            // aggr_total_price_ksh: parseInt(values.aggr_total_price_ksh!),
            day: parseInt(values.day!),
            month: parseInt(values.month!),
            year: parseInt(values.year!),
            // aggrLastEditor: "0",
            aggr_quantity_kg: +values.aggr_quantity_kg,
            aggr_payment_method: values.aggr_payment_method.value as any,
            aggr_status: values.aggr_status.value as any,
            aggr_total_price_ksh: +values.aggr_total_price_ksh!
          },
          (error, resp) => {
            if (error) {
              console.log(error);
            } else if (resp) {
              SetIsSubmitting(false);
              OnAddedOrUpdate();
            }
          }
        );
      variant === "Update" &&
        farm_id &&
        UpdateAggregationActivity(
          {
            activity_id: activityId as any,
            aggr_payment_method: values.aggr_payment_method.value as any,
            aggr_quantity_kg: parseInt(values.aggr_quantity_kg!),
            aggr_status: values.aggr_status.value as any,
            aggr_total_price_ksh: parseInt(values.aggr_total_price_ksh!),
            day: parseInt(values.day!),
            month: parseInt(values.month!),
            year: parseInt(values.year!),
            farm_id,
            variety_id: values.variety.value,
            crop_id: values.crop.value,
            aggr_last_editor: ""
          },
          (error, resp) => {
            if (error) {
              console.log(error.message);
            } else if (resp) {
              SetIsSubmitting(false);
              OnAddedOrUpdate();
            }
          }
        );
    },
  });

  React.useEffect(() => {
    GetCrop({
      page: 0,
      pageSize: 10,
    }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        SetCropsOptions(
          resp.items.map((item, index) => ({
            value: item.id,
            label: item.name,
          }))
        );
      }
    });
  }, []);

  const [CropOptions, SetCropsOptions] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [VarietyOptions, SetVarietyOptions] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [isVariteyLoading, setIsVariteyLoading] = React.useState(false);

  const handleCropValueChange: CustomAddUpdateAggregationEventComponentProps["onCropValueChange"] =
    (item) => FormikForm.setFieldValue("crop", item);

  const handleVarietyValueChange: CustomAddUpdateAggregationEventComponentProps["onVarietyValueChange"] =
    (item) => FormikForm.setFieldValue("variety", item);

  const handlePaymentMethodChange: CustomAddUpdateAggregationEventComponentProps["onPaymentValueChange"] =
    (item) => FormikForm.setFieldValue("aggr_payment_method", item);

  const handleStatusChange: CustomAddUpdateAggregationEventComponentProps["onStatusValueChange"] =
    (item) => FormikForm.setFieldValue("aggr_status", item);

  const handleDayChange: CustomAddUpdateAggregationEventComponentProps["onDayChange"] =
    (item) => FormikForm.setFieldValue("day", item);

  const handleMonthChange: CustomAddUpdateAggregationEventComponentProps["onMonthChange"] =
    (item) => FormikForm.setFieldValue("month", item);

  const handleYearChange: CustomAddUpdateAggregationEventComponentProps["onYearChange"] =
    (item) => FormikForm.setFieldValue("year", item);

  const handleTotalPriceChange: CustomAddUpdateAggregationEventComponentProps["onTotalPriceChange"] =
    (item) => FormikForm.setFieldValue("aggr_total_price_ksh", item);

  const handleQuantityChange: CustomAddUpdateAggregationEventComponentProps["onQuantityChange"] =
    (item) => FormikForm.setFieldValue("aggr_quantity_kg", item);

  const handleAddEventClick = () => {
    FormikForm.submitForm();
  };
  //update varitey when crop change
  React.useEffect(() => {
    const CropNewValue = FormikForm.values["crop"].value;
    if (FormikForm.values["crop"].value) {
      setIsVariteyLoading(true);
      GetVariety({
        page: 0,
        pageSize: 10,
        crop_id: CropNewValue
      }, (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setIsVariteyLoading(false);
          //   if (resp.items.length === 0) SetVarietyMenuLabel(NoResultStatment);
          //   if (resp.items.length > 0) SetVarietyMenuLabel(ReadyToSelectStatment);
          SetVarietyOptions(
            resp.items.map((item) => ({ value: item.id, label: item.name }))
          );
        }
      });
    }
  }, [FormikForm.values["crop"].value]);

  // get aggreagion data & fill it in the form
  React.useEffect(() => {
    variant === "Update" &&
      activityId &&
      GetFarmActivites(
        { page: 0, pageSize: 10, activity_list: [activityId!] },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            FormikForm.setValues({
              day: resp.items[0].day.toString(),
              month: resp.items[0].month.toString(),
              year: resp.items[0].year.toString(),
              aggr_payment_method: {
                label: resp.items[0].aggrPaymentMethod.toUpperCase(),
                value: resp.items[0].aggrPaymentMethod,
              },
              aggr_quantity_kg: resp.items[0].aggrQuantityKg as any,
              aggr_status: {
                label: resp.items[0].aggrStatus.toUpperCase(),
                value: resp.items[0].aggrStatus,
              },
              crop: {
                label: resp.items[0].crop?.name as string,
                value: resp.items[0].crop?.id as string,
              },
              aggr_total_price_ksh: resp.items[0].aggrTotalPriceKsh.toString(),
              variety: {
                label: resp.items[0].variety?.name as string,
                value: resp.items[0].variety?.id as string,
              },
            });
          }
        }
      );
  }, [FormikForm, variant, activityId]);

  const formValues = FormikForm.values;

  const formErrors = FormikForm.errors;

  return {
    CropOptions,
    formValues,
    handleCropValueChange,
    VarietyOptions,
    isVariteyLoading,
    handleVarietyValueChange,
    handleStatusChange,
    handlePaymentMethodChange,
    handleQuantityChange,
    handleDayChange,
    handleMonthChange,
    handleTotalPriceChange,
    handleYearChange,
    IsSubmitting,
    handleAddEventClick,
    formErrors,
  };
};
