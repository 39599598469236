import { Box, Chip, Grid, Paper, Toolbar, Typography } from "@mui/material";
import { ReactNode } from "react";
import { IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import CancelSuccessButtonsGroup, { CancelSuccessButtonsGroupProps } from "../cancel-success-buttons-group/cancel-success-buttons-group";
import CustomTextField, { CustomTextFieldProps } from "../custom-text-field/custom-text-field";
import RoundButton, { RoundButtonProps } from "../round-button/round-button";

interface FarmerLabelKeyComponentProps {
  editButtonProps?: RoundButtonProps;
  deleteButtonProps?: RoundButtonProps;
  keyTitle?: string;
  providerName?: string;
  farmersCount?: number;
  mode: "add" | "update" | "view";
  cancelButtonProps?: CancelSuccessButtonsGroupProps["CancelButtonProps"];
  successButtonProps?: CancelSuccessButtonsGroupProps["SuccessButtonProps"];
  keyInputProps?: CustomTextFieldProps;
  isShowProvider?: boolean;
  providerInputComponent?: ReactNode;
}

const FarmerLabelKeyComponent: React.FunctionComponent<FarmerLabelKeyComponentProps> = (props) => {
  const { keyTitle, deleteButtonProps, editButtonProps, farmersCount, providerName, mode, cancelButtonProps, successButtonProps, keyInputProps, isShowProvider, providerInputComponent } = props;

  return (
    <Box component={Paper} py={1}>
      <Toolbar>
        {mode === "view" && (
          <>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <Typography variant="h6">{keyTitle}</Typography>
              </Grid>
              {isShowProvider && (
                <Grid item md={4} xs={12}>
                  <Typography variant="h6">{providerName}</Typography>
                </Grid>
              )}

              <Grid item md={4} xs={12}>
                <Chip label={`${farmersCount == 0 ? "Not Used" : farmersCount + " Farmers"} `} variant="outlined" />
              </Grid>
            </Grid>
            <Box ml="auto" display="flex" gap={1.5} alignItems="center">
              <RoundButton {...editButtonProps}>
                <IoCreateOutline size={20} />
              </RoundButton>
              <RoundButton {...deleteButtonProps}>
                <IoTrashOutline size={20} />
              </RoundButton>
            </Box>
          </>
        )}
        {(mode === "add" || mode === "update") && (
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <CustomTextField fullWidth placeholder="Label Key" {...keyInputProps} />
                </Grid>
                <Grid item md={6} xs={12}>
                  {providerInputComponent}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <CancelSuccessButtonsGroup
                CancelButtonProps={{ label: "Cancel", ...cancelButtonProps }}
                SuccessButtonProps={{
                  label: mode === "add" ? "Add Label Key" : "Update Key",
                  ...successButtonProps,
                }}
                RootGridProps={{ maxWidth: "100%", width: 500 }}
              />
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </Box>
  );
};

export default FarmerLabelKeyComponent;
