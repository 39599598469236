import { Grid } from "@mui/material";
import ProvidersTable from "components/data-tables/providers-table/providers-table";
import OrganizationsTreeDiagram from "components/standalone-components/organizations-tree-diagram/organizations-tree-diagram";
import ProviderPageHeader from "components/standalone-components/providers-page-header/provider-page-header";
import { useLogic } from "./providers-screen.logic";

interface ProvidersScreenProps {}

const ProvidersScreen: React.FunctionComponent<ProvidersScreenProps> = () => {
  const { selectedTab, handleTabChange } = useLogic();
  return (
    <Grid container spacing={2} sx={{ pt: 2 }}>
      <Grid item xs={12} mb={5}>
        <ProviderPageHeader selectedTab={selectedTab} onTabChange={handleTabChange} />
      </Grid>
      {selectedTab === "all" && (
        <Grid item xs={12}>
          <ProvidersTable viewType="all" actionsToInclude={["add", "edit", "remove", "inline_affiliate", "disassociate"]} />
        </Grid>
      )}
      {selectedTab === "own" && (
        <>
          <Grid item xs={12}>
            <ProvidersTable viewType="super_orgs" actionsToInclude={["affiliate", "disassociate"]} />
          </Grid>
          <Grid item xs={12}>
            <ProvidersTable viewType="sub_orgs" actionsToInclude={["add", "disassociate"]} />
          </Grid>
          <Grid item xs={12}>
            <OrganizationsTreeDiagram />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProvidersScreen;
