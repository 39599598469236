export interface IParams {
  survey: { title: string; id: string };
}

export const breadcrumb = (params: IParams) => {
  const { survey } = params;

  return [
    { title: "Home", path: "/" },
    { title: "Surveys", path: "/surveys/" },
    {
      title: `${survey.title}`,
      path: `/surveys/${survey.id}`,
    },
  ].filter((item) => item.title); // shape the array of exists paths
};
