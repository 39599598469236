import * as React from "react";
import { Box, Grid } from "@mui/material";
import SelectMenu from "components/input-components/select-menu/SelectMenu";
import { Certificate } from "models/certificate";
import { GetCertificate } from "api/certificate/get-certificate";
import { GetFarmers } from "api/farmer/get-farmer";
import { Farmer } from "models/farmers";
import { useFormik } from "formik";
import { CreateCertificaion } from "api/certification/create-certification";
import CustomDialogV2, {
  CustomDialogV2Props,
} from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomTextField from "components/custom-compnents/custom-text-field/custom-text-field";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

export interface AddCertificationComponentProps {
  onCancel?: () => any;
  onAdded?: () => any;
  open?: boolean;
  farmer_id?: string;
}

const AddCertificationComponent: React.FunctionComponent<AddCertificationComponentProps> =
  (props) => {
    const user = useSelector((state: RootState) => state.appReducer.user);
    const [Certifiates, SetCertificates] = React.useState<Certificate[]>([]);
    const [FarmerSearchValue, setFarmerSearchValue] = React.useState("");
    const [Farmers, SetFarmers] = React.useState<Farmer[]>([]);
    const [LoadingFarmers, setLoadingFarmers] = React.useState(false);
    const Form = useFormik({
      initialValues: {
        certificate_id: undefined,
        farmer_id: props.farmer_id,
        certificaion_date: undefined,
      },
      onSubmit: (values, action) => {
        CreateCertificaion(
          {
            certificate_id: values.certificate_id!,
            farmer_id: values.farmer_id!,
            confirmed_by: user?.name!,
            certification_date: values.certificaion_date!
          },
          (error, resp) => {
            if (error) {
              console.log(error.message);
            } else if (resp) {
              props.onAdded?.();
            }
          }
        );
      },
    });
    const DialogProps: CustomDialogV2Props = {
      open: props.open ?? false,
      header: "Add Certification To Farmer",
      // ActionButtonsProps: {
      //   ButtonsVariant: "ConfirmCancel",
      //   OnCancel: props.onCancel,
      //   OnConfirm: Form.submitForm,
      //   DisableConfirm: Form.isSubmitting,
      // },
      footer: (
        <Box display="flex" justifyContent="flex-end">
          <CancelSuccessButtonsGroup
            CancelButtonProps={{ label: "Cancel", onClick: props.onCancel }}
            RootGridProps={{ width: 300 }}
            SuccessButtonProps={{
              label: "Add Certification",
              onClick: Form.submitForm,
              disabled: Form.isSubmitting,
            }}
          />
        </Box>
      ),
    };

    React.useEffect(() => {
      GetCertificate({
        page: 0,
        pageSize: 10
      }, (error, resp) => {
        if (error) {
          console.log(error);
        } else if (resp) {
          SetCertificates(resp.items);
        }
      });
    }, []);

    const GetAndSetFarmers = () => {
      setLoadingFarmers(true);

      GetFarmers(
        {
          Name: FarmerSearchValue,
          page: 0,
          pageSize: 10,
          Ids: props.farmer_id ? [props.farmer_id] : undefined,
        },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            SetFarmers(resp.Farmers);
            setLoadingFarmers(false);
          }
        }
      );
    };

    React.useEffect(() => {
      GetAndSetFarmers();
    }, [FarmerSearchValue]);

    const FieldData = [
      {
        name: "certificate",
        type: "menu",
        placeholder: "Certificate",
        label: "Select Certificate",
        value: Form.values["certificate_id"],
        options: Certifiates.map((item) => ({
          title: item.title,
          value: item.id,
        })),
        onchange: (e: any) =>
          Form.setFieldValue("certificate_id", e.target.value),
      },
      {
        name: "farmer",
        type: "menu",
        placeholder: "Farmer",
        label: "Select Farmer",
        value: Form.values["farmer_id"],
        options: Farmers.map((item) => ({
          title: `${item.firstName} ${item.lastName}`,
          value: item.id,
        })),
        onchange: (e: any) => Form.setFieldValue("farmer_id", e.target.value),
        onSearchInputChange: setFarmerSearchValue,
        searchable: true,
        SearchLabel: "Farmer Name",
        SearchPlaceholder: "Search By Farmer Name ...",
        LoadingSearch: LoadingFarmers,
        disabled: Boolean(props.farmer_id),
      },
      {
        name: "certification_date",
        type: "date",
        label: "Date",
        placeholder: "Select Data",
        value: Form.values["certificaion_date"],
        onchange: (e: any) =>
          Form.setFieldValue("certificaion_date", e.currentTarget.value),
      },
    ];
    return (
      <CustomDialogV2 {...DialogProps}>
        {/* <CustomDialog {...DialogProps}> */}
        <Box maxWidth={350} py={1}>
          <Grid container spacing={2}>
            {FieldData.map((item, index) => (
              <Grid item xs={12} key={index}>
                {item.type === "menu" ? (
                  <SelectMenu
                    label={item.label}
                    Placeholder={item.placeholder}
                    OnChange={item.onchange}
                    value={item.value}
                    options={item.options!}
                    Searchable={item.searchable!}
                    onSearchInputChange={item.onSearchInputChange!}
                    SearchLabel={item.SearchLabel!}
                    SearchPlaceholder={item.SearchPlaceholder!}
                    LoadingSearch={item.LoadingSearch}
                    disabled={item.disabled}
                  />
                ) : (
                  <CustomTextField
                    name={item.name}
                    type={item.type}
                    fullWidth
                    value={item.value}
                    onChange={item.onchange}
                    placeholder={item.placeholder}
                    label={item.label}
                    InputLabelProps={{ shrink: item.type === "date" && true }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* </CustomDialog> */}
      </CustomDialogV2>
    );
  };

export default AddCertificationComponent;
