import { FunctionComponent, ReactNode } from "react";
import CustomDialogV2 from "../custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "../cancel-success-buttons-group/cancel-success-buttons-group";
import { ButtonProps } from "@mui/material";

interface CustomConfirmationDialogProps {
  open?: boolean;
  header?: string;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  children?: ReactNode;
}

const CustomConfirmationDialog: FunctionComponent<CustomConfirmationDialogProps> = (props) => {
  const { cancelButtonProps, children, confirmButtonProps, header, open } = props;

  return (
    <CustomDialogV2
      open={open ?? false}
      header={header}
      footer={
        <CancelSuccessButtonsGroup
          CancelButtonProps={{ label: "Cancel", ...cancelButtonProps }}
          SuccessButtonProps={{
            label: "Confirm",
            ...confirmButtonProps,
          }}
        />
      }
    >
      {children}
    </CustomDialogV2>
  );
};

export default CustomConfirmationDialog;
