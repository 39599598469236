import { AxiosInstance } from "configs/axios";

interface Params {
  page: number;
  pageSize: number;
  id?: string;
  provider_id?: string;
  phone?: string;
  broadcast_message_?: string;
  sort_by?: string[];
}

interface IResp {
  items: any;
  total: number;
}
type get_messages_history_TYPE = (data: Params, callback: (data: IResp | null) => any) => any;
const get_messages_history: get_messages_history_TYPE = (Params, callback) => {
  AxiosInstance.get("/sms-messages", {
    params: Params,
  })
    .then((resp) => {
      callback({
        items: resp.data.all_sms_messages.nodes,
        total: resp.data.all_sms_messages.total_count
      });
    })
    .catch((error) => callback(error.response));
};

export { get_messages_history };
