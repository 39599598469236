import { Divider, Drawer } from "@mui/material";
import { Box, BoxProps } from "@mui/system";
import { FunctionComponent } from "react";
import React from "react";
import useStyles from "./side-bar.style";
import Logo from "components/custom-compnents/logo/logo";
import SideMenuList from "components/custom-compnents/side-menu-list/side-menu-list";
import { useLogic } from "./side-bar.logic";
import Scrollbars from "react-custom-scrollbars";

interface SideBarProps {}

const SideBar: FunctionComponent<SideBarProps> = () => {
  const classes = useStyles();
  const {
    SideBarProps,
    isDrawerOpen,
    isLargeOrLess,
    preparedData,
    handleClose,
  } = useLogic();

  const DrawerWrapper = isLargeOrLess ? Drawer : React.Fragment;

  return (
    <DrawerWrapper open={isDrawerOpen} onClose={handleClose}>
      <Box minWidth={SideBarProps.minWidth} maxWidth={SideBarProps.maxWidth}>
        <Box {...SideBarProps}>
          <Box className={classes.logo_wrapper_root}>
            <Logo />
          </Box>
          <Divider />

          <Box>
            <Scrollbars
              autoHide
              autoHeight
              autoHeightMax={"calc(100vh - 75px)"}
            >
              <SideMenuList data={preparedData} />
            </Scrollbars>
          </Box>
        </Box>
      </Box>
    </DrawerWrapper>
  );
};

export default SideBar;
