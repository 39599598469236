import { AxiosInstance } from "configs/axios";
import { ICountyLocation } from "models/county-location";

interface IParams {
  search?: string;
}

type IResp = ICountyLocation[];

const GetCountyLocation = (params: IParams) => {
  const { search } = params;
  return AxiosInstance.get<IResp>("/counties/location", { params });
};

export { GetCountyLocation };
