import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Region } from "models/region";

interface Params {
    page: number;
    pageSize: number;
    name?: string;
    region_id_list?: string[];
    is_attached_to_farmers?: boolean;
}
interface Resp {
    allRegions: {
        items: Region[];
        totalCount: number;
    }
}

interface RegionDto {
    id: string;
    name: string;
    region_id: string;
}

type GetRegionsType = (
    Params: Params,
    callback: (error: AxiosError | null, resp: null | Resp) => any
) => any;

const GetRegions: GetRegionsType = (Params, callback) => {
    AxiosInstance.get("/regions", { params: Params })
        .then((resp) => {
            callback(null, {
                allRegions: {
                    items: resp.data.data.all_regions.nodes.map((item: RegionDto) => ({
                        id: item.id,
                        name: item.name,
                        regionId: item.region_id
                    })),
                    totalCount: resp.data.data.all_regions.total_count
                }
            });
        })
        .catch((error) => callback(error, null));
};

export { GetRegions };
