import { Button, ButtonProps } from "@mui/material";
import { IoFileTraySharp } from "react-icons/io5";

interface ArchiveButtonProps extends ButtonProps {}

const ArchiveButton: React.FunctionComponent<ArchiveButtonProps> = (props) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<IoFileTraySharp />}
      {...props}
    />
  );
};

export default ArchiveButton;

ArchiveButton.defaultProps = {
  children: "Archive",
};
