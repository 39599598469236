import { AxiosInstance } from "configs/axios";

export interface IResp {
  cube: {
    farmers: {
      unique_farmer_count: number;
    };
  }[];
}

export interface IParams {
  region_id_list?: string[];
  county_id_list?: string[];
  constituency_id_list?: string[];
  ward_id_list?: string[];
  provider_id?: string;
  per_region?: boolean;
  per_county?: boolean;
  per_constituency?: boolean;
  per_ward?: boolean;
}

export const getFarmersKpi = async (params: IParams) => {
  return AxiosInstance.get("/farmer-analytics/find-farmers-count-kpi", { params });
};
