import { Grid } from "@mui/material";
import CheckBoxItemsMenu, {
  CheckBoxItemsMenuProps,
} from "components/custom-compnents/check-box-items-menu/check-box-items.menu";
import CustomMenuButton from "components/custom-compnents/custom-menu-button/custom-menu-button";
import CustomTextField, {
  CustomTextFieldProps,
} from "components/custom-compnents/custom-text-field/custom-text-field";

import * as React from "react";

export interface AgentCodesTableFilterFormProps {
  codeFilterProps?: CustomTextFieldProps;
  providerFilterProps?: CustomTextFieldProps;
  commentFilterProps?: CustomTextFieldProps;
  isShowCodeFilter?: boolean;
  isShowProviderFilter?: boolean;
  isShowCommentFilter?: boolean;
}

const AgentCodesTableFilterForm: React.FunctionComponent<
  AgentCodesTableFilterFormProps
> = (props) => {
  const {
    codeFilterProps,
    providerFilterProps,
    isShowCodeFilter,
    isShowProviderFilter,
    isShowCommentFilter,
    commentFilterProps,
  } = props;

  const FieldsData = [
    {
      label: "Code",
      type: "text",
      props: codeFilterProps,
      hidden: !isShowCodeFilter,
    },
    {
      label: "Organization",
      type: "text",
      props: providerFilterProps,
      hidden: !isShowProviderFilter,
    },
    {
      label: "Comment",
      type: "text",
      props: commentFilterProps,
      hidden: !isShowCommentFilter,
    },
  ];

  return (
    <Grid container spacing={2}>
      {FieldsData.filter((item) => !item.hidden).map(
        ({ type, ...item }, index) => (
          <Grid item lg={2} xs={12} key={index}>
            {type === "text" && (
              <CustomTextField
                placeholder={item.label}
                fullWidth
                {...(item.props as CustomTextFieldProps)}
              />
            )}
          </Grid>
        )
      )}
    </Grid>
  );
};

export default AgentCodesTableFilterForm;
