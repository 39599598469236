import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  ListProps,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { MdLogout } from "react-icons/md";
import { FaCog } from "react-icons/fa";
import capitalizeTheFirstLetterOfEachWord from "../../../utils/capitalize-the-first-letter-of-each-word";
import { IoChevronDown } from "react-icons/io5";
import useStyles from "./account-button.style";

interface AccountButtonProps {
  name: string;
  OnSettingItemClick?: () => any;
  OnLogoutItemClick?: () => any;
  isMenuButtonHidden?: true;
  listProps?: ListProps;
  listItemProps?: ListItemProps;
}

const AccountButton: React.FunctionComponent<AccountButtonProps> = ({
  name,
  OnSettingItemClick,
  OnLogoutItemClick,
  isMenuButtonHidden,
  listProps,
  listItemProps,
}) => {
  const MenuData = [
    {
      label: "Setting",
      icon: FaCog,
      onClick: () => {
        CloseMenu();
        OnSettingItemClick?.();
      },
    },
    {
      label: "Logout",
      icon: MdLogout,
      onClick: () => {
        OnLogoutItemClick?.();
        CloseMenu();
      },
    },
  ];
  const HandleClickEvent = (e: React.MouseEvent<HTMLElement>) =>
    SetAnchorEl(e.currentTarget);
  const HandleMenuClose = () => SetAnchorEl(null);
  // const ButtonProps: ButtonProps = {
  //   disableElevation: true,
  //   style: { borderRadius: 30, textTransform: "none" },
  //   startIcon: <Avatar></Avatar>,
  //   color: "inherit",
  //   variant: "contained",
  //   size: "large",
  //   onClick: HandleClickEvent,
  // };
  const [AnchorEl, SetAnchorEl] = React.useState<null | HTMLElement>(null);
  const CloseMenu = () => SetAnchorEl(null);
  const classes = useStyles();
  return (
    <>
      <List {...listProps}>
        <ListItem {...listItemProps}>
          <ListItemAvatar className={classes.avatarWrapper}>
            <Avatar className={classes.avatarRoot} />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{
              variant: "subtitle2",
              whiteSpace: "nowrap",
            }}
            primary={name ? capitalizeTheFirstLetterOfEachWord(name) : ""}
          />
          {!isMenuButtonHidden && (
            <ListItemSecondaryAction className={classes.actionWrapper}>
              <IconButton size="small" onClick={HandleClickEvent}>
                <IoChevronDown size={20} />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>

      {/* <Button {...ButtonProps}> */}
      {/* {capitalizeTheFirstLetterOfEachWord(name)} */}
      {/* </Button> */}
      <Menu
        open={Boolean(AnchorEl)}
        anchorEl={AnchorEl}
        PaperProps={{ style: { minWidth: 250 } }}
        onClose={HandleMenuClose}
      >
        {MenuData.map((item, index) => (
          <MenuItem key={index}>
            <ListItemIcon>
              <item.icon />
            </ListItemIcon>
            <ListItemText primary={item.label} onClick={item.onClick} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AccountButton;

AccountButton.defaultProps = {
  name: "Kizito Odhiambo",
};
