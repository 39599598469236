export const OrderByObject = {
  1: { asc: "from_ewallet_id_asc", desc: "from_ewallet_id_desc" },
  2: { asc: "to_ewallet_id_asc", desc: "to_ewallet_id_desc" },
  3: { asc: "amount_asc", desc: "amount_desc" },
  5: {
    asc: "type_asc",
    desc: "type_desc",
  },
  6: { asc: "timestamp_asc", desc: "timestamp_desc" }
};
