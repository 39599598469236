import { CustomMessagesHistoryTableProps } from "components/custom-compnents/custom-messages-history-table/custom-messages-history-table";
import { Fields } from "components/custom-compnents/custom-messages-history-table/custom-messages-history-table.schema";
import React from "react";
import { get_messages_history } from "./api";
export interface IParams {
  by_farmers_phone?: string;
}
export const useLogic = (params: IParams) => {
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [orderBy, setOrderBy] = React.useState(["SEND_AT_DESC"]);
  const [data, setData] = React.useState<Omit<Fields, "selected">[]>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [columnsState, setColumnsState] = React.useState([
    { field: "send_at", checked: true },
    // { field: "provider", checked: true },
    { field: "last_status", checked: true },
    // { field: "status_date", checked: true },
    { field: "messages", checked: true },
  ]);

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  React.useEffect(() => {
    setIsLoading(true);
    get_messages_history(
      {
        page: currentPage,
        pageSize: perPage,
        phone: params?.by_farmers_phone || undefined,
        sort_by: orderBy,
      },
      (resp) => {
        if (typeof resp?.total === "number" && resp) {
          setIsLoading(false);
          setData(resp?.items);
          setTotalCount(resp?.total);
        }
      }
    );
  }, [dataUpdateIndex, currentPage, perPage, orderBy]);

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  const handleOrderChange: CustomMessagesHistoryTableProps["onOrderChange"] = (
    orderBy,
    orderDirection
  ) => {
    if (orderBy != -1) {
      setOrderBy(["SEND_AT_" + orderDirection.toUpperCase()]);
    }

    console.log(orderBy, orderDirection, "order");
  };

  const tableProps: CustomMessagesHistoryTableProps = {
    isLoading: isLoading,
    onOrderChange: handleOrderChange,
    data: preparedData,
    countLabelProps: {
      count: totalCount,
    },
    actionBarProps: {
      isShowColumnButton: true,
      columnsState: columnsState,
      onColumnsStateChange: handleColumnStateChange,
    },
    pagnationProps: {
      from: perPage * currentPage,
      total: totalCount,
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
      rowsPerPage: perPage,
    },
  };
  return { tableProps };
};
