import { GetFarmerLabelsKeys } from "api/farmer-labels-keys/get";
import { GetFarmerLabelsDistinct } from "api/farmer-labels/get-distinct";
import {
  Key,
  KeyValues,
  Value,
} from "components/custom-compnents/farmer-labels-filter/farmer-labels-filter";
import { useDebounce } from "hooks/debounce-hook";
import { unionBy, uniqBy } from "lodash";
import React from "react";
import { useAppSelector } from "store/hooks";

export interface IParams {
  onSubmit?: (value: KeyValues[]) => any;
  value: KeyValues[];
}

export const useLogic = (params: IParams) => {
  const [tempComponentValue, setTempComponentValue] = React.useState<
    KeyValues[]
  >([]);


  const [keysPage, keysSetPage] = React.useState(0);
  const [valuesPage, valuesSetPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [keySearch, setKeySearch] = React.useState("");
  const [keyOptions, setKeyOptions] = React.useState<Key[]>([]);
  const [loadingKeyOptions, setLoadingKeyOptions] = React.useState(false);
  const [keySelectedValue, setKeySelectedValue] =
    React.useState<Key | null>(null);

  const [keyPrevSelectedValue, setKeyPrevSelectedValue] = React.useState(keySelectedValue)
  const [valueSearch, setValueSearch] = React.useState("");
  const [valueOptions, setValueOptions] = React.useState<Value[]>([]);
  const [loadingValueOptions, setLoadingValueOptions] = React.useState(false);
  const [valueSelectedValue, setValueSelectedValue] = React.useState<Value[]>(
    []
  );
  const concatenatedTempAndPremenetValues = tempComponentValue.concat(
    params.value
  );

  const filterKeyOptions = keyOptions.filter(
    (item) =>
      !concatenatedTempAndPremenetValues.some(
        (someItem) => someItem.key.id === item.value
      )
  );

  const filterValueOptions = valueOptions.filter(
    (item) => !valueSelectedValue.map((item) => item.value).includes(item.value)
  );

  const handleOpenButtonClick = () => setOpen(true);
  const handleClose = () => {
    setTempComponentValue(params.value);
    setOpen(false);
  };

  const userProviderId = useAppSelector(
    (root) => root.appReducer.user?.provider_id
  );

  const userMenurole = useAppSelector(
    (root) => root.appReducer.user?.menuroles
  );

  const handleSetKeySearch = (value: string) => setKeySearch(value);

  const debouncedKeySearch = useDebounce(keySearch, 700);

  const handleKeySelectedValue = (value: Key) => setKeySelectedValue(value);

  const handleSetValueSearch = (value: string) => setValueSearch(value);

  const debouncedValueSearch = useDebounce(valueSearch, 700);

  const handlesetValueSelectedValue = (value: Value[]) =>
    setValueSelectedValue(value);

  const handleAddEntry = () => {
    if (keySelectedValue?.value && keySelectedValue?.label) {
      const key = {
        id: keySelectedValue.value,
        label: keySelectedValue.label,
      };
      const values = valueSelectedValue.map((item) => ({
        id: item.value,
        label: item.label,
      }));
      const tempComponentValuesArray = tempComponentValue.map((item) => item);
      tempComponentValuesArray.push({ key, values });
      setTempComponentValue(tempComponentValuesArray);
      setValueSelectedValue([]);
      setKeySelectedValue(null);
    }
  };

  const handleClearTempValue = () => setTempComponentValue([]);

  const handleRemoveItem = (value: KeyValues, index: number) => {
    setTempComponentValue(
      tempComponentValue.filter(
        (filterItem, index) => value.key.id !== filterItem.key.id
      )
    );
  };

  const handleSubmit = () => {
    params.onSubmit?.(tempComponentValue);
    setOpen(false);
  };

  React.useEffect(() => {
    setValueOptions([]);
    setKeyOptions([]);
    setLoadingKeyOptions(true);
    GetFarmerLabelsKeys(
      {
        page: keySearch ? 0 : keysPage,
        pageSize: 10,
        key: debouncedKeySearch,
        with_farmers_count: true,
        provider_id_list:
          userMenurole === "admin"
            ? undefined
            : userProviderId
              ? [userProviderId]
              : [],
      },
      (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          setLoadingKeyOptions(false);


          let newOptions = [
            ...resp.items.map((item) => ({
              label: item.key,
              value: item.id,
            }))
          ]

          if (keyPrevSelectedValue?.value === keySelectedValue?.value) {
            newOptions.push(...keyOptions)
            newOptions = uniqBy(newOptions, (item) => item.value)
            setKeyOptions(newOptions);
          } else {
            setKeyOptions(newOptions);
          }
        }
      }
    );
  }, [userProviderId, debouncedKeySearch, keySelectedValue, keysPage]);

  React.useEffect(() => {
    setValueOptions([]);
    setLoadingValueOptions(true);

    if (keySelectedValue)
      GetFarmerLabelsDistinct(
        {
          page: valueSearch ? 1 : valuesPage,
          pageSize: 10,
          value: debouncedValueSearch,
          key_ids: keySelectedValue.value
            ? [keySelectedValue.value]
            : undefined,
        },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp) {
            setLoadingValueOptions(false);



            let newOptions = [
              ...unionBy(
                resp.items.map((item) => ({
                  label: item.value,
                  value: item.id,
                })),
                (item) => item.label
              ) as any
            ];


            if (keyPrevSelectedValue?.value === keySelectedValue?.value) {

              newOptions.push(...valueOptions)
              newOptions = uniqBy(newOptions, (item) => item.value)
              setValueOptions(newOptions);
            } else {
              setKeyPrevSelectedValue(null!)
              setValueOptions(newOptions)
            }

            setKeyPrevSelectedValue(keySelectedValue!)
          }
        }
      );
  }, [debouncedValueSearch, keySelectedValue, valuesPage]);



  // clean value input when key input changes
  React.useEffect(() => {
    setLoadingKeyOptions(true);
    keysSetPage(0);
    setValueOptions([])
    setValueSelectedValue([]);
    setLoadingKeyOptions(false);
  }, [keySelectedValue]);

  const isKeyValueFilled = keySelectedValue && valueSelectedValue.length > 0;

  return {
    loadingKeyOptions,
    open,
    handleOpenButtonClick,
    handleClose,
    handleSetKeySearch,
    filterKeyOptions,
    keySelectedValue,
    handleKeySelectedValue,
    filterValueOptions,
    loadingValueOptions,
    valueSelectedValue,
    handleSetValueSearch,
    handlesetValueSelectedValue,
    isKeyValueFilled,
    handleAddEntry,
    tempComponentValue,
    handleClearTempValue,
    handleRemoveItem,
    handleSubmit,
    keysPage,
    keysSetPage,
    valuesPage,
    valuesSetPage
  };
};
