import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { BroadcastSubscription } from "models/broadcast-subscription";

interface Params {
  // page: number;
  // pageSize: number;
  broadcast_id?: string;
  id?: string;
  // provider_id?: string;
}
export interface Resp {
  items: BroadcastSubscription[];
  page: number;
  total: number;
  pages: number;
}
type GetBroadcastsSubscriptionType = (
  Params: Params,
  callback: (Error: null | AxiosError, Resp: null | Resp) => any
) => any;
const GetBroadcastsSubscription: GetBroadcastsSubscriptionType = (
  Params,
  callback
) => {
  AxiosInstance.get("/sms-broadcast-subscriptions", {
    params: { ...Params },
  })
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { GetBroadcastsSubscription };
