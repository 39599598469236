import { Box, Grid } from "@mui/material";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomDrawer from "components/custom-compnents/custom-drawer/custom-drawer";
import CustomShowEditCard from "components/custom-compnents/custom-show-edit-card/custom-show-edit-card";
import DeleteEditButtonGroup from "components/custom-compnents/delete-edit-button-group/delete-edit-button-group";
import { IParams, useLogic } from "./show-edit-broadcast-component.logic";
import RemoveBroadcastComponent from "components/standalone-components/remove-broadcast-component/remove-broadcast-component";

export type Actions = "add" | "delete" | "update";

interface ShowEditBroadcastComponentProps extends IParams {
  open?: boolean;
  onBackClick?: () => any;
  onDeleted?: () => any;
  ActionsToExclude?: Actions[];
}

const ShowEditBroadcastComponent: React.FunctionComponent<ShowEditBroadcastComponentProps> =
  (props) => {
    const { broadcast_id, onBackClick, open, onDeleted, ActionsToExclude } = props;

    const {
      fieldsData,
      isInEditMode,
      handleEditButtonClick,
      handleCancelEditButtonClick,
      isShowDeleteComponent,
      handleDeleteButtonClick,
      handleCancelDeleteButtonClick,
      handleDeleted,
      isSubmitting,
      handleSubmit
    } = useLogic({
      broadcast_id,
    });

    return (
      <>
        <RemoveBroadcastComponent
          open={isShowDeleteComponent}
          id={broadcast_id}
          onCancel={handleCancelDeleteButtonClick}
          onDeleted={() => {
            onDeleted?.();
            handleDeleted();
          }}
        />
        <CustomDrawer
          open={open}
          NavigationHeaderProps={{
            backButtonProps: { onClick: onBackClick },
            label: "Broadcast Information",
          }}
          ActionArea={
            <Box display="flex" justifyContent="flex-end">
              {isInEditMode ? (
                <CancelSuccessButtonsGroup
                  RootGridProps={{ width: 300 }}
                  CancelButtonProps={{
                    label: "Cancel",
                    onClick: handleCancelEditButtonClick,
                  }}
                  SuccessButtonProps={{
                    label: "Confirm",
                    onClick: handleSubmit,
                    disabled: isSubmitting,
                  }}
                />
              ) : (
                <DeleteEditButtonGroup
                  RootGridProps={{ width: 300 }}
                  DeleteButtonProps={{
                    hidden: ActionsToExclude?.some((item) => item === 'delete'),
                    label: "Delete",
                    onClick: handleDeleteButtonClick,
                  }}
                  EditButtonProps={{
                    hidden: ActionsToExclude?.some((item) => item === 'update'),
                    label: "Edit",
                    onClick: handleEditButtonClick,
                  }}
                />
              )}
            </Box>
          }
        >
          <Box minHeight="calc(100vh - 220px)">
            <Grid container spacing={2} mt={1}>
              {fieldsData.map(
                (item) =>
                  (!item.isInEditMode || item.editable) && (
                    <Grid item xs={12}>
                      <CustomShowEditCard
                        ShowNode={item.showComponent}
                        label={item.placeholder}
                        editComponent={item.editComponent}
                        editable={item.isInEditMode}
                      />
                    </Grid>
                  )
              )}
            </Grid>
          </Box>
        </CustomDrawer>
      </>
    );
  };

export default ShowEditBroadcastComponent;
