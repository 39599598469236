import { Box, Paper, useMediaQuery } from "@mui/material";
import React from "react";
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
} from "recharts";
import { blue, red } from "@mui/material/colors";
import { useTheme } from "@mui/system";

interface CropModelSimulationChartProps {
  Data: CropSimulationChartDataItem[];
}
export interface CropSimulationChartDataItem {
  time: string;
  // dvs: number
  // lai: number
  // sm: number;
  tagp: number;
  twso: number;
}
const CropModelSimulationChart: React.FunctionComponent<CropModelSimulationChartProps> =
  ({ Data }) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down("md"));
    return (
      <Box component={Paper} height={450} p={2}>
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={300}
            data={Data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Tooltip />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis
              label={{
                value: "kg / ha",
                angle: -90,
                dx: -30,
              }}
            />
            {isMd && (
              <Legend
                layout="horizontal"
                verticalAlign="bottom"
                align="center"
              />
            )}
            {!isMd && (
              <Legend layout="vertical" verticalAlign="middle" align="right" />
            )}

            {/* <Bar dataKey="dvs" fill={blue['900']} name="Crop development stage (DVS)" />
                    <Bar dataKey="lai" fill={green['900']} name="LAI value" />
                    <Bar dataKey="sm" fill={red['700']} name="root-zone soil moisture (SM)" />
                    <Bar dataKey="tagp" fill={orange['900']} name="total biomass (TAGP)" />
                    <Bar dataKey="twso" name="Total weight of storage organs (TWSO)" /> */}
            <Line
              dot={false}
              dataKey="tagp"
              stroke={blue["900"]}
              name="total biomass (TAGP)"
            />
            <Line
              dot={false}
              dataKey="twso"
              stroke={red["900"]}
              name="Total weight of storage organs (TWSO)"
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    );
  };

export default CropModelSimulationChart;
