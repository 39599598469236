import { WhatsappMessageTemplateCategory } from "enums/whatsapp-message-template-category";
import * as yup from "yup";
import { languages } from "mocks/whatsapp-template-languages";
import { MessageTemplateAccessLevel } from "enums/messages-template-access-level";

export const validationSchema = () =>
  yup.object().shape({
    provider_id: yup.string().uuid().optional(),
    name: yup.string().required(),
    body: yup.string().required(),
    // category: yup.string().required().oneOf(Object.values(WhatsappMessageTemplateCategory)),
    language: yup.string().required().oneOf(languages),
    access_level: yup.string().required().oneOf(Object.values(MessageTemplateAccessLevel)),
  });
