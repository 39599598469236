import { Box, Button, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
// import { IoOpenOutline, IoRemoveSharp } from "react-icons/io5";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import { BroadcastMessage } from "models/broadcasts-message";
import moment from "moment";

export type Actions = "add" | "delete" | "update";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
  selectedFarmersIds?: string[];
  selectedByPhone?: boolean;
}
export interface Fields extends BroadcastMessage {
  selected: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onEditButtonClick?: (data: Fields) => any;
  onRemoveButtonClick?: (data: Fields) => any;
  ActionsToExclude?: Actions[];
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (
  params
) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "SUBJECT",
      field: "subject",
      hidden: !params.columnState?.find((item) => item.field === "subject")
        ?.checked,
      render: (data) => data.subject,
    },
    {
      title: "Status",
      field: "is_active",
      hidden: !params.columnState?.find((item) => item.field === "is_active")
        ?.checked,
      render: (data) => <span style={{ color: data.is_active ? '#44b16e' : 'red' }}>{data.is_active ? 'Active' : 'Inactive'}</span>
    },
    {
      title: "MESSAGE",
      field: "message",
      hidden: !params.columnState?.find((item) => item.field === "message")
        ?.checked,
      render: (data) => data.message,
    },
    {
      title: "DAY RELATIVE",
      field: "day_relative",
      hidden: !params.columnState?.find((item) => item.field === "day_relative")
        ?.checked,
      render: (data) => data.send_on_day_relative,
    },
    {
      title: "HOUR RELATIVE",
      field: "hour_relative",
      hidden: !params.columnState?.find(
        (item) => item.field === "hour_relative"
      )?.checked,
      render: (data) => data.relative_send_hour,
    },
    {
      title: "MIN RELATIVE",
      field: "min_relative",
      hidden: !params.columnState?.find((item) => item.field === "min_relative")
        ?.checked,
      render: (data) => data.relative_send_minute,
    },
    {
      title: "SEND ON DAY ABSOLUTE",
      field: "send_on_day_absolute",
      hidden: !params.columnState?.find(
        (item) => item.field === "send_on_day_absolute"
      )?.checked,
      render: (data) =>
        data.send_on_day_absolute
          ? moment
            .utc(data.send_on_day_absolute)
            .local()
            .format("yyyy/MM/DD hh:mm A")
          : "-",
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: (
        <CustomCheckBox
          onChange={(e, value) =>
            params.customSelection?.onSelectAllChange?.(params.data, value)
          }
          checked={params.data.every((item) => item.selected)}
          indeterminate={
            params.data.some((item) => item.selected) &&
            !params.data.every((item) => item.selected)
          }
        />
      ),
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => (
        <CustomCheckBox
          checked={data.selected}
          onChange={(e, value) =>
            params.customSelection?.onRowSelectChange?.(data, value)
          }
        />
      ),
    });
  }

  return {
    actions: [
      (fields) => ({
        hidden: params?.ActionsToExclude?.some((item) => item === 'update'),
        icon: () => (
          <Button
            variant="text"
            onClick={() => params.onEditButtonClick?.(fields)}
            color="success"
            startIcon={<IoCreateOutline />}
          >
            Edit
          </Button>
        ),
        onClick: () => null,

      }),
      (fields) => ({
        hidden: params?.ActionsToExclude?.some((item) => item === 'delete'),
        icon: () => (
          <Button
            variant="text"
            onClick={() => params.onRemoveButtonClick?.(fields)}
            color="success"
            startIcon={<IoTrashOutline />}
          >
            Remove
          </Button>
        ),
        onClick: () => null,
      }),
    ],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
