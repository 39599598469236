import { ButtonBase, ButtonBaseProps, Paper } from "@mui/material";

export interface RoundButtonProps extends ButtonBaseProps {
  isSelected?: boolean;
}

const RoundButton: React.FunctionComponent<RoundButtonProps> = (props) => {
  const selectedColor = "#44b16e";
  const { isSelected, ...otherProps } = props;

  return (
    <Paper
      component={ButtonBase}
      sx={{
        borderRadius: "100%",
        width: 50,
        height: 50,
        color: isSelected ? selectedColor : undefined,
        borderColor: isSelected ? selectedColor : undefined,
      }}
      {...otherProps}
    />
  );
};

export default RoundButton;
