import React from "react";
import ActivityDetailComponent from "components/standalone-components/activity-detail-component/activity-detail-component";
import AddUpdateAggregationActivity from "components/standalone-components/add-update-aggregation-activity/add-update-aggregation-activity";

import CustomFarmsActivitesTable from "components/custom-compnents/custom-farms-activites-table/custom-farms-activites-table";

import { useLogic } from "./farms-activities-table.logic";

export interface FarmsActivitiesTableProps {
  Farm_Id?: string;
  ActionsToExclude?: ("add" | "update" | "delete" | "show")[];
}

const FarmsActivitiesTable: React.FunctionComponent<FarmsActivitiesTableProps> = ({ Farm_Id, ActionsToExclude }) => {
  const { tableProps, OpenAddAggregationModal, HandleAdded, HandleCancelAddAggreagationActivity, ActivityToShowDetail, HandleClose } = useLogic({ Farm_Id, ActionsToExclude });
  return (
    <>
      {ActivityToShowDetail && <ActivityDetailComponent Variant="Modal" open={Boolean(ActivityToShowDetail)} activity_id={ActivityToShowDetail.id} OnOk={HandleClose} WithLinkButton={true} />}
      {Farm_Id && <AddUpdateAggregationActivity farm_id={Farm_Id} Variant="Add" OnAdded={HandleAdded} open={OpenAddAggregationModal} OnCancel={HandleCancelAddAggreagationActivity} />}
      <CustomFarmsActivitesTable {...tableProps} />
    </>
  );
};

export default FarmsActivitiesTable;
