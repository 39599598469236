import { Box, Grid } from "@mui/material";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomDrawer from "components/custom-compnents/custom-drawer/custom-drawer";
import CustomShowEditCard from "components/custom-compnents/custom-show-edit-card/custom-show-edit-card";
import DeleteEditButtonGroup from "components/custom-compnents/delete-edit-button-group/delete-edit-button-group";
import RemoveSurveyComponent from "../remove-survey-component/remove-survey-component";
import { IParams, useLogic } from "./show-edit-survey-component.logic";

interface ShowEditSurveyComponentProps extends IParams {
  open?: boolean;
}

const ShowEditSurveyComponent: React.FunctionComponent<ShowEditSurveyComponentProps> =
  (props) => {
    const {
      navigationHeaderProps,
      fieldsData,
      isInEditMode,
      handleEditButtonClick,
      handleCancelEditButtonClick,
      handleDeleteButtonClick,
      handleSubmit,
      isSubmitting,
      deleteDialogProps,
    } = useLogic(props);

    return (
      <CustomDrawer
        open={props.open ?? false}
        NavigationHeaderProps={navigationHeaderProps}
        ActionArea={
          <Box display="flex" justifyContent="flex-end">
            {isInEditMode ? (
              <CancelSuccessButtonsGroup
                RootGridProps={{ width: 300 }}
                CancelButtonProps={{
                  label: "Cancel",
                  onClick: handleCancelEditButtonClick,
                }}
                SuccessButtonProps={{
                  label: "Confirm",
                  onClick: handleSubmit,
                  disabled: isSubmitting,
                }}
              />
            ) : (
              <DeleteEditButtonGroup
                RootGridProps={{ width: 300 }}
                DeleteButtonProps={{
                  label: "Delete",
                  onClick: handleDeleteButtonClick,
                }}
                EditButtonProps={{
                  label: "Edit",
                  onClick: handleEditButtonClick,
                }}
              />
            )}
          </Box>
        }
      >
        <RemoveSurveyComponent {...deleteDialogProps} />
        <Box minHeight="calc(100vh - 220px)">
          <Grid container spacing={2} mt={1}>
            {fieldsData.map(
              (item) =>
                (!item.isInEditMode || item.editable) && (
                  <Grid item xs={12}>
                    <CustomShowEditCard
                      ShowNode={item.showComponent}
                      label={item.placeholder}
                      editComponent={item.editComponent}
                      editable={item.isInEditMode}
                    />
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      </CustomDrawer>
    );
  };

export default ShowEditSurveyComponent;
