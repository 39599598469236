import Axios from "axios";
import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
// interface getFarmersPercentageInRegionInterface {
//   RegionId: string[];
//   ProviderId?: string;
// }
// const getFarmersPercentageInRegion = (
//   { RegionId, ProviderId }: getFarmersPercentageInRegionInterface,
//   callback?: (data: any) => void
// ) => {
//   // get farmers percentage in regions

//   let json_query: any = {
//     query: {},
//   };
//   RegionId.forEach((item) => {
//     json_query["query"][`region_${item}`] = {
//       __args: {
//         filter: {
//           deletedAt: { isNull: true },
//           providerOptinsByFarmerId: {
//             some: { providerId: { in: ProviderId } },
//           },
//           wardByWardId: {
//             constituencyByConstituencyId: {
//               countyByCountyId: {
//                 regionId: { equalTo: item },
//               },
//             },
//           },
//         },
//       },
//       __aliasFor: "allFarmers",
//       totalCount: true,
//     };
//   });
//   ApiResolver(json_query, callback);
// };

// interface getFarmersPercentageInCountiesInterface {
//   CountiesId: string[];
//   ProviderId?: string;
// }
// const getFarmersPercentageInCounties = (
//   { CountiesId, ProviderId }: getFarmersPercentageInCountiesInterface,
//   callback?: (data: any) => void
// ) => {
//   // get farmers count in Counties

//   let json_query: any = {
//     query: {},
//   };

//   CountiesId.forEach((item) => {
//     json_query["query"][`county_${item}`] = {
//       __args: {
//         filter: {
//           providerOptinsByFarmerId: {
//             some: { providerId: { in: ProviderId } },
//           },
//           deletedAt: { isNull: true },
//           wardByWardId: {
//             constituencyByConstituencyId: {
//               countyId: { equalTo: item },
//             },
//           },
//         },
//       },
//       __aliasFor: "allFarmers",
//       totalCount: true,
//     };
//   });
//   ApiResolver(json_query, callback);
// };

// interface getFarmersPercentageInConstituencyInterface {
//   ConstituenciesId: string[];
//   ProviderId?: string;
// }
// const getFarmersPercentageInConstituency = (
//   { ConstituenciesId, ProviderId }: getFarmersPercentageInConstituencyInterface,
//   callback?: (data: any) => void
// ) => {
//   let json_query: any = {
//     query: {},
//   };

//   ConstituenciesId.forEach((item) => {
//     json_query["query"][`constituency_${item}`] = {
//       __args: {
//         filter: {
//           providerOptinsByFarmerId: {
//             some: { providerId: { in: ProviderId } },
//           },
//           deletedAt: { isNull: true },
//           wardByWardId: {
//             constituencyId: { equalTo: item },
//           },
//         },
//       },
//       __aliasFor: "allFarmers",
//       totalCount: true,
//     };
//   });

//   ApiResolver(json_query, callback);
// };

// interface getFarmersPercentageInWardInterface {
//   WardsId: string[];
//   ProviderId?: string;
// }
// const getFarmersPercentageInWard = (
//   { WardsId, ProviderId }: getFarmersPercentageInWardInterface,
//   callback?: (data: any) => void
// ) => {
//   let json_query: any = {
//     query: {},
//   };
//   WardsId.forEach((item) => {
//     json_query["query"][`ward_${item}`] = {
//       __args: {
//         filter: {
//           providerOptinsByFarmerId: {
//             some: { providerId: { in: ProviderId } },
//           },
//           deletedAt: { isNull: true },
//           wardId: { equalTo: item },
//         },
//       },
//       __aliasFor: "allFarmers",
//       totalCount: true,
//     };
//   });
//   ApiResolver(json_query, callback);
// };

interface GetAllFarmersParams {
  page: number;
  pageSize: number;
  provider_id?: string;
}

type getAllFarmersCountType = (
  Params: GetAllFarmersParams,
  callback: any
) => void

const getAllFarmersCount: getAllFarmersCountType = (Params: any, callback: any) => {
  // let cleaned_json_query: Partial<Object> = cleanDeep(json_query);

  // const graphQL_query: String = jsonToGraphQLQuery(cleaned_json_query, {
  //   pretty: true,
  // });
  AxiosInstance.get("/farmers", { params: Params })
    .then((response) => {
      callback!({
        totalCount: response.data.data.all_farmers.total_count
      });
    })
    .catch((error) => console.log(error));
};

export {
  // getFarmersPercentageInRegion,
  // getFarmersPercentageInCounties,
  getAllFarmersCount,
  // getFarmersPercentageInConstituency,
  // getFarmersPercentageInWard,
};
