import { Box, CircularProgress, Grid } from "@mui/material";
import CustomPagnation from "components/custom-compnents/custom-pagnation/custom-pagnation";
import FarmerLabelKeyComponent from "components/custom-compnents/farmer-label-key-component/farmer-label-key-component";
import FarmerLabelKeysPageHeader from "components/custom-compnents/farmer-label-keys-page-header/farmer-label-keys-page-header";
import ProvidersInput from "components/input-components/providers-input/providers-input";
import RemoveFarmerLabelKey from "components/standalone-components/remove-farmer-label-key/remove-farmer-label-key";
import { useLogic } from "./farmer-label-key-page.logic";

interface FarmerLabelKeyPageProps {}

const FarmerLabelKeyPage: React.FunctionComponent<FarmerLabelKeyPageProps> = () => {
  const {
    data,
    itemToUpdateId,
    menuroles,
    handleUpdateItemInvoke,
    handleCancelUpdate,
    totalCount,
    handleNewSearchTerm,
    loading,
    handleCancelAdd,
    handleOpenAddPanel,
    openAddPanel,
    pagnationProps,
    handleAddKey,
    isAddValid,
    providers,
    handleSetAddValue,
    keyToAdd,
    provider_id,
    loadingAdd,
    handleSetUpdateValue,
    keyToUpdateData,
    handleKeyUpdate,
    isUpdateValid,
    loadingUpdate,
    handleDeleteItemInvoke,
    itemIdToDelete,
    handleDeleted,
    handleCancelDelete,
    // providerFilterIds,
    // handleProviderFilterItemClick,
    handleProvidersChange,
  } = useLogic();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FarmerLabelKeysPageHeader
            onProvidersChange={handleProvidersChange}
            providersData={providers}
            showProviderFilter={menuroles === "admin"}
            createButtonProps={{
              onClick: handleOpenAddPanel,
            }}
            labelCountProps={{ count: totalCount }}
            searchProps={{
              onChange: (e) => handleNewSearchTerm(e.currentTarget.value),
            }}
          />
        </Grid>

        {openAddPanel && (
          <Grid item xs={12}>
            <FarmerLabelKeyComponent
              mode="add"
              providerInputComponent={<ProvidersInput disabled={menuroles !== "admin"} fullwidth={true} value={menuroles === "admin" ? keyToAdd?.providerId : provider_id} onChange={(value) => handleSetAddValue({ providerId: !Array.isArray(value) ? value?.id : "" })} />}
              successButtonProps={{
                onClick: handleAddKey,
                disabled: !isAddValid || loadingAdd,
              }}
              keyInputProps={{
                value: keyToAdd?.key,
                onChange: (e) => handleSetAddValue({ key: e.currentTarget.value }),
              }}
              cancelButtonProps={{ onClick: handleCancelAdd }}
            />
          </Grid>
        )}

        {loading && (
          <Box textAlign="center" width="100%" p={2}>
            <CircularProgress size={40} />
          </Box>
        )}

        {!loading &&
          data.map((item) => (
            <Grid item xs={12} key={item.id}>
              <FarmerLabelKeyComponent
                deleteButtonProps={{
                  onClick: () => handleDeleteItemInvoke(item.id),
                }}
                providerInputComponent={<ProvidersInput disabled={true} fullwidth={true} value={menuroles === "admin" ? keyToUpdateData?.providerId : provider_id} onChange={(value) => handleSetAddValue({ providerId: !Array.isArray(value) ? value?.id : "" })} />}
                keyInputProps={{
                  value: keyToUpdateData?.key,
                  onChange: (e) => handleSetUpdateValue({ key: e.currentTarget.value }),
                }}
                editButtonProps={{
                  onClick: () => handleUpdateItemInvoke(item.id),
                }}
                mode={itemToUpdateId === item.id ? "update" : "view"}
                keyTitle={item.key}
                farmersCount={+item.farmers_count}
                cancelButtonProps={{
                  onClick: handleCancelUpdate,
                }}
                successButtonProps={{
                  onClick: handleKeyUpdate,
                  disabled: !isUpdateValid || loadingUpdate,
                }}
                providerName={item.providerByProviderId.name}
                isShowProvider={menuroles === "admin"}
              />
            </Grid>
          ))}

        <Grid item xs={12}>
          <CustomPagnation {...pagnationProps} />
        </Grid>
      </Grid>
      <RemoveFarmerLabelKey id={itemIdToDelete} open={Boolean(itemIdToDelete)} onCancel={handleCancelDelete} onDeleted={handleDeleted} />
    </>
  );
};

export default FarmerLabelKeyPage;
