import Axios, { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { IDevice } from "models/device";

interface Params {
  page: number;
  pageSize: number;
  farmer_id?: string;
  assigned_devices?: boolean;
  imei?: string;
}

interface Resp {
  total: number;
  items: IDevice[];
}

type GetDevicesType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | Resp) => any
) => any;
const Get_devices: GetDevicesType = (
  Params,
  callback
) => {
  AxiosInstance.get("/devices", {
    params: Params,
  })
    .then((resp) => {
      callback(null, resp.data);
    })
    .catch((error) => callback(error, null));
};

export { Get_devices };
