import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  headerRoot: {
    paddingBlock: 0,
    paddingTop: "0px !important",
  },
  headerText: {
    height: 40,
  },
  itemRoot: {
    height: 45,
  },
  root: {},
  listRoot: {
    maxHeight: 500,
    overflow: "unset !important",
  },
  paperRoot: {
    overflow: "unset !important",
    maxHeight: 600,
    paddingBottom: 10,
  },
});
