import { addSuperOrganizationRelation } from "api/super-organization-relation/add-super-organization-relation";
import { useFormik } from "formik";
import { useEffect } from "react";
import { validationSchema } from "./affiliate-organization-dialog.validation";

export interface IParams {
  sub_org?: string;
  onAffiliated?: () => any;
}
export const useLogic = (params: IParams) => {
  const { sub_org, onAffiliated } = params;

  const form = useFormik({
    validateOnChange: false,
    initialValues: { super_org: "", sub_org: "" },
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      addSuperOrganizationRelation({ ...values })
        .then((resp) => {
          actions.resetForm();
          onAffiliated?.();
        })
        .catch((error) => {
          form.setErrors({ super_org: error.response.data.error });
        })
        .finally(() => {
          form.setSubmitting(false);
        });
    },
    validationSchema,
  });

  useEffect(() => {
    form.setFieldValue("sub_org", sub_org);
  }, [sub_org]);

  return { form };
};
