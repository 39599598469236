import { StandardTextFieldProps } from "@mui/material";
import { IoSearchSharp } from "react-icons/io5";
import CustomTextField from "../custom-text-field/custom-text-field";

export  interface CustomSearchTextFieldProps extends StandardTextFieldProps {}

const CustomSearchTextField: React.FunctionComponent<CustomSearchTextFieldProps> =
  (props) => {
    return (
      <CustomTextField
        {...props}
        InputProps={{
          startAdornment: (
            <IoSearchSharp
              size={25}
              style={{ marginRight: 8, color: "#5d6d6e" }}
            />
          ),
        }}
      />
    );
  };

export default CustomSearchTextField;

CustomSearchTextField.defaultProps = {
  placeholder: "Search ...",
};
