import { AxiosInstance } from "configs/axios";

export interface IParams {
  id: string;
}
export interface Resp {
  nodeDataArray: { id: string; name: string; parent: string }[];
}
const getSuperOrganizationHierarchy = async (params: IParams) => {
  const { id } = params;
  return AxiosInstance.get<Resp>("/organization-relations/find-hierarchy/" + id);
};

export { getSuperOrganizationHierarchy };
