import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  textRoot: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.79",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  },
  actionWrapper: {
    display: "flex",
  },
  avatarRoot: {
    width: "35px",
    height: "35px",
  },
  avatarWrapper: {
    marginRight: 10,
    minWidth: 0,
  },
});

export default useStyles;
