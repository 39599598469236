import { AddSurveyParticipants } from "api/survey-participants/add-participants";
import { GetSurveyById } from "api/survey/get-survey-by-id";
import { CustomSearchAddCountLabelActionBarProps } from "components/custom-compnents/custom-search-add-count-label-action-bar/custom-search-add-count-label-action-bar";
import { CustomSelection } from "components/data-tables/farmers-table/schema";
import { combinedType } from "components/standalone-components/add-update-survey-questions/add-update-survey-question";
import { SurveyParticipants } from "models/survey-participants";
import React from "react";
import { IoDocument, IoPerson } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import {
  removeLoading,
  setBreadcrumbData,
  setLoading,
} from "store/reducers/breadcrumb/breadcrumb";
import { breadcrumb } from "./survey-detail-scree.breadcrumb";

export interface IParams { }

export const useLogic = () => {
  const { survey_id: id } = useParams();

  const [ShowOnlySelectedFarmers, SetShowOnlySelectedFarmers] =
    React.useState(false);

  const [isShowEditDeleteDrawer, setIsShowEditDeleteDrawer] =
    React.useState(false);

  const dispatch = useAppDispatch();

  const TabsData = [
    { label: "Questions", value: 0, icon: IoDocument },
    { label: "Farmers ", value: 1, icon: IoPerson, disabled: true },
  ];

  const [TabValue, SetTabValue] = React.useState(0);

  const [LoadingFarmersTable, SetLoadingFarmerTable] = React.useState(false);

  const [isOpenAddQuestionModal, setIsOpenAddQuestionModal] =
    React.useState(false);

  const [DefaultSelectedFarmers, SetDefaultSelectedFarmers] = React.useState<
    SurveyParticipants[]
  >([]);

  const [loadedQuestionsCount, setLoadedQuestionsCount] = React.useState(0);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (id) {
      dispatch(setLoading());
      GetSurveyById({ page: 0, pageSize: 10, survey_id_list: [id] }, (error, resp) => {
        if (error) {
          console.log(error.message);
        } else if (resp) {
          dispatch(removeLoading());
          dispatch(setBreadcrumbData(breadcrumb({ survey: resp })));
        }
      });
    }
  }, [id]);

  const HandleSelectionChange: CustomSelection["OnSelectionChange"] = (
    Fields,
    checkedState
  ) => {
    let newSelectedFarmersIdArray = DefaultSelectedFarmers.map(
      (item) => item.user_id
    );
    if (
      checkedState &&
      !newSelectedFarmersIdArray.find((item) => item === Fields.id)
    ) {
      newSelectedFarmersIdArray.push(Fields.id);
    }
    if (
      !checkedState &&
      newSelectedFarmersIdArray.find((item) => item === Fields.id)
    ) {
      newSelectedFarmersIdArray = newSelectedFarmersIdArray.filter(
        (item) => item !== Fields.id
      );
    }

    SetLoadingFarmerTable(true);

    id &&
      AddSurveyParticipants(
        { survey_id: id, users_id_list: newSelectedFarmersIdArray },
        (error, resp) => {
          if (error) {
            console.log(error);
          } else if (resp) {
            SetDefaultSelectedFarmers(resp.data.items);
            SetLoadingFarmerTable(false);
          }
        }
      );
  };

  const HandleBulkSelectChange: CustomSelection["OnCustomSelectAllChanged"] = (
    Type,
    Fields
  ) => {
    let newSelectedFarmersIdArray = DefaultSelectedFarmers.map(
      (item) => item.user_id
    );
    if (Type === "all-selected" && Fields) {
      newSelectedFarmersIdArray = newSelectedFarmersIdArray.concat(
        Fields?.map((item) => item.id)
      );
    }
    if (Type === "none-selected" && Fields) {
      newSelectedFarmersIdArray = newSelectedFarmersIdArray.filter(
        (item) => !Fields.some((FieldsItem) => FieldsItem.id === item)
      );
    }

    SetLoadingFarmerTable(true);
    id &&
      AddSurveyParticipants(
        { survey_id: id, users_id_list: newSelectedFarmersIdArray },
        (error, resp) => {
          if (error) {
            console.log(error);
          } else if (resp) {
            SetDefaultSelectedFarmers(resp.data.items);
            SetLoadingFarmerTable(false);
          }
        }
      );
  };

  const HandleFilterSelectedFarmersButtonClicked = () =>
    SetShowOnlySelectedFarmers(!ShowOnlySelectedFarmers);

  const handleBackButtonClick = () => setIsShowEditDeleteDrawer(false);

  const handleUpdated = () => {
    // window.location.reload();
  };

  const handleDeleted = () => {
    navigate("/surveys");
  };

  const handleCancelAddQuestion = () => setIsOpenAddQuestionModal(false);

  const handleAddedQuestion = () => {
    setIsOpenAddQuestionModal(false);
    window.location.reload();
  };

  const handleAddQuestionButtonClick = () => setIsOpenAddQuestionModal(true);

  const actionBarProps: CustomSearchAddCountLabelActionBarProps = {
    createButton: {
      children: "Add New Question",
      onClick: handleAddQuestionButtonClick,
    },
    searchFieldProps: {
      disabled: true,
      style: { display: "none" },
    },
    countLabelProps: {
      count: loadedQuestionsCount,
      label: "All Questions",
    },
  };

  const addQuestionDialogProps: combinedType | undefined = id
    ? {
      Variant: "add",
      survey_id: id,
      OnCancel: handleCancelAddQuestion,
      OnAdded: handleAddedQuestion,
      open: isOpenAddQuestionModal,
    }
    : undefined;

  return {
    TabsData,
    TabValue,
    SetTabValue,
    setIsShowEditDeleteDrawer,
    handleBackButtonClick,
    isShowEditDeleteDrawer,
    ShowOnlySelectedFarmers,
    HandleSelectionChange,
    HandleBulkSelectChange,
    DefaultSelectedFarmers,
    HandleFilterSelectedFarmersButtonClicked,
    LoadingFarmersTable,
    handleUpdated,
    handleDeleted,
    id,
    actionBarProps,
    addQuestionDialogProps,
    setLoadedQuestionsCount,
  };
};
