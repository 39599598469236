import { Typography } from "@mui/material";
import * as React from "react";
import { useStyles } from "./custom-title.style";

interface CustomTitleProps {
  label?: string;
}

const CustomTitle: React.FunctionComponent<CustomTitleProps> = (props) => {
  const { label } = props;
  const classes = useStyles();

  return <Typography className={`${classes.root}`}>{label}</Typography>;
};

export default CustomTitle;
