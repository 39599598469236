import { AxiosError } from "axios";
import { AxiosInstance } from "configs/axios";
import { Resp as GetSubscripionResp } from "./get-broadcasts-subscription";
interface Resp extends GetSubscripionResp { }
interface Params {
  broadcast_id: string;
  farmer_id: string | string[];
  day_zero?: string;
}
type AddSubscriptionType = (
  Params: Params,
  callback: (error: null | AxiosError, resp: null | Resp) => any
) => any;
const AddSubscription: AddSubscriptionType = (Params, callback) => {
  AxiosInstance.post("/sms-broadcast-subscriptions", { ...Params })
    .then((resp) => callback(null, resp.data))
    .catch((error) => callback(error, null));
};

export { AddSubscription };
