import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({

  loadMoreBtn: {
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    }
  },
});
