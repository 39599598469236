import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  ListItem,
  Pagination,
  TextField,
} from "@mui/material";
import { FunctionComponent } from "react";
import { IParams, useLogic } from "./labels-input.logic";

export type Value = { label: string; value: string };

interface LabelsInputProps extends IParams {
  onChange?: (value: any | Value[], reason: AutocompleteChangeReason) => any;
  value?: Value[];
}

const LabelsInput: FunctionComponent<LabelsInputProps> = (props) => {
  const { options, setSearchTerm, isLoading, totalCount, page, setPage } =
    useLogic(props);

  return (
    <Autocomplete
      multiple
      loading={isLoading}
      options={options}
      value={props.value || []}
      onChange={(_, value, reason) => props.onChange?.(value, reason)}
      onInputChange={(e, value) => {
        setSearchTerm(value);
      }}
      renderOption={(props, option) =>
        typeof option === "string" ? (
          <Box display="flex" justifyContent="center">
            <Pagination
              size="small"
              count={Math.ceil(totalCount / 10)}
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          </Box>
        ) : (
          <li {...props} key={option?.value}>
            <ListItem {...props}>{option.label}</ListItem>
          </li>
        )
      }
      filterOptions={(options) => {
        const result =
          options.length > 0 ? [...options, "pagnation" as any] : [];

        return result;
      }}
      renderInput={(params) => <TextField {...params} placeholder="Labels" />}
    />
  );
};

export default LabelsInput;
